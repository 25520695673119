import { Grid, Typography } from "@material-ui/core";

import { useStyles } from "./styles";

export default function FormElement({ name, value, type, onFileClick }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item md={12}>
        <Typography className={classes.subSectionTitle}>
          {name} :{" "}
          <span
            className={
              type === "file"
                ? classes.fileSubSectionValue
                : classes.subSectionValue
            }
            onClick={type === "file" && onFileClick}
          >
            {name === "Indicative Interview Schedule" && value !== null ? (
              <>
                {value[0]} <span className={classes.spanText}>to</span>{" "}
                {value[1]}
              </>
            ) : (
              value || "-"
            )}
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
}
