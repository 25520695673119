import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import TopicsList from './TopicsList'
import HelpRequest from '../Help/HelpRequest'

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: theme.palette.secondary.main,
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  help: {
    display: 'flex',
    justifyContent: 'space-between',

    '& button': {
      padding: '0px 0px',
    },

    '& span': {
      fontSize: '8px',

      textTransform: 'none',
      fontWeight: 400,
    },
  },
  emptyDesc: {
    color: '#bebebe',
    fontSize: '0.75rem',
    fontStyle: 'italic',
    textAlign: 'center',
  },
}))

export default function CategoriesList({ name, isReadOnly }) {
  const classes = useStyles()

  return (
    <>
      <HelpRequest refer="category" />

      <div style={{ marginTop: '20px' }}>
        <Typography
          gutterBottom
          variant="button"
          className={classes.subHeading}
          component="caption"
          display="block"
        >
          {name ? 'Categories in ' + name : ''}
        </Typography>
        {!name && (
          <Typography className={classes.emptyDesc}>
            No Categories available
          </Typography>
        )}
      </div>

      <TopicsList isReadOnly={isReadOnly} type="group" />
    </>
  )
}
