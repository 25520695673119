import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Divider, Grid, Typography } from "@material-ui/core";

import { LIST_REQUIREMENT_SECTIONS } from "../../queries/query";
import { useQuery } from "@apollo/client";

import { customAlphabet } from "nanoid/non-secure";
import RTextItem from "../Items/requirements/textItem";
import RTextAreaItem from "../Items/requirements/textAreaItem";
import RSelectItem from "../Items/requirements/selectItem";
import RCurrencyItem from "../Items/requirements/currencyItem";
import RFileItem from "../Items/requirements/fileupload";
import RDateItem from "../Items/requirements/dateItem";
import RNumberItem from "../Items/requirements/numberItem";

const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz-", 10);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
  },
  heading: {
    fontSize: theme.spacing(2),
  },
  properties: {
    width: theme.spacing(50),
  },
  propertiestitle: {
    marginBottom: theme.spacing(2),
  },
  propsbuttongroups: {
    marginTop: theme.spacing(2),
  },
  propsbutton: {
    margin: theme.spacing(1),
  },
  helpicon: {
    fontSize: theme.spacing(1.5),
    cursor: "pointer",
  },
  weight: {
    fontSize: theme.spacing(1.5),
  },
  caption: {
    color: "red",
  },
}));

export default function RSection(props) {
  const classes = useStyles();

  //   const [items, setItems] = React.useState([]);
  const [metadata, setMetadata] = React.useState(props.metadata);

  const { loading, error, data, refetch } = useQuery(
    LIST_REQUIREMENT_SECTIONS,
    {
      fetchPolicy: "no-cache",
      variables: {
        tinput: { sectionid: props.sid, projectid: props.projectId },
        pinput: { sectionid: props.sid, projectid: props.projectId },
      },
    }
  );

  function getItem(key, type, id, metadata, values) {
    switch (type) {
      case "text_field":
        return (
          <RTextItem
            key={key}
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            readOnly={true}
            refetch={refetch}
          />
        );
      case "text_area":
        return (
          <RTextAreaItem
            rows={4}
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            readOnly={true}
            refetch={refetch}
          />
        );

      case "multi_choice":
        return (
          <RSelectItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            readOnly={true}
          />
        );

      case "currency":
        return (
          <RCurrencyItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            readOnly={true}
          />
        );

      case "attachment":
        return (
          <RFileItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            readOnly={true}
          />
        );

      case "date":
        return (
          <RDateItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            readOnly={true}
          />
        );

      case "number":
        return (
          <RNumberItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            readOnly={true}
          />
        );

      default:
        break;
    }
  }

  function getValues(values, itemid, type) {
    const result = values.filter((value) => value.itemid === itemid);
    result.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp));

    return result ? result[0] : {};
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={1}>
        <Avatar className={classes.small} color="primary">
          <Typography variant="caption" display="block">
            {metadata.id}
          </Typography>
        </Avatar>
      </Grid>

      <Grid item xs={9}>
        <Typography variant="h6" display="block" className={classes.heading}>
          {metadata.title}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        {props.type === "evaluate_template" ? (
          <Typography variant="caption" className={classes.weight}>
            Weight:{metadata.weight}
          </Typography>
        ) : null}
      </Grid>

      <Grid item xs={1}></Grid>

      <Grid item xs={11}>
        <Divider />
      </Grid>

      <Grid item xs={1}></Grid>

      <Grid item xs={11}>
        {data
          ? data.listItem.map((item) => {
              var meta = JSON.parse(item.metadata);
              var values = getValues(data.getRequirementDocument, item.id, "");
              var input = {
                itemid: item.id,
                sectionid: props.sid,
                projectid: props.projectId,
                attachment: "",
                attachmentfilename: "",
                timestamp: null,
                value: "",
                remarks: "",
              };

              input = { ...input, ...values };
              delete input.__typename;

              return getItem(item.id, item.type, item.id, meta, { ...input });
            })
          : null}
      </Grid>
    </Grid>
  );
}
