import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import PrimaryButton from "../common/PrimaryButton";
import Link from "@material-ui/core/Link";
import HelpDailogBox from "../Help/HelpDailogBox";
import InviteUser from "../Agreements/InviteUser";
import Paper from "@material-ui/core/Paper";
import { useMutation, useQuery } from "@apollo/client";
import { inviteUser } from "../Agreements/query";
import { getUsersList } from "../AdminDashboard/query";
import VbridgeAlert from "../../shared/alert";

export const useStyles = makeStyles((theme) => ({
  blockedUserRoot: {
    marginTop: theme.spacing(10),
  },
  blockedText: {
    textAlign: "center",
  },
  root: {
    maxWidth: "70%",
    margin: "auto",
    marginTop: theme.spacing(20),
    padding: theme.spacing(2),
  },
  redirectButton: {
    "& button": {
      marginTop: theme.spacing(2),
      float: "none",
    },
  },
  info: {
    fontSize: "14px",
    margin: "auto",
  },
  links: {
    marginTop: theme.spacing(3),
    display: "flex",
    margin: "auto",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: 600,
    "& a": {
      color: "#4179BD",
    },
  },
}));

export default function License() {
  const classes = useStyles();

  const [openDailog, setOpenDailog] = useState(false);
  const [heading, setHeading] = useState("");
  const [statement, setStatement] = useState("");
  const [inlay, setInlay] = useState("");
  const [finalMsg, setFinalMsg] = useState("");
  const [inviteError, setInviteError] = useState(
    "Something went wrong. Please try again."
  );

  const [
    inviteUserMutation,
    { loading: inviteLoading, error: userError, data: inviteUserSuccess },
  ] = useMutation(inviteUser, {
    onCompleted: (e) => {
      closeModal();
    },
    onError: (e) => {
      setInviteError(e.graphQLErrors[0].message);
    },
  });

  const submitUserInvite = (emailId) => {
    inviteUserMutation({
      variables: {
        input: { personemailid: emailId },
      },
    });
  };

  const openDailogBox = (hdng, stmnt, inly, msg) => {
    setOpenDailog(true);
    setHeading(hdng);
    setStatement(stmnt);
    setInlay(inly);
    setFinalMsg(msg);
  };

  const onClose = () => {
    setOpenDailog(false);
  };

  const [inviteModal, setInviteModal] = useState(false);

  const onInviteUser = (e) => {
    e.preventDefault();
    setInviteModal(true);
  };

  const closeModal = () => {
    setInviteModal(false);
  };

  return (
    <div className={classes.blockedUserRoot}>
      {userError && (
        <VbridgeAlert severity="error" open={true} message={inviteError} />
      )}

      {inviteLoading && (
        <VbridgeAlert severity="info" open={true} message="Please wait" />
      )}
      <Paper className={classes.root}>
        <Grid container className={classes.blockedText}>
          <Grid md={12} item className={classes.info}>
            <Typography className={classes.info}>
              Thank you for evaluating vBridge Hub. Your 48 hour trial access to
              the platform has ended. For continuous usage of the platform and
              to derive organization wide benefit, please onboard your
              organization as a buyer entity. Access and usage of the platform
              for Buyer organization’s is FREE.
            </Typography>
          </Grid>
          <Grid md={9} item className={classes.links}>
            <Grid item xs className={classes.data}>
              <Link
                onClick={() => {
                  openDailogBox(
                    "Feedback",
                    "We are working hard to build a meaningful product for you. We would love to have your views!",
                    "Let us know your views",
                    "Thank you for your valuable feedback. We will get in touch with you on email to update status on your feedback."
                  );
                }}
              >
                Feedback
              </Link>
            </Grid>

            <Grid item xs className={classes.data}>
              <Link
                onClick={() => {
                  openDailogBox(
                    "Contact Sales for onboarding",
                    "Happy to assist on usage of the platform or curation of any Group, Category or Product",
                    "Please provide details here",
                    "Your request has been sent to our team. We will get in touch with you on email to schedule a call as per convenience to extend assistance."
                  );
                }}
              >
                Contact Sales for onboarding
              </Link>
            </Grid>

            <Grid item xs className={classes.data}>
              <Link onClick={onInviteUser}>Refer right contact </Link>
            </Grid>

            <HelpDailogBox
              open={openDailog}
              close={onClose}
              heading={heading}
              statement={statement}
              inlay={inlay}
              message={finalMsg}
            />

            <InviteUser
              open={inviteModal}
              closeModal={closeModal}
              loading={inviteLoading}
              submitUserInvite={submitUserInvite}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
