import * as React from "react";

import { useQuery } from "@apollo/client";

import { GET_SECTIONS } from "../../queries/query";

import { Box, FormControl, Grid } from "@material-ui/core";

import RSection from "./qasection";

import { useStyles } from "./styles";

import LoadingResult from "../../../../shared/LoadingResult";

export default function QAForm({
  productId,
  id,
  projectId,
  templateId,
  type,
  readOnly,
}) {
  const classes = useStyles();

  const { data, loading } = useQuery(GET_SECTIONS, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: templateId } },
  });

  return (
    <div className={classes.root}>
      <Grid container className={classes.main}>
        <Grid item xs={12}>
          {data ? (
            <Box m={1}>
              <FormControl
                component="fieldset"
                className={classes.formControlclass}
              >
                {data.listSection.map((section, index) => {
                  var meta = JSON.parse(section.metadata);
                  meta = { ...meta, id: index + 1 };

                  return (
                    <RSection
                      productId={productId}
                      key={section.id}
                      name={section.name}
                      vbeid={id}
                      sid={section.id}
                      projectId={projectId}
                      id={index + 1}
                      metadata={meta}
                      preview={true}
                      type={type}
                      readOnly={readOnly}
                    />
                  );
                })}
              </FormControl>
            </Box>
          ) : loading ? (
            <LoadingResult />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
