import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useQuery } from '@apollo/client'

import { useParams } from 'react-router-dom'

import TopicsList from '../groups2.0/TopicsList'

import { listTopicGroups } from './query'
import HelpRequest from '../Help/HelpRequest'

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: theme.palette.secondary.main,
    textAlign: 'left',
    fontSize: '0.85rem',
  },
  help: {
    display: 'flex',
    justifyContent: 'space-between',
    // padding: "8px",

    '& button': {
      padding: '0px 0px',
    },

    '& span': {
      fontSize: '8px',
      textTransform: 'none',
      fontWeight: 400,
    },
  },
  emptyDesc: {
    color: '#bebebe ',
    fontSize: '0.75rem',
    fontStyle: 'italic',
    textAlign: 'center',
  },
}))

export default function MapGroupToTopic({ isReadOnly }) {
  const classes = useStyles()
  let { id } = useParams()

  const [groupName, setGroupName] = useState('')
  const [groupId, setGroupId] = useState('')

  const getGroupName = (groups) => {
    groups.listGroupByTopicId.groups.forEach((group) => {
      setGroupName(group.name)
      setGroupId(group.id)
    })
  }

  const { data: list } = useQuery(listTopicGroups, {
    variables: { input: { topicid: id } },
    onCompleted: (groups) => {
      getGroupName(groups)
    },
  })

  return (
    <>
      <HelpRequest refer="category" />

      <div style={{ marginTop: '20px' }}>
        <Typography
          gutterBottom
          variant="button"
          style={{ textTransform: 'capitalize' }}
          className={classes.subHeading}
          component="caption"
          display="block"
        >
          {' '}
          Related Categories in {list ? groupName : null}
        </Typography>
        {!groupName && (
          <Typography className={classes.emptyDesc}>
            No Categories available
          </Typography>
        )}
      </div>

      {groupId !== '' && (
        <TopicsList
          isReadOnly={isReadOnly}
          type="topic"
          selectedGroupId={groupId}
          topicId={id}
        />
      )}
    </>
  )
}
