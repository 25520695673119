import React from "react";
import { useParams } from "react-router-dom";

import {
  Grid,
  Divider,
  Typography,
  ListItemSecondaryAction,
  ListItemText,
  List,
  ListItemIcon,
  ListItem,
} from "@material-ui/core";

import PublishIcon from "@material-ui/icons/Publish";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import File from "../../../shared/uploadfile";
import CustomCkeditor from "../../../shared/customckeditor";
import UploadDoc from "../../../shared/uploaddocument";

import { theme } from "../../../../theme/theme";

import {
  deleteDocument,
  getCasestudyById,
  listDocs,
  signed,
  updateCaseStudyFile,
} from "./query";

import CaseStudyView from "./CaseStudyView";
import Load from "../../../shared/skeletons/skeleton";
import EmptyDiv from "../../companies/Company2.0/tabs/lists/emptyDiv";

import { useStyles } from "../../products/product2.0/styles";

export default function CaseStudyOverview({
  isReadOnly,
  UpdateCaseStudyCache,
}) {
  const { id } = useParams();
  const client = useApolloClient();
  const classes = useStyles();

  //list docs
  const { data: docs } = useQuery(listDocs, {
    variables: { input: { vbeid: id, type: "casestudy" } },
  });

  ////update cache
  const updateCache = (paramName, e, paramName1, e1, paramName2, e2) => {
    let updatedDocs = {};

    updatedDocs[paramName] = e;
    updatedDocs[paramName1] = e1;
    updatedDocs[paramName2] = e2;

    let updatedCache = [...docs.listsupportDoc];
    updatedCache.unshift({ ...updatedDocs });

    client.writeQuery({
      query: listDocs,
      data: {
        __typename: "project",
        listsupportDoc: updatedCache,
      },

      variables: { input: { vbeid: id, type: "casestudy" } },
    });
  };

  const [signedUrl] = useMutation(signed, {
    onCompleted: (e) => {
      window.open(e.signedURLforSupportDoc.signedurl, "_blank");
    },
  });

  const createUrl = (e) => {
    signedUrl({
      variables: {
        input: {
          method: "get",
          key: e.target.parentElement.getAttribute("pathname"),
          vbeid: id,
          type: "casestudy",
        },
      },
    });
  };

  //post Requirement
  const [deletedocmutation] = useMutation(deleteDocument, {
    onCompleted: (e) => {
      updateDeleteCache(e);
    },
  });

  const updateDeleteCache = (e) => {
    let updatedCache = [...docs.listsupportDoc];
    updatedCache = updatedCache.filter(
      (document) => document.id !== e.deleteSupportDoc.id
    );

    client.writeQuery({
      query: listDocs,
      data: {
        __typename: "project",
        listsupportDoc: updatedCache,
      },

      variables: { input: { vbeid: id, type: "casestudy" } },
    });
  };

  const deleteDoc = (e) => {
    deletedocmutation({
      variables: {
        input: {
          key: e.target.getAttribute("pathname"),
          vbeid: id,
          type: "casestudy",
          timestamp: e.target.getAttribute("timestamp"),
        },
      },
    });
  };

  const { loading, data } = useQuery(getCasestudyById, {
    variables: { input: { vbeid: id } },
  });

  const [postCaseStudyMutation] = useMutation(updateCaseStudyFile);

  const postUpdateCasestudy = (paramName, paramValue) => {
    postCaseStudyMutation({
      variables: {
        input: {
          vbeid: id,
          [paramName]: paramValue,
        },
      },
    });
  };

  const [caseStudyMutation] = useMutation(updateCaseStudyFile, {
    onCompleted: (e) => {
      var longEDesc = e;
      UpdateCaseStudyCache(longEDesc);
    },
  });

  const saveData = (data) => {
    caseStudyMutation({
      variables: {
        input: {
          longdescription: data,
          vbeid: id,
        },
      },
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {loading ? (
            <div className={classes.loadingDiv}>
              <Load />
            </div>
          ) : data && data.casestudyByID.id === id ? (
            data.casestudyByID.longdescription !== "" &&
            data.casestudyByID.longdescription !== null ? (
              <CustomCkeditor
                data={data.casestudyByID.longdescription}
                editorId={id}
                isReadOnly={isReadOnly ? true : false}
                saveMethod={saveData}
              />
            ) : !isReadOnly ? (
              <CustomCkeditor
                data={""}
                editorId={id}
                isReadOnly={isReadOnly ? true : false}
                saveMethod={saveData}
              />
            ) : (
              <EmptyDiv type="description" />
            )
          ) : null}
        </Grid>

        <Grid item>
          {isReadOnly ? null : (
            <File
              vbeid={id}
              entity="casestudyfile"
              filetype="doc"
              fext=".pdf"
              inputid={id + "casestudyfile"}
              paramName="filepath"
              updateCacheMethod={postUpdateCasestudy}
              buttonmessage="upload Success Story File"
              type="button"
              icon={<PublishIcon fontSize="small" />}
            />
          )}
        </Grid>

        {data ? (
          <Grid item md={12}>
            <CaseStudyView id={id} filePath={data?.casestudyByID.filepath} />
            <Divider />
          </Grid>
        ) : null}

        <Grid container>
          {isReadOnly ? null : (
            <>
              <Grid
                item
                style={{
                  paddingTop: theme.spacing(1),
                  paddingLeft: theme.spacing(3),
                }}
              >
                <Typography variant="subtitle2" color="primary">
                  Upload supporting documents
                </Typography>
              </Grid>

              {/* updateCacheMethod={updateCache} */}

              <Grid item>
                <UploadDoc
                  vbeid={id}
                  buttonmessage="upload file"
                  type="casestudy"
                  buttontype="icon"
                  inputid={id + "casestudydoc"}
                  icon={<CloudUploadIcon fontSize="small" />}
                  updateCacheMethod={updateCache}
                  paramName="id"
                  paramName1="name"
                  paramName2="signedurl"
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid item md={12}>
          <List style={{ paddingRight: theme.spacing(8) }}>
            {docs
              ? docs.listsupportDoc.map((value) => {
                  return (
                    <ListItem key={value.id}>
                      <ListItemIcon>
                        <AttachFileIcon fontSize="small" />
                      </ListItemIcon>

                      <ListItemText
                        pathname={value.signedurl}
                        onClick={createUrl}
                        style={{
                          color: "primary",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        {value.name}
                      </ListItemText>

                      {isReadOnly ? null : (
                        <>
                          <ListItemSecondaryAction>
                            <Grid container spacing={2}>
                              <Grid item>
                                <DeleteOutlinedIcon
                                  fontSize="small"
                                  pathname={value.signedurl}
                                  timestamp={value.id}
                                  onClick={deleteDoc}
                                  style={{
                                    color: "primary",
                                    cursor: "pointer",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </ListItemSecondaryAction>
                        </>
                      )}
                    </ListItem>
                  );
                })
              : null}
          </List>
        </Grid>
      </Grid>
    </>
  );
}
