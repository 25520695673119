import { useMutation, useQuery } from "@apollo/client";

import { Link } from "@material-ui/core";
import VbridgeAlert from "../../shared/alert";

import {
  getProfileAdminsQuery,
  requestProfileAdminAccess,
} from "../companies/Company2.0/query";

import { useStyles } from "./styles";

export default function RequestForProfileAdminAccess({
  request,
  companyName,
  isAdmin,
  isCompanyAdmin,
  showSteppers,
}) {
  const classes = useStyles();

  const { data: admin_data } = useQuery(getProfileAdminsQuery, {
    variables: { input: {} },
  });

  const [
    requestProfileAdminAccessMutation,
    { loading: requestLoading, data: requestData },
  ] = useMutation(requestProfileAdminAccess, {
    onCompleted: (data) => {},
    onError: (e) => {},
  });

  const submitRequest = (event) => {
    requestProfileAdminAccessMutation({
      variables: { input: {} },
    });
  };

  let adminList = "";

  if (admin_data) {
    let profileAdminList = admin_data.listProfileAdmin;

    if (profileAdminList.length !== 0) {
      profileAdminList.forEach((admin, index) => {
        if (index > 0) {
          adminList = adminList + ", " + admin.name + " ";
        } else adminList = admin.name;
      });
    }
  }

  return (
    <div className={classes.adminRequestDiv}>
      <div className={classes.formHeaderText}>
        <span className={classes.adminListText}>{companyName}</span> profile is
        claimed and managed by your colleague(s) -{" "}
        <span className={classes.adminListText}>{adminList}</span>.{" "}
        {request || requestData?.profileadminrequest ? (
          "Your admin access request is waiting for approval."
        ) : !isCompanyAdmin ? (
          <>
            You can
            <Link onClick={submitRequest}>
              <span className={classes.textLink}> Request Access</span>
            </Link>{" "}
            to join the Admin group {showSteppers && "or click NEXT"}.
          </>
        ) : null}
      </div>

      {requestLoading && (
        <VbridgeAlert
          open={true}
          message="Please wait while your request is being sent."
          severity="info"
        />
      )}

      {requestData && !requestLoading && (
        <VbridgeAlert
          open={true}
          message="Your request has been sent successfully."
          severity="success"
        />
      )}
    </div>
  );
}
