import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useQuery, useApolloClient, useLazyQuery } from "@apollo/client";

import {
  Grid,
  Button,
  Avatar,
  ListItemText,
  ListItemIcon,
  List,
  ListItem,
  Checkbox,
  ListItemAvatar,
  ButtonGroup,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

import { VBRIDGEHUB_MEDIAURL } from "../../../constants";

import Load from "../../shared/skeletons/skeleton";

import AddToProject from "../project/addproducttoproject";

import { useStyles } from "./mainListStyles";

import {
  getAwardsByCompanyId,
  getCaseStudiesByCompanyId,
  getCustomersByCompanyId,
  getPartnersByCompanyId,
  getUseCasesByCompanyId,
  userInfoQuery,
  getProductsByFilters,
  userPermission,
  getUseCaseByFilters,
  getCaseStudiesByFilters,
} from "./query";

import ProductHeader from "./lists/ProductHeader";
import EmptyDiv from "../companies/company/tabs/lists/emptyDiv";

import {
  getCasestudiesByProductId,
  getUsecasesByProductId,
} from "../products/product2.0/query";

import {
  getCasestudiesByCompanyId,
  getInvestorsByCompanyId,
  getProductsByCompanyId,
  getUsecasesByCompanyId,
} from "../companies/Company2.0/query";
import { getServicesByGroupId, getServicesByTopicId } from "./serviceQuery";

import AddNewForm from "../products/product2.0/AddNew/AddNewForm";
import AddProductForm from "../companies/Company2.0/shorts/AddProductForm";
import AddUseCaseForm from "../products/product2.0/shorts/AddUseCaseForm";
import AddCaseStudyForm from "../products/product2.0/shorts/AddCaseStudyForm";
import AddServicesForm from "../companies/Company2.0/shorts/AddServicesForm";
// import ProductDetail from "../home/ProductDetail";
import UpdateSlider from "../common/UpdateSlider";
import { getCompaniesBySkill } from "../Skills/query";

export default function MainList({
  vbeid,
  type,
  tab,
  locationsList,
  awardsList,
  partnerList,
  fundingRoundsList,
  yearList,
  maturityList,
  skillList,
  isReadOnly,
  toggleValue,
  isAdmin,
  onCheck,
  checkedValues,
  image,
}) {
  const classes = useStyles();
  const client = useApolloClient();

  let { id, typeValue } = useParams();

  if (id === undefined) {
    id = vbeid;
  }

  const [checked, setChecked] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [values, setValues] = useState([]);
  const [checkValue, setCheckValue] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [loadingMore, setLoadMore] = useState(false);
  const [newDataLength, setNewDataLength] = useState(10);
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  let queryName = getProductsByFilters;
  let dataName = "listproductfilter";

  let idName = "topicid";
  if (type === "group") {
    idName = "groupid";
  } else if (type === "productUseCase" || type === "productCaseStudy") {
    idName = "productid";
  } else if (type === "skill") {
    idName = "skillid";
  }

  if (tab === "products") {
    if (typeValue === "services" || typeValue === "products") {
      if (type === "skill") {
        queryName = getCompaniesBySkill;
        dataName = "listCompanyfilter";
      } else {
        queryName = getProductsByFilters;
      }
    } else {
      if (type === "topic") {
        queryName = getServicesByTopicId;
        dataName = "listServicesbyTopicId";
      } else {
        queryName = getServicesByGroupId;
        dataName = "listServicesbyGroupId";
      }
    }
  } else if (tab === "usecase") {
    if (type === "productUseCase") {
      queryName = getUsecasesByProductId;
      dataName = "usecaseByProductId";
    } else {
      queryName = getUseCaseByFilters;
      dataName = "listUsecasefilter";
    }
  } else if (tab === "company") {
    if (type === "awards") {
      queryName = getAwardsByCompanyId;
      dataName = "listAwardsByCompanyIDV1";
    } else if (type === "investors") {
      queryName = getInvestorsByCompanyId;
      dataName = "investorsByCompanyId";
    } else if (type === "customers") {
      queryName = getCustomersByCompanyId;
      dataName = "customersByCompanyId";
    } else if (type === "partners") {
      queryName = getPartnersByCompanyId;
      dataName = "partnersByCompanyId";
    } else if (type === "products" || type === "services") {
      queryName = getProductsByCompanyId;
      dataName = "productByComapnyId";
    } else if (type === "usecases") {
      queryName = getUsecasesByCompanyId;
      dataName = "usecaseByCompanyId";
    } else if (type === "caseStudies") {
      queryName = getCasestudiesByCompanyId;
      dataName = "casestudiesByCompanyId";
    }
  } else {
    if (type === "productCaseStudy") {
      queryName = getCasestudiesByProductId;
      dataName = "casestudyByProductId";
    } else {
      queryName = getCaseStudiesByFilters;
      dataName = "listCaseStudyfilter";
    }
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const checkedValues = [];

    if (currentIndex === -1) {
      newChecked.push(value);

      newChecked.map((value) => {
        return checkedValues.push(value.vbeid);
      });
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setValues(checkedValues);

    if (currentIndex === -1) {
      setCheckValue(true);
    } else if (currentIndex >= 1) {
      setCheckValue(true);
    } else if (newChecked.length < 1) {
      setCheckValue(false);
    }
  };

  const user = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  let filteredInput = {
    input: {
      yearlist: yearList?.length !== 0 ? yearList : null,
      partnerlist: partnerList?.length !== 0 ? partnerList : null,
      locationlist: locationsList?.length !== 0 ? locationsList : null,
      awardslist: awardsList?.length !== 0 ? awardsList : null,
      fundroundlist: fundingRoundsList?.length !== 0 ? fundingRoundsList : null,
      maturitylist: maturityList?.length !== 0 ? maturityList : null,
      skilllist: skillList?.length !== 0 ? skillList : null,
      offset: 0,
      limit: 10,
      [idName]: id,
    },
  };

  if (type === "productUseCase" || type === "productCaseStudy") {
    filteredInput = {
      input: { productid: id },
    };
  } else if (tab === "company") {
    let idType = "companyid";

    if (type === "awards") {
      idType = "companyvbeid";
    } else {
      idType = "vbeid";
    }

    filteredInput = {
      input: { [idType]: id },
    };

    if (type === "products") {
      filteredInput = {
        input: { [idType]: id },
      };
    } else if (type === "services") {
      filteredInput = {
        input: { [idType]: id, type: "service" },
      };
    }
  }

  const userpermission = client.readQuery({
    query: userPermission,
    variables: { input: {} },
  });

  const { fetchMore, data, loading } = useQuery(queryName, {
    variables: filteredInput,
  });

  const { refetch: casestudyrefetch } = useQuery(getCaseStudiesByCompanyId, {
    variables: { input: { vbeid: id } },
  });

  const [getCustomers, { loading: customerLoading, data: customerData }] =
    useLazyQuery(getCustomersByCompanyId, {
      variables: { input: { vbeid: selectedCompany } },
    });

  const [getPartners, { loading: partnerLoading, data: partnerData }] =
    useLazyQuery(getPartnersByCompanyId, {
      variables: { input: { vbeid: selectedCompany } },
    });

  const [getAwards, { loading: awardLoading, data: awardData }] = useLazyQuery(
    getAwardsByCompanyId,
    {
      variables: { input: { companyvbeid: selectedCompany } },
    }
  );

  const [getCaseStudies, { loading: caseStudyLoading, data: caseStudyData }] =
    useLazyQuery(getCaseStudiesByCompanyId, {
      variables: { input: { vbeid: selectedCompany } },
    });

  const [getUseCases, { loading: useCaseLoading, data: useCaseData }] =
    useLazyQuery(getUseCasesByCompanyId, {
      variables: { input: { vbeid: selectedCompany } },
    });

  const updateQuery = (previousResult, { fetchMoreResult }) => {
    if (!fetchMoreResult) {
      return previousResult;
    }

    const previousEdges = previousResult[dataName];
    const fetchMoreEdges = fetchMoreResult[dataName];
    setNewDataLength(fetchMoreEdges.length);

    fetchMoreResult[dataName] = [...previousEdges, ...fetchMoreEdges];

    return { ...fetchMoreResult };
  };

  const scrollMore = () => {
    if (data) {
      const currentLength = data[dataName].length;
      setLoadMore(true);

      let filteredInput = {
        yearlist: yearList?.length !== 0 ? yearList : null,
        partnerlist: partnerList?.length !== 0 ? partnerList : null,
        locationlist: locationsList?.length !== 0 ? locationsList : null,
        awardslist: awardsList?.length !== 0 ? awardsList : null,
        fundroundlist:
          fundingRoundsList?.length !== 0 ? fundingRoundsList : null,
        maturitylist: maturityList?.length !== 0 ? maturityList : null,
        skilllist: skillList?.length !== 0 ? skillList : null,
        offset: currentLength,
        limit: 10,
        [idName]: id,
      };

      if (type === "productUseCase" || type === "productCaseStudy") {
        filteredInput = {
          productid: id,
        };
      } else if (tab === "company") {
        let idType = "companyid";

        if (type !== "awards") {
          idType = "vbeid";
        }

        filteredInput = {
          [idType]: id,
        };
      }

      fetchMore({
        updateQuery,
        variables: {
          input: filteredInput,
        },
      }).then((fetchMoreResult) => {
        setDefaultData(data[dataName]);
        setLoadMore(false);
      });
    }
  };

  const onClick = (type, vbeid, companyvbeid) => {
    setSelectedCompany(companyvbeid);

    if (type === "Customers") getCustomers();
    if (type === "Partners") getPartners();
    if (type === "Awards") getAwards();
    if (type === "Case Studies" || type === "Success Stories") getCaseStudies();
    if (type === "Use Cases" || type === "Resources") getUseCases();
  };

  const updateUseCaseCache = (response) => {
    if (type === "productUseCase") {
      let dataList = data[dataName];

      let listUseCaseInformationCache = [response.addUsecaseShort, ...dataList];

      client.writeQuery({
        query: queryName,
        data: {
          [dataName]: {
            __typename: "generic",
            usecaseByProductId: listUseCaseInformationCache,
          },
        },
        variables: { input: { vbeid: id } },
      });
    } else {
      const { usecaseByCompanyId } = client.readQuery({
        query: queryName,
        variables: { input: { vbeid: id } },
      });

      let listUseCaseInformationCache = [
        response.addUsecaseShort,
        ...usecaseByCompanyId,
      ];

      client.writeQuery({
        query: queryName,
        data: {
          [dataName]: {
            __typename: "generic",
            usecaseByCompanyId: listUseCaseInformationCache,
          },
        },
        variables: { input: { vbeid: id } },
      });
    }

    setSelectedData(response.addUsecaseShort);
    setDetailOpen(true);
  };

  const updateCaseStudyCache = (response) => {
    const { casestudiesByCompanyId } = client.readQuery({
      query: getCaseStudiesByCompanyId,
      variables: { input: { vbeid: id } },
    });

    let listCaseStudyInformationCache = [
      response.addCasestudyShort,
      ...casestudiesByCompanyId,
    ];

    client.writeQuery({
      query: getCaseStudiesByCompanyId,
      data: {
        [dataName]: {
          __typename: "generic",
          casestudiesByCompanyId: listCaseStudyInformationCache,
        },
      },
      variables: { input: { vbeid: id } },
    });

    setSelectedData(response.addCasestudyShort);
    setDetailOpen(true);
  };

  const updateProductCache = (response) => {
    setSelectedData(response);
    setDetailOpen(true);
  };

  const onDrawerClose = () => {
    setDetailOpen(false);
  };

  const onEdit = (data) => {
    setSelectedData(data);
    setDetailOpen(true);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        justify="flex-end"
        className={classes.mainListGrid}
      >
        {!isReadOnly && (
          <Grid item>
            {type === "products" && tab === "company" && (
              <AddProductForm updateCache={updateProductCache} />
            )}

            {(type === "usecases" ||
              (tab === "usecase" && type === "product")) && (
              <AddUseCaseForm updateCache={updateUseCaseCache} />
            )}

            {(type === "caseStudies" ||
              (tab === "caseStudy" &&
                (type === "product" || type === "serviceCaseStudy"))) && (
              <AddCaseStudyForm
                casestudyrefetch={casestudyrefetch}
                type={type}
                updateCache={updateCaseStudyCache}
              />
            )}

            {type === "services" && (
              <AddServicesForm updateCache={updateProductCache} />
            )}
          </Grid>
        )}

        {(tab === "products" || type === "products" || type === "services") &&
        userpermission ? (
          userpermission.amiauthorized.status ? (
            <>
              {user.getLoginUserInfo.roletype === "seller" ? null : (
                <Grid item>
                  <AddToProject
                    productvbeid={values}
                    disable={checkValue ? false : true}
                    searchContext={type}
                    checkedObj={checked}
                  />
                </Grid>
              )}
            </>
          ) : (
            <Grid item>
              <AddToProject
                productvbeid={values}
                disable={checkValue ? false : true}
                searchContext={type}
                checkedObj={checked}
              />
            </Grid>
          )
        ) : type === "productUseCase" ? (
          <ButtonGroup
            disableElevation={false}
            size="small"
            variant="text"
            color="primary"
            className={classes.addNewButtonGroup}
          >
            {!isReadOnly && (
              <AddNewForm type="Resource" updateCache={updateUseCaseCache} />
            )}
          </ButtonGroup>
        ) : type === "productCaseStudy" ? (
          <ButtonGroup
            disableElevation={false}
            size="small"
            variant="text"
            color="primary"
            className={classes.addNewButtonGroup}
          >
            {!isReadOnly && <AddNewForm type="Success Story" />}
          </ButtonGroup>
        ) : null}
      </Grid>

      <Grid container className={classes.root}>
        <Grid
          item
          md={12}
          spacing={1}
          style={type === "awards" ? { display: "flex" } : {}}
        >
          <List className={type === "awards" ? classes.list : ""}>
            {data ? (
              data[dataName] === null || data[dataName] === undefined ? null : (
                (data && data[dataName] !== null && data[dataName] !== undefined
                  ? data[dataName]
                  : defaultData !== null
                  ? defaultData
                  : []
                ).map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value.vbeid}`;

                  if (type !== "awards") {
                    return (
                      <ListItem
                        key={value.vbeid}
                        divider
                        dense
                        className={classes.listItem}
                      >
                        <div className={classes.listItemRoot}>
                          {(type === "products" ||
                            type === "services" ||
                            type === "skill") &&
                            userpermission?.amiauthorized.status && (
                              <ListItemIcon className={classes.iconRoot}>
                                <Checkbox
                                  edge="end"
                                  onChange={
                                    tab === "company" &&
                                    type !== "products" &&
                                    type !== "usecases" &&
                                    type !== "caseStudies" &&
                                    type !== "services"
                                      ? onCheck(value)
                                      : handleToggle(value)
                                  }
                                  checked={
                                    tab === "company" &&
                                    type !== "products" &&
                                    type !== "usecases" &&
                                    type !== "caseStudies" &&
                                    type !== "services"
                                      ? checkedValues.indexOf(value.vbeid) !==
                                        -1
                                      : checked.indexOf(value) !== -1
                                  }
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </ListItemIcon>
                            )}

                          <ListItemAvatar className={classes.listImage}>
                            <Avatar
                              variant="square"
                              alt={image}
                              src={
                                type === "services"
                                  ? VBRIDGEHUB_MEDIAURL + "/" + image
                                  : VBRIDGEHUB_MEDIAURL + "/" + value.image
                              }
                              className={classes.productImage}
                            >
                              <CancelPresentationIcon />
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            disableTypography={true}
                            className={classes.itemText}
                            primary={
                              <ProductHeader
                                groupVbeid={value.groupvbeid}
                                topicName={
                                  type === "group"
                                    ? value.topiclist
                                    : value.groupname
                                }
                                type={type}
                                productData={value}
                                tab={tab}
                                isAdmin={isAdmin}
                                label="Customers"
                                handleClick={onClick}
                                companyvbeid={value.companyvbeid}
                                customerData={
                                  !customerLoading
                                    ? customerData?.customersByCompanyId
                                    : null
                                }
                                customerCount={value.customercount || null}
                                partnerData={
                                  !partnerLoading
                                    ? partnerData?.partnersByCompanyId
                                    : null
                                }
                                partnerCount={value.partnercount || null}
                                awardData={
                                  !awardLoading
                                    ? awardData?.listAwardsByCompanyIDV1
                                    : null
                                }
                                awardCount={value.awardcount || null}
                                caseStudyData={
                                  !caseStudyLoading
                                    ? caseStudyData?.casestudiesByCompanyId
                                    : null
                                }
                                caseStudyCount={value.casestudycount || null}
                                useCaseData={
                                  !useCaseLoading
                                    ? useCaseData?.usecaseByCompanyId
                                    : null
                                }
                                usecaseCount={value.usecasecount || null}
                                isReadOnly={isReadOnly}
                                onEditClick={onEdit}
                                userCompany={
                                  user?.getLoginUserInfo.companyvbeid
                                }
                              />
                            }
                          />
                        </div>
                      </ListItem>
                    );
                  } else {
                    return (
                      <Card style={{ margin: "8px", minWidth: "250px" }}>
                        <CardContent
                          style={{ padding: "16px 8px", display: "flex" }}
                        >
                          <Checkbox
                            edge="end"
                            onChange={onCheck(value)}
                            checked={checkedValues.indexOf(value.vbeid) !== -1}
                            inputProps={{ "aria-labelledby": labelId }}
                            style={{ padding: "0 8px 0 0" }}
                          />

                          <Avatar
                            variant="square"
                            alt={image}
                            src={VBRIDGEHUB_MEDIAURL + "/" + value.awarderimage}
                            className={classes.productImage}
                            style={{ margin: "0 8px" }}
                          >
                            <CancelPresentationIcon />
                          </Avatar>

                          <div>
                            <Typography style={{ fontSize: "0.9rem" }}>
                              {value.name}
                            </Typography>

                            <Typography style={{ fontSize: "0.7rem" }}>
                              Year - {value.year}
                            </Typography>
                          </div>
                        </CardContent>
                      </Card>
                    );
                  }
                })
              )
            ) : loading ? (
              <List className={classes.root}>
                {[0, 1, 2, 3].map(() => {
                  return <Load />;
                })}
              </List>
            ) : null}
          </List>

          {loadingMore && (
            <List className={classes.root}>
              {[0, 1, 2].map(() => {
                return <Load />;
              })}
            </List>
          )}

          {data && data[dataName] !== null && data[dataName] !== undefined ? (
            data[dataName].length === 0 ? (
              <EmptyDiv
                type={
                  type === "productUseCase" || type === "usecases"
                    ? "Resources"
                    : type === "productCaseStudy" ||
                      type === "caseStudies" ||
                      type === "serviceCaseStudy"
                    ? "Success Stories"
                    : tab === "company"
                    ? type
                    : toggleValue
                }
                margin="noTopMargin"
              />
            ) : (
              type !== "productUseCase" &&
              type !== "productCaseStudy" &&
              type !== "services" &&
              tab !== "company" &&
              newDataLength === 10 && (
                <div className={classes.loadMoreOption}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={scrollMore}
                  >
                    Load More
                  </Button>
                </div>
              )
            )
          ) : null}
        </Grid>

        {selectedData !== null && (
          <UpdateSlider
            open={detailOpen}
            product={selectedData}
            onClose={onDrawerClose}
            type={type}
            action="edit"
            logo={type === "services" ? VBRIDGEHUB_MEDIAURL + "/" + image : ""}
          />
        )}
      </Grid>
    </>
  );
}
