import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { useMutation } from "@apollo/client";

import {
  SIGNED_URL,
  UPDATE_QA_ITEM,
  UPDATE_RESPONSE_ITEM,
  UPDATE_SOW_ITEM,
} from "../../../queries/query";

import { useFormik } from "formik";

import PropTypes from "prop-types";

const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  checkbox: {
    "& span": {
      fontSize: theme.spacing(1.4),
    },
    download: {
      fontSize: theme.spacing(1),
      textTransform: "none",
    },
    deleteFile: {
      fontSize: theme.spacing(1.2),
    },
  },
  rtitle: {
    fontWeight: "bold",
  },
}));

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function UploadFile({ id, uploadFile, readOnly, upload, uploadPer }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={8}>
        <input
          className={classes.input}
          id={"file" + id}
          type="file"
          onChange={(e) => {
            uploadFile(e);
          }}
        />

        <label htmlFor={"file" + id}>
          <Button
            variant="contained"
            size="small"
            component="span"
            startIcon={<CloudUploadIcon />}
            disabled={readOnly}
          >
            Upload
          </Button>
        </label>
      </Grid>

      <Grid item xs={4}>
        {upload ? (
          <CircularProgressWithLabel color="secondary" value={uploadPer} />
        ) : null}
      </Grid>
    </Grid>
  );
}

function DownLoadFile(props) {
  const classes = useStyles();

  const [dsignedUrl, { data: dsdata, loading: dsloading, error: dserror }] =
    useMutation(SIGNED_URL, {
      onCompleted: (e) => {
        let url = e.gettemplateSignedURL.signedurl;
        window.open(url);
      },
      onError: (err) => {
        console.log(err);
      },
    });

  function deleteFile() {
    // props.formik.setFieldValue("attachmentfilename", "");
    // props.formik.setFieldValue("value", "");
    // props.formik.handleSubmit();
    // props.setUpload(false);
    // props.setUploadPer(2);
    // props.setShowupload(true);

    let fileValues = JSON.parse(props.formik.values.value);

    let keepFiles = fileValues.filter((file) => {
      return file.name !== props.name;
    });

    props.formik.setFieldValue("attachmentfilename", "");
    props.formik.setFieldValue("value", keepFiles);
    props.formik.setFieldValue("action", "delete");
    props.formik.handleSubmit();
  }

  function downLoadFile() {
    dsignedUrl({
      variables: {
        input: {
          projectid: props.formik.values.projectid,
          productid: props.formik.values.productvbeid,
          attachmentfilename: props.name,
          method: "get",
        },
      },
    });
  }

  return (
    <Grid container>
      <Grid item xs={11}>
        <Button
          onClick={downLoadFile}
          color="secondary"
          size="small"
          component="span"
          className={classes.download}
          startIcon={<AttachFileIcon fontSize="small" />}
        >
          {props.name}
        </Button>
      </Grid>

      {props.readOnly ? null : (
        <Grid item xs={1}>
          <IconButton onClick={deleteFile}>
            <CloseIcon
              color="secondary"
              className={classes.deleteFile123344}
              fontSize="small"
            />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

function getQuery(type) {
  switch (type) {
    case "response_template":
      return UPDATE_RESPONSE_ITEM;

    case "qa_template":
      return UPDATE_QA_ITEM;

    case "sow_template":
      return UPDATE_SOW_ITEM;

    default:
      return null;
  }
}

function getQueryReturnItem(type) {
  switch (type) {
    case "response_template":
      return "addResTempItemvalue";

    case "qa_template":
      return "addQuestionTempItemvalue";

    case "sow_template":
      return "addAwardTempItemvalue";

    default:
      return null;
  }
}

export default function RFileItem(props) {
  const classes = useStyles();

  let fileProps = props.values.value;

  if (fileProps !== "") {
    fileProps = JSON.parse(props.values.value);
  } else {
    fileProps = [];
  }

  const [metadata, setMetadata] = useState(props.metadata);
  const [showUpload, setShowupload] = useState(
    props.values.value === null || props.values.value === ""
  );
  const [upload, setUpload] = useState(false);
  const [uploadPer, setUploadPer] = useState(2);
  const [filesUploaded, setFilesUploaded] = useState(fileProps);

  const [updateItem, { data, loading, error }] = useMutation(
    getQuery(props.type),
    {
      onCompleted: (e) => {
        formik.setFieldValue(
          "timestamp",
          e[getQueryReturnItem(props.type)][0].timestamp
        );

        fileProps = JSON.parse(e.addReqTemplateItemvalue[0].value);

        setFilesUploaded(fileProps);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const [signedUrl, { data: sdata, loading: sloading, error: serror }] =
    useMutation(SIGNED_URL, {
      onCompleted: (e) => {
        let url = e.gettemplateSignedURL.signedurl;
        let attachment = e.gettemplateSignedURL.attachment;

        formik.setFieldValue("value", attachment);
        putFile(url);
      },
      onError: (err) => {
        console.log(err);
      },
    });

  const formik = useFormik({
    initialValues: props.values,

    onSubmit: (values) => {
      var input = { ...values };

      if (input.action !== "delete") {
        let oldFileProps = fileProps;

        if (data) {
          oldFileProps = JSON.parse(data.addReqTemplateItemvalue[0].value);
        }

        let fileValues = {
          path: input.value,
          name: input.attachmentfilename,
        };

        oldFileProps.push(fileValues);

        input.value = JSON.stringify(oldFileProps);
      } else {
        input.value = JSON.stringify(input.value);
      }

      if (input.timestamp === null) {
        delete input.timestamp;
      }

      delete input.action;

      updateItem({ variables: { input: [input] } });
    },
  });

  function uploadFile(e) {
    setUpload(true);

    var tfile = e.target.files[0];
    let name = tfile.name;

    // formik.setFieldValue("attachmentfilename", name);

    signedUrl({
      variables: {
        input: {
          projectid: formik.values.projectid,
          productid: formik.values.productvbeid,
          attachmentfilename: name,
          method: "put",
        },
      },
    });
  }

  async function putFile(url) {
    let file = document.getElementById("file" + props.id).files[0];

    await axios
      .request({
        method: "put",
        url: url,
        data: file,

        onUploadProgress: (p) => {
          const percent = Math.round((p.loaded / p.total) * 100);
          setUploadPer(percent);
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          let uploadedFile = filesUploaded;
          uploadedFile.push(file.name);

          setUpload(false);
          setFilesUploaded(uploadedFile);

          formik.setFieldValue("attachmentfilename", file.name);
          formik.setFieldValue("action", "add");

          formik.handleSubmit();
        }
      });
  }

  return (
    <Grid container alignItems="center" className={classes.root}>
      {!props.hidetitle ? (
        <Grid item xs={4}>
          <Typography
            variant="caption"
            gutterBottom
            className={props.readOnly ? classes.rtitle : ""}
          >
            {metadata.title}
          </Typography>
        </Grid>
      ) : null}

      <Grid item xs={props.hidetitle ? 12 : 4}>
        {!props.readOnly && (
          <UploadFile
            id={props.id}
            uploadFile={uploadFile}
            upload={upload}
            uploadPer={uploadPer}
            readOnly={props.readOnly}
          />
        )}

        {filesUploaded.length !== 0 &&
          filesUploaded.map((file) => {
            return (
              <DownLoadFile
                name={file.name}
                setUpload={setUpload}
                setShowupload={setShowupload}
                setUploadPer={setUploadPer}
                formik={formik}
                readOnly={props.readOnly}
                updateItem={updateItem}
              />
            );
          })}
      </Grid>

      <Grid item xs={3}>
        {metadata.remarks ? (
          props.readOnly ? (
            <Typography variant="caption">{formik.values.remarks}</Typography>
          ) : (
            <TextField
              id="filled-full-width"
              name="remarks"
              autoComplete="off"
              value={formik.values.remarks}
              onChange={formik.handleChange}
              placeholder="Remarks"
              fullWidth
              variant="outlined"
              size="small"
              onBlur={formik.handleSubmit}
            />
          )
        ) : null}
      </Grid>
    </Grid>
  );
}
