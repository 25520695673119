import CustomCkeditor from "../../../../shared/customckeditor";
import Load from "../../../../shared/skeletons/skeleton";
import EmptyDiv from "./lists/emptyDiv";

export default function AboutCompany({
  companyData,
  id,
  isReadOnly,
  saveData,
}) {
  return companyData.vbeid === id ? (
    companyData &&
    companyData.longdescription !== "" &&
    companyData.longdescription !== null ? (
      <CustomCkeditor
        data={companyData.longdescription}
        editorId={id}
        isReadOnly={isReadOnly ? true : false}
        saveMethod={saveData}
      />
    ) : !isReadOnly ? (
      <>
        <CustomCkeditor
          data={""}
          editorId={id}
          isReadOnly={isReadOnly ? true : false}
          saveMethod={saveData}
        />
      </>
    ) : (
      <EmptyDiv type="description" />
    )
  ) : (
    <div style={{ marginTop: "8px" }}>
      <Load />
    </div>
  );
}
