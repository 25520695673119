import { useState } from "react";

import {
  FormControl,
  InputLabel,
  SwipeableDrawer,
  TextField,
} from "@material-ui/core";

import { useStyles } from "./styles";

import PrimaryButton from "../common/PrimaryButton";
import SelectField from "../common/SelectField";
import { optionsTypes } from "./templateHelper";
import InputField from "../common/InputField";

export default function EditProperties({ open, item, onEdit, onClose }) {
  const classes = useStyles();

  let itemProps = item.properties;

  const [labelValue, setLabelValue] = useState(itemProps?.label);
  const [maxValue, setMaxValue] = useState(itemProps?.max);
  const [fileType, setFileType] = useState(itemProps?.fileType);
  const [optionType, setOptionsType] = useState(itemProps?.optionsType);
  const [customValues, setCustomValues] = useState(itemProps?.customValues);
  const [helpMessage, setHelpMessage] = useState("");

  const handleChange = (event, type) => {
    let value = event.target.value;

    if (type === "label") {
      setLabelValue(value);
    } else if (type === "max") {
      setMaxValue(value);
    } else if (type === "fileType") {
      setFileType(value);
    } else if (type === "optionType") {
      setOptionsType(value);
    } else if (type === "custom") {
      setCustomValues(value);
    } else if (type === "message") {
      setHelpMessage(value);
    }
  };

  const onSave = () => {
    onEdit(
      labelValue,
      maxValue,
      fileType,
      optionType,
      customValues,
      helpMessage,
      item
    );
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      className={classes.createTemplate}
      onClose={onClose}
    >
      <div>Edit Properties</div>

      <div className={classes.editHeading}>
        <InputLabel>{!item.type ? "Heading" : "Field Name"} </InputLabel>

        <TextField
          variant="outlined"
          value={labelValue}
          onChange={(event) => handleChange(event, "label")}
          className={classes.editField}
        />
      </div>

      <div className={classes.editHeading}>
        <InputLabel>Help Message</InputLabel>

        <TextField
          variant="outlined"
          value={helpMessage}
          onChange={(event) => handleChange(event, "message")}
          className={classes.editField}
        />
      </div>

      {item.type === "textarea" && (
        <div className={classes.editHeading}>
          <InputLabel>Number of rows</InputLabel>

          <TextField
            variant="outlined"
            value={maxValue}
            onChange={(event) => handleChange(event, "max")}
            className={classes.editField}
          />
        </div>
      )}

      {item.type === "text" && (
        <div className={classes.editHeading}>
          <InputLabel>Max number of characters</InputLabel>

          <TextField
            variant="outlined"
            value={maxValue}
            onChange={(event) => handleChange(event, "max")}
            className={classes.editField}
          />
        </div>
      )}

      {item.type === "file" && (
        <div className={classes.editHeading}>
          <InputLabel>File Type</InputLabel>

          <TextField
            variant="outlined"
            value={fileType}
            onChange={(event) => handleChange(event, "fileType")}
            className={classes.editField}
          />
        </div>
      )}

      {item.type === "dropdown" && (
        <div className={classes.editHeadingDropdown}>
          <FormControl variant="outlined" fullWidth>
            <SelectField
              label="Select Option Type"
              onChange={(event) => handleChange(event, "optionType")}
              value={optionType}
              options={optionsTypes}
              multiple={false}
            />
          </FormControl>
        </div>
      )}

      {item.type === "dropdown" && optionType === "custom" && (
        <div className={classes.editHeadingDropdown}>
          <InputLabel style={{ marginBottom: "8px" }}>
            Comma separated option values
          </InputLabel>
          <FormControl className={classes.formControl} fullWidth={true}>
            <InputField
              label="Custom Option values"
              placeholder="Enter comma separated values"
              onChange={(event) => handleChange(event, "custom")}
              value={customValues}
            />
          </FormControl>
        </div>
      )}

      <div className={classes.editButton}>
        <PrimaryButton
          variant="contained"
          label="Save"
          disabled={false}
          onClick={onSave}
        />
      </div>
    </SwipeableDrawer>
  );
}
