import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    fontSize: "0.8rem",
    height: "130px",
    width: "100%",
  },
  buttonDescription: {
    textTransform: "none",
    fontSize: "0.7rem",
    paddingTop: theme.spacing(1),
    height: "60px",
  },
}));

export default function ProjectTypeButton({ label, onClick, description }) {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      color="primary"
      className={classes.primaryButton}
      onClick={onClick}
    >
      <div>
        {label}
        <div className={classes.buttonDescription}>{description}</div>
      </div>
    </Button>
  );
}
