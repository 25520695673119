import { useHistory, useParams } from "react-router";
import { useStyles } from "../styles";

import { IconButton, Typography } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import clsx from "clsx";

import PercentIcon from "../PercentIcon";

import ReadMore from "../ReadMore";
import ViewButtons from "../ViewButtons";

import { getFormattedYear } from "../../../../functions/helper";
import ViewMoreButton from "../../common/ViewMoreButton";
import ListMultiCategory from "../../common/ListMultiCategory";

export default function ProductHeader({
  topicVbeid,
  groupVbeid,
  topicName,
  type,
  productData,
  tab,
  label,
  isAdmin,
  handleClick,
  customerData,
  customerCount,
  partnerData,
  partnerCount,
  awardData,
  awardCount,
  caseStudyData,
  caseStudyCount,
  useCaseData,
  usecaseCount,
  isReadOnly,
  onEditClick,
  userCompany,
}) {
  let history = useHistory();
  const classes = useStyles();
  const { typeValue } = useParams();

  const productHeading = clsx({
    [classes.productHead]: tab === "products" ? true : false,
    [classes.groupName]: true,
  });

  const productHeading2 = clsx({
    [classes.productHead2]: tab === "products" ? true : false,
    [classes.groupName2]: true,
  });

  const locationDiv = clsx({
    [classes.location]: type === "skill" ? false : true,
    [classes.spacing]: type === "skill" ? false : true,
    [classes.skillLocation]: type === "skill" ? true : false,
  });

  const {
    vbeid,
    name,
    companyvbeid,
    referenceable,
    companyname,
    companylocation,
    knpashot_score,
    securityscorecard_grade_url,
    fundround,
    awardername,
    year,
    round,
    amount,
    companyid,
    shortdescription,
    industryvertical,
    servicetypeobj,
  } = productData;

  let verticalList = "";

  if (industryvertical) {
    verticalList = industryvertical.map((value) => {
      return " " + value + " ";
    });
  }

  if (servicetypeobj) {
    verticalList = servicetypeobj.map((value) => {
      return " " + value.name + " ";
    });
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <Typography
          className={
            type === "customers" ||
            type === "partners" ||
            type === "investors" ||
            type === "awards"
              ? classes.overviewTabHeading
              : !isAdmin
              ? productHeading
              : productHeading2
          }
          variant="h6"
          onClick={() => {
            if (tab === "products" || (type === "products" && !isAdmin)) {
              if (typeValue === "products") {
                history.push("/product/" + vbeid);
              } else if (typeValue === "services") {
                history.push("/service/" + vbeid);
              } else {
                history.push("/product/" + vbeid);
              }
            } else if (tab === "usecase" || (type === "usecases" && !isAdmin)) {
              history.push("/usecase/" + vbeid);
            } else if (
              tab === "caseStudy" ||
              (type === "caseStudies" && !isAdmin)
            ) {
              history.push("/casestudy/" + vbeid);
            }
            // else if (
            //   (type === "customers" ||
            //     type === "partners" ||
            //     type === "investors") &&
            //   !isAdmin
            // ) {
            //   history.push("/company/" + companyid);
            // }
            else if (type === "services" && !isAdmin) {
              history.push("/service/" + vbeid);
            } else if (type === "awards" && !isAdmin) {
              //   history.push("/company/" + id);
            }
          }}
        >
          {name}
          {/* {type === "customers" ? (
            <span
              style={{
                fontSize: "0.75rem",
                fontWeight: "normal",
                display: "flex",
                cursor: "pointer",
                fontStyle: "italic",
              }}
            >
              Referenceable : {referenceable ? "Yes" : "No"}
            </span>
          ) : null} */}

          {type === "awards" && (
            <span
              style={{
                marginLeft: "24px",
                fontSize: "0.8rem",
                fontWeight: "400",
              }}
            >
              Awarded Year : {year}
            </span>
          )}
        </Typography>

        {topicName && (
          <>
            <span className={classes.subText}>in</span>
            <ListMultiCategory
              title={
                type === "group" ? "Visit Category Page" : "Visit Group Page"
              }
              data={topicName}
              groupVbeid={groupVbeid}
              length={1}
              type={type}
              typeValue={typeValue}
            />

            {type === "group" && topicName.length > 1 ? (
              <ViewMoreButton
                length={1}
                data={topicName}
                groupVbeid={groupVbeid}
                categoriesCount={topicName.length - 1}
              />
            ) : null}
          </>
        )}

        {!isReadOnly &&
          ((tab === "company" &&
            type !== "customers" &&
            type !== "investors" &&
            type !== "awards" &&
            type !== "partners") ||
            type === "productUseCase" ||
            type === "productCaseStudy") && (
            <IconButton onClick={() => onEditClick(productData)}>
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
          )}
      </div>

      <div className={classes.aboutDiv}>
        <div
          className={
            tab !== "company" &&
            type !== "productUseCase" &&
            type !== "productCaseStudy"
              ? classes.leftData
              : ""
          }
        >
          {tab !== "company" &&
            type !== "productUseCase" &&
            type !== "productCaseStudy" && (
              <div className={classes.companyDiv}>
                <div className={classes.companyDetails}>
                  {type !== "investors" ? (
                    <>
                      {type !== "skill" && (
                        <div
                          className={
                            type === "awards"
                              ? classes.awardName
                              : classes.companyName
                          }
                          onClick={() => {
                            if (type === "awards") {
                              // history.push("/company/" + id);
                            } else {
                              if (userCompany === companyvbeid) {
                                history.push("/mycompany/" + companyvbeid);
                              } else {
                                history.push("/company/" + companyvbeid);
                              }
                            }
                          }}
                        >
                          {tab === "products" && (
                            <span className={classes.bySubText}>by </span>
                          )}
                          {companyname || awardername}{" "}
                        </div>
                      )}

                      <div className={locationDiv}>{companylocation}</div>
                    </>
                  ) : (
                    <>
                      <div className={classes.fundingStage}>{round}</div>
                      <div className={locationDiv}>
                        Invested Amount (MN $) : {amount}
                      </div>
                      <div className={locationDiv}>
                        Last Invested : {getFormattedYear(year)}
                      </div>
                    </>
                  )}
                  <div className={classes.fundingStage}>{fundround}</div>
                </div>

                <div className={classes.iconDiv}>
                  {securityscorecard_grade_url && (
                    <img
                      className={classes.icon}
                      src={securityscorecard_grade_url}
                      alt="grade"
                    />
                  )}
                  {knpashot_score && <PercentIcon percent={knpashot_score} />}
                </div>
                {/* <div className={classes.ndaMark}>NDA</div>
        <div className={classes.partnerMark}>Partner</div> */}
              </div>
            )}

          <div className={classes.productDescText}>
            <ReadMore
              type="description"
              text={shortdescription}
              tab={tab}
              subTabType={type}
            />
          </div>

          {/* {(industryvertical || servicetypeobj) && (
            <div>
              <Typography variant="caption" style={{ fontSize: 11 }}>
                {industryvertical ? "Verticals Tagged : " : "Service Type : "}
                {verticalList + " "}
              </Typography>
            </div>
          )} */}
        </div>

        {tab !== "company" &&
          type !== "productUseCase" &&
          type !== "productCaseStudy" && (
            <div className={classes.rightData}>
              <div className={classes.viewButtons}>
                {tab !== "usecase" && (
                  <ViewButtons
                    label="Customers"
                    handleClick={handleClick}
                    vbeid={vbeid}
                    companyvbeid={companyvbeid}
                    data={customerData}
                    count={customerCount}
                  />
                )}

                {tab !== "usecase" && (
                  <ViewButtons
                    label="Partners"
                    handleClick={handleClick}
                    vbeid={vbeid}
                    companyvbeid={companyvbeid}
                    data={partnerData}
                    count={partnerCount}
                  />
                )}

                {tab !== "usecase" && (
                  <ViewButtons
                    label="Awards"
                    handleClick={handleClick}
                    vbeid={vbeid}
                    companyvbeid={companyvbeid}
                    data={awardData}
                    count={awardCount}
                  />
                )}

                {/* {tab !== "caseStudy" && tab !== "usecase" && (
                  <ViewButtons
                    label="Success Stories"
                    handleClick={handleClick}
                    vbeid={vbeid}
                    companyvbeid={companyvbeid}
                    data={caseStudyData}
                    count={caseStudyCount}
                  />
                )} */}

                {/* {tab !== "usecase" && (
                  <ViewButtons
                    label="Resources"
                    handleClick={handleClick}
                    vbeid={vbeid}
                    companyvbeid={companyvbeid}
                    data={useCaseData}
                    count={usecaseCount}
                  />
                )} */}
              </div>
            </div>
          )}
      </div>
    </>
  );
}
