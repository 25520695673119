import { useMutation } from "@apollo/client";

import { IconButton } from "@material-ui/core";

import { REMOVE_PRODUCT_FROM_PROJECT } from "../../queries/query";

import DeleteIcon from "@material-ui/icons/Delete";
import PrimaryButton from "../../../common/PrimaryButton";

export default function VendorActions({
  id,
  pvbeid,
  refetch,
  messageOption,
  onMessageClick,
}) {
  const [removeMember, { loading }] = useMutation(REMOVE_PRODUCT_FROM_PROJECT, {
    onCompleted: (e) => {
      refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const onMessage = () => {
    onMessageClick();
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {messageOption && (
        <PrimaryButton
          label="Message Supplier"
          onClick={onMessage}
          variant="contained"
        />
      )}

      <IconButton
        disabled={loading}
        edge="end"
        aria-label="delete"
        onClick={() => {
          removeMember({
            variables: { input: { vbeid: id, listvbeid: [pvbeid] } },
          });
        }}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
}
