import clsx from "clsx";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { getHoveredGroupItems } from "../../../functions/helper";

import { productMenu } from "./groups";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useStyles } from "./styles";
import { Button } from "@material-ui/core";

export default function ItemButton({
  title,
  groups,
  topics,
  handleDrawerClose,
}) {
  const classes = useStyles();
  const history = useHistory();

  let mainList = groups;
  if (title === "Services") {
    mainList = topics;
  }

  const [mouseHover, setMouseHover] = useState(false);
  const [groupHover, setGroupHover] = useState(false);
  const [hoveredGroup, setHoveredGroup] = useState("");

  const groupContentStyles = clsx({
    [classes.dropdownGroupContent]: true,
    [classes.techGroupContent]:
      hoveredGroup === "Technology" || hoveredGroup === "Managed Services"
        ? true
        : false,
  });

  const onMouseHover = () => {
    setMouseHover(true);
  };

  const onMouseLeave = () => {
    setMouseHover(false);
    setGroupHover(false);
  };

  const onGroupClick = (vbeid) => {
    let typeValue = title.toLowerCase();

    handleDrawerClose("", "close");

    history.push("/group/" + typeValue + "/" + vbeid);
  };

  const onGroupHover = (item) => {
    if (item === "Business Functions") {
      item = "Business Function";
    } else if (item === "Business Verticals") {
      item = "Business Vertical";
    }

    setHoveredGroup(item["n.name"] || item);
    setGroupHover(true);
  };

  const getItems = () => {
    const menuItems = title === "Products" ? productMenu : groups;

    return menuItems.map((item) => {
      return (
        <div
          className={classes.dropdownItem}
          onMouseOver={() => onGroupHover(item)}
          onClick={() => {
            if (title === "Services") {
              onGroupClick(item["n.vbeid"]);
            }
          }}
        >
          {title === "Products" ? item : item["n.name"]}
        </div>
      );
    });
  };

  const getFirstColumnItems = () => {
    let groupItems = getHoveredGroupItems(hoveredGroup, mainList);

    return groupItems.map((group, index) => {
      return index < groupItems.length / 2 ? (
        <div
          className={classes.groupItem}
          onClick={() => {
            onItemClick(group["n.vbeid"]);
          }}
        >
          {group["n.name"]}
        </div>
      ) : null;
    });
  };

  const getSecondColumnItems = () => {
    let groupItems = getHoveredGroupItems(hoveredGroup, mainList);

    return groupItems.map((group, index) => {
      return index >= groupItems.length / 2 ? (
        <div
          className={classes.groupItem}
          onClick={() => {
            onItemClick(group["n.vbeid"]);
          }}
        >
          {group["n.name"]}
        </div>
      ) : null;
    });
  };

  const getGroupItems = () => {
    let groupItems = getHoveredGroupItems(hoveredGroup, mainList);

    return returnItems(groupItems);
  };

  const returnItems = (groupItems) => {
    return groupItems.map((group) => {
      return (
        <div
          className={classes.groupItem}
          onClick={() => {
            onItemClick(group["n.vbeid"]);
          }}
        >
          {group["n.name"]}
        </div>
      );
    });
  };

  const onItemClick = (vbeid) => {
    let typeValue = title.toLowerCase();

    handleDrawerClose("", "close");

    if (title === "Products") {
      history.push("/group/" + typeValue + "/" + vbeid);
    } else {
      history.push("/topic/" + typeValue + "/" + vbeid);
    }

    setMouseHover(false);
    setGroupHover(false);
  };

  return (
    <>
      <div
        className={classes.dropdown}
        onMouseOver={onMouseHover}
        onMouseLeave={onMouseLeave}
      >
        <Button className={classes.dropbtn} endIcon={<ExpandMoreIcon />}>
          <div>{title}</div>
        </Button>

        {mouseHover && (
          <div className={classes.dropdownContent}>{getItems()}</div>
        )}

        {groupHover && (
          <div className={groupContentStyles}>
            {hoveredGroup === "Technology" ||
            hoveredGroup === "Managed Services" ? (
              <div className={classes.groupsList}>
                <div className={classes.groupColumn}>
                  {getFirstColumnItems()}
                </div>

                <div className={classes.groupColumn}>
                  {getSecondColumnItems()}
                </div>
              </div>
            ) : (
              getGroupItems()
            )}
          </div>
        )}
      </div>
    </>
  );
}
