import { useState } from "react";

import {
  Paper,
  Grid,
  Typography,
  FormControl,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

import TemplateTableContainer from "./templateList";

import { GET_PROJECT, GET_TEMPLATE_BY_PROJECT } from "../../queries/query";

import { useQuery } from "@apollo/client";

import { useParams, useHistory } from "react-router-dom";

import { useStyles } from "./styles";
import PageButtons from "../createProject/WorkPackage/PageButtons";

const ButtonGroup = ({ id, loading, templateType, onClick }) => {
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            if (templateType === "response") {
              history.push("/other-templates/" + id);
              onClick();
            } else {
              history.push("/update-requirements/" + id);
            }
          }}
        >
          Back
        </Button>
      </Grid>

      <Grid item xs={5}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            if (templateType === "qna") {
              history.push("/response-templates/" + id);
              onClick();
            } else {
              history.push("/review-project/" + id);
            }
          }}
          disabled={loading}
        >
          {templateType === "qna" ? "Next" : "Review & Submit"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default function MapOtherTemplate() {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  let pathname = history.location.pathname.split("/");

  const [for_, setFor_] = useState("all");
  const [templateType, setTemplateType] = useState(
    pathname[1] === "response-templates" ? "response" : "qna"
  );

  const { data: projectData } = useQuery(GET_PROJECT, {
    variables: { input: { vbeid: id }, fetchPolicy: "no-cache" },
  });

  const { data, refetch } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    variables: {
      input: { vbeid: id, typelist: ["response_template", "qa_template"] },
    },
  });

  const onButtonClick = () => {
    if (templateType === "qna") {
      setTemplateType("response");
    } else {
      setTemplateType("qna");
    }
  };

  const getSelectedTemplates = () => {
    let selectedTemplate = [];

    data.getTemplatesByProjectID.forEach((template) => {
      if (
        template.type === "response_template" &&
        templateType === "response"
      ) {
        selectedTemplate = template;
      }
      if (template.type === "qa_template" && templateType === "qna") {
        selectedTemplate = template;
      }
    });

    return selectedTemplate;
  };

  return (
    <Grid container alignItems="center" className={classes.otherTemplateRoot}>
      <Grid item xs={12}>
        <Grid container className={classes.templatelist}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.textFieldLabel}
            >
              {templateType === "qna"
                ? "Q & A Templates (Select one)"
                : "Response Templates (Select one)"}
            </Typography>
          </Grid>
        </Grid>

        <Paper elevation={1} className={classes.templatelistPaper}>
          <Grid container>
            <Grid item xs={6}></Grid>

            <Grid item xs={6}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="for"
                  name="for"
                  value={for_}
                  onChange={(e) => {
                    setFor_(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio size="small" />}
                    label="All"
                    className={classes.for}
                  />

                  <FormControlLabel
                    value="public"
                    control={<Radio size="small" />}
                    label="vBridge Hub"
                    className={classes.for}
                  />

                  <FormControlLabel
                    value="company"
                    control={<Radio size="small" />}
                    label="My Company "
                    className={classes.for}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {projectData && data && (
              <Grid item xs={12}>
                <TemplateTableContainer
                  id={
                    templateType === "qna" ? "qa_template" : "response_template"
                  }
                  for={for_ === "all" ? ["public", "company"] : [for_]}
                  status="published"
                  refetch={refetch}
                  selectedTemplate={
                    data?.getTemplatesByProjectID ? getSelectedTemplates() : []
                  }
                  templateType={templateType}
                  projectData={projectData?.getProjectByIDV1}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>

      {projectData && (
        <Grid item xs={12} style={{ marginTop: "32px" }}>
          {projectData?.getProjectByIDV1.type === "service-workpackage" ? (
            <PageButtons
              nextLocation="otherTemplate"
              nextDisabled={false}
              prevDisabled={false}
              templateType={templateType}
              onClick={onButtonClick}
            />
          ) : (
            <ButtonGroup
              id={id}
              templateType={templateType}
              onClick={onButtonClick}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}
