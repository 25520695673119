import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Grid,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router";

import { VBRIDGEHUB_MEDIAURL } from "../../constants";

import { useStyles } from "./appBarStyles";

import BusinessIcon from "@material-ui/icons/Business";

export default function SearchResultList({
  result,
  handleClose,
  type,
  onSelect,
  toggleValue,
  handleDrawerClose,
}) {
  const classes = useStyles();
  const history = useHistory();

  let productResults = result.filter((item) => {
    return item.index === "product";
  });

  let categoryResults = result.filter((item) => {
    return item.index === "topic" || item.index === "group";
  });

  let companyResults = result.filter((item) => {
    return item.index === "company";
  });

  let skillResults = result.filter((item) => {
    return item.index === "skill";
  });

  const handleClick = (result) => {
    if (type !== "service") {
      handleClose();

      if (
        result.index === "topic" ||
        result.index === "group" ||
        result.index === "skill"
      ) {
        let typeValue = "products";

        if (toggleValue === "services" || result.index === "skill") {
          typeValue = "services";
        }

        handleDrawerClose("", "close");

        history.push("/" + result.index + "/" + typeValue + "/" + result.vbeid);
      } else {
        history.push("/" + result.index + "/" + result.vbeid);
      }
    } else {
      onSelect(result);
    }
  };

  const getProducts = (results) => {
    if (results.length !== 0) {
      return results.map((r) => (
        <ListItem
          key={r.vbeid}
          onClick={() => {
            handleClick(r);
          }}
          button
          dense={true}
          className={classes.searchResultItem}
        >
          {r.index !== "topic" && r.index !== "group" && r.index !== "skill" && (
            <ListItemAvatar>
              <Avatar
                className={classes.searchAvatar}
                aria-describedby="avatar-popover"
                variant="square"
                size="small"
                alt={<BusinessIcon />}
                src={
                  r.image
                    ? VBRIDGEHUB_MEDIAURL + "/" + r.image
                    : VBRIDGEHUB_MEDIAURL + "/" + r.companylogo
                }
              >
                <BusinessIcon />
              </Avatar>
            </ListItemAvatar>
          )}

          <div className={classes.listItemStyle}>
            <div className={classes.listItemTextMain}>
              <ListItemText
                primary={r.name}
                secondary={
                  r.index !== "topic" &&
                  r.index !== "group" &&
                  r.index !== "skill" &&
                  r.index !== "company" &&
                  (`by ${r.companyname}` || "-")
                }
                className={classes.listItemText}
              />

              <ListItemText
                className={classes.indexTextRoot}
                primary={
                  <Typography
                    variant="caption"
                    display="block"
                    color="primary"
                    className={
                      r.index === "company"
                        ? classes.companyText
                        : classes.indextext
                    }
                  >
                    {r.index !== "topic" &&
                      r.index !== "group" &&
                      r.index !== "skill" &&
                      (r.type ? r.type : r.index)}
                  </Typography>
                }
              />
            </div>

            {r.shortdescription && (
              <div
                className={
                  r.index === "topic" ||
                  r.index === "group" ||
                  r.index === "skill"
                    ? classes.shortDescriptionStyle
                    : classes.shortDescForProduct
                }
              >
                {r.shortdescription}
              </div>
            )}
          </div>
        </ListItem>
      ));
    } else {
      return <div className={classes.noResultsText}>No results found</div>;
    }
  };

  return (
    <div className={classes.resultList}>
      <Grid container className={classes.resultListMain}>
        <Grid item md={categoryResults.length !== 0 ? 6 : 12}>
          {productResults.length !== 0 && (
            <div className={classes.resultMainDiv}>
              <div className={classes.headingText}>Products / Services</div>

              <div className={classes.resultListDiv}>
                <div>{getProducts(productResults)}</div>
              </div>
            </div>
          )}
        </Grid>

        <Grid
          item
          md={
            categoryResults.length !== 0 && productResults.length !== 0 ? 6 : 12
          }
        >
          {categoryResults.length !== 0 && (
            <div className={classes.resultMainDiv}>
              <div className={classes.headingText}>Categories</div>

              <div className={classes.categoryList}>
                <div className={classes.resultListDiv}>
                  <div>{getProducts(categoryResults)}</div>
                </div>
              </div>
            </div>
          )}
        </Grid>

        <Grid
          item
          md={companyResults.length !== 0 && skillResults.length !== 0 ? 6 : 12}
        >
          {companyResults.length !== 0 && (
            <div className={classes.resultMainDiv}>
              <div className={classes.headingText}>Company</div>

              <div className={classes.resultListDiv}>
                <div>{getProducts(companyResults)}</div>
              </div>
            </div>
          )}
        </Grid>

        <Grid item md={companyResults.length !== 0 ? 6 : 12}>
          {skillResults.length !== 0 && (
            <div className={classes.resultMainDiv}>
              <div className={classes.headingText}>Skills</div>

              <div className={classes.categoryList}>
                <div className={classes.resultListDiv}>
                  <div>{getProducts(skillResults)}</div>
                </div>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
