import { Grid, Tooltip, withStyles } from "@material-ui/core";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";

import { useStyles } from "../groups2.0/styles";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#eee",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function CategoryDropDown({ data, title, type, length }) {
  const classes = useStyles();
  const history = useHistory();
  const { typeValue } = useParams();

  const groupBadges = clsx({
    [classes.typeBadges]: true,
    [classes.groupTypes]: true,
  });

  const categoryBadges = clsx({
    [classes.typeBadges]: true,
    [classes.categoryTypes]: true,
  });

  return data.map((val, index) => {
    if (index >= length) {
      return (
        <LightTooltip title={title}>
          <Grid>
            <div
              className={type === "group" ? groupBadges : categoryBadges}
              onClick={() => {
                history.push("/topic/" + typeValue + "/" + val.vbeid);
              }}
            >
              {val.name.toUpperCase()}
            </div>
          </Grid>
        </LightTooltip>
      );
    } else {
      return null;
    }
  });
}
