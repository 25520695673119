import { useStyles } from "./styles";

import InfoGrid from "./tabs/companyInfo/InfoGrid";
import { ENTERPRISE } from "../../../../functions/helper";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { getCompanyVerticals } from "./query";

const countries = require("../../../../data/countriesdata.json");
const locationStates = require("../../../../data/states_bycountries.json");

export default function CompanyInformation({ data, orgType, isBuyer }) {
  const classes = useStyles();
  const { id } = useParams();

  const { data: companyVerticals } = useQuery(getCompanyVerticals, {
    variables: { input: { vbeid: id } },
  });

  const {
    founded,
    location,
    round,
    // amount,
    businessvertical,
    location_state,
    employeeCount,
  } = data;

  let verticalName = [];

  if (companyVerticals) {
    companyVerticals.listCompanyVertical.forEach((companyVertical) => {
      businessvertical.forEach((vertical) => {
        if (companyVertical.vbeid === vertical) {
          verticalName.push(companyVertical.name);
        }
      });
    });
  }

  let countryName = "";
  countries.forEach((country) => {
    if (location === country.countryname) {
      countryName = country.countryname;
    }
  });

  let stateName = "";
  locationStates.forEach((stateValue) => {
    if (location_state === stateValue["ste.name"]) {
      stateName = stateValue["ste.name"];
    }
  });

  let headquarterName =
    countryName && stateName ? stateName + ", " + countryName : location;

  return data ? (
    <div className={classes.infoGrid}>
      {orgType !== ENTERPRISE && !isBuyer && (
        <>
          <InfoGrid title="Founded Year" data={founded || "-"} />

          <div className={classes.divider}></div>
        </>
      )}

      <InfoGrid title="Headquarters" data={headquarterName || "-"} />

      {isBuyer && (
        <>
          <div className={classes.divider}></div>
          <InfoGrid title="Vertical" data={verticalName || "-"} />
        </>
      )}

      {orgType !== ENTERPRISE && !isBuyer && (
        <>
          {/* <div className={classes.divider}></div> */}

          {/* <InfoGrid title="Funding Stage" data={round || "-"} /> */}

          <div className={classes.divider}></div>

          {/* <InfoGrid title="Funding Amount (MN $)" data={amount || "-"} /> */}

          <InfoGrid title="Employee Count" data={employeeCount || "-"} />

          {/* <div className={classes.divider}></div> */}
        </>
      )}
    </div>
  ) : null;
}
