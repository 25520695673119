import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { updateProduct } from "./query";

import EditLongDescription from "./updateLongDescription";

export default function LongDescriptionComponent({
  vbeid,
  longContent,
  queryName,
  updateCache,
  fetch,
  // UpdateCasestudyCache,
  UpdateStatusCache,
}) {
  //query

  const [postDescriptionMutation] = useMutation(queryName, {
    onCompleted: (e) => {
      if (e.updateProduct) {
        updateCache(e);
        fetch();
      } else {
        updateCache(e);
        UpdateStatusCache(e);
      }
    },
  });

  ///function for the longdescription and update

  const saveData = (data) => {
    postDescriptionMutation({
      variables: {
        input: {
          longdescription: data,
          vbeid: vbeid,
          longdescriptionupdated: data === "" ? false : true,
        },
      },
    });
  };

  return (
    <EditLongDescription
      longdescription={longContent}
      id={vbeid}
      isReadOnly={false}
      saveData={saveData}
    />
  );
}
