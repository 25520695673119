import { FormControl, Grid } from "@material-ui/core";

import { useState } from "react";
import { timeValuesList } from "../../../../../functions/helper";

import InputField from "../../../common/InputField";
import SelectField from "../../../common/SelectField";

export default function TimeSlotsForm({
  values,
  onAddTimeSlot,
  onChange,
  index,
}) {
  let today = new Date().toISOString().split("T")[0];

  const [formDate, setFormDate] = useState(values?.date || today);
  const [formTimeFrom, setTimeFrom] = useState(values?.fromTime || "");
  const [formTimeTo, setTimeTo] = useState(values?.fromTo || "");

  const onValueChange = (e, field) => {
    if (field === "date") {
      setFormDate(e.target.value);
    } else if (field === "fromTime") {
      setTimeFrom(e.target.value);
    } else if (field === "toTime") {
      setTimeTo(e.target.value);
    }

    onChange(e.target.value, field, index);
  };

  return (
    <>
      <Grid item md={4} style={{ margin: "8px 0" }}>
        <FormControl fullWidth={true}>
          <InputField
            label="Interview Date"
            onChange={(e) => onValueChange(e, "date")}
            value={formDate}
            type="date"
          />
        </FormControl>
      </Grid>

      <Grid item md={3} style={{ margin: "8px 0" }}>
        <FormControl fullWidth={true} variant="outlined">
          <SelectField
            label="Time - From *"
            value={formTimeFrom}
            onChange={(e) => onValueChange(e, "fromTime")}
            options={timeValuesList}
          />
        </FormControl>
      </Grid>

      <Grid item md={3} style={{ margin: "8px 0" }}>
        <FormControl fullWidth={true} variant="outlined">
          <SelectField
            label="Time - To *"
            value={formTimeTo}
            onChange={(e) => onValueChange(e, "toTime")}
            options={timeValuesList}
          />
        </FormControl>
      </Grid>
    </>
  );
}
