import React, { useState } from "react";
import { useParams } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";

import { useQuery, useMutation, useApolloClient } from "@apollo/client";

import SimpleSnackbar from "./snackbar";
import SimpleErrorSnackbar from "./errorsnackbar";

import { listGroupTopics, mapTopicToGroup, searchTopic } from "./query";

import "./groups.css";
import { useStyles } from "./styles";

const topics = require("../../../data/topics.json");

export default function MapTopic() {
  const classes = useStyles();
  const [values, setValues] = useState("");

  const { id } = useParams();
  const client = useApolloClient();

  const [
    mapTopicToGroupMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(mapTopicToGroup, {
    onCompleted: (e) => {
      UpdateTopicGroupCache(e);
    },
  });

  const createTopicToGroup = () => {
    mapTopicToGroupMutation({
      variables: {
        input: {
          topicid: values,
          groupid: id,
        },
      },
    });
  };

  useQuery(searchTopic);

  useQuery(listGroupTopics, {
    variables: { input: { groupid: id } },
  });

  //////update cache for create topics from group
  const UpdateTopicGroupCache = (e) => {
    const { listTopicsByGroupId } = client.readQuery({
      query: listGroupTopics,
      variables: { input: { groupid: id } },
    });

    let listGroupTopicsCache = { ...listTopicsByGroupId };
    let topics = [...listGroupTopicsCache.topics];

    topics.unshift({ ...e.topictogroup });

    client.writeQuery({
      query: listGroupTopics,
      data: {
        listTopicsByGroupId: {
          __typename: "topicsresult",
          listTopicsByGroupId: { topics: topics },
        },
      },
      variables: { input: { groupid: id } },
    });
  };

  return (
    <>
      {mutationError && <SimpleErrorSnackbar />}
      {mutationLoading && <SimpleSnackbar />}

      <Box p={1}>
        <Grid container spacing={1} style={{ alignItems: "center" }}>
          <Grid item md={12}>
            <Autocomplete
              options={topics}
              getOptionLabel={(option) => option["n.name"]}
              clearOnBlur="true"
              onChange={(event, newvalue) => {
                setValues(newvalue ? newvalue["n.vbeid"] : null);
              }}
              renderOption={(option) => (
                <div className={classes.optionLabel}>{option["n.name"]} </div>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Topic"
                  variant="outlined"
                  margin="dense"
                  id="input"
                />
              )}
            />
          </Grid>

          <Grid item md={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={values ? false : true}
              size="small"
              onClick={createTopicToGroup}
            >
              Map
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
