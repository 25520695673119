import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { green } from '@material-ui/core/colors';
import { useMutation, gql, useQuery, useApolloClient } from '@apollo/client';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import BackupIcon from '@material-ui/icons/Backup';
import * as yup from 'yup';
import { useFormik } from 'formik';

import SimpleSnackbar from "./createtopicsnackbar";
import ErrorSnackbar from "./errorsnackbar";
import Avatar from "@material-ui/core/Avatar";
import { VBRIDGEHUB_MEDIAURL } from '../../../constants';
import { nanoid } from 'nanoid'
import {BootstrapInput} from '../../../theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
 

  button: {
    marginTop:"7%" ,
    marginRight: "10%" },
  
  formcontrol:{
    width:190,
    margin: theme.spacing(0)
  
  },

  input1: {
    width: 520,
    fontSize: "3em",
    
  },

  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },

  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    }},

    description: {
      height: 150,
      fontSize: "3em"
    },
    descriptionfield: {
      width: 520,
      fontSize: "3em"
    },

    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
}));

export function NewTopic() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [ setLoading] = React.useState(false);
  const timer = React.useRef();
  const [previewurl, setPreviewurl] = React.useState('');
const client=useApolloClient();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  },[]);

  const handleButtonClick = () => {
    if (!mutationLoading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
        handleClose();
      }, 2000);  
    } 
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const submit = event => {
  //   event.preventDefault();
  // };



  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const signed = gql` 
   mutation upload($input:s3input!) {
      getSignedURL(input: $input) {
        signedurl
      }
    }`;

  async function Uploadtopiclogo(e){
          
    const url=e.getSignedURL.signedurl;      
    const response = await fetch(url, { method: "PUT", body:document.getElementById('vbridgeGroupLogo').files[0] });
    formik.values.image =  response.url.split('?')[0].split('media-dev.vbridgehub.com')[1];
    setPreviewurl(VBRIDGEHUB_MEDIAURL+formik.values.image);
           }
  
  
  const [uploadmutation] = 
   useMutation(signed,{onCompleted:(e)=>{Uploadtopiclogo(e)}});
  
  
      async function uploadlogo(e){
           e.preventDefault();
           const nid=nanoid(8);
           const fext=".png";
           const fname= nid.concat(fext)

          await uploadmutation(
            {
              variables:{
                input:{
                  method: 'put',
                  entity: 'topicimage',
                  filetype: 'logo',
                  filename: fname,                      }
                        }
            });
          }

const posttopic = gql`
mutation addtopic($input:topicinput! ) {
  addtopic(input:$input) {
    alias
    id
    name
    type
  }
}`; 

const [posttopicmutation,{loading: mutationLoading, error: mutationError, data }] =  useMutation(posttopic,{onCompleted:(e)=>{updateCreateTopicCache(e)}});

function Createtopic(e){
  e.preventDefault();

   posttopicmutation(
    {
      variables:{
        input:{
        alias:formik.values.alias,
        description:formik.values.description,
        image:formik.values.image,
        name:formik.values.name,
        type:formik.values.type
        }
                }
    }
  );
  handleButtonClick(); 
  } 

  //const cname = '^\s*([0-9a-zA-Z]+)\s*$';
 
  const validationSchema = yup.object().shape({
    name: yup.string()
    .required("*Name required"),
  
    type: yup.string()
    .required("*Name required"),
 
    description: yup.string()
       .required("*Field required"),

  });
 
 const formik = useFormik({
   initialValues: {
     name: '',
     description:'',
     type:'',
     image:'',
     alias:[]
   },
   validationSchema: validationSchema
   
 });

const listtopics=gql`
query listing { 
  listtopic(input:{}) {
    total
    topics {
      alias
      id
      name
      type
    }
  }
}`;

 useQuery(listtopics);



function updateCreateTopicCache(e)
{


const {listtopic} =  client.readQuery({query:listtopics,
variables:{input:{}}});

let listTopicCache = {...listtopic};

listTopicCache.total = listTopicCache.total+1
let topics=[...listTopicCache.topics]
topics.unshift({...e.addtopic})

client.writeQuery({query:listtopics,
data:{
  listtopic:{
__typename: "topicsresult",
listtopic: {'total':listTopicCache.total+1,
  topics:topics}}
},
variables:{input:{}}});

}


  
    
  return (

    <div>
        

      <Grid container>
      <Grid item xs={3}>
      
      {mutationError && <ErrorSnackbar/>}
      {data? <SimpleSnackbar/> : null}
      
        </Grid>
        </Grid>

      <Button onClick={handleClickOpen('paper')} className={classes.button} variant="outlined" color="primary">
        Create Topic</Button>    

<form>
    
      <Dialog fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Grid fullWidth>
        <DialogTitle id="scroll-dialog-title">Create Topic</DialogTitle>
        
        <DialogContent dividers={scroll === 'paper'}>

       <div className={classes.root}>
    
       <Grid container>
         <Grid item>
        <FormControl  className={classes.margin}>
    
          <InputLabel  required={true}  shrink  htmlFor="my-input" focused={true}>Topic Name</InputLabel>
            <BootstrapInput   required={true} id="my-input" aria-describedby="my-helper-text"
            value={formik.values.name}
            onChange={formik.handleChange('name')}
            onBlur={formik.handleBlur('name')}
            />

        </FormControl>
</Grid>
       <Grid item style={{color:"red"}}>
          {formik.touched.name && formik.errors.name ? (
             <div className="error-message">
               {formik.errors.name}
               </div>
           ): null}
          </Grid>
      </Grid>

          <Grid container>
          <Grid item>
        <FormControl  className={classes.margin}>
         
          <InputLabel  required={true}  shrink  htmlFor="my-input" focused={true}>Alias / Also Called as</InputLabel>
            <BootstrapInput   required={true} id="my-input" aria-describedby="my-helper-text"
            value={formik.values.alias}
            onChange={formik.handleChange('alias')}
            onBlur={formik.handleBlur('alias')}
            />
        </FormControl>
        </Grid>

           </Grid>
        
            

            <Grid item xs={8} sm={12}>

          <FormControl className={classes.formcontrol}>
          <InputLabel  required={true}  shrink  htmlFor="my-input" focused={true}>Topic Type</InputLabel>

          <Select  id="grouped-native-select" input={<BootstrapInput />}
          value={formik.values.type} onChange={formik.handleChange('type') }
          onBlur={formik.handleBlur('type')}
        >
          <option value="category">Category</option>

        </Select>
          
        </FormControl>
        </Grid>
           

        <Grid container md={12} spacing={2}>
        <Grid item md={10}>
  <Typography>
  <FormHelperText>Upload Logo *
    <Button
      variant="contained"
      color="primary"
      component="label"
      style={{ float: "right" }}
    >
      <BackupIcon/>
      <input type="file" id="vbridgeGroupLogo"
       onChange={uploadlogo}
       hidden />
    </Button>
    </FormHelperText>
  </Typography>
          </Grid>

          <Grid item md={2}>
          <Avatar
              
              variant="square"
              src={previewurl}
              className={classes.mainlogosize}
            />
</Grid>
          </Grid>
<div>
       
      <FormControl className={classes.description}>
      <InputLabel  required={true} style={{marginTop:'-3%'}} shrink  htmlFor="my-input" focused={true}>Description</InputLabel>

          <TextField className={classes.descriptionfield} value={formik.values.description}
            onChange={formik.handleChange('description')}
            onBlur={formik.handleBlur('description')}
            multiline
            rows={4}
            rowsMax={50}
            variant="outlined" 
          />
        </FormControl>

        </div>
    </div>
        </DialogContent>
        </Grid>

        <DialogActions>
          <Button  color="primary" variant="contained" 
          onClick={handleClose}>
            Cancel
          </Button>

          <div className={classes.wrapper}>
          <Button  color="primary" variant="contained"
           type="submit" onClick={Createtopic}
           disabled={!(formik.isValid && formik.dirty)}>
            ADD TOPIC
          </Button>
          {mutationLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
    </div>

        </DialogActions>
      </Dialog>
      </form>
    </div>
  
  );
}

export default NewTopic;