/**
 * Styles for Group and category pages
 */

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    color: "#0e3046",
    lineHeight: "1.8",
  },
  square: {
    width: "65%",
    height: "65%",
    marginTop: "10%",
  },
  descriptionfield: {
    width: 520,
    fontSize: "3em",
  },
  savebutton: {
    marginTop: "2%",
  },
  tabRoot: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#fff",
  },
  tabs: {
    fontSize: "0.8rem",
    textTransform: "capitalize !important",
    "& span": {
      textTransform: "capitalize",
    },
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  productHead: {
    cursor: "pointer",
    color: "#4179BD",
    fontSize: "0.85rem !important",
  },

  productHead2: {
    color: "#4179BD",
    fontSize: "0.85rem !important",
  },

  groupName2: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: "600",
  },

  groupName: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: "600",
    cursor: "pointer",
  },
  emptyDesc: {
    color: "#bebebe",
    fontSize: "0.75rem",
    fontStyle: "italic",
  },

  overviewTabHeading: {
    fontSize: "1rem",
    fontWeight: "600",
  },
  productName: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: "600",
    color: "#4179BD",
  },
  typeBadge: {
    fontSize: "0.52rem",
    padding: theme.spacing(0, 0.5),
    fontWeight: "601",
    border: "1px solid #999",
    borderRadius: "5px",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.6),
    color: "#999",
    height: "15%",
  },

  typeBadges: {
    fontSize: "0.52rem",
    padding: theme.spacing(0.5, 0.5),
    fontWeight: "601",
    border: "1px solid #999",
    borderRadius: "5px",
    color: "#999",
  },

  CategoryList: {
    // marginTop: "5px",
    padding: "8px",
  },

  productType: {
    padding: "2px 4px",
  },
  categoryType: {
    border: "1px solid #413287",
    color: "#413287",
    cursor: "pointer",
  },
  categoryTypes: {
    border: "1px solid #413287",
    color: "#8C2785",
    cursor: "pointer",
    margin: "12px",
  },

  groupType: {
    border: "1px solid #8C2785",
    color: "#8C2785",
    cursor: "pointer",
  },

  groupTypes: {
    border: "1px solid #8C2785",
    color: "#8C2785",
    cursor: "pointer",
  },
  actionButton: {
    fontSize: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: theme.spacing(2),
    },
  },
  loadMoreOption: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    "& button": {
      float: "none",
      textTransform: "capitalize",
    },
  },
  companyName: {
    fontSize: "0.85rem",
    fontWeight: "500",
    cursor: "pointer",
    color: "#4179BD",
    "&:hover, &:focus": {
      color: "#232961",
    },
    "&:active": {
      color: "#232961",
    },
  },
  awardName: {
    fontSize: "0.85rem",
    fontWeight: "500",
  },
  filterGroup: {
    marginTop: theme.spacing(6.5),
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    maxWidth: "20% !important",
  },
  filterTitle: {
    fontSize: "0.85rem",
    fontWeight: "500",
    marginBottom: theme.spacing(1),
  },
  filterList: {
    marginLeft: theme.spacing(1),
    display: "grid",
  },
  list: {
    padding: 0,
    "& li": {
      display: "block",
    },
  },
  companyDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: theme.spacing(0),
  },
  location: {
    fontSize: "0.85rem",
    marginLeft: "24px !important",
  },
  skillLocation: {
    fontSize: "0.85rem",
  },
  fundingStage: {
    fontSize: "0.85rem",
  },
  iconDiv: {
    display: "flex",
    marginLeft: theme.spacing(1.5),
    "& img": {
      width: "20px",
      margin: theme.spacing(0, 2, 0, 0),
    },
  },
  ndaMark: {
    fontWeight: "800 !important",
    fontSize: "0.8rem",
    color: "#4179BD",
    marginRight: theme.spacing(2),
  },
  partnerMark: {
    fontWeight: "800 !important",
    fontSize: "0.8rem",
    color: "#333",
    backgroundColor: "#FADA5E",
  },
  spacing: {
    margin: theme.spacing(0, 2, 0, 1),
  },
  listItemRoot: {
    display: "flex",
    alignItems: "center",
  },
  moreInfoDiv: {
    margin: theme.spacing(1, 1, 1, 7),
  },
  selectedInfo: {
    fontWeight: "500 !important",
    color: theme.palette.primary.main,
    backgroundColor: "#eee",
  },
  selectedItem: {
    fontSize: "1rem",
    fontWeight: "500",
  },
  dataList: {
    whiteSpace: "normal",
    "& span": {
      fontSize: "0.7rem",
      width: "70px",
    },
  },
  noDataText: {
    color: "#999",
    fontStyle: "italic",
    margin: 0,
    "& span": {
      fontSize: "0.85rem !important",
    },
  },
  noDataItem: {
    padding: "0 !important",
  },
  avatarRoot: {
    display: "flex",
    justifyContent: "center",
    "& img": {
      objectFit: "contain",
    },
  },
  groupRoot: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
  },
  drawerOpenGroupRoot: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    marginLeft: 250,
  },
  subHeading: {
    color: theme.palette.secondary.main,
    textAlign: "left",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    textTransform: "capitalize",
  },
  addTopic: {
    marginTop: theme.spacing(2),
  },
  paperRoot: {
    padding: theme.spacing(1, 2),
  },
  optionLabel: {
    fontSize: "0.8rem",
  },
  viewMoreOption: {
    display: "flex",
    fontSize: "0.7rem",
    color: "#232961",
    cursor: "pointer",
    height: "fit-content",
  },
  viewMoreBtn: {
    float: "none",
    width: "65px",
    "& span": {
      fontSize: "0.6rem",
    },
  },
  searchTextField: {
    margin: theme.spacing(0, 1, 0.5, -1),
    width: "85%",
  },
  innerProgressBar: {
    width: "60%",
    marginTop: "-13px",
    marginLeft: "-3px",
  },
  scoreText: {
    fontSize: "0.6rem",
    marginTop: "-4px",
    marginBottom: "-4px",
    textAlign: "center",
  },
  scoreDiv: {
    display: "flex",
    justifyContent: "center",
    minWidth: "60px",
  },
  labelCount: {
    fontSize: "0.65rem !important",
    marginLeft: "2px",
    fontWeight: "normal",
  },
  mainTabsDiv: {
    maxWidth: "80%",
    flexBasis: "80%",
  },
  companyDetailsDiv: {
    display: "flex",
    margin: theme.spacing(-0.5, 0, 0.5),
    color: "#4179BD",
    "&:hover, &:focus": {
      color: "#232961",
    },
    "&:active": {
      color: "#232961",
    },
  },
  addList: {
    position: "absolute",
    right: theme.spacing(2),
    marginTop: theme.spacing(1.5),
  },
  subText: {
    fontWeight: "normal",
    marginLeft: theme.spacing(0.75),
    fontSize: "0.85rem",
    color: "#333",
  },
  bySubText: {
    fontWeight: "normal",
    fontSize: "0.85rem",
    color: "#333",
    marginLeft: theme.spacing(0.5),
  },
  descriptionText: {
    fontSize: "0.9rem",
    lineHeight: "1.5",
    paddingTop: theme.spacing(0.5),
    color: "#000",
  },
  productDescText: {
    fontSize: "0.8rem",
    lineHeight: "1.5",
    paddingTop: theme.spacing(0.5),
    color: "#000",
  },
  companyLinks: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  companyInfo: {
    margin: theme.spacing(1, 0),
  },
  viewButtons: {
    display: "block",
    "& button": {
      float: "left",
      textTransform: "capitalize",
      fontSize: "0.7rem",
      padding: theme.spacing(0, 0.5),
      justifyContent: "left",
      fontWeight: "500",
    },
  },
  aboutDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftData: {
    width: "80%",
  },
  companyDetails: {
    display: "flex",
  },

  help: {
    display: "flex",
    justifyContent: "right",

    "& button": {
      padding: "0px 0px",
    },

    "& span": {
      fontSize: "8px",
      textTransform: "none",
      fontWeight: 500,
    },
  },
  reqBtn: {
    margin: "5px 0px 0px 0px !important",
    padding: "0px !important",
    // textTransform: "none",
    minWidth: "20px !important",
    height: "15px !important",
    "& span": {
      padding: "0px 2px",
      fontSize: "9px",
      justifyContent: "center !important",
    },
  },

  HelpIcon: {
    cursor: "pointer",
    padding: "0px 8px",
  },
}));
