import { useQuery } from "@apollo/client";

import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  SwipeableDrawer,
} from "@material-ui/core";

import CustomCkeditor from "../../../../../shared/customckeditor";

import DataPageHeader from "../../../../common/DataPageHeader";

import EmptyDiv from "../../../../companies/company/tabs/lists/emptyDiv";
import { getCompanyById } from "../../../../companies/Company2.0/query";

import { useStyles } from "./styles";

export default function CompanyDetail({ open, company, onClose }) {
  const classes = useStyles();

  const { vbeid } = company;

  const { data, loading } = useQuery(getCompanyById, {
    variables: { input: { vbeid: vbeid } },
  });

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      className={classes.productDetailDiv}
      onClose={onClose}
    >
      <Card square className={classes.productDetailCard} elevation={0}>
        {data && (
          <DataPageHeader
            data={data?.queryCompanybyid}
            isReadOnly={true}
            vbeid={vbeid}
            updateCache={() => {}}
            editName={false}
            editDescription={false}
            editNameClick={() => {}}
            handleNameChange={() => {}}
            saveNameClick={() => {}}
            editDescriptionClick={() => {}}
            handleDescriptionChange={() => {}}
            saveDescriptionClick={() => {}}
            type="company"
            isAdmin={false}
            isBuyer={true}
          />
        )}

        <Divider />

        <CardContent
          className={
            loading ? classes.productDescLoading : classes.productDescription
          }
        >
          {data ? (
            data.queryCompanybyid &&
            data.queryCompanybyid.longdescription !== "" ? (
              <CustomCkeditor
                data={data.queryCompanybyid.longdescription}
                editorId={vbeid}
                isReadOnly={true}
              />
            ) : (
              <EmptyDiv type="description" />
            )
          ) : (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </CardContent>
      </Card>
    </SwipeableDrawer>
  );
}
