import { gql } from "@apollo/client";

export const requestConcierge = gql`
  mutation concierge($input: projectinput!) {
    RequestforConcierge(input: $input) {
      id
    }
  }
`;

export const submitConcierge = gql`
  mutation concierge($input: projectinput!) {
    submitConcierge(input: $input) {
      id
    }
  }
`;

export const listConciergerequests = gql`
  query requests {
    listConcierge {
      remarks
      vbeid
      name
      createdon
      personemailid
      timeline
      id
      description
    }
  }
`;

export const listsuggestedvendors = gql`
  query suggested($input: projectinput!) {
    listSuggestedProductsFromConcierge(input: $input) {
      name
      id
      productname
      productvbeid
      companyname
      companyvbeid
      image
      productdescription
      remarks
    }
  }
`;

///project information
export const projectbyid = gql`
  query project($input: id!) {
    getProjectByID(input: $input) {
      name
      id
      description
      startdate
      enddate
      createdon
      createdby
    }
  }
`;

export const addVendorsToConcierge = gql`
  mutation addproductsintoconcierge($input: projectinput!) {
    addProductsToProjectConcierge(input: $input) {
      id
    }
  }
`;

export const removeVendorsinConcierge = gql`
  mutation productsintoconcierge($input: projectinput!) {
    removeProductsFromProjectConcierge(input: $input) {
      status
      id
    }
  }
`;

export const listVendorsconcierge = gql`
  query vendors($input: projectinput!) {
    listProductsFromProjectConcierge(input: $input) {
      name
      id
      productname
      productvbeid
      companyname
      companyvbeid
      image
      productdescription
    }
  }
`;
