import React, { useEffect, useRef, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useFormik } from "formik";

import { useMutation } from "@apollo/client";

import { useParams } from "react-router-dom";

import SimpleSnackbar from "../shorts/snackbar";
import SimpleErrorSnackbar from "../shorts/errorsnackbar";

import AddNewButton from "../AddNew/AddNewButton";

import { useStyles } from "./styles";

import { postCaseStudy, postUsecase } from "./query";
import { validationSchema } from "./validationSchema";
import FormInput from "./FormInput";

// const verticals = require("../../../../../data/companyVerticals.json");

export default function AddNewForm({ type, updateCache }) {
  const classes = useStyles();
  const { id } = useParams();
  const timer = useRef();

  const [open, setOpen] = useState(false);
  //   const [setLoading] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!mutationLoading) {
      //   setLoading(true);
      timer.current = window.setTimeout(() => {
        // setLoading(false);
        handleClose();
      }, 500);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      url: "",
      businessVertical: [],
      product: "",
    },
    validationSchema: validationSchema,
  });

  const [
    postUsecaseMutation,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(postUsecase, {
    onCompleted: (response) => {
      updateCache(response);
    },
  });

  const [postCaseStudyMutation] = useMutation(postCaseStudy);

  const createUseCase = (e) => {
    e.preventDefault();

    postUsecaseMutation({
      variables: {
        input: {
          name: formik.values.name,
          productid: id,
          url: formik.values.url,
          industryvertical: formik.values.businessVertical,
        },
      },
    });

    handleButtonClick();
  };

  const createCaseStudy = (e) => {
    e.preventDefault();

    postCaseStudyMutation({
      variables: {
        input: {
          name: formik.values.name,
          url: formik.values.url,
          businessvertical: formik.values.businessVertical,
          productid: id,
        },
      },
    });

    handleButtonClick();
  };

  return (
    <div>
      {mutationError && <SimpleErrorSnackbar />}
      {data && <SimpleSnackbar />}

      <AddNewButton name={`Add New ${type}`} handleClick={handleClickOpen} />

      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          className={classes.dialogRoot}
        >
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            Add {type}
          </DialogTitle>

          <DialogContent dividers className={classes.dialogDividers}>
            <DialogContentText>
              Please enter {type} details below
            </DialogContentText>

            <Grid container>
              <Grid item md={10}>
                <FormInput
                  type={type}
                  field="name"
                  value={formik.values.name}
                  onChange={formik.handleChange("name")}
                  onBlur={formik.handleBlur("name")}
                />
              </Grid>

              <Grid item md={12}>
                {formik.touched.name && formik.errors.name ? (
                  <div className={classes.errorMessage}>
                    * {formik.errors.name}
                  </div>
                ) : null}
              </Grid>

              <Grid item md={10}>
                <FormInput
                  type={type}
                  field="url"
                  value={formik.values.url}
                  onChange={formik.handleChange("url")}
                  onBlur={formik.handleBlur("url")}
                />
              </Grid>

              <Grid item md={5}>
                {formik.touched.url && formik.errors.url ? (
                  <div className={classes.errorMessage}>
                    * {formik.errors.url}
                  </div>
                ) : null}
              </Grid>
            </Grid>

            {/* <Grid container>
              <Grid item md={10}>
                <FormInput
                  type={type}
                  field="businessVertical"
                  value={formik.values.businessVertical}
                  onChange={formik.handleChange("businessVertical")}
                  onBlur={formik.handleBlur("businessVertical")}
                  options={verticals}
                />
              </Grid>
            </Grid> */}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              size="small"
              disabled={mutationLoading}
            >
              Cancel
            </Button>

            <Button
              onClick={type === "Solution" ? createUseCase : createCaseStudy}
              type="submit"
              color="primary"
              variant="contained"
              disabled={!(formik.isValid && formik.dirty)}
              size="small"
            >
              {mutationLoading ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : (
                "Add"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
