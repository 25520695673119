import { gql } from "@apollo/client";

export const addQuestion = gql`
  mutation questionMutation($input: discussioninput!) {
    addQuestion(input: $input) {
      id
      question
      totalreplies
      usercompanyid
      createdby
    }
  }
`;

export const listQuestionByProjectProduct = gql`
  query listQuestions($input: discussioninput!) {
    listQuestionByProjectProduct(input: $input) {
      id
      question
      usercompanyid
      createdby
    }
  }
`;

export const addReply = gql`
  mutation replyMutation($input: discussioninput!) {
    addReply(input: $input) {
      id
      reply
      usercompanyid
      createdby
    }
  }
`;
