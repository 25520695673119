import { gql, useQuery } from "@apollo/client";

import Avatar from "@material-ui/core/Avatar";

const userImage = gql`
  query upload($input: s3input!) {
    getSignedURL(input: $input) {
      signedurl
    }
  }
`;

export default function UserAvatar({ imagePath, name }) {
  const { loading, data } = useQuery(userImage, {
    variables: {
      input: {
        method: "get",
        entity: "userimage",
        filetype: "image",
        filename: imagePath.split("/")[2],
        vbeid: imagePath.split("/")[0],
      },
    },
  });

  if (loading) {
    return (
      <Avatar
        aria-describedby="avatar-popover"
        src="https://media-dev.vbridgehub.com/dummy.dummy"
        alt={name}
      ></Avatar>
    );
  }

  return (
    <Avatar
      aria-describedby="avatar-popover"
      alt={name}
      src={data.getSignedURL.signedurl}
    ></Avatar>
  );
}
