import { InputLabel } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { useStyles } from "../styles";

export default function Attachment({ properties, onRemove, onEdit }) {
  const { label } = properties;
  const classes = useStyles();

  return (
    <div className={classes.textField}>
      <InputLabel>
        {label || "Upload File"}

        <span className={classes.itemText}>
          <DeleteIcon onClick={onRemove} />
        </span>

        <span className={classes.itemText}>
          <EditIcon onClick={onEdit} />
        </span>
      </InputLabel>

      <input accept="image/*" id="icon-button-file" type="file" disabled />
    </div>
  );
}
