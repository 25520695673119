import { useMutation, useQuery } from "@apollo/client";
import {
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useParams } from "react-router-dom";

import { GET_MILESTONES, UPDATE_MILESTONE_STATUS } from "./query";
import { useStyles } from "./styles";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useState } from "react";
import { getMilestoneStatus } from "../../../../../functions/helper";
import CandidateActionSlider from "./CandidateActionSlider";

export default function MilestoneTable({ projectData, type }) {
  const classes = useStyles();
  const { id } = useParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [selectedWP, setSelectedWP] = useState("");
  const [actionSlider, setActionSlider] = useState(false);

  const { data, loading, refetch } = useQuery(GET_MILESTONES, {
    variables: {
      input: {
        vbeid: id,
      },
    },
    fetchPolicy: "no-cache",
  });

  const [updateMilestoneStatus, { data: updateData }] = useMutation(
    UPDATE_MILESTONE_STATUS,
    {
      onCompleted: (e) => {
        refetch();
      },
    }
  );

  const getMilestoneCount = (workPackage) => {
    let milestoneResponse = JSON.parse(workPackage.data);

    return milestoneResponse.milestoneList.length;
  };

  const getMilestoneTotalPrice = (workPackage) => {
    let milestonePriceTotal = 0;
    let milestoneResponse = JSON.parse(workPackage.data);

    milestoneResponse.milestoneList.forEach((milestone) => {
      let price = parseInt(milestone.price);
      milestonePriceTotal += price;
    });

    return milestonePriceTotal;
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActionSlider(false);
    setCurrentIndex(null);
    setSelectedWP("");
  };

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentIndex(index);
  };

  const onActionClick = (workPackage, action) => {
    if (action === "uploadPO") {
      setActionSlider(true);
      setSelectedWP(workPackage);
      setAnchorEl(null);
    } else {
      setAnchorEl(null);

      updateMilestoneStatus({
        variables: {
          input: {
            PK: workPackage.PK,
            SK: workPackage.SK,
            status: action,
            remarks: action,
          },
        },
      });
    }
  };

  const getActionItems = (workPackage) => {
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {workPackage.status === "SUBMITTED" && (
          <div>
            <MenuItem
              className={classes.menuitem}
              onClick={() => {
                onActionClick(workPackage, "requestForProposalChange");
              }}
            >
              Request for Proposal Change
            </MenuItem>

            <MenuItem
              className={classes.menuitem}
              onClick={() => {
                onActionClick(workPackage, "requestForSow");
              }}
            >
              Request for SoW
            </MenuItem>

            <MenuItem
              className={classes.menuitem}
              onClick={() => {
                onActionClick(workPackage, "rejectProposal");
              }}
            >
              Reject Proposal
            </MenuItem>
          </div>
        )}

        {(workPackage.status === "sowUploaded" ||
          workPackage.status === "acceptSow") && (
          <div>
            <MenuItem
              className={classes.menuitem}
              onClick={() => {
                onActionClick(workPackage, "requestSowChange");
              }}
            >
              Request for SoW Change
            </MenuItem>

            <MenuItem
              className={classes.menuitem}
              onClick={() => {
                onActionClick(workPackage, "acceptedSow");
              }}
            >
              Accept SoW
            </MenuItem>

            <MenuItem
              className={classes.menuitem}
              onClick={() => {
                onActionClick(workPackage, "rejectProposal");
              }}
            >
              Reject Proposal
            </MenuItem>
          </div>
        )}

        {workPackage.status === "acceptedSow" && (
          <div>
            <MenuItem
              className={classes.menuitem}
              onClick={() => {
                onActionClick(workPackage, "uploadPO");
              }}
            >
              Upload PO
            </MenuItem>
          </div>
        )}
      </Menu>
    );
  };

  return (
    <>
      <Grid container>
        <Grid xs={12} style={{ margin: "16px 0" }}>
          <TableContainer className="tableContainer">
            {loading ? (
              <CircularProgress className={classes.loadstyle} size={24} />
            ) : (
              <Table
                style={{ width: "100%" }}
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Milestone Count</TableCell>

                    <TableCell>Price</TableCell>

                    <TableCell>Status</TableCell>

                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>

                {data?.wp_listWorkPackageByStatus.length !== 0 ? (
                  <TableBody>
                    {data?.wp_listWorkPackageByStatus?.map(
                      (workPackage, index) => {
                        return (
                          <TableRow>
                            <TableCell>
                              {getMilestoneCount(workPackage)}
                            </TableCell>

                            <TableCell>
                              {getMilestoneTotalPrice(workPackage)}
                            </TableCell>

                            <TableCell>
                              {getMilestoneStatus(workPackage.status)}
                            </TableCell>

                            <TableCell align="center">
                              {workPackage.status !== "requestForSow" && (
                                <IconButton
                                  className={classes.iconstyle}
                                  onClick={(event) => {
                                    handleClick(event, index);
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              )}
                            </TableCell>

                            {currentIndex === index &&
                              getActionItems(workPackage)}
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No Milestones!
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            )}
          </TableContainer>
        </Grid>
      </Grid>

      {actionSlider && (
        <CandidateActionSlider
          actionSlider={actionSlider}
          onClose={handleClose}
          action="uploadPO"
          refetch={refetch}
          selectedResource=""
          selectedWorkPackage={selectedWP}
          projectData={projectData}
          poDetails=""
          remarks=""
        />
      )}
    </>
  );
}
