//import { useState } from "react";

import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { theme } from "../../../theme/theme";

const useStyles = makeStyles({
  checkboxLabel: {
    "& span": {
      fontSize: "0.8rem",
      padding: theme.spacing(0, 0.25),
    },
    "& .MuiSvgIcon-root": {
      width: "1rem",
    },
    "& .PrivateSwitchBase-root-83": {
      padding: "0 4px",
    },
  },
  filterCountText: {
    fontSize: "0.65rem !important",
    color: "#999",
    marginLeft: theme.spacing(0.5),
  },
  filterText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  mainFilterDiv: {
    display: "flex",
    width: "140px",
  },
  undefinedText: {
    fontStyle: "italic",
  },
});

export default function FilterCheckBox({
  name,
  count,
  vbeid,
  onFilterSelect,
  listData,
}) {
  const classes = useStyles();

  const handleCheck = () => {
    let oldChecked = listData?.includes(vbeid);
    let newChecked = !oldChecked;

    onFilterSelect(vbeid, newChecked);
  };

  const getFilterText = () => {
    return (
      <div className={classes.mainFilterDiv}>
        <div
          className={name === "-" ? classes.undefinedText : classes.filterText}
        >
          {name === "-" ? "Undefined" : name}
        </div>
        <span className={classes.filterCountText}>({count})</span>
      </div>
    );
  };

  return (
    <FormControlLabel
      className={classes.checkboxLabel}
      control={
        <Checkbox
          checked={listData?.includes(vbeid) ? true : false}
          onChange={handleCheck}
          name={name}
        />
      }
      label={getFilterText()}
    />
  );
}
