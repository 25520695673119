import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Divider, Grid, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

import { LIST_REQUIREMENT_SECTIONS } from "../../queries/query";
import { useQuery } from "@apollo/client";

// import { customAlphabet } from "nanoid/non-secure";

import RTextItem from "../Items/requirements/textItem";
import RTextAreaItem from "../Items/requirements/textAreaItem";
import RSelectItem from "../Items/requirements/selectItem";
import RCurrencyItem from "../Items/requirements/currencyItem";
import RFileItem from "../Items/requirements/fileupload";
import RDateItem from "../Items/requirements/dateItem";
import RNumberItem from "../Items/requirements/numberItem";
import { useStyles } from "./styles";

// const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz-", 10);

export default function RSection(props) {
  const classes = useStyles();

  //   const [items, setItems] = React.useState([]);
  const [metadata, setMetadata] = React.useState(props.metadata);

  const { loading, error, data, refetch } = useQuery(
    LIST_REQUIREMENT_SECTIONS,
    {
      fetchPolicy: "no-cache",
      variables: {
        tinput: { sectionid: props.sid, projectid: props.projectId },
        pinput: { sectionid: props.sid, projectid: props.projectId },
      },
    }
  );

  function getItem(key, type, id, metadata, values) {
    switch (type) {
      case "text_field":
        return (
          <RTextItem
            key={key}
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            refetch={refetch}
            readOnly={props.readOnly}
          />
        );
      case "text_area":
        return (
          <RTextAreaItem
            rows={4}
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            refetch={refetch}
            readOnly={props.readOnly}
          />
        );

      case "multi_choice":
        return (
          <RSelectItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            readOnly={props.readOnly}
          />
        );

      case "currency":
        return (
          <RCurrencyItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            readOnly={props.readOnly}
          />
        );

      case "attachment":
        return (
          <RFileItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            readOnly={props.readOnly}
          />
        );

      case "date":
        return (
          <RDateItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            readOnly={props.readOnly}
          />
        );

      case "number":
        return (
          <RNumberItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            values={values}
            readOnly={props.readOnly}
          />
        );

      default:
        break;
    }
  }

  function getValues(values, itemid, type) {
    const result = values.filter((value) => value.itemid === itemid);
    result.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp));

    return result ? result[0] : {};
  }

  return (
    <Grid container className={classes.sectionRoot}>
      <Grid item xs={1}>
        <Avatar className={classes.small} color="primary">
          <Typography variant="caption" display="block">
            {metadata.id}
          </Typography>
        </Avatar>
      </Grid>

      <Grid item xs={9}>
        <Typography variant="h6" display="block" className={classes.heading}>
          {metadata.title}

          <Tooltip title={metadata.help_message}>
            <HelpIcon fontSize="small" className={classes.helpicon} />
          </Tooltip>
        </Typography>
      </Grid>

      <Grid item xs={2}>
        {props.type === "evaluate_template" ? (
          <Typography variant="caption" className={classes.weight}>
            Weight : {metadata.weight}
          </Typography>
        ) : null}
      </Grid>

      <Grid item xs={1}></Grid>

      <Grid item xs={11}>
        <Divider />
      </Grid>

      <Grid item xs={1}></Grid>

      <Grid item xs={11}>
        {data
          ? data.listItem.map((item) => {
              var meta = JSON.parse(item.metadata);
              var values = getValues(data.getRequirementDocument, item.id, "");
              var input = {
                itemid: item.id,
                sectionid: props.sid,
                projectid: props.projectId,
                attachment: "",
                attachmentfilename: "",
                timestamp: null,
                value: "",
                remarks: "",
              };

              input = { ...input, ...values };
              delete input.__typename;

              return getItem(item.id, item.type, item.id, meta, { ...input });
            })
          : null}
      </Grid>
    </Grid>
  );
}
