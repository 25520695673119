import {
  Paper,
  Grid,
  Typography,
  FormControl,
  TextField,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Box,
  Badge,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import * as React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import ViewResponseForm from "../../vendor/reponse/responseForm";
import ViewQAForm from "../../vendor/reponse/qaForm";
import {
  GET_PRODUCTS_BY_PROJECT,
  GET_SECTIONS,
  GET_TEMPLATE_BY_PROJECT,
  LIST_EVALUATION_SECTIONS,
} from "../../queries/query";
import { useQuery } from "@apollo/client";
import RSection from "../../forms/others/evaluateTeamTablesesection";

const useStyles = makeStyles((theme) => ({
  root: {},
  responsePaper: {
    width: "100%",
  },

  tabname: {
    fontSize: theme.spacing(1.2),
  },
  tableIndexCell: {
    width: theme.spacing(20),
    fontWeight: "bold",
  },
  hidecell: {
    display: "none",
  },
  container: {},
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabName(props) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={2}>
        <Badge badgeContent={props.index} color="secondary" />
      </Grid>
      <Grid item xs={10}>
        <Typography variant="caption" className={classes.tabname}>
          {props.name}
        </Typography>
      </Grid>
    </Grid>
  );
}

function EvaluateSectionForm(props) {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_SECTIONS, {
    variables: { input: { vbeid: props.templateId } },
  });

  return (
    <React.Fragment>
      {data
        ? data.listSection.map((section, index) => {
            let obj = JSON.parse(section.metadata);

            return (
              <React.Fragment>
                <TableRow selected={true}>
                  <TableCell
                    colSpan={props.products.length + 1}
                    className={classes.tableIndexCell}
                  >
                    {obj.title}
                  </TableCell>
                </TableRow>

                <RSection
                  sid={section.id}
                  projectId={props.projectId}
                  products={props.products}
                  type="evaluate_template"
                  person={props.person}
                />
              </React.Fragment>
            );
          })
        : null}
    </React.Fragment>
  );
}

function EvaluationSections(props) {
  const classes = useStyles();

  const { loading, error, data, refetch } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    variables: { input: { vbeid: props.id, typelist: ["evaluate_template"] } },
  });

  return (
    <React.Fragment>
      {data ? (
        <EvaluateSectionForm
          templateId={data.getTemplatesByProjectID[0].vbeid}
          projectId={props.id}
          products={props.products}
          person={props.person}
        />
      ) : null}
    </React.Fragment>
  );
}

export default function ViewVendorEvaluationTeamTable({ person }) {
  const classes = useStyles();
  const { id } = useParams();

  //   const [value, setValue] = React.useState(0);

  //   const handleChange = (event, newValue) => {
  //     setValue(newValue);
  //   };

  const { loading, error, data, refetch } = useQuery(GET_PRODUCTS_BY_PROJECT, {
    variables: { input: { vbeid: id } },
  });

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader={true} aria-label="vendors">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableIndexCell}></TableCell>

                {data
                  ? data.getProductsByProjectIDV1.map((product, index) => {
                      return (
                        <TableCell key={index}>{product.productname}</TableCell>
                      );
                    })
                  : null}
              </TableRow>
            </TableHead>

            <TableBody>
              {data ? (
                <EvaluationSections
                  id={id}
                  products={data.getProductsByProjectIDV1}
                  person={person}
                />
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}
