import { useState } from "react";

import { useLazyQuery } from "@apollo/client";

import { Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { searchQuery } from "../home/query";

import { useStyles } from "./styles";
import SearchResult from "./SearchResult";

export default function SearchAutoCompleteField({
  setDomain,
  setName,
  setCompany,
  sentAwardVbeid,
  type,
  defaultValue,
  selectedIndex,
  label,
  placeholder,
  setNamdAndVbeid,
  productMapType,
}) {
  let options = [];

  const [anchorEl, setAnchorEl] = useState(null);
  const [text, setText] = useState("");
  const [value, setValue] = useState("");

  const classes = useStyles();
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const [handleText] = useLazyQuery(searchQuery, {
    onCompleted: (response) => {
      if (response.esQuery.result.length !== 0) {
        response.esQuery.result.map((r) => {
          return options.push({ type: r.vbeid, value: r.name });
        });
      }
    },
  });

  const removeOption = (index) => {
    selectedIndex(index);
  };

  const handleChangeText = () => {
    if (text !== "" && text !== undefined) {
      handleText({
        variables: {
          input: { index: type, name: text },
        },
      });
    }
  };

  const handleInputChangeList = (event, type) => {
    if (event.target.value !== undefined) {
      setText(event.target.value);
      setValue(event.target.value);
    }

    setAnchorEl(event.target.parentElement.parentElement);

    if (type === "delete") {
      handleChangeText();
      setAnchorEl(null);
    }
  };

  return (
    <>
      <Autocomplete
        freeSolo
        limitTags={3}
        closeText="Close"
        clearText="Clear"
        multiple={true}
        value={defaultValue}
        options={options}
        getOptionLabel={(option) => option.value}
        getOptionSelected={(option, value) => option.vbeid === value.type}
        renderTags={(value) =>
          value.map((option, index) => (
            <Chip
              className={
                productMapType === "mapType"
                  ? classes.productTypeChip
                  : classes.multiSelectChip
              }
              variant="outlined"
              key={option.type}
              label={option.value}
              onDelete={(event) => {
                removeOption(index);
                event.stopPropagation();
                handleInputChangeList(event, "delete");
                //defaultValue = { defaultValue }
                //defaultValue = a;
              }}
            />
          ))
        }
        onKeyUp={(event) => {
          handleInputChangeList(event);
        }}
        clearOnBlur={true}
        noOptionsText={""}
        renderOption={(option) => option.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            margin="dense"
            id="input"
          />
        )}
        className={classes.autocompleteSearch}
      />

      <SearchResult
        className={classes.resultDiv}
        id={id}
        open={open}
        anchorEl={anchorEl}
        setValue={setValue}
        setAnchorEl={setAnchorEl}
        text={text}
        setDomain={setDomain}
        setName={setName}
        sentAwardVbeid={sentAwardVbeid}
        setCompany={setCompany}
        type={type}
        setNamdAndVbeid={setNamdAndVbeid}
      />
    </>
  );
}
