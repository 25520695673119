import { Button, makeStyles } from "@material-ui/core";

export default function PrimaryButton({
  variant,
  label,
  disabled,
  onClick,
  size,
  startIcon,
  type,
}) {
  const useStyles = makeStyles((theme) => ({
    primaryButton: {
      fontSize: "0.7rem",
      marginLeft: "8px",
      float: "none",
    },
    smallButton: {
      fontSize: "0.65rem",
      marginLeft: "8px",
      float: "none",
    },
    selectedSmall: {
      fontSize: "0.65rem",
      marginLeft: "8px",
      float: "none",
      backgroundColor: "#008000",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#008000",
        color: "#fff",
      },
    },
    removeBtn: {
      fontSize: "0.65rem",
      marginLeft: "8px",
      float: "none",
      backgroundColor: "#D2042D",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#D2042D",
        color: "#fff",
      },
    },
  }));

  const classes = useStyles();

  return (
    <Button
      variant={variant || "contained"}
      color="primary"
      className={
        type === "remove"
          ? classes.removeBtn
          : size === "small" && variant === "selected"
          ? classes.selectedSmall
          : size === "small"
          ? classes.smallButton
          : classes.primaryButton
      }
      onClick={onClick}
      disabled={disabled || false}
      size={size}
      startIcon={startIcon || null}
    >
      {label}
    </Button>
  );
}
