import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useStyles } from "./styles";

export default function FormInput({
  type,
  field,
  value,
  onChange,
  onBlur,
  options,
}) {
  const classes = useStyles();

  return field !== "businessVertical" ? (
    <FormControl className={classes.formControlDiv}>
      <TextField
        id="outlined-basic"
        label={`${type} ${field}`}
        placeholder={`Enter ${type} ${field}`}
        variant="outlined"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        size="small"
      />
    </FormControl>
  ) : (
    <FormControl variant="outlined" className={classes.formControlDiv}>
      <InputLabel id="business-vertical-label">Business Vertical</InputLabel>

      <Select
        labelId="business-vertical-label"
        id="business-vertical-outlined"
        value={value}
        onChange={onChange}
        label="Business Vertical"
        multiple
        className={classes.selectDiv}
      >
        {options.map((vertical) => {
          return (
            <MenuItem key={vertical["n.name"]} value={vertical["n.name"]}>
              {vertical["n.name"]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
