import * as React from "react";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { GET_COMPANIES_BY_PROJECT } from "../../queries/query";

import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import { useQuery } from "@apollo/client";

import { VBRIDGEHUB_MEDIAURL } from "../../../../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    "& img": {
      objectFit: "contain",
    },
  },
  centerAlignCell: {
    textAlign: "center",
  },
  emptyText: {
    color: "#999",
    fontStyle: "italic",
  },
}));

export default function ServiceOverview() {
  const { id } = useParams();
  const classes = useStyles();

  const { data } = useQuery(GET_COMPANIES_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id } },
  });

  let products = [];

  if (data) {
    data.listCompaniesByProjectID.forEach((product) => {
      if (product.id !== "concierge0001") {
        products.push(product);
      }
    });
  }

  return (
    <div className={classes.root}>
      <Box>
        {data ? (
          products.length !== 0 ? (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="">
                <TableHead>
                  <TableRow>
                    <TableCell>Supplier</TableCell>

                    <TableCell className={classes.centerAlignCell}>
                      Added
                    </TableCell>

                    <TableCell className={classes.centerAlignCell}>
                      Invited
                    </TableCell>

                    <TableCell className={classes.centerAlignCell}>
                      Response
                    </TableCell>

                    {/* <TableCell className={classes.centerAlignCell}>
                  Evaluation
                </TableCell> */}

                    {/* <TableCell className={classes.centerAlignCell}>
                      Contract
                    </TableCell>

                    <TableCell className={classes.centerAlignCell}>
                      Award
                    </TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data
                    ? products.map((row) => {
                        return (
                          <TableRow key={row.vbeid}>
                            <TableCell>
                              <Grid container>
                                <Grid item xs={2}>
                                  <Avatar
                                    variant="square"
                                    src={VBRIDGEHUB_MEDIAURL + "/" + row.image}
                                    className={classes.small}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={10}
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  {row.name}
                                </Grid>

                                <Grid item xs={2}></Grid>

                                <Grid
                                  item
                                  xs={10}
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  {row.productname}
                                </Grid>
                              </Grid>
                            </TableCell>

                            <TableCell className={classes.centerAlignCell}>
                              {row.role?.includes("ADDED") ? (
                                <CheckCircleIcon color="secondary" />
                              ) : (
                                <RadioButtonUnchecked />
                              )}
                            </TableCell>

                            <TableCell className={classes.centerAlignCell}>
                              {row.role?.includes("INVITED") ? (
                                <CheckCircleIcon color="secondary" />
                              ) : (
                                <RadioButtonUnchecked />
                              )}
                            </TableCell>

                            <TableCell className={classes.centerAlignCell}>
                              {row.role?.includes("SUBMITTED") ? (
                                <CheckCircleIcon color="secondary" />
                              ) : (
                                <RadioButtonUnchecked />
                              )}
                            </TableCell>

                            {/* <TableCell className={classes.centerAlignCell}>
                          {row.role.includes("EVALUATED") ? (
                            <CheckCircleIcon color="secondary" />
                          ) : (
                            <RadioButtonUnchecked />
                          )}
                        </TableCell> */}

                            {/* <TableCell className={classes.centerAlignCell}>
                              {row.role?.includes("ADD_TO_CONTRACT") ? (
                                <CheckCircleIcon color="secondary" />
                              ) : (
                                <RadioButtonUnchecked />
                              )}
                            </TableCell>

                            <TableCell className={classes.centerAlignCell}>
                              {row.role?.includes("ADD_TO_AWARD") ? (
                                <CheckCircleIcon color="secondary" />
                              ) : (
                                <RadioButtonUnchecked />
                              )}
                            </TableCell> */}
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className={classes.emptyText}>
              No companies have been added to this project
            </div>
          )
        ) : (
          <CircularProgress />
        )}
      </Box>
    </div>
  );
}
