/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";

import { useStyles } from "./styles";

import {Grid,FormControl, Button, Typography, Divider} from "@material-ui/core";

import { useFormik } from "formik";

import { BootstrapInput } from "../../../../theme/theme";

import {useMutation} from "@apollo/client";

import Footer from '../project/footerButtons';

import {addproposal, updateproposal} from './query';
import {signed} from '../project/query';
import AddAttachments from '../project/addAttachement';
import VbridgeAlert from "../../../shared/alert";
import Attachmentslist from '../project/listAttachments';
import ViewSubmittedRequirement from '../project/viewSubmittedRequirement';

export default function Response({longtext , listdata, 
    productid, review, getTextMethod, lead, submittedRequirement, display}){
    let { id, invitedid } = useParams(); 
    let history = useHistory();
    const classes = useStyles();
  
   
    //const [attachments, setAttachments] = React.useState([]);
    
    const [getAttachments, setGetAttachments] = React.useState(listdata!=null?[...listdata.attachments]:[]);
 
    const [isOwner] = React.useState(true);


    const formik = useFormik({
        initialValues: {
            description:longtext}});

    //addProposal
    const [postproposal, { data:addsuccess, error:addingError }] 
                = useMutation(addproposal, {onCompleted:(e)=>{SendText()}});

     //updateProposal
     const [editproposal, { data:updatesuccess, error:updatingError }] 
     = useMutation(updateproposal,{onCompleted:(e)=>{SendText()}});


     //updateProposal
     const [edproposal] 
     = useMutation(updateproposal,{onCompleted:(e)=>{SendText()}});


     //signed url for downloading the document           
     const [signedUrl] = useMutation(
        signed,
        {
          onCompleted: (e) => {
            window.open(e.signedURLforProjectSupportDoc.signedurl, "_blank");
          },
        }
      );

 //Send longDescription
 function SendText(){
    if(review){
        getTextMethod(formik.values.description);
        }
}

    //Route to Nxt Page
    function NextPage(){
        return(
          setTimeout(() => {
            
            history.push({
                pathname: "/request/reviewandsubmit/" + id + '/' + productid + '/' + invitedid})

          },4000)
        )
    }


     //Any change in the attachments, execute update API
     useEffect(() => {
      if(listdata!=null){
        UpdateProposalEffect();}
    }, [getAttachments]);

    function ExecuteDelete(filepath){
        DeleteDoc(filepath);
    }
      
    function DeleteDoc(filepath) {

                let newList = [...getAttachments]
               
                let filterdlist = newList.filter((x)=>x!==filepath)
                setGetAttachments(filterdlist);}


    //function for generating the signedurl
    function CreateUrl(e) {
        
        signedUrl({
                      variables: {
                        input: {
                          method: "get",
                          key:e.target.parentNode.getAttribute('pathname'),
                        },
                      },
                    });
                  }

    function Attachments(e){
       //setAttachments(e);
       setGetAttachments([...getAttachments, ...e]);
                }

    function AddProposal(e) {
        
                postproposal({
                      variables: {
                        input: {
                         attachments:getAttachments,
                         vbeid:id,
                         status: "save", 
                         longdescription:formik.values.description,
                         productvbeid: productid
                        },
                      },
                    });
                  }

    
        function UpdateProposal(e) {

               editproposal({
                          variables: {
                            input: {
                             attachments:getAttachments,
                             vbeid:id,
                             status: "save", 
                             longdescription:formik.values.description,
                             productvbeid: productid,
                             id:listdata.id
                            },
                          },
                        });
                      }


        ///review submit button functions
        function AddReviewProposal(e) {
        
          postproposal({
                variables: {
                  input: {
                   attachments:getAttachments,
                   vbeid:id,
                   status: "save", 
                   longdescription:formik.values.description,
                   productvbeid: productid
                  },
                },
              });
              NextPage();
            }


  function UpdateReviewProposal(e) {
   

         editproposal({
                    variables: {
                      input: {
                       attachments:getAttachments,
                       vbeid:id,
                       status: "save", 
                       longdescription:formik.values.description,
                       productvbeid: productid,
                       id:listdata.id
                      },
                    },
                  });

                  NextPage();
                }


          ///update for useEffect
          function UpdateProposalEffect(e) {

            edproposal({
                       variables: {
                         input: {
                          attachments:getAttachments,
                          vbeid:id,
                          status: "save", 
                          longdescription:formik.values.description,
                          productvbeid: productid,
                          id:listdata.id
                         },
                       },
                     });
                   }


    return(
        <>
         {addingError || updatingError? (
            <VbridgeAlert
              severity="error"
              open={true}
              message="Something went wrong ... "
            />
          ) : null}
          {addsuccess || updatesuccess ? (
            <VbridgeAlert
              severity="success"
              open={true}
              message="Proposal Updated successfully. "
            />
          ) : null}


        <Grid container >
              <Grid item md={12} >
                <FormControl className={classes.proposalTextBox} >
                 
                  <BootstrapInput
                    required={true}
                    id="my-input1"
                    aria-describedby="my-helper-text"
                    placeholder="Provide your solution description here"
                    multiline
                    // rows={5}
                    // rowsMax={6}
                    value={formik.values.description}
                    onChange={formik.handleChange("description")}
                    onBlur={formik.handleBlur("description")}
                    //disabled={isOwner?false:true}
                  />

            <div className={classes.attachmentsAlign}>
            <AddAttachments addNew={true} docType='proposal' isOwner={isOwner} attachMethod={Attachments}/>
            </div>
                
            <div>
            <Attachmentslist addNew={true} getAttachments={getAttachments}
            CreateUrl={CreateUrl} ExecuteDelete={ExecuteDelete} isOwner={isOwner}/>
            </div>
            

                </FormControl>

               
              </Grid>
           

           
            
           

            <Grid item md={12}>

            {review?(
                <>
                {listdata!==undefined?
                <Button variant='contained' color='primary'
                onClick={UpdateProposal} className={classes.buttonStyle}>
                   Save
               </Button>
               :
               <Button variant='contained' color='primary'
                 onClick={AddProposal} className={classes.buttonStyle}>
                    Save
                </Button>}
                </>
            ):(
            
            listdata!==undefined?
            <Footer buttonText1='Save as draft' buttonText2='Review & Submit' method1={UpdateProposal} method2={UpdateReviewProposal} />              
            :
            <Footer buttonText1='Save as draft' buttonText2='Review & Submit' method1={AddProposal} method2={AddReviewProposal} />              
            
            
            )}

            </Grid>
            
              {display?null:(
            <Grid item md={12}>
            

        {submittedRequirement.length>=1?(
          <>
            <Divider/>
            
            <div  className={classes.requirementViewSideHeading}>
        <Typography variant="overline text">
        Previously submitted responses
        </Typography>
        </div>
            <ViewSubmittedRequirement  submittedRequirement={submittedRequirement}/>

          </>
            ):(null)}

              </Grid>
              )}

            </Grid>
        </>

    )
}