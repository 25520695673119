import { gql } from "@apollo/client";

export const createTemplate = gql`
  mutation createTemplate($input: templateinput!) {
    createTemplate(input: $input) {
      name
      vbeid
      type
      shortdescription
      for
      createdby
      createdon
    }
  }
`;

export const addSection = gql`
  mutation addSection($input: [templateinput]) {
    addSectionToTemplate(input: $input) {
      id
      vbeid
      metadata
    }
  }
`;

export const editSection = gql`
  mutation editSection($input: templateinput!) {
    editSection(input: $input) {
      id
      vbeid
      metadata
    }
  }
`;

export const removeSection = gql`
  mutation removeSection($input: templateinput!) {
    removeSection(input: $input) {
      id
      vbeid
    }
  }
`;

export const addItem = gql`
  mutation addItem($input: [templateinput]) {
    addItemtoSection(input: $input) {
      id
      vbeid
    }
  }
`;

export const editItem = gql`
  mutation editItem($input: templateinput!) {
    editItem(input: $input) {
      id
      vbeid
      metadata
    }
  }
`;

export const getTemplates = gql`
  query getTemplates($input: templateinput!) {
    listTemplates(input: $input) {
      name
      vbeid
      type
      shortdescription
      for
      status
      createdby
      createdon
    }
  }
`;

export const updateTemplate = gql`
  mutation updateTemplate($input: templateinput!) {
    updateTemplate(input: $input) {
      name
      vbeid
      type
      shortdescription
      for
    }
  }
`;

export const updateStatus = gql`
  mutation updateStatus($input: templateinput!) {
    updateTemplate(input: $input) {
      name
      vbeid
      type
      shortdescription
      for
    }
  }
`;

export const getSectionsInTemplate = gql`
  query getSectionsInTemplate($input: templateinput!) {
    listSection(input: $input) {
      id
      name
      helpmessage
      vbeid
      metadata
    }
  }
`;

export const getItemsInSection = gql`
  query getItemsInSection($input: templateinput!) {
    listItem(input: $input) {
      id
      name
      helpmessage
      vbeid
      type
      metadata
    }
  }
`;

export const deleteItem = gql`
  mutation deleteItem($input: templateinput!) {
    deleteItem(input: $input) {
      id
    }
  }
`;

export const deleteSection = gql`
  mutation deleteSection($input: templateinput!) {
    deleteSection(input: $input) {
      id
    }
  }
`;
