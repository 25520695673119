import { useState } from "react";

import {
  Box,
  Grid,
  IconButton,
  ListItemSecondaryAction,
  Paper,
  Typography,
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useHistory, useParams } from "react-router-dom";

import { GET_LOGIN_USER_INFO } from "../../queries/query";

import {gql, useApolloClient } from "@apollo/client";

//import BuyerProjects from "./projects";

import TemplateTable from "../templates/templates";

import { useStyles } from "./styles";
import { Templates } from "./templates";

import { CreateProjectModal } from "./CreateProjectModal";
import ServiceProjects from "./ServiceProjects";

const userPermission = gql`
  query identity($input: personinput!) {
    amiauthorized(input: $input) {
      status
    }
  }
`;

const SideIcon = () => {
  return (
    <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="arrow" color="secondary">
        <ArrowRightIcon />
      </IconButton>
    </ListItemSecondaryAction>
  );
};

const MainMenu = () => {
  const { type } = useParams();
  const history = useHistory();

  const classes = useStyles();
  const client = useApolloClient();

  const [open, setOpen] = useState(true);
  const [createProjectModal, setCreateProjectModal] = useState(false);
  //   const [projectType, setProjectType] = useState("");

  const user = client.readQuery({
    query: GET_LOGIN_USER_INFO,
    variables: { input: {} },
  });

  const handleClick = () => {
    setOpen(!open);
    setCreateProjectModal(false);
    // setProjectType("");
  };

  const onCreateProject = () => {
    setCreateProjectModal(true);
  };

  const handleChange = (e) => {
    // setProjectType(e.target.value);
  };

  const onCreateClick = (projectType) => {
    if (projectType === "product" || projectType === "service") {
      history.push("/create-project-v3/new");
    } else if (projectType === "workpackage") {
      history.push("/create-workpackage-project-v3/new");
    } else {
      history.push("/create-service-project-v3/new");
    }
  };

  return (
    <List component="nav" dense>
      {/* <ListItem
        button
        selected={type === "overview"}
        onClick={() => {
          history.push("/projects3.0/overview");
        }}
        className={classes.dashboardMenuItem}
      >
        <ListItemText primary="Dashboard" />
        {type === "overview" ? <SideIcon /> : null}
      </ListItem> */}

      <ListItem
        button
        selected={type === "serviceProjects"}
        onClick={() => {
          history.push("/projects3.0/serviceProjects");
        }}
        className={classes.dashboardMenuItem}
      >
        <ListItemText primary="Dashboard" />
        {type === "serviceProjects" || type === "overview" ? (
          <SideIcon />
        ) : null}
      </ListItem>

     {userPermission && userPermission.amiauthorized.status && (
        <ListItem
          button
          onClick={onCreateProject}
          className={classes.dashboardMenuItem}
        >
          <ListItemText primary="Create Project" />
        </ListItem>
      )}

      <ListItem
        button
        onClick={handleClick}
        className={classes.dashboardMenuItem}
      >
        <ListItemText primary="Templates" />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              history.push("/projects3.0/requirement_template");
            }}
          >
            <ListItemText primary="Requirement Templates" />
            {type === "requirement_template" ? <SideIcon /> : null}
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              history.push("/projects3.0/response_template");
            }}
          >
            <ListItemText primary="Response Templates" />
            {type === "response_template" ? <SideIcon /> : null}
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              history.push("/projects3.0/qa_template");
            }}
          >
            <ListItemText primary="Q&A Template" />
            {type === "qa_template" ? <SideIcon /> : null}
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              history.push("/projects3.0/evaluate_template");
            }}
          >
            <ListItemText primary="Evaluate Templates" />
            {type === "evaluate_template" ? <SideIcon /> : null}
          </ListItem>

          {user.getLoginUserInfo.isVbridgeUser === "true" ? (
            <>
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/projects3.0/due_dilligence_template");
                }}
              >
                <ListItemText primary="Due Dilligence Templates" />
                {type === "due_dilligence_template" ? <SideIcon /> : null}
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/projects3.0/feed_back_template");
                }}
              >
                <ListItemText primary="Feedback Templates" />
                {type === "feed_back_template" ? <SideIcon /> : null}
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/projects3.0/contract_template");
                }}
              >
                <ListItemText primary="Contract Templates" />
                {type === "contract_template" ? <SideIcon /> : null}
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/projects3.0/sow_template");
                }}
              >
                <ListItemText primary="SOW Templates" />
                {type === "sow_template" ? <SideIcon /> : null}
              </ListItem>
            </>
          ) : null}
        </List>
      </Collapse>

{userPermission && userPermission.amiauthorized.status && (
      <CreateProjectModal
        open={createProjectModal}
        handleClose={handleClick}
        // projectType={projectType}
        handleChange={handleChange}
        onCreateClick={onCreateClick}
      />
          )}
    </List>
  );
};

export default function ProjectMain({
  userInfo,
  companyvbeid,
  drawerOpen,
  mainDrawerClick,
}) {
  const classes = useStyles();
  const { type } = useParams();

  return (
    <div className={drawerOpen ? classes.openDrawerRoot : classes.root}>
      <Box m={10}>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          className={classes.heading}
        >
          My Projects {">"} Project Dashboard
        </Typography>

        <Paper className={classes.main}>
          <Grid container>
            <Grid item xs={2} className={classes.menuList}>
              <MainMenu />
            </Grid>

            <Grid item xs={10} className={classes.mainArea}>
              {/* {type === "overview" ? <BuyerProjects /> : null} */}

              {type === "overview" ? <ServiceProjects /> : null}

              {type === "serviceProjects" && <ServiceProjects />}

              {Templates.map((template, index) => {
                if (template.id === type) {
                  return <TemplateTable key={index} type={type} />;
                } else {
                  return null;
                }
              })}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}
