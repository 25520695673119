import * as React from "react";

import { DataGrid } from "@material-ui/data-grid";

import { useMutation } from "@apollo/client";

export default function DataGridValidation({
  loading,
  data,
  columns,
  method,
  onEdit,
  pageChange,
  updateQueryName,
}) {
  /////update company mutation
  const [
    postCompanyMutation,
    { data: companyUpdated, error: companyUpdationError },
  ] = useMutation(updateQueryName, {
    onCompleted: (e) => {
      // UpdateCompanyCache(e);
    },
  });

  //post update function
  const updateCompany = (e) => {
    postCompanyMutation({
      variables: {
        input: {
          shortdescription: e.value,
          vbeid: e.row.id,
        },
      },
    });
  };

  return (
    <DataGrid
      loading={loading}
      density="compact"
      autoHeight={true}
      rowsPerPageOptions={[5]}
      checkboxSelection={true}
      disableSelectionOnClick={true}
      onSelectionModelChange={method}
      onCellEditCommit={updateCompany}
      onEditCellChangeCommitted={onEdit}
      rows={loading ? [] : data}
      columns={columns}
    />
  );
}
