import { Button, Divider, Popover, Typography } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useState } from "react";
import { useStyles } from "./HelpStyles";

import "./help.css";
import MailIcon from "@material-ui/icons/Mail";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import HelpDailogBox from "./HelpDailogBox";
import AddInvite from "../companies/Company2.0/shorts/AddInvite";

export default function HelpMenu() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDailog, setOpenDailog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [heading, setHeading] = useState("");
  const [statement, setStatement] = useState("");
  const [inlay, setInlay] = useState("");
  const [finalMsg, setFinalMsg] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const openDailogBox = (hdng, stmnt, inly, msg) => {
    setOpenDailog(true);
    setHeading(hdng);
    setStatement(stmnt);
    setInlay(inly);
    setFinalMsg(msg);
  };

  const onClose = () => {
    setOpenDailog(false);
  };

  return (
    <>
      <div className={classes.HelpIcon}>
        <HelpOutlineIcon onClick={handleClick} />
      </div>
      <div>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          // transformOrigin={{
          //   vertical: "top",
          //   horizontal: "center",
          // }}
        >
          <div className={classes.mainDiv}>
            <Button
              className={classes.btn}
              onClick={() => {
                openDailogBox(
                  "Schedule a Demo",
                  "We will get in touch with you to schedule a demo call",
                  "We will get in touch with you to schedule a demo call",
                  "Thank you for initiating a request for demo. We will get in touch with you on email to schedule a demo call as per convenience."
                );
              }}
            >
              Schedule a Demo?
            </Button>
            <Divider />
            <Button
              className={classes.btn}
              onClick={() => {
                openDailogBox(
                  "Need Help",
                  "Happy to assist on usage of the platform or curation of any Group, Category or Product",
                  "Please provide details here",
                  "Your request has been sent to our team. We will get in touch with you on email to schedule a call as per convenience to extend assistance."
                );
              }}
            >
              Need Help?
            </Button>
            <Divider />
            <Button
              className={classes.btn}
              onClick={() => {
                openDailogBox(
                  "Feedback & Suggestions",
                  "We are working hard to build a meaningful product for you. We would love to have your views!",
                  "Let us know your views",
                  "Thank you for your valuable feedback. We will get in touch with you on email to update status on your feedback."
                );
              }}
            >
              Have a Feedback?
            </Button>
            <Divider />
            <Button className={classes.ibtn}>
              <AddInvite
                inviteMsg="Send an Invite"
                headername="Invite a company to vBridge Hub"
                dialogtitle="Know of any Tech Vendor, Service Provider or Enterprise who benefits from the platform"
              />
            </Button>
            <Divider />
            <div className={classes.contactUs}>
              <Typography className={classes.contact}>Contact Us:</Typography>
              <div>
                <a class="mailto" href="mailto:helpdesk@vbridgehub.com">
                  <Typography className={classes.contactInfo}>
                    <MailIcon className={classes.mailicon} />:
                    helpdesk@vbridgehub.com
                  </Typography>
                </a>
              </div>
              <div>
                <Typography className={classes.contactInfo}>
                  <WhatsAppIcon />: +91 88616 82888
                </Typography>
              </div>
            </div>
          </div>
        </Popover>
        <HelpDailogBox
          open={openDailog}
          close={onClose}
          heading={heading}
          statement={statement}
          inlay={inlay}
          message={finalMsg}
        />
      </div>
    </>
  );
}
