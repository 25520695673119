import { useParams, useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import ProjectFormReview from "../createProject/projectformreview";
import AddShortReview from "../createProject/addshortreview";

import VendorsList from "../vendors/vendrodslist";

import UpdateRequirementForm from "../requirements/updateRequirementsreview";
import MapOtherTemplate from "../requirements/mapOtherTemplateReview";

import { useStyles } from "./styles";

import SubmitProject from "./SubmitProject";
import ViewPriceForm from "../response/priceForm";
import { useQuery } from "@apollo/client";
import { GET_PROJECT } from "../../queries/query";
import PrimaryButton from "../../../common/PrimaryButton";

export default function ProjectReview() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const { data } = useQuery(GET_PROJECT, {
    fetchPolicy: "network-only",
    variables: {
      input: { vbeid: id },
    },
  });

  return (
    <Box m={10}>
      <IconButton
        style={{ float: "left" }}
        color="primary"
        onClick={() => {
          history.push("/projects3.0/overview");
        }}
      >
        <ArrowBackIcon />
      </IconButton>

      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} className={classes.reviewTextRow}>
            <Box m={1}>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant="h6">
                    Review and publish your project
                  </Typography>
                </Grid>

                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <SubmitProject id={id} />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} className={classes.row}>
            <div className={classes.responseHeading}>Project Details</div>
            <ProjectFormReview />
            <AddShortReview />
          </Grid>

          <Grid item xs={12} className={classes.row}>
            <div className={classes.responseHeading}>Requirement</div>
            {data?.getProjectByIDV1 && (
              <UpdateRequirementForm
                type="review"
                projectData={data?.getProjectByIDV1}
              />
            )}
          </Grid>

          <Grid item xs={12} className={classes.row}>
            <div className={classes.responseHeading}>Supplier Templates</div>
            <MapOtherTemplate type="review" />

            <div style={{ marginLeft: "80px" }}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.textFieldLabel}
              >
                Summary Pricing Template{" "}
                <span style={{ fontWeight: "normal", fontSize: "0.75rem" }}>
                  {" "}
                  (as per project type selected. Mandatory for supplier to fill)
                </span>
              </Typography>

              <ViewPriceForm
                type="review"
                projectId={id}
                projectData={data?.getProjectByIDV1}
              />
            </div>
          </Grid>

          <Grid item xs={12} className={classes.row}>
            <div className={classes.responseHeading}>Products Selected</div>
            <VendorsList review={true} type="review" />
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.row}>
          <Divider />

          <Box m={1}>
            <Grid container>
              <Grid item xs={8}>
                <PrimaryButton
                  label="Back"
                  variant="outlined"
                  onClick={() => {
                    history.push("/other-templates/" + id);
                  }}
                />
              </Grid>

              {data && (
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <SubmitProject id={id} projectData={data?.getProjectByIDV1} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </div>
    </Box>
  );
}
