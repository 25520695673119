import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";

import UnfoldLessIcon from "@material-ui/icons/UnfoldLess";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

import { getUserById } from "./query";

import RepliesList from "./ReplyList";
import UserInfo from "./userInfo";

const useStyles = makeStyles((theme) => ({
  mainQuestion: {
    paddingLeft: theme.spacing(1),
  },
  question: {
    margin: 0,
    paddingBottom: theme.spacing(0.5),
    fontSize: "0.85rem",
    "& p": {
      margin: 0,
      fontSize: "0.85rem",
    },
  },
  cardContent: {
    padding: "0px 8px 4px !important",
    marginLeft: "60px",
  },
  button: {
    textTransform: "capitalize",
  },
  root: {
    padding: "0 8px",
    "& .MuiButton-label": {
      fontSize: "12px",
    },
  },
  replyPlaceholder: {
    color: "#999",
    fontStyle: "italic",
    marginLeft: theme.spacing(0.5),
  },
}));

export default function ContentCard({
  id,
  createdBy,
  question,
  userImage,
  totalReplies,
  projectId,
  productId,
  updateRepliesCount,
}) {
  const classes = useStyles();
  const { data } = useQuery(getUserById, {
    variables: { input: { vbeid: createdBy } },
  });

  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(!open);
  };

  function createMarkup() {
    return { __html: question };
  }

  return (
    <div className={classes.mainQuestion}>
      <Card>
        <UserInfo
          id={id}
          createdBy={createdBy}
          userImage={userImage}
          type="question"
        />

        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            dangerouslySetInnerHTML={createMarkup()}
            className={classes.question}
          ></Typography>
        </CardContent>

        <Divider />

        <CardActions className={classes.root}>
          <Button
            color="primary"
            size="small"
            className={classes.button}
            startIcon={open ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
            onClick={onClick}
          >
            {open ? "Collapse All" : `${totalReplies} Replies. `}
            {totalReplies === 0 ? (
              <span className={classes.replyPlaceholder}>Click to reply</span>
            ) : null}
          </Button>
        </CardActions>

        {open ? (
          <RepliesList
            id={id}
            projectId={projectId}
            productId={productId}
            userInfo={data?.getPersonByVBEID}
            updateRepliesCount={updateRepliesCount}
          />
        ) : null}
      </Card>
    </div>
  );
}
