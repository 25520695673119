import * as yup from "yup";

export const validationSchema = yup.object().shape({
  name: yup
    .string("Enter Name")
    .min(2, "Name should have a minimum of 2 characters")
    .required("Please enter a name"),

  url: yup
    .string("Enter URL")
    .required("URL is required")
    .matches(
      /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      "Please enter a valid and secure URL"
    ),

  businessVertical: yup.array().min(1).required("Please select a vertical"),
});
