import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { useMutation } from "@apollo/client";

import VbridgeAlert from "../shared/alert";

import { mappingRelation } from "./query";
import {
  mapProductToService,
  mapTopicToService,
} from "../entities/services/query";

const topics = require("../../data/topics.json");

export default function MapTopic({
  id,
  name,
  currentvbeid,
  type,
  mapType,
  updateCache,
  data,
  page,
}) {
  const [values, setValues] = useState([]);

  let listData = topics;

  if (type === "service" && mapType === "product") {
    listData = data.productByComapnyId;
  }

  const [mapRelationMutation, { error: mutationError, data: mapData }] =
    useMutation(mappingRelation);

  const [mapTopicMutation, { data: mapTopic }] = useMutation(
    mapTopicToService,
    {
      onCompleted: (response) => {
        updateCache("topicobj", values);
      },
    }
  );

  const [mapProductMutation] = useMutation(mapProductToService, {
    onCompleted: (response) => {
      updateCache("productobj", values);
    },
  });

  const mapRelation = (e) => {
    if (type === "service") {
      if (mapType === "product") {
        mapProductMutation({
          variables: {
            input: {
              productvbeid: values,
              vbeid: id,
            },
          },
        });
      } else {
        mapTopicMutation({
          variables: {
            input: {
              topicvbeid: values,
              vbeid: id,
            },
          },
        });
      }
    } else {
      mapRelationMutation({
        variables: {
          input: {
            relationname: name,
            listvbeid: values,
            vbeid: id,
            existingvbeid: currentvbeid || null,
          },
        },
      });
    }
  };

  return (
    <>
      {mutationError && (
        <VbridgeAlert
          open={true}
          message="Something Went Wrong..."
          severity="error"
        />
      )}

      {(mapData || mapTopic) && (
        <VbridgeAlert
          open={true}
          message="Mapped Successfully"
          severity="success"
        />
      )}

      <Box p={page === "slider" ? 0 : 1} style={{ width: "100%" }}>
        <Grid container>
          <Grid item md={page === "slider" ? 12 : 8} style={{ width: "100%" }}>
            <Autocomplete
              multiple
              options={listData}
              getOptionLabel={(option) => {
                if (mapType === "product") return option.name;
                else return option["n.name"];
              }}
              style={{ width: "100%" }}
              clearOnBlur="true"
              onChange={(event, newvalue) => {
                if (type === "service") {
                  if (mapType === "product") {
                    let selectedValue = [];
                    newvalue.forEach((value) => {
                      selectedValue.push(value.vbeid);
                    });

                    setValues(selectedValue);
                  } else {
                    let selectedValue = [];
                    newvalue.forEach((value) => {
                      selectedValue.push(value["n.vbeid"]);
                    });

                    setValues(selectedValue);
                  }
                } else {
                  let selectedValue = [];
                  newvalue.forEach((value) => {
                    selectedValue.push(value["n.vbeid"]);
                  });

                  setValues(selectedValue);
                }
              }}
              renderOption={(option) => {
                if (mapType === "product") return option.name;
                else return option["n.name"];
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    mapType === "product" ? "Search Product" : "Search Topic"
                  }
                  variant="outlined"
                  margin={page !== "slider" ? "dense" : ""}
                  id="input"
                />
              )}
            />
          </Grid>

          {page !== "slider" && (
            <Grid item md={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={values?.length !== 0 ? false : true}
                size="small"
                style={{ marginTop: "15%" }}
                onClick={mapRelation}
              >
                Map
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}
