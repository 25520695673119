import { useState } from "react";
import { useParams } from "react-router-dom";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import VbridgeAlert from "../../../../shared/alert";
import PrimaryButton from "../../../common/PrimaryButton";

import {
  GET_SECTIONS,
  GET_TEMPLATE_BY_PROJECT,
  LIST_RESPONSE_SECTIONS,
  VENDOR_SUBMIT_PROJECT,
  LIST_QA_SECTIONS,
  GET_PRODUCT_COST,
  GET_TIME_MONEY,
  GET_FIXED_COST,
} from "../../queries/query";

export default function SubmitResponse({ readOnly, refetch, projectData }) {
  const { id, productId } = useParams();

  const [open, setOpen] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [responseItemCount, setResponseItemCount] = useState(0);
  const [responseValidation, setResponseValidation] = useState(true);

  const [qnaItemCount, setQnaItemCount] = useState(0);
  const [qnaValidation, setQnaValidation] = useState(true);

  const [pricingValidation, setPricingValidation] = useState(true);

  let pricingQuery = "";
  let pricingDataName = "";

  if (projectData.type === "product") {
    pricingQuery = GET_PRODUCT_COST;
    pricingDataName = "getProductCostItemvalue";
  } else if (projectData.engagement_type === "time_material") {
    pricingQuery = GET_TIME_MONEY;
    pricingDataName = "getTimeMaterialItemvalue";
  } else {
    pricingQuery = GET_FIXED_COST;
    pricingDataName = "getFixedPriceItemvalue";
  }

  const { data: responseData } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: {
      input: { vbeid: id, typelist: ["response_template", "qa_template"] },
    },
    onCompleted: (response) => {
      response.getTemplatesByProjectID.forEach((template) => {
        if (template.type === "response_template") {
          getTemplateSections({
            variables: {
              input: { vbeid: template.vbeid },
            },
          });
        }
        if (template.type === "qa_template") {
          getQaSections({
            variables: {
              input: { vbeid: template.vbeid },
            },
          });
        }
      });
    },
  });

  const { data: pricingData } = useQuery(pricingQuery, {
    fetchPolicy: "no-cache",
    variables: {
      input: { projectid: id, productvbeid: productId },
    },
    onCompleted: (response) => {
      let data = response[pricingDataName];

      if (data.length === 0) {
        setPricingValidation(false);
      }
    },
  });

  const [getTemplateSections, { data: responseSections }] = useLazyQuery(
    GET_SECTIONS,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [getQaSections, { data: qaSections }] = useLazyQuery(GET_SECTIONS, {
    fetchPolicy: "no-cache",
  });

  const [getItems, { loading: responseItemsLoading }] = useLazyQuery(
    LIST_RESPONSE_SECTIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (response) => {
        if (response.getResTempItemvalue.length < response.listItem.length) {
          setResponseValidation(false);
        } else {
          if (responseItemCount < responseSections.listSection.length) {
            getItems({
              variables: {
                tinput: {
                  sectionid: responseSections.listSection[responseItemCount].id,
                  projectid: id,
                },
                pinput: {
                  sectionid: responseSections.listSection[responseItemCount].id,
                  projectid: id,
                  productvbeid: productId,
                },
              },
            });

            setResponseItemCount(responseItemCount + 1);
          }

          if (
            responseItemCount === responseSections.listSection.length &&
            responseValidation === ""
          ) {
            setResponseValidation(true);

            getQaItems({
              variables: {
                tinput: {
                  sectionid: qaSections.listSection[0].id,
                  projectid: id,
                },
                pinput: {
                  sectionid: qaSections.listSection[0].id,
                  projectid: id,
                  productvbeid: productId,
                },
              },
            });

            setQnaItemCount(1);
          }
        }
      },
    }
  );

  const [getQaItems, { loading: qaItemsLoading }] = useLazyQuery(
    LIST_QA_SECTIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (response) => {
        if (
          response.getQuestionTempItemvalue.length < response.listItem.length
        ) {
          setQnaValidation(false);
        } else {
          if (qnaItemCount < qaSections.listSection.length) {
            getQaItems({
              variables: {
                tinput: {
                  sectionid: qaSections.listSection[qnaItemCount].id,
                  projectid: id,
                },
                pinput: {
                  sectionid: qaSections.listSection[qnaItemCount].id,
                  projectid: id,
                  productvbeid: productId,
                },
              },
            });

            setQnaItemCount(qnaItemCount + 1);
          }

          if (
            qnaItemCount === qaSections.listSection.length &&
            qnaValidation === ""
          ) {
            setQnaValidation(true);
          }
        }
      },
    }
  );

  const [submitProject] = useMutation(VENDOR_SUBMIT_PROJECT, {
    onCompleted: (e) => {
      handleClose();
      setSubmitSuccess(true);
      refetch();
    },
    onError: (err) => {
      handleClose();
    },
  });

  const handleClickOpen = () => {
    if (responseData.getTemplatesByProjectID.length !== 0) {
      setResponseValidation("");
      setQnaValidation("");

      getItems({
        variables: {
          tinput: {
            sectionid: responseSections.listSection[0].id,
            projectid: id,
          },
          pinput: {
            sectionid: responseSections.listSection[0].id,
            projectid: id,
            productvbeid: productId,
          },
        },
      });

      setResponseItemCount(1);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <VbridgeAlert
        severity="success"
        open={submitSuccess}
        message="Your response has been submitted to the client. Click on Project Name in Project Home to continue engaging with the client."
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-submission"
        aria-describedby="confirm-submission"
      >
        <DialogTitle id="confirm-submission">Confirm Submission</DialogTitle>

        {responseItemsLoading ||
        qaItemsLoading ||
        responseValidation === "" ||
        qnaValidation === "" ? (
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        ) : (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {!responseValidation ? (
                  <Typography variant="caption">
                    Please note that you have not completed one or more sections
                    of the Solution Response.
                    <br /> Please update and submit project again.
                  </Typography>
                ) : !qnaValidation ? (
                  <Typography variant="caption">
                    Please note that you have not completed one or more sections
                    of the QnA Response.
                    <br /> Please update and submit project again.
                  </Typography>
                ) : !pricingValidation ? (
                  <Typography variant="caption">
                    Please note that you have not completed the Pricing
                    Response.
                    <br /> Please update and submit project again.
                  </Typography>
                ) : (
                  <Typography variant="caption">
                    Please note that you can not edit the response upon clicking
                    Submit.
                    <br /> By clicking Submit, your response will be shared with
                    the buyer.
                    <br /> Please ensure that you follow your company's
                    information sharing guidelines with suppliers before
                    submitting your response.
                  </Typography>
                )}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <PrimaryButton
                onClick={handleClose}
                variant="outlined"
                label="Cancel"
              />

              {!responseValidation ? (
                <PrimaryButton
                  label={"Okay"}
                  variant="contained"
                  onClick={handleClose}
                />
              ) : (
                <PrimaryButton
                  label={"Submit"}
                  variant="contained"
                  onClick={() => {
                    submitProject({
                      variables: {
                        input: { vbeid: id, productvbeid: productId },
                      },
                    });
                  }}
                />
              )}
            </DialogActions>
          </>
        )}
      </Dialog>

      <div style={{ float: "right" }}>
        <PrimaryButton
          label={"Submit"}
          variant="contained"
          onClick={handleClickOpen}
          disabled={readOnly}
        />
      </div>
    </>
  );
}
