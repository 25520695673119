import { Grid, makeStyles, Paper, Snackbar } from "@material-ui/core";
import React, { useState } from "react";
import InputField from "../common/InputField";
import { useFormik } from "formik";
import * as yup from "yup";
import OutlinedButton from "../companies/company/OutlinedButton";
import { useMutation } from "@apollo/client";
import { addProjectOwner, addOperationEmp, addFinanceEmp } from "./query";
import SimpleSnackbar from "../companies/Company2.0/shorts/snackbar";
import SimpleErrorSnackbar from "../groups2.0/errorsnackbar";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    margin: theme.spacing(1),
    padding: theme.spacing(0.4),
  },
  heading: {
    fontSize: theme.spacing(1.6),
    fontWeight: 600,
  },

  field: {
    width: "100% !important",
    margin: "8px !important",
  },

  errors: {
    color: "red !important",
    // padding: "2px",
    fontSize: theme.spacing(1.4),
    margin: "0px  8px !important",
  },

  btn: {
    margin: "8px 0px 8px 16px !important",
  },
  cancelbtn: {
    marginRight: "8px !important",
  },
}));

function AddUser({ refetch, id, userInfo, onClose, handleOpen }) {
  const classes = useStyles();
  const [error, setError] = useState("");

  const validationSchema = yup.object().shape({
    // name: yup.string("Enter User Name").min(2, "Minimum 2 Characters!"),

    emailId: yup
      .string("Invalid email address format")
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid email address"
      ),

    // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    //   error = 'Invalid email address';
  });

  let roleField = "";

  if (id === "project") {
    roleField = addProjectOwner;
  } else if (id === "operation") {
    roleField = addOperationEmp;
  } else if (id === "finance") {
    roleField = addFinanceEmp;
  }

  const formik = useFormik({
    initialValues: {
      // name: "",
      emailId: "",
    },

    validationSchema: validationSchema,
  });

  const [
    addEmployee,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(roleField, {
    onCompleted: (e) => {
      refetch();
      formik.resetForm();
      onClose();
      //   dataFetch();
      //   updateInvestorInformationCache(e);
    },
    onError: (e) => {
      setError(e.graphQLErrors[0].message);
      formik.resetForm();
    },
  });

  const addEmployeeAccess = (e) => {
    e.preventDefault();

    addEmployee({
      variables: {
        input: {
          emailid: formik.values.emailId,
          name: "",
        },
      },
    });
  };

  return (
    <>
      {mutationLoading ? <SimpleSnackbar type="loading" /> : null}
      {mutationError ? <SimpleErrorSnackbar msg={error} /> : null}

      <Grid
        container
        md={12}
        direction="row"
        justifyContent="left"
        alignContent="left"
        alignItems="left"
      >
        <Grid container md={5}>
          <Grid item md={12} className={classes.field}>
            <InputField
              label="User Name"
              placeholder="Enter Name"
              required={false}
              fullWidth={true}
              value={formik.values.name}
              onChange={formik.handleChange("name")}
              onBlur={formik.handleBlur("name")}
            />
          </Grid>

          <Grid item md={12}>
            {formik.touched.name && formik.errors.name ? (
              <div className={classes.errors}>{formik.errors.name}</div>
            ) : null}
          </Grid>
        </Grid>

        <Grid container md={6}>
          <Grid item md={12} className={classes.field}>
            <InputField
              label="Email "
              placeholder="Enter Email Address"
              required={true}
              fullWidth={true}
              value={formik.values.emailId}
              onChange={formik.handleChange("emailId")}
              onBlur={formik.handleBlur("emailId")}
            />
          </Grid>

          <Grid item md={12}>
            {formik.touched.emailId && formik.errors.emailId ? (
              <div className={classes.errors}>{formik.errors.emailId}</div>
            ) : null}
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.btn}>
          <Grid item md={1} className={classes.cancelbtn}>
            <OutlinedButton label="Cancel" onClick={onClose} />
          </Grid>

          <Grid item md={1}>
            <OutlinedButton
              label="Add"
              type="contained"
              disabled={!(formik.dirty && formik.isValid)}
              onClick={addEmployeeAccess}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AddUser;

{
  /* <Paper elevation={2} className={classes.paper}> */
}
{
  /* <Grid item md={1}>
          <p className={classes.heading}>Add User</p>
        </Grid> */
}
