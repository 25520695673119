import React from "react";
import PropTypes from "prop-types";

import { Tabs, Tab, Box, Divider } from "@material-ui/core";

import { useStyles } from "./styles";
import MainList from "./MainList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function GroupTabs({
  type,
  data,
  locationsList,
  awardsList,
  partnerList,
  fundingRoundsList,
  yearList,
  maturityList,
  skillList,
  isReadOnly,
  toggleValue,
  typeValue,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.tabRoot}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          className={classes.tabs}
          label={
            toggleValue === "Services" || typeValue === "services"
              ? type === "skill"
                ? "Companies"
                : "Services"
              : toggleValue === "Products" || typeValue === "products"
              ? "Products"
              : ""
          }
          {...a11yProps(0)}
        />

        {type !== "skill" && (
          <Tab
            className={classes.tabs}
            label="Success Stories"
            {...a11yProps(1)}
          />
        )}

        {toggleValue !== "Services" &&
          typeValue !== "services" &&
          type !== "skill" && (
            <Tab className={classes.tabs} label="Resources" {...a11yProps(2)} />
          )}
      </Tabs>
      <Divider />

      <TabPanel className={classes.list} value={value} index={0}>
        <MainList
          type={type}
          tab="products"
          locationsList={locationsList}
          awardsList={awardsList}
          partnerList={partnerList}
          fundingRoundsList={fundingRoundsList}
          yearList={yearList}
          maturityList={maturityList}
          skillList={skillList}
          isReadOnly={isReadOnly}
          toggleValue={toggleValue}
        />
      </TabPanel>

      <TabPanel className={classes.list} value={value} index={2}>
        <MainList
          type={type}
          tab="usecase"
          locationsList={locationsList}
          awardsList={awardsList}
          partnerList={partnerList}
          fundingRoundsList={fundingRoundsList}
          yearList={yearList}
          maturityList={maturityList}
          skillList={skillList}
          isReadOnly={isReadOnly}
          toggleValue={toggleValue}
        />
      </TabPanel>

      <TabPanel className={classes.list} value={value} index={1}>
        <MainList
          type={type}
          tab="caseStudy"
          locationsList={locationsList}
          awardsList={awardsList}
          partnerList={partnerList}
          fundingRoundsList={fundingRoundsList}
          yearList={yearList}
          maturityList={maturityList}
          skillList={skillList}
          isReadOnly={isReadOnly}
          toggleValue={toggleValue}
        />
      </TabPanel>
    </div>
  );
}
