import { useState } from "react";

import PrimaryButton from "../../common/PrimaryButton";

import { Menu, MenuItem } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { useStyles } from "../styles";

import TextComponent from "./TextComponent";
import TextAreaComponent from "./TextAreaComponent";
import Attachment from "./Attachment";
import DropdownComponent from "./DropdownComponent";
import { getItemsInSection } from "../query";
import { useQuery } from "@apollo/client";

export default function SectionComponent({
  section,
  properties,
  onRemove,
  onEdit,
  onAddItem,
  items,
  onEditItemProps,
  onRemoveItem,
}) {
  const classes = useStyles();

  const { data: itemList } = useQuery(getItemsInSection, {
    variables: { input: { vbeid: section.vbeid, timestamp: section.id } },
  });

  const { label } = properties;

  const [anchorEl, setAnchorEl] = useState(null);
  const [sectionItems, setSectionItems] = useState(false);

  if (itemList && itemList.listItem.length !== 0 && !sectionItems) {
    itemList.listItem.forEach((item, index) => {
      if (!item.properties) {
        let itemProps = JSON.parse(item.metadata);
        let itemVbeid = item.vbeid.split("#");

        item = Object.assign({}, item, {
          properties: itemProps,
          id: itemVbeid[3],
          itemId: item.id,
        });
      }

      onAddItem(item);

      if (index === itemList.listItem.length - 1) {
        setSectionItems(true);
      }
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value) => {
    onAddItem(value);
    setAnchorEl(null);
  };

  return (
    <div className={classes.mainSectionDiv}>
      <div className={classes.sectionHeading}>
        {label}
        <span className={classes.itemText}>
          <DeleteIcon onClick={onRemove} />
        </span>

        <span className={classes.itemText}>
          <EditIcon onClick={onEdit} />
        </span>
      </div>

      <div style={{}} className={classes.sectionBody}>
        <div className={classes.sectionButton}>
          <PrimaryButton
            variant="contained"
            label="Add Item"
            onClick={handleClick}
          />

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem value="text" onClick={() => handleSelect("text")}>
              Text
            </MenuItem>

            <MenuItem value="textarea" onClick={() => handleSelect("textarea")}>
              Textarea
            </MenuItem>

            <MenuItem value="dropdown" onClick={() => handleSelect("dropdown")}>
              Multiselect
            </MenuItem>

            <MenuItem value="file" onClick={() => handleSelect("file")}>
              Attachment
            </MenuItem>
          </Menu>
        </div>

        <div>
          {items.map((item, index) => {
            if (item.type === "text") {
              return (
                <TextComponent
                  properties={item.properties}
                  onRemove={() => onRemoveItem(item)}
                  onEdit={() => onEditItemProps(item)}
                />
              );
            } else if (item.type === "textarea") {
              return (
                <TextAreaComponent
                  properties={item.properties}
                  onRemove={() => onRemoveItem(item)}
                  onEdit={() => onEditItemProps(item)}
                />
              );
            } else if (item.type === "file") {
              return (
                <Attachment
                  properties={item.properties}
                  onRemove={() => onRemoveItem(item)}
                  onEdit={() => onEditItemProps(item)}
                />
              );
            } else if (item.type === "dropdown") {
              return (
                <DropdownComponent
                  item={item}
                  properties={item.properties}
                  onRemove={() => onRemoveItem(item)}
                  onEdit={() => onEditItemProps(item)}
                />
              );
            } else return null;
          })}
        </div>
      </div>
    </div>
  );
}
