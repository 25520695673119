/* eslint-disable no-unused-vars */
import * as React from "react";

import { useStyles, LightTooltip, EllipsisText } from "./styles";

import {
  Grid,
  Avatar,
  Paper,
  Chip,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import DialogEdit from "./dialogPopup";
import { VBRIDGEHUB_MEDIAURL } from "../../../constants";
import CustomisedDrawer from "./swipableDrawer";
import LongDescriptionComponent from "./longDescription";
import DataGridValidation from "./dataGrid";
import { productelById, updateProduct } from "./query";
import ProductUpdateForm from "./updateProductForm";
import VbridgeAlert from "../../shared/alert";

export default function ProductValidation({
  productBatch,
  loading,
  updateBatch,
  pageChanging,
  longdesc,
  fetch,
  batchvbeid,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const [postId, setPostId] = React.useState("");

  const client = useApolloClient();

  //getFormik values for updating company information
  function GetFormikValues(targetValues, targetId) {
    setValue(targetValues);
    setPostId(targetId);
  }

  ///update cache on map updation
  function UpdateProductMapCache(mapObj) {
    var updatedObj = mapObj;
    var listObjs = [...productBatch];
    var foundObj = listObjs.filter((x) => x.id === updatedObj.id);
    var foundIndex = listObjs.findIndex((x) => x.id === updatedObj.id);

    let topics = foundObj[0].topiclist;
    let newTopics = mapObj.listdetails;
    let topicListUpdated = [...topics, ...newTopics];

    var finalObj = {
      name: foundObj[0].name,
      companyname: foundObj[0].companyname,
      id: foundObj[0].id,
      image: foundObj[0].image,
      longdescription: foundObj[0].longdescription,
      producturl: foundObj[0].producturl,
      shortdescription: foundObj[0].shortdescription,
      topiclist: topicListUpdated,
      __typename: "product",
    };

    listObjs[foundIndex] = finalObj;
    updateBatch([...listObjs]);
  }

  //update obj for unmapping the topic
  function UpdateProductUnMapCache(unmapObj) {
    var updatedObj = unmapObj;
    var listObjs = [...productBatch];
    var foundObj = listObjs.filter((x) => x.id === updatedObj.id);
    var foundIndex = listObjs.findIndex((x) => x.id === updatedObj.id);

    let arrayOne = foundObj[0].topiclist;
    let arrayTwo = unmapObj.listdetails;

    let results = arrayOne.filter(
      (value1) => !arrayTwo.some((value2) => value2.vbeid === value1.vbeid)
    );

    var finalObj = {
      name: foundObj[0].name,
      companyname: foundObj[0].companyname,
      id: foundObj[0].id,
      image: foundObj[0].image,
      longdescription: foundObj[0].longdescription,
      producturl: foundObj[0].producturl,
      shortdescription: foundObj[0].shortdescription,
      topiclist: results,
      __typename: "product",
    };

    listObjs[foundIndex] = finalObj;
    updateBatch([...listObjs]);
  }

  //popup open for edit company details
  const [popup, setPopup] = React.useState(false);
  const [param, setParam] = React.useState("");

  function OpenPopup(e) {
    setPopup(true);
  }

  function ClosePopup() {
    setPopup(false);
  }

  //popup open for edit company details
  const [popupSimilar, setPopupSimilar] = React.useState(false);

  function ClosePopupSimilar() {
    setPopupSimilar(false);
  }

  //swipable drawer edit for long description
  const [drawer, setDrawer] = React.useState(false);
  const [productData, setProductData] = React.useState("");

  //to get extracted long description

  const { data } = useQuery(productelById, {
    variables: { input: { vbeid: productData.id } },
  });

  /////update company mutation
  const [
    postProductMutation,
    { data: productUpdated, error: productUpdationError },
  ] = useMutation(updateProduct, {
    onCompleted: (e) => {
      UpdateProductCache(e);
    },
  });

  //post product info

  const postProduct = (e) => {
    postProductMutation({
      variables: {
        input: {
          name: value.name,
          shortdescription: value.shortdescription,
          producturl: value.producturl,
          vbeid: postId,
        },
      },
    });
  };

  // update cache
  function UpdateProductCache(e) {
    var updatedObj = e.updateProduct;
    var listObjs = [...productBatch];
    var foundIndex = listObjs.findIndex((x) => x.id === updatedObj.id);
    listObjs[foundIndex] = updatedObj;
    updateBatch([...listObjs]);
  }

  //update cache for longdescription
  const UpdateProductLongDescCache = (e) => {
    let UpdatedlongDescData = e.updateProduct.longdescription;
    let updateLongDesc = { ...data.productelById };

    updateLongDesc.longdescription = UpdatedlongDescData;

    client.writeQuery({
      query: productelById,
      data: {
        productelById: updateLongDesc,
      },
      variables: { input: { vbeid: productData.id } },
    });
  };

  function OpenDrawer(e) {
    setDrawer(true);
  }

  function CloseDrawer() {
    setDrawer(false);
  }

  //update individual param
  function oncelledit(param) {
    postProductMutation({
      variables: {
        input: { vbeid: param.id, [param.field]: param.props.value },
      },
    });
  }

  //vertical display in data grid
  function VerticalDisplay({ vertical }) {
    if (vertical !== null) {
      return vertical.map((verticalVal) => {
        return (
          <LightTooltip title={verticalVal.name} placement="bottom">
            <Chip
              label={<EllipsisText>{verticalVal.name}</EllipsisText>}
              size="small"
            />
          </LightTooltip>
        );
      });
    } else {
      return null;
    }
  }

  //product columns
  const columns = [
    {
      field: "image",
      headerName: "Company Name",
      width: 200,
      renderCell: (params) => (
        <Grid container md={12} spacing={1}>
          <Grid item md={2} className={classes.logoAlign}>
            <Avatar
              className={classes.logoSize}
              variant="square"
              src={VBRIDGEHUB_MEDIAURL + "/" + params.value}
            >
              {":("}
            </Avatar>
          </Grid>

          <Grid item md={10}>
            <LightTooltip title={params.row.companyname} placement="bottom">
              <Typography variant="caption">
                {params.row.companyname}
              </Typography>
            </LightTooltip>
          </Grid>
        </Grid>
      ),
    },

    {
      field: "name",
      headerName: "Product Name",
      width: 200,
      renderCell: (params) => (
        <Grid container md={12} spacing={1}>
          <Grid item md={10}>
            <div
              onClick={() => {
                OpenPopup();
                setParam(params);
              }}
            >
              <Typography
                variant="caption"
                color="primary"
                className={classes.iconPointer}
              >
                {params.row.name}
              </Typography>
            </div>
          </Grid>
        </Grid>
      ),
    },

    {
      field: "shortdescription",
      headerName: " Short Description",
      width: 260,
      renderCell: (params) => (
        <div>
          <LightTooltip title={params.value} placement="bottom-start">
            <Typography variant="caption">{params.value}</Typography>
          </LightTooltip>
        </div>
      ),
    },

    {
      field: "topiclist",
      headerName: "Category",
      width: 160,
      renderCell: (params) => (
        <div>
          <VerticalDisplay vertical={params.value} />
        </div>
      ),
    },

    {
      field: "producturl",
      headerName: "URL",
      width: 80,
      renderCell: (params) => (
        <LanguageIcon
          onClick={(event) => window.open(params.value, "_blank")}
        />
      ),
    },

    {
      field: "longdescription",
      headerName: "Long Description",
      width: 170,
      renderCell: (params) => (
        <div
          onClick={() => {
            OpenDrawer();
            setProductData(params);
          }}
        >
          <Typography
            variant="caption"
            color="primary"
            className={classes.iconPointer}
          >
            Long Description
          </Typography>
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      width: 170,
      renderCell: (params) => (
        <Typography variant="caption" color="primary">
          {params.row.longdescriptionupdated === true ||
          [
            "TV-Hospitality-Test-zdbcyytbul",
            "TV-BigDataAnalytics-3lvmcgdpgg",
            "TV-CASB-Test-2-s7fqpgrv1p",
            "TV-iPaas-AppDeliverNet-ContIntSoftware-9aivwkan5p",
            "TV-Devops-ContInt-AppLife-ContDep-ContDel-buaagygwud",
            "TV-ITFM-ITSM-UITM-kgksulqgu",
            "SP-List1-k11x5g0dvd",
            "TV-ApiManPlat-ApiTool-p3ehxbhb6",
            "TV-DAMAC-DecRequirement-SCM-GRC-lmfacfktlk",
          ].includes(batchvbeid) ? (
            <LightTooltip title="Edited description">
              <Chip label={<EllipsisText>Yes</EllipsisText>} size="small" />
            </LightTooltip>
          ) : (
            <LightTooltip title="Long description not yet edited">
              <Chip label={<EllipsisText>No</EllipsisText>} size="small" />
            </LightTooltip>
          )}
        </Typography>
      ),
    },
  ];

  return (
    <>
      {popup ? (
        <DialogEdit
          buttonMainText={() => {}}
          buttonText1="Cancel"
          openPop={popup}
          closePop={ClosePopup}
          buttonText2="Update"
          updateMethod={postProduct}
          dialogTitle="Update Product Details"
          dialogMainContent={
            <ProductUpdateForm
              method={GetFormikValues}
              params={param.row}
              updateMapCache={UpdateProductMapCache}
              updateUnMapCache={UpdateProductUnMapCache}
            />
          }
        />
      ) : null}

      {drawer ? (
        <>
          <CustomisedDrawer
            buttonMainText="Long Description"
            openDrawer={drawer}
            closeDrawer={CloseDrawer}
            sliderText="Update Product Long Description"
            sliderComponent={
              <>
                {data ? (
                  data?.productelById &&
                  data.productelById.longdescription !== "" ? (
                    <LongDescriptionComponent
                      vbeid={productData.row.id}
                      longContent={data?.productelById.longdescription}
                      updateCache={(e) => UpdateProductLongDescCache(e)}
                      queryName={updateProduct}
                      fetch={fetch}
                    />
                  ) : (
                    <LongDescriptionComponent
                      vbeid={productData.row.id}
                      longContent={data?.productelById.elongdescription}
                      updateCache={(e) => UpdateProductLongDescCache(e)}
                      queryName={updateProduct}
                      fetch={fetch}
                    />
                  )
                ) : (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </>
            }
          />
        </>
      ) : null}

      {popupSimilar ? (
        <CustomisedDrawer
          buttonMainText="View Similars"
          openDrawer={popupSimilar}
          closeDrawer={ClosePopupSimilar}
          sliderText="Update Product similars"
        />
      ) : null}
      <Grid container>
        <Grid item xs={3}>
          {productUpdationError ? (
            <VbridgeAlert
              severity="error"
              open={true}
              message="Something went wrong."
            />
          ) : null}
          {productUpdated ? (
            <VbridgeAlert
              severity="success"
              open={true}
              message="Product Information Updated successfully"
            />
          ) : null}
        </Grid>
      </Grid>
      <Paper className={classes.dataGridPaper}>
        <DataGridValidation
          onEdit={oncelledit}
          data={productBatch}
          columns={columns}
          pageChange={pageChanging}
          loading={loading}
          method={() => {}}
          updateQueryName={updateProduct}
        />
      </Paper>
    </>
  );
}
