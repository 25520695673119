import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import Products from "./tabs/tablists/listproducts";
import UseCases from "./tabs/tablists/listusecases";
import CaseStudies from "./tabs/tablists/listcasestudies";
import { a11yProps } from "../../../functions/helper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
 

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     "aria-controls": `scrollable-auto-tabpanel-${index}`,
//   };
// }

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",

    marginTop: theme.spacing(1),
    //backgroundColor:'white'
  },
  tabs: {
    fontSize: "0.7em",
    "& span": {
      textTransform: "capitalize",
    },
  },
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab className={classes.tabs} label="Products List" {...a11yProps(0)} />
        <Tab className={classes.tabs} label="Usecases List" {...a11yProps(1)} />
        <Tab
          className={classes.tabs}
          label="Casestudy List"
          {...a11yProps(2)}
        />
      </Tabs>
      <Divider />

      <TabPanel value={value} index={0}>
        <Products />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <UseCases />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <CaseStudies />
      </TabPanel>
    </div>
  );
}
