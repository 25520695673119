import React from "react";

import { useStyles } from "./styles";

import {
    Grid,
    Button
  } from "@material-ui/core";


export default function Footer(props){
   
    const classes = useStyles();


    return(
        <Grid
        container
        justify="flex-start"
        className={classes.footerButtons}
      >
        <Grid item md={6}>
        <Button
            className={classes.buttonStyle}
            onClick={() => {props.method1();}}
            variant="contained"
            color="primary"
          >
            {props.buttonText1}
          </Button>
          </Grid>

          <Grid item md={6}>

<Button
className={classes.buttonStyle}
disabled={props.disabled}
onClick={() => {props.method2();}}
variant="contained"
color="primary"
>
{props.buttonText2}
</Button>
</Grid>

</Grid>

    )
}