import { gql } from "@apollo/client";

export const getCasestudyById = gql`
  query casestudyinformation($input: casestudyinput!) {
    casestudyByID(input: $input) {
      name
      image
      shortdescription
      companyvbeid
      companyname
      filepath
      vbeid
      id
      businessvertical
      filepath
      vbeid
      longdescription
      url
      productlist {
        vbeid
        name
      }
    }
  }
`;

export const userInfoQuery = gql`
  query userinfo {
    getLoginUserInfo(input: {}) {
      vbeid
      name
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
      getLoginUserInfo
    }
  }
`;

export const updateCaseStudy = gql`
  mutation update($input: casestudyinput!) {
    updatecasestudy(input: $input) {
      name
      shortdescription
    }
  }
`;

export const getCasestudyByCasestudyId = gql`
  query casestudyinformation($input: casestudyinput!) {
    casestudysBycasestudyID(input: $input) {
      name
      image
      vbeid
      id
    }
  }
`;

export const signed = gql`
  mutation upload($input: projectinput!) {
    signedURLforSupportDoc(input: $input) {
      signedurl
      id
      path
      name
    }
  }
`;

export const listDocs = gql`
  query docs($input: projectinput!) {
    listsupportDoc(input: $input) {
      name
      signedurl
      createdby
      id
    }
  }
`;

export const deleteDocument = gql`
  mutation update($input: projectinput!) {
    deleteSupportDoc(input: $input) {
      id
    }
  }
`;

export const getSigned = gql`
  query upload($input: s3input!) {
    getSignedURL(input: $input) {
      signedurl
    }
  }
`;

export const updateCaseStudyFile = gql`
  mutation update($input: casestudyinput!) {
    updatecasestudy(input: $input) {
      filepath
      longdescription
      vbeid
    }
  }
`;
