import React from "react";
import { useHistory } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

import { VBRIDGEHUB_MEDIAURL } from "../../../../constants";

import { useStyles } from "./styles";

export default function OtherProductsList({ data, vbeid, userCompany }) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      {data ? (
        <List className={classes.productsListRoot}>
          {data.map((value) => {
            if (value.id !== vbeid) {
              return (
                <ListItem
                  //   role={undefined}
                  dense
                  button
                  onClick={() => {
                    if (value.vbeid === userCompany) {
                      history.push("/mycompany/" + value.vbeid);
                    } else history.push(value.vbeid);
                  }}
                  className={classes.productItem}
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="square"
                      src={VBRIDGEHUB_MEDIAURL + "/" + value.image}
                      className={classes.companyImage}
                    >
                      {":("}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText primary={value.name} />
                </ListItem>
              );
            } else return null;
          })}
        </List>
      ) : null}
    </>
  );
}
