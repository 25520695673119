import * as React from "react";

import { useStyles } from "./styles";

import { Grid } from "@material-ui/core";

import { useFormik } from "formik";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";

import { getProducts, getBatches } from "./query";
import ProductFilters from "./productFilters";
import ProductValidation from "./productValidation";

export default function ProductGrid() {
  const classes = useStyles();
  const [productBatch, setProductBatch] = React.useState([]);
  const client = useApolloClient();

  //filter params and updateForm values
  const formik = useFormik({
    initialValues: {
      pagefrom: "",
      pageto: "",
      batchvbeid: "",
      orderby: "",
    },
  });

  const [pageOffset, setPageOffset] = React.useState("");

  //getBatches
  const { data: listBatches } = useQuery(getBatches);

  //query to get the companies list
  const [getProductResults, { loading, refetch }] = useLazyQuery(getProducts, {
    onCompleted: (e) => {
      setProductBatch(e.getProductsByBatch);
    },
  });

  //function to query the company batch results
  function QueryBatch() {
    getProductResults({
      variables: {
        input: {
          offset: formik.values.pagefrom,
          limit: formik.values.pageto,
          batchvbeid: formik.values.batchvbeid,
          orderby: formik.values.orderby,
        },
      },
    });

    setPageOffset(formik.values.pageto);
  }

  //query to get the companies list
  const [getProductPageChangeResults] = useLazyQuery(getProducts, {
    onCompleted: (e) => {
      setProductBatch([...productBatch, ...e.getProductsByBatch]);
    },
  });

  //onpage change in datagrid api call
  function QueryBatchOnPageChange(e) {
    getProductPageChangeResults({
      variables: {
        input: {
          offset: pageOffset,
          limit: formik.values.pageto,
          batchvbeid: formik.values.batchvbeid,
          orderby: formik.values.orderby,
        },
      },
    });
    setPageOffset(pageOffset + formik.values.pageto);
  }

  return (
    <Grid container md={12} className={classes.dataGridAlign}>
      <Grid item md={10}>
        {listBatches ? (
          <ProductFilters
            batches={listBatches.getBatchesList.length >= 1 ? listBatches : ""}
            formik={formik}
            method={QueryBatch}
          />
        ) : null}
      </Grid>

      <Grid item md={12}>
        <ProductValidation
          productBatch={productBatch}
          loading={loading}
          fetch={refetch}
          updateBatch={setProductBatch}
          pageChanging={QueryBatchOnPageChange}
          batchvbeid={formik.values.batchvbeid}
        />
      </Grid>
    </Grid>
  );
}
