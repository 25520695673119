import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "70px",
    padding: theme.spacing(0, 4),
  },
  blockBtn: {
    textTransform: "capitalize",
    padding: theme.spacing(0, 1),
  },
  dataGridDiv: {
    margin: theme.spacing(1, 0),
  },
  templateOptionsDiv: {
    marginTop: theme.spacing(8),
    textAlign: "left",
  },
  templateOptionsList: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
    fontSize: "0.9rem",
    cursor: "pointer",
  },
  selectedOption: {
    background: "#efefef",
    borderRadius: "5px",
    fontWeight: "500",
  },
  templatesDiv: {
    marginTop: theme.spacing(2),
  },
  tabs: {
    textTransform: "capitalize",
  },
  createTemplate: {
    "& .MuiDrawer-paper": {
      width: "65%",
      padding: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1, 0),
  },
  status: {
    textTransform: "uppercase",
    paddingLeft: "10px",
  },
  dividerOne: {
    marginTop: "16px",
  },
  dividerTwo: {
    marginTop: "24px",
  },
  selectStyle: {
    marginLeft: "16px",
    width: "30%",
  },
  sectionMain: {
    margin: "8px",
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
  },
  message: {
    fontSize: "0.85rem",
  },
  helpButton: {
    "& span svg": {
      width: "15px",
      height: "15px",
    },
  },
  selectPreview: {
    display: "flex",
    alignItems: "center",
    "& .MuiInputBase-root": {
      width: "40%",
    },
  },
  formDiv: {
    border: "1px solid #999",
    borderRadius: "3px",
    textAlign: "left",
    padding: "8px",
    marginBottom: "12px",
  },
  componentItem: {
    border: "1px solid #999",
    borderRadius: "3px",
    padding: "8px",
    margin: "4px",
    cursor: "pointer",
  },
  itemText: {
    color: "#999",
    fontSize: "0.75rem",
    float: "right",
    "& svg": {
      width: "18px",
      height: "18px",
      marginLeft: "12px",
      cursor: "pointer",
    },
  },
  textField: {
    margin: "0 8px",
    background: "#E7E7E7",
    padding: "8px",
    borderRadius: "3px",
    "& label": {
      margin: "4px 0",
    },
    "& input": {
      padding: "8px 12px",
    },
  },
  editField: {
    padding: "8px",
    borderRadius: "3px",
    width: "60%",
    "& input": {
      padding: "8px 12px",
    },
  },
  sectionDiv: {
    backgroundColor: "#d8d8d8",
    marginTop: "12px",
  },
  itemList: {
    padding: "4px 12px",
  },
  sectionHeading: {
    margin: "8px 0 4px",
  },
  sectionBody: {
    border: "1px solid #999",
    borderRadius: "3px",
    minHeight: "100px",
    padding: "8px",
    marginBottom: "12px",
  },
  sectionButton: {
    minHeight: "40px",
  },
  editHeading: {
    marginTop: "12px",
  },
  editHeadingDropdown: {
    margin: "8px",
  },
  editButton: {
    display: "flex",
    alignItems: "left",
  },
  mainSectionDiv: {
    marginBottom: "20px",
  },
  previewBtn: {
    "& button": {
      marginBottom: "8px",
    },
  },
  templateName: {
    cursor: "pointer",
    fontWeight: "500",
  },
  inputPreview: {
    display: "flex",
    alignItems: "center",
    margin: "12px 0",
  },
  itemsPreviewDiv: {
    marginBottom: "0 16px 8px",
  },
  sectionList: {
    margin: "16px",
  },
}));
