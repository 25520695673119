import { gql } from "@apollo/client";

export const RecListBatches = gql`
  query recListingBatches($input: projectinputV1!) {
    RecListBatches(input: $input) {
      id
      vbeid
      name
    }
  }
`;

export const RecGetProductsbyMappedType = gql`
  query recGetProducts($input: projectinputV1!) {
    RecGetProductsbyMappedType(input: $input) {
      id
      vbeid
      image
      name
      shortdescription
      topiclist {
        vbeid
        name
      }
    }
  }
`;

export const ReclistSimilarProductsByProduct = gql`
  query recGetProducts($input: productinput!) {
    ReclistSimilarProductsByProduct(input: $input) {
      id
      vbeid
      name
      image
      shortdescription
      topiclist {
        vbeid
        name
      }
    }
  }
`;

export const ProductsToCategoryRecMap = gql`
  mutation mapToTopics($input: projectinputV1!) {
    RecMapProductsToCategory(input: $input) {
      responsestatus
    }
  }
`;

export const RecRemoveMapProductsToCategory = gql`
  mutation RecRemoveMappedProducts($input: projectinputV1!) {
    RecRemoveMapProductsToCategory(input: $input) {
      responsestatus
    }
  }
`;
