import React, { useState } from "react";

import { Button, Checkbox, Grid, makeStyles, Tooltip } from "@material-ui/core";
import {
  CasestudyByProducts,
  CustomersByCompany,
  InvestorsByCompany,
  PartnersByCompany,
} from "./CompareProductProperties";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  croot: {
    padding: "0px 0px 16px 0px",
    alignContent: "center",

    "& > *": {
      width: "fit-content",
    },
  },
  names: {
    fontSize: "13px",
    fontWeight: 500,
    border: "1px solid #DCDCDC",
    //backgroundColor: "#E6E9DF",
    backgroundColor: "#eee",
    padding: "8px",
    alignItems: "center",
    display: "flex",
    color: "black",
  },

  //backgroundColor: "#dfe8f1",
  //border: "1px solid #808080",
  //backgroundColor: "#f3ecf2",

  rownames: {
    fontSize: "13px",
    fontWeight: 600,
    border: "1px solid #DCDCDC",
    backgroundColor: "#232961",
    padding: "4px 4px 8px 4px",
    alignContent: "center",
    alignItems: "center",
    color: "white",

    "& svg": {
      color: "white",
      fontSize: "14px !important",

      "&:hover": {
        color: "red",
      },
    },
  },

  columns: {
    fontWeight: 400,
    border: "1px solid #DCDCDC",
    backgroundColor: "#ffffff",
    padding: "8px",
    color: "#000000",
    fontSize: "12px",

    // "& > *": {
    //   fontSize: "12px",
    // },
  },

  noData: {
    fontSize: "16px",
    fontWeight: 600,
    border: "1px solid #DCDCDC",
    backgroundColor: "#232961",
    padding: "16px",
    alignItems: "center",
    display: "flex",
    color: "white",
  },

  container: {
    justifyContent: "center",
  },
  checkbox: {
    fontSize: "12px",
  },

  rmvbtn: {
    cursor: "pointer",
    minWidth: "2px !important",
    padding: "0px 2px",

    "& span": {
      fontSize: "20px",
      color: "#720000",
    },
  },

  datanotthere: {
    color: "Grey",
    fontWeight: 100,
  },

  pnames: {
    display: "grid",
  },
  cboxname: {
    display: "flex",
    alignItems: "center",
  },
  h3tag: {
    padding: "2px 26px",
  },
}));

export default function CompareProductData({
  productName,
  checkedList,
  searchContext,
  setWidthPaper,
}) {
  const classes = useStyles();

  const [values, setValues] = useState([]);
  const [checkValue, setCheckValue] = useState(false);
  const [checked, setChecked] = useState([]);
  const [index, setIndex] = useState([]);

  let productData = [];

  productName.forEach((product) => {
    productData.push({
      comapnyName: product.companyname,
      location: product.companylocation,
      name: product.name,
      id: product.id,
      companyid: product.companyvbeid,
      founded: product.founded,
      fundround: product.fundround,
      shortdesc: product.shortdescription,
    });
  });

  const [newData, setNewData] = useState(productData);

  const handleClick = (pid) => {
    const currentIndex = checked.indexOf(pid);

    const newChecked = [...checked];

    const checkedValues = [];

    if (currentIndex === -1) {
      newChecked.push(pid);

      newChecked.map((pid) => {
        return checkedValues.push(pid);
      });
    } else {
      newChecked.splice(currentIndex, 1);

      newChecked.map((pid) => {
        return checkedValues.push(pid);
      });
    }

    setChecked(newChecked);
    checkedList(newChecked);
    setValues(checkedValues);
    setIndex(currentIndex);

    if (currentIndex === -1) {
      setCheckValue(true);
    } else if (currentIndex >= 1) {
      setCheckValue(true);
    } else if (newChecked.length < 1) {
      setCheckValue(false);
    }
  };

  const removeColumn = (prodid) => {
    const pindex = newData.findIndex((product) => product.id === prodid);

    if (pindex > -1 && newData.length > 2) {
      let updatedData = [];
      updatedData = newData.filter((data, index) => index !== pindex);
      setNewData(updatedData);
    }
  };

  setWidthPaper(newData);

  return (
    <div className={classes.croot}>
      {newData.length <= 4 ? (
        <Grid container>
          <Grid container direction="row" className={classes.container}>
            <Grid
              item
              md={newData.length > 3 ? 2 : 3}
              className={classes.noData}
            ></Grid>

            {newData.map((prod) => {
              return (
                <Grid
                  item
                  md={newData.length > 3 ? 2 : 3}
                  className={classes.rownames}
                >
                  <div className={classes.pnames}>
                    <div>
                      {newData.length > 2 ? (
                        <Tooltip title="Remove Product ">
                          <Button
                            className={classes.rmvbtn}
                            onClick={() => removeColumn(prod.id)}
                          >
                            <HighlightOffIcon size="small" />
                          </Button>
                        </Tooltip>
                      ) : null}
                    </div>

                    <div className={classes.cboxname}>
                      <Checkbox
                        classes={classes.checkbox}
                        onClick={() => handleClick(prod.id)}
                      />
                      {prod.name}
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>

          <Grid container direction="row" className={classes.container}>
            <Grid
              item
              md={newData.length > 3 ? 2 : 3}
              className={classes.names}
            >
              Company Name
            </Grid>
            {newData.map((prod) => {
              return (
                <Grid
                  item
                  md={newData.length > 3 ? 2 : 3}
                  className={classes.columns}
                >
                  {prod.companyname === null ? "No data" : prod.comapnyName}
                </Grid>
              );
            })}
          </Grid>

          <Grid container direction="row" className={classes.container}>
            <Grid
              item
              md={newData.length > 3 ? 2 : 3}
              className={classes.names}
            >
              Short Description
            </Grid>

            {newData?.map((prod) => {
              return (
                <Grid
                  item
                  md={newData.length > 3 ? 2 : 3}
                  className={classes.columns}
                >
                  {prod.shortdesc === null || prod.shortdesc === "" ? (
                    <i className={classes.datanotthere}>No Data</i>
                  ) : (
                    prod.shortdesc
                  )}
                </Grid>
              );
            })}
          </Grid>

          <Grid container direction="row" className={classes.container}>
            <Grid
              item
              md={newData.length > 3 ? 2 : 3}
              className={classes.names}
            >
              Location
            </Grid>

            {newData?.map((prod) => {
              return (
                <Grid
                  item
                  md={newData.length > 3 ? 2 : 3}
                  className={classes.columns}
                >
                  {prod.location === null ? (
                    <i className={classes.datanotthere}>No Data</i>
                  ) : (
                    prod.location
                  )}
                </Grid>
              );
            })}
          </Grid>

          <Grid container direction="row" className={classes.container}>
            <Grid
              item
              md={newData.length > 3 ? 2 : 3}
              className={classes.names}
            >
              Founded Year
            </Grid>

            {newData?.map((prod) => {
              return (
                <Grid
                  item
                  md={newData.length > 3 ? 2 : 3}
                  className={classes.columns}
                >
                  {prod.founded === null || prod.founded === undefined ? (
                    <i className={classes.datanotthere}>No Data</i>
                  ) : (
                    prod.founded
                  )}
                </Grid>
              );
            })}
          </Grid>

          <Grid container direction="row" className={classes.container}>
            <Grid
              item
              md={newData.length > 3 ? 2 : 3}
              className={classes.names}
            >
              Funding Stage
            </Grid>

            {newData?.map((prod) => {
              return (
                <Grid
                  item
                  md={newData.length > 3 ? 2 : 3}
                  className={classes.columns}
                >
                  {prod.fundround === null ? (
                    <i className={classes.datanotthere}>No Data</i>
                  ) : (
                    prod.fundround
                  )}
                </Grid>
              );
            })}
          </Grid>

          <Grid container direction="row" className={classes.container}>
            <Grid
              item
              md={newData.length > 3 ? 2 : 3}
              className={classes.names}
            >
              Investor
            </Grid>

            {newData.map((prod) => {
              return (
                <Grid
                  item
                  md={newData.length > 3 ? 2 : 3}
                  className={classes.columns}
                >
                  <InvestorsByCompany props={prod.companyid} />
                </Grid>
              );
            })}
          </Grid>

          <Grid container direction="row" className={classes.container}>
            <Grid
              item
              md={newData.length > 3 ? 2 : 3}
              className={classes.names}
            >
              Customers
            </Grid>

            {newData.map((prod) => {
              return (
                <Grid
                  item
                  md={newData.length > 3 ? 2 : 3}
                  className={classes.columns}
                >
                  <CustomersByCompany props={prod.companyid} />
                </Grid>
              );
            })}
          </Grid>

          <Grid container direction="row" className={classes.container}>
            <Grid
              item
              md={newData.length > 3 ? 2 : 3}
              className={classes.names}
            >
              Partners
            </Grid>

            {newData.map((prod) => {
              // console.log("prod", prod);
              return (
                <Grid
                  item
                  md={newData.length > 3 ? 2 : 3}
                  className={classes.columns}
                >
                  <PartnersByCompany props={prod.companyid} />
                </Grid>
              );
            })}
          </Grid>

          <Grid container direction="row" className={classes.container}>
            <Grid
              item
              md={newData.length > 3 ? 2 : 3}
              className={classes.names}
            >
              Success Story
            </Grid>

            {newData.map((prod) => {
              return (
                <Grid
                  item
                  md={newData.length > 3 ? 2 : 3}
                  className={classes.columns}
                >
                  <CasestudyByProducts
                    productid={prod.id}
                    companyid={prod.companyid}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      ) : (
        <div className={classes.h3tag}>
          <h3>You can compare a maximum of 4 products at a time.</h3>
        </div>
      )}
    </div>
  );
}
