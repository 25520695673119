import React from "react";

import { Button, Divider, makeStyles, Typography } from "@material-ui/core";
import {
  getCustomersByCompanyId,
  getPartnersByCompanyId,
} from "../groups2.0/query";
import { getCasestudiesByProductId } from "../products/product2.0/query";

import { useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { getInvestorsByCompanyId } from "../companies/Company2.0/query";

const useStyles = makeStyles((theme) => ({
  listusecasename: {},
  datanotthere: {
    color: "Grey",
    fontWeight: 100,
  },
  typography: {
    fontSize: "12px !important",
    cursor: "pointer",
    display: "flex",
    padding: "0px 4px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Roboto"',
      '"Oxygen"',
      '"Ubuntu"',
      '"Cantarell"',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      "sans-serif",
    ].join(","),

    "&:hover": {
      color: "teal",
    },
  },

  stypography: {
    fontSize: "10px !important",
    cursor: "pointer",
    display: "flex",
    padding: "8px 4px  4px 4px !important",
    color: "#4179BD",
  },
}));

export const CasestudyByProducts = ({ productid, companyid }) => {
  const history = useHistory();
  const classes = useStyles();

  const { loading, error, data } = useQuery(getCasestudiesByProductId, {
    variables: { input: { productid: productid } },
  });

  return (
    <div className={classes.listusecasename}>
      {data?.casestudyByProductId.length !== 0 ? (
        data?.casestudyByProductId.map((caseStudy, index) => {
          return (
            <>
              {" "}
              {index <= 2 ? (
                <>
                  <Typography
                    className={classes.typography}
                    onClick={() => {
                      history.push(window.open("casestudy/" + caseStudy.vbeid));
                    }}
                  >
                    <li>{caseStudy.name}</li>
                  </Typography>
                  <Divider />
                </>
              ) : null}
            </>
          );
        })
      ) : (
        <i className={classes.datanotthere}>No Data</i>
      )}

      {data?.casestudyByProductId.length > 3 ? (
        <Typography
          className={classes.stypography}
          onClick={() => {
            history.push(
              window.open("company/" + companyid + "/SuccessStories")
            );
          }}
        >
          Other Success Stories
        </Typography>
      ) : null}
    </div>
  );
};

// Partners

export const PartnersByCompany = ({ props }) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(getPartnersByCompanyId, {
    variables: { input: { vbeid: props } },
  });

  return (
    <div className={classes.listusecasename}>
      {data?.partnersByCompanyId.length !== 0 ? (
        data?.partnersByCompanyId.map((partner) => {
          return <>{<li>{partner.name}</li>}</>;
        })
      ) : (
        <i className={classes.datanotthere}>No Data</i>
      )}
    </div>
  );
};

//Customers

export const CustomersByCompany = ({ props }) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(getCustomersByCompanyId, {
    variables: { input: { vbeid: props } },
  });

  return (
    <div className={classes.listusecasename}>
      {data?.customersByCompanyId.length !== 0 ? (
        data?.customersByCompanyId.map((customer) => {
          return (
            <li>
              {customer.length === 0 || customer === undefined
                ? "No Data"
                : customer.name}
            </li>
          );
        })
      ) : (
        <i className={classes.datanotthere}>No Data</i>
      )}
    </div>
  );
};

//

// const getInvestorsByCompanyId=gql`
// query investorsinformation($input:companyinput!) {
// investorsByCompanyId(input: $input) {
//   id
//   name
//   image
//   shortdescription
//   longdescription
//   vbeid
//   founder
//   founded
//   location
//   linkedinurl
//   facebookurl
//   twitterurl
//   companyurl

// }
// }
// `;

// Investor

export const InvestorsByCompany = ({ props }) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(getInvestorsByCompanyId, {
    variables: { input: { vbeid: props } },
  });

  return (
    <div className={classes.listusecasename}>
      {data?.investorsByCompanyId.length !== 0 ? (
        data?.investorsByCompanyId.map((investor) => {
          return (
            <li>
              {investor.length === 0 || investor === undefined
                ? "No Data"
                : investor.name}
            </li>
          );
        })
      ) : (
        <i className={classes.datanotthere}>No Data</i>
      )}
    </div>
  );
};
