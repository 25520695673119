import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  Button,
  CircularProgress,
  Drawer,
  Grid,
  Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useMutation, useQuery } from "@apollo/client";

import PreviewTemplate from "../templates/desing/preview";

import { GET_TEMPLATES, MAP_TEMPLATE_TO_PROJECT } from "../../queries/query";

import PrimaryButton from "../../../common/PrimaryButton";

import { useStyles } from "./styles";
import ProjectConfirm from "../../../common/ProjectConfirm";

export default function TemplateTableContainer({
  id,
  forList,
  status,
  refetch,
  selectedTemplate,
  templateType,
  projectData,
}) {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_TEMPLATES, {
    variables: {
      input: {
        typelist: [id],
        statuslist: [status],
        forlist: forList,
      },
    },
  });

  return (
    <TableContainer component="div" className={classes.tableContainer}>
      <Table
        stickyHeader
        className={classes.table}
        aria-label="simple table"
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>Template</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>

        {loading && <caption>Loading...</caption>}
        {error && <caption>Something went wrong</caption>}

        {data ? (
          <TableBody className={classes.tablebody}>
            {data.listTemplates.map((template, index) => (
              <Template
                key={index}
                template={template}
                refetch={refetch}
                selectedTemplate={selectedTemplate}
                templateType={templateType}
                projectData={projectData}
              />
            ))}
          </TableBody>
        ) : null}
      </Table>
    </TableContainer>
  );
}

const Template = ({
  key,
  template,
  refetch,
  selectedTemplate,
  templateType,
  projectData,
}) => {
  const classes = useStyles();
  const { id } = useParams();

  const [state, setState] = useState(false);
  const [open, setOpen] = useState(false);

  const { vbeid, name, shortdescription, createdby, type } = template;

  const [addTemplate, { loading }] = useMutation(MAP_TEMPLATE_TO_PROJECT, {
    onCompleted: (e) => {
      refetch();
      setOpen(false);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(!open);
  };

  const onSelectClick = () => {
    setOpen(true);
  };

  const onSelectConfirm = () => {
    addTemplate({
      variables: { input: { templatevbeid: vbeid, vbeid: id } },
    });
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
        <Grid container className={classes.preview}>
          <Grid item xs={12}>
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              onClick={() => {
                setState(false);
              }}
            >
              Close
            </Button>
          </Grid>

          <Grid item xs={12}>
            <PreviewTemplate
              id={vbeid}
              setState={setState}
              type={type}
              setSweight={(e) => {}}
            />
          </Grid>
        </Grid>
      </Drawer>

      <TableRow key={key}>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle2" gutterBottom>
            {name}
          </Typography>

          <Typography variant="caption" display="block" gutterBottom>
            {shortdescription}
          </Typography>

          <>
            <Typography variant="caption" display="inline" gutterBottom>
              Authored by -{" "}
            </Typography>

            <Typography
              variant="caption"
              color="secondary"
              display="inline"
              gutterBottom
            >
              {createdby.includes("vbridgehub.com") ? "vBridge Hub" : createdby}
            </Typography>
          </>
        </TableCell>

        <TableCell>
          <div>
            <PrimaryButton
              variant="outlined"
              size="small"
              label="Preview"
              onClick={() => {
                setState(true);
              }}
              disabled={loading}
            />
          </div>

          <div className={classes.wrapper}>
            <PrimaryButton
              variant={
                vbeid === selectedTemplate?.vbeid ? "selected" : "outlined"
              }
              size="small"
              label={vbeid === selectedTemplate?.vbeid ? "Selected" : "Select"}
              onClick={() => {
                if (vbeid !== selectedTemplate?.vbeid) {
                  onSelectClick();
                }
              }}
              disabled={
                vbeid !== selectedTemplate?.vbeid &&
                (loading || projectData.role.includes("ADDED_TO"))
              }
            />

            {loading ? (
              <CircularProgress size={10} className={classes.buttonProgress} />
            ) : null}
          </div>
        </TableCell>
      </TableRow>

      <ProjectConfirm
        open={open}
        onConfirm={onSelectConfirm}
        onCancel={onCancel}
        templateType={templateType}
      />
    </>
  );
};
