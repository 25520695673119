import { gql } from "@apollo/client";

export const addProjectOwner = gql`
  mutation projectowner($input: personinput!) {
    addProjectOwnershipToEmployee(input: $input) {
      responsestatus
    }
  }
`;

export const removeProjectOwner = gql`
  mutation removeprojectowner($input: personinput!) {
    removeProjectOwnershipFromEmployee(input: $input) {
      responsestatus
    }
  }
`;

export const addOperationEmp = gql`
  mutation addOperationemp($input: personinput!) {
    addOperationsToEmployee(input: $input) {
      responsestatus
    }
  }
`;

export const removeOperationsEmp = gql`
  mutation removeoperationemp($input: personinput!) {
    removeOperationsFromEmployee(input: $input) {
      responsestatus
    }
  }
`;

export const addFinanceEmp = gql`
  mutation addfinanceemp($input: personinput!) {
    addFinanceToEmployee(input: $input) {
      responsestatus
    }
  }
`;

export const removeFinanceEmp = gql`
  mutation removefinanceemp($input: personinput!) {
    removeFinanceFromEmployee(input: $input) {
      responsestatus
    }
  }
`;

export const listTags = gql`
  query listAllTags {
    listTag(input: {}) {
      name
      type
      value
      action
      createdon
    }
  }
`;

export const addTag = gql`
  mutation addtags($input: genericinput!) {
    addTag(input: $input) {
      responsestatus
    }
  }
`;

export const editTags = gql`
  mutation editTags($input: genericinput!) {
    editTag(input: $input) {
      responsestatus
    }
  }
`;

// addTag	name,type,value,action
// listTag	no input
// editTag	timestamp,action	timestamp is createdon value from listTag
