import React, { useEffect, useRef } from "react";

import { useQuery, useMutation } from "@apollo/client";
import clsx from "clsx";

import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import NewQuestion from "../discussion2.0/newQuestion";
import QuestionForm from "../discussion2.0/QuestionForm";

import { listQuestionByProjectProduct, postrequest } from "./query";

import QuestionContent from "./questionContent";
import ContentLoading from "./ContentLoading";

const useStyles = makeStyles((theme) => ({
  newQuestion: {
    float: "left",
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  mainFlexBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: theme.spacing(40),
  },
  mainForumBox: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    padding: theme.spacing(1),
    borderRadius: "5px",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  fixedHeightBox: {
    maxHeight: theme.spacing(40),
  },
  emptyChatBox: {
    color: "#999",
    fontSize: "0.7rem",
    paddingLeft: theme.spacing(2),
    textAlign: "center",
    minHeight: theme.spacing(40),
  },
  newHeading: {
    margin: "40px 20px 20px",
    fontSize: "0.8rem",
    fontStyle: "italic",
  },
  newSubHeading: {
    fontStyle: "italic",
  },
  buttonStyle: {
    borderRadius: "2rem",
    textTransform: "capitalize",
    float: "none",
    marginTop: theme.spacing(5),
  },
}));

export default function DiscussionCard({ productId, projectId, type }) {
  const classes = useStyles();
  const messagesEndRef = useRef(null);

  const [buttonclick, setButtonClick] = React.useState(true);

  const { loading, data } = useQuery(listQuestionByProjectProduct, {
    variables: { input: { projectvbeid: projectId, productvbeid: productId } },
  });

  const [postrequestmutation] = useMutation(postrequest);

  const emptyBox = clsx({
    [classes.mainFlexBox]: false,
    [classes.emptyChatBox]: true,
  });

  const mainBox = clsx({
    [classes.mainFlexBox]: true,
    [classes.mainForumBox]: true,
    [classes.fixedHeightBox]: type === "request" ? false : true,
  });

  useEffect(() => {
    if (data && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [data]);

  if (loading) {
    return <ContentLoading />;
  }

  function sendInvite() {
    postrequestmutation({
      variables: {
        input: { listvbeid: productId, vbeid: projectId },
      },
    });

    setButtonClick(false);
  }

  return (
    <>
      <Box p={1} className={mainBox}>
        {data &&
          data.listQuestionByProjectProduct.length >= 1 &&
          data.listQuestionByProjectProduct.map((value) => {
            return (
              <div ref={messagesEndRef}>
                <QuestionContent
                  projectId={projectId}
                  productId={productId}
                  key={value.id}
                  id={value.id}
                  question={value.question}
                  companyid={value.usercompanyid}
                  createdBy={value.createdby}
                  totalReplies={value.totalreplies}
                />
              </div>
            );
          })}

        {data && data.listQuestionByProjectProduct.length === 0 ? (
          <div className={emptyBox}>
            <div>
              <div className={classes.newHeading}>
                You're yet to start a discussion.
              </div>
              <div className={classes.newSubHeading}>
                Click the button below to send an invite to begin.
              </div>
            </div>

            {buttonclick ? (
              <Button
                className={classes.buttonStyle}
                onClick={sendInvite}
                variant="contained"
                color="primary"
                size="small"
              >
                Message
              </Button>
            ) : (
              <QuestionForm
                projectId={projectId}
                productId={productId}
                closeQuestion={() => {}}
                type="question"
              />
            )}
          </div>
        ) : (
          <NewQuestion
            className={classes.newQuestion}
            productId={productId}
            projectId={projectId}
          />
        )}
      </Box>
    </>
  );
}
