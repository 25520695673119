import React, { useState } from "react";

import { useStyles } from "./styles";
import { CircularProgress } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import Drawer from "@material-ui/core/Drawer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useTheme } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import {
  ACCEPT_TIMESHEET,
  getInvoiceResourcesByStatus,
  REJECT_TIMESHEET,
} from "./query";

import { useMutation, useQuery } from "@apollo/client";

import { getFormattedDate } from "../../../../functions/helper";

export default function InvoiceBuyerScreen2({ drawerOpen }) {
  const classes = useStyles();
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentIndex, setCurrentIndex] = useState("");

  let today = new Date().toISOString().split("T")[0];

  const { data, loading, refetch } = useQuery(getInvoiceResourcesByStatus, {
    variables: { input: { status: "TIMESHEET_SUBMITTED" } },
    fetchPolicy: "no-cache",
  });

  const [acceptTimesheet] = useMutation(ACCEPT_TIMESHEET, {
    onCompleted: () => {
      refetch();
      handleClose();
    },
  });

  const [rejectTimesheet] = useMutation(REJECT_TIMESHEET, {
    onCompleted: () => {
      refetch();
      handleClose();
    },
  });

  const resourceDatalength = data?.listInvoiceByStatus?.length;

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, resourceDatalength - page * rowsPerPage);

  const onApprove = (resource) => {
    acceptTimesheet({
      variables: {
        input: {
          PK: resource.PK,
          SK: resource.SK,
          approvalduedate: today,
          remarks: "Timesheet approved",
        },
      },
    });
  };

  const onReject = (resource) => {
    rejectTimesheet({
      variables: {
        input: {
          PK: resource.PK,
          SK: resource.SK,
          approvalduedate: today,
          remarks: "Timesheet rejected",
        },
      },
    });
  };

  return (
    <div className={drawerOpen ? classes.drawerOpenRoot : classes.root}>
      <p className={classes.heading}>Timesheets</p>

      <Paper className={classes.paper}>
        <TableContainer className="tableContainer">
          {loading ? (
            <CircularProgress className={classes.loadStyle} size={24} />
          ) : (
            <Table
              style={{ width: "100%" }}
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Project Name</TableCell>
                  <TableCell align="center">Resource Name</TableCell>
                  <TableCell align="center">Supplier Name</TableCell>
                  <TableCell align="center">Billing Mode</TableCell>
                  <TableCell align="center">Start Date</TableCell>
                  <TableCell align="center">End Date</TableCell>
                  <TableCell align="center">No of Hours</TableCell>
                  {/* <TableCell align="center">Approval Due Date</TableCell> */}
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>

              {data?.listInvoiceByStatus?.length ? (
                <TableBody>
                  {data?.listInvoiceByStatus?.map((resourcedata, index) => {
                    return (
                      <TableRow>
                        <TableCell align="right">
                          {resourcedata.projectName}
                        </TableCell>

                        <TableCell align="center">
                          {resourcedata.resourcename}
                        </TableCell>

                        <TableCell align="center">
                          {resourcedata.companyname}
                        </TableCell>

                        <TableCell align="center">Per Hour</TableCell>

                        <TableCell align="center">
                          {getFormattedDate(resourcedata.engagementStartDate)}
                        </TableCell>

                        <TableCell align="center">
                          {getFormattedDate(resourcedata.engagementEndDate)}
                        </TableCell>

                        <TableCell align="center">
                          {resourcedata.numberofhours}
                        </TableCell>

                        {/* <TableCell align="center">
                          {resourcedata.timesheetapprovalby}
                        </TableCell> */}

                        <TableCell align="center">
                          <IconButton
                            className={classes.iconstyle}
                            onClick={(event) => {
                              handleClick(event, index);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>

                        {currentIndex === index && (
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <MenuItem
                              className={classes.menuitem}
                              onClick={() => {
                                onApprove(resourcedata);
                              }}
                            >
                              Approve
                            </MenuItem>

                            <MenuItem
                              className={classes.menuitem}
                              onClick={() => {
                                onReject(resourcedata);
                              }}
                            >
                              Reject
                            </MenuItem>
                          </Menu>
                        )}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={10}>No new timesheets!</TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={resourceDatalength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>

        <Divider />
        <h1>Po Content</h1>
        <Divider />
      </Drawer>
    </div>
  );
}
