import React from "react";
import { Grid } from "@material-ui/core";

import { editor_toolbar } from "../../../../../shared/EditorToolBar";

export default class JDEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = { edit: props.edit, companyId: props.editorId };

    this.onEdit = this.onEdit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentWillUnmount() {
    if (this.mainEditor) {
      this.mainEditor.destroy().catch((error) => {});
    }
  }

  componentDidMount() {
    this.createEditor();
  }

  createEditor() {
    let ckeditor_toolbar = editor_toolbar;

    window.ClassicEditor.create(
      document.querySelector("#editor" + this.props.editorId),
      ckeditor_toolbar,
      {
        placeholder: "Start writing requirements here",
      }
    )
      .then((editor) => {
        this.mainEditor = editor;

        editor.setData(this.props.data ? this.props.data : "");

        editor.isReadOnly = this.props.isReadOnly;
      })

      .catch((error) => {
        console.error(error);
      });
  }

  onEdit() {
    this.setState({ edit: true });
    this.mainEditor.isReadOnly = false;
  }

  onSave() {
    this.props.saveMethod(this.mainEditor.getData());
    this.setState({ edit: false });
    this.mainEditor.isReadOnly = true;
  }

  checkEditor() {
    if (this.mainEditor) {
      this.mainEditor.destroy().catch((error) => {});
      setTimeout(() => {
        this.createEditor();

        this.setState({
          companyId: this.props.editorId,
        });
      }, 100);
    }
  }

  onBlur() {
    this.props.saveMethod(this.mainEditor.getData());
  }

  render() {
    const { editorId } = this.props;
    const { edit, companyId } = this.state;

    if (editorId !== companyId) {
      this.checkEditor();
    }

    return (
      <>
        <Grid
          container
          className={edit ? "" : "read-only-ck-editor-vbridge"}
          onBlur={this.onBlur}
        >
          <Grid item md={12}>
            <textarea
              id={"editor" + editorId}
              placeholder="Have a job description? Paste it here!"
              style={{ visibility: "hidden" }}
            ></textarea>
          </Grid>
        </Grid>
      </>
    );
  }
}
