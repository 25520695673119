import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper, Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";

//import Similars from '../../shared/similarentities';
import VbridgeAlert from "../../shared/alert";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  // alignbutton:{
  //   marginTop:"-2%",
  //   marginLeft:"11%"
  // }
}));

export default function CreateCompanyShort() {
  const classes = useStyles();
  let history = useHistory();

  //const cname = '^\s*([0-9a-zA-Z]+)\s*$';

  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter your Company Name")
      .min(2, "Company Name should be of minimum 2 characters length")
      .required("Company Name is required"),

    companyurl: yup
      .string()
      .url("*Enter valid company url")
      .required("*Url required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      companyurl: "",
    },
    validationSchema: validationSchema,
  });

  const postcompany = gql`
    mutation company($input: companyinput!) {
      addCompanyShort(input: $input) {
        name
        companyurl
        vbeid
        id
      }
    }
  `;

  const [postcompanymutation, { error: mutationError, data }] = useMutation(
    postcompany,
    {
      onCompleted: (e) => {
        route(e);
      },
    }
  );

  function CreateCompanyShort(e) {
    e.preventDefault();

    postcompanymutation({
      variables: {
        input: {
          name: formik.values.name,
          companyurl: formik.values.companyurl,
        },
      },
    });
  }

  function route(e) {
    history.push("/company/" + e.addCompanyShort.vbeid);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          {mutationError ? (
            <VbridgeAlert
              severity="error"
              open={true}
              message="Something went wrong ... "
            />
          ) : null}
          {data ? (
            <VbridgeAlert
              severity="success"
              open={true}
              message="Company Added successfully "
            />
          ) : null}
        </Grid>
      </Grid>

      <Box m={10}>
        <Grid container>
          <Paper>
            <form className={classes.root} noValidate autoComplete="off">
              <Grid container md={12} spacing={1}>
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    label="Company Name"
                    variant="outlined"
                    margin="dense"
                    required
                    value={formik.values.name}
                    onChange={formik.handleChange("name")}
                    onBlur={formik.handleBlur("name")}
                  />
                </Grid>

                <Grid item style={{ color: "red" }}>
                  {formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : null}
                </Grid>

                <Grid item>
                  <TextField
                    id="outlined-basic"
                    label="Company Url"
                    variant="outlined"
                    margin="dense"
                    required
                    value={formik.values.companyurl}
                    onChange={formik.handleChange("companyurl")}
                    onBlur={formik.handleBlur("companyurl")}
                  />
                </Grid>

                <Grid item style={{ color: "red" }}>
                  {formik.touched.companyurl && formik.errors.companyurl
                    ? formik.errors.companyurl
                    : null}
                </Grid>
              </Grid>

              <Grid item className={classes.alignbutton}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                  onClick={CreateCompanyShort}
                >
                  Add
                </Button>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Box>
    </>
  );
}
