import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import {
  Typography,
  CardContent,
  Grid,
  IconButton,
  Paper,
  TextField,
  Breadcrumbs,
} from "@material-ui/core";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import { VBRIDGEHUB_MEDIAURL } from "../../../constants";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SaveIcon from "@material-ui/icons/Save";

import { useStyles } from "../topic2.0/styles";
import ErrorSnackbar from "../topic2.0/errorsnackbar";
import SimpleSnackbar from "../topic2.0/topicsnackbar";

import HelpRequest from "../Help/HelpRequest";

import TypeBadge from "../../shared/TypeBadge";

import GroupLogo from "../groups2.0/GroupLogo";
import ReadMore from "../groups2.0/ReadMore";
import MainFilters from "../groups2.0/MainFilters";
import GroupTabs from "../groups2.0/GroupTabs";
import MainGroupLoading from "../groups2.0/MainGroupLoading";

import { getSkillById } from "./query";

export default function MainSkill({ isReadOnly, toggleValue }) {
  const classes = useStyles();
  const client = useApolloClient();
  let { id, typeValue } = useParams();
  const history = useHistory();

  const [editName, setEditName] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editNameValue, setEditNameValue] = useState("");
  const [editDescriptionValue, setEditDescriptionValue] = useState("");
  const [yearList, setYearList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [locationsList, setLocationList] = useState([]);
  const [awardsList, setAwardList] = useState([]);
  const [fundingRoundsList, setFundingRoundsList] = useState([]);
  const [maturityList, setMaturityList] = useState([]);
  const [skillList, setSkillList] = useState([]);

  const editNameClick = () => {
    setEditName(true);
  };

  const saveNameClick = () => {
    setEditName(false);
    // updateCache("name", editNameValue);
  };

  const editDescriptionClick = () => {
    setEditDescription(true);
  };

  const saveDescriptionClick = () => {
    setEditDescription(false);
    // updateCache("description", editDescriptionValue);
  };

  //   const updateCache = (paramName, paramValue) => {
  //     if (paramValue !== "") {
  //       const { queryTopicbyid } = client.readQuery({
  //         query: queryTopics,
  //         variables: { input: { vbeid: id } },
  //       });

  //       let updatedTopic = { ...queryTopicbyid };
  //       updatedTopic[paramName] = paramValue;

  //       client.writeQuery({
  //         query: queryTopics,
  //         data: {
  //           queryTopicbyid: updatedTopic,
  //         },

  //         variables: { input: { vbeid: id } },
  //       });
  //       postUpdatetopic(paramName, paramValue, id);
  //     }
  //   };

  const handleNameChange = (e) => {
    setEditNameValue(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setEditDescriptionValue(e.target.value);
  };

  //   const [posttopicmutation, { loading: updateLoading, error: mutationError }] =
  //     useMutation(updatetopic);

  //   const postUpdatetopic = (paramName, paramValue, topicid) => {
  //     posttopicmutation({
  //       variables: {
  //         input: {
  //           topicid: topicid,
  //           [paramName]: paramValue,
  //         },
  //       },
  //     });
  //   };

  const onFilterSet = (id, checkedValue) => {
    let filterList = locationsList;

    if (checkedValue) {
      setLocationList((prevList) => [...prevList, id]);
    } else {
      let index = filterList.indexOf(id);
      if (index !== -1) {
        setLocationList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ]);
      }
    }
  };

  const onFilterAwards = (id, checkedValue) => {
    let filterList = awardsList;

    if (checkedValue) {
      setAwardList((prevList) => [...prevList, id]);
    } else {
      let index = filterList.indexOf(id);
      if (index !== -1) {
        setAwardList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ]);
      }
    }
  };

  const onFilterYear = (id, checkedValue) => {
    let filterList = yearList;

    if (checkedValue) {
      setYearList((prevList) => [...prevList, id]);
    } else {
      let index = filterList.indexOf(id);
      if (index !== -1) {
        setYearList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ]);
      }
    }
  };

  const onFilterPartner = (id, checkedValue) => {
    let filterList = partnerList;

    if (checkedValue) {
      setPartnerList((prevList) => [...prevList, id]);
    } else {
      let index = filterList.indexOf(id);
      if (index !== -1) {
        setPartnerList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ]);
      }
    }
  };

  const onFilterFundingRound = (id, checkedValue) => {
    let filterList = fundingRoundsList;

    if (checkedValue) {
      setFundingRoundsList((prevList) => [...prevList, id]);
    } else {
      let index = filterList.indexOf(id);
      if (index !== -1) {
        setFundingRoundsList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ]);
      }
    }
  };

  const onFilterMaturity = (id, checkedValue) => {
    let filterList = maturityList;

    if (checkedValue) {
      setMaturityList((prevList) => [...prevList, id]);
    } else {
      let index = filterList.indexOf(id);
      if (index !== -1) {
        setMaturityList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ]);
      }
    }
  };

  const onFilterSkills = (id, checkedValue) => {
    let filterList = skillList;

    if (checkedValue) {
      setSkillList((prevList) => [...prevList, id]);
    } else {
      let index = filterList.indexOf(id);
      if (index !== -1) {
        setSkillList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ]);
      }
    }
  };

  const { data, error, loading } = useQuery(getSkillById, {
    variables: { input: { skillid: id } },
  });

  useEffect(() => {
    setYearList([]);
    setPartnerList([]);
    setLocationList([]);
    setAwardList([]);
    setFundingRoundsList([]);
    setMaturityList([]);
    setSkillList([]);
  }, [id]);

  return (
    <>
      {error && <ErrorSnackbar />}
      {loading && <SimpleSnackbar />}

      {/* <div style={{ bottom: "29px", position: "relative" }}>
        {Group && Group.listGroupByTopicId.groups.length !== 0 ? (
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link
              color="primary"
              style={{
                fontSize: "13px",
                textDecoration: "none",
                fontStyle: "italic",
              }}
              to={
                "/group/" +
                typeValue +
                "/" +
                Group.listGroupByTopicId.groups[0].id
              }
            >
              {Group.listGroupByTopicId.groups.length >= 1
                ? Group.listGroupByTopicId.groups[0].name
                : ""}
            </Link>

            {data ? (
              <Typography
                color="primary"
                style={{ fontSize: "13px", fontStyle: "italic" }}
              >
                {data.queryTopicbyid.name}
              </Typography>
            ) : null}
          </Breadcrumbs>
        ) : null}
      </div> */}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={1} square={true} className={classes.root}>
            <div className={classes.help}>
              <HelpRequest refer="coveragecategory" />
            </div>

            {data ? (
              <CardContent style={{ textAlign: "left" }}>
                <Grid container justify="flex-start">
                  <Grid item md={2}>
                    {data?.querySkillbyId ? (
                      <GroupLogo
                        image={
                          VBRIDGEHUB_MEDIAURL + "/" + data.querySkillbyId.image
                        }
                        name={data.querySkillbyId.name}
                        isReadOnly={isReadOnly}
                        vbeid={id}
                        //updateCache={updateCache}
                      />
                    ) : null}
                  </Grid>

                  <Grid item md={10}>
                    <Grid container md={12}>
                      <Grid item>
                        {!editName ? (
                          <Typography
                            gutterBottom
                            variant="BUTTON TEXT"
                            component="h2"
                            className={classes.groupName}
                          >
                            {data?.querySkillbyId.name}

                            <TypeBadge name="SKILL" type="main" />

                            <span>
                              {isReadOnly ? null : (
                                <IconButton>
                                  <EditOutlinedIcon
                                    fontSize="small"
                                    onClick={editNameClick}
                                  />
                                </IconButton>
                              )}
                            </span>
                          </Typography>
                        ) : null}

                        {editName ? (
                          <Grid container spacing={2}>
                            <Grid item>
                              <TextField
                                margin="dense"
                                variant="outlined"
                                defaultValue={data?.querySkillbyId.name}
                                onChange={handleNameChange}
                              />
                            </Grid>

                            <Grid item>
                              <IconButton onClick={saveNameClick}>
                                <SaveIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>

                    <Grid item>
                      {!editDescription ? (
                        <Typography
                          variant="p"
                          component="p"
                          className={classes.descriptionText}
                        >
                          {/* <ReadMore>{data.queryTopicbyid.description}</ReadMore> */}

                          <span>
                            {isReadOnly ? null : (
                              <IconButton>
                                <EditOutlinedIcon
                                  fontSize="small"
                                  onClick={editDescriptionClick}
                                />
                              </IconButton>
                            )}
                          </span>
                        </Typography>
                      ) : null}

                      {editDescription ? (
                        <Grid container spacing={2}>
                          {/* <Grid item>
                            <TextField
                              label="Enter Description"
                              variant="outlined"
                              className={classes.descriptionfield}
                              defaultValue={data.queryTopicbyid.description}
                              multiline
                              rows={4}
                              rowsMax={6}
                              onChange={handleDescriptionChange}
                            />
                          </Grid>

                          <Grid item>
                            <IconButton onClick={saveDescriptionClick}>
                              <SaveIcon />
                            </IconButton>
                          </Grid> */}
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <MainFilters
                    type="skill"
                    onFilterSet={onFilterSet}
                    onFilterAwards={onFilterAwards}
                    onFilterYear={onFilterYear}
                    onFilterPartner={onFilterPartner}
                    onFilterFundingRound={onFilterFundingRound}
                    onFilterMaturity={onFilterMaturity}
                    onFilterSkills={onFilterSkills}
                    locationsList={locationsList}
                    awardsList={awardsList}
                    partnerList={partnerList}
                    fundingRoundsList={fundingRoundsList}
                    yearList={yearList}
                    maturityList={maturityList}
                    skillList={skillList}
                    typeValue={typeValue}
                  />

                  <Grid item md={9} className={classes.mainTabsDiv}>
                    <GroupTabs
                      type="skill"
                      locationsList={locationsList}
                      awardsList={awardsList}
                      partnerList={partnerList}
                      fundingRoundsList={fundingRoundsList}
                      yearList={yearList}
                      maturityList={maturityList}
                      skillList={skillList}
                      isReadOnly={isReadOnly}
                      toggleValue={toggleValue}
                      typeValue={typeValue}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            ) : (
              <MainGroupLoading />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
