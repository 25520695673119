import { useMutation } from "@apollo/client";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { useState } from "react";
import { useHistory } from "react-router-dom";

import InviteUser from "../entities/Agreements/InviteUser";

import { inviteUser } from "../entities/Agreements/query";
import AddInvite from "../entities/companies/Company2.0/shorts/AddInvite";

import VbridgeAlert from "./alert";

export default function MenuDrawer({ open, userInfo, handleDrawerClose }) {
  const drawerWidth = 240;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: "900",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    drawerHeader: {
      background: "#232961",
    },
    listItemText: {
      "& span": {
        fontSize: "0.9rem",
        color: "#999",
        marginTop: theme.spacing(1),
      },
    },
    subItem: {
      padding: "0, 12px !important",
    },
    activeSubItem: {
      padding: "0, 12px !important",
      backgroundColor: "#eee !important",
    },
    subItemListText: {
      margin: theme.spacing(0, 2),
      "& span": {
        fontSize: "0.9rem",
      },
    },
    item: {
      padding: theme.spacing(0, 2),
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const pathname = history.location.pathname;

  const [inviteModal, setInviteModal] = useState(false);
  const [inviteError, setInviteError] = useState(
    "Something went wrong. Please try again."
  );
  const [invitesupplier, setInviteSupplier] = useState(false);

  const [
    inviteUserMutation,
    { loading: inviteLoading, error, data: inviteUserSuccess },
  ] = useMutation(inviteUser, {
    onCompleted: (e) => {
      closeModal();
    },
    onError: (e) => {
      setInviteError(e.graphQLErrors[0].message);
    },
  });

  //   const handleDrawerClick = () => {
  //     handleDrawerClose();
  //   };

  const onInviteUser = (e) => {
    e.preventDefault();
    setInviteModal(true);
  };

  const closeModal = () => {
    setInviteModal(false);
  };

  const onInviteSupplier = () => {
    setInviteSupplier(true);
  };

  const onSupplierClose = () => {
    setInviteSupplier(false);
  };

  const submitUserInvite = (emailId) => {
    inviteUserMutation({
      variables: {
        input: { personemailid: emailId },
      },
    });
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={open}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider />

        <List style={{ marginTop: "8px" }}>
          <ListItem key="company" className={classes.item}>
            <ListItemText
              primary="MY COMPANY"
              className={classes.listItemText}
            />
          </ListItem>

          <ListItem
            button
            key="companyProfile"
            className={
              pathname === "/mycompany/:id"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");

              history.push("/mycompany/" + userInfo.companyvbeid);
            }}
          >
            <ListItemText
              primary="Company Profile"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem
            button
            key="suppliers"
            className={
              pathname === "/venders" ? classes.activeSubItem : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/venders");
            }}
          >
            <ListItemText
              primary="My Suppliers"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem
            button
            key="inviteSupplier"
            className={classes.subItem}
            onClick={onInviteSupplier}
          >
            <ListItemText
              primary="Invite Supplier"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem
            button
            key="inviteColleague"
            className={classes.subItem}
            onClick={onInviteUser}
          >
            <ListItemText
              primary="Invite Colleague"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem key="projects" className={classes.item}>
            <ListItemText primary="PROJECTS" className={classes.listItemText} />
          </ListItem>

          <ListItem
            button
            key="myProjects"
            className={
              pathname === "/projects3.0/overview"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/projects3.0/overview");
            }}
          >
            <ListItemText
              primary="My Projects"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem
            button
            key="approveTimesheet"
            className={
              pathname === "/accept-timesheet"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/accept-timesheet");
            }}
          >
            <ListItemText
              primary="Approve Timesheet"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem
            button
            key="approveMilestone"
            className={
              pathname === "/accept-milestone"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/accept-milestone");
            }}
          >
            <ListItemText
              primary="Approve Milestone"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem key="purchaseOrders" className={classes.item}>
            <ListItemText
              primary="PURCHASE ORDERS"
              className={classes.listItemText}
            />
          </ListItem>

          <ListItem
            button
            key="releasePO"
            className={
              pathname === "/purchaseOrders"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/purchaseOrders");
            }}
          >
            <ListItemText
              primary="Release Purchase Order"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem
            button
            key="view"
            className={
              pathname === "/resource-onboarding"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/resource-onboarding");
            }}
          >
            <ListItemText
              primary="Resource Onboarding"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem
            button
            key="view"
            className={
              pathname === "/supplier-onboarding"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/supplier-onboarding");
            }}
          >
            <ListItemText
              primary="Supplier Onboarding"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem
            button
            key="attention"
            className={
              pathname === "/po-changes"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/po-changes");
            }}
          >
            <ListItemText
              primary="Attention Required"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem
            button
            key="view"
            className={
              pathname === "/view-purchase-orders"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/view-purchase-orders");
            }}
          >
            <ListItemText
              primary="View Purchase Orders"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem key="invoice" className={classes.item}>
            <ListItemText
              primary="INVOICE & PAYMENTS"
              className={classes.listItemText}
            />
          </ListItem>

          <ListItem
            button
            key="accept"
            className={
              pathname === "/accept-invoice"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/accept-invoice");
            }}
          >
            <ListItemText
              primary="Accept Invoices"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem
            button
            key="accept"
            className={
              pathname === "/wp-accept-invoice"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/wp-accept-invoice");
            }}
          >
            <ListItemText
              primary="Accept Work Package Invoices"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem button key="release" className={classes.subItem}>
            <ListItemText
              primary="Release Payments"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem
            button
            key="viewInvoice"
            className={
              pathname === "/invoice-list"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/invoice-list");
            }}
          >
            <ListItemText
              primary="View Invoices"
              className={classes.subItemListText}
            />
          </ListItem>

          <ListItem
            button
            key="viewInvoice"
            className={
              pathname === "/wp-invoice-list"
                ? classes.activeSubItem
                : classes.subItem
            }
            onClick={(e) => {
              handleDrawerClose(e, "close");
              history.push("/wp-invoice-list");
            }}
          >
            <ListItemText
              primary="View Work Package Invoices"
              className={classes.subItemListText}
            />
          </ListItem>
        </List>
      </Drawer>

      <InviteUser
        open={inviteModal}
        closeModal={closeModal}
        loading={inviteLoading}
        submitUserInvite={submitUserInvite}
      />

      <AddInvite
        invite="invite supplier"
        headername="Invite a Supplier to vBridge Hub"
        dialogtitle="Know of any Tech Vendor and/or Service Provider who benefits from the platform?"
        supplieropen={invitesupplier}
        supplierclose={onSupplierClose}
      />

      {inviteUserSuccess && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="User invited successfully"
        />
      )}

      {error && (
        <VbridgeAlert severity="error" open={true} message={inviteError} />
      )}
    </>
  );
}
