import { useApolloClient, useQuery } from "@apollo/client";

import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  SwipeableDrawer,
} from "@material-ui/core";

import CustomCkeditor from "../../shared/customckeditor";

import DataPageHeader from "../common/DataPageHeader";

import { userInfoQuery } from "../companies/Company2.0/query";
import EmptyDiv from "../companies/Company2.0/tabs/lists/emptyDiv";
import { productelById } from "../DataValidation/query";

import { userPermission } from "../groups2.0/query";

import { useStyles } from "./styles";

export default function ProductDetail({
  open,
  product,
  onClose,
  type,
  mainDrawerClick,
}) {
  const classes = useStyles();
  const client = useApolloClient();

  const user = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  const userpermission = client.readQuery({
    query: userPermission,
    variables: { input: {} },
  });

  const { data, loading } = useQuery(productelById, {
    variables: { input: { vbeid: product.id } },
  });

  const { id } = product;

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      className={classes.productDetailDiv}
      onClose={onClose}
    >
      <Card square className={classes.productDetailCard} elevation={0}>
        <DataPageHeader
          data={product}
          isReadOnly={true}
          vbeid={id}
          updateCache={() => {}}
          editName={false}
          editDescription={false}
          editNameClick={() => {}}
          handleNameChange={() => {}}
          saveNameClick={() => {}}
          editDescriptionClick={() => {}}
          handleDescriptionChange={() => {}}
          saveDescriptionClick={() => {}}
          type={type}
          isAdmin={false}
          userPermission={userpermission}
          userInfo={user?.getLoginUserInfo}
          mainDrawerClick={mainDrawerClick}
        />

        <Divider />

        <CardContent
          className={
            loading ? classes.productDescLoading : classes.productDescription
          }
        >
          {data ? (
            data.productelById && data.productelById.longdescription !== "" ? (
              <CustomCkeditor
                data={data.productelById.longdescription}
                editorId={id}
                isReadOnly={true}
              />
            ) : (
              <EmptyDiv type="description" />
            )
          ) : (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </CardContent>
      </Card>
    </SwipeableDrawer>
  );
}
