import { Box, Grid, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";

export default function Policy() {
  return (
    <Box m={1}>
      <Grid container style={{ marginTop: "16px" }}>
        <Grid item md={6}>
          <Link
            target="blank"
            href="https://vbridgehub.com/privacypolicy"
            variant="caption"
          >
            Privacy Policy
          </Link>
        </Grid>

        <Grid item md={6}>
          <Link
            target="_blank"
            href="https://www.vbridgehub.com/docs/buyer-terms-of-use.pdf"
            variant="caption"
          >
            Buyer Terms of Use
          </Link>
        </Grid>

        <Grid item md={6}>
          <Link
            target="blank"
            href="https://vbridgehub.com/aboutus"
            variant="caption"
          >
            About us
          </Link>
        </Grid>

        <Grid item md={6}>
          <Link
            target="blank"
            href="https://vbridgehub.com/resources"
            variant="caption"
          >
            Resources
          </Link>
        </Grid>

        <Grid item md={12}>
          <Typography color="primary" variant="caption">
            © 2022 vBridge Hub | All Rights Reserved
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
