import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),

    "& button": {
      textTransform: "none ",
      float: "right",
    },
  },
  drawerOpenRoot: {
    marginTop: theme.spacing(8),
    marginLeft: 160,

    "& button": {
      textTransform: "none ",
      float: "right",
    },
  },
  blockBtn: {
    textTransform: "none",
    padding: theme.spacing(0, 1),
    color: "#4179BD",
  },

  acceptbtn: {
    textTransform: "none",
    cursor: "pointer",
    color: "#4179BD",
    fontSize: "12px",

    "&:hover": {
      background: "#f2fff2",
      padding: "2px",
    },
  },

  rejectbtn: {
    textTransform: "none",
    cursor: "pointer",
    color: "#4179BD",
    fontSize: "12px",

    "&:hover": {
      background: "#fedada",
      padding: "2px",
    },
  },

  dataGridDiv: {
    margin: "16px 0",
  },
  formHeaderText: {
    fontSize: "0.85rem",
    textAlign: "left",
  },
  companyName: {
    color: "#4179BD",
    fontWeight: "500",
  },

  userrole: {
    lineHeight: 1.2,
  },
  adminRequest: {
    fontSize: "12px",
    fontWeight: 500,
    "& div": {
      marginBottom: "4px",
    },
  },
  user: {
    margin: "4px 0 8px",
    textTransform: "capitalize",
  },

  btn: {
    float: "right",
    marginBottom: theme.spacing(1),
  },
}));
