import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useStyles } from "./styles";

export default function AutoCompleteField({
  options,
  setValues,
  label,
  multiple,
  disabled,
  value,
  onchange,
  onBlur,
  defaultvalue,
  type,
  onclick,
}) {
  let selectedValue = "";
  let selectedVbeid = "";

  const getSelectedOption = () => {
    let selectedOption = "";

    options.map((opt) => {
      if (opt.value === defaultvalue) {
        return (selectedOption = opt);
      } else return "";
    });

    return selectedOption || null;
  };

  const noAwardFound = () => {
    onclick();
  };

  const classes = useStyles();

  return (
    <Autocomplete
      noOptionsText={
        type === "selectaward" ? (
          <span onClick={noAwardFound} className={classes.noOption}>
            Didn't find Award? Click here to add an Award manually.
          </span>
        ) : (
          "No Option"
        )
      }
      disabled={disabled}
      className={
        type === "ratecard"
          ? classes.AutoCompleteratecard
          : classes.AutoCompleteField
      }
      multiple={multiple}
      options={options}
      value={getSelectedOption()}
      getOptionLabel={(option) => option?.name}
      clearOnBlur="true"
      onBlur={onBlur}
      onChange={(event, newValue) => {
        selectedValue = newValue?.value;
        selectedVbeid = newValue?.vbeid;
        setValues(selectedValue, selectedVbeid);
      }}
      // renderOption={(option) => option?.name}
      renderInput={(params) => (
        <TextField
          className={type === "ratecard" ? classes.textfield : null}
          {...params}
          label={label}
          variant="outlined"
          margin="dense"
          id="input"
        />
      )}
    />
  );
}
