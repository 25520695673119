import * as React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";

import { FormControl } from "@material-ui/core";
import Select from "@material-ui/core/Select";

import Drawer from "@material-ui/core/Drawer";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useFormik } from "formik";

import { useMutation, useApolloClient } from "@apollo/client";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core";
import { UPDATE_REQUIREMENT_ITEM } from "../../../queries/query";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  textfield: {
    height: theme.spacing(100),
  },
  properties: {
    width: theme.spacing(50),
  },
  propertiestitle: {
    marginBottom: theme.spacing(2),
  },
  propsbuttongroups: {
    marginTop: theme.spacing(2),
  },
  propsbutton: {
    margin: theme.spacing(1),
  },
  helpicon: {
    fontSize: theme.spacing(1.5),
    cursor: "pointer",
  },
  select: {
    height: theme.spacing(4.5),
  },
  checkbox: {
    "& span": {
      fontSize: theme.spacing(1.4),
    },
  },
  rtitle: {
    fontWeight: "bold",
  },
}));

const validateItem = (values) => {
  const errors = {};
  if (!values.value) {
    errors.value = "Required";
  }

  return errors;
};

export default function RSelectItem(props) {
  const classes = useStyles();
  const [metadata, setMetadata] = React.useState(props.metadata);

  const [updateItem, { data, loading, error }] = useMutation(
    UPDATE_REQUIREMENT_ITEM,
    {
      onCompleted: (e) => {
        formik.setFieldValue(
          "timestamp",
          e.addReqTemplateItemvalue[0].timestamp
        );
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const formik = useFormik({
    initialValues: props.values,
    validate: validateItem,
    onSubmit: (values) => {
      console.log(values);
      var input = { ...values };

      if (input.timestamp === null) {
        delete input.timestamp;
      }

      updateItem({ variables: { input: [input] } });
    },
  });

  return (
    <Grid container alignItems="center" className={classes.root} spacing={1}>
      <Grid item xs={4}>
        <Typography
          variant="caption"
          gutterBottom
          className={props.readOnly ? classes.rtitle : ""}
        >
          {" "}
          {metadata.title}{" "}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          {props.readOnly ? (
            <Typography variant="caption">{formik.values.value}</Typography>
          ) : (
            <Select
              native
              className={classes.select}
              name="value"
              onBlur={formik.handleSubmit}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.value)}
              value={formik.values.value}
            >
              <option aria-label="None" value="">
                Choose Any{" "}
              </option>
              {metadata.options.split(",").map((option) => {
                return <option value={option}>{option}</option>;
              })}
            </Select>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={3}>
        {metadata.remarks ? (
          props.readOnly ? (
            <Typography variant="caption">{formik.values.remarks}</Typography>
          ) : (
            <TextField
              autoComplete="off"
              disabled={loading}
              id="filled-full-width"
              name="remarks"
              value={formik.values.remarks}
              onChange={formik.handleChange}
              onBlur={formik.handleSubmit}
              placeholder="Remarks"
              fullWidth
              variant="outlined"
              size="small"
            />
          )
        ) : null}
      </Grid>
    </Grid>
  );
}
