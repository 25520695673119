import React, { useState, useEffect } from "react";

import {
  ApolloProvider,
  ApolloLink,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";

import { useAuth0 } from "@auth0/auth0-react";

import {
  AUTH_TYPE,
  createAuthLink as awsCreateAuthLink,
} from "aws-appsync-auth-link";

// import { AUTH_TOKEN } from "./constants";

import MainLoading from "./components/shared/loading";
import EmailError from "./components/entities/profile/emailverification";

export default function AuthorizedApolloProvider(props) {
  const [authToken, setAuthToken] = useState(null);

  const {
    isLoading,
    loginWithRedirect,
    isAuthenticated,
    user,
    error,
    getAccessTokenSilently,
  } = useAuth0();

  const url = process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT;
  const region = process.env.REACT_APP_AWS_APPSYNC_REGION;

  const auth = {
    type: AUTH_TYPE.OPENID_CONNECT,
    jwtToken: authToken,
  };

  const link = ApolloLink.from([
    awsCreateAuthLink({ url, region, auth }),
    createHttpLink({ uri: url }),
  ]);

  const client = new ApolloClient({
    link,
    connectToDevTools: false,
    cache: new InMemoryCache({
      typePolicies: {
        gquery: {
          fields: {
            productsByGroupID: offsetLimitPagination(),
          },
        },
      },
    }),
  });

  useEffect(() => {
    (async function login() {
      if (!isLoading && !user && !error) {
        localStorage.setItem("urlpath", window.location.pathname);
        await loginWithRedirect();
      }
      if (isAuthenticated) {
        setAuthToken(
          await getAccessTokenSilently({ audience: `https://aws.amazon.com/` })
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (error) {
    return <EmailError />;
  }

  if (authToken) {
    if (process.env.NODE_ENV === "development") {
      console.log(authToken);
      //console.log('origin', window.origin);
    }
    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
  }

  return <MainLoading />;
}
