import React from "react";
import { useParams } from "react-router-dom";

import DiscussionCard from "./discussionCard";

export default function MessageList({
  companyname,
  productname,
  productvbeid, 
  vbeid,
}) {
  let { id } = useParams();

  return (
    <>
      <DiscussionCard
        name={companyname + " : " + productname}
        productId={productvbeid}
        projectId={id?id:vbeid}
        buttonvbeid={vbeid}
      />
    </>
  );
}
