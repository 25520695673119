import { Avatar, Paper, Typography } from "@material-ui/core";

import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

import { useHistory } from "react-router-dom";

import { VBRIDGEHUB_MEDIAURL } from "../../../../../../constants";

import PrimaryButton from "../../../../common/PrimaryButton";

import { useStyles } from "./styles";

export default function ProviderCard({
  company,
  onSelect,
  type,
  removeSupplier,
  onClick,
}) {
  const classes = useStyles();
  const history = useHistory();

  const onRemoveClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    removeSupplier();
  };

  const onSelectClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onSelect();
  };

  return (
    <Paper
      elevation={3}
      square={true}
      className={
        company.selected || type === "selected"
          ? classes.selectedResultCard
          : classes.resultCard
      }
      onClick={onClick}
    >
      <>
        <div className={classes.resultDiv}>
          <Avatar
            variant="square"
            alt={company.name}
            src={VBRIDGEHUB_MEDIAURL + "/" + company.image}
            className={classes.productImage}
          >
            <CancelPresentationIcon />
          </Avatar>

          <div className={classes.productDetails}>
            <Typography
              variant="BUTTON TEXT"
              component="h2"
              className={classes.productName}
              onClick={() => {
                history.push("/company/" + company.vbeid);
              }}
            >
              {company.name || "-"}
            </Typography>
          </div>
        </div>

        <div className={classes.locationMain}>
          <div className={classes.location}>{company.location || "-"}</div>
        </div>

        <div style={{ minHeight: "100px", textAlign: "left" }}>
          <div style={{ fontSize: "0.8rem", fontStyle: "italic" }}>
            {type === "skillMatch"
              ? "VBH Suggested"
              : type === "selected"
              ? "Self Selected"
              : ""}
          </div>

          {company.existingvendor && (
            <div
              style={{
                fontSize: "0.8rem",
                fontStyle: "italic",
                marginTop: "8px",
                color: "#008000",
                fontWeight: "600",
              }}
            >
              Existing Supplier
            </div>
          )}
        </div>

        <div style={{ display: "flex" }}>
          {!company.selected && type !== "selected" ? (
            <PrimaryButton
              size="small"
              variant="contained"
              label="Select Supplier"
              onClick={onSelectClick}
            />
          ) : (
            <PrimaryButton
              size="small"
              variant="contained"
              label="Remove Supplier"
              onClick={onRemoveClick}
              type="remove"
            />
          )}
        </div>
      </>
    </Paper>
  );
}
