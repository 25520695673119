import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export default function ListLoad() {
  return (
    <Grid container justify="flex-start">
      <Skeleton width={100} height={40} variant="text" />
    </Grid>
  );
}
