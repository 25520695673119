import { useState } from "react";
import { useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";

import PrimaryButton from "../common/PrimaryButton";

import { useStyles } from "./styles";

import { getSectionsInTemplate, updateStatus } from "./query";
import { useLazyQuery, useMutation } from "@apollo/client";

import DataGridValidation from "../DataValidation/dataGrid";
import CreateTemplateForm from "./CreateTemplateForm";
import PreviewModal from "./PreviewModal";
import VbridgeAlert from "../../shared/alert";

export default function TemplatesList({
  type,
  tab,
  list,
  update,
  updateTemplateCache,
  loading,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [open, setOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);

  const [
    statusUpdateMutation,
    { data: statusData, loading: statusLoading, error: statusError },
  ] = useMutation(updateStatus);

  const [getSections, { data, loading: sectionLoading, error }] = useLazyQuery(
    getSectionsInTemplate,
    {
      variables: { input: { vbeid: selectedTemplate?.vbeid } },
    }
  );

  const onCreateClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedTemplate("");
    setPreviewOpen(false);
  };

  const onTemplateClick = (template) => {
    setSelectedTemplate(template);

    history.push({
      pathname: "/createTemplate/" + template.vbeid,
      state: { template: template },
    });
  };

  const onStatusChange = (event, template, status) => {
    event.preventDefault();
    event.stopPropagation();

    statusUpdateMutation({
      variables: {
        input: {
          status: status,
          vbeid: template.vbeid,
        },
      },
    });
  };

  const onPreviewClick = (template) => {
    setSelectedTemplate(template);
    getSections();
    setPreviewOpen(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 180,
      renderCell: (params) => (
        <div
          className={classes.templateName}
          onClick={() => {
            if (params.row.status !== "archived") {
              onTemplateClick(params.row);
            }
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <div>{params.value ? params.value.toUpperCase() : "CREATED"}</div>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <div style={{ display: "flex" }}>
          <PrimaryButton
            label="Preview"
            onClick={() => onPreviewClick(params.row)}
            disabled={params.row.status === "archived"}
          />

          <PrimaryButton
            label="Publish"
            onClick={(event) => onStatusChange(event, params.row, "published")}
            disabled={
              params.row.status === "published" ||
              params.row.status === "archived"
            }
          />

          <PrimaryButton
            label="Archive"
            onClick={(event) => onStatusChange(event, params.row, "archived")}
            disabled={params.row.status === "archived"}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={classes.templatesDiv}>
      {(statusError || error) && (
        <VbridgeAlert
          severity="error"
          open={true}
          message="Something went wrong! Please try again later."
        />
      )}

      {(statusLoading || sectionLoading) && (
        <VbridgeAlert severity="info" open={true} message="Please wait" />
      )}

      {statusData && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="Template updated successfully"
        />
      )}

      <Grid container>
        <Grid item md={12}>
          <PrimaryButton label="Create New Template" onClick={onCreateClick} />
        </Grid>

        <Grid item md={12}>
          <DataGridValidation
            loading={loading}
            data={loading ? [] : list}
            columns={columns}
            onEdit={() => {}}
            method={() => {}}
          />
        </Grid>
      </Grid>

      <CreateTemplateForm
        open={open}
        handleClose={onClose}
        type={type}
        tab={tab}
        update={update}
      />

      {data && (
        <PreviewModal
          open={previewOpen}
          template={selectedTemplate}
          handleClose={onClose}
          sectionsData={data.listSection}
          itemsData={[]}
        />
      )}
    </div>
  );
}
