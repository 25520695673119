import { useQuery } from "@apollo/client";
import { getSigned } from "./query";

export default function CaseStudyView({ id, filePath }) {
  const { loading, data } = useQuery(getSigned, {
    variables: {
      input: {
        method: "get",
        entity: "casestudydoc",
        filetype: "pdf",
        filename: id,
        vbeid: id,
        key: filePath,
      },
    },
  });

  if (loading) {
    return null;
  } else if (filePath == null) {
    return null;
  } else if (filePath) {
    return (
      <embed
        src={data?.getSignedURL?.signedurl}
        width="100%"
        height="700"
        type="application/pdf"
      />
    );
  }
}
