import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";

import TimeAndMoneyList from "../price/timeMoneyList";
import MileStoneList from "../price/mileStoneList";
import ProductCostList from "../price/productCostList";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function ViewPriceForm({
  productId,
  projectId,
  readOnly,
  projectData,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {projectData?.type === "product" ? (
        <ProductCostList
          productvbeid={productId}
          projectid={projectId}
          readOnly={readOnly || false}
        />
      ) : projectData?.engagement_type === "time_material" ? (
        <TimeAndMoneyList
          productvbeid={productId}
          projectid={projectId}
          readOnly={readOnly || false}
        />
      ) : (
        <MileStoneList
          productvbeid={productId}
          projectid={projectId}
          readOnly={readOnly || false}
        />
      )}
    </div>
  );
}
