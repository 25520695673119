import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useStyles } from "./styles";

export default function AutoCompleteField({
  options,
  setValues,
  label,
  multiple,
  disabled,
  value,
  onchange,
  onBlur,
  defaultValue,
}) {
  const classes = useStyles();

  let selectedValue = "";
  let selectedVbeid = "";

  const getSelectedOption = () => {
    let selectedOption = "";

    options.map((opt) => {
      if (opt.value === defaultValue) {
        return (selectedOption = opt);
      } else return "";
    });

    return selectedOption || null;
  };

  return multiple ? (
    <Autocomplete
      multiple={multiple}
      value={defaultValue}
      options={options}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => {
        if (option.value) {
          return option.value === value.value;
        } else {
          return option.vbeid === value.vbeid;
        }
      }}
      clearOnBlur="true"
      onBlur={onBlur}
      onChange={
        onchange
          ? onchange
          : (event, newValue) => {
              selectedValue = newValue?.value;
              selectedVbeid = newValue?.vbeid;

              if (selectedValue === undefined) {
                setValues(newValue, selectedValue);
              } else {
                setValues(selectedValue, selectedVbeid);
              }
            }
      }
      renderOption={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          margin="dense"
          id="input"
          className={classes.autocompleteTextField}
        />
      )}
    />
  ) : (
    <Autocomplete
      multiple={multiple}
      options={options}
      value={getSelectedOption()}
      getOptionLabel={(option) => option.name}
      clearOnBlur="true"
      onBlur={onBlur}
      onChange={
        onchange
          ? onchange
          : (event, newValue) => {
              selectedValue = newValue?.value;
              selectedVbeid = newValue?.vbeid;

              setValues(selectedValue, selectedVbeid);
            }
      }
      renderOption={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          margin="dense"
          id="input"
        />
      )}
    />
  );
}
