import AddShort from "./addshort";
import ProjectForm from "./projectform";

import MapRequirementTemplate from "../requirements/mapRequirementTemplate";
import MapOtherTemplate from "../requirements/mapOtherTemplate";
import UpdateRequirementForm from "../requirements/updateRequirements";
import TeamForm from "../team/manageTeam";
import ManageVendors from "../vendors/manageVendors";
import ServiceProject from "./ServiceProject/serviceProject";
import JobDescription from "./ServiceProject/JobDescription";
import RecommendedProviders from "./ServiceProject/RecommendedProviders";
import QnaTemplate from "./ServiceProject/QnaTemplate";
import WorkPackageProject from "./WorkPackage/workPackageProject";

export const getNextPage = (page) => {
  switch (page) {
    case "/create-project-v3":
      return "/add-team-to-project";

    case "/create-service-project-v3":
      return "/job-description";

    case "/job-description":
      return "/recommended-providers";

    case "/add-team-to-project":
      return "/add-short-description";

    case "/service-add-team":
      return "/service-response-template";

    case "/add-short-description":
      return "/add-vendors";

    case "/add-vendors":
      return "/requirement-template";

    case "/requirement-template":
      return "/update-requirements";

    case "/update-requirements":
    case "/other-templates":
      return "/other-templates";

    default:
      return null;
  }
};

export const getPreviousPage = (page) => {
  switch (page) {
    case "/create-project-v3":
    case "/add-team-to-project":
      return "/create-project-v3";

    case "/create-service-project-v3":
      return "/create-service-project-v3";

    case "/job-description":
      return "/create-service-project-v3";

    case "/add-short-description":
      return "/add-team-to-project";

    case "/add-vendors":
      return "/add-short-description";

    case "/requirement-template":
      return "/add-vendors";

    case "/update-requirements":
      return "/requirement-template";

    case "/other-templates":
      return "/update-requirements";

    default:
      return null;
  }
};

export const getPageProgress = (page) => {
  switch (page) {
    case "/create-project-v3":
      return 15;

    case "/create-service-project-v3":
    case "/create-workpackage-project-v3":
      return 5;

    case "/job-description":
    case "/wp-job-description":
      return 15;

    case "/add-team-to-project":
    case "/service-add-team":
      return 20;

    case "/add-short-description":
      return 25;

    case "/add-vendors":
      return 50;

    case "/recommended-providers":
    case "/wp-templates":
      return 60;

    case "/service-response-template":
      return 85;

    case "/requirement-template":
      return 75;

    case "/update-requirements":
      return 85;

    case "/other-templates":
      return 90;

    case "/response-templates":
      return 95;

    default:
      return null;
  }
};

export const getPageMessage = (page) => {
  switch (page) {
    case "/create-project-v3":
    case "/create-service-project-v3":
    case "/create-workpackage-project-v3":
      return {
        title: "Let’s start with a relevant Project Title",
        message:
          "This helps in providing relevance to the identified suppliers about the project. It’s first thing they will see and hence make it count!",
      };

    case "/job-description":
    case "/wp-job-description":
      return {
        title: "Skills Required and Job Description",
        message:
          "Include roles & responsibilities of the resource, your expectations about the task & deliverables, what you’re looking in a resource, and anything unique that you want to share.",
      };

    case "/recommended-providers":
      return {
        title: "Suppliers Listing",
        message:
          "List of suppliers based on your project requirements and as per your company guidelines on supplier compliance. You may select one or many from this list. You can always add suppliers to the project by searching through the marketplace.",
      };

    case "/add-team-to-project":
    case "/service-add-team":
      return {
        title: "Project Team Members",
        message: (
          <span>
            Project owner will be able to create project requirement, add
            suppliers, message with them and submit the project to chosen
            suppliers.
            <br />
            <div style={{ marginTop: "12px" }}>
              Team members will only be able to add suppliers to the project,
              message with them and review their responses.
            </div>
          </span>
        ),
      };

    case "/add-short-description":
      return {
        title: "Project objective ",
        message:
          "Give a short description in about 3 to 5 sentences about your project which defines the objective and expected outcomes. This will be visible to the suppliers upon submitting the project.",
      };

    case "/add-vendors":
      return {
        title: "Suppliers List",
        message:
          "Here are the suppliers whom you and project team members have shortlisted to receive the project requirements. If you have used the Concierge service, you will also see the list of recommended suppliers in the Concierge tab whom you can move to suppliers list. To add more suppliers to the project, use the search bar or product/services supplier listing on the top menu to review and Add to Project. Also, if you no longer want to involve particular supplier(s), you can exclude them from the list.",
      };

    case "/requirement-template":
      return {
        title: "Choose Requirement Template",
        message: (
          <span>
            Here is a list of requirement templates that you can use to create
            your requirement. Templates authored by vBridge Hub and your company
            are listed. You can preview them and select any one of them for the
            project. You can write and define your requirements in the selected
            template in the next tab.
          </span>
        ),
      };

    case "/wp-templates":
      return {
        title: "Choose Project Templates",
        message: (
          <span>
            Here is a list of Requirement, Screening Questions and Response
            templates that you can use to create your requirement. Templates
            authored by vBridge Hub and your company are listed. You can preview
            them and select any one of them for the project. You can write and
            define your requirements for the selected requirement template in
            the next tab.
          </span>
        ),
      };

    case "/update-requirements":
      return {
        title: "Describe your requirement",
        message:
          "This is how vendors/partners will know what you need and if they can meet your expectation! Include your expectations about the task or deliverable, what you are looking for in this requirement, and anything unique that you are looking for.",
      };

    case "/service-response-template":
      return {
        title: "Screening Questions",
        message:
          "Here is a list of templates with Screening Questions that you can use in which your suppliers will provide you their responses. A templatized supplier response will help you and your team in making quick evaluation with side-by-side comparisons.",
      };

    case "/other-templates":
      return {
        title: "Build Q&A Template",
        message:
          "Here is a list of Q&A templates that you can use in which your suppliers will provide you their responses. A templatized supplier response for your Q&A will help you and your team in making quick evaluation with side-by-side comparisons. For your ease, Templates authored by vBridge Hub and your company are listed. You can preview them and select any one of them for the project.",
      };

    case "/response-templates":
      return {
        title: "Build Vendor Response Template",
        message:
          "Here is a list of response templates that you can use in which your suppliers will provide you their overall response along with the Q&A response template. A templatized supplier response will help you and your team in making quick evaluation with side-by-side comparisons. For your ease, Templates authored by vBridge Hub and your company are listed. You can preview them and select any one of them for the project.",
      };

    default:
      return null;
  }
};

export const GetMainForm = (props) => {
  switch (props.page) {
    case "/create-project-v3":
      return <ProjectForm />;

    case "/create-service-project-v3":
      return <ServiceProject />;

    case "/create-workpackage-project-v3":
      return <WorkPackageProject />;

    case "/job-description":
      return <JobDescription />;

    case "/wp-job-description":
      return <JobDescription projectType="workpackage" />;

    case "/add-team-to-project":
      return <TeamForm />;

    case "/service-add-team":
      return <TeamForm type="service" />;

    case "/add-short-description":
      return <AddShort />;

    case "/add-vendors":
      return <ManageVendors />;

    case "/requirement-template":
      return <MapRequirementTemplate />;

    case "/update-requirements":
      return <UpdateRequirementForm />;

    case "/other-templates":
    case "/response-templates":
      return <MapOtherTemplate />;

    case "/recommended-providers":
      return <RecommendedProviders />;

    case "/qna-templates":
    case "/service-response-template":
      return <QnaTemplate />;

    default:
      return <h1>Page Not Found</h1>;
  }
};
