/**
 * Component to render list of different filters
 */

import { useParams } from "react-router";

import { useQuery } from "@apollo/client";

import { Grid } from "@material-ui/core";

import FilterList from "./FilterList";

import {
  listFoundedYearsById,
  //   listFundingRoundsById,
  listLocationsById,
  listMaturityById,
  //   listPartnersById,
  listRecognisationsById,
  listSkillsById,
  //   listVerticalsById,
} from "./query";

import { useStyles } from "./styles";
import { sortData, sortYear } from "../../../functions/helper";

export default function MainFilters({
  onFilterSet,
  onFilterAwards,
  onFilterYear,
  onFilterPartner,
  onFilterFundingRound,
  onFilterMaturity,
  onFilterSkills,
  locationsList,
  awardsList,
  partnerList,
  fundingRoundsList,
  yearList,
  maturityList,
  skillList,
  type,
  typeValue,
}) {
  const { id } = useParams();
  const classes = useStyles();

  let idName = "topicid";
  if (type === "group") {
    idName = "groupid";
  } else if (type === "skill") {
    idName = "skillid";
  }

  let filterInput = {
    [idName]: id,
    limit: 100,
    offset: 0,
    awardslist: awardsList.length !== 0 ? awardsList : null,
    yearlist: yearList.length !== 0 ? yearList : null,
    partnerlist: partnerList.length !== 0 ? partnerList : null,
    locationlist: locationsList.length !== 0 ? locationsList : null,
    fundroundlist: fundingRoundsList.length !== 0 ? fundingRoundsList : null,
    maturitylist: maturityList.length !== 0 ? maturityList : null,
    skilllist:
      skillList.length !== 0 && typeValue === "services" ? skillList : null,
  };

  let { data: recognisations } = useQuery(listRecognisationsById, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      input: filterInput,
    },
  });

  //   let { data: partners } = useQuery(listPartnersById, {
  //     fetchPolicy: "network-only",
  //     nextFetchPolicy: "cache-first",
  //     variables: {
  //       input: filterInput,
  //     },
  //   });

  let { data: foundedYears } = useQuery(listFoundedYearsById, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      input: filterInput,
    },
  });

  let { data: locations } = useQuery(listLocationsById, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      input: filterInput,
    },
  });

  let { data: maturity } = useQuery(listMaturityById, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      input: filterInput,
    },
  });

  let { data: skills } = useQuery(listSkillsById, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      input: filterInput,
    },
  });

  //   let { data: verticals } = useQuery(listVerticalsById, {
  //     variables: { input: { [idName]: id, limit: 100, offset: 0 } },
  //   });

  //   let { data: fundingRounds } = useQuery(listFundingRoundsById, {
  //     variables: { input: { [idName]: id, limit: 100, offset: 0 } },
  //   });

  const getSelectedLocations = (id, checkedValue) => {
    onFilterSet(id, checkedValue);
  };

  const getSelectedAwards = (id, checkedValue) => {
    onFilterAwards(id, checkedValue);
  };

  const getSelectedYears = (id, checkedValue) => {
    onFilterYear(id, checkedValue);
  };

  //   const getSelectedPartner = (id, checkedValue) => {
  //     onFilterPartner(id, checkedValue);
  //   };

  const getSelectedMaturity = (id, checkedValue) => {
    onFilterMaturity(id, checkedValue);
  };

  const getSelectedSkills = (id, checkedValue) => {
    onFilterSkills(id, checkedValue);
  };

  //   const getSelectedFundingRound = (id, checkedValue) => {
  //     onFilterFundingRound(id, checkedValue);
  //   };

  return (
    <Grid item md={3} className={classes.filterGroup}>
      {typeValue === "services" && (
        <FilterList
          data={skills?.getSkills ? sortData(skills.getSkills) : null}
          type="Skills"
          getList={getSelectedSkills}
          listData={skillList}
        />
      )}

      <FilterList
        data={
          recognisations?.getRecognizationsbytopicId
            ? sortData(recognisations.getRecognizationsbytopicId)
            : null
        }
        type="Awards"
        getList={getSelectedAwards}
        listData={awardsList}
      />

      <FilterList
        data={maturity?.getMaturity ? sortData(maturity.getMaturity) : null}
        type="Supplier Maturity"
        getList={getSelectedMaturity}
        listData={maturityList}
      />

      <FilterList
        data={locations?.getLocations ? sortData(locations.getLocations) : null}
        type="Locations"
        getList={getSelectedLocations}
        listData={locationsList}
      />

      <FilterList
        data={
          foundedYears?.getFoundedYears
            ? sortYear(foundedYears.getFoundedYears)
            : null
        }
        type="Founded Year"
        getList={getSelectedYears}
        listData={yearList}
      />

      {/* <FilterList
        data={partners?.getPartners ? sortData(partners.getPartners) : null}
        type="Partners"
        getList={getSelectedPartner}
        listData={partnerList}
      /> */}

      {/* <FilterList
        data={verticals?.getCustomerbyvertical}
        type="Customer Verticals"
      /> */}

      {/* <FilterList
        data={
          fundingRounds?.getFundingRounds
            ? sortData(fundingRounds.getFundingRounds)
            : null
        }
        type="Funding Round"
        getList={getSelectedFundingRound}
      /> */}
    </Grid>
  );
}
