import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  openDrawerRoot: {
    marginLeft: 200,
  },
  main: {
    padding: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
    "& span": {
      fontSize: "0.8rem",
    },
  },
  mainmenu: {
    color: theme.palette.secondary.main,
  },
  backbutton: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(18),
  },
  projectName: {
    textAlign: "left",
    marginLeft: theme.spacing(-3),
  },
  mainArea: {
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: "1rem",
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  menuList: {
    borderRight: "1px solid rgba(0,0,0,0.1)",
  },
  dashboardMenuItem: {
    marginTop: theme.spacing(1),
  },
  projectNameDiv: {
    "&:hover": {
      color: "#4179BD",
    },
  },
}));
