import { gql } from "@apollo/client";

export const getCompaniesBySkill = gql`
  query getCompaniesBySkill($input: genericinput!) {
    listCompanyfilter(input: $input) {
      name
      image
      companyname
      companyvbeid
      topicname
      topicvbeid
      shortdescription
      id
      vbeid
      awardcount
      partnercount
      customercount
      casestudycount
      usecasecount
      securityscorecard_grade_url
      knpashot_score
      groupname
      groupvbeid
      companylocation
      fundround
      founded
      longdescription
    }
  }
`;

export const getSkillById = gql`
  query getSkillById($input: genericinput!) {
    querySkillbyId(input: $input) {
      name
      vbeid
    }
  }
`;
