import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    marginLeft: "40%",
    marginTop: "-10%",
  },
  createFormGroup: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  //   formControl: {
  //     marginBottom: theme.spacing(1),
  //   },
  subheading: {
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(2),
    textAlign: "left",
    textTransform: "capitalize",
  },
  inline: {
    textAlign: "left",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  claimCompany: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  companyName: {
    fontSize: theme.spacing(1),
  },
  companyAvatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  companyObject: {
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  myCompanyHeading: {
    color: theme.palette.secondary.main,
    textAlign: "left",
    textTransform: "capitalize",
  },
  myCompanyRoot: {
    padding: "8px",
    marginBottom: theme.spacing(1),
    borderRadius: 0,
  },
  myCompanyContent: {
    padding: "0 8px",
  },
  companyActions: {
    cursor: "pointer",
    padding: "4px 8px 0",
  },
  myCompanyName: {
    fontWeight: 500,
  },
  statusBar: {
    height: theme.spacing(1),
    backgroundColor: "#4CAF50",
  },
  redStatusBar: {
    height: theme.spacing(1),
    backgroundColor: "#DC004E",
  },

  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
  },
  drawerOpenRoot: {
    marginTop: theme.spacing(10),
    marginLeft: 200,
  },
  card: {
    maxWidth: "100%",
    color: "#0e3046",
    lineHeight: "1.8",
    position: "relative",
  },
  square: {
    width: 100,
    height: 100,
    marginTop: "1%",
    marginLeft: "1%",
  },
  ngrid: {
    marginBottom: "3%",
  },
  descriptionfield: {
    width: 600,
    fontSize: "3em",
  },
  emptyDesc: {
    color: "#bebebe",
    fontSize: "0.75rem",
    fontStyle: "italic",
  },
  groupName: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.3rem",
  },
  companyInfoHeading: {
    fontSize: "0.85rem",
    fontWeight: "500",
  },
  infoGrid: {
    display: "flex",
    justifyContent: "space-around",
    margin: theme.spacing(3.5, 0, 2),
  },
  overviewContentList: {
    listStyleType: "none",
    padding: 0,
    "& li": {
      padding: theme.spacing(0.5, 1),
      cursor: "pointer",
      fontSize: "0.85rem",
    },
  },
  selectedListTitle: {
    background: "#efefef",
    fontWeight: "500",
    borderRadius: "5px",
  },
  divider: {
    height: "40px",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  updateButtonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  errorfields: {
    color: "red",
    fontSize: "10px",
    marginBottom: theme.spacing(1),
  },
  actionBtn: {
    fontSize: "0.8rem",
  },
  updateHeading: {
    padding: theme.spacing(2),
    "& h2": {
      fontSize: "1rem",
    },
  },
  dialogContent: {
    padding: theme.spacing(1, 2),
    "& .MuiGrid-container": {
      marginTop: theme.spacing(1),
    },
  },
  buttonsDiv: {
    marginTop: theme.spacing(1),
  },
  stepTitleMessage: {
    display: "flex",
    justifyContent: "space-between",
  },
  mapText: {
    textAlign: "center",
    margin: "16px auto 0",
    fontSize: "0.8rem",
    fontStyle: "italic",
    width: "80%",
  },
  formDiv: {
    marginBottom: theme.spacing(1),
    width: "90%",
  },

  country: {
    marginBottom: theme.spacing(1),
    width: "45%",
    paddingRight: "2px",
    marginTop: "-16px",

    "& label": {
      fontSize: "14px",
    },
  },

  yrefields: {
    marginBottom: theme.spacing(1),
    width: "30%",
    paddingRight: "2px",
  },

  headquarters: {
    //marginBottom: theme.spacing(1),
    width: "90%",

    "& h6": {
      margin: "0px 8px 12px 2px !important",
      paddingBottom: "8px",
      fontWeight: 600,
      fontSize: "12px",
    },
  },

  management: {
    marginBottom: "2px",
    width: "90%",
  },
}));
