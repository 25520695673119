import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    justifyContent: "center",

    "& button": {
      textTransform: "none !important",
    },
  },

  item: {
    margin: theme.spacing(0.4),
  },

  swiper: {
    "& .MuiDrawer-paper": {
      width: "70%",
    },

    "& .MuiCard-root": {
      overflowY: "scroll !important",

      // "&::-webkit-scrollbar": {
      //   margin: "8px !important",
      //   width: "0.15em",
      //   height: "10px !important",
      // },
      // "&::-webkit-scrollbar-track": {
      //   "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      // },
      // "&::-webkit-scrollbar-thumb": {
      //   backgroundColor: "#232961",
      //   outline: "3px solid slategrey",
      //   borderRadius: "8px !important",
      // },
    },

    "& .makeStyles-companyDetailsDiv-736": {
      margin: 0,
    },
  },

  headcontainer: {
    margin: theme.spacing(1, 1, 0, 1),
    padding: theme.spacing(1, 0),
    background: "#eee",
  },

  sliderTitle: {
    fontSize: theme.spacing(2),
    padding: theme.spacing(0.4),
    margin: "4px 4px 4px 12px !important",
    fontWeight: 600,
  },

  sliderhelpertext: {
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.4),
    margin: "8px 0px !important",

    fontWeight: 600,
  },

  cardgrid: {
    display: "flex",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    // background: "#f4f0ec",
  },

  cards: {
    "& p": {
      margin: "1px 4px !important",
    },
    // display: "flex",
  },

  header: {
    justifyContent: "left",
    fontSize: theme.spacing(2),
    fontWeight: "600 !important",
    padding: theme.spacing(0, 1),
    margin: theme.spacing(1),
    "& p": {
      margin: "0px !important",
      padding: "0px !important",
    },
  },

  cardheader: {
    justifyContent: "left",
    fontSize: theme.spacing(1.8),
    fontWeight: "600 !important",
    padding: theme.spacing(1),
    background: "#eee",
    margin: theme.spacing(0),
  },

  cardhead: {
    background: "#eee",
    "& button": {
      justifyContent: "left !important",
      alignItems: "left !important",
    },
  },

  details: {
    padding: theme.spacing(1),
  },

  addheader: {
    justifyContent: "left",
    fontSize: theme.spacing(2),
    fontWeight: "600 !important",
    margin: "8px 16px !important",
  },

  field: {
    margin: theme.spacing(0.4, 2),
  },
  cityfield: {
    margin: theme.spacing(1.2, 2, 0.4, 2),
  },

  addressfield: {
    margin: theme.spacing(1.2, 2, 0.4, 2),
  },

  countrystate: {
    margin: theme.spacing(0, 1, 0, 0),
    // marginBottom: "8px !important",
  },
  bankCountry: {
    marginTop: "-8px !important",
    marginBottom: "8px !important",
  },

  addlegalroot: {
    marginTop: theme.spacing(1),

    "& label": {
      fontSize: "12px !important",
      color: "#000000",
    },

    "& input": {
      fontSize: "12px !important",
      color: "#000000",
    },
  },

  divider: {
    borderColor: "#E8E8E8 !important",
    fontSize: "2px !important",
    borderWidth: "2px !important",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },

  btn: {
    margin: "12px 16px 16px  !important",
  },
  cancelbtn: {
    marginRight: "8px !important",
  },

  errorfields: {
    fontSize: "10px !important",
    color: "red",
  },

  label: {
    fontSize: "12px !important",
    fontWeight: "600 !important",
  },
  value: {
    fontSize: "12px !important",
    fontWeight: "400 !important",
  },
  editbtn: {
    marginTop: "4px !important",
    cursor: "pointer",

    "& svg": {
      marginTop: "4px !important",

      fontSize: "18px !important",
      color: "#232961",
    },
  },

  // editicon: {
  //   fontSize: "14px !important",
  //   color: "#eee",
  // },

  entitycard: {
    padding: theme.spacing(1),
  },

  cardsAdd: {
    background: "#eee",
    width: "400px !important",
    height: "315px !important",
  },

  addCard: {
    cursor: "pointer",
  },

  newcard: {
    background: "#eee",
    justifyContent: "center",
  },

  addcardhead: {
    background: "#eee",
  },
  addcardheader: {
    justifyContent: "left",
    fontSize: theme.spacing(1.8),
    fontWeight: "600 !important",
    padding: theme.spacing(1),
    margin: theme.spacing(0),
    background: "#ffff",
  },

  helpertext: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },

  approve: {
    color: "#005900 !important",
    padding: "0px !important",
    margin: "0px !important",
    fontStyle: "italic",
    fontSize: "12px !important",
  },

  Approved: {
    //color: "#ff4040 !important",
    padding: "0px !important",
    margin: "0px !important",
  },

  docs: {
    padding: "4px",
    boxShadow: "2px 4px 2px 4px #eee !important",
    margin: "8px !important",
    background: "#eee !important",

    "& ul": {
      padding: "0px !important",
      margin: "0px !important",
    },
    "& paper": {
      padding: "0px !important",
      margin: "0px !important",
    },

    "& li": {
      padding: "0px !important",
      margin: "0px !important",
    },
    "& svg": {
      color: "#a30000",
      // fontSize: theme.spacing(3.2),
    },

    "& span": {
      fontSize: theme.spacing(1.8),
    },
  },

  pdf: {
    "& svg": {
      color: "#a30000",
      fontSize: theme.spacing(3),
    },

    "& span": {
      cursor: "pointer !important",
    },
  },

  entityinfo: {
    margin: theme.spacing(0, 2),
  },

  decline: {
    color: "#b30000 !important",
    padding: "0px !important",
    margin: "0px !important",
    fontSize: "12px !important",
  },

  addnew: {
    margin: "12px !important",
    color: "#232961 !important",
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },

  nodata: {
    color: "#232961 !important",
    fontWeight: "500 !important",
    marginLeft: theme.spacing(1),
  },

  link: {
    color: "teal",
    textDecoration: "underline",
  },

  accounting: {
    marginBottom: theme.spacing(1),
  },

  fileName: {
    cursor: "pointer",
  },

  // pdfdivider: {
  //   "& hr": {
  //     padding: theme.spacing(0.5),
  //     margin: theme.spacing(0, 1),
  //     borderColor: "#000000 !important",
  //     borderWidth: "0px 1px  0px 0px !important",
  //     height: "25px !important",
  //   },
  // },
}));
