import { Grid, Typography } from "@material-ui/core";

export default function ListInfo({ name, description }) {
  return (
    <Grid container md={12}>
      <Grid item md={12}>
        <Typography gutterBottom variant="BUTTON TEXT" component="h4">
          {name}
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="caption" component="p">
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
}
