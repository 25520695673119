import * as React from "react";

import { useParams } from "react-router-dom";

import ViewResponseForm from "./responseForm";
import ViewQAForm from "./qaForm";
import ViewPriceForm from "./priceForm";

import { useStyles } from "./styles";
import SubmitResponse from "./SubmitResponse";

export default function ReviewSubmit({
  productId,
  readOnly,
  projectData,
  refetch,
}) {
  const { id } = useParams();
  const classes = useStyles();

  return (
    <>
      <div className={classes.responseHeading}>Solution Response</div>
      <ViewResponseForm
        id={id}
        productId={productId}
        readOnly={readOnly || false}
      />

      <div className={classes.responseHeading}>Q&A Response</div>
      <ViewQAForm id={id} productId={productId} readOnly={readOnly || false} />

      <div className={classes.responseHeading}>Pricing Response</div>
      <ViewPriceForm
        productId={productId}
        projectId={id}
        readOnly={readOnly || false}
        projectData={projectData}
      />

      <SubmitResponse
        readOnly={readOnly || false}
        refetch={refetch}
        projectData={projectData}
      />
    </>
  );
}
