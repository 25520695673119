import React from "react";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import { Box, makeStyles } from "@material-ui/core";

import QuestionForm from "../discussion2.0/QuestionForm";
import ContentLoading from "./ContentLoading";
import ReplyContent from "./ReplyContent";

import { addReply, listReplyByQuestion } from "./query";

const useStyles = makeStyles((theme) => ({
  boxRoot: {
    margin: "0 8px",
  },
}));

export default function RepliesList({
  id,
  projectId,
  productId,
  userInfo,
  updateRepliesCount,
}) {
  const client = useApolloClient();
  const classes = useStyles();

  const { loading, data } = useQuery(listReplyByQuestion, {
    variables: {
      input: {
        projectvbeid: projectId,
        productvbeid: productId,
        questionsk: id,
      },
    },
  });

  const [addReplyMutation] = useMutation(addReply, {
    onCompleted: (e) => {
      updateReplyCache(e);
    },
  });

  const replies = client.readQuery({
    query: listReplyByQuestion,
    variables: {
      input: {
        projectvbeid: projectId,
        productvbeid: productId,
        questionsk: id,
      },
    },
  });

  function sendReply(data) {
    addReplyMutation({
      variables: {
        input: {
          projectvbeid: projectId,
          questionsk: id,
          productvbeid: productId,
          reply: data,
        },
      },
    });
  }

  function updateReplyCache(e) {
    var currentlist = replies ? [...replies.listReplyByQuestion] : [];

    client.writeQuery({
      query: listReplyByQuestion,
      data: {
        listReplyByQuestion: currentlist.push(e.addReply),
      },
      variables: {
        input: {
          projectvbeid: projectId,
          productvbeid: productId,
          questionsk: id,
        },
      },
    });

    updateRepliesCount();
  }

  if (loading) {
    return <ContentLoading />;
  }

  return (
    <Box className={classes.boxRoot}>
      {data
        ? data.listReplyByQuestion.map((value) => {
            return <ReplyContent reply={value} />;
          })
        : null}

      <QuestionForm
        projectId={projectId}
        productId={productId}
        saveMethod={sendReply}
        editorId={"re" + id}
        type="reply"
        closeQuestion={() => {}}
      />
    </Box>
  );
}
