import { useState } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";

import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import CategoriesSearchResults from "../entities/home/CategoriesSearchResults";
import ProductsSearchResults from "../entities/home/ProductsSearchResults";
import SearchBox from "../entities/home/SearchBox";

import { getSearchResults } from "../entities/home/query";
import { listFollowingGroups } from "../entities/profile2.0/query";

import { useStyles } from "./appBarStyles";

import ProductDetail from "../entities/home/ProductDetail";
import HelpRequest from "../entities/Help/HelpRequest";

const homeGroups = require("./../../data/homeGroups.json");
const homeServiceGroups = require("./../../data/homeServiceGroups.json");

export default function HomeSearch({
  onSearchActive,
  open,
  toggleValue,
  onSelect,
  mainDrawerClick,
}) {
  const classes = useStyles();

  let followingGroups = [];
  let followingGroupNames = [];
  let popularGroupList = [];
  let popularServiceGroups = [];
  let popularGroupNames = [];
  let popularServiceGroupNames = [];
  let searchContext = "Products";

  const [searchText, setSearchText] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [searchType, setSearchType] = useState("keyword");
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");

  const { data: followingGroupsList } = useQuery(listFollowingGroups);

  const [searchQuery, { loading, data }] = useLazyQuery(getSearchResults, {
    variables: { input: { query: searchText } },
    onCompleted: (response) => {
      let searchResults = response.openAIsearch;
      let products = [];
      let categories = [];

      searchResults.forEach((result) => {
        if (!products.includes(result.vbeid)) {
          products.push(result.vbeid);
        }

        if (!categories.includes(result.label)) {
          categories.push(result.label);
        }
      });

      setProductsList(products);
      setCategoriesList(categories);

      mainDrawerClick("", "close");

      onSearchActive();
    },
  });

  const onSearch = (text) => {
    setSearchText(text);
    searchQuery();
  };

  const handleSearchType = (event) => {
    setSearchType(event.target.value);
  };

  const onProductCardClick = (product) => {
    setDetailOpen(!detailOpen);
    setSelectedProduct(product);
  };

  const onDrawerClose = () => {
    setDetailOpen(false);
  };

  if (
    followingGroupsList &&
    followingGroupsList.getFollowingGroupsByUserId &&
    followingGroupsList.getFollowingGroupsByUserId !== null
  ) {
    followingGroupsList.getFollowingGroupsByUserId.forEach((group, index) => {
      followingGroups.push(group.id);
      followingGroupNames.push(group.name);
    });
  }

  homeGroups.forEach((group) => {
    if (!followingGroups.includes(group["n.vbeid"])) {
      popularGroupList.push(group["n.vbeid"]);
      popularGroupNames.push(group["n.name"]);
    }
  });

  homeServiceGroups.forEach((group) => {
    if (!followingGroups.includes(group["n.vbeid"])) {
      popularServiceGroups.push(group["n.vbeid"]);
      popularServiceGroupNames.push(group["n.name"]);
    }
  });

  return (
    <div elevation={1}>
      {/* <div className={classes.all}> */}
      {/* <FormControl component="fieldset" className={classes.searchTypeForm}>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue={searchType}
          >
            <FormControlLabel
              className={classes.radioButtonLabel}
              value="keyword"
              control={<Radio color="primary" />}
              label="Keyword Search"
              onChange={handleSearchType}
            />

            <FormControlLabel
              className={classes.radioButtonLabel}
              value="advanced"
              control={<Radio color="primary" />}
              label="Advanced Search"
              onChange={handleSearchType}
            />
          </RadioGroup>
        </FormControl> */}

      {/* <div className={classes.helpdiv}>
          <HelpRequest refer="category" />
        </div>
      </div> */}

      {/* <Paper elevation={1} square className={classes.searchBoxDiv}>
        <Box p={2}>
          <Typography className={classes.searchTitle}>
            Get started by discovering{" "}
            {toggleValue === "services" ? "Services" : "Products"} and Solutions
          </Typography>

          <SearchBox
            onSearchClick={onSearch}
            searchType={searchType}
            onSelect={onSelect}
            toggleValue={toggleValue}
          />
        </Box>
      </Paper> */}

      {loading && (
        <div style={{ marginTop: "32px" }}>
          <CircularProgress />
          <div>Please wait while we get the best results</div>
        </div>
      )}

      {data && (
        <>
          <ProductsSearchResults
            products={productsList}
            type="product"
            open={open}
            rowType="search"
            onProductCardClick={onProductCardClick}
            searchContext={searchContext}
            toggleValue={toggleValue}
            mainDrawerClick={mainDrawerClick}
          />

          <CategoriesSearchResults
            categories={categoriesList}
            categoryNames={[]}
            type="category"
            open={open}
            rowType="search"
            onProductCardClick={onProductCardClick}
            searchContext={searchContext}
            toggleValue={toggleValue}
            mainDrawerClick={mainDrawerClick}
          />
        </>
      )}

      {followingGroups.length !== 0 && (
        <CategoriesSearchResults
          categories={followingGroups}
          categoryNames={followingGroupNames}
          type="group"
          open={open}
          rowType="interest"
          onProductCardClick={onProductCardClick}
          searchContext={searchContext}
          toggleValue={toggleValue}
          mainDrawerClick={mainDrawerClick}
        />
      )}

      {popularGroupList && (
        <>
          <CategoriesSearchResults
            categories={popularServiceGroups}
            categoryNames={popularServiceGroupNames}
            type="group"
            open={open}
            rowType="trending"
            onProductCardClick={onProductCardClick}
            searchContext="Services"
            toggleValue={toggleValue}
            mainDrawerClick={mainDrawerClick}
          />
          <CategoriesSearchResults
            categories={popularGroupList}
            categoryNames={popularGroupNames}
            type="group"
            open={open}
            rowType="trending"
            onProductCardClick={onProductCardClick}
            searchContext="Products"
            toggleValue={toggleValue}
            mainDrawerClick={mainDrawerClick}
          />
        </>
      )}

      <ProductDetail
        open={detailOpen}
        product={selectedProduct}
        onClose={onDrawerClose}
        type="product"
        mainDrawerClick={mainDrawerClick}
      />
    </div>
  );
}
