import * as React from "react";
import { useMutation, gql } from "@apollo/client";
// import { isOverflown  } from '@material-ui/x-grid';
import { VBRIDGEHUB_MEDIAURL } from "../../../../constants";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Grid, Paper } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { GridOverlay } from "@material-ui/data-grid";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import SimpleSnackbar from "./snackbar";
import SimpleErrorSnackbar from "./errorsnackbar";
import { DataGrid } from "@material-ui/data-grid";

import Tooltip from "@material-ui/core/Tooltip";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
  },
  cell: {
    alignItems: "center",
    lineHeight: "24px",
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    "& .cellValue": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: theme.spacing(2),
  },
}))(Tooltip);

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    // const isCurrentlyOverflown = isOverflown(cellValue.current);
    // setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };
  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div
      ref={wrapper}
      className={classes.cell}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </div>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value ? params.value.toString() : ""}
      width={params.colDef.width}
    />
  );
}

renderCellExpand.propTypes = {
  colDef: PropTypes.any.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
};

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const getProducts = gql`
  query products($offset: Int, $limit: Int) {
    listProduct(input: { offset: $offset, limit: $limit }) {
      id
      name
      image
      category
      producturl
      shortdescription
    }
  }
`;

const columns = [
  {
    field: "image",
    headerName: " Logo",
    width: 100,
    renderCell: (params) => (
      <Avatar variant="square" src={VBRIDGEHUB_MEDIAURL + "/" + params.value}>
        {":("}
      </Avatar>
    ),
  },

  {
    field: "name",
    headerName: " Name",
    width: 200,
    renderCell: (params) => <> {params.value}</>,
  },
  {
    field: "shortdescription",
    headerName: " Short Description",
    width: 400,
    editable: true,
    renderCell: (params) => (
      <div>
        <LightTooltip title={params.value} placement="right">
          <div>{params.value}</div>
        </LightTooltip>
      </div>
    ),
  },
  {
    field: "category",
    headerName: "Category",
    width: 200,
    editable: true,
    renderCell: (params) => <> {params.value}</>,
  },
  {
    field: "producturl",
    headerName: " URL",
    width: 100,
    renderCell: (params) => (
      <a rel="noreferrer" href={params.value} target="_blank">
        webpage
      </a>
    ),
  },
];

export default function ProductsProfileList() {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      pagefrom: "",
      pageto: "",
    },
  });

  const [getProductResults, { loading, data }] = useLazyQuery(getProducts, {
    variables: { offset: formik.values.pagefrom, limit: formik.values.pageto },
  });

  const updateProduct = gql`
    mutation update($input: productinput!) {
      updateProduct(input: $input) {
        id
        shortdescription
      }
    }
  `;

  const [
    postproductmutation,
    { loading: updateLoading, error: mutationError },
  ] = useMutation(updateProduct, {
    onCompleted: (e) => {
      onUpdateProductCompleted(e);
    },
  });

  function onUpdateProductCompleted(e) {}

  function oncelledit(param) {
    postproductmutation({
      variables: {
        input: { vbeid: param.id, shortdescription: param.props.value },
      },
    });
  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          {mutationError && <SimpleErrorSnackbar />}
          {updateLoading && <SimpleSnackbar />}
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Box m={12}>
          <Grid container xs={12} spacing={2}>
            <h3 style={{ textAlign: "Left" }}>Product Validation List</h3>

            <form noValidate autoComplete="off">
              <Grid container spacing={1} justify="flex-end">
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    margin="dense"
                    label="From"
                    variant="outlined"
                    value={formik.values.pagefrom}
                    onChange={formik.handleChange("pagefrom")}
                  />
                </Grid>

                <Grid item>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Size
                    </InputLabel>

                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={formik.values.pageto}
                      onChange={formik.handleChange("pageto")}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    style={{ marginTop: "10%" }}
                    onClick={() => getProductResults()}
                    size="small"
                    color="primary"
                  >
                    Go to Page
                  </Button>
                </Grid>
              </Grid>
            </form>

            <Paper style={{ width: "100%" }}>
              {data ? (
                <DataGrid
                  loading={loading}
                  density="compact"
                  disableColumnMenu={true}
                  autoHeight={true}
                  pageSize={10}
                  rowCount={1000}
                  checkboxSelection={true}
                  onEditCellChangeCommitted={oncelledit}
                  LoadingOverlay={CustomLoadingOverlay}
                  rowHeight={80}
                  rows={loading ? loading : data ? data.listProduct : null}
                  columns={columns.map((column) => ({
                    ...column,
                    sortable: false,
                  }))}
                />
              ) : null}
            </Paper>
          </Grid>
        </Box>
      </div>
    </>
  );
}
