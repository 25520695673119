import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, FormControl, Grid, CircularProgress } from "@material-ui/core";

import { GET_SECTIONS } from "../../../queries/query";

import { useQuery } from "@apollo/client";

import RSection from "../psection";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: theme.spacing(80),
  },
  main: {
    width: theme.spacing(100),
  },
  formControlclass: {
    width: "100%",
    textAlign: "left",
  },
}));

export default function PreviewTemplate(props) {
  const classes = useStyles();
  var sweight = 0;

  const { loading, error, data } = useQuery(GET_SECTIONS, {
    fetchPolicy: "network-only",
    variables: { input: { vbeid: props.id } },
  });

  if (data) {
    data.listSection.forEach((section, index) => {
      var metaitem = JSON.parse(section.metadata);
      sweight = sweight + metaitem.weight;
    });

    props.setSweight(sweight);
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.main}>
        <Grid item xs={12}>
          {data ? (
            <Box m={2}>
              <FormControl
                component="fieldset"
                className={classes.formControlclass}
              >
                {data.listSection.map((section, index) => {
                  var meta = JSON.parse(section.metadata);
                  meta = { ...meta, id: index + 1 };

                  return (
                    <RSection
                      key={section.id}
                      name={section.name}
                      vbeid={props.id}
                      sid={section.id}
                      id={index + 1}
                      metadata={meta}
                      preview={true}
                      type={props.type}
                      disabled={props.disabled}
                    />
                  );
                })}
              </FormControl>
            </Box>
          ) : loading ? (
            <CircularProgress />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
