import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  listItem: {
    paddingTop: 0,
  },
  chatRoot: {
    maxHeight: theme.spacing(60),
  },
  itemRoot: {
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
  mainFlexBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: theme.spacing(40),
  },
  mainForumBox: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    maxHeight: theme.spacing(40),
    padding: theme.spacing(1),
    borderRadius: "5px",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  emptyChatBox: {
    color: "#999",
    fontSize: "0.7rem",
    paddingLeft: theme.spacing(2),
  },
  newHeading: {
    margin: "40px 20px 20px",
    fontSize: "0.8rem",
    fontStyle: "italic",
  },
  newSubHeading: {
    fontStyle: "italic",
  },
}));
