import countries from "../data/countriesdata.json";

const skills = require("../data/services/skills.json");

export function getDate(datetime) {
  let date = new Date(parseInt(datetime) * 1000).toDateString("en-US");
  let time = new Date(parseInt(datetime) * 1000).toLocaleTimeString("en-US");

  return date + " " + time + " IST";
}

export function getCreatedDate(timestamp) {
  var myDate = new Date(parseInt(timestamp));

  return myDate.toLocaleString();
}

export function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export function validateEmail(email) {
  let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (regex.test(email)) return true;
  else return false;
}

export function sortData(data) {
  return data.slice().sort((a, b) => b.count - a.count);
}

export function sortYear(data) {
  return data.slice().sort((a, b) => b.year - a.year);
}

export const TECH_VENDOR = "Tech Vendor";
export const SERVICE_PROVIDER = "Service Provider";
export const ENTERPRISE = "Enterprise";
export const TECH_SERVICE = "Tech and Service";
export const ENTERPRISE_TECH = "Enterprise and Tech";
export const ENTERPRISE_SERVICE = "Enterprise and Service";
export const EP_TECH_SERVICE = "Enterprise and Tech and Service";

export function getOrgType(orgType) {
  if (orgType.length === 1) {
    if (orgType.includes(TECH_VENDOR)) {
      return TECH_VENDOR;
    } else if (orgType.includes(SERVICE_PROVIDER)) {
      return SERVICE_PROVIDER;
    } else if (orgType.includes(ENTERPRISE)) {
      return ENTERPRISE;
    }
  } else {
    if (
      orgType.length !== 0 &&
      orgType.includes(TECH_VENDOR) &&
      orgType.includes(SERVICE_PROVIDER)
    ) {
      return TECH_SERVICE;
    } else if (
      orgType.length === 0 ||
      (orgType.includes(ENTERPRISE) &&
        !orgType.includes(TECH_VENDOR) &&
        !orgType.includes(SERVICE_PROVIDER))
    )
      return ENTERPRISE;
    else if (orgType.includes(ENTERPRISE) && orgType.includes(TECH_VENDOR)) {
      return ENTERPRISE_TECH;
    } else if (
      orgType.includes(ENTERPRISE) &&
      orgType.includes(SERVICE_PROVIDER)
    ) {
      return ENTERPRISE_SERVICE;
    } else if (
      orgType.includes(ENTERPRISE) &&
      orgType.includes(TECH_VENDOR) &&
      orgType.includes(SERVICE_PROVIDER)
    ) {
      return EP_TECH_SERVICE;
    }
  }
}

export function getFormattedYear(year) {
  let yearString = year.split(" ");
  let formatYear = yearString[0].split("-");

  let yearValue = formatYear[0];
  let monthValue = formatYear[1];
  let dateValue = formatYear[2];

  return monthValue + "-" + dateValue + "-" + yearValue;
}

export function getDateFromEpoch(date) {
  let dateValue = Date(date);

  return getFormattedDate(dateValue);
}

export function getFormattedDate(date) {
  let dateValue = new Date(date);

  let dateNumber = dateValue.getDate();
  let month = dateValue.toLocaleString("default", { month: "long" });
  let year = dateValue.getFullYear();

  if (dateNumber < 10) {
    dateNumber = "0" + dateValue.getDate();
  }

  return dateNumber + " " + month + " " + year;
}

export const equalsIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false;

  const uniqueValues = new Set([...a, ...b]);

  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length;
    const bCount = b.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }

  return true;
};

export const getHoveredGroupItems = (hoveredGroup, groups) => {
  let groupItems = [];

  groups.forEach((group) => {
    if (
      group["n.type"] === hoveredGroup ||
      group["grp.name"] === hoveredGroup
    ) {
      groupItems.push(group);
    }
  });

  return groupItems;
};

export const getSkillName = (skillList, type) => {
  if (type === "single") {
    let skillName = "";

    skills.forEach((skill) => {
      if (skill["skl.vbeid"] === skillList) {
        skillName = skill["skl.name"];
      }
    });

    return skillName;
  } else {
    let skillNames = "";

    skills.forEach((skill) => {
      skillList.forEach((singleSkill) => {
        if (skill["skl.vbeid"] === singleSkill) {
          if (skillNames === "") {
            skillNames = skill["skl.name"];
          } else {
            skillNames = skillNames + ", " + skill["skl.name"];
          }
        }
      });
    });

    return skillNames;
  }
};

export const getActionValue = (status) => {
  if (status === "Added" || status === "SAVED" || status === "SUBMITTED") {
    return "requestForInterview";
  } else if (
    status === "requestForInterview" ||
    status === null ||
    status === "sowGenerated" ||
    status === "requestedForRateChange"
  ) {
    return "reject";
  } else if (status === "interviewScheduled") {
    return "shortlist";
  } else if (
    status === "shortlisted" ||
    status === "sowChangeRequested" ||
    status === "rateUpdated"
  ) {
    return "generateSow";
  } else if (status === "accepted" || status === "acceptedSow") {
    return "generatePo";
  } else if (status === "sowGenerated") {
    return "viewSow";
  } else return null;
};

export const getCandidateStatus = (status) => {
  if (status === "requestForInterview") {
    return "Requested for Interview";
  } else if (status === "Added" || status === "SUBMITTED") {
    return "Submitted";
  } else if (status === "interviewScheduled") {
    return "Interview Scheduled";
  } else if (status === "shortlisted") {
    return "Shortlisted";
  } else if (status === "sowGenerated") {
    return "SoW Submitted";
  } else if (status === "acceptedSow" || status === "accepted") {
    return "SoW Accepted";
  } else if (status === "rejectedSow") {
    return "SoW Rejected";
  } else if (status === "rejected") {
    return "Rejected";
  } else if (status === "poGenerated") {
    return "PO Submitted";
  } else if (status === "Withdrawn") {
    return "Withdrawn";
  } else if (status === "sowChangeRequested") {
    return "Requested Change in SoW";
  } else if (status === "requestedForRateChange") {
    return "Requested For Rate Change";
  } else if (status === "rateUpdated") {
    return "Shortlisted";
  } else if (status === "SAVED") {
    return "Saved";
  } else if (status === "UPLOADED_PO") {
    return "Purchase Order Submitted";
  } else return status;
};

export const getVbeidOfSelectedCountry = (country) => {
  let countryVbeid = "";

  countries.forEach((countryObj) => {
    if (country === countryObj.countryname) {
      countryVbeid = countryObj.countryvbeid;
    }
  });

  return countryVbeid;
};

export const getDiversityNames = (diversity, listDiversity) => {
  let diversityNames = "";

  listDiversity.forEach((diversityObj) => {
    diversity.forEach((single) => {
      if (diversityObj.vbeid === single) {
        if (diversityNames === "") {
          diversityNames = diversityObj.name;
        } else {
          diversityNames = diversityNames + ", " + diversityObj.name;
        }
      }
    });
  });

  return diversityNames;
};

export const getMilestoneStatus = (status) => {
  if (status === "requestForSow") {
    return "SoW Requested";
  } else if (status === "sowUploaded") {
    return "SoW Submitted";
  } else if (status === "acceptSow") {
    return "SoW Accepted";
  } else return status;
};

export const timeValuesList = [
  { type: "12:00 AM", value: "12:00 AM" },
  { type: "12:30 AM", value: "12:30 AM" },
  { type: "01:00 AM", value: "01:00 AM" },
  { type: "01:30 AM", value: "01:30 AM" },
  { type: "02:00 AM", value: "02:00 AM" },
  { type: "02:30 AM", value: "02:30 AM" },
  { type: "03:00 AM", value: "03:00 AM" },
  { type: "03:30 AM", value: "03:30 AM" },
  { type: "04:00 AM", value: "04:00 AM" },
  { type: "04:30 AM", value: "04:30 AM" },
  { type: "05:00 AM", value: "05:00 AM" },
  { type: "05:30 AM", value: "05:30 AM" },
  { type: "06:00 AM", value: "06:00 AM" },
  { type: "06:30 AM", value: "06:30 AM" },
  { type: "07:00 AM", value: "07:00 AM" },
  { type: "07:30 AM", value: "07:30 AM" },
  { type: "08:00 AM", value: "08:00 AM" },
  { type: "08:30 AM", value: "08:30 AM" },
  { type: "09:00 AM", value: "09:00 AM" },
  { type: "09:30 AM", value: "09:30 AM" },
  { type: "10:00 AM", value: "10:00 AM" },
  { type: "10:30 AM", value: "10:30 AM" },
  { type: "11:00 AM", value: "11:00 AM" },
  { type: "11:30 AM", value: "11:30 AM" },
  { type: "12:00 PM", value: "12:00 PM" },
  { type: "12:30 PM", value: "12:30 PM" },
  { type: "01:00 PM", value: "01:00 PM" },
  { type: "01:30 PM", value: "01:30 PM" },
  { type: "02:00 PM", value: "02:00 PM" },
  { type: "02:30 PM", value: "02:30 PM" },
  { type: "03:00 PM", value: "03:00 PM" },
  { type: "03:30 PM", value: "03:30 PM" },
  { type: "04:00 PM", value: "04:00 PM" },
  { type: "04:30 PM", value: "04:30 PM" },
  { type: "05:00 PM", value: "05:00 PM" },
  { type: "05:30 PM", value: "05:30 PM" },
  { type: "06:00 PM", value: "06:00 PM" },
  { type: "06:30 PM", value: "06:30 PM" },
  { type: "07:00 PM", value: "07:00 PM" },
  { type: "07:30 PM", value: "07:30 PM" },
  { type: "08:00 PM", value: "08:00 PM" },
  { type: "08:30 PM", value: "08:30 PM" },
  { type: "09:00 PM", value: "09:00 PM" },
  { type: "09:30 PM", value: "09:30 PM" },
  { type: "10:00 PM", value: "10:00 PM" },
  { type: "10:30 PM", value: "10:30 PM" },
  { type: "11:00 PM", value: "11:00 PM" },
  { type: "11:30 PM", value: "11:30 PM" },
];
