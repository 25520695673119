import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";

import * as React from "react";

import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";

import { GET_PRODUCTS_BY_PROJECT } from "../../queries/query";

import { VBRIDGEHUB_MEDIAURL } from "../../../../../constants";

import { useStyles } from "./styles";
import VendorActions from "./VendorActions";

export default function VendorsList({ review, type }) {
  const { id } = useParams();
  const classes = useStyles();

  const { data, refetch } = useQuery(GET_PRODUCTS_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id } },
  });

  let products = [];

  if (data) {
    data.getProductsByProjectIDV1.forEach((product) => {
      if (product.id !== "concierge0001") {
        products.push(product);
      }
    });
  }

  return (
    <Grid container className={type === "review" ? classes.root : ""}>
      <Grid item xs={12}>
        <Typography variant="span" gutterBottom className={classes.message}>
          Requirements will be shared with these suppliers
        </Typography>
      </Grid>

      <Grid item xs={review ? 9 : 12}>
        <List dense={true}>
          {data
            ? products.map((row) => {
                return (
                  <ListItem key={row.id}>
                    <ListItemAvatar>
                      <Avatar
                        variant="square"
                        src={VBRIDGEHUB_MEDIAURL + "/" + row.image}
                        className={classes.imglarge}
                      >
                        :(
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={row.companyname}
                      secondary={row.productname}
                    ></ListItemText>

                    <ListItemSecondaryAction>
                      <VendorActions
                        refetch={refetch}
                        id={row.vbeid}
                        pvbeid={row.productvbeid}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })
            : null}
        </List>
      </Grid>
    </Grid>
  );
}
