import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  main: {
    width: theme.spacing(100),
  },
  formControlclass: {
    width: "100%",
    textAlign: "left",
  },
  sectionRoot: {
    marginTop: theme.spacing(3),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
  },
  heading: {
    fontSize: theme.spacing(2),
  },
  helpicon: {
    fontSize: theme.spacing(1.5),
    cursor: "pointer",
  },
  weight: {
    fontSize: theme.spacing(1.5),
  },
  caption: {
    color: "red",
  },
  viewRequirement: {
    marginRight: theme.spacing(12),
  },
}));
