import { useMutation } from "@apollo/client";

import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";

import { useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";

import InputField from "../../../common/InputField";
import PrimaryButton from "../../../common/PrimaryButton";

import {
  ADD_PO_DETAILS,
  ADD_WP_PO_DETAILS,
  ONBOARD_RESOURCE,
  ONBOARD_SUPPLIER,
  UPDATE_PO_DETAILS,
  UPDATE_RESOURCE_STATUS,
} from "../../queries/query";

import POForm from "./POForm";
import SowForm from "./SowForm";
import TimeSlotsForm from "./TimeSlotsForm";

import { useStyles } from "./styles";
import { getFormattedDate } from "../../../../../functions/helper";

export default function CandidateActionSlider({
  actionSlider,
  onClose,
  action,
  refetch,
  selectedResource,
  projectData,
  poDetails,
  remarks,
}) {
  const classes = useStyles();
  const { id } = useParams();

  let today = new Date().toISOString().split("T")[0];
  let resourceInfo = selectedResource?.remarks;
  let resourceRemarks = "";

  if (
    selectedResource &&
    selectedResource.remarks !== "" &&
    !remarks &&
    action !== "generatePo" &&
    action !== "onboardResource" &&
    action !== "updatePO" &&
    action !== "viewPO" &&
    action !== "uploadPO" &&
    action !== "onboardSupplier"
  ) {
    resourceRemarks = JSON.parse(resourceInfo);
  } else if (action === "generatePo" || action === "updatePO") {
    resourceRemarks = JSON.parse(resourceInfo);
  } else if (remarks) {
    resourceInfo = remarks;

    if (remarks !== "" && action !== "viewPO") {
      resourceRemarks = JSON.parse(resourceInfo);
    }
  }

  if (action === "uploadPO") {
    resourceInfo = JSON.parse(selectedResource.data);
  }

  const [timeSlots, setTimeSlots] = useState([
    { date: "", fromTime: "", toTime: "" },
  ]);
  const [timeSlotCount, setTimeSlotsCount] = useState(1);
  const [attachments, setAttachments] = useState(remarks.attachments || []);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [internalIdError, setInternalIdError] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);

  const [updateStatus] = useMutation(UPDATE_RESOURCE_STATUS, {
    onCompleted: () => {
      refetch();
      onClose();
    },
  });

  const [addPoDetails] = useMutation(ADD_PO_DETAILS, {
    onCompleted: () => {
      refetch();
      onClose();
    },
  });

  const [updatePoDetails] = useMutation(UPDATE_PO_DETAILS, {
    onCompleted: () => {
      refetch();
      onClose();
    },
  });

  const [onboardResource] = useMutation(ONBOARD_RESOURCE, {
    onCompleted: () => {
      refetch();
      onClose();
    },
  });

  const [onboardSupplier] = useMutation(ONBOARD_SUPPLIER, {
    onCompleted: () => {
      refetch();
      onClose();
    },
  });

  const [uploadPOForMileStone] = useMutation(ADD_WP_PO_DETAILS, {
    onCompleted: () => {
      refetch();
      onClose();
    },
  });

  const getMilestoneTotalPrice = () => {
    let milestonePriceTotal = 0;
    let milestoneResponse = resourceInfo;

    milestoneResponse.milestoneList.forEach((milestone) => {
      let price = parseInt(milestone.price);
      milestonePriceTotal += price;
    });

    return milestonePriceTotal;
  };

  const getStartDate = (workPackage) => {
    let milestoneResponse = JSON.parse(workPackage.data);

    return milestoneResponse.projectStartDate;
  };

  const formik = useFormik({
    initialValues: {
      remarks:
        action === "reject" ||
        action === "requestRateChange" ||
        action === "shortlist" ||
        action === "onboardResource" ||
        action === "onboardSupplier"
          ? ""
          : resourceInfo,
      date: "",
      fromtime: "",
      totime: "",
      revisedbillingrate: resourceRemarks
        ? resourceRemarks.revisedbillingrate === ""
          ? "-"
          : resourceRemarks.revisedbillingrate || "="
        : selectedResource.revisedbillingrate || "-",
      startDate: resourceRemarks?.startDate || null,
      endDate: resourceRemarks?.endDate || null,
      billingType: "",
      poDate:
        action === "viewPO"
          ? poDetails[0]?.podate || null
          : action === "updatePO"
          ? selectedResource.podate
          : "",
      workStartDate:
        action === "viewPO"
          ? poDetails[0]?.postartdate || null
          : action === "updatePO"
          ? selectedResource.postartdate
          : action === "generatePo"
          ? resourceRemarks.startDate
          : action === "uploadPO"
          ? resourceInfo.projectStartDate
          : "",
      workEndDate:
        action === "viewPO"
          ? poDetails[0]?.poenddate || null
          : action === "updatePO"
          ? selectedResource.poenddate
          : action === "generatePo"
          ? resourceRemarks.endDate
          : action === "uploadPO"
          ? resourceInfo.projectEndDate
          : "",
      rate:
        action === "viewPO"
          ? poDetails[0]?.porate || ""
          : action === "updatePO"
          ? selectedResource.porate
          : action === "generatePo"
          ? resourceRemarks.revisedbillingrate === "" ||
            resourceRemarks.revisedbillingrate === "N/A"
            ? resourceRemarks.billingRate
            : resourceRemarks.revisedbillingrate || "-"
          : action === "uploadPO"
          ? getMilestoneTotalPrice()
          : "",
      additionalInfo:
        action === "viewPO"
          ? poDetails[0]?.remarks || ""
          : action === "updatePO"
          ? selectedResource.remarks
          : "",
      attachment:
        action === "viewPO"
          ? poDetails[0].attachment || ""
          : action === "updatePO"
          ? selectedResource.attachment
          : "",
      onboardingDate:
        selectedResource.engagementStartDate || getStartDate(selectedResource),
      internalResourceId: "",
      poNumber:
        action === "viewPO"
          ? poDetails[0].ponumber || ""
          : action === "updatePO"
          ? selectedResource.ponumber
          : "",
    },
  });

  const onFormSubmit = () => {
    if (
      action !== "generatePo" &&
      action !== "onboardResource" &&
      action !== "updatePO" &&
      action !== "uploadPO" &&
      action !== "onboardSupplier"
    ) {
      if (action === "schedule") {
        if (
          timeSlots[timeSlots.length - 1].toTime === "" ||
          timeSlots[timeSlots.length - 1].fromTime === ""
        ) {
          timeSlots.pop();
        }
      }

      let values = {
        remarks: JSON.stringify(timeSlots),
        vbeid: id,
        timestamp: selectedResource.createdon,
        status: "requestForInterview",
        vendorid: selectedResource.vendorid,
      };

      let finalRemarks = { remarks: formik.values.remarks };

      if (
        action === "reject" ||
        action === "shortlist" ||
        action === "requestRateChange"
      ) {
        values = {
          remarks: JSON.stringify(finalRemarks),
          status:
            action === "reject"
              ? "rejected"
              : action === "requestRateChange"
              ? "requestedForRateChange"
              : "shortlisted",
          vendorid: selectedResource.vendorid,
          timestamp: selectedResource.createdon,
          vbeid: id,
        };
      }

      if (action === "generateSow") {
        const { startDate, endDate, revisedRate } = formik.values;

        let remarks = {
          startDate: startDate,
          endDate: endDate,
          billingRate: selectedResource.billingrate,
          supplierName: selectedResource.companyname,
          attachments: attachments,
          revisedRate: revisedRate,
        };

        values = {
          remarks: JSON.stringify(remarks),
          status: "sowGenerated",
          vendorid: selectedResource.vendorid,
          timestamp: selectedResource.createdon,
          vbeid: id,
          engagementStartDate: startDate,
          engagementEndDate: endDate,
        };
      }

      updateStatus({
        variables: {
          input: values,
        },
      });
    } else {
      if (
        action === "generatePo" ||
        action === "updatePO" ||
        action === "uploadPO"
      ) {
        const {
          poDate,
          workStartDate,
          workEndDate,
          rate,
          additionalInfo,
          attachment,
          poNumber,
        } = formik.values;

        let poDetails = {
          podate: poDate,
          postartdate: workStartDate,
          poenddate: workEndDate,
          porate: rate,
          remarks: additionalInfo,
          attachment: attachment,
          PK: selectedResource.PK,
          SK: selectedResource.SK,
          ponumber: poNumber,
        };

        if (action === "generatePo") {
          addPoDetails({
            variables: {
              input: poDetails,
            },
          });
        } else if (action === "uploadPO") {
          console.log("podetails", poDetails);
          uploadPOForMileStone({
            variables: {
              input: poDetails,
            },
          });
        } else {
          poDetails.ponumber = selectedResource.ponumber;

          updatePoDetails({
            variables: {
              input: poDetails,
            },
          });
        }
      } else if (action === "onboardSupplier") {
        const { onboardingDate, internalResourceId, remarks } = formik.values;

        if (internalResourceId === "") {
          setInternalIdError(true);
        } else {
          onboardSupplier({
            variables: {
              input: {
                supplierOnboardingDate: onboardingDate,
                internalSupplierID: internalResourceId,
                remarks: remarks,
                // vbeid: selectedResource.vbeid,
                // timestamp: selectedResource.createdon,
                // vendorid: selectedResource.vendorid,
                PK: selectedResource.PK,
                SK: selectedResource.SK,
              },
            },
          });
        }
      } else {
        const { onboardingDate, internalResourceId, remarks } = formik.values;

        if (internalResourceId === "") {
          setInternalIdError(true);
        } else {
          onboardResource({
            variables: {
              input: {
                resourceOnboardingDate: onboardingDate,
                internalResourceID: internalResourceId,
                remarks: remarks,
                vbeid: selectedResource.vbeid,
                timestamp: selectedResource.createdon,
                vendorid: selectedResource.vendorid,
              },
            },
          });
        }
      }
    }
  };

  const submitFileUpload = (paramName, filepath, url, fileName) => {
    let files = attachments;

    files.push({ name: fileName, url: url });

    setAttachments(files);
    setUploadComplete(!uploadComplete);
  };

  const onTimeSlotValueChange = (value, field, index) => {
    let prevValues = timeSlots;
    let currentTimeSlot = timeSlots[index];

    currentTimeSlot[field] = value;
    prevValues[index] = currentTimeSlot;

    if (prevValues[index].date === "") {
      prevValues[index].date = today;
    }

    if (prevValues[index].fromTime === "" || prevValues[index].toTime === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    setTimeSlots(prevValues);
  };

  const returnTimeSlots = () => {
    let slots = [];

    for (var i = 0; i < timeSlotCount; i++) {
      slots.push(
        <TimeSlotsForm
          values={timeSlots[i]}
          onAddTimeSlot={onAddTimeSlot}
          onChange={onTimeSlotValueChange}
          index={i}
        />
      );
    }

    return slots;
  };

  const onAddTimeSlot = () => {
    let prevValues = timeSlots;
    let newCount = timeSlotCount + 1;

    prevValues[timeSlotCount] = {
      date: "",
      fromTime: "",
      toTime: "",
    };

    setTimeSlots(prevValues);
    setTimeSlotsCount(newCount);
  };

  const returnTimings = (timeSlot) => {
    return (
      <div>
        {timeSlot.date} <span style={{ fontStyle: "italic" }}>from </span>{" "}
        {timeSlot.fromTime} <span style={{ fontStyle: "italic" }}> to</span>{" "}
        {timeSlot.toTime}
      </div>
    );
  };

  const checkPoDetails = () => {
    const { poNumber, poDate, workStartDate, workEndDate, rate, attachment } =
      formik.values;

    if (
      poNumber !== "" &&
      poDate !== "" &&
      workStartDate !== "" &&
      workEndDate !== "" &&
      rate !== "" &&
      attachment !== ""
    ) {
      return false;
    } else return true;
  };

  const fileUploaded = () => {
    setFileUpload(!fileUpload);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={actionSlider}
      className={classes.actionDrawer}
      onClose={onClose}
    >
      <Card square className={classes.actionCard} elevation={0}>
        <CardHeader
          className={classes.cardTitle}
          title={
            action === "schedule"
              ? "Request for Interview"
              : action === "shortlist"
              ? "Shortlist Resource"
              : action === "generateSow" ||
                action === "viewSow" ||
                action === "viewPO"
              ? ""
              : action === "viewSchedule"
              ? "Interview Schedule Details"
              : action === "viewInterviewDetails"
              ? "Interview Details"
              : action === "viewRemarks"
              ? "Remarks"
              : action === "generatePo" || action === "uploadPO"
              ? "Generate PO"
              : action === "reject"
              ? "Reject Resource"
              : action === "requestRateChange"
              ? "Request Rate Change"
              : action === "onboardResource"
              ? "Onboard Resource"
              : action === "updatePO"
              ? "Update PO"
              : ""
          }
        />

        <CardContent style={{ padding: "20px 16px 8px 16px" }}>
          <Typography style={{ fontSize: "0.85rem" }}>
            {action === "schedule"
              ? "Enter available time slots"
              : action === "reject"
              ? ""
              : ""}
          </Typography>

          <form>
            <Grid container spacing={2}>
              {action === "schedule" && (
                <>
                  {returnTimeSlots()}

                  {timeSlotCount < 5 && (
                    <Grid item md={2} style={{ margin: "8px 0" }}>
                      <PrimaryButton
                        label="Add"
                        onClick={onAddTimeSlot}
                        size="small"
                      />
                    </Grid>
                  )}
                </>
              )}

              {action === "viewInterviewDetails" && (
                <>
                  <Grid item md={12}>
                    {resourceRemarks.map((info) => returnTimings(info))}
                  </Grid>
                </>
              )}

              {action === "viewSchedule" && (
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <Typography
                      style={{ fontSize: "0.85rem", marginBottom: "4px" }}
                    >
                      Meeting Link
                    </Typography>

                    <Typography
                      style={{ fontSize: "0.8rem", marginBottom: "16px" }}
                    >
                      {resourceRemarks.meetinglink}
                    </Typography>

                    <Typography
                      style={{ fontSize: "0.85rem", marginBottom: "4px" }}
                    >
                      Interview Date and Time
                    </Typography>

                    <Typography
                      style={{ fontSize: "0.8rem", marginBottom: "8px" }}
                    >
                      <div>
                        {resourceRemarks.date}{" "}
                        <span style={{ fontStyle: "italic" }}> from </span>{" "}
                        {resourceRemarks.fromTime}{" "}
                        <span style={{ fontStyle: "italic" }}> to</span>{" "}
                        {resourceRemarks.toTime}
                      </div>
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {(action === "reject" ||
                action === "shortlist" ||
                action === "requestRateChange") && (
                <FormControl fullWidth={true} style={{ marginBottom: "8px" }}>
                  <InputField
                    label="Remarks"
                    onChange={formik.handleChange("remarks")}
                    value={formik.values.remarks}
                    multiline={true}
                    rows={3}
                  />
                </FormControl>
              )}

              {action === "viewRemarks" && (
                <Grid item md={12}>
                  {remarks && resourceRemarks.remarks !== "" ? (
                    <div
                      style={{
                        border: "1px solid #999",
                        borderRadius: "5px",
                        padding: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <Typography>
                        {remarks ? resourceRemarks.remarks : ""}
                      </Typography>
                    </div>
                  ) : (
                    <Typography
                      style={{
                        fontSize: "0.8rem",
                        fontStyle: "italic",
                        color: "#999",
                      }}
                    >
                      No remarks
                    </Typography>
                  )}
                </Grid>
              )}

              {(action === "generateSow" || action === "viewSow") && (
                <SowForm
                  formik={formik}
                  selectedResource={selectedResource}
                  projectData={projectData}
                  submitFileUpload={submitFileUpload}
                  attachments={attachments}
                  view={action}
                />
              )}

              {(action === "generatePo" ||
                action === "viewPO" ||
                action === "updatePO" ||
                action === "uploadPO") && (
                <POForm
                  formik={formik}
                  fileUploaded={fileUploaded}
                  selectedResource={selectedResource}
                  projectData={projectData}
                />
              )}

              {(action === "onboardResource" ||
                action === "onboardSupplier") && (
                <>
                  <Grid item md={12}>
                    <FormControl
                      fullWidth={true}
                      style={{ marginBottom: "8px" }}
                    >
                      <InputField
                        label={
                          action === "onboardSupplier"
                            ? "Supplier Onboarding Date"
                            : "Resource Onboarding Date"
                        }
                        onChange={formik.handleChange("onboardingDate")}
                        value={formik.values.onboardingDate}
                        type="date"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={12}>
                    <FormControl
                      fullWidth={true}
                      style={{ marginBottom: "8px" }}
                    >
                      <InputField
                        label={
                          action === "onboardSupplier"
                            ? "Internal Project ID"
                            : "Internal Resource ID"
                        }
                        onChange={formik.handleChange("internalResourceId")}
                        value={formik.values.internalResourceId}
                      />
                    </FormControl>

                    {internalIdError &&
                      formik.values.internalResourceId === "" && (
                        <FormHelperText className={classes.errorMessage}>
                          Please enter ID
                        </FormHelperText>
                      )}
                  </Grid>

                  <Grid item md={12}>
                    <FormControl
                      fullWidth={true}
                      style={{ marginBottom: "8px" }}
                    >
                      <InputField
                        label="Remarks"
                        onChange={formik.handleChange("remarks")}
                        value={formik.values.remarks}
                        multiline={true}
                        rows={3}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              {action !== "viewRemarks" &&
                action !== "viewSchedule" &&
                action !== "viewSow" && (
                  <Grid md={12} className={classes.submitBtn}>
                    <PrimaryButton
                      label={
                        action === "schedule"
                          ? "Send Interview Request"
                          : "Submit"
                      }
                      type="submit"
                      onClick={onFormSubmit}
                      disabled={
                        action === "generateSow"
                          ? formik.values.startDate && formik.values.endDate
                            ? false
                            : true
                          : action === "schedule"
                          ? disabled
                          : action === "reject" ||
                            action === "shortlist" ||
                            action === "requestRateChange"
                          ? formik.values.remarks === ""
                            ? true
                            : false
                          : action === "onboardResource"
                          ? formik.values.onboardingDate !== "" &&
                            formik.values.internalResourceId !== ""
                            ? false
                            : true
                          : action === "generatePo" || action === "uploadPO"
                          ? checkPoDetails()
                          : false
                      }
                    />
                  </Grid>
                )}
            </Grid>
          </form>
        </CardContent>
      </Card>
    </SwipeableDrawer>
  );
}
