import React from "react";

import { useStyles } from "./styles";

import { Grid } from "@material-ui/core";

import { useFormik } from "formik";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import VbridgeAlert from "../../shared/alert";
import { getCompanies, getBatches, mergeCompanies } from "./query";
import CompanyFilters from "./companyFilters";
import CompanyValidation from "./companyValidation";
import CustomisedButton from "./shared";

export default function CompanyGrid() {
  const classes = useStyles();

  const [companyBatch, setCompanyBatch] = React.useState([]);
  const [selectionVbeid, setSelectedVbeid] = React.useState([]);

  //filter params and updateForm values
  const formik = useFormik({
    initialValues: {
      pagefrom: "",
      pageto: "",
      batchvbeid: "",
      orderby: "",
    },
  });

  const [pageOffset, setPageOffset] = React.useState("");

  //getBatches
  const { data: listBatches } = useQuery(getBatches);

  //query to get the companies list
  const [getCompanyResults, { loading, refetch }] = useLazyQuery(getCompanies, {
    onCompleted: (e) => {
      setCompanyBatch(e.getCompaniesByBatch);
    },
  });

  //function to query the company batch results
  function QueryBatch(e) {
    getCompanyResults({
      variables: {
        input: {
          offset: formik.values.pagefrom,
          limit: formik.values.pageto,
          batchvbeid: formik.values.batchvbeid,
          orderby: formik.values.orderby,
        },
      },
    });

    setPageOffset(formik.values.pageto);
  }

  //query to get the companies list
  const [getCompanyPageChangeResults] = useLazyQuery(getCompanies, {
    onCompleted: (e) => {
      setCompanyBatch([...companyBatch, ...e.getCompaniesByBatch]);
    },
  });

  //onpage change in datagrid api call
  function QueryBatchOnPageChange(e) {
    getCompanyPageChangeResults({
      variables: {
        input: {
          offset: pageOffset,
          limit: formik.values.pageto,
          batchvbeid: formik.values.batchvbeid,
          orderby: formik.values.orderby,
        },
      },
    });

    setPageOffset(pageOffset + formik.values.pageto);
  }

  //query to get the companies list
  const [mergeCompanyid, { data: merged, error: mergedError }] = useMutation(
    mergeCompanies,
    {
      onCompleted: (e) => {
        AfterMerge(e);
      },
    }
  );

  //function to mergeCompanies
  function MergeCompanies() {
    mergeCompanyid({
      variables: {
        input: {
          vbeid: selectionVbeid,
        },
      },
    });
  }

  //function after merging companies
  function AfterMerge(e) {
    let listId = e.mergeCompany.vbeids.slice(1);
    setCompanyBatch(companyBatch.filter((x) => !listId.includes(x.id)));
  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          {mergedError ? (
            <VbridgeAlert
              severity="error"
              open={true}
              message="Something went wrong."
            />
          ) : null}
          {merged ? (
            <VbridgeAlert
              severity="success"
              open={true}
              message="Companies Merged successfully"
            />
          ) : null}
        </Grid>
      </Grid>

      <Grid container md={12} className={classes.dataGridAlign}>
        <Grid item md={10}>
          {listBatches ? (
            <CompanyFilters
              batches={
                listBatches.getBatchesList.length >= 1 ? listBatches : ""
              }
              formik={formik}
              method={QueryBatch}
            />
          ) : null}
        </Grid>

        <Grid item md={2} className={classes.mergeButton}>
          <CustomisedButton
            buttonText="Merge"
            method={MergeCompanies}
            disable={selectionVbeid.length >= 2 ? false : true}
          />
        </Grid>

        <Grid item md={12}>
          <CompanyValidation
            companyBatch={companyBatch}
            loading={loading}
            getVbeid={setSelectedVbeid}
            updateBatch={setCompanyBatch}
            pageChanging={QueryBatchOnPageChange}
            fetching={refetch}
            batchvbeid={formik.values.batchvbeid}
          />
        </Grid>
      </Grid>
    </>
  );
}
