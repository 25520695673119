import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import { useParams } from "react-router-dom";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

export default function AddItemMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let { type, id } = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        startIcon={<AddIcon />}
        onClick={handleClick}
        size="small"
        variant="outlined"
        color="secondary"
      >
        Add Item
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {type !== "evaluate_template" ? (
          <>
            {" "}
            <StyledMenuItem
              dense
              onClick={() => {
                props.addItem("text_field");
              }}
            >
              <ListItemIcon>
                <TextFieldsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Text Field" />
            </StyledMenuItem>
            <StyledMenuItem
              dense
              onClick={() => {
                props.addItem("text_area");
              }}
            >
              <ListItemIcon>
                <ViewHeadlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Text Area" />
            </StyledMenuItem>
          </>
        ) : null}

        <StyledMenuItem
          dense
          onClick={() => {
            props.addItem("multi_choice");
          }}
        >
          <ListItemIcon>
            <FormatListBulletedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Multiple Choice" />
        </StyledMenuItem>
        {type !== "evaluate_template" ? (
          <>
            <StyledMenuItem
              dense
              onClick={() => {
                props.addItem("currency");
              }}
            >
              <ListItemIcon>
                <AttachMoneyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Currency" />
            </StyledMenuItem>
            <StyledMenuItem
              dense
              onClick={() => {
                props.addItem("number");
              }}
            >
              <ListItemIcon>
                <FormatListNumberedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Number" />
            </StyledMenuItem>

            <StyledMenuItem
              dense
              onClick={() => {
                props.addItem("date");
              }}
            >
              <ListItemIcon>
                <CalendarTodayIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Date" />
            </StyledMenuItem>
            <StyledMenuItem
              dense
              onClick={() => {
                props.addItem("attachment");
              }}
            >
              <ListItemIcon>
                <AttachFileIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Attachment" />
            </StyledMenuItem>
          </>
        ) : null}
      </StyledMenu>
    </div>
  );
}
