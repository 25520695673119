import { useHistory, useParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";

import { CircularProgress } from "@material-ui/core";

import { GET_PROJECT, UPDATE_PROJECT } from "../../../queries/query";

import { useStyles } from "../styles";

import JobDescriptionForm from "./JobDescriptionForm";
import AddDescForm from "../AddDescForm";

export default function JobDescription({ type, projectType }) {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const { data, refetch } = useQuery(GET_PROJECT, {
    variables: { input: { vbeid: id } },
    fetchPolicy: "no-cache",
  });

  const [updateProject] = useMutation(UPDATE_PROJECT, {
    onCompleted: (e) => {
      history.push("/recommended-providers/" + e.updateProjectV1.vbeid);
    },
  });

  return (
    <div className={type === "review" ? "" : classes.jdRoot}>
      {data ? (
        projectType === "workpackage" ? (
          <AddDescForm
            projectData={data.getProjectByIDV1}
            updateProject={updateProject}
            projectType={projectType}
            type={type}
            refetch={refetch}
          />
        ) : (
          <JobDescriptionForm
            projectData={data.getProjectByIDV1}
            updateProject={updateProject}
            type={type}
          />
        )
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
