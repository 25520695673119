import { gql } from "@apollo/client";

export const mappingRelation = gql`
  mutation relationmap($input: id!) {
    relationshipmapping(input: $input) {
      id
      listdetails {
        name
        vbeid
      }
    }
  }
`;

export const addOrgTypeToCompany = gql`
  mutation addOrgTypeToCompany($input: companyinput!) {
    addCompanytoOrgtype(input: $input) {
      id
      vbeid
    }
  }
`;

export const searchQuery = gql`
  query esQuery($input: esinput) {
    esQuery(input: $input) {
      total
      result {
        name
        image
        index
        vbeid
      }
    }
  }
`;

export const mapTopicToProduct = gql`
  mutation mapTopicToProduct($input: id!) {
    mapProductstoTopic(input: $input) {
      listvbeid
    }
  }
`;

export const mapSkillsToProduct = gql`
  mutation mapSkillsToProduct($input: id!) {
    mapProductToSkills(input: $input) {
      listvbeid
    }
  }
`;

export const mapNewSkillToProduct = gql`
  mutation mapNewSkillToProduct($input: id!) {
    mapNewSkillToProduct(input: $input) {
      listvbeid
    }
  }
`;
