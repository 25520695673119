import React, { useState } from "react";

import { Paper, Typography, Box, Grid } from "@material-ui/core";

import MainGroup from "./MainGroup";
import MapTopic from "./maptopic";
import CategoriesList from "./CategoriesList";

import { useQuery } from "@apollo/client";

import Policy from "../../shared/policy";

import { userInfo } from "./query";
import { useStyles } from "./styles";

export default function Groups({ toggleValue, drawerOpen }) {
  const classes = useStyles();

  const [isReadOnly, setReadonly] = useState(true);
  const [groupName, setGroupName] = useState(null);

  const user = useQuery(userInfo, {
    variables: { input: {} },
    onCompleted: (e) => {
      afterLoadingMainData(e);
    },
  });

  function afterLoadingMainData(e) {
    // setReadonly(e.getLoginUserInfo.isVbridgeUser !== "true");
  }

  return (
    <div
      className={drawerOpen ? classes.drawerOpenGroupRoot : classes.groupRoot}
    >
      <Box m={5}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <MainGroup
              setName={setGroupName}
              isReadOnly={isReadOnly}
              toggleValue={toggleValue}
            />
          </Grid>

          <Grid item xs={3}>
            <Grid item md={12} className={classes.aligntopicslist}>
              <Paper elevation={1} square={true} className={classes.paperRoot}>
                <CategoriesList name={groupName} isReadOnly={isReadOnly} />
              </Paper>
            </Grid>

            {isReadOnly ? null : (
              <Grid item md={12} spacing={1} className={classes.addTopic}>
                <Paper elevation={1} square={true}>
                  <Typography
                    gutterBottom
                    variant="button"
                    className={classes.subHeading}
                    component="caption"
                    display="block"
                  >
                    Map Category
                  </Typography>

                  <MapTopic />
                </Paper>
              </Grid>
            )}
            <Policy />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
