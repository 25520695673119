import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  productName: {
    alignItems: "center",
    fontSize: "0.85rem",
    fontWeight: "600",
    color: "#4179BD",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "80%",

    "&:hover, &:focus": {
      color: "#232961",
    },

    "&:active": {
      color: "#232961",
    },
  },
  resultCard: {
    padding: theme.spacing(1),
    borderRadius: "5px",
    cursor: "pointer",
    "& svg": {
      fill: "#4179BD",
    },
  },
  selectedResultCard: {
    padding: theme.spacing(1),
    borderRadius: "5px",
    cursor: "pointer",
    "& svg": {
      fill: "#4179BD",
    },
    border: "2px solid #4179BD",
  },
  resultDiv: {
    display: "flex",
    textAlign: "left",
  },
  productDetails: {
    marginLeft: theme.spacing(1),
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  productDesc: {
    textAlign: "left",
    margin: theme.spacing(1),
    fontSize: "0.8rem",
    height: "6.5rem",
    overflow: "hidden",
  },
  location: {
    textAlign: "left",
  },
  locationMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(1, 0, 1, 1),
    fontSize: "0.75rem",
  },
  companyMatch: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(-1),
  },
  productImage: {
    "& img": {
      objectFit: "contain",
    },
  },
  productDetailDiv: {
    "& .MuiDrawer-paper": {
      width: "60%",
      padding: theme.spacing(1),
    },

    "& .MuiAvatar-square": {
      width: "70px",
      height: "70px",
      "& img": {
        objectFit: "contain",
      },
    },

    "& .makeStyles-companyDetailsDiv-736": {
      margin: 0,
    },
  },
  projectDetailSlider: {
    "& .MuiDrawer-paper": {
      width: "35%",
      padding: theme.spacing(1),
    },

    "& .MuiAvatar-square": {
      width: "60px",
      height: "60px",
      "& img": {
        objectFit: "contain",
      },
    },

    "& .makeStyles-companyDetailsDiv-736": {
      margin: 0,
    },
  },
  productDescription: {
    overflow: "auto",
    height: "70vh",
  },
  productDescLoading: {
    textAlign: "center",
  },
  sliderDescription: {
    overflow: "auto",
    height: "70vh",
    padding: theme.spacing(0, 2, 2, 2),
  },
  rowHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    margin: theme.spacing(0, 3),
  },
  productDetailCard: {
    "& .MuiGrid-item": {
      margin: 0,
    },
  },
  longDescText: {
    color: "#bebebe",
    fontSize: "0.8rem",
    fontStyle: "italic",
  },
  providerList: {
    //marginLeft: "-50px",
    padding: theme.spacing(0, 2),
  },
  reviewList: {
    marginTop: "8px",
  },
  filterDiv: {
    margin: theme.spacing(2, 0),
  },
  emptyListText: {
    fontSize: "0.8rem",
    fontStyle: "italic",
    color: "#999",
    margin: "8px 0 16px 8px",
  },
  selectedButton: {
    margin: theme.spacing(2, 0),
    textAlign: "right",
  },
}));
