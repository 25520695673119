import React from "react";

import { makeStyles, Avatar } from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

import File from "../../shared/uploadfile";

const useStyles = makeStyles((theme) => ({
  square: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    "& img": {
      objectFit: "contain",
    },
  },
  root: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function GroupLogo({ image, isReadOnly, vbeid, updateCache }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar variant="square" src={image} className={classes.square}>
        <CancelPresentationIcon />
      </Avatar>

      {isReadOnly ? null : (
        <File
          vbeid={vbeid}
          entity="groupimage"
          filetype="logo"
          fext=".png"
          paramName="image"
          inputid={vbeid + "grouplogo"}
          updateCacheMethod={updateCache}
          buttonmessage="upload logo"
          type="icon"
          icon={<PhotoCamera fontSize="small" />}
        />
      )}
    </div>
  );
}
