import * as React from 'react';
//import { Link } from 'react-router-dom';

import { Dialog, DialogActions, 
    DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

import CustomisedButton from './shared';
//import { useStyles } from './styles';


export default function DialogEdit({dialogMainContent,closePop, openPop, dialogTitle,
     dialogContentText, buttonText1, buttonText2, buttonMainText, updateMethod }) {
      //const classes = useStyles();
      //const [open, setOpen] = React.useState(openPop);

  //     console.log('dialog', open)
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <div>
      {/* <CustomisedButton method={handleClickOpen} buttonText={buttonMainText}/> */}
        {/* <div className={classes.actionEditLinkAlign}>
        <Link onClick={handleClickOpen} className={classes.linkStyle}>
        {buttonMainText}
        </Link>
        </div> */}

      <Dialog open={openPop} onClose={closePop}>

        <DialogTitle>{dialogTitle}</DialogTitle>

        <DialogContent>

          <DialogContentText>
            {dialogContentText}
          </DialogContentText>

         {dialogMainContent}
     
        </DialogContent>

        <DialogActions>
          <CustomisedButton method={closePop} buttonText={buttonText1}/>
          <CustomisedButton method={()=>{updateMethod(); closePop();}} buttonText={buttonText2}/>
        </DialogActions>

      </Dialog>
    </div>
  );
}
