import { gql } from "@apollo/client";

export const getServicesByTopicId = gql`
  query servicesByTopicId($input: serviceinput) {
    listServicesbyTopicId(input: $input) {
      name
      id
    }
  }
`;

export const getServicesByGroupId = gql`
  query servicesByGroupId($input: serviceinput) {
    listServicesbyGroupId(input: $input) {
      name
      id
      companyname
      companyvbeid
      shortdescription
      countryobj {
        name
        vbeid
      }
      topicobj {
        name
        vbeid
      }
    }
  }
`;
