import * as React from "react";
//import { Link } from 'react-router-dom';

import { useStyles } from "./styles";

import { SwipeableDrawer, Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function CustomisedDrawer({
  buttonMainText,
  openDrawer,
  closeDrawer,
  sliderComponent,
  sliderText,
}) {
  const classes = useStyles();

  //const [open, setOpen] = React.useState(openDrawer);

  // function openDiscussions(e) {
  //     setOpen(!open);
  //   }

  //   function closeDiscussion(e) {
  //     setOpen(!open);
  //   }

  return (
    <>
      {/* <div className={classes.actionLongDescriptionLinkAlign}>
        <Link onClick={openDiscussions} className={classes.linkStyle}>
        {buttonMainText}
        </Link>
        </div> */}

      <SwipeableDrawer anchor="right" open={openDrawer}>
        <div className={classes.swipableDrawer}>
          {/* <div>
        <Typography variant='overline text' color='primary'>
          {sliderText}
        </Typography>
        </div>

        <Divider/> */}

          {/* <ClickAwayListener onClickAway={closeDiscussion}> */}
          <Grid container md={12}>
            <Grid item md={12}>
              <ArrowBackIcon
                fontSize="small"
                className={classes.iconPointer}
                onClick={closeDrawer}
              />
            </Grid>

            <Grid item md={12}>
              {sliderComponent}
            </Grid>
          </Grid>

          {/* </ClickAwayListener> */}
        </div>
      </SwipeableDrawer>
    </>
  );
}
