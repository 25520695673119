import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress,
  Box,
} from "@material-ui/core";
import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import {
  CREATE_PROJECT,
  GET_PROJECT,
  UPDATE_PROJECT,
} from "../../queries/query";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: theme.spacing(10),
  },
  textFieldLabel: {
    fontSize: theme.spacing(1.8),
    marginTop: theme.spacing(2),
  },
  typetext: {
    textAlign: "center",
    fontSize: theme.spacing(1.8),
  },
  formControlclass: {
    width: "110%",
    textAlign: "left",
  },
  typecard: {
    margin: theme.spacing(1),
  },
  projectype: {
    margin: theme.spacing(0.5),
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    marginTop: theme.spacing(1),
    left: "80%",
  },
  wrapper: {
    position: "relative",
  },
  loading: {
    margin: "35%",
  },
  engarement_type: {
    "& .MuiFormControlLabel-label": {
      fontSize: theme.spacing(1),
    },
  },
  engarement_type_label: {
    fontSize: theme.spacing(1.4),
  },
}));

const validateProjectCreation = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.type) {
    errors.type = "Required";
  }
  if (!values.decisiontimeline) {
    errors.decisiontimeline = "Required";
  }

  if (values.type === "service" && values.engagement_type === "-") {
    errors.engagement_type = "Required";
  }
  return errors;
};

function ProjectType(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid Item xs={6}>
        <Card variant="outlined" className={classes.typecard}>
          <CardActionArea
            onClick={
              props.data
                ? null
                : () => {
                    props.formik.setFieldValue("type", "product");
                  }
            }
          >
            <Grid container alignItems="center">
              <Grid Item xs={2}>
                <FormControlLabel
                  value="product"
                  control={<Radio size="small" />}
                  label=""
                  className={classes.projectype}
                />
              </Grid>
              <Grid Item xs={10}>
                <Grid container>
                  <Grid
                    Item
                    xs={12}
                    dispaly="flex"
                    align-items="center"
                    className={classes.typetext}
                  >
                    <SettingsIcon fontSize="small" />
                  </Grid>
                  <Grid
                    Item
                    xs={12}
                    dispaly="flex"
                    align-items="center"
                    className={classes.typetext}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.typetext}
                    >
                      {" "}
                      Product{" "}
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                      For sourcing Technology Products
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid Item xs={6}>
        <Card variant="outlined" className={classes.typecard}>
          <CardActionArea
            onClick={
              props.data
                ? null
                : () => {
                    props.formik.setFieldValue("type", "service");
                  }
            }
          >
            <Grid container alignItems="center">
              <Grid Item xs={2}>
                <FormControlLabel
                  value="service"
                  control={<Radio size="small" />}
                  label=""
                  className={classes.projectype}
                />
              </Grid>
              <Grid Item xs={10}>
                <Grid container>
                  <Grid
                    Item
                    xs={12}
                    dispaly="flex"
                    align-items="center"
                    className={classes.typetext}
                  >
                    <PeopleIcon fontSize="small" />
                  </Grid>
                  <Grid
                    Item
                    xs={12}
                    dispaly="flex"
                    align-items="center"
                    className={classes.typetext}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.typetext}
                    >
                      {" "}
                      Service{" "}
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                      For sourcing Technology Services
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
}

function Form(props) {
  const classes = useStyles();

  const userRole = props.data.role[0];

  const formik = useFormik({
    initialValues: props.data
      ? {
          vbeid: props.data.vbeid,
          name: props.data.name,
          decisiontimeline: props.data.decisiontimeline,
          type: props.data.type,
          version: props.data.version,
          location: props.data.location,
          shortdescription: props.data.shortdescription,
          engagement_type: props.data.engagement_type,
        }
      : {
          name: "",
          decisiontimeline: "",
          type: "",
          version: "v1",
          location: "",
          shortdescription: "",
          engagement_type: "-",
        },
    validate: validateProjectCreation,
    onSubmit: (values) => {
      if (props.data) {
        updateProject({ variables: { input: values } });
      } else {
        // console.log(values);
        createProject({ variables: { input: values } });
      }
    },
  });

  const [createProject, { data, loading, error }] = useMutation(
    CREATE_PROJECT,
    {
      onCompleted: (e) => {
        console.log(e);
      },
    }
  );

  const [updateProject, { data: udata, loading: uloading, error: uerror }] =
    useMutation(UPDATE_PROJECT, {
      onCompleted: (e) => {},
    });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl component="fieldset" className={classes.formControlclass}>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Title of Project *
        </Typography>

        <Typography
          variant="caption"
          style={{ color: "red", fontWeight: "bold", margin: "5px" }}
        >
          Never include company names or any sensitive information in the
          project title
        </Typography>

        <TextField
          id="filled-full-width"
          name="name"
          placeholder="Project Name"
          fullWidth
          variant="outlined"
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          onBlur={formik.handleSubmit}
          disabled={userRole === "ADDED_TO"}
        />

        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Project Type *
        </Typography>

        <RadioGroup
          aria-label="type"
          name="type"
          disabled={props.data || userRole === "ADDED_TO" ? true : false}
          onChange={props.data ? () => {} : formik.handleChange}
          value={formik.values.type}
          error={formik.touched.type && Boolean(formik.errors.type)}
        >
          <ProjectType formik={formik} data={props.data} />
        </RadioGroup>

        <Grid container>
          <Grid Item xs={6}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.textFieldLabel}
            >
              Indicative decision timeline *
            </Typography>

            <RadioGroup
              aria-label="time"
              name="decisiontimeline"
              onChange={formik.handleChange}
              value={formik.values.decisiontimeline}
              error={
                formik.touched.decisiontimeline &&
                Boolean(formik.errors.decisiontimeline)
              }
              onBlur={formik.handleSubmit}
            >
              <FormControlLabel
                value="Immediately"
                control={<Radio size="small" />}
                label="Immediately"
              />

              <FormControlLabel
                value="Within the next 3 months"
                control={<Radio size="small" />}
                label="Within the next 3 months"
              />

              <FormControlLabel
                value="Within the next 6 months"
                control={<Radio size="small" />}
                label="Within the next 6 months"
              />
            </RadioGroup>
          </Grid>

          <Grid Item xs={6}>
            {formik.values.type === "service" ? (
              <Box m={1}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.textFieldLabel}
                >
                  Engagement Type *
                </Typography>

                <RadioGroup
                  aria-label="engagement_type"
                  name="engagement_type"
                  value={formik.values.engagement_type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleSubmit}
                >
                  <FormControlLabel
                    value="fixed_price"
                    control={<Radio size="small" />}
                    label="Fixed Price"
                  />

                  <FormControlLabel
                    value="time_material"
                    control={<Radio size="small" />}
                    label="Time & Material"
                  />

                  <FormControlLabel
                    value="both"
                    control={<Radio size="small" />}
                    label="Both"
                  />
                </RadioGroup>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </FormControl>
    </form>
  );
}

export default function ProjectFormReview() {
  const classes = useStyles();

  const { id } = useParams();

  const { loading, data } = useQuery(GET_PROJECT, {
    variables: {
      input: { vbeid: id },
      skip: id === "new",
      fetchPolicy: "network-only",
    },
  });

  return (
    <Grid container alignItems="center" className={classes.root}>
      {loading ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Form data={data.getProjectByIDV1} />
      )}
    </Grid>
  );
}
