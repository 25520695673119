import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useQuery, useMutation, useApolloClient } from "@apollo/client";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";

import SimpleSnackbar from "./topicsnackbar";
import SimpleErrorSnackbar from "./errorsnackbar";

import "../groups2.0/groups.css";
import { useStyles } from "./styles";
import { listTopicGroups, mapGroupToTopic, searchTopic } from "./query";

const groups = require("../../../data/groups.json");

export default function MapGroup() {
  const [values, setValues] = useState("");
  const classes = useStyles();

  let { id } = useParams();
  const client = useApolloClient();

  const [
    mapGroupToTopicMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(mapGroupToTopic, {
    onCompleted: (e) => {
      UpdateTopicGroupCache(e);
    },
  });

  function createGroupToTopic() {
    mapGroupToTopicMutation({
      variables: {
        input: {
          topicid: id,
          groupid: values,
        },
      },
    });
  }

   useQuery(searchTopic);

   useQuery(listTopicGroups, {
    variables: { input: { topicid: id } },
  });

  function UpdateTopicGroupCache(e) {
    const { listGroupByTopicId } = client.readQuery({
      query: listTopicGroups,
      variables: { input: { topicid: id } },
    });
    let listGroupTopicsCache = { ...listGroupByTopicId };
    let groups = [...listGroupTopicsCache.groups];
    groups.unshift({ ...e.topictogroup });

    client.writeQuery({
      query: listTopicGroups,
      data: {
        listGroupByTopicId: {
          __typename: "groupsresult",
          listGroupByTopicId: { groups: groups },
        },
      },
      variables: { input: { topicid: id } },
    });
  }

  return (
    <>
      {mutationError && <SimpleErrorSnackbar />}
      {mutationLoading && <SimpleSnackbar />}

      <Box p={1}>
        <Grid container>
          <Grid item md={12}>
            <Autocomplete
              options={groups}
              getOptionLabel={(option) => option["n.name"]}
              clearOnBlur="true"
              onChange={(event, newvalue) => {
                setValues(newvalue ? newvalue["n.vbeid"] : null);
              }}
              renderOption={(option) => (
                <div className={classes.optionLabel}>{option["n.name"]} </div>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Group"
                  variant="outlined"
                  margin="dense"
                  id="input"
                />
              )}
            />
          </Grid>

          <Grid item md={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={values ? false : true}
              size="small"
              onClick={createGroupToTopic}
            >
              Map
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
