import React from "react";

import { useStyles } from "./styles";
import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import Table from "@material-ui/core/Table";
import DescriptionIcon from "@material-ui/icons/Description";
import Drawer from "@material-ui/core/Drawer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { useTheme } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getInvoiceResourcesByStatus } from "./query";
import { useQuery } from "@apollo/client";
import { getDateFromEpoch } from "../../../../functions/helper";

export default function InvoiceBuyerScreen8({ drawerOpen }) {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentIndex, setCurrentIndex] = useState("");

  const { data, loading } = useQuery(getInvoiceResourcesByStatus, {
    variables: { input: { status: "INVOICE_ACCEPTED" } },
    fetchPolicy: "no-cache",
  });

  const resourceDatalength = data?.listInvoiceByStatus?.length;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, resourceDatalength - page * rowsPerPage);

  return (
    <div className={drawerOpen ? classes.drawerOpenRoot : classes.root}>
      <p className={classes.heading}>Invoices</p>

      <Paper className={classes.paper}>
        <TableContainer className="tableContainer">
          {loading ? (
            <CircularProgress className={classes.loadStyle} size={24} />
          ) : (
            <Table
              style={{ width: "100%" }}
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Project Name</TableCell>
                  <TableCell align="center">Project Owner</TableCell>
                  <TableCell align="center">Seller Name</TableCell>
                  <TableCell align="center">Resource Name</TableCell>
                  <TableCell align="center">Billing Mode</TableCell>
                  <TableCell align="center">Currency</TableCell>
                  <TableCell align="center">Invoice Number</TableCell>
                  <TableCell align="center">Invoice Date</TableCell>
                  <TableCell align="center">Invoice Amount</TableCell>
                  <TableCell align="center">Invoice Copy</TableCell>
                  {/* <TableCell align="center">Actions</TableCell> */}
                </TableRow>
              </TableHead>

              {data?.listInvoiceByStatus?.length ? (
                <TableBody>
                  {data?.listInvoiceByStatus?.map((resourcedata) => {
                    return (
                      <TableRow>
                        <TableCell align="left">
                          {resourcedata.projectName}
                        </TableCell>

                        <TableCell align="center">
                          {resourcedata.projectOwner}
                        </TableCell>

                        <TableCell align="center">
                          {resourcedata.companyname}
                        </TableCell>

                        <TableCell align="center">
                          {resourcedata.resourcename}
                        </TableCell>

                        <TableCell align="center">Per Hour</TableCell>

                        <TableCell align="center">
                          {resourcedata.currency}
                        </TableCell>

                        <TableCell align="center">
                          {resourcedata.invoicenumber}
                        </TableCell>

                        <TableCell align="center">
                          {getDateFromEpoch(resourcedata.invoicedate)}
                        </TableCell>

                        <TableCell align="center">
                          {resourcedata.invoiceamount}
                        </TableCell>

                        <TableCell style={{}} align="left">
                          <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            className={classes.docicon}
                          >
                            <DescriptionIcon />
                          </IconButton>
                        </TableCell>
                        {/* <TableCell align="center">
                          <IconButton
                            className={classes.iconstyle}
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem
                            className={classes.menuitem}
                            onClick={handleClose}
                          >
                            Approve
                          </MenuItem>
                          <MenuItem
                            className={classes.menuitem}
                            onClick={handleClose}
                          >
                            Reject
                          </MenuItem>
                        </Menu> */}
                      </TableRow>
                    );
                  })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={10}>No new invoices!</TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={resourceDatalength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>

        <Divider />
        <h1>Po Content</h1>
        <Divider />
      </Drawer>
    </div>
  );
}
