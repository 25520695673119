/**
 * Agreements Component
 * Component to show agreements to user with options to agree to sign the documents,
 * skip signing or invite an authorised user to the platform
 */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Box, Grid, Paper } from "@material-ui/core";

import { useStyles } from "./styles";

import DocumentTabs from "./documentTabs";
import InviteUser from "./InviteUser";

import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";

import VbridgeAlert from "../../shared/alert";

import {
  downloadDocument,
  getAgreement,
  inviteUser,
  userInfoQuery,
} from "./query";
import { getCompanyById, getNDAStatus } from "../companies/company/query";
import { getCompanyNDAStatus } from "../companies/Company2.0/query";

import OnboardingSteps from "../common/OnboardingSteps";
import StepperButtonGroup from "../profile2.0/StepperButtonGroup";

import "./agreements.css";
import { updateProfile } from "../profile2.0/query";

const agreement = require("../../../docs/agreement.pdf");

export default function Agreements({ drawerOpen }) {
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();

  const [inviteModal, setInviteModal] = useState(false);
  const [inviteError, setInviteError] = useState(
    "Something went wrong. Please try again."
  );
  const [showDocument, setShowDocument] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const [downloadComplete, setDownloadComplete] = useState(false);

  const { data: ndaStatus } = useQuery(getNDAStatus, {
    variables: { input: {} },
  });

  const { data: companyStatus } = useQuery(getCompanyNDAStatus, {
    variables: { input: {} },
  });

  const [inviteUserMutation, { loading: inviteLoading, error, data }] =
    useMutation(inviteUser, {
      onCompleted: (e) => {
        closeModal();
      },
      onError: (e) => {
        setInviteError(e.graphQLErrors[0].message);
      },
    });

  const [
    getAgreementMutation,
    { loading: agreementLoading, error: agreementError },
  ] = useMutation(getAgreement, {
    onCompleted: (data) => {
      if (data.createNDAdocumentforSign) {
        setDocumentUrl(data.createNDAdocumentforSign.signedurl);
        setShowDocument(true);
      } else {
        setInviteError("Could not download NDA. Please try again.");
      }
    },
    onError: (e) => {
      console.log("agreementError", e);
    },
  });

  const user = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  const showSteppers =
    user.getLoginUserInfo.isProfileUpdated !== "true" ||
    user.getLoginUserInfo.companyvbeid === "";
  //|| user?.getLoginUserInfo.onBoardingCompleted === false;

  const { data: companyData } = useQuery(getCompanyById, {
    variables: { input: { vbeid: user.getLoginUserInfo.companyvbeid } },
  });

  const [updateProfileMutation] = useMutation(updateProfile, {});

  const uploadPersonImageCache = () => {
    updateProfileMutation({
      variables: { input: { onBoardingCompleted: true } },
    });
  };

  const onSkip = (e) => {
    e.preventDefault();

    uploadPersonImageCache();
    history.push("/");
  };

  const onInviteUser = (e) => {
    e.preventDefault();
    setInviteModal(true);
  };

  const closeModal = () => {
    setInviteModal(false);
  };

  const submitUserInvite = (emailId) => {
    inviteUserMutation({
      variables: {
        input: { personemailid: emailId },
      },
    });
  };

  const onAgree = () => {
    getAgreementMutation({
      variables: {},
    });
  };

  const onIframeLoad = (e) => {
    let iframePath = e.target.contentWindow.location.pathname;

    if (
      iframePath === "/agreementAccepted" ||
      iframePath === "/agreementDeclined" ||
      iframePath === "/agreementSkip"
    ) {
      history.push(iframePath);
    }
  };

  const onStepperClick = (nextIndex) => {
    history.push({ pathname: "/register", state: { index: nextIndex } });
  };

  const [
    downloadQuery,
    { loading: downloading, data: downloadData, error: downloadError },
  ] = useLazyQuery(downloadDocument);

  const onDownloadClick = () => {
    setDownloadComplete(false);
    downloadQuery();
  };

  const onShowUnSignedDocument = () => {
    let url = `${agreement}#toolbar=0&navpanes=0`;

    window.open(url, "_blank").focus();
  };

  if (downloadData && !downloadComplete) {
    setDownloadComplete(true);

    let signedUrl = downloadData.downloadNDAsigneddocument.signedurl;
    window.open(signedUrl, "_self");
  }

  const [updateProfileOnAgreement] = useMutation(updateProfile, {
    onCompleted: (e) => {
      if (e.updateProfile.onBoardingCompleted) {
        history.push("/");
      }
    },
  });

  const onNextClick = () => {
    updateProfileOnAgreement({
      variables: { input: { onBoardingCompleted: true } },
    });
  };

  return (
    <div className={drawerOpen ? classes.drawerOpenRoot : classes.root}>
      {showSteppers && <OnboardingSteps step={2} />}

      <Box m={3}>
        <Grid container className={classes.mainGrid}>
          <Grid item md={12}>
            <div className={classes.stepTitleMessage}>
              {companyStatus ? (
                companyStatus?.companyNDAStatus?.status === "completed" ? (
                  <>
                    <div className={classes.formHeaderText}>
                      Marketplace Agreement is signed by{" "}
                      <b>{companyStatus?.companyNDAStatus?.name}</b> from your
                      company.
                    </div>

                    {showSteppers && (
                      <StepperButtonGroup
                        loading={false}
                        createCompanyLoading={false}
                        stepIndex={2}
                        formikValid={true}
                        companyFormikValid={true}
                        formikDirty={false}
                        companyFormikDirty={false}
                        onBackClick={() => onStepperClick(1)}
                        nextClick={onNextClick}
                      />
                    )}
                  </>
                ) : (
                  <div
                    className={classes.formHeaderText}
                    style={{ textAlign: "center" }}
                  >
                    Please verify that you are authorised to bind yourself and
                    your organisation to the following Marketplace Agreement
                    including Mutual NDA terms. If you are not, you may
                    <span className={classes.textLink} onClick={onInviteUser}>
                      {" "}
                      invite an authorized signer to join your organisation
                    </span>{" "}
                    or{" "}
                    <span
                      className={classes.textLink}
                      onClick={onShowUnSignedDocument}
                    >
                      download the document to share with the concerned to get
                      approval
                    </span>{" "}
                    or{" "}
                    <span className={classes.textLink} onClick={onSkip}>
                      skip this step and sign at a later date.
                    </span>
                  </div>
                )
              ) : (
                <div className={classes.formHeaderText}></div>
              )}
            </div>

            {companyStatus?.companyNDAStatus?.status !== "completed" &&
              showSteppers && (
                <StepperButtonGroup
                  loading={false}
                  createCompanyLoading={false}
                  stepIndex={2}
                  formikValid={true}
                  companyFormikValid={true}
                  formikDirty={false}
                  companyFormikDirty={false}
                  onBackClick={() => onStepperClick(1)}
                  // nextClick={() => {
                  //   history.push("/");
                  // }}
                  nextClick={onNextClick}
                  showSteppers={showSteppers}
                />
              )}

            <Paper elevation={1} square={true} className={classes.body}>
              {showDocument ? (
                <div>
                  <iframe
                    className={classes.iframe}
                    title="document"
                    src={documentUrl}
                    id="mainFrame"
                    onLoad={onIframeLoad}
                  ></iframe>
                </div>
              ) : (
                <DocumentTabs
                  onInviteUser={onInviteUser}
                  onSkip={onSkip}
                  onAgree={onAgree}
                  type="Mutual NDA"
                  companyName={companyData && companyData.queryCompanybyid.name}
                  agreementLoading={agreementLoading}
                  ndaStatus={ndaStatus?.getNDAStatus?.status}
                  companyStatus={companyStatus?.companyNDAStatus}
                  onDownloadClick={onDownloadClick}
                />
              )}
            </Paper>
          </Grid>
        </Grid>

        {showSteppers && (
          <StepperButtonGroup
            loading={false}
            createCompanyLoading={false}
            stepIndex={2}
            formikValid={true}
            companyFormikValid={true}
            formikDirty={false}
            companyFormikDirty={false}
            onBackClick={() => onStepperClick(1)}
            nextClick={() => {
              history.push("/");
            }}
          />
        )}
      </Box>

      {data && data.addPersonToPlatform.id === "true" && (
        <VbridgeAlert
          open={true}
          message="User Invited Successfully"
          severity="success"
        />
      )}

      {error && (
        <VbridgeAlert open={true} message={inviteError} severity="error" />
      )}

      {agreementError && (
        <VbridgeAlert open={true} message={agreementError} severity="error" />
      )}

      <InviteUser
        open={inviteModal}
        closeModal={closeModal}
        loading={inviteLoading}
        submitUserInvite={submitUserInvite}
      />

      {downloading && (
        <VbridgeAlert
          open={true}
          message="Please wait while the document is being retrieved."
          severity="info"
        />
      )}

      {downloadError && (
        <VbridgeAlert open={true} message={downloadError} severity="error" />
      )}
    </div>
  );
}
