import { useState } from "react";
import { useParams } from "react-router";

import { useQuery } from "@apollo/client";

import { Button, FormControl, Grid, TextField } from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

import { useFormik } from "formik";

import InputField from "../../common/InputField";
import SelectField from "../../common/SelectField";
import {
  productUpdateSchema,
  solutionUpdateSchema,
  successStoryUpdateSchema,
} from "../../common/updateSchemas";

import { useStyles } from "../../home/styles";

import {
  //   getCategoriesByServiceType,
  //   getServiceTypes,
  listProducts,
} from "./query";

import { equalsIgnoreOrder } from "../../../../functions/helper";
// import AddServiceTypeForm from "./shorts/AddServiceTypeForm";

import {
  availabilityArray,
  sasBasedoptions,
  PricingModel,
  SupportCoverage,
  markets,
} from "../../../../functions/options";

const verticals = require("../../../../data/companyVerticals.json");
const productTopics = require("../../../../data/products/topics.json");
const serviceTopics = require("../../../../data/services/topics.json");
const serviceSkills = require("../../../../data/services/skills.json");

export default function EditUsecase({ data, onClick, dataName, type }) {
  const classes = useStyles();
  const { id } = useParams();

  //   let selectedServiceTypes = data[dataName].servicetypelist;

  //   let selectedServices = [];

  //   if (type === "service" || type === "services") {
  //     selectedServiceTypes.forEach((serviceType) => {
  //       selectedServices.push(serviceType.vbeid);
  //     });
  //   }

  let topic = "";

  let skillList = serviceSkills.map((skill) => {
    return {
      type: skill["skl.vbeid"],
      value: skill["skl.name"],
      topic: skill["tpic.vbeid"],
    };
  });

  if (
    type === "products" ||
    type === "product" ||
    type === "service" ||
    type === "services"
  ) {
    topic = "topiclist";
  } else if (type === "productUseCase" || type === "usecases") {
    topic = "productid";
  }

  let mappedCategories = [];
  let initalSkills = [];
  let mappedSkills = [];

  if (
    (topic === "topiclist" || topic === "topicobj") &&
    data[dataName][topic].length !== 0
  ) {
    data[dataName][topic].forEach((topic) => {
      mappedCategories.push({ type: topic.vbeid, value: topic.name });
    });

    if (
      (type === "services" || type === "service") &&
      data[dataName]["skilllist"] &&
      data[dataName]["skilllist"].length !== 0
    ) {
      data[dataName]["skilllist"].forEach((skill) => {
        mappedSkills.push({ type: skill.vbeid, value: skill.name });
      });
    }

    skillList.forEach((skill) => {
      mappedCategories.forEach((category) => {
        if (skill.topic === category.type) {
          skill.topicName = category.value;
          initalSkills.push(skill);
        }
      });
    });
  }

  const [values, setValues] = useState(
    type === "products" || type === "services" ? mappedCategories : []
  );
  //   const [serviceTypeCount, setServiceTypeCount] = useState(
  //     selectedServiceTypes?.length
  //   );
  //   const [selectedType, setSelectedType] = useState(selectedServices);
  //   const [selectedCategories, setSelectedCategories] = useState([]);
  //   const [categoryList, setCategoryList] = useState([]);
  //   const [currentService, setCurrentService] = useState(0);
  //   const [categorySelect, setCategorySelect] = useState(false);
  //   const [newList, setNewList] = useState(false);
  const [matchingSkills, setMatchingSkills] = useState(initalSkills);
  const [selectedSkills, setSelectedSkills] = useState(
    type === "services" ? mappedSkills : []
  );
  const [newSkill, setNewSkill] = useState("");

  let vertical = "businessvertical";
  if (type === "usecases" || type === "productUseCase") {
    vertical = "industryvertical";
  }

  const { loading: productsloading, data: products } = useQuery(listProducts, {
    variables: { input: { vbeid: id } },
  });

  //   const { data: serviceTypes } = useQuery(getServiceTypes, {
  //     variables: { input: {} },
  //   });

  let mappedProducts = [];

  if (type === "usecases" || type === "caseStudies") {
    data[dataName].productlist?.forEach((product) => {
      mappedProducts.push(product.vbeid);
    });
  }

  const getSkills = (categories, categoryNames) => {
    let matchingSkills = [];

    skillList.forEach((skill) => {
      categories.forEach((category, index) => {
        if (skill.topic === category) {
          skill.topicName = categoryNames[index];
          matchingSkills.push(skill);
        }
      });
    });

    setMatchingSkills(matchingSkills);
  };

  const formik = useFormik({
    initialValues: {
      url: data[dataName].url || data[dataName].producturl,
      product: mappedProducts || [],
      industryvertical: data[dataName][vertical]
        ? data[dataName][vertical]
        : [],
      topic:
        type === "products" || type === "services"
          ? mappedCategories.length !== 0
            ? mappedCategories
            : []
          : [],
      skills: type === "services" ? mappedSkills : [],
      //   serviceType: serviceTypeId,

      changeAvailability:
        type === "products"
          ? data[dataName].availability
            ? data[dataName].availability
            : ""
          : "",

      sasbases:
        type === "products"
          ? data[dataName].saasBased
            ? data[dataName].saasBased
            : ""
          : "",

      prices:
        type === "products"
          ? data[dataName].pricingModel
            ? data[dataName].pricingModel
            : ""
          : "",

      supports:
        type === "products"
          ? data[dataName].supportCoverage
            ? data[dataName].supportCoverage
            : ""
          : "",

      marketplace:
        type === "products"
          ? data[dataName].marketplaceListing
            ? data[dataName].marketplaceListing
            : ""
          : "",
    },
    validationSchema:
      type === "products" || type === "services"
        ? productUpdateSchema
        : type === "usecases" || type === "productUseCase"
        ? solutionUpdateSchema
        : successStoryUpdateSchema,
  });

  let companyVerticals = [];

  verticals.forEach((vertical) => {
    if (vertical["n.type"] === "Company Vertical") {
      companyVerticals.push({
        type: vertical["n.name"],
        value: vertical["n.name"],
      });
    }
  });

  let topicsList = [];
  let topicListName = type === "products" ? productTopics : serviceTopics;

  topicListName.forEach((topic) => {
    topicsList.push({
      type: topic["n.vbeid"],
      value: topic["n.name"],
      serviceType: topic["grp.type"],
    });
  });

  //   const onCapabilityChange = (e) => {
  //     setNewSkill(e.target.value);
  //   };

  // let serviceTypeList = [];
  // if (serviceTypes) {
  //   serviceTypes.listServicetypes.forEach((serviceType) => {
  //     serviceTypeList.push({
  //       type: serviceType.vbeid,
  //       value: serviceType.name,
  //     });
  //   });
  // }

  //   let serviceTypeList = [];

  //   serviceTypeList.push(
  //     {
  //       type: "Consulting Services",
  //       value: "Consulting Services",
  //     },
  //     { type: "Professional Services", value: "Professional Services" },
  //     { type: "Managed Services", value: "Managed Services" },
  //     { type: "Development Services", value: "Development Services" }
  //   );

  //   const getTypeName = (serviceTypeId) => {
  //     let service = serviceTypeList.filter((serviceType) => {
  //       return serviceType.type === serviceTypeId;
  //     });

  //     return service[0]?.value;
  //   };

  //   const [getCategories] = useLazyQuery(getCategoriesByServiceType, {
  //     variables: {
  //       input: {
  //         name: getTypeName(selectedType[currentService]),
  //       },
  //     },
  //     onCompleted: (response) => {
  //       let currentList = response.listTopicsByServicetypeName;

  //       let mainCategoriesList = categoryList;
  //       mainCategoriesList[currentService] = currentList;

  //       setCategoryList(mainCategoriesList);

  //       setNewList(!newList);
  //     },
  //   });

  //   const handleChange = (e, index) => {
  //     let selectedTypes = selectedType;

  //     selectedTypes[index] = e.target.value;

  //     setCurrentService(index);
  //     setSelectedType(selectedTypes);

  //     let mainCategoriesList = categoryList;
  //     mainCategoriesList[index] = [];
  //     setCategoryList(mainCategoriesList);

  //     getCategories();
  //   };

  // const handleCategorySelect = (e, index) => {
  //   let prevSelectedCategories = selectedCategories;
  //   let currentCategories = selectedCategories[index];

  //   if (currentCategories) {
  //     currentCategories = e.target.value;
  //   } else {
  //     currentCategories = e.target.value;
  //   }

  //   prevSelectedCategories[index] = currentCategories;

  //   setSelectedCategories(prevSelectedCategories);
  //   setCategorySelect(!categorySelect);
  // };

  //   const handleCategorySelect = (e, index) => {
  //     // let selectCategories = e;
  //     // setSelectedCategories(e);
  //     // let currentCategories = selectedCategories[index];
  //     var select = e.map((val) => {
  //       return val.name;
  //     });

  //     setSelectedCategories(select);
  //     // if (currentCategories) {
  //     //   currentCategories = e.target.value;
  //     // } else {
  //     //   currentCategories = e.target.value;
  //     // }

  //     // prevSelectedCategories[index] = currentCategories;

  //     // setSelectedCategories(prevSelectedCategories);
  //     // setCategorySelect(!categorySelect);
  //   };

  //   const returnServiceTypes = () => {
  //     let returnTypes = [];

  //     for (var i = 0; i < serviceTypeCount; i++) {
  //       let index = i;

  //       returnTypes.push(
  //         <AddServiceTypeForm
  //           handleChange={(e) => handleChange(e, index)}
  //           selectedType={selectedType[i]}
  //           serviceTypeList={serviceTypeList}
  //           categoryList={categoryList[i]}
  //           index={i}
  //           handleCategorySelect={(e) => handleCategorySelect(e, index)}
  //           selectedCategories={selectedCategories[index] || []}
  //           categoriesLoading={categoriesLoading}
  //         />
  //       );
  //     }

  //     return returnTypes;
  //   };

  //   const addServiceType = () => {
  //     let newCount = serviceTypeCount + 1;
  //     setServiceTypeCount(newCount);
  //   };

  return (
    <>
      <Grid container style={{ width: "100%", margin: "20px 0" }} spacing={1}>
        <Grid item md={type === "services" ? 6 : 4}>
          <Grid item md={12}>
            <FormControl fullWidth={true} className={classes.formControl}>
              <InputField
                label={
                  type === "usecases" || type === "productUseCase"
                    ? "Resource URL"
                    : type === "products"
                    ? "Product URL"
                    : type === "services"
                    ? "Service URL"
                    : "Success Story URL"
                }
                placeholder="https://www.vbridgehub.com/"
                onChange={formik.handleChange("url")}
                value={formik.values.url}
              />
            </FormControl>
          </Grid>

          <Grid item className={classes.errorfields}>
            {formik.touched.url && formik.errors.url ? (
              <div className="error-message">{formik.errors.url}</div>
            ) : null}
          </Grid>
        </Grid>

        {/* {type === "services" && (
          <Grid
            container
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              margin: "0 8px",
            }}
            spacing={1}
          > */}
        {/* {returnServiceTypes()} */}

        {/* {serviceTypeCount < 5 && (
              <Grid item md={2}>
                <IconButton onClick={addServiceType}>
                  <AddIcon fontSize="small" />
                </IconButton>
              </Grid>
            )} */}
        {/* </Grid>
        )} */}

        {/* {type === "services" && (
          <>
            <Grid item md={6}>
              <AutoCompleteField
                options={topicsList}
                label="Select categories"
                setValues={setValues}
              />
            </Grid>
          </>
        )} */}

        {type === "services" && (
          <Grid item md={12}>
            <Grid container>
              <Grid item md={6}>
                <Autocomplete
                  multiple={true}
                  limitTags={2}
                  id="multiple-limit-tags"
                  defaultValue={values}
                  clearOnBlur="true"
                  options={topicsList}
                  className={classes.formControl}
                  onChange={(event, newValue) => {
                    let selectedValue = [];
                    let selectedValueName = [];

                    newValue?.forEach((value) => {
                      selectedValue.push(value.type);
                      selectedValueName.push(value.value);
                    });
                    setValues(selectedValue);

                    getSkills(selectedValue, selectedValueName);
                  }}
                  getOptionLabel={(option) => `${option.value}`}
                  renderOption={(option) => (
                    <>
                      <div>{option.value}</div>
                      <div
                        style={{
                          fontStyle: "italic",
                          fontSize: "0.7rem",
                          marginLeft: "8px",
                        }}
                      >
                        {option.serviceType}
                      </div>
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.selectText}
                      label="Map Category"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item md={6}>
                <Autocomplete
                  multiple={true}
                  limitTags={2}
                  id="multiple-limit-tags"
                  defaultValue={selectedSkills}
                  clearOnBlur="true"
                  options={matchingSkills}
                  className={classes.formControl}
                  onChange={(event, newvalue) => {
                    let selectedValue = [];

                    newvalue?.forEach((value) => {
                      selectedValue.push(value.type);
                    });

                    setSelectedSkills(selectedValue);
                  }}
                  groupBy={(option) => option.topicName}
                  getOptionLabel={(option) => option.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.selectText}
                      label="Map Capabilities"
                      variant="outlined"
                      placeholder="Search Capabilities"
                    />
                  )}
                />
              </Grid>

              {/* <Grid item md={6}>
                <InputField
                  label="Add New Capability"
                  onChange={onCapabilityChange}
                  multiline={false}
                />
              </Grid> */}
            </Grid>
          </Grid>
        )}

        {type === "products" && (
          <>
            <Grid item md={6}>
              <Autocomplete
                multiple={true}
                limitTags={2}
                id="multiple-limit-tags"
                defaultValue={values}
                clearOnBlur="true"
                options={topicsList}
                className={classes.formControl}
                onChange={(event, newvalue) => {
                  let selectedValue = [];
                  newvalue?.forEach((value) => {
                    selectedValue.push(value.type);
                  });
                  setValues(selectedValue);
                }}
                getOptionLabel={(option) => option.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search Category"
                    className={classes.selectText}
                    label="Map Category"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item md={4}>
              <FormControl
                variant="outlined"
                fullWidth={true}
                className={classes.formControl}
              >
                <SelectField
                  label="Availability"
                  onChange={formik.handleChange("changeAvailability")}
                  value={formik.values.changeAvailability}
                  options={availabilityArray}
                />
              </FormControl>
            </Grid>

            <Grid item md={3}>
              <FormControl
                variant="outlined"
                fullWidth={true}
                className={classes.formControl}
              >
                <SelectField
                  label="SaaS based "
                  onChange={formik.handleChange("sasbases")}
                  value={formik.values.sasbases}
                  options={sasBasedoptions}
                  multiple={false}
                />
              </FormControl>
            </Grid>

            <Grid item md={3}>
              <FormControl
                variant="outlined"
                fullWidth={true}
                className={classes.formControl}
              >
                <SelectField
                  label="Pricing Model"
                  onChange={formik.handleChange("prices")}
                  value={formik.values.prices}
                  options={PricingModel}
                />
              </FormControl>
            </Grid>

            <Grid item md={3}>
              <FormControl
                variant="outlined"
                fullWidth={true}
                className={classes.formControl}
              >
                <SelectField
                  label="Support Coverage"
                  onChange={formik.handleChange("supports")}
                  value={formik.values.supports}
                  options={SupportCoverage}
                  multiple={false}
                />
              </FormControl>
            </Grid>

            <Grid item md={3}>
              <FormControl
                variant="outlined"
                fullWidth={true}
                className={classes.formControl}
              >
                <SelectField
                  label="Marketplace Listing "
                  onChange={formik.handleChange("marketplace")}
                  value={formik.values.marketplace}
                  options={markets}
                />
              </FormControl>
            </Grid>
          </>
        )}

        {type !== "products" &&
          type !== "services" &&
          type !== "usecases" &&
          type === "productUsecases" && (
            <>
              <Grid item md={3}>
                <FormControl
                  variant="outlined"
                  fullWidth={true}
                  className={classes.formControl}
                >
                  <SelectField
                    label="Business Vertical"
                    onChange={formik.handleChange("industryvertical")}
                    value={formik.values.industryvertical}
                    options={companyVerticals}
                    multiple={
                      type === "usecases" || type === "productUseCase"
                        ? true
                        : false
                    }
                  />
                </FormControl>
              </Grid>
            </>
          )}

        {type !== "products" && type !== "services" && (
          <Grid item md={3}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.formControl}
            >
              <SelectField
                label="Map Applicable Product"
                onChange={formik.handleChange("product")}
                value={formik.values.product}
                options={
                  productsloading
                    ? []
                    : products
                    ? products.productByComapnyId
                    : null
                }
                multiple={true}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item md={4}>
          <Button
            onClick={() => {
              onClick(formik, values, selectedSkills, newSkill);
            }}
            color="primary"
            disabled={
              equalsIgnoreOrder(values, formik.values.topic) &&
              !(formik.isValid && formik.dirty) &&
              equalsIgnoreOrder(selectedSkills, formik.values.skills)
            }
            className={
              type === "products" ? classes.addNewBtnproduct : classes.addNewBtn
            }
            variant="contained"
          >
            Save
          </Button>
        </Grid>

        {type === "services" && (
          <>
            {/* <Grid item md={12}>
              <Typography className={classes.mapHeadingText}>
                Map Service to Partner Product
              </Typography>
              <Grid container spacing={1}>
                <Grid item md={3}>
                  <FormControl fullWidth={true} className={classes.formControl}>
                    <InputField
                      label="Company"
                      placeholder="Select Company"
                      onChange={handleChange}
                      value={selectedCompany?.name || text}
                    />
                  </FormControl>

                  <SearchResult
                    className={classes.resultDiv}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    text={text}
                    n={5}
                    type="service"
                    onSelect={onCompanySelect}
                  />
                </Grid>

                <Grid item md={6}>
                  <Autocomplete
                    multiple
                    defaultValue={srvcProduct}
                    options={companyProducts?.productByComapnyId || []}
                    getOptionLabel={(option) => {
                      return option.name;
                    }}
                    style={{ width: "100%" }}
                    clearOnBlur="true"
                    onChange={(event, newvalue) => {
                      let selectedValue = [];
                      newvalue.forEach((value) => {
                        selectedValue.push(value.vbeid);
                      });

                      setSrvcProduct(selectedValue);
                    }}
                    renderOption={(option) => {
                      return option.name;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          selectedCompany ? "Product" : "Select Company first"
                        }
                        placeholder="Search Product"
                        variant="outlined"
                        id="input"
                        className={classes.selectText}
                        autoComplete="off"
                      />
                    )}
                  />
                </Grid>

                <Grid item md={2}>
                  <Button
                    onClick={() => {
                      onClick(formik, values);
                    }}
                    color="primary"
                    disabled={
                      type === "services"
                        ? !(formik.isValid && formik.dirty) &&
                          equalsIgnoreOrder(values, data[dataName].productobj)
                        : !(formik.isValid && formik.dirty)
                    }
                    className={classes.addNewBtn}
                    variant="contained"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid> */}
          </>
        )}
      </Grid>
    </>
  );
}
