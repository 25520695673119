import { useQuery } from "@apollo/client";

import { getProductsByFilters } from "../groups2.0/query";

import CategoryResultLoading from "./CategoryResultLoading";
import ProductsSearchResults from "./ProductsSearchResults";

export default function CategoryProducts({
  category,
  categoryName,
  type,
  open,
  rowType,
  onProductCardClick,
  searchContext,
  toggleValue,
  mainDrawerClick,
}) {
  let idName = "topicid";
  if (rowType === "interest" || rowType === "trending") {
    idName = "groupid";
  }

  const { data, loading, fetchMore } = useQuery(getProductsByFilters, {
    variables: {
      input: {
        yearlist: null,
        partnerlist: null,
        locationlist: null,
        awardslist: null,
        fundroundlist: null,
        offset: 0,
        limit: 10,
        [idName]: category,
      },
    },
  });

  const updateQuery = (previousResult, { fetchMoreResult }) => {
    if (!fetchMoreResult) {
      return previousResult;
    }

    const previousEdges = previousResult.listproductfilter;
    const fetchMoreEdges = fetchMoreResult.listproductfilter;

    fetchMoreResult.listproductfilter = [...previousEdges, ...fetchMoreEdges];

    return { ...fetchMoreResult };
  };

  const getMoreProducts = () => {
    if (data) {
      fetchMore({
        updateQuery,
        variables: {
          input: {
            yearlist: null,
            partnerlist: null,
            locationlist: null,
            awardslist: null,
            fundroundlist: null,
            offset: data.listproductfilter.length,
            limit: 10,
            [idName]: category,
          },
        },
      }).then((fetchMoreResult) => {});
    }
  };

  if (loading) {
    return <CategoryResultLoading />;
  }

  return (
    <div>
      {data && data.listproductfilter.length !== 0 && (
        <ProductsSearchResults
          products={data.listproductfilter}
          type={type}
          open={open}
          rowType={rowType}
          onProductCardClick={onProductCardClick}
          searchContext={searchContext}
          categoryName={categoryName}
          categoryId={category}
          onNextClick={getMoreProducts}
          toggleValue={toggleValue}
          mainDrawerClick={mainDrawerClick}
        />
      )}
    </div>
  );
}
