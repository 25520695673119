import { useStyles } from "../styles";

export default function OverviewTabs({
  currentTab,
  onTabClick,
  orgType,
  isBuyer,
}) {
  const classes = useStyles();

  return (
    <ul className={classes.overviewContentList}>
      <li
        className={currentTab === "about" ? classes.selectedListTitle : ""}
        onClick={() => onTabClick("about")}
      >
        About
      </li>
      {/* {orgType !== "Enterprise" && !isBuyer && ( */}
      <>
        <li
          className={currentTab === "awards" ? classes.selectedListTitle : ""}
          onClick={() => onTabClick("awards")}
        >
          Awards
        </li>
        {/* <li
            className={
              currentTab === "investors" ? classes.selectedListTitle : ""
            }
            onClick={() => onTabClick("investors")}
          >
            Investors
          </li> */}
        <li
          className={
            currentTab === "customers" ? classes.selectedListTitle : ""
          }
          onClick={() => onTabClick("customers")}
        >
          Customers
        </li>
        <li
          className={currentTab === "partners" ? classes.selectedListTitle : ""}
          onClick={() => onTabClick("partners")}
        >
          Partners
        </li>
      </>
      {/* )} */}
    </ul>
  );
}
