import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import MapTopic from "../../../shared/mapping";
import Policy from "../../../shared/policy";

import {
  getProductById,
  getProductByProductId,
  getProducts,
  updateProductInfo,
} from "./query";
import { userInfoQuery, userPermission } from "../../groups2.0/query";

import DataPageHeader from "../../common/DataPageHeader";

import { useStyles } from "./styles";

import ProductTabs from "./producttabs";
import DataLoading from "./DataLoading";
import LeftPaneProducts from "./LeftPaneProducts";

export default function ProductPage({ type, drawerOpen, mainDrawerClick }) {
  const classes = useStyles();
  const { id } = useParams();
  const client = useApolloClient();

  const [editName, setEditName] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editNameValue, setEditNameValue] = useState("");
  const [editDescriptionValue, setEditDescriptionValue] = useState("");
  const [isReadOnly, setReadonly] = useState(true);
  const [isAdmin, setAdmin] = useState(true);
  const [companyid, setCompanyid] = useState("");
  const [length, setLength] = useState("");

  const admin_role = "PROFILE_ADMIN";

  const user = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  const editNameClick = () => {
    setEditName(true);
  };

  const editDescriptionClick = () => {
    setEditDescription(true);
  };

  const handleNameChange = (e) => {
    setEditNameValue(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setEditDescriptionValue(e.target.value);
  };

  const afterLoadingMainData = (e) => {
    setAdmin(user.getLoginUserInfo.companyvbeid === e.productById.companyvbeid);

    setReadonly(
      !(
        (
          user.getLoginUserInfo.companyvbeid === e.productById.companyvbeid &&
          user.getLoginUserInfo.role.includes(admin_role)
        )
        //   || user.getLoginUserInfo.isVbridgeUser === "true"
      )
    );

    setCompanyid(e.productById.companyvbeid);
  };

  const { data } = useQuery(getProductById, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id } },
    onCompleted: (e) => {
      afterLoadingMainData(e);
    },
  });

  let otherProductsInput = {
    input: {
      vbeid: companyid,
    },
  };

  if (type === "service" || data?.productById.type === "service") {
    otherProductsInput = {
      input: {
        vbeid: companyid,
        type: "service",
      },
    };
  }

  const { data: otherProducts } = useQuery(getProducts, {
    variables: { otherProductsInput },
    onCompleted: (response) => {
      setLength(response.productByComapnyId.length);
    },
  });

  //update product
  const [postproductmutation] = useMutation(updateProductInfo);

  const postUpdateProduct = (paramName, paramValue, id) => {
    postproductmutation({
      variables: {
        input: {
          vbeid: id,
          [paramName]: paramValue,
        },
      },
    });
  };

  //update cache
  const updateCache = (paramName, paramValue) => {
    if (paramValue !== "") {
      let updatedProduct = { ...data.productById };
      updatedProduct[paramName] = paramValue;

      client.writeQuery({
        query: getProductById,
        data: {
          productById: updatedProduct,
        },

        variables: { input: { vbeid: id } },
      });

      postUpdateProduct(paramName, paramValue, id);
    }
  };

  //update cache for longdescription
  const UpdateProductCache = (e) => {
    let UpdatedlongDescData = e;
    let updatedProduct = { ...data.productById };
    updatedProduct.longdescription = UpdatedlongDescData;

    client.writeQuery({
      query: getProductById,
      data: {
        productById: updatedProduct,
      },
      variables: { input: { vbeid: id } },
    });
  };

  const saveNameClick = () => {
    setEditName(false);
    updateCache("name", editNameValue);
  };

  const saveDescriptionClick = () => {
    setEditDescription(false);
    updateCache("shortdescription", editDescriptionValue);
  };

  const { data: similarProducts } = useQuery(getProductByProductId, {
    variables: { input: { vbeid: id } },
  });

  const userpermission = client.readQuery({
    query: userPermission,
    variables: { input: {} },
  });

  //   let productsTagged = "";
  //   let serviceTypes = "";
  //   let locations = "";

  //   if (data && (type === "service" || data?.productById.type === "service")) {
  //     if (
  //       data.productById.productobj &&
  //       data.productById.productobj.length !== 0
  //     ) {
  //       productsTagged = data.productById.productobj.map((value) => {
  //         return " " + value.name + " ";
  //       });
  //     }

  //     if (
  //       data.productById.servicetypeobj &&
  //       data.productById.servicetypeobj.length !== 0
  //     ) {
  //       serviceTypes = data.productById.servicetypeobj.map((value) => {
  //         return " " + value.name + " ";
  //       });
  //     }

  //     if (
  //       data.productById.countryobj &&
  //       data.productById.countryobj.length !== 0
  //     ) {
  //       locations = data.productById.countryobj.map((value) => {
  //         return " " + value.name + " ";
  //       });
  //     }
  //   }

  return (
    <>
      <div className={drawerOpen ? classes.drawerOpenRoot : classes.root}>
        <Box m={5}>
          <div>
            {data ? (
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                {data.productById.grouplist && (
                  <Link
                    color="primary"
                    className={classes.breadCrumbValue}
                    to={
                      data.productById.grouplist !== null &&
                      data.productById.grouplist?.length !== 0
                        ? "/group/" +
                          type +
                          "s/" +
                          data.productById.grouplist[0]?.vbeid
                        : "/"
                    }
                  >
                    {data.productById.grouplist?.length !== 0 &&
                      data.productById.grouplist[0].name}
                  </Link>
                )}

                <Link
                  color="primary"
                  className={classes.breadCrumbValue}
                  to={
                    data.productById.topiclist.length !== 0
                      ? "/topic/" +
                        type +
                        "s/" +
                        data.productById.topiclist[0].vbeid
                      : null
                  }
                >
                  {data.productById.topiclist.length !== 0 &&
                    data.productById.topiclist[0].name}
                </Link>

                <Typography color="primary" className={classes.breadCrumbValue}>
                  {data.productById.name}
                </Typography>
              </Breadcrumbs>
            ) : null}
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={9}>
              <Paper elevation={1} square={true}>
                <Card className={classes.card}>
                  {data ? (
                    <CardContent style={{ textAlign: "left" }}>
                      <DataPageHeader
                        data={data?.productById}
                        isReadOnly={true}
                        vbeid={id}
                        updateCache={updateCache}
                        editName={editName}
                        editDescription={editDescription}
                        editNameClick={editNameClick}
                        handleNameChange={handleNameChange}
                        saveNameClick={saveNameClick}
                        editDescriptionClick={editDescriptionClick}
                        handleDescriptionChange={handleDescriptionChange}
                        saveDescriptionClick={saveDescriptionClick}
                        type={type}
                        isAdmin={isAdmin}
                        userPermission={userpermission}
                        userInfo={user?.getLoginUserInfo}
                        mainDrawerClick={mainDrawerClick}
                      />

                      {data && (
                        <ProductTabs
                          isReadOnly={true}
                          isAdmin={isAdmin}
                          type="product"
                          data={data}
                          UpdateProductCache={(e) => UpdateProductCache(e)}
                        />
                      )}
                    </CardContent>
                  ) : (
                    <DataLoading />
                  )}
                </Card>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              {length > 1 ? (
                <LeftPaneProducts
                  title={
                    type === "service" || data?.productById.type === "service"
                      ? "Other Services From "
                      : "Other Products From "
                  }
                  companyName={data?.productById?.companyname}
                  products={otherProducts?.productByComapnyId}
                  vbeid={id}
                />
              ) : null}

              {isAdmin ? null : (
                <LeftPaneProducts
                  title={
                    type === "service" || data?.productById.type === "service"
                      ? "Similar Services in "
                      : "Similar Products in "
                  }
                  companyName={
                    data?.productById?.topicname ||
                    data?.productById.topiclist[0]?.name
                  }
                  products={similarProducts?.productsByProductID}
                  vbeid={id}
                />
              )}

              {isReadOnly
                ? null
                : type === "service" &&
                  data?.productById.type === "service" && (
                    <Grid item md={12}>
                      <Paper
                        elevation={1}
                        square={true}
                        className={classes.mapProduct}
                      >
                        <Typography
                          gutterBottom
                          variant="button"
                          style={{ textTransform: "capitalize" }}
                          className={classes.subHeading}
                          component="caption"
                          display="block"
                        >
                          Map Service to Category
                        </Typography>

                        <MapTopic
                          id={id}
                          name="BELONGS_TO"
                          currentvbeid={
                            data ? data?.productById.topicvbeid : null
                          }
                          type="service"
                          mapType="category"
                          updateCache={updateCache}
                        />
                      </Paper>
                    </Grid>
                  )}

              {isReadOnly
                ? null
                : (type === "product" ||
                    data?.productById.type === "service") && (
                    <Grid item md={12}>
                      <Paper
                        elevation={1}
                        square={true}
                        className={classes.mapProduct}
                      >
                        <Typography
                          gutterBottom
                          variant="button"
                          style={{ textTransform: "capitalize" }}
                          className={classes.subHeading}
                          component="caption"
                          display="block"
                        >
                          Map Product to Category
                        </Typography>

                        <MapTopic
                          id={id}
                          name="BELONGS_TO"
                          currentvbeid={
                            data ? data?.productById.topicvbeid : null
                          }
                        />
                      </Paper>
                    </Grid>
                  )}

              <Policy />
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
