import { gql } from "@apollo/client";

export const getUsecaseById = gql`
  query usecaseinformation($input: usecaseinput!) {
    usecaseByID(input: $input) {
      id
      name
      image
      companyvbeid
      companyname
      shortdescription
      vbeid
      industryvertical
      longdescription
      url
      productlist {
        vbeid
        name
      }
    }
  }
`;

export const userInfoQuery = gql`
  query userinfo {
    getLoginUserInfo(input: {}) {
      vbeid
      name
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
    }
  }
`;

export const updateUsecaseData = gql`
  mutation update($input: usecaseinput!) {
    updateusecase(input: $input) {
      name
      shortdescription
      url
      industryvertical
      productlist {
        vbeid
        name
      }
    }
  }
`;

export const getUsecaseByUsecaseId = gql`
  query usecaseinformation($input: usecaseinput!) {
    usecaseByusecaseID(input: $input) {
      name
      image
      vbeid
      id
    }
  }
`;

export const signed = gql`
  mutation upload($input: projectinput!) {
    signedURLforSupportDoc(input: $input) {
      signedurl
      id
      path
      name
    }
  }
`;

export const listDocs = gql`
  query docs($input: projectinput!) {
    listsupportDoc(input: $input) {
      name
      signedurl
      createdby
      id
    }
  }
`;

export const deleteDocument = gql`
  mutation update($input: projectinput!) {
    deleteSupportDoc(input: $input) {
      id
    }
  }
`;

export const postUsecase = gql`
  mutation updatecompany($input: usecaseinput!) {
    updateusecase(input: $input) {
      longdescription
    }
  }
`;
