import React, { useEffect } from "react";

import { useStyles } from "./styles";

import { BootstrapInput } from "../../../theme/theme";

import {
  InputLabel,
  FormControl,
  MenuItem,
  ListItemText,
  Select,
} from "@material-ui/core";

import { useFormik } from "formik";
import * as yup from "yup";

const verticals = require("../../../data/companyVerticals.json");

export default function CompanyUpdateForm({ params, method }) {
  const classes = useStyles();

  //console.log('method',method)

  ///regex patterns for url validations
  const lnurl =
    "^(http(s)?://)?(www.)?linkedin.com/(in|profile|pub|company)/([A-z 0-9 _ -]+)/?$";
  const fburl = "^(https?://)?(www.)?facebook.com/[a-zA-Z0-9(.?)?]";
  const turl = "^(https?://)?(www.)?twitter.com/[a-zA-Z0-9(.?)?]";

  //validationSchema
  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter Name")
      .min(3, "Name should be of minimum 2 characters length")
      .required("Name is required")
      .nullable(),

    founder: yup
      .string("Enter Founder Name")
      .min(3, "Founder Name should be of minimum 2 characters length")
      .required("Name is required")
      .nullable(),

    shortdescription: yup
      .string("Enter Short Description")
      .min(10, "Short Description should be of minimum 10 characters length")
      .required("Name is required")
      .nullable(),

    founded: yup.string().required("*Field required").nullable(),

    location: yup
      .string("Enter Location")
      .min(3, "Location should be of minimum 2 characters length")
      .required("Location is required")
      .nullable(),

    linkedinurl: yup
      .string("Enter your Linkedin URL")
      .nullable()
      .matches(lnurl, "Enter valid Linkedin URL"),

    facebookurl: yup
      .string("Enter your Facebook URL")
      .nullable()
      .matches(fburl, "Enter valid Facebook URL"),

    twitterurl: yup
      .string("Enter your Twitter URL")
      .nullable()
      .matches(turl, "Enter valid Twitter URL"),

    companyurl: yup
      .string("Enter your Company URL")
      .nullable()
      .required("Company URL is required")
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        "Enter valid Company URL"
      ),
  });

  //filter params and updateForm values
  const formik = useFormik({
    initialValues: {
      name: params.name,
      founder: params.founder,
      founded: params.founded,
      location: params.location,
      companyurl: params.companyurl,
      linkedinurl: params.linkedinurl,
      facebookurl: params.facebookurl,
      twitterurl: params.twitterurl,
      businessvertical: params.businessvertical,
      shortdescription: params.shortdescription,
    },
    validationSchema: validationSchema,
  });

  //send Formik values
  useEffect(() => {
    method(formik.values, params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, params.id]);

  // {formik.touched.founder && formik.errors.founder ? (

  return (
    <form>
      <FormControl fullWidth={true}>
        <InputLabel required={true} shrink focused={true}>
          Company Name
        </InputLabel>

        <BootstrapInput
          required={true}
          value={formik.values.name}
          onChange={formik.handleChange("name")}
          onBlur={formik.handleBlur("name")}
          placeholder="Enter Company Name"
        />

        <div className={classes.errorfields}>
          {formik.touched.name && formik.errors.name && (
            <div className="error-message">{formik.errors.name}</div>
          )}
        </div>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel required={true} shrink focused={true}>
          Founder Name
        </InputLabel>

        <BootstrapInput
          required={true}
          value={formik.values.founder}
          onChange={formik.handleChange("founder")}
          onBlur={formik.handleBlur("founder")}
          placeholder="Enter Founder Name"
        />

        <div className={classes.errorfields}>
          {formik.touched.founder && formik.errors.founder ? (
            <div className="error-message">{formik.errors.founder}</div>
          ) : null}
        </div>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel required={true} shrink focused={true}>
          Location
        </InputLabel>

        <BootstrapInput
          required={true}
          value={formik.values.location}
          onChange={formik.handleChange("location")}
          onBlur={formik.handleBlur("location")}
          placeholder="Enter Company Location"
        />

        <div className={classes.errorfields}>
          {formik.touched.location && formik.errors.location ? (
            <div className="error-message">{formik.errors.location}</div>
          ) : null}
        </div>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel required={true} shrink focused={true}>
          Founded Year
        </InputLabel>

        <BootstrapInput
          variant="outlined"
          fullwidth
          type="number"
          min="1700"
          max="2099"
          value={formik.values.founded}
          onChange={formik.handleChange("founded")}
          onBlur={formik.handleBlur("founded")}
        />

        <div className={classes.errorfields}>
          {formik.touched.founded && formik.errors.founded ? (
            <div className="error-message">{formik.errors.founded}</div>
          ) : null}
        </div>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel required={true} shrink focused={true}>
          Company Url
        </InputLabel>

        <BootstrapInput
          required={true}
          value={formik.values.companyurl}
          onChange={formik.handleChange("companyurl")}
          onBlur={formik.handleBlur("companyurl")}
          placeholder="Enter Company Url"
        />

        <div className={classes.errorfields}>
          {formik.touched.companyurl && formik.errors.companyurl ? (
            <div className="error-message">{formik.errors.companyurl}</div>
          ) : null}
        </div>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel shrink focused={true}>
          LinkedIn
        </InputLabel>

        <BootstrapInput
          required={true}
          value={formik.values.linkedinurl}
          onChange={formik.handleChange("linkedinurl")}
          onBlur={formik.handleBlur("linkedinurl")}
          placeholder="Enter linkedinurl"
        />

        <div className={classes.errorfields}>
          {formik.touched.linkedinurl && formik.errors.linkedinurl ? (
            <div className="error-message">{formik.errors.linkedinurl}</div>
          ) : null}
        </div>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel shrink focused={true}>
          Facebook Url
        </InputLabel>

        <BootstrapInput
          required={true}
          value={formik.values.facebookurl}
          onChange={formik.handleChange("facebookurl")}
          onBlur={formik.handleBlur("facebookurl")}
          placeholder="Enter facebookurl"
        />

        <div className={classes.errorfields}>
          {formik.touched.facebookurl && formik.errors.facebookurl ? (
            <div className="error-message">{formik.errors.facebookurl}</div>
          ) : null}
        </div>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel shrink focused={true}>
          Twitter Url
        </InputLabel>

        <BootstrapInput
          required={true}
          value={formik.values.twitterurl}
          onChange={formik.handleChange("twitterurl")}
          onBlur={formik.handleBlur("twitterurl")}
          placeholder="Enter twitterurl"
        />

        <div className={classes.errorfields}>
          {formik.touched.twitterurl && formik.errors.twitterurl ? (
            <div className="error-message">{formik.errors.twitterurl}</div>
          ) : null}
        </div>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel required={true} shrink focused={true}>
          Business Vertical
        </InputLabel>

        <Select
          required={true}
          input={<BootstrapInput />}
          value={formik.values.businessvertical}
          onChange={formik.handleChange("businessvertical")}
          onBlur={formik.handleBlur("businessvertical")}
          //style={{ width: "165%", marginLeft: "-1%" }}
        >
          {verticals.map((group) => {
            if (group["n.type"] === "Company Vertical") {
              return (
                <MenuItem dense={true} value={group["n.vbeid"]}>
                  <ListItemText primary={group["n.name"]} />
                </MenuItem>
              );
            } else return null;
          })}
        </Select>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel required={true} shrink focused={true}>
          Short Description
        </InputLabel>

        <BootstrapInput
          multiline
          rows={3}
          rowsMax={5}
          required={true}
          value={formik.values.shortdescription}
          onChange={formik.handleChange("shortdescription")}
          onBlur={formik.handleBlur("shortdescription")}
          placeholder="Enter shortdescription"
        />

        <div className={classes.errorfields}>
          {formik.touched.shortdescription && formik.errors.shortdescription ? (
            <div className="error-message">
              {formik.errors.shortdescription}
            </div>
          ) : null}
        </div>
      </FormControl>
    </form>
  );
}
