import * as React from "react";
import { useParams } from "react-router-dom";

import { Grid, CircularProgress } from "@material-ui/core";

import { useQuery } from "@apollo/client";

import { GET_PROJECT } from "../../queries/query";

import { useStyles } from "./styles";

import AddDescForm from "./AddDescForm";

export default function AddShort() {
  const classes = useStyles();
  let { id } = useParams();

  const { loading, data, refetch } = useQuery(GET_PROJECT, {
    variables: { input: { vbeid: id }, fetchPolicy: "no-cache" },
  });

  return (
    <Grid container alignItems="center" className={classes.descriptionRoot}>
      {loading ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <AddDescForm projectData={data.getProjectByIDV1} refetch={refetch} />
      )}
    </Grid>
  );
}
