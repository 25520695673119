import { gql } from '@apollo/client'

export const getVendorList = gql`
  query vendorinfo($input: companyinput!) {
    listVendorsByCompany(input: $input) {
      id
      vbeid
      name
      image
      companyurl
    }
  }
`

export const addVendors = gql`
  mutation addVendorinfo($input: companyinput!) {
    addVendorsToCompany(input: $input) {
      responsestatus
    }
  }
`

export const removeVendor = gql`
  mutation remove($input: companyinput!) {
    removeVendorsFromCompany(input: $input) {
      responsestatus
    }
  }
`
