import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  textfield: {
    height: theme.spacing(100),
  },
  properties: {
    width: theme.spacing(50),
  },
  propertiestitle: {
    marginBottom: theme.spacing(2),
  },
  propsbuttongroups: {
    marginTop: theme.spacing(2),
  },
  propsbutton: {
    margin: theme.spacing(1),
  },
  helpicon: {
    fontSize: theme.spacing(1.5),
    cursor: "pointer",
  },
  checkbox: {
    "& span": {
      fontSize: theme.spacing(1.4),
    },
  },
  input: {
    display: "none",
  },
  download: {
    fontSize: "0.7rem",
    textTransform: "none",
  },
  downloadDiv: {
    display: "flex",
  },
  rtitle: {
    fontWeight: "bold",
  },
}));
