import { InputLabel, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { useStyles } from "../styles";

export default function TextComponent({ properties, onRemove, onEdit }) {
  const classes = useStyles();

  const { label, max } = properties;

  return (
    <div className={classes.textField}>
      <InputLabel>
        {label || "TextField"}

        <span className={classes.itemText}>
          <DeleteIcon onClick={onRemove} />
        </span>

        <span className={classes.itemText}>
          <EditIcon onClick={onEdit} />
        </span>
      </InputLabel>

      <TextField
        variant="outlined"
        fullWidth={true}
        disabled={true}
        maxRows={max}
      ></TextField>
    </div>
  );
}
