import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "30ch",
    },
  },
  round: {
    width: "100%",
  },
  errorfields: {
    color: "red",
    fontSize: "10px",
  },
  errormessage: {
    marginBottom: theme.spacing(2),
  },

  textfields: {
    width: "100%",
    marginTop: theme.spacing(1),
  },

  vertical: {
    width: "100%",
  },
  verticalfield: {
    width: "100%",
  },
  addNewBtn: {
    fontSize: "0.7rem",
    marginBottom: "8px",
    marginLeft: "8px",
    minWidth: "2px !important",
    padding: "4px",
    color: "#00000",
  },
  formMainDiv: {
    padding: "12px",

    "& .MuiDialog-paperWidthSm": {
      // maxWidth: "1000px",
      // width: "auto",
      maxHeight: "100vh !important",
    },
  },
  formTitle: {
    padding: "4px 16px",

    "& h2": {
      color: "#000000",
    },
  },

  autoCompleteText: {
    fontSize: "0.9rem",

    "& input": {
      height: "12px !important",
      width: "29px !important",
    },

    "& .MuiAutocomplete-tag": {
      margin: "2px",
      fontSize: "12px !important",
    },

    "& svg": {
      height: "14px !important",
      width: "16px !important",
    },

    "& .MuiChip-root": {
      height: "28px !important",
      width: "70% !important",
    },
  },

  formContent: {
    alignItems: "center",
    padding: "8px 8px 0px 8px",
    "& .MuiTypography-root": {
      fontSize: "0.85rem",
      padding: "0px 8px",
    },

    "& .MuiDialogContentText-root": {
      marginBottom: "4px !important",
    },

    "& .MuiInputBase-root input": {
      fontSize: "0.85rem",
    },

    "& textarea": {
      fontSize: "0.85rem",
    },
  },
  formControl: {
    marginBottom: "8px",
  },

  HelpIcon: {
    cursor: "pointer",

    "& svg": {
      fontSize: "16px",
      paddingTop: "10px",
    },
  },

  errorMsg: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#8B0000",
    paddingBottom: "8px",
  },

  addNewBtnComp: {
    marginRight: "8px",
    fontSize: "0.7rem",
    marginBottom: theme.spacing(1),
    //color: "#8e4785",
  },

  compareProducts: {
    alignContent: "center",
  },

  widthSet: {
    alignContent: "center",
    width: "fit-content",
  },

  croot: {
    "& > *": {
      margin: theme.spacing(1),
      width: "fit-content",
    },
  },
  evaluate: {
    display: "flex",
    justifyContent: "flex-end",
  },

  search: {
    display: "flex",
    justifyContent: "space-between",
  },
  inviteBtn: {
    padding: "0px",
  },

  title: {
    padding: "4px 12px",

    "& h2": {
      fontSize: "16px",
    },
  },
  stmt: {
    padding: "4px 2px 0px 10px",
    fontSize: "12px",
    margin: "8px 0",
    fontStyle: "italic",
  },

  helperText: {
    color: "#999",
    fontSize: "0.75rem",
    fontStyle: "italic",
  },
  divider: {
    color: "gery",
  },

  iconbtn: {
    padding: "2px",
  },

  addaward: {
    //display: "flex",
    width: "20ch !important",

    "& p": {
      fontSize: "10px",
    },
  },

  addawardbtn: {
    fontSize: "10px",
    padding: "0px !important",
    color: "teal",
    cursor: "pointer",
  },
  noUrlText: {
    color: "#232961",
    cursor: "pointer",
    marginBottom: "8px",
  },
}));
