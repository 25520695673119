import { useState } from "react";

import { useParams } from "react-router-dom";

import { GET_TEMPLATE_PRODUCTS_BY_PROJECT } from "../../queries/query";

import { useQuery } from "@apollo/client";

import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

import PreviewTemplate from "../templates/desing/preview";
import { useStyles } from "./styles";
import ServiceRequirementForm from "./ServiceRequirementForm";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const QuestionnaireTemplate = ({ disabled }) => {
  const { id } = useParams();
  const classes = useStyles();

  const { loading, data } = useQuery(GET_TEMPLATE_PRODUCTS_BY_PROJECT, {
    variables: { input: { vbeid: id, typelist: ["qa_template"] } },
  });

  return (
    <div className={classes.reqroot}>
      {data ? (
        data.getTemplatesByProjectID.length !== 0 ? (
          <PreviewTemplate
            id={data.getTemplatesByProjectID[0].vbeid}
            setSweight={() => {}}
            disabled={disabled}
          />
        ) : (
          <div className={classes.emptyText}>QnA Template is not selected</div>
        )
      ) : loading ? (
        <CircularProgress />
      ) : null}
    </div>
  );
};

export default function ViewServiceRequirements({ projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  const [value, setValue] = useState(0);

  const { data } = useQuery(GET_TEMPLATE_PRODUCTS_BY_PROJECT, {
    variables: { input: { vbeid: id, typelist: ["qa_template"] } },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={1} className={classes.paper}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={<Typography variant="caption">Requirements</Typography>}
            {...a11yProps(0)}
            className={classes.tab}
          />

          {data && data.getTemplatesByProjectID.length !== 0 && (
            <Tab
              label={<Typography variant="caption">Requirement Q&A</Typography>}
              {...a11yProps(1)}
              className={classes.tab}
            />
          )}
        </Tabs>

        <TabPanel value={value} index={0}>
          <ServiceRequirementForm projectData={projectData} />
        </TabPanel>

        {data && data.getTemplatesByProjectID.length !== 0 && (
          <TabPanel value={value} index={1}>
            <QuestionnaireTemplate disabled={true} />
          </TabPanel>
        )}
      </Paper>
    </div>
  );
}
