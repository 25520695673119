import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
  Typography,
  Drawer,
} from "@material-ui/core";

import { GET_PRODUCTS_BY_PROJECT, MOVE_TO_EVALUATE } from "../../queries/query";
import { useMutation, useQuery } from "@apollo/client";

import ViewVendorResponse from "./vendorResponse";
import ViewVendorResponseTable from "./vendorReponseTable";

import { VBRIDGEHUB_MEDIAURL } from "../../../../../constants";

import MessageWindow from "../messaging/messageLink";
import VbridgeAlert from "../../../../shared/alert";
import ConfirmAction from "../../../common/ConfirmAction";
import PrimaryButton from "../../../common/PrimaryButton";

import { useStyles } from "./styles";
import LoadingResult from "../../../../shared/LoadingResult";

const Vendor = ({
  product,
  checked,
  handleToggle,
  id,
  onMoveToEvaluate,
  projectData,
}) => {
  const classes = useStyles();

  const [state, setState] = useState(false);

  return (
    <Grid item xs={4} style={{ margin: "8px" }}>
      <Drawer anchor="right" open={state} onClose={setState}>
        <Grid container className={classes.heading}>
          <Grid item xs={10}>
            <Typography variant="h5">{product.productname}</Typography>
          </Grid>

          <Grid item xs={2}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                setState(false);
              }}
            >
              Close
            </Button>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <ViewVendorResponse
              productId={product.productvbeid}
              projectData={projectData}
            />
          </Grid>
        </Grid>
      </Drawer>

      <Card>
        <Grid container>
          <Grid item xs={2}>
            <Checkbox
              size="small"
              checked={checked.indexOf(product.productvbeid) !== -1}
              onChange={handleToggle(product.productvbeid)}
            />
          </Grid>

          <Grid item xs={10}>
            <CardActionArea
              onClick={() => {
                setState(true);
              }}
            >
              <CardContent style={{ padding: "8px" }}>
                <Grid container>
                  <Grid item xs={2}>
                    <Avatar
                      variant="square"
                      src={VBRIDGEHUB_MEDIAURL + "/" + product.image}
                      className={classes.productImage}
                    >
                      :(
                    </Avatar>
                  </Grid>

                  <Grid
                    item
                    xs={10}
                    style={{ textAlign: "left", paddingLeft: "8px" }}
                  >
                    <Grid xs={12}>
                      <Typography variant="subtitile2">
                        {product.companyname}
                      </Typography>
                    </Grid>

                    <Grid xs={12}>
                      <Typography variant="caption">
                        {product.productname}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Grid>

          <Grid item xs={12}>
            <CardActions>
              <PrimaryButton
                label={
                  product.role.includes("ADD_TO_EVALUATE")
                    ? "Evaluation in Progress"
                    : "Move to Evaluate"
                }
                size="small"
                variant="contained"
                onClick={
                  product.role.includes("ADD_TO_EVALUATE")
                    ? null
                    : onMoveToEvaluate
                }
                disabled={projectData.role.includes("ADDED_TO")}
              />

              <MessageWindow
                productname={product.productname}
                productId={product.productvbeid}
                id={id}
              />
            </CardActions>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default function ViewResponse({ projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  const [checked, setChecked] = useState([]);
  const [state, setState] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const { data, refetch } = useQuery(GET_PRODUCTS_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id, type: "SUBMITTED" } },
  });

  const [moveToEvaluate, { data: evaluateData, loading: evaluateLoading }] =
    useMutation(MOVE_TO_EVALUATE, {
      onCompleted: (e) => {
        setConfirmOpen(false);
        refetch();
      },
    });

  const onMoveToEvaluate = () => {
    moveToEvaluate({
      variables: {
        input: { vbeid: id, productvbeid: selectedProduct.productvbeid },
      },
    });
  };

  const onProductSelect = (product) => {
    setSelectedProduct(product);
    setConfirmOpen(true);
  };

  const onCancel = () => {
    setConfirmOpen(false);
  };

  return (
    <div className={classes.root}>
      <VbridgeAlert
        open={evaluateData}
        message="Product moved to evaluation successfully"
        severity="success"
      />

      <ConfirmAction
        open={confirmOpen}
        action="evaluate"
        onConfirm={onMoveToEvaluate}
        onCancel={onCancel}
        loading={evaluateLoading}
      />

      <Drawer anchor="right" open={state} onClose={setState}>
        <Grid container className={classes.heading}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                setState(false);
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ViewVendorResponseTable
            products={checked}
            projectData={projectData}
          />
        </Grid>
      </Drawer>

      <Grid container style={{ margin: "8px" }}>
        {data && data.getProductsByProjectIDV1.length !== 0 && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                setState(true);
              }}
              disabled={checked.length < 2}
            >
              Compare
            </Button>
          </Grid>
        )}

        {data ? (
          data.getProductsByProjectIDV1.length !== 0 ? (
            data.getProductsByProjectIDV1.map((product, index) => {
              return (
                <Vendor
                  key={index}
                  product={product}
                  id={id}
                  handleToggle={handleToggle}
                  checked={checked}
                  onMoveToEvaluate={() => onProductSelect(product)}
                  projectData={projectData}
                />
              );
            })
          ) : (
            <Grid item xs={12}>
              <div
                style={{
                  textAlign: "center",
                  color: "#999",
                  fontStyle: "italic",
                  marginTop: "16px",
                }}
              >
                No Suppliers have responded yet
              </div>
            </Grid>
          )
        ) : (
          <LoadingResult />
        )}
      </Grid>
    </div>
  );
}
