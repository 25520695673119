import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import {
  GET_PRODUCTS_BY_PROJECT,
  GET_SECTIONS,
  GET_TEMPLATE_BY_PROJECT,
  MOVE_TO_CONTRACT,
} from "../../queries/query";

import { useMutation, useQuery } from "@apollo/client";

import RSection from "../../forms/others/evaluateSummaryTablesesection";

import VbridgeAlert from "../../../../shared/alert";
import ConfirmAction from "../../../common/ConfirmAction";
import PrimaryButton from "../../../common/PrimaryButton";
import { useStyles } from "./styles";

const EvaluateSectionForm = (props) => {
  const classes = useStyles();

  const [totalValue, setTotalValue] = useState(false);
  const [sectionTotal, setsectionTotal] = useState([]);

  const { loading, error, data } = useQuery(GET_SECTIONS, {
    variables: { input: { vbeid: props.templateId } },
  });

  let sectionTotalData = [];

  const getSectionValue = (sectionTotalWeight, sectionId, productId) => {
    sectionTotalData.push({
      total: sectionTotalWeight,
      sectionId: sectionId,
      productId,
    });

    if (
      sectionTotalData.length === data.listSection.length * 2 &&
      !totalValue
    ) {
      setsectionTotal(sectionTotalData);
      setTotalValue(true);
      props.setSectionTotals(sectionTotalData);
    }
  };

  const getSectionTotalValue = (sectionId, productId) => {
    let singleSection = sectionTotal.filter((data) => {
      return data.sectionId === sectionId && productId === data.productId;
    });

    return singleSection[0].total;
  };

  return (
    <>
      {data
        ? data.listSection.map((section) => {
            let obj = JSON.parse(section.metadata);

            return (
              <>
                <TableRow selected={true}>
                  <TableCell className={classes.tableIndexCell}>
                    {obj.title}
                  </TableCell>

                  {props.products.map((product) => {
                    return (
                      <TableCell
                        style={{ textAlign: "center", fontWeight: "bold" }}
                      >
                        {totalValue &&
                          getSectionTotalValue(
                            section.id,
                            product.productvbeid
                          )}
                      </TableCell>
                    );
                  })}
                </TableRow>

                {props.products.map((product) => {
                  return (
                    <RSection
                      sid={section.id}
                      projectId={props.projectId}
                      product={product}
                      type="evaluate_template"
                      sectionMeta={obj}
                      products={props.products}
                      getSectionTotal={getSectionValue}
                    />
                  );
                })}
              </>
            );
          })
        : null}
    </>
  );
};

function EvaluationSections(props) {
  const classes = useStyles();

  const { loading, error, data, refetch } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    variables: { input: { vbeid: props.id, typelist: ["evaluate_template"] } },
  });

  return (
    <>
      {data ? (
        data.getTemplatesByProjectID.length !== 0 ? (
          <EvaluateSectionForm
            templateId={data.getTemplatesByProjectID[0].vbeid}
            projectId={props.id}
            products={props.products}
            setSectionTotals={props.setSectionTotals}
          />
        ) : (
          <div className={classes.emptyText}>
            Response Template is not selected
          </div>
        )
      ) : null}
    </>
  );
}

export default function ViewVendorEvaluationSummaryTable({ projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [sectionTotals, setSectionTotals] = useState([]);

  const { loading, error, data, refetch } = useQuery(GET_PRODUCTS_BY_PROJECT, {
    variables: { input: { vbeid: id } },
  });

  let evaluateProducts = [];

  if (data) {
    evaluateProducts = data.getProductsByProjectIDV1.filter((product) => {
      return product.role.includes("ADD_TO_EVALUATE");
    });
  }

  const [
    moveToContract,
    { data: contractData, loading: contractLoading, error: contractError },
  ] = useMutation(MOVE_TO_CONTRACT, {
    onCompleted: (e) => {
      setConfirmOpen(false);
      refetch();
    },
  });

  const onMovetoContract = () => {
    moveToContract({
      variables: {
        input: { vbeid: id, productvbeid: selectedProduct.productvbeid },
      },
    });
  };

  const onProductSelect = (product) => {
    setSelectedProduct(product);
    setConfirmOpen(true);
  };

  const onCancel = () => {
    setConfirmOpen(false);
  };

  const getTotalScore = (productId) => {
    let finalScore = 0;

    let productSectionTotal = sectionTotals.filter((value) => {
      return value.productId === productId;
    });

    productSectionTotal.forEach((value) => {
      finalScore = finalScore + value.total;
    });

    return finalScore.toFixed(2);
  };

  return (
    <div className={classes.evaluateRoot}>
      <VbridgeAlert
        open={contractData}
        message="Product moved to contract successfully"
        severity="success"
      />

      {evaluateProducts.length === 0 ? (
        <div className={classes.emptyText}>Evaluations are not completed.</div>
      ) : (
        <>
          <ConfirmAction
            open={confirmOpen}
            action="contract"
            onConfirm={onMovetoContract}
            onCancel={onCancel}
          />

          <Paper className={classes.evaluateRoot}>
            <TableContainer className={classes.container}>
              <Table stickyHeader={true} aria-label="vendors">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableIndexCell}></TableCell>

                    {data
                      ? evaluateProducts.map((product, index) => {
                          return (
                            <TableCell
                              key={index}
                              className={classes.productNameCell}
                            >
                              {product.productname}

                              {!projectData.role.includes("ADDED_TO") && (
                                <PrimaryButton
                                  label={
                                    product.role.includes("ADD_TO_CONTRACT")
                                      ? "Added to Contract"
                                      : "Move to Contract"
                                  }
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    product.role.includes("ADD_TO_CONTRACT")
                                      ? null
                                      : onProductSelect(product)
                                  }
                                />
                              )}
                            </TableCell>
                          );
                        })
                      : null}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data ? (
                    <>
                      <TableRow>
                        <TableCell className={classes.tableIndexCell}>
                          Total Score
                        </TableCell>

                        {sectionTotals &&
                          evaluateProducts.map((product) => {
                            return (
                              <TableCell style={{ textAlign: "center" }}>
                                <Chip
                                  label={getTotalScore(product.productvbeid)}
                                  color="primary"
                                />
                              </TableCell>
                            );
                          })}
                      </TableRow>

                      <EvaluationSections
                        id={id}
                        products={evaluateProducts}
                        setSectionTotals={setSectionTotals}
                      />
                    </>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </div>
  );
}
