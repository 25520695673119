import * as React from "react";

import { Grid, CircularProgress } from "@material-ui/core";

import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { GET_PROJECT } from "../../../queries/query";

import { useStyles } from "../styles";

import ServiceProjectForm from "./serviceProjectForm";

export default function ServiceProject({ type }) {
  const classes = useStyles();

  let { id } = useParams();

  const { loading, data } = useQuery(GET_PROJECT, {
    variables: {
      input: { vbeid: id },
    },
    skip: id === "new",
    fetchPolicy: "network-only",
  });

  return (
    <Grid
      container
      alignItems="center"
      className={type === "review" ? "" : classes.projectFormRoot}
    >
      {loading ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Grid item md={12}>
          <ServiceProjectForm data={data?.getProjectByIDV1} type={type} />
        </Grid>
      )}
    </Grid>
  );
}
