import { useState } from "react";
import { useHistory } from "react-router";

import { useApolloClient } from "@apollo/client";

import { Typography } from "@material-ui/core";

import CategoryResultCard from "./CategoryResultCard";
import SearchResultCard from "./SearchResultCard";

import Slider from "react-slick";

import { useStyles } from "./styles";

import "./slick.css";
import "slick-carousel/slick/slick-theme.css";

import AddToProject from "../project/addproducttoproject";
import { userInfoQuery } from "../companies/Company2.0/query";
import CompareProduct from "../project/CompareProduct";

export default function ProductsSearchResults({
  products,
  type,
  open,
  rowType,
  onProductCardClick,
  searchContext,
  categoryName,
  onNextClick,
  categoryId,
  toggleValue,
  mainDrawerClick,
}) {
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();

  let evaluateAccess = false;

  const [values, setValues] = useState([]);
  const [checkValue, setCheckValue] = useState(false);
  const [checked, setChecked] = useState([]);

  const user = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  if (
    user?.getLoginUserInfo?.roletype === "buyer" ||
    user?.getLoginUserInfo?.isVbridgeUser === "true"
  ) {
    evaluateAccess = true;
  }

  let settings = {
    dots: false,
    slidesToShow: products.length < 5 ? products.length : 5,
    slidesToScroll: 1,
    infinite: false,
    centerMode: false,
  };

  if (open) {
    settings.slidesToShow = products.length < 4 ? products.length : 4;
  }

  const handleClick = (product) => {
    onProductCardClick(product);
  };

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const checkedValues = [];

    if (currentIndex === -1) {
      newChecked.push(value);

      newChecked.map((value) => {
        return checkedValues.push(value.vbeid);
      });
    } else {
      newChecked.splice(currentIndex, 1);

      newChecked.map((value) => {
        return checkedValues.push(value.vbeid);
      });
    }

    setChecked(newChecked);
    setValues(checkedValues);

    if (currentIndex === -1) {
      setCheckValue(true);
    } else if (currentIndex >= 1) {
      setCheckValue(true);
    } else if (newChecked.length < 1) {
      setCheckValue(false);
    }
  };

  const onSliderEdge = (currentSlide, nextSlide) => {
    if (currentSlide < nextSlide && type !== "product") {
      if (currentSlide % settings.slidesToShow === 0) {
        onNextClick();
      }
    }
  };

  return (
    <div
      className={
        type === "product" ? classes.productResultDiv : classes.productDiv
      }
    >
      <div className={classes.rowHeading}>
        <Typography className={classes.productTitle}>
          {type === "category" || type === "group"
            ? rowType === "trending"
              ? `${searchContext} by Trending Groups - ${categoryName}`
              : rowType === "interest"
              ? `${searchContext} by Areas of Interest - ${categoryName}`
              : `${searchContext} related to ${products[0].groupname} > ${products[0].topiclist[0]?.name}`
            : `${searchContext} matching your search`}

          {(type === "category" || type === "group") && (
            <span
              className={classes.detailText}
              onClick={(e) => {
                mainDrawerClick(e, "close");

                if (rowType === "interest" || rowType === "trending") {
                  history.push("group/" + toggleValue + "/" + categoryId);
                } else {
                  history.push(
                    "topic/" + toggleValue + "/" + products[0].topicvbeid
                  );
                }
              }}
            >
              Detailed Listing
            </span>
          )}
        </Typography>

        {evaluateAccess && (
          <div className={classes.compareadd}>
            <CompareProduct
              products={checked}
              disable={
                checked.length >= 2 && checked.length <= 4 ? false : true
              }
              searchContext={searchContext}
            />

            <AddToProject
              productvbeid={values}
              disable={checkValue ? false : true}
              searchContext={searchContext}
              userCompany={user?.getLoginUserInfo.companyvbeid}
              checkedObj={checked}
            />
          </div>
        )}
      </div>

      {products.length !== 0 ? (
        <div className={classes.container}>
          <div className={classes.slickContainer}>
            <Slider
              {...settings}
              beforeChange={(currentSlide, nextSlide) =>
                onSliderEdge(currentSlide, nextSlide)
              }
            >
              {products.map((product) => {
                return type === "category" || type === "group" ? (
                  <CategoryResultCard
                    productData={product}
                    type={type}
                    onProductCardClick={() => {
                      handleClick(product);
                    }}
                    checked={checked.indexOf(product) !== -1}
                    onClick={handleToggle}
                    evaluateAccess={evaluateAccess}
                    userCompany={user?.getLoginUserInfo.companyvbeid}
                    mainDrawerClick={mainDrawerClick}
                  />
                ) : (
                  <SearchResultCard
                    product={product}
                    type={type}
                    onProductCardClick={onProductCardClick}
                    checked={values.indexOf(product) !== -1}
                    onClick={handleToggle}
                    evaluateAccess={evaluateAccess}
                    userCompany={user?.getLoginUserInfo.companyvbeid}
                    toggleValue={toggleValue}
                    mainDrawerClick={mainDrawerClick}
                  />
                );
              })}
            </Slider>
          </div>
        </div>
      ) : null}
    </div>
  );
}
