import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif !important"].join(
      ","
    ),
    marginTop: theme.spacing(8),
  },

  loadStyle: {
    marginLeft: theme.spacing(54),
    marginTop: theme.spacing(5),
  },

  drawerOpenRoot: {
    marginLeft: 250,
    marginTop: theme.spacing(8),
  },

  heading: {
    fontWeight: "600",
    marginLeft: theme.spacing(3),
  },

  drawerPaper: {
    width: 640,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },

  paper: {
    //width: "90%",
    //height: "50%",
    //marginTop: theme.spacing(17),
    //marginLeft: theme.spacing(5.5),
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(2),
    margin: theme.spacing(3),
  },
  table: {
    paddingLeft: theme.spacing(1),
    "& th": {
      fontSize: theme.spacing(1.6),
      padding: "2px !important",
      textAlign: "center !important",
    },
    "& td": {
      padding: "2px !important",
      fontSize: theme.spacing(1.6),
      textAlign: "center !important",
    },
  },
  menuitem: {
    fontSize: theme.spacing(1.6),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  iconstyle: {
    marginRight: theme.spacing(1.6),
  },
  docicon: {
    marginRight: theme.spacing(2.5),
  },

  // engagement:{

  //   "& th":{

  //   marginLeft:"24px !important"}
  // }
}));
