import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { useState } from "react";

import CompanySearch from "../../shared/companySearch";
import InputField from "../common/InputField";

import MapCompanyDailog from "./MapCompanyDailog";

import { useStyles } from "./styles";

export default function CreateCompanyProfile({
  formik,
  userInfo,
  saveCompanyProfile,
  claimProfile,
  setStepIndexOnClick,
  mapedCompany,
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [name, setCompanyName] = useState("");
  const [domain, setCompanyDomain] = useState("");
  const [companyPresent, setCompanyPresent] = useState(true);
  const [dailogBox, setDailogBox] = useState(true);
  const [submit, setSubmit] = useState(true);

  const setName = (text) => {
    formik.values.companyName = text;
    formik.handleChange("companyName");
    setCompanyName(text);
  };

  const setDomain = (text) => {
    formik.values.companyURL = text;
    formik.handleChange("companyURL");
    setCompanyDomain(text);
  };

  const setCompany = (value) => {
    setCompanyPresent(value);
  };

  const onCreateProfile = () => {
    //setSubmit(false);
    // console.log("formik.errors.companyName", formik.errors.companyName);
    // console.log("formik.errors.companyURL", formik.errors.companyURL);

    if (formik.errors.companyName || formik.errors.companyURL) {
      setSubmit(false);
    }

    // if (!formik.errors.companyName && !formik.errors.companyURL) {
    //   setSubmit(true);
    // }

    if (!formik.errors.companyName && !formik.errors.companyURL) {
      saveCompanyProfile();
      setLoading(true);
      setDailogBox(true);
    }

    console.log("submit", submit);
  };

  const onClose = () => {
    setDailogBox(false);
  };

  // console.log("formik", formik);
  // console.log("formik.errors.companyName", formik.errors.companyName);
  // console.log("formik.errors.companyURL", formik.errors.companyURL);

  return (
    <>
      <form>
        <FormGroup className={classes.createFormGroup}>
          <div className={classes.formDivLeft}>
            <FormControl fullWidth={true} className={classes.formControl}>
              <InputLabel
                required={true}
                shrink
                htmlFor="company-name-search"
                focused={true}
              >
                Search Your Company
              </InputLabel>

              <CompanySearch
                id="company-name-search"
                setName={setName}
                setDomain={setDomain}
                setCompany={setCompany}
              />
            </FormControl>

            {(formik.values.companyName !== "" || !companyPresent) && (
              <>
                <>
                  <FormControl fullWidth={true} className={classes.formControl}>
                    <InputField
                      label="Company Name"
                      onChange={formik.handleChange("companyName")}
                      value={formik.values.companyName || name}
                      //disabled={!companyPresent ? false : true}
                    />
                  </FormControl>

                  {/* {formik.errors.companyName && !submit ? ( */}
                  {!submit ? (
                    <div className={classes.errorMsg}>
                      {formik.errors.companyName}
                    </div>
                  ) : null}
                </>

                <>
                  <FormControl fullWidth={true} className={classes.formControl}>
                    <InputField
                      label="Website URL"
                      onChange={formik.handleChange("companyURL")}
                      placeholder="https://vbridgrhub.com"
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">
                      //       https://
                      //     </InputAdornment>
                      //   ),
                      // }}
                      value={formik.values.companyURL || domain}
                      //disabled={!companyPresent ? false : true}
                    />
                  </FormControl>
                  {/* {formik.errors.companyURL && !submit ? ( */}
                  {!submit ? (
                    <div className={classes.errorMsg}>
                      {formik.errors.companyURL}
                    </div>
                  ) : null}
                </>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={onCreateProfile}
                  className={classes.stepperButtons}
                  // type="submit"
                  disabled={
                    !companyPresent
                      ? formik.values.companyURL.length >= 12 &&
                        formik.values.companyName.length >= 1
                        ? false
                        : true
                      : false
                  }
                >
                  Map to Company
                </Button>
              </>
            )}
          </div>
        </FormGroup>
      </form>

      {mapedCompany && (
        <MapCompanyDailog
          dopen={dailogBox}
          onClose={onClose}
          isUser={userInfo.roletype}
          companyName={name}
          saveCompanyProfile={saveCompanyProfile}
          claimProfile={claimProfile}
          setStepIndexOnClick={setStepIndexOnClick}
          mapedCompany={mapedCompany}
        />
      )}
    </>
  );
}
