import * as React from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import { Grid, TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root:{
        marginTop:theme.spacing(1)
    },
    textfield:{
        height:theme.spacing(100)
    }
}))





export default function TextShortItem(props)
{

    const classes = useStyles();
    return(
     
        <Grid container alignItems="center" className={classes.root}>
            <Grid item xs={8}>
            <Typography variant="caption" gutterBottom > {props.name} </Typography>

            </Grid>
            <Grid item xs={4}>
            <TextField  id="filled-full-width"  placeholder={props.placeholder} fullWidth  variant="outlined"  size="small" />
            </Grid>

        </Grid>
    )


}