import React from "react";

import { Grid } from "@material-ui/core";

import { useStyles } from "./styles";

export default function BlockedUser() {
  const classes = useStyles();

  return (
    <div className={classes.blockedUserRoot}>
      <Grid container className={classes.blockedText}>
        <Grid md={12} item>
          Your account has been blocked. Please contact your company admin for
          more details.
        </Grid>
      </Grid>
    </div>
  );
}
