import React from "react";
import { useHistory } from "react-router-dom";

import { Waypoint } from "react-waypoint";

import { gql, useQuery, useMutation, useApolloClient } from "@apollo/client";

import { makeStyles } from "@material-ui/core/styles";
import { XGrid, LicenseInfo } from "@material-ui/x-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import { Icon } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";

import SimpleErrorSnackbar from "./errorsnackbar";
import SimpleSnackbar from "./deletesnackbar";

LicenseInfo.setLicenseKey(
  "56ae5c7b59f46c73b6b7004f16cddf6fT1JERVI6MjM5MzQsRVhQSVJZPTE2NTA1MTg0NDUwMDAsS0VZVkVSU0lPTj0x"
);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1%",
  },
  deleteicon: {
    cursor: "pointer",
  },
}));

// function CustomLoadingOverlay() {
//   return (
//     <GridOverlay>
//       <div style={{ position: 'absolute', top: 0, width: '100%' }}>
//         <LinearProgress />
//       </div>
//     </GridOverlay>
//   );
// }

export default function GroupTable() {
  const classes = useStyles();
  const client = useApolloClient();
  const [limit, setLimit] = React.useState(10);
  const [defaultData, setDefaultData] = React.useState([]);
  let history = useHistory();
  ////delete Mutation

  const deletegroup = gql`
    mutation delete($input: id!) {
      removeGroup(input: $input) {
        id
      }
    }
  `;

  const [removegroupmutation, { error: mutationError, data: removeGroup }] =
    useMutation(deletegroup, {
      onCompleted: (e) => {
        UpdateDeleteCache(e);
      },
    });

  function handleDelete(e) {
    if (e.field === "action") {
      var r = window.confirm("Are you sure you want to delete group ?");
      if (r) {
        removegroupmutation({
          variables: {
            input: {
              vbeid: e.id,
            },
          },
        });
      }
    }
  }

  //////update cache for delete group
  function UpdateDeleteCache(e) {
    const { listGroup } = client.readQuery({
      query: listgroups,
      variables: { input: {} },
    });
    let listGroupCache = { ...listGroup };
    listGroupCache.total = listGroupCache.total - 1;
    listGroupCache.groups = listGroupCache.groups.filter(
      (group) => group.id !== e.removeGroup.id
    );

    client.writeQuery({
      query: listgroups,
      data: {
        listGroup: listGroupCache,
      },
      variables: { input: {} },
    });
  }

  const columns = [
    {
      field: "name",
      headerName: "Group Name",
      width: 200,
      renderCell: (params) => (
        <ListItem
          style={{ cursor: "pointer" }}
          key={params.getValue("id")}
          divider
          dense
          onClick={() => {
            history.push("/group/" + params.getValue("id"));
          }}
        >
          {params.value}
        </ListItem>
      ),
    },
    { field: "type", headerName: "Type", width: 200 },

    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <strong>
          <Icon className={classes.deleteicon}>
            <DeleteIcon />
          </Icon>
        </strong>
      ),
    },
  ];

  const listgroups = gql`
    query listing($offset: Int, $limit: Int) {
      listGroup(input: { offset: $offset, limit: $limit }) {
        total
        groups {
          alias
          id
          name
          type
        }
      }
    }
  `;

  const { loading, data, fetchMore } = useQuery(listgroups, {
    variables: {
      offset: 0,
      limit: limit,
    },
  });

  return (
    <div className={classes.root}>
      {mutationError && <SimpleErrorSnackbar />}
      {removeGroup ? <SimpleSnackbar /> : null}

      <>
        <XGrid
          density="compact"
          autoHeight={true}
          disableColumnMenu={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          sortingmode="server"
          loading={loading}
          hideFooterRowCount={false}
          onCellClick={handleDelete}
          rowCount={limit}
          rows={loading ? defaultData : data.listGroup.groups}
          columns={columns.map((column) => ({
            ...column,
            sortable: false,
          }))}
        />

        <Waypoint
          onEnter={() => {
            if (data) {
              const currentLength = data.listGroup.groups.length;
              fetchMore({
                variables: {
                  offset: currentLength,
                  limit: 10,
                },
              }).then((fetchMoreResult) => {
                setDefaultData(data.listGroup.groups);
                setLimit(
                  currentLength + fetchMoreResult.data.listGroup.groups.length
                );
              });
            }
          }}
        />
      </>
    </div>
  );
}
