import { useLazyQuery } from "@apollo/client";

import { Card, Grid, SwipeableDrawer, Typography } from "@material-ui/core";

import { getSigned } from "../../../CaseStudy2.0/casestudy/query";

import JDEditor from "../createProject/ServiceProject/JDEditor";

import FormElement from "../requirements/FormElement";

import { useStyles } from "../requirements/styles";

export default function JDSlider({ projectData, jdSliderOpen, onClose }) {
  const classes = useStyles();

  let urlSplit = "";
  let fileNameSplit = "";
  let fileName = "";

  if (projectData.jobdescriptionfile && projectData.jobdescriptionfile !== "") {
    urlSplit = projectData.jobdescriptionfile.split("/");
    fileNameSplit = urlSplit[5].split("?");
    fileName = fileNameSplit[0];
  }

  const [getUrl] = useLazyQuery(getSigned, {
    onCompleted: (response) => {
      window.open(response.getSignedURL.signedurl);
    },
  });

  const onFileClick = () => {
    let fileUrlSplit = projectData.jobdescriptionfile.split("/");
    let fileName = fileUrlSplit[5].split("?");

    getUrl({
      variables: {
        input: {
          method: "get",
          filetype: "file",
          filename: fileName[0],
          vbeid: fileUrlSplit[3],
        },
      },
    });
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={jdSliderOpen}
      className={classes.actionDrawer}
      onClose={onClose}
    >
      <Card square className={classes.actionCard} elevation={0}>
        <Typography style={{ fontWeight: "bold", marginLeft: "12px" }}>
          Job Description
        </Typography>

        <Grid item md={11} className={classes.jdSection}>
          <div
            style={{
              border: "1px solid #999",
              borderRadius: "5px",
              marginTop: "8px",
            }}
          >
            <JDEditor
              data={projectData.jobdescription}
              editorId="jdId"
              isReadOnly={true}
              saveMethod={() => {}}
              edit={false}
            />
          </div>

          {projectData.jobdescriptionfile &&
          projectData.jobdescription !== null ? (
            <div className={classes.interSection}>
              <FormElement
                name="Job Description File"
                value={fileName}
                type="file"
                onFileClick={onFileClick}
              />
            </div>
          ) : null}
        </Grid>
      </Card>
    </SwipeableDrawer>
  );
}
