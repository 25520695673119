import { useState } from "react";
import { useHistory } from "react-router";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";

import { useFormik } from "formik";

import { useMutation, useApolloClient } from "@apollo/client";

import VbridgeAlert from "../../shared/alert";

import { updateProfile, userInfoQuery } from "./query";
import { createCompany } from "../companies/Company2.0/query";

import { useStyles } from "./styles";

import { validationSchema } from "./helper";

import OnboardingSteps from "../common/OnboardingSteps";
import { companyValidationSchema } from "../common/companyValidationSchema";

import PersonalProfileForm from "./PersonalProfileForm";
import CompanyProfile from "./CompanyProfile";
import StepperButtonGroup from "./StepperButtonGroup";
import Marketplace from "./Marketplace";
import PrimaryButton from "../common/PrimaryButton";

export default function Registration({ isProfile, drawerOpen }) {
  const history = useHistory();
  const classes = useStyles();
  const client = useApolloClient();

  const currentIndex = history?.location?.state?.index;

  const [stepIndex, setStepIndex] = useState(currentIndex || 0);

  if (currentIndex || currentIndex === 0) {
    let state = { ...history.location.state };

    if (stepIndex !== currentIndex) {
      setStepIndex(currentIndex);
    }

    delete state.index;
    history.replace({ ...history.location, state });
  }

  const [groupsEdited, setGroupsEdited] = useState(false);
  const [mapedCompany, setMappedCompany] = useState(false);

  const [updateProfileMutation, { loading, error, data }] = useMutation(
    updateProfile,
    {
      onCompleted: (e) => {
        if (showSteppers) {
          updateUserProfileCache(e);
          setStepIndex(1);
        }
      },
    }
  );

  const [createCompanyMutation, { loading: createCompanyLoading }] =
    useMutation(createCompany, {
      onCompleted: (e) => {
        updateUserProfileCache(e);
        setMappedCompany(true);
      },
      onError: (error) => {
        window.alert(error.graphQLErrors[0].message);
      },
    });

  const user = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  const { name, designation, jobrole } = user?.getLoginUserInfo;

  const showSteppers = user?.getLoginUserInfo
    ? user?.getLoginUserInfo.isProfileUpdated !== "true" ||
      user?.getLoginUserInfo.companyvbeid === ""
    : //|| user?.getLoginUserInfo.onBoardingCompleted === false
      user?.isProfileUpdated !== "true" || user?.companyvbeid === "";

  const updateUserProfileCache = (e) => {
    let data = { ...e.updateProfile };

    if (stepIndex === 1) {
      data = { ...e.addMyCompany };
    }

    client.writeQuery({
      query: userInfoQuery,
      data: {
        getLoginUserInfo: data,
      },
      variables: { input: {} },
    });
  };

  let formik = useFormik({
    initialValues: {
      Name: name,
      Designation: designation,
      Role: jobrole,
    },

    validationSchema: validationSchema,
  });

  let companyFormik = useFormik({
    initialValues: {
      companyName: "",
      companyURL: "",
      agreed: true,
    },

    validationSchema: companyValidationSchema,
  });

  const saveProfileClick = () => {
    const { Name, Designation, Role } = formik.values;

    if (!formik.dirty && !groupsEdited) {
      setStepIndex(1);
    } else {
      updateProfileMutation({
        variables: {
          input: {
            name: Name,
            designation: Designation,
            jobrole: Role,
            roletype: "buyer",
          },
        },
      });
    }
  };

  const saveCompanyProfile = () => {
    const { companyName, companyURL } = companyFormik.values;

    if (user?.getLoginUserInfo.companyvbeid !== "") {
      setStepIndex(2);
    } else {
      createCompanyMutation({
        variables: {
          input: { name: companyName, companyurl: companyURL },
        },
      });
    }
  };

  const onBackClick = () => {
    let newStepIndex = stepIndex !== 0 ? stepIndex - 1 : 0;
    setStepIndex(newStepIndex);
  };

  const onNextClick = () => {
    history.push("/");
  };

  if (stepIndex === 2) {
    history.push("/agreements");
  }

  const onGroupsSelect = () => {
    setGroupsEdited(true);
  };

  const setStepIndexOnClick = (index) => {
    setStepIndex(index);
  };

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {user?.getLoginUserInfo.roletype !== "buyer" &&
      user?.getLoginUserInfo.isValidLicense !== "false" ? (
        <div style={{ padding: "24px" }}>
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            style={{ padding: "24px" }}
          >
            <DialogContent>
              <Typography gutterBottom className={classes.heading}>
                Welcome to vBridge Hub – Your Enterprise Technology Sourcing
                Marketplace!!
              </Typography>

              <Typography gutterBottom className={classes.info}>
                You are currently on a 48 hour trial version of the platform.
                During this period, you can discover various Technology Products
                and Services suppliers using our group and category listing.
                Alternatively, you can also use keyword based or advanced AI
                based search functionality to identify potential suppliers. We
                strongly recommend to onboard your organization as a buyer
                entity in order to engage, evaluate and contract with suppliers
                using vBridge Hub.
              </Typography>

              <Typography gutterBottom className={classes.info}>
                For a guided demo of the platform and its benefits, click on the
                support button on the top menu bar and click on “Schedule a
                Demo”.
              </Typography>

              <Typography gutterBottom className={classes.info}>
                We look forward to your valuable feedback and also to onboard
                your organization as a valuable user of the platform.
              </Typography>
            </DialogContent>

            <DialogActions style={{ padding: "0px 24px 24px 0px" }}>
              <PrimaryButton
                label="Okay"
                variant="contained"
                onClick={handleClose}
              />
            </DialogActions>
          </Dialog>
        </div>
      ) : null}

      <div className={drawerOpen ? classes.drawerOpenRoot : ""}>
        <div className={classes.stepperRoot}>
          {showSteppers && <OnboardingSteps step={stepIndex} />}
        </div>

        <Box m={2}>
          <Grid container spacing={3} style={{ justifyContent: "center" }}>
            <Grid item xs={12} sm={10}>
              <div className={classes.stepTitleMessage}>
                {stepIndex === 0 && (
                  <div className={classes.formHeaderText}>
                    {user?.getLoginUserInfo.isProfileUpdated === "true"
                      ? "This is your personal profile. Please keep this profile updated."
                      : 'Let\'s get started by creating your Personal Profile. This section can be updated from time to time except for the field – "I am".'}
                  </div>
                )}

                {stepIndex === 1 &&
                user?.getLoginUserInfo.companyvbeid === "" ? (
                  <div className={classes.formHeaderText}>
                    Based on domain from your email id, we do not see your
                    company on vBridge Hub. You can search and map yourself to
                    your company{" "}
                  </div>
                ) : (
                  <div className={classes.formSubText}></div>
                )}

                {showSteppers && (
                  <StepperButtonGroup
                    loading={loading}
                    createCompanyLoading={createCompanyLoading}
                    stepIndex={stepIndex}
                    firstTimeUser={user?.getLoginUserInfo.isProfileUpdated}
                    formikValid={formik.isValid}
                    companyFormikValid={companyFormik.isValid}
                    formikDirty={formik.dirty}
                    companyFormikDirty={companyFormik.dirty}
                    onBackClick={onBackClick}
                    saveProfileClick={saveProfileClick}
                    saveCompanyProfile={saveCompanyProfile}
                    nextClick={onNextClick}
                    companyVbeid={user?.getLoginUserInfo.companyvbeid}
                    showSteppers={showSteppers}
                  />
                )}
              </div>

              <Paper className={classes.formPaper}>
                {stepIndex === 0 && (
                  <PersonalProfileForm
                    formik={formik}
                    data={user?.getLoginUserInfo}
                    updateUserProfileCache={updateUserProfileCache}
                    onGroupsSelect={onGroupsSelect}
                  />
                )}

                {stepIndex === 1 && (
                  <CompanyProfile
                    formik={companyFormik}
                    saveCompanyProfile={saveCompanyProfile}
                    setStepIndexOnClick={setStepIndexOnClick}
                    mapedCompany={mapedCompany}
                  />
                )}

                {stepIndex === 2 && <Marketplace />}

                {data ? (
                  <VbridgeAlert
                    severity="success"
                    open={true}
                    message="Your request has been completed successfully "
                  />
                ) : null}

                {error ? (
                  <VbridgeAlert
                    severity="error"
                    open={true}
                    message="Something went wrong"
                  />
                ) : null}
              </Paper>

              <StepperButtonGroup
                loading={loading}
                createCompanyLoading={createCompanyLoading}
                stepIndex={stepIndex}
                firstTimeUser={user?.getLoginUserInfo.isProfileUpdated}
                formikValid={formik.isValid}
                companyFormikValid={companyFormik.isValid}
                formikDirty={formik.dirty}
                companyFormikDirty={companyFormik.dirty}
                onBackClick={onBackClick}
                saveProfileClick={saveProfileClick}
                saveCompanyProfile={saveCompanyProfile}
                nextClick={onNextClick}
                companyVbeid={user?.getLoginUserInfo.companyvbeid}
                showSteppers={showSteppers}
                groupsEdited={groupsEdited}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
