import { useParams } from "react-router-dom";

import {
  Paper,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

import PropTypes from "prop-types";

import {
  GET_PRODUCTS_BY_PROJECT,
  GET_SECTIONS,
  GET_TEMPLATE_BY_PROJECT,
} from "../../queries/query";

import { useQuery } from "@apollo/client";

import RSection from "../../forms/others/responseTablesesection";
import QASection from "../../forms/others/qaTablesesection";

import TimeAndMoneyList from "../../vendor/price/timeMoneyList";
import MileStoneList from "../../vendor/price/mileStoneList";
import ProductCostList from "../../vendor/price/productCostList";

import LoadingResult from "../../../../shared/LoadingResult";

import { useStyles } from "./styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const QASectionForm = (props) => {
  const classes = useStyles();

  const { loading, data } = useQuery(GET_SECTIONS, {
    variables: { input: { vbeid: props.templateId } },
  });

  return (
    <>
      {data ? (
        data.listSection.map((section, index) => {
          let obj = JSON.parse(section.metadata);

          return (
            <>
              <TableRow selected={true}>
                <TableCell
                  colSpan={props.products.length + 1}
                  className={classes.tableIndexCell}
                >
                  {obj.title}
                </TableCell>
              </TableRow>

              <QASection
                sid={section.id}
                projectId={props.projectId}
                products={props.products}
                type="qa_template"
                showProducts={props.showProducts}
              />
            </>
          );
        })
      ) : loading ? (
        <LoadingResult />
      ) : null}
    </>
  );
};

const QASections = ({ id, products, showProducts }) => {
  const { loading, data } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    variables: { input: { vbeid: id, typelist: ["qa_template"] } },
  });

  return (
    <>
      {data ? (
        <QASectionForm
          templateId={data.getTemplatesByProjectID[0].vbeid}
          projectId={id}
          products={products}
          showProducts={showProducts}
        />
      ) : loading ? (
        <LoadingResult />
      ) : null}
    </>
  );
};

const ResponseSectionForm = ({
  templateId,
  products,
  projectId,
  showProducts,
}) => {
  const classes = useStyles();

  const { data } = useQuery(GET_SECTIONS, {
    variables: { input: { vbeid: templateId } },
  });

  return (
    <>
      {data
        ? data.listSection.map((section, index) => {
            let obj = JSON.parse(section.metadata);

            return (
              <>
                <TableRow selected={true}>
                  <TableCell
                    colSpan={products.length + 1}
                    className={classes.tableIndexCell}
                  >
                    {obj.title}
                  </TableCell>
                </TableRow>

                <RSection
                  sid={section.id}
                  projectId={projectId}
                  products={products}
                  type="response_template"
                  showProducts={showProducts}
                />
              </>
            );
          })
        : null}
    </>
  );
};

const ShowMileStones = ({
  templateId,
  products,
  projectId,
  showProducts,
  id,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableRow selected={true}>
        <TableCell
          colSpan={products.length + 1}
          className={classes.tableIndexCell}
        >
          MileStones
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className={classes.showcell}></TableCell>

        {products.map((product, index) => {
          return (
            <TableCell
              key={index}
              className={
                showProducts.includes(product.productvbeid)
                  ? classes.showcell
                  : classes.hidecell
              }
            >
              <MileStoneList
                productvbeid={product.productvbeid}
                projectid={id}
                readOnly={true}
              />
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};

const ShowProductsCost = ({
  templateId,
  products,
  projectId,
  showProducts,
  id,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableRow selected={true}>
        <TableCell
          colSpan={products.length + 1}
          className={classes.tableIndexCell}
        >
          Product Cost
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className={classes.showcell}></TableCell>
        {products.map((product, index) => {
          return (
            <TableCell
              key={index}
              className={
                showProducts.includes(product.productvbeid)
                  ? classes.showcell
                  : classes.hidecell
              }
            >
              <ProductCostList
                productvbeid={product.productvbeid}
                projectid={id}
                readOnly={true}
              />
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};

const ShowTimeAndMoney = ({
  templateId,
  products,
  projectId,
  showProducts,
  id,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableRow selected={true}>
        <TableCell
          colSpan={products.length + 1}
          className={classes.tableIndexCell}
        >
          Time And Money
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className={classes.showcell}></TableCell>
        {products.map((product, index) => {
          return (
            <TableCell
              key={index}
              className={
                showProducts.includes(product.productvbeid)
                  ? classes.showcell
                  : classes.hidecell
              }
            >
              <TimeAndMoneyList
                productvbeid={product.productvbeid}
                projectid={id}
                readOnly={true}
              />
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};

const ResponseSections = ({ id, products, showProducts }) => {
  const { loading, data } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    variables: { input: { vbeid: id, typelist: ["response_template"] } },
  });

  return (
    <>
      {data ? (
        <ResponseSectionForm
          templateId={data.getTemplatesByProjectID[0].vbeid}
          projectId={id}
          products={products}
          showProducts={showProducts}
        />
      ) : loading ? (
        <LoadingResult />
      ) : null}
    </>
  );
};

export default function ViewVendorResponseTable({ products, projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  const { data } = useQuery(GET_PRODUCTS_BY_PROJECT, {
    variables: { input: { vbeid: id } },
  });

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader={true} aria-label="vendors">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableIndexCell}></TableCell>

              {data
                ? data.getProductsByProjectIDV1.map((product, index) => {
                    return (
                      <TableCell
                        key={index}
                        className={
                          products.includes(product.productvbeid)
                            ? classes.showcell
                            : classes.hidecell
                        }
                      >
                        {product.productname}
                      </TableCell>
                    );
                  })
                : null}
            </TableRow>
          </TableHead>

          <TableBody>
            {data ? (
              <ResponseSections
                id={id}
                products={data.getProductsByProjectIDV1}
                showProducts={products}
              />
            ) : null}

            {data ? (
              <QASections
                id={id}
                products={data.getProductsByProjectIDV1}
                showProducts={products}
              />
            ) : null}

            {data ? (
              projectData.type === "product" ? (
                <ShowProductsCost
                  id={id}
                  products={data.getProductsByProjectIDV1}
                  showProducts={products}
                />
              ) : projectData.engagement_type === "time_material" ? (
                <ShowTimeAndMoney
                  id={id}
                  products={data.getProductsByProjectIDV1}
                  showProducts={products}
                />
              ) : (
                <ShowMileStones
                  id={id}
                  products={data.getProductsByProjectIDV1}
                  showProducts={products}
                />
              )
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
