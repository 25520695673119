import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Tabs,
  Tab,
} from "@material-ui/core";

import PropTypes from "prop-types";

import { useQuery } from "@apollo/client";

import { GET_PROJECT } from "../../queries/query";

import Concierge from "./concierge";
import VendorList from "./VendorList";

import { useStyles } from "./styles";

const ButtonGroup = ({ id, loading }) => {
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            history.push("/add-short-description/" + id);
          }}
        >
          Back
        </Button>
      </Grid>

      <Grid item xs={5}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            history.push("/requirement-template/" + id);
          }}
          disabled={loading}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Recommendations = ({ data }) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label="Suppliers shortlisted"
            {...a11yProps(0)}
            className={classes.tab}
          />

          <Tab
            label="Concierge Recommendations"
            {...a11yProps(1)}
            className={classes.tab}
          />

          {/* <Tab
            label="Selected Suppliers"
            {...a11yProps(1)}
            className={classes.tab}
          /> */}
        </Tabs>

        <TabPanel value={value} index={0}>
          {/* <OpenAI data={data} /> */}
          <VendorList projectData={data} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Concierge data={data} />
        </TabPanel>

        {/* <TabPanel value={value} index={2}>
          <VendorList projectData={data} />
        </TabPanel> */}
      </Grid>
    </Grid>
  );
};

export default function ManageVendors() {
  const classes = useStyles();
  const { id } = useParams();

  const { data } = useQuery(GET_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id } },
  });

  return (
    <Grid container alignItems="center" className={classes.conciergeRoot}>
      <Grid item xs={12} className={classes.mainForm}>
        {data ? (
          <Recommendations data={data.getProjectByIDV1} />
        ) : (
          <CircularProgress className={classes.loading} />
        )}
      </Grid>

      <Grid item xs={9}>
        <ButtonGroup id={id} />
      </Grid>
    </Grid>
  );
}
