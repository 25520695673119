import * as React from "react";

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Button,
  CircularProgress,
  Box,
  FormHelperText,
  //   FormLabel,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
// import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";

import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";

import { useFormik } from "formik";

import { useMutation, useQuery } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";

import {
  CREATE_PROJECT,
  GET_PROJECT,
  UPDATE_PROJECT,
} from "../../queries/query";
import { useStyles } from "./styles";

const validateProjectCreation = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Please enter project name.";
  }
  if (!values.type) {
    errors.type = "Please select project type.";
  }
  if (!values.decisiontimeline) {
    errors.decisiontimeline = "Please select a decision timeline";
  }

  if (values.type === "service" && values.engagement_type === "-") {
    errors.engagement_type = "Please select engagement type";
  }
  return errors;
};

const ButtonGroup = ({ loading }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            history.push("/projects3.0");
          }}
          disabled={true}
        >
          Back
        </Button>
      </Grid>

      <Grid item xs={5}>
        <div className={classes.wrapper}>
          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            onClick={() => {}}
            disabled={loading}
          >
            Next
          </Button>
          {loading ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : null}
        </div>
      </Grid>
    </Grid>
  );
};

const ProjectType = ({ data, formik }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {/* <Grid Item xs={6}>
        <Card variant="outlined" className={classes.typecard}>
          <CardActionArea
            onClick={
              data
                ? null
                : () => {
                    formik.setFieldValue("type", "product");
                  }
            }
          >
            <Grid container alignItems="center">
              <Grid Item xs={2}>
                <FormControlLabel
                  value="product"
                  control={<Radio size="small" />}
                  label=""
                  className={classes.projectype}
                />
              </Grid>

              <Grid Item xs={10}>
                <Grid container>
                  <Grid
                    Item
                    xs={12}
                    dispaly="flex"
                    align-items="center"
                    className={classes.typetext}
                  >
                    <SettingsIcon fontSize="small" />
                  </Grid>

                  {/* <Grid
                    Item
                    xs={12}
                    dispaly="flex"
                    align-items="center"
                    className={classes.typetext}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.typetext}
                    >
                      {" "}
                      Product{" "}
                    </Typography>

                    <Typography variant="caption" gutterBottom>
                      For sourcing Technology Products
                    </Typography>
                  </Grid> 
                </Grid>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      </Grid> */}

      <Grid item xs={6}>
        <Card variant="outlined" className={classes.typecard}>
          <CardActionArea
            onClick={
              data
                ? null
                : () => {
                    formik.setFieldValue("type", "service-fixed");
                  }
            }
          >
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <FormControlLabel
                  value="service"
                  control={<Radio size="small" />}
                  label=""
                  className={classes.projectype}
                />
              </Grid>

              <Grid item xs={10}>
                <Grid container>
                  <Grid
                    Item
                    xs={12}
                    display="flex"
                    align-items="center"
                    className={classes.typetext}
                  >
                    <PeopleIcon fontSize="small" />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    dispaly="flex"
                    align-items="center"
                    className={classes.typetext}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.typetext}
                    >
                      {" "}
                      Service{" "}
                    </Typography>

                    <Typography variant="caption" gutterBottom>
                      For sourcing Technology Services
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

const Form = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const userRole = props.data?.role[0] || "";

  const formik = useFormik({
    initialValues: props.data
      ? {
          vbeid: props.data.vbeid,
          name: props.data.name,
          decisiontimeline: props.data.decisiontimeline,
          type: props.data.type,
          version: props.data.version,
          location: props.data.location,
          shortdescription: props.data.shortdescription,
          engagement_type: props.data.engagement_type,
        }
      : {
          name: "",
          decisiontimeline: "",
          type: "service",
          version: "v1",
          location: "",
          shortdescription: "",
          engagement_type: "-",
        },
    validate: validateProjectCreation,

    onSubmit: (values) => {
      if (props.data) {
        updateProject({ variables: { input: values } });
      } else {
        createProject({ variables: { input: values } });
      }
    },
  });

  const [createProject, { data, loading, error }] = useMutation(
    CREATE_PROJECT,
    {
      onCompleted: (e) => {
        history.push("/add-team-to-project/" + e.createProjectV1.vbeid);
      },
    }
  );

  const [updateProject, { data: udata, loading: uloading, error: uerror }] =
    useMutation(UPDATE_PROJECT, {
      onCompleted: (e) => {
        history.push("/add-team-to-project/" + e.updateProjectV1.vbeid);
      },
    });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl component="fieldset" className={classes.formControlclass}>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Write a title for your project *
        </Typography>

        <TextField
          id="filled-full-width"
          name="name"
          placeholder="Project Name"
          fullWidth
          variant="outlined"
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          disabled={userRole === "ADDED_TO"}
        />
        <FormHelperText className={classes.errorMessage}>
          {formik.errors.name}
        </FormHelperText>

        <Typography
          variant="caption"
          style={{ color: "red", fontWeight: "bold", margin: "5px" }}
        >
          Never include company names or any sensitive information in the
          project title
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Choose project type *
        </Typography>

        <RadioGroup
          aria-label="type"
          name="type"
          disabled={props.data ? true : false}
          onChange={props.data ? () => {} : formik.handleChange}
          value={formik.values.type}
          error={formik.touched.type && Boolean(formik.errors.type)}
        >
          <ProjectType formik={formik} data={props.data} />

          <FormHelperText className={classes.errorMessage}>
            {formik.errors.type}
          </FormHelperText>
        </RadioGroup>

        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.textFieldLabel}
            >
              Indicative decision timeline *
            </Typography>

            <RadioGroup
              aria-label="time"
              name="decisiontimeline"
              onChange={formik.handleChange}
              value={formik.values.decisiontimeline}
              error={
                formik.touched.decisiontimeline &&
                Boolean(formik.errors.decisiontimeline)
              }
            >
              <FormControlLabel
                value="Immediately"
                control={<Radio size="small" />}
                label="Immediately"
              />

              <FormControlLabel
                value="Within the next 3 months"
                control={<Radio size="small" />}
                label="Within the next 3 months"
              />

              <FormControlLabel
                value="Within the next 6 months"
                control={<Radio size="small" />}
                label="Within the next 6 months"
              />
            </RadioGroup>

            <FormHelperText className={classes.errorMessage}>
              {formik.errors.decisiontimeline}
            </FormHelperText>
          </Grid>

          <Grid item xs={6}>
            {formik.values.type === "service" ? (
              <Box m={1}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.textFieldLabel}
                >
                  Engagement Type *
                </Typography>

                <RadioGroup
                  aria-label="engagement_type"
                  name="engagement_type"
                  value={formik.values.engagement_type}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="fixed_price"
                    control={<Radio size="small" />}
                    label="Fixed Price"
                  />

                  <FormControlLabel
                    value="time_material"
                    control={<Radio size="small" />}
                    label="Time & Material"
                  />

                  <FormControlLabel
                    value="both"
                    control={<Radio size="small" />}
                    label="Both"
                  />
                </RadioGroup>
                <FormHelperText className={classes.errorMessage}>
                  {formik.errors.engagement_type}
                </FormHelperText>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </FormControl>

      <ButtonGroup loading={loading || uloading} />
    </form>
  );
};

export default function ProjectForm() {
  const classes = useStyles();

  let { id } = useParams();

  const { loading, data } = useQuery(GET_PROJECT, {
    variables: {
      input: { vbeid: id },
    },
    skip: id === "new",
    fetchPolicy: "network-only",
  });

  return (
    <Grid container alignItems="center" className={classes.projectFormRoot}>
      {loading ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Form data={data?.getProjectByIDV1} />
      )}
    </Grid>
  );
}
