import React from "react";
import { Box, Divider, Grid, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  mainForumBox: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    maxHeight: theme.spacing(58),
    overflowY: "scroll",
    padding: theme.spacing(1),
  },
}));

export default function ContentLoading() {
  const classes = useStyles();

  return (
    <Box p={1} className={classes.mainForumBox}>
      {Array(3)
        .fill(0)
        .map((value) => {
          return (
            <Box p={1} style={{ marginBottom: "1rem" }}>
              <Grid container justify="flex-start" spacing={2}>
                <Grid item md={1}>
                  <Skeleton variant="rect" height={20} />
                </Grid>
                <Grid item md={11}>
                  <Skeleton variant="rect" height={20} />
                  <Divider />
                  <Skeleton variant="rect" height={20} />
                </Grid>
              </Grid>
            </Box>
          );
        })}
    </Box>
  );
}
