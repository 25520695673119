import * as React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import {
  GET_PRODUCTS_BY_PROJECT,
  GET_TEMPLATE_PRODUCTS_BY_PROJECT,
  MOVE_TO_AWARD,
} from "../../queries/query";
import { useMutation, useQuery } from "@apollo/client";
import ResponseForm from "../../forms/others/responseForm";
import QAForm from "../../forms/others/QAForm";
import EvaluationForm from "../../forms/others/evaluationForm";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { VBRIDGEHUB_MEDIAURL } from "../../../../../constants";
import Drawer from "@material-ui/core/Drawer";
import ViewContractForm from "./contractForm";
import MessageWindow from "../messaging/messageLink";
import VbridgeAlert from "../../../../shared/alert";
import ConfirmAction from "../../../common/ConfirmAction";
import { useState } from "react";
import PrimaryButton from "../../../common/PrimaryButton";
import LoadingResult from "../../../../shared/LoadingResult";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(3),
  },
  reponsePaper: {
    width: "100%",
  },
  tab: {
    width: theme.spacing(30),
  },
  heading: {
    padding: theme.spacing(2),
  },
  label: {
    "& .MuiFormControlLabel-label": {
      fontSize: theme.spacing(1.2),
    },
  },
  productImage: {
    "& img": {
      objectFit: "contain",
    },
  },
}));

function Vendor(props) {
  const classes = useStyles();
  const { id } = useParams();

  const [state, setState] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");

  const [
    moveToAward,
    { data: awardData, loading: awardLoading, error: awardError },
  ] = useMutation(MOVE_TO_AWARD, {
    onCompleted: (e) => {
      setConfirmOpen(false);
      props.refetch();
    },
  });

  const onMoveToAward = () => {
    moveToAward({
      variables: {
        input: { vbeid: id, productvbeid: selectedProduct.productvbeid },
      },
    });
  };

  const onProductSelect = (product) => {
    setSelectedProduct(product);
    setConfirmOpen(true);
  };

  const onCancel = () => {
    setConfirmOpen(false);
  };

  return (
    <Grid item xs={4}>
      <VbridgeAlert
        open={awardData}
        message="Product moved to award successfully"
        severity="success"
      />

      <ConfirmAction
        open={confirmOpen}
        action="award"
        onConfirm={onMoveToAward}
        onCancel={onCancel}
      />

      <Drawer anchor="right" open={state} onClose={setState}>
        <Grid container className={classes.heading}>
          <Grid item xs={10}>
            <Typography variant="h5">{props.product.productname}</Typography>
          </Grid>

          <Grid item xs={2}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                setState(false);
              }}
            >
              Close
            </Button>
          </Grid>

          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <ViewContractForm
              productId={props.product.productvbeid}
              projectId={id}
            />
          </Grid>
        </Grid>
      </Drawer>

      <Card>
        <Grid container>
          <Grid item xs={12}>
            <CardActionArea
              onClick={() => {
                setState(true);
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={2}>
                    <Avatar
                      variant="square"
                      src={VBRIDGEHUB_MEDIAURL + "/" + props.product.image}
                      className={classes.productImage}
                    >
                      :(
                    </Avatar>
                  </Grid>

                  <Grid
                    item
                    xs={10}
                    style={{ textAlign: "left", paddingLeft: "8px" }}
                  >
                    <Grid xs={12}>
                      <Typography variant="subtitile2">
                        {props.product.companyname}
                      </Typography>
                    </Grid>

                    <Grid xs={12}>
                      <Typography variant="caption">
                        {props.product.productname}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>

            <CardActions>
              {props.projectData.role.includes("MANAGES") && (
                <PrimaryButton
                  label={
                    props.product.role.includes("ADD_TO_AWARD")
                      ? "Moved to Award"
                      : "Move to Award"
                  }
                  size="small"
                  variant="contained"
                  onClick={() =>
                    props.product.role.includes("ADD_TO_AWARD")
                      ? null
                      : onProductSelect(props.product)
                  }
                />
              )}

              <MessageWindow
                productname={props.product.productname}
                productId={props.product.productvbeid}
                id={props.id}
              />
            </CardActions>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

export default function ViewContract({ projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  //   const [value, setValue] = React.useState(0);
  const [checked, setChecked] = React.useState([]);
  //   const [state, setState] = React.useState(false);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const { data, refetch } = useQuery(GET_PRODUCTS_BY_PROJECT, {
    variables: { input: { vbeid: id } },
  });

  let contractProducts = [];

  if (data) {
    contractProducts = data.getProductsByProjectIDV1.filter((product) => {
      return product.role.includes("ADD_TO_CONTRACT");
    });
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {data ? (
          contractProducts.length !== 0 ? (
            contractProducts.map((product, index) => {
              return (
                <Vendor
                  key={index}
                  id={id}
                  product={product}
                  handleToggle={handleToggle}
                  checked={checked}
                  refetch={refetch}
                  projectData={projectData}
                />
              );
            })
          ) : (
            <div
              style={{
                textAlign: "center",
                color: "#999",
                fontStyle: "italic",
                marginTop: "16px",
              }}
            >
              No Suppliers have been moved to Contract
            </div>
          )
        ) : (
          <LoadingResult />
        )}
      </Grid>

      <Grid item xs={12}></Grid>
    </div>
  );
}
