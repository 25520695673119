import {
  Paper,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import * as React from "react";

import { useHistory, useParams } from "react-router-dom";

import {
  GET_PRODUCTS_BY_PROJECT,
  GET_SECTIONS,
  GET_TEMPLATE_BY_PROJECT,
  SUBMIT_EVALUATION,
} from "../../queries/query";

import { useMutation, useQuery } from "@apollo/client";

import RSection from "../../forms/others/evaluateTablesesection";
import { useStyles } from "./styles";
import ConfirmAction from "../../../common/ConfirmAction";
import { useState } from "react";
import VbridgeAlert from "../../../../shared/alert";
import LoadingResult from "../../../../shared/LoadingResult";

function EvaluateSectionForm(props) {
  const classes = useStyles();

  const { data, loading } = useQuery(GET_SECTIONS, {
    variables: { input: { vbeid: props.templateId } },
  });

  return (
    <>
      {data ? (
        data.listSection.map((section) => {
          let obj = JSON.parse(section.metadata);

          return (
            <>
              <TableRow selected={true}>
                <TableCell
                  colSpan={props.products.length + 1}
                  className={classes.tableIndexCell}
                >
                  {obj.title}
                </TableCell>
              </TableRow>

              <RSection
                sid={section.id}
                projectId={props.projectId}
                products={props.products}
                type="evaluate_template"
                readOnly={props.readOnly}
              />
            </>
          );
        })
      ) : loading ? (
        <LoadingResult />
      ) : null}
    </>
  );
}

function EvaluationSections(props) {
  const { loading, data } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    variables: { input: { vbeid: props.id, typelist: ["evaluate_template"] } },
  });

  return (
    <>
      {data && data.getTemplatesByProjectID.length !== 0 ? (
        <EvaluateSectionForm
          templateId={data.getTemplatesByProjectID[0].vbeid}
          projectId={props.id}
          products={props.products}
          readOnly={props.readOnly}
        />
      ) : loading ? (
        <LoadingResult />
      ) : data && data.getTemplatesByProjectID.length === 0 ? (
        <Typography variant="h6">Please select a template first.</Typography>
      ) : null}
    </>
  );
}

export default function ViewVendorEvaluationTable({ projectData }) {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { data, loading } = useQuery(GET_PRODUCTS_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id } },
  });

  const [submitEvaluation, { loading: submitLoading }] = useMutation(
    SUBMIT_EVALUATION,
    {
      onCompleted: (e) => {
        setOpen(false);
        setShowAlert(true);
        history.push("/project/evaluate2/" + id);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const onSubmit = () => {
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onConfirmSubmit = () => {
    submitEvaluation({
      variables: { input: { vbeid: id } },
    });
  };

  let evaluateProducts = [];

  if (data) {
    evaluateProducts = data.getProductsByProjectIDV1.filter((product) => {
      return product.role.includes("ADD_TO_EVALUATE");
    });
  }

  let readOnly = projectData.role.includes("SUBMITTED_EVALUATION");

  return (
    <div className={classes.root}>
      {showAlert && (
        <VbridgeAlert
          open={showAlert}
          severity="success"
          message="Your ratings were submitted successfully"
        />
      )}

      {data &&
        evaluateProducts.length !== 0 &&
        !projectData.role.includes("SUBMITTED_EVALUATION") && (
          <Grid container className={classes.heading}>
            <Grid item xs={4}>
              <Button variant="outlined" size="small" onClick={onSubmit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        )}

      {data && evaluateProducts.length === 0 ? (
        <div className={classes.emptyText}>
          No products available to evaluate.
        </div>
      ) : loading ? (
        <CircularProgress />
      ) : (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader={true} aria-label="vendors">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableIndexCell}></TableCell>
                  {data
                    ? evaluateProducts.map((product, index) => {
                        return (
                          <TableCell key={index}>
                            {product.productname}
                          </TableCell>
                        );
                      })
                    : null}
                </TableRow>
              </TableHead>

              <TableBody>
                {data ? (
                  <EvaluationSections
                    id={id}
                    products={evaluateProducts}
                    readOnly={readOnly}
                  />
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      <ConfirmAction
        open={open}
        action="confirm"
        onConfirm={onConfirmSubmit}
        onCancel={onCancel}
        loading={submitLoading}
      />
    </div>
  );
}
