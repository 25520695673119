import { IconButton, Tooltip } from "@material-ui/core";
import InputField from "../common/InputField";
import { useStyles } from "./styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

export default function InputPreview({ item }) {
  const { type } = item;

  let itemProps = item?.properties;

  if (!item.properties) {
    itemProps = JSON.parse(item.metadata);
  }

  const { label, helpMessage, max } = itemProps;

  const classes = useStyles();
  return (
    <div className={classes.inputPreview}>
      <InputField
        label={label}
        onChange={() => {}}
        value=""
        multiline={type === "textarea" ? true : false}
        disabled={false}
        placeholder={label}
        rows={type === "textarea" ? max : false}
        fullWidth={true}
      />

      {helpMessage !== "" && (
        <Tooltip title={helpMessage} placement="top">
          <IconButton className={classes.helpButton}>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
