import { useState } from "react";

import { Chip, Tab, Tabs } from "@material-ui/core";

import clsx from "clsx";

import { TabPanel } from "../Agreements/tabPanel";

import { useStyles } from "./styles";

const groups = require("../../../data/groups.json");

export default function GroupCard({ onGroupClick, selectedGroups }) {
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);

  const onClick = (group) => {
    onGroupClick(group["n.vbeid"]);
  };

  const groupChip = clsx({
    [classes.normalGroupChip]: true,
    [classes.selectedGroupChip]: false,
  });

  const selectedChip = clsx({
    [classes.normalGroupChip]: true,
    [classes.selectedGroupChip]: true,
  });

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="simple tabs example"
        className={classes.tabRoot}
      >
        <Tab label="Technology" {...a11yProps(0)} />
        <Tab label="Business Functions" {...a11yProps(1)} />
        <Tab label="Business Verticals" {...a11yProps(2)} />
      </Tabs>

      <TabPanel value={tabValue} index={0} className={classes.tabPanelRoot}>
        {groups.map((group) => {
          return group["n.type"] === "Technology" ? (
            <Chip
              size="small"
              label={group["n.name"]}
              className={
                selectedGroups.includes(group["n.vbeid"])
                  ? selectedChip
                  : groupChip
              }
              onClick={() => onClick(group)}
            />
          ) : null;
        })}
      </TabPanel>

      <TabPanel value={tabValue} index={1} className={classes.tabPanelRoot}>
        {groups.map((group) => {
          return group["n.type"] === "Business Function" ? (
            <Chip
              size="small"
              label={group["n.name"]}
              className={
                selectedGroups.includes(group["n.vbeid"])
                  ? selectedChip
                  : groupChip
              }
              onClick={() => onClick(group)}
            />
          ) : null;
        })}
      </TabPanel>

      <TabPanel value={tabValue} index={2} className={classes.tabPanelRoot}>
        {groups.map((group) => {
          return group["n.type"] === "Business Vertical" ? (
            <Chip
              size="small"
              label={group["n.name"]}
              className={
                selectedGroups.includes(group["n.vbeid"])
                  ? selectedChip
                  : groupChip
              }
              onClick={() => onClick(group)}
            />
          ) : null;
        })}
      </TabPanel>
    </>
  );
}
