import { Card, Divider, Typography } from "@material-ui/core";

import { getCreatedDate } from "../../../functions/helper";

import PrimaryButton from "../common/PrimaryButton";
import SectionPreview from "./SectionPreview";

import { useStyles } from "./styles";

export default function TemplatePreview({
  template,
  sections,
  items,
  handleClose,
}) {
  const classes = useStyles();

  const { createdon, createdby, name } = template;

  return (
    <Card
      square
      className={classes.productDetailCard}
      elevation={0}
      style={{
        padding: "16px",
        borderRadius: "5px",
        height: "530px",
        overflow: "auto",
      }}
    >
      <div>
        <Typography style={{ fontSize: "0.7rem" }}>
          Date : {getCreatedDate(createdon)}{" "}
        </Typography>

        <Typography style={{ fontSize: "0.7rem" }}>
          Created By : {createdby}{" "}
        </Typography>
      </div>

      <Typography style={{ textAlign: "center" }}>{name}</Typography>

      <Divider />

      <div className={classes.sectionList}>
        {sections.map((section) => {
          return <SectionPreview section={section} items={items} />;
        })}
      </div>

      <div className={classes.previewBtn}>
        <PrimaryButton
          variant="outlined"
          disabled={false}
          label="Close"
          onClick={handleClose}
        />
      </div>
    </Card>
  );
}
