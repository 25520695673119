import { useQuery } from "@apollo/client";
import { List, Paper } from "@material-ui/core";
import { searchQuery } from "../home/query";
import ResultListItem from "./ResultListItem";
import { useStyles } from "./styles";

export default function ResultList({
  text,
  handleClose,
  setValue,
  setDomain,
  setName,
  setCompany,
  sentAwardVbeid,
  setNamdAndVbeid,
  type,
  //handleValues,
}) {
  const { data } = useQuery(searchQuery, {
    variables: { input: { index: type, name: text } },
  });

  const classes = useStyles();

  let searchList = undefined;

  if (data?.esQuery.result.length !== 0) {
    searchList = data?.esQuery.result.map((r) => (
      <ResultListItem
        item={r}
        handleClose={handleClose}
        setValue={setValue}
        setDomain={setDomain}
        setName={setName}
        setCompany={setCompany}
        sentAwardVbeid={sentAwardVbeid}
        setNamdAndVbeid={setNamdAndVbeid}
      />
    ));
  } else {
    if (type !== "skill") {
      let noResult = {
        name: "Didn't find award? Add award below manually.",
        domain: "unavailable",
        logo: "unavailable",
      };

      searchList = (
        <ResultListItem
          item={noResult}
          handleClose={handleClose}
          setValue={setValue}
          setDomain={setDomain}
          setName={setName}
          setCompany={setCompany}
          sentAwardVbeid={sentAwardVbeid}
        />
      );
    }
  }

  return searchList ? (
    <Paper style={{ maxHeight: 200, overflow: "auto" }}>
      <List dense={true} component="nav" className={classes.resultList}>
        {searchList}
      </List>
    </Paper>
  ) : null;
}
