import * as React from "react";
import { useState } from "react";

import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs } from "@material-ui/core";

import { GET_TEMPLATE_BY_PROJECT } from "../../queries/query";

import { useQuery } from "@apollo/client";

import RequirementForm from "../../forms/requirement/viewRequirement";

import TabPanel from "../../../common/TabPanel";

import ViewQAForm from "../reponse/qaForm";
import ViewPriceForm from "../reponse/priceForm";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0, 0, 2),
  },
}));

export default function ViewRequirements({ projectData }) {
  const classes = useStyles();
  const { id, productId } = useParams();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data, refetch } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id, typelist: ["requirement_template"] } },
  });

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange}>
        <Tab
          label="Project Requirement"
          {...a11yProps(0)}
          className={classes.tab}
        />

        <Tab label="Project Q&A" {...a11yProps(1)} className={classes.tab} />

        <Tab
          label="Project Pricing"
          {...a11yProps(2)}
          className={classes.tab}
        />
      </Tabs>

      {data && (
        <>
          <TabPanel value={value} index={0}>
            <RequirementForm
              projectId={id}
              templateId={data?.getTemplatesByProjectID[0].vbeid}
              refetch={refetch}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <ViewQAForm id={id} productId={productId} readOnly={true} />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <ViewPriceForm
              productId={productId}
              projectId={id}
              readOnly={true}
              projectData={projectData}
            />
          </TabPanel>
        </>
      )}
    </div>
  );
}
