import React from "react";

import { useStyles } from "../entities/groups2.0/styles";

import clsx from "clsx";

export default function TypeBadge({ name, type }) {
  const classes = useStyles();

  const typeBadge = clsx({
    [classes.typeBadge]: true,
    [classes.productType]: false,
  });

  const productBadge = clsx({
    [classes.typeBadge]: true,
    [classes.productType]: false,
  });

  return (
    <div className={type === "product" ? productBadge : typeBadge}>
      {type === "usecases" || type === "productUseCase"
        ? "RESOURCE"
        : type === "caseStudies"
        ? "SUCCESS STORY"
        : // : type === "productUsecases"
          // ? "RESOURCE"
          name}
    </div>
  );
}
