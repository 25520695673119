import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    color: "#0e3046",
    lineHeight: "1.8",
    position: "relative",
    bottom: "24px",
  },
  square: {
    width: 100,
    height: 100,
    marginTop: "1%",
    marginLeft: "1%",
  },
  descriptionfield: {
    width: 520,
    fontSize: "3em",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  groupName: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: "600",
  },
  topicRoot: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
  },
  drawerOpenTopicRoot: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    marginLeft: 250,
  },
  subHeading: {
    color: theme.palette.secondary.main,
    textAlign: "left",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  addGroup: {
    marginTop: theme.spacing(2),
  },
  paperRoot: {
    padding: theme.spacing(1, 2),
  },
  descriptionText: {
    fontSize: "0.85rem",
    lineHeight: "1.5",
  },
  categoryGroupDiv: {
    display: "flex",
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(-0.5),
    "& div": {
      marginLeft: 0,
    },
  },
  mainTabsDiv: {
    maxWidth: "80%",
    flexBasis: "80%",
  },

  help: {
    display: "flex",
    justifyContent: "right",
    padding: "4px 4px",

    "& button": {
      padding: "0px 2px",
    },

    "& span": {
      fontSize: "8px",
      textTransform: "none",
      fontWeight: 600,
    },
  },
}));
