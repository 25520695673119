/**
 * Component to render the NDA document with options for user to sign,
 * skip or invite an authorised user
 */

import { useState } from "react";

import { Button, Checkbox, FormControlLabel } from "@material-ui/core";

import OutlinedButton from "../companies/company/OutlinedButton";

import { useStyles } from "./styles";
import "./agreements.css";

const agreement = require("../../../docs/agreement.pdf");

export default function DocumentTabs({
  onInviteUser,
  onSkip,
  onAgree,
  type,
  companyName,
  agreementLoading,
  ndaStatus,
  companyStatus,
  onDownloadClick,
}) {
  const classes = useStyles();

  const [confirmChecked, setConfirmChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleConfirmChange = (e) => {
    let check = e.target.checked;
    setConfirmChecked(check);

    if (check) {
      setDisabled(false);
    } else setDisabled(true);
  };

  const onClick = () => {
    setDisabled(true);
    onAgree();
  };

  return (
    <>
      {companyStatus?.status === "completed" && (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={onDownloadClick}
          style={{ marginBottom: "8px" }}
        >
          Download Agreement
        </Button>
      )}

      <iframe
        className={classes.iframe}
        title="document"
        //src="https://www.vbridgehub.com/docs/vbridge-hub-platform-mutual-nda-v2.pdf#toolbar=0&navpanes=0"
        src={`${agreement}#toolbar=0&navpanes=0`}
        id="documentFrame"
      ></iframe>

      {ndaStatus !== "completed" && (
        <>
          <div className={classes.checkBoxForm}>
            <FormControlLabel
              value="confirm"
              control={
                <Checkbox
                  checked={confirmChecked}
                  onChange={handleConfirmChange}
                  className={classes.checkBox}
                  color="primary"
                />
              }
              label={`I hereby confirm that I have the authority to enter into a binding agreement on behalf of
            ${companyName || "my company"}.`}
            />
          </div>

          <div className={classes.agreeButton}>
            <OutlinedButton
              disabled={disabled}
              label={agreementLoading ? "Redirecting" : "Agree & Sign"}
              onClick={onClick}
              type="contained"
            />
          </div>
        </>
      )}
    </>
  );
}
