import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

import {
  GET_PRODUCTS_BY_PROJECT,
  GET_SECTIONS,
  GET_TEMPLATE_BY_PROJECT,
  SUBMIT_PROJECT,
  LIST_REQUIREMENT_SECTIONS,
} from "../../queries/query";

import { useStyles } from "./styles";
import PrimaryButton from "../../../common/PrimaryButton";

export default function SubmitProject({ id, projectData }) {
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [sectionData, setSectionData] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const [itemValidation, setItemsValidation] = useState(true);
  const [submitOpen, setSubmitOpen] = useState(false);

  const [submitProject, { loading }] = useMutation(SUBMIT_PROJECT, {
    onCompleted: (e) => {
      handleClose();
      setSubmitOpen(true);
    },
    onError: (err) => {
      handleClose();
    },
  });

  const { data: productsData } = useQuery(GET_PRODUCTS_BY_PROJECT, {
    variables: { input: { vbeid: id } },
  });

  const { data: templatesData } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    variables: {
      input: { vbeid: id, typelist: ["response_template", "qa_template"] },
    },
  });

  const { data: requirementTemplate } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    variables: { input: { vbeid: id, typelist: ["requirement_template"] } },
  });

  const [getSections, { data: sections }] = useLazyQuery(GET_SECTIONS, {
    variables: {
      input: { vbeid: requirementTemplate?.getTemplatesByProjectID[0]?.vbeid },
    },
  });

  const [getItems, { loading: itemsLoading }] = useLazyQuery(
    LIST_REQUIREMENT_SECTIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (response) => {
        if (response.getRequirementDocument.length < response.listItem.length) {
          setItemsValidation(false);
        } else {
          if (itemCount < sections.listSection.length) {
            getItems({
              variables: {
                tinput: {
                  sectionid: sections.listSection[itemCount].id,
                  projectid: id,
                },
                pinput: {
                  sectionid: sections.listSection[itemCount].id,
                  projectid: id,
                },
              },
            });

            setItemCount(itemCount + 1);
          }

          if (
            itemCount === sections.listSection.length &&
            itemValidation === ""
          ) {
            setItemsValidation(true);
          }
        }
      },
    }
  );

  if (requirementTemplate && !sectionData) {
    if (requirementTemplate.getTemplatesByProjectID.length !== 0) {
      getSections();
    }

    setSectionData(true);
  }

  const handleClickOpen = () => {
    if (requirementTemplate.getTemplatesByProjectID.length !== 0) {
      setItemsValidation("");

      getItems({
        variables: {
          tinput: {
            sectionid: sections.listSection[0].id,
            projectid: id,
          },
          pinput: {
            sectionid: sections.listSection[0].id,
            projectid: id,
          },
        },
      });

      setItemCount(1);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-submission"
        aria-describedby="confirm-submission"
      >
        {itemsLoading && itemValidation === "" ? (
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        ) : (
          <>
            <DialogTitle id="confirm-submission">
              {productsData?.getProductsByProjectIDV1.length === 0
                ? "Attention Required"
                : "Confirm Submission"}
            </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {requirementTemplate?.getTemplatesByProjectID.length === 0 ? (
                  <Typography
                    variant="caption"
                    className={classes.validateMessage}
                  >
                    Please note that you have not added a requirement template
                    to the project.
                    <br /> Please update and submit project.
                  </Typography>
                ) : productsData?.getProductsByProjectIDV1.length === 0 ? (
                  <Typography
                    variant="caption"
                    className={classes.validateMessage}
                  >
                    Please note that you have not added any suppliers to the
                    project.
                    <br /> Please update and submit project.
                  </Typography>
                ) : templatesData?.getTemplatesByProjectID.length === 0 ? (
                  <Typography
                    variant="caption"
                    className={classes.validateMessage}
                  >
                    Please note that you have not added Response and/or Q & A
                    templates to the project.
                    <br /> Please update and submit project.
                  </Typography>
                ) : itemValidation === false ? (
                  <Typography
                    variant="caption"
                    className={classes.validateMessage}
                  >
                    Please note that you have not completed one or more sections
                    of the requirement template.
                    <br /> Please update and submit project.
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    className={classes.validateMessage}
                  >
                    Please note that you can not edit the requirement upon
                    clicking Submit. Click on Project Name in the dashboard for
                    further actions.
                    <br /> By clicking Submit, your requirements will be shared
                    with the identified suppliers.
                    <br /> Please ensure that you follow your company's
                    information sharing guidelines with suppliers before
                    submitting your requirements.
                  </Typography>
                )}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                onClick={() => {
                  if (
                    productsData?.getProductsByProjectIDV1.length === 0 ||
                    requirementTemplate?.getTemplatesByProjectID.length === 0 ||
                    templatesData?.getTemplatesByProjectID.length === 0 ||
                    itemValidation === false
                  ) {
                    handleClose();
                  } else {
                    submitProject({ variables: { input: { vbeid: id } } });
                  }
                }}
                color="primary"
                autoFocus
                disabled={loading || projectData?.role[0] === "ADDED_TO"}
                variant="contained"
              >
                {productsData?.getProductsByProjectIDV1.length === 0 ||
                requirementTemplate?.getTemplatesByProjectID.length === 0 ||
                templatesData?.getTemplatesByProjectID.length === 0 ||
                itemValidation === false
                  ? "Okay"
                  : "Submit"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog
        open={submitOpen}
        onClose={handleClose}
        aria-labelledby="confirm-submission"
        aria-describedby="confirm-submission"
      >
        <>
          <DialogTitle id="confirm-submission">Project Submitted</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="caption" className={classes.validateMessage}>
                Your project has been submitted to the identified suppliers.
                Click on Project Name in Project Home to continue engaging with
                suppliers and start viewing responses.
              </Typography>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                history.push("/projects3.0/overview");
              }}
              color="primary"
              autoFocus
              variant="contained"
            >
              Projects Home
            </Button>
          </DialogActions>
        </>
      </Dialog>

      <PrimaryButton
        label="Submit Project"
        variant="contained"
        onClick={() => handleClickOpen()}
      />
    </>
  );
}
