import { Button, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const useStyles = makeStyles((theme) => ({
  addNewBtn: {
    fontSize: "0.7rem",
    marginBottom: theme.spacing(1),
  },
}));

export default function AddNewButton({
  name,
  handleClick,
  disabled,
  icon,
  variant,
}) {
  const classes = useStyles();

  return (
    <Button
      variant={variant ? variant : "outlined"}
      size="small"
      color="primary"
      onClick={() => {
        handleClick();
      }}
      className={classes.addNewBtn}
      disabled={disabled}
    >
      {icon === "remove" ? (
        <DeleteOutlinedIcon fontSize="small" />
      ) : (
        <AddIcon fontSize="small" />
      )}
      {name}
    </Button>
  );
}
