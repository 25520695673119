import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { GET_TEMPLATE_BY_PROJECT } from "../../queries/query";
import { useQuery } from "@apollo/client";
import ResponseForm from "../../forms/others/responseForm";

import LoadingResult from "../../../../shared/LoadingResult";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function ViewResponseForm({ id, productId, readOnly }) {
  const classes = useStyles();

  const { loading, data } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id, typelist: ["response_template"] } },
  });

  return (
    <div className={classes.root}>
      {data ? (
        <ResponseForm
          projectId={id}
          productId={productId}
          type="response_template"
          templateId={data.getTemplatesByProjectID[0]?.vbeid}
          readOnly={readOnly}
        />
      ) : loading ? (
        <LoadingResult />
      ) : null}
    </div>
  );
}
