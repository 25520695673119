import { gql } from "@apollo/client";

export const updatetopic = gql`
  mutation update($input: topicinput!) {
    updatetopic(input: $input) {
      name
      description
    }
  }
`;

export const queryTopics = gql`
  query topicInformation($input: id!) {
    queryTopicbyid(input: $input) {
      name
      description
      image
    }
  }
`;

export const listTopicGroups = gql`
  query listing($input: topictogroupinput!) {
    listGroupByTopicId(input: $input) {
      total
      groups {
        name
        id
      }
    }
  }
`;

export const getProductsByTopicId = gql`
  query productsinformation($vbeid: String, $offset: Int, $limit: Int) {
    productsByTopicID(
      input: { vbeid: $vbeid, offset: $offset, limit: $limit }
    ) {
      name
      image
      companyname
      companyvbeid
      groupname
      groupvbeid
      shortdescription
      id
      vbeid
    }
  }
`;

export const listGroupTopics = gql`
  query listing($input: topictogroupinput!) {
    listTopicsByGroupId(input: $input) {
      total
      topics {
        name
        id
      }
    }
  }
`;

export const deleteTopicGroup = gql`
  mutation delete($input: topictogroupinput!) {
    removetopictogroup(input: $input) {
      groupid
    }
  }
`;

export const userInfoQuery = gql`
  query userinfo {
    getLoginUserInfo(input: {}) {
      vbeid
      name
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
    }
  }
`;

export const mapGroupToTopic = gql`
  mutation addgrouptotopic($input: topictogroupinput!) {
    topictogroup(input: $input) {
      topicid
      groupid
    }
  }
`;

export const searchTopic = gql`
  query listing {
    listGroup(input: {}) {
      total
      groups {
        id
        name
      }
    }
  }
`;
