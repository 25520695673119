import { makeStyles, Tooltip, withStyles } from "@material-ui/core";

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#eee",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(5),
    width: "90%",
  },
  backLink: {
    color: theme.palette.secondary.main,
    float: "left",
  },
  question: {
    //marginLeft: "65px",
    fontSize: "0.83rem",
    "& p": {
      margin: "3px 0 0 0",
      fontSize: "0.85rem",
    },
  },
  projecthead: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  linkalign: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  listvendors: {
    textAlign: "left",
  },
  covernote: {
    textAlign: "left",
  },
  textfields: {
    width: "90%",
  },
  buttonStyle: {
    borderRadius: "2rem",
    textTransform: "capitalize",
  },
  buttonalign: {
    paddingLeft: theme.spacing(100),
  },
  productHead: {
    cursor: "pointer",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  fixvendorsheight: {
    height: "140px",
    //width:'85%',
    textAlign: "left",
    marginTop: theme.spacing(2),
    overflowY: "scroll",
    overflowX: "hidden",
    padding: theme.spacing(3),
    backgroundColor: "#efefef",
    fontSize: "0.8rem",
  },
}));
