import { useHistory } from "react-router";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import { getProfileAdminsQuery } from "../companies/company/query";
import { claimProfile } from "../companies/Company2.0/query";

import ClaimCompanyProfile from "./ClaimCompanyProfile";
import CreateCompanyProfile from "./CreateCompanyProfile";

import { userInfoQuery } from "./query";

import Load from "../../shared/skeletons/skeleton";

export default function CompanyProfile({
  formik,
  saveCompanyProfile,
  setStepIndexOnClick,
  mapedCompany,
}) {
  const client = useApolloClient();
  const history = useHistory();

  const data = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  const { data: admin_data, loading: adminDataLoading } = useQuery(
    getProfileAdminsQuery,
    {
      variables: { input: {} },
    }
  );

  const [claimProfileMutation, { loading: claimLoading }] = useMutation(
    claimProfile,
    {
      onCompleted: (claimResponse) => {
        updateUserProfileCache(claimResponse);
      },
    }
  );

  const updateUserProfileCache = (claimResponse) => {
    const { id, vbeid, name } = claimResponse.claimCompany;

    const admins = client.readQuery({
      query: getProfileAdminsQuery,
      variables: { input: {} },
    });

    let adminslist = [...admins.listProfileAdmin];

    adminslist.unshift({
      __typename: "person",
      id: id,
      vbeid: vbeid,
      name: name,
    });

    client.writeQuery({
      query: userInfoQuery,
      data: {
        getLoginUserInfo: { ...claimResponse.claimCompany },
      },
      variables: { input: {} },
    });

    client.writeQuery({
      query: getProfileAdminsQuery,
      data: {
        listProfileAdmin: adminslist,
      },
      variables: { input: {} },
    });
  };

  const claimProfileClick = (event) => {
    claimProfileMutation({
      variables: { input: {} },
    });
  };

  if (admin_data) {
    if (
      data.getLoginUserInfo.companyvbeid &&
      admin_data.listProfileAdmin.length !== 0
    ) {
      history.push("/mycompany/" + data.getLoginUserInfo.companyvbeid);
    }
  }

  if (adminDataLoading) {
    return <Load />;
  }

  return !admin_data?.listProfileAdmin.length &&
    data?.getLoginUserInfo.companyvbeid &&
    !mapedCompany ? (
    <ClaimCompanyProfile
      claimProfile={claimProfileClick}
      agreed={true}
      loading={claimLoading}
      userInfo={data?.getLoginUserInfo}
    />
  ) : data.getLoginUserInfo.companyvbeid && !mapedCompany ? null : (
    <CreateCompanyProfile
      formik={formik}
      userInfo={data?.getLoginUserInfo}
      saveCompanyProfile={saveCompanyProfile}
      claimProfile={claimProfileClick}
      setStepIndexOnClick={setStepIndexOnClick}
      mapedCompany={mapedCompany}
    />
  );
}
