/**
 * Component for Read More and Reaad Less options
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import EmptyDiv from "../companies/company/tabs/lists/emptyDiv";

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: "pointer",
  },
  actionText: {
    color: theme.palette.secondary.main,
    fontSize: "0.75rem",
  },
}));

export default function ReadMore({ type, text, tab, subTabType }) {
  const classes = useStyles();

  const [isReadMore, setIsReadMore] = React.useState(true);

  let lengthCheck = 250;
  if (type === "description") {
    lengthCheck = 150;
  }

  const toggleReadMore = () => {
    let prevReadMore = isReadMore;

    setIsReadMore(!isReadMore);

    if (type === "main" && !prevReadMore) {
      window.scrollTo({ top: 0 });
    }
  };

  return (
    <>
      {text === null || text === "" || text === undefined ? (
        subTabType === "usecases" || subTabType === "caseStudies" ? null : (
          <EmptyDiv type="description" margin="noMargin" />
        )
      ) : text?.length <= lengthCheck ? (
        text
      ) : (
        <>
          {isReadMore ? text?.slice(0, lengthCheck) : text}
          <span onClick={toggleReadMore} className={classes.icon}>
            {isReadMore ? (
              <>
                ... <span className={classes.actionText}>Read More</span>{" "}
              </>
            ) : (
              <span className={classes.actionText}> Read Less</span>
            )}
          </span>
        </>
      )}
    </>
  );
}
