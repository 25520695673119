import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    margin: "auto",
  },
  companyLogo: {
    minWidth: theme.spacing(4),
  },
  text: {
    textTransform: "capitalize",
  },
  autocompleteSearch: {
    "& .MuiFormControl-marginDense": {
      marginTop: "0 !important",
    },
    "& label": {
      fontSize: "0.85rem",
    },
  },
  productTypeChip: {
    fontSize: "12px",
    padding: "0px",
    border: "0px",
    overflowY: "scroll",
  },
  multiSelectChip: {
    fontSize: "0.7rem",
    height: "22px",
    margin: "0 2px",
    "& .MuiChip-deleteIcon": {
      width: "18px",
    },
  },
  autocompleteTextField: {
    "& label": {
      fontSize: "0.9rem",
    },
  },
}));
