import { Popover } from "@material-ui/core";
import ResultList from "./ResultList";
import { useStyles } from "./styles";

export default function SearchResult(props) {
  const classes = useStyles();
  const {
    id,
    open,
    anchorEl,
    setAnchorEl,
    text,
    setValue,
    setDomain,
    setName,
    setCompany,
    sentAwardVbeid,
    type,
    handleValues,
    setNamdAndVbeid,
  } = props;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      className={classes.popover}
      id={id}
      open={open}
      anchorEl={anchorEl}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {text ? (
        <ResultList
          setValue={setValue}
          text={text}
          handleClose={handleClose}
          setDomain={setDomain}
          setName={setName}
          sentAwardVbeid={sentAwardVbeid}
          setCompany={setCompany}
          type={type}
          handleValues={handleValues}
          setNamdAndVbeid={setNamdAndVbeid}
        />
      ) : null}
    </Popover>
  );
}
