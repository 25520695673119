import { useState } from "react";

import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableContainer } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Chip } from "@material-ui/core";

import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import {gql, useQuery } from "@apollo/client";
import { GET_SERVICE_PROJECTS_BY_USER } from "../../queries/query";
import { useHistory } from "react-router-dom";
import PrimaryButton from "../../../common/PrimaryButton";
import Load from "../../../../shared/skeletons/skeleton";

import { useStyles } from "./styles.js";

import ProjectSlider from "../../../common/ProjectSlider";
import ConciergeSlider from "./ConciergeSlider";

const ProjectMenu = ({ status, vbeid, onMessage, onConcierge, type }) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {status ? null : (
          <MenuItem
            key="View"
            onClick={() => {
              if (type === "service-timematerial") {
                history.push("/create-service-project-v3/" + vbeid);
              } else if (type === "service-workpackage") {
                history.push("/create-workpackage-project-v3/" + vbeid);
              } else {
                history.push("/create-project-v3/" + vbeid);
              }
            }}
          >
            Edit and Submit
          </MenuItem>
        )}

        <MenuItem key="Message" onClick={onMessage}>
          Message Suppliers
        </MenuItem>

        <MenuItem key="Concierge" onClick={onConcierge}>
          Concierge
        </MenuItem>
      </Menu>
    </div>
  );
};

export default function ServiceProjects() {
  const history = useHistory();
  const classes = useStyles();

  const [messageOpen, setMessageOpen] = useState(false);
  const [conciergeOpen, setConciergeOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState([]);
  
  const userPermission = gql`
  query identity($input: personinput!) {
    amiauthorized(input: $input) {
      status
    }
  }
`;

  const { loading, data } = useQuery(GET_SERVICE_PROJECTS_BY_USER, {
    fetchPolicy: "no-cache",
  });

  const onClose = () => {
    setMessageOpen(false);
    setConciergeOpen(false);
  };

  const onMessageClick = (project) => {
    setSelectedProject(project);
    setMessageOpen(true);
  };

  const onConciergeClick = (project) => {
    setSelectedProject(project);
    setConciergeOpen(true);
  };

  return (
    <>
      <TableContainer component={Paper}>
        {data && data.listServiceProjects.length !== 0 ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>PROJECT NAME</TableCell>
                <TableCell align="left">CREATED DATE</TableCell>
                <TableCell align="left">CREATED BY</TableCell>
                <TableCell align="left">TYPE</TableCell>
                <TableCell align="left">STATUS</TableCell>
                <TableCell align="left">ACTIONS</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data ? (
                data.listServiceProjects.map((row) => (
                  <TableRow
                    key={row.vbeid}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" component="th" scope="row">
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        onClick={() => {
                          history.push("/project/serviceOverview/" + row.vbeid);
                        }}
                        className={classes.projectNameDiv}
                        style={{ cursor: "pointer" }}
                      >
                        {row.name}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" component="th" scope="row">
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        {new Date(parseInt(row.createdon))
                          .toLocaleDateString("en-US", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                          .toString()}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" component="th" scope="row">
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        {row.createdby}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" component="th" scope="row">
                      <Chip
                        label={
                          row.type === "service-fixed"
                            ? "Work Package"
                            : row.type === "service-timematerial"
                            ? "Contract Resource"
                            : row.type
                        }
                        size="small"
                        color="secondary"
                        style={{ textTransform: "capitalize" }}
                      ></Chip>
                    </TableCell>

                    <TableCell align="left" component="th" scope="row">
                      <Chip
                        align="justify"
                        label={
                          row.projectstatus ? row.projectstatus : "In Progress"
                        }
                        size="small"
                        color="primary"
                        variant="outlined"
                      ></Chip>
                    </TableCell>

                    <TableCell align="left" component="th" scope="row">
                      <ProjectMenu
                        vbeid={row.vbeid}
                        status={row.projectstatus}
                        onMessage={() => onMessageClick(row)}
                        onConcierge={() => onConciergeClick(row)}
                        type={row.type}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6}>
                      {loading
                        ? "Please wait ..."
                        : "There are no projects to display"}
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
            </TableBody>
          </Table>
        ) : loading ? (
          <Load />
        ) : (
          <div style={{ margin: "16px 0" }}>
            <Typography variant="subtitle2" gutterBottom component="div">
              There are no projects to display.
            </Typography>



            {userPermission && userPermission.amiauthorized.status && (
              <PrimaryButton
                label="Create Project"
                variant="contained"
                size="small"
                onClick={() => {
                  history.push("/create-project-v3/new");
                }}
              />
            )}
          </div>
        )}
      </TableContainer>

      {messageOpen && (
        <ProjectSlider
          open={messageOpen}
          projectVbeid={selectedProject.vbeid}
          onClose={onClose}
          showAll={true}
          project={selectedProject}
        />
      )}

      {conciergeOpen && (
        <ConciergeSlider
          open={conciergeOpen}
          projectVbeid={selectedProject.vbeid}
          project={selectedProject}
          onClose={onClose}
        />
      )}
    </>
  );
}
