import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

import {
  GET_COMPANIES_BY_PROJECT,
  SUBMIT_SERVICE_PROJECT,
} from "../../queries/query";

import { useStyles } from "./styles";

import PrimaryButton from "../../../common/PrimaryButton";

export default function SubmitServiceProject({ id, projectData }) {
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [submitOpen, setSubmitOpen] = useState(false);

  const [submitProject, { loading }] = useMutation(SUBMIT_SERVICE_PROJECT, {
    onCompleted: (e) => {
      handleClose();
      setSubmitOpen(true);
    },
    onError: (err) => {
      handleClose();
    },
  });

  const {
    data: companies,
    loading: companiesLoading,
    refetch,
  } = useQuery(GET_COMPANIES_BY_PROJECT, {
    variables: { input: { vbeid: id } },
    fetchPolicy: "no-cache",
  });

  const handleClickOpen = () => {
    refetch();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-submission"
        aria-describedby="confirm-submission"
      >
        {companiesLoading ? (
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        ) : (
          <>
            <DialogTitle id="confirm-submission">
              {companies?.listCompaniesByProjectID.length === 0
                ? "Attention Required"
                : "Confirm Submission"}
            </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {companies?.listCompaniesByProjectID.length === 0 ? (
                  <Typography
                    variant="caption"
                    className={classes.validateMessage}
                  >
                    Please note that you have not added any suppliers to the
                    project.
                    <br /> Please update and submit project.
                  </Typography>
                ) : (
                  <>
                    <Typography
                      component="div"
                      className={classes.validateMessage}
                    >
                      By clicking Submit, your requirements will be shared with
                      the selected suppliers.
                    </Typography>

                    <Typography
                      component="div"
                      className={classes.validateMessage}
                    >
                      {" "}
                      Please ensure that you follow your company's information
                      sharing guidelines with suppliers before submitting your
                      requirements.
                    </Typography>

                    <Typography
                      component="div"
                      className={classes.validateMessage}
                    >
                      Please note that you can not edit the requirement upon
                      clicking Submit. Click on Project Name in the dashboard
                      for further actions.
                    </Typography>
                  </>
                )}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                size="small"
              >
                Cancel
              </Button>

              <Button
                onClick={() => {
                  if (companies?.listCompaniesByProjectID.length === 0) {
                    handleClose();
                  } else {
                    submitProject({ variables: { input: { vbeid: id } } });
                  }
                }}
                color="primary"
                autoFocus
                disabled={loading || projectData?.role[0] === "ADDED_TO"}
                variant="contained"
                size="small"
              >
                {companies?.listCompaniesByProjectID.length === 0
                  ? "Okay"
                  : "Submit"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog
        open={submitOpen}
        onClose={handleClose}
        aria-labelledby="confirm-submission"
        aria-describedby="confirm-submission"
      >
        <>
          <DialogTitle id="confirm-submission">Project Submitted</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography component="div" className={classes.validateMessage}>
                Your project has been submitted to the selected suppliers.{" "}
              </Typography>

              <Typography component="div" className={classes.validateMessage}>
                Click on Project Name in Project Home to continue engaging with
                suppliers and start viewing responses.
              </Typography>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                history.push("/projects3.0/serviceProjects");
              }}
              color="primary"
              autoFocus
              variant="contained"
            >
              Projects Home
            </Button>
          </DialogActions>
        </>
      </Dialog>

      <PrimaryButton
        label="Submit Project"
        variant="contained"
        onClick={() => handleClickOpen()}
      />
    </>
  );
}
