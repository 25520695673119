import { gql } from "@apollo/client";

//list group details
export const getGroupById = gql`
  query groupInformation($input: id!) {
    queryGroupbyid(input: $input) {
      name
      description
      image
      id
    }
  }
`;

//update group
export const updategroup = gql`
  mutation update($input: groupinput!) {
    updateGroup(input: $input) {
      name
      description
      id
    }
  }
`;

export const userInfoQuery = gql`
  query userinfo {
    getLoginUserInfo(input: {}) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      roletype
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
      isPrivatemail
    }
  }
`;

//list products by id details
export const getProductsByGroupId = gql`
  query productsinformation($vbeid: String, $offset: Int, $limit: Int) {
    productsByGroupID(
      input: { vbeid: $vbeid, offset: $offset, limit: $limit }
    ) {
      name
      image
      companyname
      companyvbeid
      companyurl
      topicname
      topicvbeid
      shortdescription
      id
      vbeid
    }
  }
`;

//list products by id and filters
export const getProductsByFilters = gql`
  query getProductsByFilters($input: genericinput!) {
    listproductfilter(input: $input) {
      name
      image
      companyname
      companyvbeid
      topicname
      topicvbeid
      shortdescription
      id
      vbeid
      awardcount
      partnercount
      customercount
      casestudycount
      usecasecount
      securityscorecard_grade_url
      knpashot_score
      groupname
      groupvbeid
      companylocation
      fundround
      founded
      longdescription
      topiclist {
        name
        vbeid
      }
    }
  }
`;

export const getUseCaseByFilters = gql`
  query getUseCaseByFilters($input: genericinput!) {
    listUsecasefilter(input: $input) {
      name
      image
      companyname
      companyvbeid
      shortdescription
      id
      vbeid
    }
  }
`;

export const getCaseStudiesByFilters = gql`
  query getCaseStudiesByFilters($input: genericinput!) {
    listCaseStudyfilter(input: $input) {
      name
      image
      companyname
      companyvbeid
      shortdescription
      id
      vbeid
    }
  }
`;

//add to list mutation
export const postAddToList = gql`
  mutation list($input: listinput!) {
    addToList(input: $input) {
      name
      vbeid
    }
  }
`;

//get user lists
export const userLists = gql`
  query userlists {
    getListsByUser {
      id
      name
      description
      vbeid
    }
  }
`;

//add to project mutation
export const postproduct = gql`
  mutation producttoproject($input: projectinput!) {
    addProductsToProject(input: $input) {
      name
      vbeid
    }
  }
`;

//add company to project mutation
export const addCompanyToProject = gql`
  mutation addCompanyToProject($input: projectinputV1!) {
    addCompanyToProject(input: $input) {
      name
      vbeid
      responsestatus
    }
  }
`;

//get user projects
export const userProjects = gql`
  query projects {
    listProjectByPersonID {
      id
      name
      description
      vbeid
    }
  }
`;

//query topicsbygroupid
export const listGroupTopics = gql`
  query listing($input: topictogroupinput!) {
    listTopicsByGroupId(input: $input) {
      total
      topics {
        name
        id
        tag
      }
    }
  }
`;

//delete topics mapped to groups
export const deleteTopicGroup = gql`
  mutation delete($input: topictogroupinput!) {
    removetopictogroup(input: $input) {
      topicid
    }
  }
`;

export const getCustomersByCompanyId = gql`
  query customersInformation($input: companyinput!) {
    customersByCompanyId(input: $input) {
      name
      image
      shortdescription
      vbeid
      companyid
      companyurl
      id
      referenceable
    }
  }
`;

export const getPartnersByCompanyId = gql`
  query partnersInformation($input: companyinput!) {
    partnersByCompanyId(input: $input) {
      name
      image
      shortdescription
      companyid
      companyurl
      vbeid
      id
    }
  }
`;

export const getAwardsByCompanyId = gql`
  query awardsInformation($input: awardinputV1!) {
    listAwardsByCompanyIDV1(input: $input) {
      id
      vbeid
      name
      year
      awarderimage
    }
  }
`;

// export const getAwardsByCompanyId = gql`
//   query awardsInformation($input: genericinput!) {
//     awardsByCompanyId(input: $input) {
//       id
//       name
//       year
//       awardername
//       image
//       shortdescription
//       vbeid
//     }
//   }
// `;

export const getCaseStudiesByCompanyId = gql`
  query caseStudyInformation($input: companyinput!) {
    casestudiesByCompanyId(input: $input) {
      name
      image
      shortdescription
      vbeid
      id
      businessvertical
      longdescription
    }
  }
`;

export const getUseCasesByCompanyId = gql`
  query useCasesInformation($input: companyinput!) {
    usecaseByCompanyId(input: $input) {
      name
      image
      shortdescription
      industryvertical
      vbeid
      id
    }
  }
`;

export const userInfo = gql`
  query userinfo {
    getLoginUserInfo(input: {}) {
      vbeid
      name
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
    }
  }
`;

export const mapTopicToGroup = gql`
  mutation addtopictogroup($input: topictogroupinput!) {
    topictogroup(input: $input) {
      topicid
      groupid
    }
  }
`;

export const searchTopic = gql`
  query listing {
    listtopic(input: {}) {
      total
      topics {
        id
        name
      }
    }
  }
`;

export const listTopicGroups = gql`
  query listing($input: topictogroupinput!) {
    listGroupByTopicId(input: $input) {
      total
      groups {
        name
        id
      }
    }
  }
`;

export const listRecognisationsById = gql`
  query listRecognisationsById($input: genericinput!) {
    getRecognizationsbytopicId(input: $input) {
      count
      name
      vbeid
    }
  }
`;

export const listPartnersById = gql`
  query listPartnersById($input: genericinput!) {
    getPartners(input: $input) {
      count
      name
      vbeid
    }
  }
`;

export const listVerticalsById = gql`
  query listVerticalsById($input: genericinput!) {
    getCustomerbyvertical(input: $input) {
      count
      name
      vbeid
    }
  }
`;

export const listLocationsById = gql`
  query listLocationsById($input: genericinput!) {
    getLocations(input: $input) {
      count
      name
      vbeid
    }
  }
`;

export const listFundingRoundsById = gql`
  query listFundingRoundsById($input: genericinput!) {
    getFundingRounds(input: $input) {
      count
      name
      vbeid
    }
  }
`;

export const listFoundedYearsById = gql`
  query listFoundedYearsById($input: genericinput!) {
    getFoundedYears(input: $input) {
      year
      count
    }
  }
`;

export const listMaturityById = gql`
  query listMaturityById($input: genericinput!) {
    getMaturity(input: $input) {
      name
      count
    }
  }
`;

export const listSkillsById = gql`
  query listSkillsById($input: genericinput!) {
    getSkills(input: $input) {
      name
      count
    }
  }
`;

///list usecases by id details
export const getUseCasesByGroupId = gql`
  query usecasesInformation($vbeid: String, $offset: Int, $limit: Int) {
    usecaseByGroupID(input: { vbeid: $vbeid, offset: $offset, limit: $limit }) {
      name
      image
      companyname
      companyvbeid
      topicname
      topicvbeid
      shortdescription
      id
      vbeid
    }
  }
`;

export const getCasestudiesByGroupId = gql`
  query casestudyInformation($vbeid: String, $offset: Int, $limit: Int) {
    casestudyByGroupID(
      input: { vbeid: $vbeid, offset: $offset, limit: $limit }
    ) {
      name
      image
      companyname
      companyvbeid
      topicname
      topicvbeid
      shortdescription
      id
      vbeid
    }
  }
`;

export const userPermission = gql`
  query identity($input: personinput!) {
    amiauthorized(input: $input) {
      status
    }
  }
`;
