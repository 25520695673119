import { useState } from "react";
import { useParams } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";

import {
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";

import { useFormik } from "formik";

import { theme } from "../../../../../../theme/theme";
import File from "../../../../../shared/uploadfile";

import AutoCompleteField from "../../../../common/AutoCompleteField";
import InputField from "../../../../common/InputField";
import SelectField from "../../../../common/SelectField";

import { getSignedUrlForDoc } from "../../../queries/query";
import { useStyles } from "../styles";

import { workExperienceValues } from "./DropdownData";
import JDEditor from "./JDEditor";
import NextPrevButtons from "./NextPrevButtons";
import SearchSkill from "./SearchSkill";

import { getVbeidOfSelectedCountry } from "../../../../../../functions/helper";
import SearchAutoCompleteField from "../../../../common/SearchAutoCompleteField";

const countries = require("../../../../../../data/countriesdata.json");
const states = require("../../../../../../data/states_bycountries.json");
const currencyList = require("../../../../../../data/currency.json");

export default function JobDescriptionForm({
  projectData,
  updateProject,
  type,
}) {
  const { id } = useParams();
  const classes = useStyles();

  let countryVbeid =
    projectData.country && getVbeidOfSelectedCountry(projectData.country);

  let secondarySkillsObj = [];

  if (projectData.secondaryskill && projectData.secondaryskill.length !== 0) {
    projectData.secondaryskill.forEach((skill) => {
      secondarySkillsObj.push({ type: skill, value: skill });
    });
  }

  const [selectedState, setSelectedState] = useState(projectData.state || "");
  const [selectedValue, setSelectedValues] = useState(
    projectData.country || ""
  );
  const [countrychange, setCountryChange] = useState(true);
  const [countryvbeid, setCountryvbeid] = useState(countryVbeid || "");
  const [jobDesc, setJobDesc] = useState(projectData.jobdescription);
  const [primarySkill, setPrimarySkill] = useState(projectData.primaryskill);
  const [secondarySkills, setSecondarySkills] = useState(
    secondarySkillsObj || []
  );
  const [jdFile, setJdFile] = useState(projectData.jobdescriptionfile || "");
  const [fileName, setFileName] = useState("");
  const [secondarySkillNames, setSecondaySkillNames] = useState(
    projectData.secondaryskill || []
  );

  let optCountries = [];
  let optStates = [];

  countries.map((country) => {
    return optCountries.push({
      name: country.countryname,
      value: country.countryname,
      vbeid: country.countryvbeid,
    });
  });

  states.map((state) => {
    if (
      selectedValue === state["cntry.name"] ||
      countryvbeid === state["cntry.vbeid"]
    ) {
      return optStates.push({
        name: state["ste.name"],
        value: state["ste.name"],
        vbeid: state["cntry.vbeid"],
      });
    } else return null;
  });

  const onCountrySelect = (values, selectedVbeid) => {
    setCountryChange(false);
    setSelectedValues(values);
    formik.values.country = values;
    setCountryvbeid(selectedVbeid);
  };

  const onStateSelect = (values, selectedVbeid) => {
    setCountryChange(false);
    setSelectedState(values);
    formik.values.state = values;
    setCountryvbeid(selectedVbeid);
  };

  const validateProjectUpdate = (values) => {
    const errors = {};

    if (!jobDesc && !jdFile) {
      errors.jobdescription =
        "Please enter job description or upload a job description file.";
    }

    if (!values.primaryskill) {
      errors.primaryskill = "Please select a primary skill.";
    }

    if (!values.expinprimaryskill) {
      errors.expinprimaryskill =
        "Please select experience required for primary skill.";
    }

    if (!values.resourcecount) {
      errors.resourcecount = "Please enter no of resources.";
    }

    if (!values.workduration) {
      errors.workduration = "Please enter work duration.";
    }

    if (!values.currency) {
      errors.currency = "Please select currency.";
    }

    if (!values.budget) {
      errors.currency = "Please enter a budget.";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: projectData
      ? {
          vbeid: projectData.vbeid,
          expinprimaryskill: projectData.expinprimaryskill,
          primaryskill: projectData.primaryskill,
          secondaryskill: projectData.secondaryskill,
          jobdescription: projectData.jobdescription,
          resourcecount: projectData.resourcecount,
          budget: projectData.budget,
          workduration: projectData.workduration,
          currency: projectData.currency,
          country: projectData.country,
          state: projectData.state,
        }
      : {
          expinprimaryskill: "",
          secondaryskill: "",
          primaryskill: "",
          jobdescription: "",
          resourcecount: "",
          budget: "",
          workduration: "",
          currency: "",
          country: "",
          state: "",
        },
    validate: validateProjectUpdate,

    onSubmit: (values) => {
      values.jobdescription = jobDesc;
      values.secondaryskill = secondarySkillNames;
      values.vbeid = id;
      values.jobdescriptionfile = jdFile;

      updateProject({ variables: { input: values } });
    },
  });

  const saveData = (data) => {
    delete formik.errors.jobdescription;
    setJobDesc(data);
  };

  const getUrl = (paramName, filepath, newUrl, fileName) => {
    let fileNameDecoded = decodeURI(fileName);

    setJdFile(newUrl);
    setFileName(fileNameDecoded);
  };

  const onFileClick = () => {
    getFileUrl();
  };

  const getFileName = () => {
    if (jdFile !== "") {
      let fileUrlSplit = jdFile.split("/file");
      let fileNameSplit = fileUrlSplit[1].split("?");
      let finalFileName = fileNameSplit[0].split("/");

      let fileNameDecoded = decodeURI(finalFileName[1]);

      return fileNameDecoded;
    } else {
      return "";
    }
  };

  const [getFileUrl] = useLazyQuery(getSignedUrlForDoc, {
    variables: {
      input: {
        method: "get",
        entity: "jobdescription",
        filetype: "file",
        filename: getFileName(),
        vbeid: id,
      },
    },

    onCompleted: (response) => {
      window.open(response.getSignedURL.signedurl);
    },
  });

  const setName = (skillName) => {
    formik.values.primaryskill = skillName;
    setPrimarySkill(skillName);
  };

  const setNameAndVbeid = (value) => {
    let prevSecondarySkills = secondarySkills;
    let prevSkillNames = secondarySkillNames;

    prevSecondarySkills.push({ type: value.vbeid, value: value.name });
    prevSkillNames.push(value.name);

    setSecondarySkills(prevSecondarySkills);
    setSecondaySkillNames(prevSkillNames);
  };

  const removeSecondarySkill = (index) => {
    let prevSecondarySkills = secondarySkills;
    let prevSkillNames = secondarySkillNames;

    setSecondarySkills([
      ...prevSecondarySkills.slice(0, index),
      ...prevSecondarySkills.slice(index + 1),
    ]);

    setSecondaySkillNames([
      ...prevSkillNames.slice(0, index),
      ...prevSkillNames.slice(index + 1),
    ]);
  };

  return (
    <form style={{ textAlign: "left" }} onSubmit={formik.handleSubmit}>
      <Typography variant="h6" gutterBottom className={classes.textFieldLabel}>
        Skill Requirement
      </Typography>

      <Grid container spacing={1}>
        <Grid item md={5}>
          <FormControl component="fieldset" fullWidth={true}>
            <SearchSkill
              id="searchskill"
              setName={setName}
              defaultvalue={primarySkill}
              placeholder="Choose one"
              disable={false}
              label="Primary Skill"
            />

            <FormHelperText className={classes.errorMessage}>
              {formik.errors.primaryskill}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item md={6}>
          <FormControl component="fieldset" fullWidth={true}>
            <SearchAutoCompleteField
              type="skill"
              defaultValue={secondarySkills}
              selectedIndex={removeSecondarySkill}
              label="Secondary Skills"
              placeholder="Choose multiple"
              setNamdAndVbeid={setNameAndVbeid}
            />

            <FormHelperText className={classes.errorMessage}>
              {formik.errors.secondaryskill}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item md={4}>
          <FormControl
            variant="outlined"
            fullWidth={true}
            style={{ margin: "8px 0 0 0" }}
          >
            <SelectField
              label="Experience in Primary Skill"
              onChange={formik.handleChange("expinprimaryskill")}
              value={formik.values.expinprimaryskill}
              options={workExperienceValues}
            />

            <FormHelperText className={classes.errorMessage}>
              {formik.errors.expinprimaryskill}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl className={classes.serviceFormControl} fullWidth={true}>
            <InputField
              label="No of Resources *"
              value={formik.values.resourcecount}
              onChange={formik.handleChange("resourcecount")}
              type="number"
            />
          </FormControl>

          <FormHelperText className={classes.serviceErrorMessage}>
            {formik.errors.resourcecount}
          </FormHelperText>
        </Grid>

        <Grid item xs={4}>
          <FormControl className={classes.serviceFormControl} fullWidth={true}>
            <InputField
              label="Work Duration (in months) *"
              value={formik.values.workduration}
              onChange={formik.handleChange("workduration")}
              type="number"
            />
          </FormControl>

          <FormHelperText className={classes.serviceErrorMessage}>
            {formik.errors.workduration}
          </FormHelperText>
        </Grid>
      </Grid>

      {projectData.worklocation !== "remote" && (
        <>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.textFieldLabel}
          >
            Work Location
          </Typography>

          <Grid container spacing={1}>
            <Grid item md={11}>
              {(projectData.worklocation === "office" ||
                projectData.worklocation === "hybrid") && (
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth={true}>
                      <AutoCompleteField
                        label="Country *"
                        options={optCountries}
                        setValues={onCountrySelect}
                        multiple={false}
                        defaultValue={selectedValue}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth={true}>
                      <AutoCompleteField
                        label="State *"
                        options={optStates}
                        setValues={onStateSelect}
                        defaultValue={selectedState}
                        multiple={false}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}

      <Typography variant="h6" gutterBottom className={classes.textFieldLabel}>
        Budget Details
      </Typography>

      <Grid container spacing={1} style={{ marginBottom: "16px" }}>
        <Grid item md={11}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <FormControl
                className={classes.serviceFormControl}
                fullWidth={true}
                variant="outlined"
              >
                <SelectField
                  label="Currency *"
                  value={formik.values.currency}
                  onChange={formik.handleChange("currency")}
                  options={currencyList}
                />
              </FormControl>

              <FormHelperText className={classes.serviceErrorMessage}>
                {formik.errors.currency}
              </FormHelperText>
            </Grid>

            <Grid item xs={4}>
              <FormControl
                className={classes.serviceFormControl}
                fullWidth={true}
              >
                <InputField
                  label="Rate (per hour) *"
                  value={formik.values.budget}
                  onChange={formik.handleChange("budget")}
                  type="number"
                />
              </FormControl>

              <FormHelperText className={classes.serviceErrorMessage}>
                {formik.errors.budget}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom className={classes.textFieldLabel}>
        Job Description
      </Typography>

      <Grid container spacing={1}>
        <Grid item md={12}>
          <FormControl
            component="fieldset"
            style={{ marginBottom: "16px" }}
            fullWidth={true}
          >
            {type !== "review" ? (
              <Grid container spacing={1}>
                <Grid item md={7}>
                  <JDEditor
                    data={jobDesc}
                    editorId="jdId"
                    isReadOnly={false}
                    saveMethod={saveData}
                    edit={true}
                  />
                </Grid>

                <Grid
                  item
                  md={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "center",
                      margin: "8px 0",
                      fontSize: "0.9rem",
                    }}
                  >
                    or
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      style={{ display: "none" }}
                    />

                    <label htmlFor="contained-button-file">
                      <File
                        vbeid={id}
                        entity="jobdescription"
                        filetype="file"
                        fext=".pdf"
                        paramName="image"
                        inputid={id + "grouplogo"}
                        updateCacheMethod={getUrl}
                        buttonmessage="Upload File"
                      />
                      <div style={{ fontSize: "0.75rem", marginTop: "4px" }}>
                        Please select a pdf file
                      </div>
                    </label>

                    <div
                      style={{
                        margin: "8px",
                        cursor: "pointer",
                        wordBreak: "break-all",
                        fontSize: "0.85rem",
                      }}
                      onClick={onFileClick}
                    >
                      {jdFile && jdFile !== "" ? getFileName() : fileName}
                    </div>
                  </div>
                </Grid>

                <Grid item md={12}>
                  <Typography
                    variant="caption"
                    style={{ color: "red", fontWeight: "bold", margin: "5px" }}
                  >
                    Never include company names or any sensitive information in
                    the job description
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <>
                {projectData.jobdescription !== "" && (
                  <div
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      borderRadius: "3px",
                    }}
                  >
                    <JDEditor
                      data={projectData.jobdescription}
                      editorId="jdId"
                      isReadOnly={true}
                      saveMethod={saveData}
                      edit={false}
                    />
                  </div>
                )}

                {jdFile !== "" && (
                  <div
                    style={{ margin: "8px 0 0 0", cursor: "pointer" }}
                    onClick={onFileClick}
                  >
                    Uploaded File :{" "}
                    <span style={{ color: theme.palette.secondary.main }}>
                      {getFileName()}
                    </span>
                  </div>
                )}
              </>
            )}

            <FormHelperText className={classes.errorMessage}>
              {formik.errors.jobdescription}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      {type !== "review" && (
        <div style={{ margin: "8px 0" }}>
          <NextPrevButtons
            nextDisabled={!formik.isValid}
            prevDisabled={false}
            nextLocation="providers"
            prevLocation="create"
          />
        </div>
      )}
    </form>
  );
}
