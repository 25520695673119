import React, { useState } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "./styles";

import { Grid } from "@material-ui/core";
import "./role.css";
import { useQuery } from "@apollo/client";
import { editTags, listTags } from "./query";

import { useMutation } from "@apollo/client";
import SimpleSnackbar from "../companies/Company2.0/shorts/snackbar";
import SimpleErrorSnackbar from "../companies/Company2.0/shorts/errorsnackbar";
import AlertBox from "../common/AlertBox";
import OutlinedButton from "../companies/company/OutlinedButton";
import AddSwiper from "./AddSwiper";
import { Alert } from "@material-ui/lab";

function Row({ tag, TagFetch }) {
  const [alertBoxEnable, setAlertBoxEnable] = useState(false);
  const [tagEnable, setTagEnable] = useState(tag.action);
  const [timestamp, setTimeStamp] = useState("");

  const classes = useStyles();

  const handleEnableOpen = (createdon) => {
    setTimeStamp(createdon);
    setTagEnable(!tagEnable);
    setAlertBoxEnable(true);
  };
  const handleEnableClose = () => {
    setAlertBoxEnable(false);
  };

  //Remove

  //Enable and disable

  const [
    enableDisable,
    { loading: mutationLoad, error: mutationErrors, data: mutationdata },
  ] = useMutation(editTags, {
    onCompleted: (e) => {
      TagFetch();
    },
  });

  let status = "";

  if (tagEnable) {
    status = "true";
  } else {
    status = "false";
  }

  const handleEnableDisable = () => {
    enableDisable({
      variables: {
        input: {
          timestamp: timestamp,
          action: status,
        },
      },
    });
  };

  console.log("tag", tag.name);
  console.log("tag", tagEnable);

  return (
    <React.Fragment>
      {mutationLoad || mutationdata ? <SimpleSnackbar type="loading" /> : null}
      {mutationErrors ? (
        <SimpleErrorSnackbar msg="Something Went Wrong" />
      ) : null}

      <>
        <TableRow className={classes.root} key={tag.id}>
          <TableCell
            align="left"
            className={tag.action === "false" ? classes.disabledata : null}
          >
            {tag.name}
          </TableCell>
          <TableCell
            align="left"
            className={tag.action === "false" ? classes.disabledata : null}
          >
            {tag.type}
          </TableCell>

          <TableCell
            align="left"
            className={tag.action === "false" ? classes.disabledata : null}
          >
            {tag.value}
          </TableCell>

          <TableCell align="left">
            <span
              className={classes.button}
              onClick={() => handleEnableOpen(tag.createdon)}
            >
              {" "}
              {tag.action === "true" ? "Disable" : "Enable"}
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
            className={classes.expandtable}
          ></TableCell>
        </TableRow>
      </>

      {alertBoxEnable ? (
        <AlertBox
          open={alertBoxEnable}
          title={tag.action === true ? "Disable Tag " : "Enable Tag"}
          onClose={handleEnableClose}
          description={
            tag.action === true
              ? "Are you sure you want to Disable Option ?"
              : "Are you sure you want to Enable Option ?"
          }
          onConfirm={handleEnableDisable}
        />
      ) : null}
    </React.Fragment>
  );
}

export default function TagManagement({ userInfo }) {
  const classes = useStyles();

  const { data: listTag, refetch: TagFetch } = useQuery(listTags, {
    variables: { input: {} },
  });
  const [swipperOpen, setSwipperOpen] = useState(false);

  const handleSwipperOpen = () => {
    setSwipperOpen(true);
  };

  const handleSwipperClose = () => {
    setSwipperOpen(false);
  };

  return (
    <div className={classes.tagtable}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={10}>
          {" "}
          <h4
            style={{
              textAlign: "left",
              // padding: "8px",
              marginBottom: "16px",
              fontWeight: "500",
            }}
          >
            Tag Management
          </h4>
        </Grid>
        <Grid
          item
          md={1}
          style={{
            textAlign: "left",
            // padding: "8px",
            marginBottom: "2px",
            fontWeight: "500",
          }}
        >
          <OutlinedButton
            label="+ Add Tag"
            type="contained"
            onClick={handleSwipperOpen}
          />
        </Grid>

        <Grid item md={11} className={classes.entityinfo}>
          <Alert severity="info" className={classes.item}>
            Consider Tags as Business units around which the stakeholders roles
            are aligned. In this section, define your organization tags which
            will be flagged as mandatory fields in every project. Tag Management
            helps in: <br />
            <span style={{ marginLeft: "16px" }}>i.Generating Reports </span>
            <br />
            <span style={{ marginLeft: "16px" }}>
              ii.Maintaining continuity of Project life cycle{" "}
            </span>
            <br />
          </Alert>
        </Grid>
        <Grid item md={11}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="center">Value</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              {listTag?.listTag.length !== 0 ? (
                <TableBody>
                  {listTag?.listTag?.map((tag) => {
                    return (
                      <Row tag={tag} userInfo={userInfo} TagFetch={TagFetch} />
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  {" "}
                  <TableRow>
                    <TableCell align="center !important" colSpan={2}>
                      <p>No Tags Found</p>
                    </TableCell>
                  </TableRow>
                  {/* <p className={classes.nodocs}>No Tags found</p> */}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {swipperOpen ? (
        <AddSwiper
          open={swipperOpen}
          onClose={handleSwipperClose}
          type="tag"
          title="Add Tag"
          refetch={TagFetch}
          // id={role.id}
          // userInfo={userInfo}
          // title={title}
        />
      ) : null}
    </div>
  );
}
