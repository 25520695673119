/**
 * Styles for list page
 */

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainListGrid: {
    marginTop: "4px",
  },
  addNewButtonGroup: {
    marginTop: theme.spacing(1),
  },
  root: {
    maxWidth: "100%",
    color: "#0e3046",
    lineHeight: "1.8",
  },
  listItem: {
    padding: 0,
  },
  listImage: {
    minWidth: "40px",
    margin: "0 4px 0 0",
  },

  productImage: {
    "& img": {
      objectFit: "contain",
    },
  },
  itemText: {
    padding: "0 8px",
    width: "72%",
  },

  descriptionText: {
    fontSize: "0.85rem",
    lineHeight: "1.5",
    paddingTop: "2px",
    color: "#000",
  },

  viewButtons: {
    display: "block",
    "& button": {
      float: "left",
      textTransform: "capitalize",
      fontSize: "0.7rem",
      padding: theme.spacing(0, 0.5),
      justifyContent: "left",
      fontWeight: "500",
    },
  },
  loadMoreOption: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    "& button": {
      float: "none",
      textTransform: "capitalize",
    },
  },
  listItemRoot: {
    display: "flex",
    alignItems: "center",
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
      "& span": {
        padding: 0,
      },
      "& svg": {
        width: "18px",
        height: "18px",
      },
    },
  },
  list: {
    display: "flex",
    flexWrap: "wrap",
  },
}));
