import React from "react";

import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  contactName: {
    cursor: "pointer",
    lineHeight: "1.5",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  productHead: {
    fontSize: "0.8rem",
    fontWeight: "500",
  },
  selectedContact: {
    fontWeight: "600",
  },
  productName: {
    fontWeight: "normal",
    fontSize: "0.75rem",
  },
  selectedProductName: {
    fontWeight: "500",
  },
}));

export default function ContactList({
  companyname,
  name,
  vbeid,
  sendValues,
  selected,
}) {
  const classes = useStyles();

  const onClick = () => {
    sendValues(companyname, name, vbeid);
  };

  const companyName = clsx({
    [classes.contactName]: true,
    [classes.productHead]: true,
  });

  const selectedCompanyName = clsx({
    [classes.contactName]: true,
    [classes.productHead]: true,
    [classes.selectedContact]: true,
  });

  const productName = clsx({
    [classes.contactName]: true,
    [classes.productName]: true,
  });

  const selectedProductName = clsx({
    [classes.contactName]: true,
    [classes.productName]: true,
    [classes.selectedProductName]: true,
  });

  return (
    <>
      <Grid container spacing={0} onClick={onClick}>
        <Grid item md={12}>
          <Typography
            className={selected ? selectedCompanyName : companyName}
            variant="subtitle1"
            companyname={companyname}
            productname={name}
            productid={vbeid}
          >
            {companyname}
          </Typography>

          <Typography
            variant="subtitle1"
            className={selected ? selectedProductName : productName}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
