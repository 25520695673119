import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  LinearProgress,
  FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { useMutation } from "@apollo/client";

import {
  SIGNED_URL,
  UPDATE_QA_ITEM,
  UPDATE_RESPONSE_ITEM,
  UPDATE_SOW_ITEM,
} from "../../../queries/query";

import { useFormik } from "formik";
import { useState } from "react";

const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  textfield: {
    height: theme.spacing(100),
  },
  properties: {
    width: theme.spacing(50),
  },
  propertiestitle: {
    marginBottom: theme.spacing(2),
  },
  propsbuttongroups: {
    marginTop: theme.spacing(2),
  },
  propsbutton: {
    margin: theme.spacing(1),
  },
  helpicon: {
    fontSize: theme.spacing(1.5),
    cursor: "pointer",
  },
  checkbox: {
    "& span": {
      fontSize: theme.spacing(1.4),
    },
  },
  input: {
    display: "none",
  },
  download: {
    fontSize: "0.7rem",
    textTransform: "none",
  },
  rtitle: {
    fontWeight: "bold",
  },
  downloadDiv: {
    display: "flex",
  },
}));

const validateItem = (values) => {
  const errors = {};
  //   if (!values.value) {
  //     errors.value = "Required";
  //   }

  return errors;
};

function FileBrowse(props) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <input
          className={classes.input}
          id={"file" + props.id}
          type="file"
          disabled={props.readOnly}
          onChange={(e) => {
            props.uploadFile(e);
          }}
        />

        <label htmlFor={"file" + props.id}>
          <Button
            color="secondary"
            size="small"
            component="span"
            startIcon={<CloudUploadIcon />}
            disabled={props.readOnly}
          >
            Attachment
          </Button>
        </label>
      </Grid>

      {props.upload ? (
        <Grid item xs={12} className={classes.progressbar}>
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={props.uploadPer}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

function DownLoadFile(props) {
  const classes = useStyles();

  const [dsignedUrl, { data: dsdata, loading: dsloading, error: dserror }] =
    useMutation(SIGNED_URL, {
      onCompleted: (e) => {
        let url = e.gettemplateSignedURL.signedurl;
        window.open(url);
      },
      onError: (err) => {
        console.log(err);
      },
    });

  function deleteFile() {
    let fileValues = JSON.parse(props.formik.values.attachment);

    let keepFiles = fileValues.filter((file) => {
      return file.name !== props.name;
    });

    props.formik.setFieldValue("attachmentfilename", "");
    props.formik.setFieldValue("attachment", keepFiles);
    props.formik.setFieldValue("action", "delete");
    props.formik.handleSubmit();
  }

  function downLoadFile() {
    dsignedUrl({
      variables: {
        input: {
          projectid: props.formik.values.projectid,
          productid: props.formik.values.productvbeid,
          attachmentfilename: props.name,
          method: "get",
        },
      },
    });
  }

  return (
    <Grid container>
      <Grid item xs={11} className={classes.downloadDiv}>
        <Button
          onClick={downLoadFile}
          color="secondary"
          size="small"
          component="span"
          className={props.readOnly ? "" : classes.download}
          startIcon={<AttachFileIcon fontSize="small" />}
        >
          {props.name}
        </Button>
      </Grid>

      {props.readOnly ? null : (
        <Grid item xs={1}>
          <IconButton onClick={deleteFile}>
            <CloseIcon
              color="secondary"
              className={classes.deleteFile}
              fontSize="small"
            />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

function getQuery(type) {
  switch (type) {
    case "response_template":
      return UPDATE_RESPONSE_ITEM;

    case "qa_template":
      return UPDATE_QA_ITEM;

    case "sow_template":
      return UPDATE_SOW_ITEM;

    default:
      return null;
  }
}

function getQueryReturnItem(type) {
  switch (type) {
    case "response_template":
      return "addResTempItemvalue";

    case "qa_template":
      return "addQuestionTempItemvalue";

    case "sow_template":
      return "addAwardTempItemvalue";

    default:
      return null;
  }
}

export default function RTextItem(props) {
  const classes = useStyles();

  let fileProps = props.values.attachment;

  if (fileProps !== "") {
    fileProps = JSON.parse(props.values.attachment);
  } else {
    fileProps = [];
  }

  const [metadata, setMetadata] = useState(props.metadata);
  const [upload, setUpload] = useState(false);
  const [uploadPer, setUploadPer] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState(fileProps);

  if (fileProps.length !== filesUploaded.length) {
    setFilesUploaded(fileProps);
  }

  const [updateItem, { loading }] = useMutation(getQuery(props.type), {
    onCompleted: (e) => {
      formik.setFieldValue(
        "timestamp",
        e[getQueryReturnItem(props.type)][0].timestamp
      );

      props.refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [signedUrl] = useMutation(SIGNED_URL, {
    onCompleted: (e) => {
      let url = e.gettemplateSignedURL.signedurl;
      let attachment = e.gettemplateSignedURL.attachment;

      formik.setFieldValue("attachment", attachment);

      putFile(url);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const formik = useFormik({
    initialValues: props.values,

    validate: validateItem,

    onSubmit: (values) => {
      var input = { ...values };

      if (input.action !== "delete") {
        let oldFileProps = fileProps;

        if (input.attachment !== "" && input.attachment.length !== 0) {
          let fileValues = {
            path: input.attachment,
            name: input.attachmentfilename,
          };

          oldFileProps.push(fileValues);

          input.attachment = JSON.stringify(oldFileProps);
        } else {
          input.attachment = "";
        }
      } else {
        if (input.attachment !== "" && input.attachment.length !== 0) {
          input.attachment = JSON.stringify(input.attachment);
        } else {
          input.attachment = "";
        }
      }

      if (input.timestamp === null) {
        delete input.timestamp;
      }
      if (input.createdby) {
        delete input.createdby;
      }

      delete input.action;

      updateItem({ variables: { input: [input] } });
    },
  });

  function uploadFile(e) {
    setUpload(true);
    var tfile = e.target.files[0];
    let name = tfile.name;

    signedUrl({
      variables: {
        input: {
          projectid: formik.values.projectid,
          productid: formik.values.productvbeid,
          attachmentfilename: name,
          method: "put",
        },
      },
    });
  }

  async function putFile(url) {
    let file = document.getElementById("file" + props.id).files[0];

    await axios
      .request({
        method: "put",
        url: url,
        data: file,
        onUploadProgress: (p) => {
          const percent = Math.round((p.loaded / p.total) * 100);
          setUploadPer(percent);
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          let uploadedFile = filesUploaded;
          uploadedFile.push(file.name);

          setUpload(false);
          setFilesUploaded(uploadedFile);

          formik.setFieldValue("attachmentfilename", file.name);
          formik.setFieldValue("action", "add");

          formik.handleSubmit();
        }
      });
  }

  return (
    <Grid container alignItems="center" className={classes.root} spacing={1}>
      {!props.hidetitle ? (
        <Grid item xs={4}>
          <Typography
            variant="caption"
            gutterBottom
            className={props.readOnly ? classes.rtitle : ""}
          >
            {" "}
            {metadata.title}{" "}
          </Typography>
        </Grid>
      ) : null}

      <Grid item xs={props.hidetitle ? 12 : 4}>
        <FormControl autoComplete="new-password">
          {props.readOnly ? (
            <Typography variant="caption">{formik.values.value} </Typography>
          ) : (
            <TextField
              disabled={loading || props.readOnly}
              autoComplete="off"
              id="filled-full-width"
              name="value"
              onChange={formik.handleChange}
              helperText={formik.errors.value}
              error={Boolean(formik.errors.value)}
              value={formik.values.value}
              placeholder={props.placeholder}
              fullWidth
              variant="outlined"
              size="small"
              onBlur={formik.handleSubmit}
            />
          )}
        </FormControl>
      </Grid>

      <Grid item xs={5}>
        {metadata.attachment ? (
          <>
            <FileBrowse
              id={props.id}
              uploadFile={uploadFile}
              upload={upload}
              uploadPer={uploadPer}
              readOnly={props.readOnly}
            />

            {filesUploaded.length !== 0 &&
              filesUploaded.map((file) => {
                return (
                  <DownLoadFile
                    name={file.name}
                    setUpload={setUpload}
                    setUploadPer={setUploadPer}
                    formik={formik}
                    readOnly={props.readOnly}
                    updateItem={updateItem}
                  />
                );
              })}
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}
