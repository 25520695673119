import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Card, Box, CardContent, Grid, Paper } from "@material-ui/core";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import Policy from "../../../shared/policy";

import {
  getUsecaseById,
  getUsecaseByUsecaseId,
  updateUsecaseData,
  userInfoQuery,
} from "./query";
import { userPermission } from "../../groups2.0/query";

import DataPageHeader from "../../common/DataPageHeader";

import { useStyles } from "./styles";
import DataLoading from "../../products/product2.0/DataLoading";
import LeftPaneProducts from "../../products/product2.0/LeftPaneProducts";
import SolutionTabs from "./SolutionTabs";

export default function UsecasePage() {
  const classes = useStyles();
  const { id } = useParams();
  const client = useApolloClient();

  const [editName, setEditName] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editNameValue, setEditNameValue] = useState("");
  const [editDescriptionValue, setEditDescriptionValue] = useState("");
  const [isReadOnly, setReadonly] = useState(true);
  const [isAdmin, setAdmin] = useState(true);

  const admin_role = "PROFILE_ADMIN";

  //user information

  const user = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  const editNameClick = () => {
    setEditName(true);
  };

  const saveNameClick = () => {
    setEditName(false);
    updateCache("name", editNameValue);
  };

  const editDescriptionClick = () => {
    setEditDescription(true);
  };

  const saveDescriptionClick = () => {
    setEditDescription(false);
    updateCache("shortdescription", editDescriptionValue);
  };

  function afterLoadingMainData(e) {
    setAdmin(user.getLoginUserInfo.companyvbeid === e.usecaseByID.companyvbeid);

    setReadonly(
      !(
        (user.getLoginUserInfo.companyvbeid === e.usecaseByID.companyvbeid &&
          user.getLoginUserInfo.role.includes(admin_role)) ||
        user.getLoginUserInfo.isVbridgeUser === "true"
      )
    );
  }

  const { data } = useQuery(getUsecaseById, {
    variables: { input: { vbeid: id } },
    onCompleted: (e) => {
      afterLoadingMainData(e);
    },
  });

  ///update usecase

  const [postUsecaseMutation] = useMutation(updateUsecaseData);

  const postUpdateUsecase = (paramName, paramValue, id) => {
    postUsecaseMutation({
      variables: {
        input: {
          vbeid: id,
          [paramName]: paramValue,
        },
      },
    });
  };

  ////update cache
  const updateCache = (paramName, paramValue) => {
    if (paramValue !== "") {
      let updatedUsecase = { ...data.usecaseByID };
      updatedUsecase[paramName] = paramValue;

      client.writeQuery({
        query: getUsecaseById,
        data: {
          usecaseByID: updatedUsecase,
        },

        variables: { input: { vbeid: id } },
      });
      postUpdateUsecase(paramName, paramValue, id);
    }
  };

  //update cache for longdescription
  function UpdateUsecCaseCache(e) {
    let UpdatedlongDescData = e;
    let updatedUsecase = { ...data.usecaseByID };
    updatedUsecase.longdescription = UpdatedlongDescData;

    client.writeQuery({
      query: getUsecaseById,
      data: {
        usecaseByID: updatedUsecase,
      },
      variables: { input: { vbeid: id } },
    });
  }

  const handleNameChange = (e) => {
    setEditNameValue(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setEditDescriptionValue(e.target.value);
  };

  const { data: similarUsecases } = useQuery(getUsecaseByUsecaseId, {
    variables: { input: { vbeid: id } },
  });

  const userpermission = client.readQuery({
    query: userPermission,
    variables: { input: {} },
  });

  return (
    <div className={classes.root}>
      <Box m={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <Paper elevation={1} square={true}>
              <Card className={classes.card}>
                {data ? (
                  <CardContent style={{ textAlign: "left" }}>
                    <DataPageHeader
                      data={data?.usecaseByID}
                      isReadOnly={true}
                      vbeid={id}
                      updateCache={updateCache}
                      editName={editName}
                      editDescription={editDescription}
                      editNameClick={editNameClick}
                      handleNameChange={handleNameChange}
                      saveNameClick={saveNameClick}
                      editDescriptionClick={editDescriptionClick}
                      handleDescriptionChange={handleDescriptionChange}
                      saveDescriptionClick={saveDescriptionClick}
                      type="resource"
                      isAdmin={isAdmin}
                      userPermission={userpermission}
                      userInfo={user?.getLoginUserInfo}
                    />

                    <SolutionTabs
                      isReadOnly={true}
                      isAdmin={isAdmin}
                      UpdateUsecCaseCache={(e) => UpdateUsecCaseCache(e)}
                    />
                  </CardContent>
                ) : (
                  <DataLoading />
                )}
              </Card>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={3}>
            {isAdmin ? null : (
              <LeftPaneProducts
                title="Similar Resources"
                companyName={data?.productById?.companyname}
                products={similarUsecases?.usecaseByusecaseID}
                vbeid={id}
                type="Solutions"
              />
            )}

            <Policy />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
