import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import PrimaryButton from "../common/PrimaryButton";

export const useStyles = makeStyles((theme) => ({
  blockedUserRoot: {
    marginTop: theme.spacing(10),
  },
  blockedText: {
    textAlign: "center",
  },
  redirectButton: {
    "& button": {
      marginTop: theme.spacing(2),
      float: "none",
    },
  },
}));

export default function UnauthorisedUser({ drawerOpen, mainDrawerClick }) {
  const classes = useStyles();

  const onSupplierPortalClick = () => {
    if (window.location.hostname === "dev.vbridgehub.in") {
      window.location.href = "https://supplier-dev.vbridgehub.in";
    } else {
      window.location.href = "https://supplier.vbridgehub.com";
    }
  };

  if (drawerOpen) {
    mainDrawerClick("", "close");
  }

  return (
    <div className={classes.blockedUserRoot}>
      <Grid container className={classes.blockedText}>
        <Grid md={12} item>
          You are already registered on vBridge Hub supplier portal. Did you
          mean to log in to vBridge Hub supplier portal?
        </Grid>

        <Grid md={12} item className={classes.redirectButton}>
          <PrimaryButton
            label="Supplier Portal"
            variant="contained"
            onClick={onSupplierPortalClick}
          />
        </Grid>
      </Grid>
    </div>
  );
}
