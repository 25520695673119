import ItemButton from "./ItemButton";

const groups = require("../../../data/services/groups.json");
const topics = require("../../../data/services/topics.json");

export default function ServicesMenu({ handleDrawerClose }) {
  return (
    <ItemButton
      title="Services"
      groups={groups}
      topics={topics}
      handleDrawerClose={handleDrawerClose}
    />
  );
}
