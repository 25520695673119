import React from "react";
//import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import { useMutation, gql } from "@apollo/client";
import IconButton from "@material-ui/core/IconButton";

const axios = require("axios");

const signed = gql`
  mutation upload($input: projectinput!) {
    signedURLforSupportDoc(input: $input) {
      signedurl
      id
      path
      name
    }
  }
`;

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "15%",
//     margin: "auto",
//     marginTop: "5%",
//   },
//   logoImg: {
//     width: "100%",
//     marginBottom: "2%",
//   },
//   bar: {},
// }));

export default function UploadDoc(props) {
  const [status, setStatus] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [signedUrl] = useMutation(signed, {
    onCompleted: (e) => {
      afterGettingSignedUrl(e);
    },
  });

  function getSignedUrl(e) {
    // console.log({
    //     method: "put",
    //     vbeid: props.vbeid,
    //     type: props.type,
    //     name: e,
    //   })

    signedUrl({
      variables: {
        input: {
          method: "put",
          name: e,
          vbeid: props.vbeid,
          type: props.type,
        },
      },
    });
  }

  function startUploadFile(e) {
    var fileName = e.target.files[0].name;

    setMessage("Preparing for upload ..");
    setStatus(true);
    getSignedUrl(fileName);
  }

  function afterGettingSignedUrl(e) {
    uploadFile(e.signedURLforSupportDoc.signedurl);

    props.updateCacheMethod(
      props.paramName,
      e.signedURLforSupportDoc.id,
      props.paramName1,
      e.signedURLforSupportDoc.name,
      props.paramName2,
      e.signedURLforSupportDoc.path
    );
  }

  async function uploadFile(url) {
    await axios
      .request({
        method: "put",
        url: url,
        data: document.getElementById(props.inputid).files[0],
        onUploadProgress: (p) => {
          const percent = Math.round((p.loaded / p.total) * 100);
          setMessage("Uploading ..." + percent + "%");
        },
      })
      .then(function (response) {
        setStatus(false);

        //let newUrl = response.config.url.split("?")[0];
        //let newFilepath = newUrl.substring(newUrl.lastIndexOf(".com") + 4);
        //let filepath = newFilepath.substring(1);

        //props.updateCacheMethod(props.paramName, filepath);
      });
  }

  return (
    <div>
      <Snackbar
        open={status}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="info">{message}</Alert>
      </Snackbar>
      <input type="file" id={props.inputid} onChange={startUploadFile} hidden />
      <label htmlFor={props.inputid}>
        {props.buttontype === "icon" ? (
          <IconButton
            variant="contained"
            color="primary"
            component="span"
            startIcon={props.icon}
          >
            {props.icon}
          </IconButton>
        ) : (
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={props.icon}
          >
            {props.buttonmessage}
          </Button>
        )}
      </label>
    </div>
  );
}
