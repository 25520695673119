import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";

export default function ResultListItem(props) {
  const {
    item,
    handleClose,
    setValue,
    setDomain,
    setName,
    setCompany,
    sentAwardVbeid,
    setNamdAndVbeid,
    type,
  } = props;
  const classes = useStyles();

  function handleItemClick(e) {
    e.preventDefault();

    let { name, domain, vbeid } = item;

    if (setCompany) {
      setCompany(true);
    }

    if (setValue) {
      setValue(name);
    }

    if (setName) {
      setName(name);
    }

    if (sentAwardVbeid) {
      sentAwardVbeid(vbeid);
    }

    if (setDomain) {
      setDomain("https://" + domain);
    }

    if (setNamdAndVbeid) {
      setNamdAndVbeid(item);
    }

    handleClose();
  }

  return (
    <ListItem key={item.vbeid} button dense={true} onClick={handleItemClick}>
      {type === "award" && (
        <ListItemAvatar className={classes.companyLogo}>
          <Avatar
            className={classes.avatar}
            aria-describedby="avatar-popover"
            variant="square"
            size="small"
            alt={item.name}
            src={item.logo}
          />
        </ListItemAvatar>
      )}

      <ListItemText
        primary={
          <Typography
            variant="button"
            display="block"
            color="primary"
            className={classes.text}
          >
            {item.name}
          </Typography>
        }
      />
    </ListItem>
  );
}
