import { gql } from "@apollo/client";

export const getProductById = gql`
  query productinformation($input: productinput!) {
    productById(input: $input) {
      id
      name
      image
      shortdescription
      vbeid
      companyvbeid
      companyname
      companylocation
      topiclist {
        name
        vbeid
      }
      grouplist {
        name
        vbeid
      }
      skilllist {
        name
        vbeid
      }
      servicetypelist
      groupvbeid
      groupname
      longdescription
      producturl
      founded
      fundround
      availability
      saasBased
      pricingModel
      supportCoverage
      marketplaceListing
      type
      longdesimage
    }
  }
`;

export const getLongDescImage = gql`
  query getLongDescImage($input: s3input!) {
    getSignedURL(input: $input) {
      signedurl
    }
  }
`;

export const getProducts = gql`
  query productinformation($input: companyinput!) {
    productByComapnyId(input: $input) {
      id
      name
      image
      vbeid
    }
  }
`;

export const updateProductInfo = gql`
  mutation update($input: productinput!) {
    updateProduct(input: $input) {
      name
      image
      producturl
      longdescription
      shortdescription
    }
  }
`;

export const getProductByProductId = gql`
  query productinformation($input: productinput!) {
    productsByProductID(input: $input) {
      name
      image
      vbeid
      id
    }
  }
`;

export const getUsecasesByProductId = gql`
  query usecasesinformation($input: usecaseinput!) {
    usecaseByProductId(input: $input) {
      name
      image
      shortdescription
      vbeid
      industryvertical
    }
  }
`;

export const getCasestudiesByProductId = gql`
  query casestudyinformation($input: casestudyinput!) {
    casestudyByProductId(input: $input) {
      name
      image
      shortdescription
      vbeid
      businessvertical
    }
  }
`;

export const signed = gql`
  mutation upload($input: projectinput!) {
    signedURLforSupportDoc(input: $input) {
      signedurl
      id
      path
      name
    }
  }
`;

export const listDocs = gql`
  query docs($input: projectinput!) {
    listsupportDoc(input: $input) {
      name
      signedurl
      createdby
      id
    }
  }
`;

export const deleteDocument = gql`
  mutation update($input: projectinput!) {
    deleteSupportDoc(input: $input) {
      id
    }
  }
`;

export const postProduct = gql`
  mutation updateproduct($input: productinput!) {
    updateProduct(input: $input) {
      longdescription
    }
  }
`;

export const getProductInfoById = gql`
  query productinformation($input: productinput!) {
    productById(input: $input) {
      longdescription
      vbeid
      id
    }
  }
`;

export const postUseCase = gql`
  mutation usecase($input: usecaseinput!) {
    addUsecaseShort(input: $input) {
      vbeid
      name
      url
    }
  }
`;

// topicvbeid
//       topicname
