import React, { useState } from "react";

import {
  withStyles,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

import { useStyles } from "./styles";
import ListLoad from "./ListLoad";

import { VBRIDGEHUB_MEDIAURL } from "../../../constants";
import { useHistory } from "react-router-dom";

const StyledMenu = withStyles((theme) => ({
  paper: {
    border: "1px solid #d3d4d5",
    backgroundColor: "#eee",
    "& .MuiList-root": {
      display: "flex",
      margin: theme.spacing(0.5, 1),
    },
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0.5, 0, 0.5),
    fontSize: "0.8rem",
    display: "block",
    textAlign: "center",
    "&:focus": {
      //   backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        // color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function ViewButtons({
  label,
  handleClick,
  vbeid,
  companyvbeid,
  data,
  count,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(false);

  const onLabelClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelected(true);
    handleClick(label, vbeid, companyvbeid);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelected(false);
  };

  const handleRedirect = (value) => {
    let id = value.vbeid;

    if (
      label === "Customers" ||
      label === "Partners" ||
      label === "Resources"
    ) {
      id = value.companyid;
    }

    if (label === "Resources") {
      history.push("/usecase/" + id);
    } else if (label === "Success Stories") {
      history.push("/casestudy/" + id);
    } else if (label === "Customers" || label === "Partners") {
      history.push("/company/" + id);
    } else history.push("/company/" + companyvbeid);
  };

  const handleViewMore = (companyvbeid, label) => {
    let pathLabel = label;

    if (label === "Success Stories") {
      pathLabel = "SuccessStories";
    }

    if (label !== "Customers" && label !== "Partners" && label !== "Awards") {
      history.push("/company/" + companyvbeid + "/" + pathLabel);
    } else {
      history.push("/company/" + companyvbeid);
    }
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        color="primary"
        onClick={onLabelClick}
        className={selected ? classes.selectedInfo : ""}
      >
        {label}{" "}
        {count ? <span className={classes.labelCount}>({count})</span> : null}
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {data && data.length !== 0 ? (
          data.map((value, index) => {
            let companyImage = value.image;
            if (!companyImage && value.companyurl) {
              const curl = new URL(value.companyurl);
              companyImage = `https://logo.clearbit.com/${curl.hostname}`;
            }

            if (index < 4) {
              return (
                <StyledMenuItem onClick={() => handleRedirect(value)}>
                  <ListItemAvatar className={classes.avatarRoot}>
                    <Avatar
                      variant="square"
                      alt={value.name}
                      src={
                        value.image
                          ? VBRIDGEHUB_MEDIAURL + "/" + value.image
                          : companyImage
                      }
                    >
                      <CancelPresentationIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText className={classes.dataList}>
                    {value.name} {label === "Awards" ? value.year : null}
                  </ListItemText>
                </StyledMenuItem>
              );
            } else if (index === data.length - 1) {
              return (
                <Button
                  className={classes.viewMoreOption}
                  onClick={() => handleViewMore(companyvbeid, label)}
                >
                  View More
                </Button>
              );
            } else return null;
          })
        ) : data && data.length === 0 ? (
          <StyledMenuItem className={classes.noDataItem}>
            <ListItemText className={classes.noDataText}>
              No {label}
            </ListItemText>
          </StyledMenuItem>
        ) : (
          <StyledMenuItem>
            <ListItemText>
              <ListLoad />
            </ListItemText>
          </StyledMenuItem>
        )}
      </StyledMenu>
    </div>
  );
}
