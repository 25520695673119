import { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

import { useFormik } from "formik";

import { UPDATE_PROJECT } from "../../queries/query";

import { useStyles } from "./styles";

import { useMutation } from "@apollo/client";

import productTopics from "../../../../../data/products/topics.json";
import serviceTopics from "../../../../../data/services/topics.json";

import AutoCompleteField from "../../../common/AutoCompleteField";
import SearchAutoCompleteField from "../../../common/SearchAutoCompleteField";
import SelectField from "../../../common/SelectField";

import { workLocationValues } from "./ServiceProject/DropdownData";
import { getVbeidOfSelectedCountry } from "../../../../../functions/helper";
import InputField from "../../../common/InputField";
import PageButtons from "./WorkPackage/PageButtons";

const countries = require("../../../../../data/countriesdata.json");
const states = require("../../../../../data/states_bycountries.json");
const currencyList = require("../../../../../data/currency.json");

export default function AddDescForm({
  projectData,
  refetch,
  projectType,
  type,
}) {
  const history = useHistory();
  const classes = useStyles();

  const { shortdescription, vbeid, country } = projectData;

  let countryVbeid = country && getVbeidOfSelectedCountry(country);

  let secondarySkillsObj = [];
  let optCountries = [];
  let optStates = [];

  const [selectedState, setSelectedState] = useState(projectData.state || "");
  const [selectedValue, setSelectedValues] = useState(
    projectData.country || ""
  );
  const [countrychange, setCountryChange] = useState(true);
  const [countryvbeid, setCountryvbeid] = useState(countryVbeid || "");
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [secondarySkills, setSecondarySkills] = useState(
    secondarySkillsObj || []
  );
  const [secondarySkillNames, setSecondaySkillNames] = useState(
    projectData.secondaryskill || []
  );

  countries.map((country) => {
    return optCountries.push({
      name: country.countryname,
      value: country.countryname,
      vbeid: country.countryvbeid,
    });
  });

  states.map((state) => {
    if (
      selectedValue === state["cntry.name"] ||
      countryvbeid === state["cntry.vbeid"]
    ) {
      return optStates.push({
        name: state["ste.name"],
        value: state["ste.name"],
        vbeid: state["cntry.vbeid"],
      });
    } else return null;
  });

  const onCountrySelect = (values, selectedVbeid) => {
    setCountryChange(false);
    setSelectedValues(values);
    formik.values.country = values;
    setCountryvbeid(selectedVbeid);
  };

  const onStateSelect = (values, selectedVbeid) => {
    setCountryChange(false);
    setSelectedState(values);
    formik.values.state = values;
    setCountryvbeid(selectedVbeid);
  };

  if (projectData.secondaryskill && projectData.secondaryskill.length !== 0) {
    projectData.secondaryskill.forEach((skill) => {
      secondarySkillsObj.push({ type: skill, value: skill });
    });
  }

  let topics = projectData.type === "service" ? serviceTopics : productTopics;

  let topicList = [];

  topics.forEach((topic) => {
    topicList.push({ name: topic["n.name"], value: topic["n.vbeid"] });
  });

  const validateShort = (values) => {
    const errors = {};
    if (!values.shortdescription) {
      errors.shortdescription = "Please enter short description";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      vbeid: vbeid,
      shortdescription: shortdescription,
      worklocation: projectData.worklocation,
      currency: projectData.currency,
      budget: projectData.budget,
      secondaryskill: projectData.secondaryskill,
    },
    validate: validateShort,
    onSubmit: (values) => {
      values.secondaryskill = secondarySkillNames;

      updateProject({ variables: { input: values } });
    },
  });

  const [updateProject, { loading }] = useMutation(UPDATE_PROJECT, {
    onCompleted: (e) => {
      refetch();
      history.push("/requirement-template/" + e.updateProjectV1.vbeid);
    },
  });

  const onTopicSelect = (values) => {
    setSelectedTopics(values);
  };

  const removeSecondarySkill = (index) => {
    let prevSecondarySkills = secondarySkills;
    let prevSkillNames = secondarySkillNames;

    setSecondarySkills([
      ...prevSecondarySkills.slice(0, index),
      ...prevSecondarySkills.slice(index + 1),
    ]);

    setSecondaySkillNames([
      ...prevSkillNames.slice(0, index),
      ...prevSkillNames.slice(index + 1),
    ]);
  };

  const setNameAndVbeid = (value) => {
    let prevSecondarySkills = secondarySkills;
    let prevSkillNames = secondarySkillNames;

    prevSecondarySkills.push({ type: value.vbeid, value: value.name });
    prevSkillNames.push(value.name);

    setSecondarySkills(prevSecondarySkills);
    setSecondaySkillNames(prevSkillNames);
  };

  return (
    <FormControl component="fieldset" className={classes.formControlclass}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.mainForm}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.textFieldLabel}
            >
              Add project short description
            </Typography>
          </Grid>

          <Grid item>
            <TextField
              id="filled-full-width"
              name="shortdescription"
              placeholder="Project short description"
              multiline
              rows={8}
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.shortdescription}
              error={
                formik.touched.shortdescription &&
                Boolean(formik.errors.shortdescription)
              }
              disabled={projectData.role[0] === "ADDED_TO"}
            />

            <FormHelperText className={classes.errorMessage}>
              {formik.errors.shortdescription}
            </FormHelperText>

            <Typography
              variant="caption"
              style={{ color: "red", fontWeight: "bold", margin: "5px" }}
            >
              Never include company names or any sensitive information in the
              short description
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.textFieldLabel}
            >
              Select Categories and Skills
            </Typography>

            <Typography variant="caption">
              Identify the categories and skills for this project
            </Typography>
          </Grid>

          <Grid container xs={12} spacing={1}>
            <Grid item md={projectType === "workpackage" ? 6 : 8}>
              <AutoCompleteField
                options={topicList}
                label="Select Categories"
                setValues={onTopicSelect}
                defaultValue={selectedTopics}
                multiple={true}
              />
            </Grid>

            {projectType === "workpackage" && (
              <Grid item md={6} style={{ marginTop: "8px" }}>
                <SearchAutoCompleteField
                  type="skill"
                  defaultValue={secondarySkills}
                  selectedIndex={removeSecondarySkill}
                  label="Select Skills"
                  placeholder="Choose multiple"
                  setNamdAndVbeid={setNameAndVbeid}
                />
              </Grid>
            )}
          </Grid>

          {projectType === "workpackage" && (
            <>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.textFieldLabel}
              >
                Service Delivery Model
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.serviceFormControl}
                  >
                    <SelectField
                      label="Workplace type *"
                      onChange={formik.handleChange("worklocation")}
                      value={formik.values.worklocation}
                      options={workLocationValues}
                      type="workplaceOption"
                    />

                    <FormHelperText className={classes.errorMessage}>
                      {formik.errors.worklocation}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              {formik.values.worklocation !== "remote" && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.textFieldLabel}
                  >
                    Work Location
                  </Typography>

                  <Grid container spacing={1}>
                    <Grid item md={11}>
                      {(formik.values.worklocation === "office" ||
                        formik.values.worklocation === "hybrid") && (
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth={true}>
                              <AutoCompleteField
                                label="Country *"
                                options={optCountries}
                                setValues={onCountrySelect}
                                multiple={false}
                                defaultValue={selectedValue}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth={true}>
                              <AutoCompleteField
                                label="State *"
                                options={optStates}
                                setValues={onStateSelect}
                                defaultValue={selectedState}
                                multiple={false}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}

              <Typography
                variant="h6"
                gutterBottom
                className={classes.textFieldLabel}
              >
                Budget Details
              </Typography>

              <Grid item md={11}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl
                      className={classes.serviceFormControl}
                      fullWidth={true}
                      variant="outlined"
                    >
                      <SelectField
                        label="Currency *"
                        value={formik.values.currency}
                        onChange={formik.handleChange("currency")}
                        options={currencyList}
                      />
                    </FormControl>

                    <FormHelperText className={classes.serviceErrorMessage}>
                      {formik.errors.currency}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      className={classes.serviceFormControl}
                      fullWidth={true}
                    >
                      <InputField
                        label="Rate (per hour) *"
                        value={formik.values.budget}
                        onChange={formik.handleChange("budget")}
                      />
                    </FormControl>

                    <FormHelperText className={classes.serviceErrorMessage}>
                      {formik.errors.budget}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </div>

        {type !== "review" && (
          <div style={{ margin: "24px 0" }}>
            <PageButtons
              nextLocation="templates"
              nextDisabled={!formik.isValid || false}
              prevDisabled={false}
              loading={loading}
              prevLocation="create"
            />
          </div>
        )}
      </form>
    </FormControl>
  );
}
