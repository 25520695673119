import { makeStyles, TextField } from "@material-ui/core";

export default function InputField({
  label,
  onChange,
  value,
  multiline,
  disabled,
  placeholder,
  fullWidth,
  onBlur,
  max,
  rows,
  required,
  type,
  shrink,
  variant,
  min,
  placeholderColor,
}) {
  const useStyles = makeStyles((theme) => ({
    inputField: {
      "& .MuiInputBase-root.Mui-disabled": {
        color: "#333",
      },
      "& label": {
        fontSize: "14px",
      },
    },
    coloredInputField: {
      "& input": {
        color: "red",
      },
    },
  }));

  const classes = useStyles();

  return (
    <TextField
      label={label}
      variant={variant || "outlined"}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      required={required}
      size="small"
      multiline={multiline}
      rows={multiline ? rows : "1"}
      disabled={disabled}
      className={
        placeholderColor ? classes.coloredInputField : classes.inputField
      }
      autoComplete="off"
      placeholder={placeholder}
      fullWidth={fullWidth}
      inputProps={{ maxLength: max }}
      type={type || ""}
      min={min || null}
      onKeyDown={(e) => {
        if (type === "number") {
          ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
        }
      }}
    />
  );
}
