import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Drawer,
} from "@material-ui/core";

import { GET_COMPANIES_BY_PROJECT } from "../../queries/query";
import { useQuery } from "@apollo/client";

import { VBRIDGEHUB_MEDIAURL } from "../../../../../constants";

import MessageWindow from "../messaging/messageLink";

import { useStyles } from "./styles";
import LoadingResult from "../../../../shared/LoadingResult";

import VendorServiceResponse from "./VendorServiceResponse";
import ViewServiceResponseTable from "./ViewServiceResponseTable";

const Vendor = ({
  company,
  checked,
  handleToggle,
  id,
  onMoveToEvaluate,
  projectData,
}) => {
  const classes = useStyles();

  const [state, setState] = useState(false);

  return (
    <Grid item xs={4} style={{ margin: "8px" }}>
      <Drawer anchor="right" open={state} onClose={setState}>
        <Grid container className={classes.heading}>
          <Grid item xs={10}>
            <Typography variant="h5">{company.name}</Typography>
          </Grid>

          <Grid item xs={2}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                setState(false);
              }}
            >
              Close
            </Button>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <VendorServiceResponse
              companyId={company.vbeid}
              projectData={projectData}
            />
          </Grid>
        </Grid>
      </Drawer>

      <Card>
        <Grid container>
          {/* <Grid item xs={2}>
            {company.role.includes("SUBMITTED") && (
              <Checkbox
                size="small"
                checked={checked.indexOf(company.vbeid) !== -1}
                onChange={handleToggle(company.vbeid)}
              />
            )}
          </Grid> */}

          <Grid item xs={10}>
            <CardActionArea
              onClick={() => {
                if (company.role.includes("SUBMITTED")) {
                  setState(true);
                } else {
                  setState(false);
                }
              }}
            >
              <CardContent style={{ padding: "8px" }}>
                <Grid container>
                  <Grid item xs={2}>
                    <Avatar
                      variant="square"
                      src={VBRIDGEHUB_MEDIAURL + "/" + company.image}
                      className={classes.productImage}
                    >
                      :(
                    </Avatar>
                  </Grid>

                  <Grid
                    item
                    xs={10}
                    style={{ textAlign: "left", paddingLeft: "8px" }}
                  >
                    <Grid xs={12}>
                      <Typography variant="subtitle2">
                        {company.name}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid xs={12}>
                    <Typography
                      style={{
                        fontStyle: "italic",
                        fontSize: "0.8rem",
                      }}
                    >
                      {company.role.includes("SUBMITTED")
                        ? `Response Received (${company.employeeCount} resources)`
                        : company.role.includes("REJECTED")
                        ? "Rejected Proposal"
                        : "Response Awaited"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Grid>

          <Grid item xs={12}>
            <CardActions style={{ display: "flex", justifyContent: "center" }}>
              <MessageWindow
                productname={company.name}
                productId={company.vbeid}
                id={id}
              />
            </CardActions>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default function ViewServiceResponse({ projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  const [checked, setChecked] = useState([]);
  const [state, setState] = useState(false);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const { data } = useQuery(GET_COMPANIES_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id } },
  });

  return (
    <div className={classes.root}>
      <Drawer anchor="right" open={state} onClose={setState}>
        <Grid container className={classes.heading}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                setState(false);
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ViewServiceResponseTable
            companies={checked}
            projectData={projectData}
          />
        </Grid>
      </Drawer>

      <Grid container style={{ margin: "8px" }}>
        {/* {data && data.listCompaniesByProjectID.length !== 0 && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                setState(true);
              }}
              disabled={checked.length < 2}
            >
              Compare
            </Button>
          </Grid>
        )} */}

        {data ? (
          data.listCompaniesByProjectID.length !== 0 ? (
            data.listCompaniesByProjectID.map((company, index) => {
              return (
                <Vendor
                  key={index}
                  company={company}
                  id={id}
                  handleToggle={handleToggle}
                  checked={checked}
                  //onMoveToEvaluate={() => onCompanySelect(company)}
                  projectData={projectData}
                />
              );
            })
          ) : (
            <Grid item xs={12}>
              <div
                style={{
                  textAlign: "center",
                  color: "#999",
                  fontStyle: "italic",
                  marginTop: "16px",
                }}
              >
                No Suppliers have responded yet
              </div>
            </Grid>
          )
        ) : (
          <LoadingResult />
        )}
      </Grid>
    </div>
  );
}
