import React, { useEffect } from "react";

import { useStyles } from "./styles";

import { BootstrapInput } from "../../../theme/theme";

import { InputLabel, FormControl } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
//import Autocomplete from "@material-ui/lab/Autocomplete";

import { useFormik } from "formik";
import * as yup from "yup";
import { useQuery } from "@apollo/client";
import { listProducts } from "./query";
import SelectField from "../common/SelectField";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";

const verticals = require("../../../data/companyVerticals.json");

export default function CasestudyUpdateForm({ params, method }) {
  const classes = useStyles();
  // const [values, setValues] = React.useState(params.productid);

  const values = params.productid;

  //query for products tagged to company
  const { loading: productsloading, data: products } = useQuery(listProducts, {
    variables: { input: { vbeid: params.companyvbeid } },
  });

  let companyVerticals = [];
  verticals.forEach((vertical) => {
    if (vertical["n.type"] === "Company Vertical") {
      companyVerticals.push({
        type: vertical["n.name"],
        value: vertical["n.name"],
      });
    }
  });

  //validationSchema
  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter Name")
      .min(3, "Name should be of minimum 2 characters length")
      .required("Name is required")
      .nullable(),

    casestudyurl: yup
      .string("Enter your Casestudy URL")
      .nullable()
      .required("Casestudy URL is required")
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        "Enter valid Casestudy URL"
      ),
  });

  //filter params and updateForm values
  const formik = useFormik({
    initialValues: {
      name: params.name,
      casestudyurl: params.url,
      shortdescription: params.shortdescription,
      businessvertical: params.businessvertical ? params.businessvertical : [],
      productid: params.productid,
    },
    validationSchema: validationSchema,
  });

  //send Formik values
  useEffect(() => {
    method(formik.values, params.id, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, params.id, values]);

  return (
    <form>
      <FormControl fullWidth={true}>
        <InputLabel required={true} shrink focused={true}>
          Success Story Name
        </InputLabel>

        <BootstrapInput
          required={true}
          value={formik.values.name}
          onChange={formik.handleChange("name")}
          onBlur={formik.handleBlur("name")}
          placeholder="Enter Usecase Name"
        />

        <div className={classes.errorfields}>
          {formik.touched.name && formik.errors.name ? (
            <div className="error-message">{formik.errors.name}</div>
          ) : null}
        </div>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel required={true} shrink focused={true}>
          Success Story Url
        </InputLabel>

        <BootstrapInput
          required={true}
          value={formik.values.casestudyurl}
          onChange={formik.handleChange("casestudyurl")}
          onBlur={formik.handleBlur("casestudyurl")}
          placeholder="Enter casestudyurl Url"
        />

        <div className={classes.errorfields}>
          {formik.touched.casestudyurl && formik.errors.casestudyurl ? (
            <div className="error-message">{formik.errors.casestudyurl}</div>
          ) : null}
        </div>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel
          required={true}
          shrink
          id="demo-mutiple-checkbox-label"
          focused={true}
        >
          Customer Business Vertical
        </InputLabel>

        <Select
          required={true}
          input={<BootstrapInput />}
          value={formik.values.businessvertical}
          onChange={formik.handleChange("businessvertical")}
          onBlur={formik.handleBlur("businessvertical")}
          //style={{ width: "165%", marginLeft: "-1%" }}
        >
          {verticals.map((group) => {
            if (group["n.type"] === "Company Vertical") {
              return (
                <MenuItem dense={true} value={group["n.name"]}>
                  <ListItemText primary={group["n.name"]} />
                </MenuItem>
              );
            } else return null;
          })}
        </Select>
      </FormControl>

      <div className={classes.productMapBoxAlign}>
        <FormControl fullWidth={true}>
          <InputLabel required={true} shrink htmlFor="my-input2" focused={true}>
            Choose Applicable Product
          </InputLabel>
          <SelectField
            //label="Applicable Product"
            onChange={formik.handleChange("productid")}
            value={formik.values.productid}
            options={
              productsloading
                ? []
                : products
                ? products.productByComapnyId
                : null
            }
            multiple={false}
          />
        </FormControl>
      </div>

      <div className={classes.productMapBoxAlign}>
        <FormControl fullWidth={true}>
          <InputLabel required={true} shrink focused={true}>
            Short Description
          </InputLabel>

          <BootstrapInput
            multiline
            rows={3}
            rowsMax={5}
            required={true}
            value={formik.values.shortdescription}
            onChange={formik.handleChange("shortdescription")}
            onBlur={formik.handleBlur("shortdescription")}
            placeholder="Enter shortdescription"
          />

          <div className={classes.errorfields}>
            {formik.touched.shortdescription &&
            formik.errors.shortdescription ? (
              <div className="error-message">
                {formik.errors.shortdescription}
              </div>
            ) : null}
          </div>
        </FormControl>
      </div>
    </form>
  );
}
