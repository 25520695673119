import * as React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import RSection from "../rsection";
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import {
  ADD_SECTION,
  GET_SECTIONS,
  GET_TEMPLATE,
} from "../../../queries/query";

import { customAlphabet } from "nanoid/non-secure";
import PreviewTemplate from "./preview";
import PublishTemplate from "./publish";
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz-", 10);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  main: {
    padding: theme.spacing(1),
  },
  desing: {
    marginTop: theme.spacing(2),
  },
  formControlclass: {
    width: "100%",
    textAlign: "left",
  },
  backbutton: {
    marginLeft: -theme.spacing(5),
  },
  createSection: {
    paddingTop: theme.spacing(1),
  },
  mainbuttons: {
    marginLeft: theme.spacing(2),
  },
  heading: {
    textAlign: "left",
    paddingTop: theme.spacing(1),
  },
  startMessage: {
    border: "2px solid silver",
    borderStyle: "dotted",
  },
}));

function CreateSection(props) {
  const client = useApolloClient();

  let { type, id } = useParams();
  const [createSection, { data, loading, error }] = useMutation(ADD_SECTION, {
    onCompleted: (e) => {
      console.log(e);
    },
  });

  function addSection() {
    const nano_id = nanoid();
    let values = {
      id: nano_id,
      vbeid: id,
      metadata: JSON.stringify({
        title: "Edit this section title",
        help_message: "",
        weight: 10,
      }),
    };

    const { listSection } = client.readQuery({
      query: GET_SECTIONS,
      variables: { input: { vbeid: id } },
    });

    var newlist = [...listSection];
    newlist.push({ ...values, __typename: "template" });

    client.writeQuery({
      query: GET_SECTIONS,
      data: {
        listSection: newlist,
      },
      variables: { input: { vbeid: id } },
    });

    createSection({ variables: { input: values } });
  }

  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation
      size="small"
      onClick={addSection}
    >
      Add new section
    </Button>
  );
}

export default function DesignTemplate() {
  const classes = useStyles();
  let history = useHistory();

  const [state, setState] = React.useState(false);
  const [sections, setSections] = React.useState([]);

  let { type, id } = useParams();

  const { data: tdata } = useQuery(GET_TEMPLATE, {
    variables: { input: { vbeid: id } },
  });

  const { data } = useQuery(GET_SECTIONS, {
    variables: { input: { vbeid: id } },
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(!open);
  };

  return (
    <div className={classes.root}>
      <Box m={10}>
        <Paper className={classes.main}>
          <Grid>
            <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
              <PublishTemplate id={id} setState={setState} type={type} />
            </Drawer>
          </Grid>

          <Grid container>
            <Grid item xs={1} className={classes.backbutton}>
              <IconButton>
                <ArrowBackIcon
                  color="secondary"
                  onClick={() => {
                    history.push("/projects3.0/" + type);
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item xs={7} className={classes.heading}>
              <Typography variant="h5" gutterBottom>
                {tdata ? tdata.getTemplateByid.name : ""}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.createSection}>
              {data && tdata ? (
                <>
                  <Button
                    variant="contained"
                    disabled={data.listSection.length === 0}
                    color="primary"
                    disableElevation
                    size="small"
                    className={classes.mainbuttons}
                    onClick={() => {
                      setState(true);
                    }}
                  >
                    Preview & Publish
                  </Button>
                  <CreateSection setSections={setSections} />
                </>
              ) : null}
            </Grid>
            <Grid item xs={12} className={classes.desing}>
              {data && tdata ? (
                <Box m={1}>
                  {data.listSection.length === 0 ? (
                    <Paper elevation={0} className={classes.startMessage}>
                      <Box m={2}>
                        <Typography variant="caption">
                          Start by adding a new section and follow it up by
                          adding further items as appropriate.
                          <br />
                          You can preview your template before publishing into
                          the repository
                        </Typography>
                      </Box>
                    </Paper>
                  ) : (
                    <FormControl
                      component="fieldset"
                      className={classes.formControlclass}
                    >
                      {data.listSection.map((section, index) => {
                        var meta = JSON.parse(section.metadata);
                        meta = { ...meta, id: index + 1 };

                        return (
                          <RSection
                            key={section.id}
                            name={section.name}
                            vbeid={id}
                            sid={section.id}
                            id={index + 1}
                            metadata={meta}
                            preview={
                              tdata
                                ? tdata.getTemplateByid.status === "created"
                                  ? false
                                  : true
                                : true
                            }
                            type={type}
                          />
                        );
                      })}
                    </FormControl>
                  )}
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}
