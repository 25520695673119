import { useLazyQuery } from "@apollo/client";

import { FormControl, Grid, Typography } from "@material-ui/core";

import { useState } from "react";
import { useParams } from "react-router-dom";
import { getFormattedDate } from "../../../../../functions/helper";

import File from "../../../../shared/uploadfile";
import InputField from "../../../common/InputField";

import { getSignedUrlForDoc } from "../../queries/query";

import { useStyles } from "./styles";

export default function POForm({
  formik,
  fileUploaded,
  selectedResource,
  projectData,
}) {
  const classes = useStyles();
  const { id } = useParams();

  const [jdFile, setJdFile] = useState("");
  const [fileName, setFileName] = useState("");

  const getFileName = () => {
    if (jdFile !== "") {
      let fileNameDecoded = fileName;

      return fileNameDecoded;
    } else {
      return "";
    }
  };

  const [getFileUrl] = useLazyQuery(getSignedUrlForDoc, {
    onCompleted: (response) => {
      window.open(response.getSignedURL.signedurl);
    },
  });

  const getUrl = (paramName, filepath, newUrl, fileName) => {
    let fileNameDecoded = decodeURI(fileName);

    formik.values.attachment = filepath;

    setJdFile(filepath);
    setFileName(fileNameDecoded);
    fileUploaded();
  };

  const onFileClick = () => {
    getFileUrl({
      variables: {
        input: {
          filetype: "pdf",
          key: jdFile,
        },
      },
    });
  };

  return (
    <>
      <Grid md={4} className={classes.poLabel}>
        <Typography>PO Number *</Typography>
      </Grid>

      <Grid md={6} className={classes.poLabel}>
        <FormControl>
          <InputField
            onChange={formik.handleChange("poNumber")}
            value={formik.values.poNumber}
          />
        </FormControl>
      </Grid>

      <Grid md={4} className={classes.poLabel}>
        <Typography>PO Date *</Typography>
      </Grid>

      <Grid md={6} className={classes.poLabel}>
        <FormControl>
          <InputField
            onChange={formik.handleChange("poDate")}
            value={formik.values.poDate}
            type="date"
          />
        </FormControl>
      </Grid>

      <Grid md={4} className={classes.poLabel}>
        <Typography>Start Date *</Typography>
      </Grid>

      <Grid md={6} className={classes.poLabel}>
        <Typography style={{ fontSize: "0.9rem" }}>
          {getFormattedDate(formik.values.workStartDate)}
        </Typography>
      </Grid>

      <Grid md={4} className={classes.poLabel}>
        <Typography>End Date *</Typography>
      </Grid>

      <Grid md={6} className={classes.poLabel}>
        <Typography style={{ fontSize: "0.9rem" }}>
          {getFormattedDate(formik.values.workEndDate)}
        </Typography>
      </Grid>

      <Grid md={4} className={classes.poLabel}>
        <Typography>Rate (per hour) *</Typography>
      </Grid>

      <Grid md={6} className={classes.poLabel}>
        <Typography style={{ fontSize: "0.9rem" }}>
          {projectData.currency} {formik.values.rate}
        </Typography>
      </Grid>

      <Grid md={4} className={classes.poLabel}>
        <Typography>Upload Attachment *</Typography>
      </Grid>

      <Grid md={6} className={classes.poLabel}>
        <div style={{ display: "flex" }}>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            style={{ display: "none" }}
          />

          <label htmlFor="contained-button-file">
            <File
              vbeid={selectedResource.buyerid}
              //entity="po"
              filetype="po"
              fext=".pdf"
              paramName="image"
              inputid={id + "grouplogo"}
              updateCacheMethod={getUrl}
              buttonmessage="Upload File"
            />
            <div style={{ fontSize: "0.75rem", marginTop: "4px" }}>
              Please select a pdf file
            </div>
          </label>

          <div
            style={{
              margin: "8px",
              cursor: "pointer",
              wordBreak: "break-all",
              fontSize: "0.85rem",
            }}
            onClick={onFileClick}
          >
            {jdFile && jdFile !== "" ? getFileName() : fileName}
          </div>
        </div>
      </Grid>

      <Grid md={4} className={classes.poLabel}>
        <Typography>Additional Info</Typography>
      </Grid>

      <Grid md={6} className={classes.poLabel}>
        <FormControl fullWidth={true}>
          <InputField
            onChange={formik.handleChange("additionalInfo")}
            value={formik.values.additionalInfo}
            multiline={true}
            rows={3}
          />
        </FormControl>
      </Grid>
    </>
  );
}
