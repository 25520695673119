import React from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import ErrorImg from "./../../img/undraw_bug_fixing_oc7a.svg";
import { Box, ButtonGroup, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import Button from "@material-ui/core/Button";

const useStyles = (theme) => ({
  buttonProgress: {
    marginLeft: "40%",
    marginTop: "-8%",
  },

  logo: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  errorImg: {
    width: "50%",
  },
  formPaper: {
    padding: "6%",
    textAlign: "center",
  },
  formBox: {
    marginTop: "5%",
    marginLeft: "30%",
    marginRight: "30%",
  },
  formControl: {
    marginBottom: "3%",
  },
  formComboBox: {
    height: theme.spacing(0.1),
  },
  buttonRow: {
    marginBottom: "3%",
  },
  bgroup: {
    margin: "auto",
    marginTop: theme.spacing(2),
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
  }

  render() {
    const { classes, history } = this.props;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box className={classes.formBox}>
          <Paper elevation={0} className={classes.formPaper} square={true}>
            <img alt="img" src={ErrorImg} className={classes.errorImg} />

            <Typography
              style={{ paddingBottom: "5%", paddingTop: "5%" }}
              component="span"
              variant="caption"
              display="block"
              gutterBottom
            >
              Something went wrong. Our team is working to get this fixed.
            </Typography>

            <ButtonGroup variant="text" className={classes.bgroup}>
              <Button
                startIcon={<HomeIcon />}
                onClick={() => {
                  history.push("/");
                  window.location.reload();
                }}
              >
                Back to Home
              </Button>
            </ButtonGroup>
          </Paper>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default withRouter(withStyles(useStyles)(ErrorBoundary));
