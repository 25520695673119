import { gql } from "@apollo/client";

export const getResourcesByStatus = gql`
  query getResourcesByStatus($input: projectinputV1) {
    listResourcesByStatus(input: $input) {
      name
      companyname
      projectName
      projectOwner
      vendorid
      buyerid
      workstartby
      workduration
      engagementStartDate
      engagementEndDate
      currency
      billingrate
      porate
      PK
      SK
      remarks
      status
      ponumber
      podate
      vbeid
      createdon
      revisedbillingrate
      postartdate
      poenddate
    }
  }
`;
