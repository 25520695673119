import React, { useState } from "react";
import { useParams } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";

import { useQuery, useMutation, useApolloClient } from "@apollo/client";

import { useStyles } from "./styles";
import {
  ProductsToCategoryRecMap,
  RecRemoveMapProductsToCategory,
} from "./query";
import SimpleErrorSnackbar from "../groups2.0/errorsnackbar";
import SimpleSnackbar from "../groups2.0/snackbar";

const topics = require("../../../data/topics.json");

export default function TopicMapping({
  classname,
  data,
  fetch,
  fetching,
  productIds,
}) {
  const classes = useStyles();
  const [values, setValues] = useState("");

  const { id } = useParams();
  const client = useApolloClient();

  // console.log("values", values);
  // console.log("productIds", productIds);

  // const [
  //   mapTopicToGroupMutation,
  //   { loading: mutationLoading, error: mutationError },
  // ] = useMutation(mapTopicToGroup, {
  //   onCompleted: (e) => {
  //     UpdateTopicGroupCache(e);
  //   },
  // });

  // const createTopicToGroup = () => {
  //   mapTopicToGroupMutation({
  //     variables: {
  //       input: {
  //         topicid: values,
  //         groupid: id,
  //       },
  //     },
  //   });
  // };

  // useQuery(searchTopic);

  // useQuery(listGroupTopics, {
  //   variables: { input: { groupid: id } },
  // });

  //////update cache for create topics from group
  // const UpdateTopicGroupCache = (e) => {
  //   const { listTopicsByGroupId } = client.readQuery({
  //     query: listGroupTopics,
  //     variables: { input: { groupid: id } },
  //   });

  //   let listGroupTopicsCache = { ...listTopicsByGroupId };
  //   let topics = [...listGroupTopicsCache.topics];

  //   topics.unshift({ ...e.topictogroup });

  //   client.writeQuery({
  //     query: listGroupTopics,
  //     data: {
  //       listTopicsByGroupId: {
  //         __typename: "topicsresult",
  //         listTopicsByGroupId: { topics: topics },
  //       },
  //     },
  //     variables: { input: { groupid: id } },
  //   });
  // };

  //mapping products

  const [
    mapProductsToTopics,
    { data: mappedData, loading: mutationLoading, error: mutationError },
  ] = useMutation(ProductsToCategoryRecMap, {
    onCompleted: (e) => {
      // fetch();
      CacheForMapping(e.RecMapProductsToCategory);
      // AfterMerge(e);
    },
  });

  //function to mergeCompanies
  function MapProducts() {
    mapProductsToTopics({
      variables: {
        input: {
          vbeid: values,
          listvbeid: productIds,
        },
      },
    });
  }

  //mapping similars products

  const [
    mapSimilarProductsToTopics,
    {
      data: mappedSimilarData,
      loading: mutationSimilarLoading,
      error: mutationSimilarError,
    },
  ] = useMutation(ProductsToCategoryRecMap, {
    onCompleted: (e) => {
      // fetching();
      // console.log("ProductsToCategoryRecMap", e.ProductsToCategoryRecMap);
      CacheForMapping(e.RecMapProductsToCategory);
      // AfterMerge(e);
    },
  });

  //function to mergeCompanies
  function MapProducts() {
    mapProductsToTopics({
      variables: {
        input: {
          vbeid: values,
          listvbeid: productIds,
        },
      },
    });
  }

  function CacheForMapping(e) {
    // console.log("eeeeeeeeeee", e);
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          {mutationError && <SimpleErrorSnackbar />}
          {mutationLoading && <SimpleSnackbar />}
        </Grid>
      </Grid>

      <Box>
        <Grid
          container
          spacing={1}
          className={classname ? classes.classname : classes.topicTab}
        >
          <Grid item>
            <Autocomplete
              options={topics}
              getOptionLabel={(option) => option["n.name"]}
              clearOnBlur=" true"
              style={{ width: 200 }}
              disabled={productIds ? false : true}
              onChange={(event, newvalue) => {
                setValues(newvalue ? newvalue["n.vbeid"] : null);
              }}
              renderOption={(option) => (
                <div className={classes.optionLabel}>{option["n.name"]} </div>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Search Topic"
                  variant="outlined"
                  margin="dense"
                  id="input"
                />
              )}
            />
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={productIds.length < 1 ? true : false}
              // size="small"
              className={classes.mapButton}
              onClick={MapProducts}
              // onClick={createTopicToGroup}
            >
              Map
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
