import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import { useMutation, gql } from "@apollo/client";

import { nanoid } from "nanoid";

const axios = require("axios");

const signed = gql`
  mutation upload($input: s3input!) {
    putSignedURL(input: $input) {
      signedurl
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "15%",
    margin: "auto",
    marginTop: "5%",
  },
  logoImg: {
    width: "100%",
    marginBottom: "2%",
  },
  uploadBtn: {
    fontSize: "0.8rem",
  },
}));

export default function File({
  vbeid,
  entity,
  filetype,
  fext,
  inputid,
  paramName,
  updateCacheMethod,
  buttonmessage,
  type,
  icon,
}) {
  const classes = useStyles();

  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState("");

  const [signedUrl] = useMutation(signed, {
    onCompleted: (e) => {
      afterGettingSignedUrl(e);
    },
  });

  const getSignedUrl = () => {
    signedUrl({
      variables: {
        input: {
          method: "put",
          entity: entity,
          filetype: filetype,
          filename: document.getElementById(inputid).files[0].name,
          vbeid: vbeid,
        },
      },
    });
  };

  const startUploadFile = (e) => {
    setMessage("Preparing for upload ...");
    setStatus(true);
    getSignedUrl();
  };

  const afterGettingSignedUrl = (e) => {
    uploadFile(e.putSignedURL.signedurl);
  };

  async function uploadFile(url) {
    let file = document.getElementById(inputid).files[0];

    await axios
      .request({
        method: "put",
        url: url,
        data: file,
        onUploadProgress: (p) => {
          const percent = Math.round((p.loaded / p.total) * 100);
          setMessage("Uploading ..." + percent + "%");
        },
      })
      .then(function (response) {
        setStatus(false);

        let newUrl = response.config.url.split("?")[0];
        let newFilepath = newUrl.substring(newUrl.lastIndexOf(".com") + 4);
        let filepath = newFilepath.substring(1);

        updateCacheMethod(paramName, filepath, response.config.url, file.name);
      });
  }

  return (
    <div style={{ textAlign: "center" }}>
      <Snackbar
        open={status}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="info">{message}</Alert>
      </Snackbar>

      <input
        type="file"
        id={inputid}
        accept=".pdf"
        onChange={startUploadFile}
        hidden
      />

      <label htmlFor={inputid}>
        {type === "icon" ? (
          <IconButton
            variant="contained"
            color="primary"
            component="span"
            startIcon={icon}
            className={classes.uploadBtn}
          >
            {icon}
          </IconButton>
        ) : (
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={icon}
            className={classes.uploadBtn}
          >
            {buttonmessage}
          </Button>
        )}
      </label>
    </div>
  );
}
