import { gql } from "@apollo/client";

export const getUsersList = gql`
  query getUsersList($input: roletouserinput!) {
    getUsersbyCompany(input: $input) {
      id
      name
      designation
      isMailIdValidated
      isPrivatemail
      isProfileUpdated
      isVbridgeUser
      jobrole
      role
      vbeid
      roletype
      status
      emailid
    }
  }
`;
