import { useParams, useHistory } from "react-router-dom";

import { Box, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useStyles } from "./styles";

import { useQuery } from "@apollo/client";
import { GET_PROJECT } from "../../queries/query";

import PrimaryButton from "../../../common/PrimaryButton";

import ServiceProject from "../createProject/ServiceProject/serviceProject";
import JobDescription from "../createProject/ServiceProject/JobDescription";
import SubmitServiceProject from "./SubmitServiceProject";
import ProvidersList from "../createProject/ServiceProject/ProvidersList";
import WorkPackageProject from "../createProject/WorkPackage/workPackageProject";
import UpdateRequirementForm from "../requirements/updateRequirementsreview";
import MapOtherTemplate from "../requirements/mapOtherTemplateReview";

export default function ServiceReview({ drawerOpen }) {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const { data } = useQuery(GET_PROJECT, {
    fetchPolicy: "no-cache",
    variables: {
      input: { vbeid: id },
    },
  });

  return (
    <Box m={10} style={drawerOpen ? { marginLeft: "240px" } : {}}>
      <IconButton
        style={{ float: "left" }}
        color="primary"
        onClick={() => {
          history.push("/projects3.0/overview");
        }}
      >
        <ArrowBackIcon />
      </IconButton>

      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} className={classes.reviewTextRow}>
            <Box m={1}>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant="h6">
                    Review and publish your project
                  </Typography>
                </Grid>

                {data && (
                  <Grid item xs={4} style={{ textAlign: "right" }}>
                    <SubmitServiceProject
                      id={id}
                      projectData={data.getProjectByIDV1}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} className={classes.row}>
            <div className={classes.responseHeading}>Project Details</div>

            {data &&
              (data.getProjectByIDV1.type === "service-workpackage" ? (
                <WorkPackageProject type="review" />
              ) : (
                <ServiceProject type="review" />
              ))}

            <div className={classes.responseHeading}>Job Description</div>
            <Grid container>
              <Grid item md={9}>
                {data &&
                  (data.getProjectByIDV1.type === "service-workpackage" ? (
                    <JobDescription type="review" projectType="workpackage" />
                  ) : (
                    <JobDescription type="review" />
                  ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.row}>
            <div className={classes.responseHeading}>Requirement</div>
            {data?.getProjectByIDV1 && (
              <UpdateRequirementForm
                type="review"
                projectData={data?.getProjectByIDV1}
              />
            )}
          </Grid>

          <Grid item xs={12} className={classes.row}>
            <div className={classes.responseHeading}>Supplier Templates</div>
            <MapOtherTemplate type="review" />
          </Grid>

          {/* {templateData && templateData.getTemplatesByProjectID.length !== 0 && (
            <Grid item xs={12} className={classes.row}>
              <div className={classes.responseHeading}>QnA Template</div>

              <QATemplate data={templateData.getTemplatesByProjectID[0]} />
            </Grid>
          )} */}

          <Grid item xs={12} className={classes.row}>
            <div className={classes.responseHeading}>
              Selected Service Providers
            </div>

            {data &&
              (data.getProjectByIDV1.type === "service-workpackage" ? (
                <ProvidersList
                  type="review"
                  projectData={data}
                  projectType="workpackage"
                />
              ) : (
                <ProvidersList type="review" projectData={data} />
              ))}
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.row}>
          <Divider />

          <Box m={1}>
            <Grid container>
              <Grid item xs={8}>
                <PrimaryButton
                  label="Back"
                  variant="outlined"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </Grid>

              {data && (
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <SubmitServiceProject
                    id={id}
                    projectData={data.getProjectByIDV1}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </div>
    </Box>
  );
}
