import TimeAndMoneyList from "../../vendor/price/timeMoneyList";
import MileStoneList from "../../vendor/price/mileStoneList";
import ProductCostList from "../../vendor/price/productCostList";

import { useStyles } from "./styles";

export default function ViewPriceForm({
  projectId,
  productId,
  type,
  projectData,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {projectData?.type === "product" ? (
        <ProductCostList
          productvbeid={productId}
          projectid={projectId}
          readOnly={true}
        />
      ) : projectData?.engagement_type === "time_material" ? (
        <TimeAndMoneyList
          productvbeid={productId}
          projectid={projectId}
          readOnly={true}
        />
      ) : (
        <MileStoneList
          productvbeid={productId}
          projectid={projectId}
          readOnly={true}
        />
      )}
    </div>
  );
}
