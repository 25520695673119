import * as React from "react";

import { DataGrid } from "@material-ui/data-grid";
import { withStyles } from "@material-ui/core";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
  },
  virtualScrollerContent: {
    height: "100% !important",
    overflow: "scroll",
  },
})(DataGrid);

export default function AdminDataGrid({ loading, data, columns }) {
  return (
    <StyledDataGrid
      loading={loading}
      density="compact"
      autoHeight={true}
      rowsPerPageOptions={[5]}
      checkboxSelection={false}
      disableSelectionOnClick={true}
      rows={loading ? [] : data}
      columns={columns}
    />
  );
}
