import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  errorfields: {
    color: "red",
  },
  textfields: {
    width: "100%",
  },
  buttonStyle: {
    borderRadius: "2rem",
    textTransform: "capitalize",
    fontSize: "0.7rem",
  },
  buttonsAlign: {
    paddingBottom: theme.spacing(2),
  },
}));
