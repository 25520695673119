import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormHelperText } from "@material-ui/core";

import { useFormik } from "formik";
import * as yup from "yup";

import { useMutation, useQuery } from "@apollo/client";

import SimpleErrorSnackbar from "./errorsnackbar";

import { postCaseStudy } from "../AddNew/query";
import AddNewButton from "../AddNew/AddNewButton";

import { listProducts } from "../../../companies/Company2.0/query";

import InputField from "../../../common/InputField";
import SelectField from "../../../common/SelectField";
import { useStyles } from "./styles";

const groups = require("../../../../../data/groups.json");
const verticals = require("../../../../../data/companyVerticals.json");

export default function AddCaseStudyForm({
  type,
  updateCache,
  casestudyrefetch,
}) {
  const classes = useStyles();
  const { id } = useParams();
  const timer = useRef();

  const [groupValue, setGroupValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState([]);
  const [noUrl, setNoUrl] = useState(false);
  const [loading, setLoading] = useState(false);

  let verticalList = [];

  verticals.forEach((name) => {
    verticalList.push({
      type: name["n.vbeid"],
      value: name["n.name"],
    });
  });

  let typeId = "productid";
  if (type === "service") typeId = "serviceid";

  const groupCheck = () => {
    const newVal = [];

    groups.map((group) => {
      if (group["n.type"] === "Vertical") {
        return newVal.push(group["n.name"]);
      } else return null;
    });

    setGroupValue(newVal);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  // const handleButtonClick = () => {
  //   if (!mutationLoading) {
  //     timer.current = window.setTimeout(() => {}, 500);
  //   }
  // };
  const handleButtonClick = () => {
    if (!mutationLoading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    groupCheck();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const { data: products } = useQuery(listProducts, {
    variables: { input: { vbeid: id } },
  });

  let productsList = [];

  products?.productByComapnyId.forEach((product) => {
    productsList.push({
      type: product.vbeid,
      value: product.name,
    });
  });

  const handleOnChange = (event) => {
    let selectedValues = values;
    let newValue = event.target.value;

    let index = selectedValues.indexOf(newValue);

    if (index !== -1) {
      setValues([
        ...selectedValues.slice(0, index),
        ...selectedValues.slice(index + 1),
      ]);
    } else {
      setValues(newValue);
    }
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter Success Story Name")
      .min(2, "Success Story Name should be of minimum 2 characters length")
      .required("Success Story Name is required"),

    url: yup
      .string("Enter Success Story URL")
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        "Enter Success Story URL"
      ),

    // businessvertical: yup
    //   .string("Select Vertical")
    //   .oneOf(groupValue, "Please select a valid Vertical")
    //   .required("Vertical is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      url: "",
      businessvertical: [],
      [typeId]: "",
    },

    validationSchema: validationSchema,
  });

  //mutation for adding casestudy

  const [
    postCaseStudyMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(postCaseStudy, {
    onCompleted: (e) => {
      handleClose();
      updateCache(e);
      // casestudyrefetch();
      // handleClose();
    },
  });

  const createCaseStudyShort = (e) => {
    e.preventDefault();

    postCaseStudyMutation({
      variables: {
        input: {
          name: formik.values.name,
          url: formik.values.url,
          businessvertical: formik.values.businessvertical,
          [typeId]: id,
          productlist: values,
        },
      },
    });

    handleButtonClick();
  };

  const onNoUrlClick = () => {
    setNoUrl(!noUrl);
  };

  return (
    <div>
      {mutationError && <SimpleErrorSnackbar />}

      <AddNewButton
        name="Add New Success Story"
        handleClick={handleClickOpen}
      />

      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className={classes.title}>
            Add Success Story
          </DialogTitle>

          <DialogContent dividers>
            <DialogContentText className={classes.stmt}>
              Please Enter Success Story Details in the following fields.
            </DialogContentText>

            <div className={classes.root}>
              <Grid container>
                <Grid item md={11}>
                  <FormControl fullWidth={true} className={classes.formControl}>
                    <InputField
                      label="Success Story Name *"
                      value={formik.values.name}
                      onChange={formik.handleChange("name")}
                      onBlur={formik.handleBlur("name")}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={11}>
                  {formik.errors.name ? (
                    <div className={classes.errorMsg}>{formik.errors.name}</div>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item md={11}>
                  <FormControl fullWidth={true} className={classes.formControl}>
                    <InputField
                      label={
                        !noUrl ? "Success Story Url *" : "No URL available"
                      }
                      value={formik.values.url}
                      onChange={formik.handleChange("url")}
                      onBlur={formik.handleBlur("url")}
                      placeholder="https://www.vbridgehub.com/services/"
                      disabled={noUrl}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={11}>
                  {formik.errors.url && !noUrl ? (
                    <div className={classes.errorMsg}>{formik.errors.url}</div>
                  ) : null}
                </Grid>

                <FormHelperText margin="dense">
                  * Please add a success story URL as available on your website
                  for the Success Story. Our AI will also use this content and
                  metadata when buyers are using Advanced Search on vBridge Hub.
                </FormHelperText>

                <FormHelperText
                  margin="dense"
                  className={classes.noUrlText}
                  onClick={onNoUrlClick}
                >
                  {!noUrl
                    ? "Click here if you do not have a URL for the Success Story."
                    : "Click here to enter a URL for the Success Story."}
                </FormHelperText>
              </Grid>

              <Grid container>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <SelectField
                      label="Customer Industry Vertical*"
                      value={formik.values.businessvertical}
                      onChange={formik.handleChange("businessvertical")}
                      onBlur={formik.handleBlur("businessvertical")}
                      options={verticalList}
                      multiple={true}
                    />
                  </FormControl>
                </Grid>

                {/* <Grid item md={11}>
                  {formik.errors.businessvertical ? (
                    <div className={classes.errorMsg}>
                      {formik.errors.businessvertical}
                    </div>
                  ) : null}
                </Grid> */}
              </Grid>

              <Grid container>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <SelectField
                      label="Map Applicable Product"
                      options={productsList}
                      // onChange={formik.handleChange("productlist")}
                      // onBlur={formik.handleBlur("productlist")}
                      onChange={handleOnChange}
                      multiple={true}
                      value={values}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              className={classes.addNewBtn}
            >
              Cancel
            </Button>

            <Button
              onClick={createCaseStudyShort}
              type="submit"
              color="primary"
              disabled={!(formik.isValid && formik.dirty) || loading}
              className={classes.addNewBtn}
              variant="contained"
            >
              Add
            </Button>
            {mutationLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
