import { gql } from "@apollo/client";

export const userInfoQuery = gql`
  query userinfo {
    getLoginUserInfo(input: {}) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      roletype
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
      isPrivatemail
      status
      companyname
      onBoardingCompleted
    }
  }
`;

export const getProfileAdminsQuery = gql`
  query profileAdmins {
    listProfileAdmin(input: {}) {
      id
      vbeid
      name
      imageurl
    }
  }
`;

export const getProfileAdminsRequestQueue = gql`
  query profileAdminsRequestQueue {
    listProfileAdminRequest(input: {}) {
      id
      vbeid
      name
    }
  }
`;

export const getCompanyById = gql`
  query companyinfo($input: id!) {
    queryCompanybyid(input: $input) {
      id
      name
      image
      shortdescription
      longdescription
      businessvertical
      vbeid
      founder
      founded
      location
      linkedinurl
      facebookurl
      twitterurl
      companyurl
      orgtype
      round
      amount
      securityscorecard_grade_url
      knpashot_score
      employeeCount
      revenue
      certifications
      integrations
      maturity
      location_state
      projectowner
      contractresourceday
      workpackageday
      billingperioddayfrom
      billingperioddayto
      timesheetsubmissionday
      timesheetapprovalday
      invoicesubmissionday
      fixedbid
      timeandmaterialbid
      productbid
      skilllist {
        vbeid
        name
      }
    }
  }
`;

export const claimProfile = gql`
  mutation claimProfile {
    claimCompany {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
    }
  }
`;

export const createCompany = gql`
  mutation createCompany($input: companyinput!) {
    addMyCompany(input: $input) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
    }
  }
`;

export const requestProfileAdminAccess = gql`
  mutation requestAdminAccess {
    profileadminrequest(input: {}) {
      id
      role
    }
  }
`;

export const removeAdminAccess = gql`
  mutation removeProfileadmin($input: id!) {
    removeProfileadmin(input: $input) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
    }
  }
`;

export const acceptOrRejectAdminRequest = gql`
  mutation acceptOrRejectAdminRequest($input: id!) {
    profileaccessapproval(input: $input) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
    }
  }
`;

export const getNDAStatus = gql`
  query getStatus {
    getNDAStatus(input: {}) {
      status
    }
  }
`;

export const getCompanyNDAStatus = gql`
  query getCompanyNDAStatus {
    companyNDAStatus(input: {}) {
      status
      name
    }
  }
`;

export const updateCompanyDetails = gql`
  mutation update($input: companyinput!) {
    updateCompany(input: $input) {
      name
      shortdescription
    }
  }
`;

export const getCompanyByCompanyId = gql`
  query companyinformation($input: companyinput!) {
    companysBycompanyID(input: $input) {
      name
      image
      vbeid
      id
    }
  }
`;

export const postCompany = gql`
  mutation updatecompany($input: companyinput!) {
    updateCompany(input: $input) {
      founder
      founded
      location
      companyurl
      linkedinurl
      facebookurl
      twitterurl
      businessvertical
    }
  }
`;

export const postCompanyDesc = gql`
  mutation updatecompany($input: companyinput!) {
    updateCompany(input: $input) {
      id
      longdescription
    }
  }
`;

export const removeAward = gql`
  mutation award($input: awardinputV1!) {
    removeMapAwardTocompanyV1(input: $input) {
      id
    }
  }
`;

export const getAwardsByCompanyId = gql`
  query awardsinformation($input: awardinputV1!) {
    listAwardsByCompanyIDV1(input: $input) {
      id
      vbeid
      name
      year
    }
  }
`;

export const removeInvestor = gql`
  mutation investor($input: id!) {
    removeInvestor(input: $input) {
      responsestatus
    }
  }
`;

export const getInvestorsByCompanyId = gql`
  query investorsinformation($input: companyinput!) {
    investorsByCompanyId(input: $input) {
      name
      image
      shortdescription
      vbeid
      id
    }
  }
`;

export const removeCustomer = gql`
  mutation customer($input: id!) {
    removeCustomer(input: $input) {
      status
      id
    }
  }
`;

export const getCustomersByCompanyId = gql`
  query customersinformation($input: companyinput!) {
    customersByCompanyId(input: $input) {
      name
      image
      shortdescription
      vbeid
      companyid
      id
    }
  }
`;

export const getPartnersByCompanyId = gql`
  query partnersinformation($input: companyinput!) {
    partnersByCompanyId(input: $input) {
      name
      image
      shortdescription
      companyid
      vbeid
      id
      partnertype
    }
  }
`;

export const removePartner = gql`
  mutation partner($input: id!) {
    removePartner(input: $input) {
      status
      id
    }
  }
`;

export const getProductsByCompanyId = gql`
  query productsinformation($input: companyinput!) {
    productByComapnyId(input: $input) {
      name
      image
      shortdescription
      vbeid
      id
    }
  }
`;

export const getUsecasesByCompanyId = gql`
  query usecasesinformation($input: companyinput!) {
    usecaseByCompanyId(input: $input) {
      name
      image
      shortdescription
      industryvertical
      vbeid
      id
      url
      productid
      productname
      longdescription
    }
  }
`;

export const getCasestudiesByCompanyId = gql`
  query casestudyinformation($input: companyinput!) {
    casestudiesByCompanyId(input: $input) {
      name
      image
      shortdescription
      vbeid
      id
      businessvertical
      url
      productname
      productid
    }
  }
`;

export const getServicesByCompanyId = gql`
  query listServicesbyCompanyId($input: serviceinput!) {
    listServicesbyCompanyId(input: $input) {
      name
      shortdescription
      longdescription
      vbeid
      id
      servicetypeobj {
        name
        vbeid
      }
    }
  }
`;

export const postCompanyInfo = gql`
  mutation updatecompany($input: companyinput!) {
    updateCompany(input: $input) {
      founder
      founded
      location
      companyurl
      linkedinurl
      facebookurl
      twitterurl
      businessvertical
      employeeCount
      revenue
      certifications
      integrations
      maturity
      location_state
      contractresourceday
      workpackageday
      billingperioddayfrom
      billingperioddayto
      timesheetsubmissionday
      timesheetapprovalday
      invoicesubmissionday
    }
  }
`;

export const postProduct = gql`
  mutation product($input: productinput!) {
    addProductShort(input: $input) {
      name
      producturl
      vbeid
    }
  }
`;

export const postCaseStudy = gql`
  mutation casestudy($input: casestudyinput!) {
    addCasestudyShort(input: $input) {
      name
      url
      vbeid
    }
  }
`;

export const listProducts = gql`
  query listing($input: companyinput!) {
    productByComapnyId(input: $input) {
      name
      vbeid
    }
  }
`;

export const postAward = gql`
  mutation award($input: genericinput!) {
    addAward(input: $input) {
      name
      awarderurl
      awardername
      year
      id
      vbeid
      shortdescription
    }
  }
`;

export const mapAwardTocompanyV1 = gql`
  mutation addAwards($input: awardinputV1!) {
    mapAwardTocompanyV1(input: $input) {
      responsestatus
      id
      vbeid
      name
      companyvbeid
    }
  }
`;

export const mapNewAward = gql`
  mutation addAwards($input: awardinputV1!) {
    createAwardAndMapToCompanyV1(input: $input) {
      responsestatus
      id
      vbeid
      name
      companyvbeid
    }
  }
`;

export const postInvestor = gql`
  mutation investor($input: genericinput!) {
    addInvestor(input: $input) {
      responsestatus
    }
  }
`;

export const inviteCompanyEmail = gql`
  mutation inviteCompany($input: genericinput!) {
    inviteCompanyEmail(input: $input) {
      id
    }
  }
`;

export const postCustomer = gql`
  mutation customer($input: genericinput!) {
    addCustomer(input: $input) {
      id
      name
      shortdescription
      customerurl
      referenceable
    }
  }
`;

export const postPartner = gql`
  mutation partner($input: genericinput!) {
    addPartner(input: $input) {
      id
      name
      shortdescription
      partnerurl
    }
  }
`;

export const updateUsecase = gql`
  mutation update($input: usecaseinput!) {
    updateusecase(input: $input) {
      name
      shortdescription
      longdescription
    }
  }
`;

export const updateCaseStudy = gql`
  mutation update($input: casestudyinput!) {
    updatecasestudy(input: $input) {
      name
      shortdescription
      url
      productlist {
        vbeid
        name
      }
      businessvertical
      longdescription
    }
  }
`;

export const listOrgTypes = gql`
  query listOrgtypes {
    listOrgtypes(input: {}) {
      vbeid
      name
    }
  }
`;

export const addServiceToCompany = gql`
  mutation addServiceToCompany($input: serviceinput!) {
    addService(input: $input) {
      id
    }
  }
`;

export const editService = gql`
  mutation editService($input: serviceinput!) {
    updateService(input: $input) {
      id
    }
  }
`;

export const mapTopicToService = gql`
  mutation mapTopicToService($input: id!) {
    addServiceToTopic(input: $input) {
      id
    }
  }
`;

export const getServiceTypes = gql`
  query listServiceTypes {
    listServicetypes(input: {}) {
      vbeid
      name
    }
  }
`;

export const mapServiceTypeToService = gql`
  mutation mapServiceTypeToService($input: serviceinput!) {
    addServiceTypeToService(input: $input) {
      id
    }
  }
`;

export const getCategoriesByServiceType = gql`
  query getCategoriesByServiceType($input: topicinput!) {
    listTopicsByServicetypeName(input: $input) {
      vbeid
      name
    }
  }
`;

export const getCompanyVerticals = gql`
  query getCompanyVerticals {
    listCompanyVertical(input: {}) {
      vbeid
      name
    }
  }
`;

export const getSkillsByComopny = gql`
  query skills($input: companyinput!) {
    skillsByCompany(input: $input) {
      name
      vbeid
    }
  }
`;
