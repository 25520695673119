import { useQuery } from "@apollo/client";
import { TableCell } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { GET_ITEM_VALUES } from "../../queries/query";
import RCurrencyItem from "../Items/both/currencyItem";
import RDateItem from "../Items/both/dateItem";
import RFileItem from "../Items/both/fileupload";
import RNumberItem from "../Items/both/numberItem";
import RSelectItem from "../Items/both/selectItem";
import RTextAreaItem from "../Items/both/textAreaItem";
import RTextItem from "../Items/both/textItem";

export default function EvaluateItemValue({
  item,
  product,
  sid,
  meta,
  templateType,
  person,
  readOnly,
}) {
  const { id } = useParams();

  const { data, refetch } = useQuery(GET_ITEM_VALUES, {
    variables: {
      pinput: {
        sectionid: sid,
        projectid: id,
        productvbeid: product.productvbeid,
        personvbeid: person,
      },
    },
  });

  const getValues = (values, itemId, productId) => {
    if (values.length !== 0) {
      const result = values.filter(
        (value) => value.itemid === itemId && value.productvbeid === productId
      );

      result.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp));

      return result ? result[0] : {};
    } else return {};
  };

  const getItemValues = () => {
    var values = getValues(
      data.getEvaluationTempItemvaluebyemp,
      item.id,
      product.productvbeid
    );

    var input = {
      itemid: item.id,
      sectionid: sid,
      projectid: id,
      productvbeid: product.productvbeid,
      attachment: "",
      attachmentfilename: "",
      timestamp: null,
      value: "",
      remarks: "",
    };

    input = { ...input, ...values };
    delete input.__typename;

    return getItem(product.productvbeid, item.id, item.type, item.id, meta, {
      ...input,
    });
  };

  const getItem = (pid, key, type, id, metadata, values) => {
    switch (type) {
      case "text_field":
        return (
          <TableCell style={{ textAlign: "center" }}>
            <RTextItem
              key={key}
              id={id}
              sid={sid}
              productId={pid}
              type={templateType}
              metadata={metadata}
              values={values}
              hidetitle={true}
              refetch={refetch}
              readOnly={readOnly}
            />
          </TableCell>
        );

      case "text_area":
        return (
          <TableCell style={{ textAlign: "center" }}>
            <RTextAreaItem
              rows={4}
              id={id}
              sid={sid}
              productId={pid}
              type={templateType}
              metadata={metadata}
              values={values}
              hidetitle={true}
              refetch={refetch}
              readOnly={readOnly}
            />
          </TableCell>
        );

      case "multi_choice":
        return (
          <TableCell style={{ textAlign: "center" }}>
            <RSelectItem
              id={id}
              sid={sid}
              productId={pid}
              type={templateType}
              metadata={metadata}
              values={values}
              hidetitle={true}
              readOnly={readOnly}
            />
          </TableCell>
        );

      case "currency":
        return (
          <TableCell style={{ textAlign: "center" }}>
            <RCurrencyItem
              id={id}
              sid={sid}
              productId={pid}
              type={templateType}
              metadata={metadata}
              values={values}
              hidetitle={true}
              readOnly={readOnly}
            />
          </TableCell>
        );

      case "attachment":
        return (
          <TableCell style={{ textAlign: "center" }}>
            <RFileItem
              id={id}
              sid={sid}
              productId={pid}
              type={templateType}
              metadata={metadata}
              values={values}
              hidetitle={true}
              readOnly={readOnly}
            />
          </TableCell>
        );

      case "date":
        return (
          <TableCell style={{ textAlign: "center" }}>
            <RDateItem
              id={id}
              sid={sid}
              productId={pid}
              type={templateType}
              metadata={metadata}
              values={values}
              hidetitle={true}
              readOnly={readOnly}
            />
          </TableCell>
        );

      case "number":
        return (
          <TableCell style={{ textAlign: "center" }}>
            <RNumberItem
              id={id}
              sid={sid}
              productId={pid}
              type={templateType}
              metadata={metadata}
              values={values}
              hidetitle={true}
              readOnly={readOnly}
            />
          </TableCell>
        );

      default:
        break;
    }
  };

  return (
    <>{data && data.getEvaluationTempItemvaluebyemp ? getItemValues() : null}</>
  );
}
