import * as yup from "yup";

export const roleTypeOptions = [
  { type: "buyer", value: "A Buyer" },
  { type: "seller", value: "A Seller" },
];

export const buyerRoles = [
  "Procurement",
  "IT Infrastructure",
  "IT Applications",
  "Digital",
  "Human Resource",
  "Architect",
  "Alliances",
  "Sales",
  "Pre-Sales",
  "Marketing",
  "Developer",
  "Engineer",
];

export const sellerRoles = [
  "Sales",
  "Marketing",
  "Alliances",
  "Product Management",
];

export const buyerRoleOptions = [
  { type: "Procurement", value: "Procurement" },
  { type: "IT Infrastructure", value: "IT Infrastructure" },
  { type: "IT Applications", value: "IT Applications" },
  { type: "Digital", value: "Digital" },
  { type: "Human Resource", value: "Human Resource" },
  { type: "Architect", value: "Architect" },
  { type: "Alliances", value: "Alliances" },
  { type: "Sales", value: "Sales" },
  { type: "Pre-Sales", value: "Pre-Sales" },
  { type: "Marketing", value: "Marketing" },
  { type: "Developer", value: "Developer" },
  { type: "Engineer", value: "Engineer" },
];

export const sellerRoleOptions = [
  { type: "Sales", value: "Sales" },
  { type: "Marketing", value: "Marketing" },
  { type: "Alliances", value: "Alliances" },
  { type: "Product Management", value: "Product Management" },
];

export const validationSchema = yup.object({
  Name: yup
    .string("Enter Name")
    .min(3, "Name should be of minimum 2 characters length")
    .required("Name is required"),
  Designation: yup
    .string("Enter your Designation")
    .min(2, "Designation should be of minimum 2 characters length")
    .required("Designation is required"),
  Role: yup
    .string("Enter your Job Role")
    .oneOf(buyerRoles.concat(sellerRoles), "Please select a valid role")
    .required("Designation is required"),
});
