import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  heading: {
    padding: theme.spacing(2),
  },
  label: {
    "& .MuiFormControlLabel-label": {
      fontSize: theme.spacing(1.2),
    },
  },
  productImage: {
    "& img": {
      objectFit: "contain",
    },
  },
  tabname: {
    fontSize: theme.spacing(1.2),
  },
  tableIndexCell: {
    width: theme.spacing(20),
    fontWeight: "bold",
  },
  hidecell: {
    display: "none",
  },
  vendorResponseRoot: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(2),
    minWidth: theme.spacing(100),
  },
  responsePaper: {
    width: "100%",
  },
  actionDrawer: {
    "& .MuiDrawer-paper": {
      width: "60%",
      padding: "8px",
    },

    "& .MuiAvatar-square": {
      width: "70px",
      height: "70px",
      "& img": {
        objectFit: "contain",
      },
    },

    "& .makeStyles-companyDetailsDiv-736": {
      margin: 0,
    },
  },
  poLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  },
  submitBtn: {
    marginTop: "16px",
    textAlign: "right",
  },
  sowDiv: {
    marginBottom: "16px",
    fontWeight: "bold",
  },
  sowLogo: {
    display: "flex",
    justifyContent: "end",
  },
  sowTitle: {
    marginBottom: "16px",
    textAlign: "center",
  },
  sowTable: {
    border: "1px solid #ebebeb",
  },
  sowItem: {
    margin: "8px 0",
  },
  sowHeading: {
    fontWeight: "600",
  },
  sowItemName: {
    fontSize: "0.8rem",
    fontWeight: "600",
  },
  sowItemValue: {
    fontSize: "0.85rem",
  },
  sowValueTable: {
    margin: "8px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sowSubValue: {
    verticalAlign: "sub",
  },
  cardTitle: {
    "& span": {
      fontSize: "0.9rem",
      fontWeight: "bold",
    },
    borderBottom: "1px solid #BEBEBE",
    padding: "8px 16px",
  },
  section: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  fieldTitle: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    color: "#000",
    display: "flex",
  },
  fieldValue: {
    fontSize: "0.9rem",
    color: "#000",
    lineHeight: "inherit",
    marginLeft: theme.spacing(0.5),
  },
  fieldSelectValue: {
    fontSize: "0.9rem",
    cursor: "pointer",
    lineHeight: "inherit",
    marginLeft: theme.spacing(0.5),
    color: "#4179BD",
  },
  errorMessage: {
    color: "red !important",
    fontSize: "0.75rem !important",
    marginTop: "-4px !important",
  },
  actionCard: {
    width: "400px",
  },
  iframe: {
    width: "100%",
    height: "90vh",
  },
}));
