import * as React from "react";

import { DataGrid } from "@material-ui/data-grid";
import MapTopic from "../groups2.0/maptopic";
import TopicMapping from "./TopicMapping";
import SelectField from "../common/SelectField";
import { CircularProgress, Paper } from "@material-ui/core";

export default function ProdDataGrid({
  loading,
  rows,
  columns,
  method,
  onEdit,
  pageChange,
}) {
  const [productIds, setProductIds] = React.useState([]);
  return (
    <>
      <div>
        <TopicMapping productIds={productIds} />
        {rows ? (
          <Paper>
            <div style={{ height: 480, width: "100%" }}>
              <DataGrid
                rows={loading ? [] : rows}
                columns={columns}
                pageSize={10}
                checkboxSelection
                disableSelectionOnClick
                loading={loading}
                // autoHeight={true}
                rowHeight={100}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = rows.filter((row) =>
                    selectedIDs.has(row.id.toString())
                  );
                  // console.log("selectedRowData", selectedRowData);
                  const nowSelected = selectedRowData.map((meow) => meow.id);
                  // console.log("nowSelected222", nowSelected);
                  setProductIds(nowSelected);
                }}
              />
            </div>
          </Paper>
        ) : null}
        {loading ? <CircularProgress /> : null}
      </div>
    </>
  );
}

// import React from "react";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
// import ListItemText from "@material-ui/core/ListItemText";
// import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Checkbox from "@material-ui/core/Checkbox";
// import Avatar from "@material-ui/core/Avatar";
// import { ListItemIcon } from "@material-ui/core";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: "100%",
//       maxWidth: 360,
//       backgroundColor: theme.palette.background.paper,
//     },
//   })
// );

// export default function ProdDataGrid({ data, loading }) {
//   const classes = useStyles();

//   // const { data } = props;
//   const [checked, setChecked] = React.useState([1]);

//   if (data) {
//     console.log("dataaa", data);
//   }

//   const handleToggle = (value: number) => () => {
//     const currentIndex = checked.indexOf(value);
//     const newChecked = [...checked];

//     if (currentIndex === -1) {
//       newChecked.push(value);
//     } else {
//       newChecked.splice(currentIndex, 1);
//     }

//     setChecked(newChecked);
//   };

//   return (
//     <List dense className={classes.root}>
//       {data?.map((value) => {
//         console.log("value", value);
//         const labelId = `checkbox-list-secondary-label-${value}`;
//         return (
//           <ListItem key={value} button>
//             <ListItemIcon>
//               <Checkbox
//                 edge="start"
//                 checked={checked.indexOf(value) !== -1}
//                 tabIndex={-1}
//                 disableRipple
//                 inputProps={{ "aria-labelledby": labelId }}
//               />
//             </ListItemIcon>
//             <ListItemAvatar>
//               <Avatar
//               // alt={`Avatar n°${value + 1}`}
//               // src={`/static/images/avatar/${value + 1}.jpg`}
//               >
//                 {value.image}
//               </Avatar>
//             </ListItemAvatar>
//             <ListItemText>{value.name}</ListItemText>
//             <ListItem>
//               <ListItemText styles={{ marginLeft: "60%" }}>
//                 {value.shortdescription}
//               </ListItemText>
//             </ListItem>
//           </ListItem>
//         );
//       })}
//     </List>
//   );
// }

// import * as React from "react";
// import {
//   DataGrid,
//   GridColDef,
//   GridValueGetterParams,
// } from "@material-ui/data-grid";

// const columns: GridColDef[] = [
//   { field: "id", headerName: "ID", width: 90 },
//   {
//     field: "firstName",
//     headerName: "First name",
//     width: 150,
//     editable: true,
//   },
//   {
//     field: "lastName",
//     headerName: "Last name",
//     width: 150,
//     editable: true,
//   },
//   {
//     field: "age",
//     headerName: "Age",
//     type: "number",
//     width: 110,
//     editable: true,
//   },
//   {
//     field: "fullName",
//     headerName: "Full name",
//     description: "This column has a value getter and is not sortable.",
//     sortable: false,
//     width: 160,
//     valueGetter: (params: GridValueGetterParams) =>
//       `${params.getValue(params.id, "firstName") || ""} ${
//         params.getValue(params.id, "lastName") || ""
//       }`,
//   },
// ];

// const rows = [
//   { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
//   { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
//   { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
//   { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
//   { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
//   { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
//   { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
//   { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
//   { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
// ];

// export default function SimilarProdDataGrid() {
//   return (
//     <div style={{ height: 400, width: "100%" }}>
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         pageSize={5}
//         checkboxSelection
//         disableSelectionOnClick
//       />
//     </div>
//   );
// }
