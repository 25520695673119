import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Divider, Grid, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

import { useQuery } from "@apollo/client";

import { LIST_QA_SECTIONS } from "../../queries/query";

// import { customAlphabet } from "nanoid/non-secure";

import RTextItem from "../Items/both/textItem";
import RTextAreaItem from "../Items/both/textAreaItem";
import RSelectItem from "../Items/both/selectItem";
import RCurrencyItem from "../Items/both/currencyItem";
import RFileItem from "../Items/both/fileupload";
import RDateItem from "../Items/both/dateItem";
import RNumberItem from "../Items/both/numberItem";
import { useState } from "react";

// const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz-", 10);

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(3),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
  },
  heading: {
    fontSize: theme.spacing(2),
  },
  properties: {
    width: theme.spacing(50),
  },
  propertiestitle: {
    marginBottom: theme.spacing(2),
  },
  propsbuttongroups: {
    marginTop: theme.spacing(2),
  },
  propsbutton: {
    margin: theme.spacing(1),
  },
  helpicon: {
    fontSize: theme.spacing(1.5),
    cursor: "pointer",
  },
  weight: {
    fontSize: theme.spacing(1.5),
  },
  caption: {
    color: "red",
  },
}));

export default function RSection(props) {
  const classes = useStyles();

  //   const [items, setItems] = React.useState([]);
  const [metadata, setMetadata] = useState(props.metadata);

  const { loading, error, data, refetch } = useQuery(LIST_QA_SECTIONS, {
    fetchPolicy: "no-cache",
    variables: {
      tinput: { sectionid: props.sid },
      pinput: {
        sectionid: props.sid,
        projectid: props.projectId,
        productvbeid: props.productId,
      },
    },
  });

  function getItem(key, type, id, metadata, values) {
    switch (type) {
      case "text_field":
        return (
          <RTextItem
            key={key}
            id={id}
            sid={props.sid}
            productId={props.productId}
            type={props.type}
            metadata={metadata}
            values={values}
            readOnly={props.readOnly}
            refetch={refetch}
          />
        );
      case "text_area":
        return (
          <RTextAreaItem
            key={key}
            rows={4}
            id={id}
            sid={props.sid}
            productId={props.productId}
            type={props.type}
            metadata={metadata}
            values={values}
            readOnly={props.readOnly}
            refetch={refetch}
          />
        );

      case "multi_choice":
        return (
          <RSelectItem
            key={key}
            id={id}
            sid={props.sid}
            productId={props.productId}
            type={props.type}
            metadata={metadata}
            values={values}
            readOnly={props.readOnly}
          />
        );

      case "currency":
        return (
          <RCurrencyItem
            key={key}
            id={id}
            sid={props.sid}
            productId={props.productId}
            type={props.type}
            metadata={metadata}
            values={values}
            readOnly={props.readOnly}
          />
        );

      case "attachment":
        return (
          <RFileItem
            key={key}
            id={id}
            sid={props.sid}
            productId={props.productId}
            type={props.type}
            metadata={metadata}
            values={values}
            readOnly={props.readOnly}
          />
        );

      case "date":
        return (
          <RDateItem
            key={key}
            id={id}
            sid={props.sid}
            productId={props.productId}
            type={props.type}
            metadata={metadata}
            values={values}
            readOnly={props.readOnly}
          />
        );

      case "number":
        return (
          <RNumberItem
            key={key}
            id={id}
            sid={props.sid}
            productId={props.productId}
            type={props.type}
            metadata={metadata}
            values={values}
            readOnly={props.readOnly}
          />
        );

      default:
        break;
    }
  }

  function getValues(values, itemid, type) {
    const result = values.filter((value) => value.itemid === itemid);
    result.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp));

    return result ? result[0] : {};
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={1}>
        <Avatar className={classes.small} color="primary">
          <Typography variant="caption" display="block">
            {metadata.id}
          </Typography>
        </Avatar>
      </Grid>

      <Grid item xs={9}>
        <Typography variant="h6" display="block" className={classes.heading}>
          {metadata.title}
          <Tooltip title={metadata.help_message}>
            <HelpIcon fontSize="small" className={classes.helpicon} />
          </Tooltip>
        </Typography>
      </Grid>

      <Grid item xs={2}>
        {props.type === "evaluate_template" ? (
          <Typography variant="caption" className={classes.weight}>
            Weight:{metadata.weight}
          </Typography>
        ) : null}
      </Grid>

      <Grid item xs={1}></Grid>

      <Grid item xs={11}>
        <Divider />
      </Grid>

      <Grid item xs={1}></Grid>

      <Grid item xs={11}>
        {data
          ? data.listItem.map((item) => {
              var meta = JSON.parse(item.metadata);
              var values = getValues(
                data.getQuestionTempItemvalue,
                item.id,
                ""
              );

              var input = {
                itemid: item.id,
                sectionid: props.sid,
                projectid: props.projectId,
                productvbeid: props.productId,
                attachment: "",
                attachmentfilename: "",
                timestamp: null,
                value: "",
                remarks: "",
              };

              input = { ...input, ...values };
              delete input.__typename;

              return getItem(item.id, item.type, item.id, meta, { ...input });
            })
          : null}
      </Grid>
    </Grid>
  );
}
