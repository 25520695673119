import { gql, useQuery } from "@apollo/client";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";

import React, { useState } from "react";
import { getSkillName } from "../../../../../../functions/helper";

export const VBRIDGEHUB_MEDIAURL = process.env.REACT_APP_VBRIDGEHUB_MEDIAURL;

const searchQuery = gql`
  query esQuery($input: esinput) {
    esQuery(input: $input) {
      result {
        name
        vbeid
        image
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  avatar: {
    width: "20px !important",
    height: "20px !important",
    margin: "auto",
    backgroundColor: "#f5f5f5 !important",
    color: "#232961 !important",
  },
  companyLogo: {
    minWidth: "28px",
  },
  text: {
    textTransform: "capitalize",
    fontSize: "10px !important",
    fontWeight: "600 !important",
  },

  labelSearch: {
    marginBottom: "8px !important",

    "& label": {
      fontSize: "13px !important",
    },
  },
  loading: {
    fontSize: "12px !important",
    margin: "8px  16px !important",
    color: "teal",
    fontWeight: "600 !important",
  },
}));

function ReultListItem(props) {
  const {
    item,
    handleClose,
    setValue,
    setDomain,
    setName,
    setCompany,
    sentSkillVbeid,
  } = props;
  const classes = useStyles();

  function handleItemClick(e) {
    e.preventDefault();

    let { name, domain, vbeid } = item;

    if (setCompany) {
      setCompany(true);
    }

    setValue(name);
    setName(name);

    if (sentSkillVbeid) {
      sentSkillVbeid(vbeid);
    }
    if (setDomain) {
      setDomain("https://" + domain);
    }

    handleClose();
  }

  return (
    <ListItem key={item.vbeid} button dense={true} onClick={handleItemClick}>
      <ListItemAvatar className={classes.companyLogo}>
        <Avatar
          className={classes.avatar}
          aria-describedby="avatar-popover"
          variant="square"
          size="small"
          src={VBRIDGEHUB_MEDIAURL + "/" + item.image}
        >
          <BusinessIcon />{" "}
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={
          <Typography
            variant="button"
            display="block"
            color="primary"
            className={classes.text}
          >
            {item.name}
          </Typography>
        }
      />
    </ListItem>
  );
}

function ResultList({
  text,
  handleClose,
  setValue,
  setDomain,
  setName,
  setCompany,
  sentSkillVbeid,
}) {
  const { data, loading } = useQuery(searchQuery, {
    variables: { input: { index: "skill", name: text } },
  });

  const classes = useStyles();

  let searchList = [];

  if (data?.esQuery.result.length !== 0) {
    searchList = data?.esQuery.result.map((r) => (
      <ReultListItem
        item={r}
        handleClose={handleClose}
        setValue={setValue}
        setDomain={setDomain}
        setName={setName}
        setCompany={setCompany}
        sentSkillVbeid={sentSkillVbeid}
        loading={loading}
      />
    ));
  } else {
    let noResult = {
      name: "Skill not Found ",
      domain: "unavailable",
      logo: "unavailable",
    };

    searchList = (
      <ReultListItem
        item={noResult}
        handleClose={handleClose}
        setValue={setValue}
        setDomain={setDomain}
        setName={setName}
        setCompany={setCompany}
        sentSkillVbeid={sentSkillVbeid}
      />
    );
  }

  return (
    <>
      {!loading ? (
        <List dense={true} component="nav" className={classes.resultList}>
          {searchList}
        </List>
      ) : (
        <div className={classes.loading}>loading...</div>
      )}
    </>
  );
}

function SearchResult(props) {
  const classes = useStyles();
  const {
    id,
    open,
    anchorEl,
    setAnchorEl,
    text,
    setValue,
    setDomain,
    setName,
    setCompany,
    sentSkillVbeid,
  } = props;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <>
        <Popover
          className={classes.popover}
          id={id}
          open={open}
          anchorEl={anchorEl}
          disableAutoFocus={true}
          disableEnforceFocus={true}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {text ? (
            <ResultList
              setValue={setValue}
              text={text}
              handleClose={handleClose}
              setDomain={setDomain}
              setName={setName}
              sentSkillVbeid={sentSkillVbeid}
              setCompany={setCompany}
            />
          ) : null}
        </Popover>
      </>
    </>
  );
}

export default function SearchSkill({
  setDomain,
  setName,
  setCompany,
  sentSkillVbeid,
  placeholder,
  defaultvalue,
  label,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [text, setText] = useState("");
  const [value, setValue] = useState(defaultvalue);

  const classes = useStyles();
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const handleInputChange = (e) => {
    e.preventDefault();

    setText(e.target.value);
    setValue(e.target.value);
    setAnchorEl(e.target.parentElement.parentElement);
  };

  return (
    <>
      <TextField
        value={value === "unavailable" ? "" : value}
        className={classes.labelSearch}
        placeholder={placeholder}
        onChange={handleInputChange}
        label={label}
        autoComplete="off"
        size="small"
        defaultValue={defaultvalue}
        variant="outlined"
      />

      <SearchResult
        className={classes.resultDiv}
        id={id}
        open={open}
        anchorEl={anchorEl}
        setValue={setValue}
        setAnchorEl={setAnchorEl}
        text={text}
        setDomain={setDomain}
        setName={setName}
        sentSkillVbeid={sentSkillVbeid}
        setCompany={setCompany}
      />
    </>
  );
}
