import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  emptyDesc: {
    color: '#bebebe',
    fontSize: '0.75rem',
    fontStyle: 'italic',
  },

  emptyDescdata: {
    color: '#bebebe',
    fontSize: '0.75rem',
    fontStyle: 'italic',
  },

  emptyRoot: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  emptyMailRoot: {
    fontWeight: 'normal',
  },
  productEmptyRoot: {
    textAlign: 'center',
  },
  awardsMargin: {
    marginTop: '15px',
    marginLeft: theme.spacing(35),
  },
}))

export default function EmptyDiv({ type, margin }) {
  const classes = useStyles()

  return (
    <div
      className={
        margin === 'noTopMargin'
          ? type === 'awards'
            ? classes.awardsMargin
            : classes.productEmptyRoot
          : type !== 'Mail Domain' && margin !== 'noMargin'
          ? classes.emptyRoot
          : classes.emptyMailRoot
      }
    >
      <span className={classes.emptyDesc}>
        {type === 'search' ? 'No matching results' : `No ${type} available`}
      </span>
    </div>
  )
}
