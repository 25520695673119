/**
 * Component rendered when user Declined/Skipped NDA on Zoho
 */

import { useHistory } from "react-router-dom";

import { useQuery } from "@apollo/client";

import clsx from "clsx";
import { useStyles } from "./styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import MainLoading from "../../shared/loading";
import { getNDAStatus } from "../companies/company/query";
import OutlinedButton from "../companies/company/OutlinedButton";

export default function AgreementFail() {
  const classes = useStyles();
  const history = useHistory();

  const pathname = history.location.pathname;

  const redIcon = clsx({
    [classes.statusIcon]: true,
    [classes.redStatusIcon]: true,
  });

  const homeLink = clsx({
    [classes.ndaText]: true,
    [classes.linkText]: true,
  });

  const onHomePageClick = () => {
    history.push("/");
  };

  const { loading, data } = useQuery(getNDAStatus, {
    variables: { input: {} },
  });

  if (loading) {
    return (
      <>
        <MainLoading />
        <div className={classes.loadingText}>
          Please wait while we get a confirmation
        </div>
      </>
    );
  }

  return (
    <div className={classes.root}>
      {data && data.getNDAStatus ? (
        data.getNDAStatus?.status === "declined" ||
        data.getNDAStatus?.status === "inprogress" ? (
          <>
            <HighlightOffIcon className={redIcon} />

            <div className={classes.ndaText}>
              {pathname === "/agreementSkip" ? "Skipped" : "Declined"}
            </div>

            <div className={classes.heading}>
              <span className={classes.ndaText}>Please note</span> : Members
              prefer to engage with organisations that have signed a mutual NDA.
            </div>

            <div>
              You can come back to sign the mutual NDA from the{" "}
              <span className={homeLink} onClick={onHomePageClick}>
                Home Page.
              </span>{" "}
            </div>

            <div style={{ marginTop: "8px" }}>
              Should you need any clarifications, please send us a mail to {""}
              <a href="mailto:sales@vbridgehub.com">sales@vbridgehub.com</a>
            </div>
          </>
        ) : (
          // data.getNDAStatus === null ? (
          //   <div className={classes.ndaText}>Something went wrong!</div>
          // ) :
          <>
            <div className={classes.heading} style={{ marginTop: "150px" }}>
              Something went wrong!
            </div>
            <div className={classes.agreeButton}>
              <OutlinedButton
                label="Go to Home Page"
                onClick={() => {
                  history.push("/");
                }}
                type="contained"
              />
            </div>
          </>
        )
      ) : null}
    </div>
  );
}
