import { gql } from "@apollo/client";

//companies list
export const getCompanies = gql`
  query companies($input: id!) {
    getCompaniesByBatch(input: $input) {
      id
      name
      image
      companyurl
      facebookurl
      linkedinurl
      twitterurl
      location
      founded
      founder
      businessvertical
      shortdescription
      longdescription
      longdescriptionupdated
      orgtype
      partnercount
      customercount
      awardcount
    }
  }
`;

//update company
export const postCompanyInfo = gql`
  mutation updatecompany($input: companyinput!) {
    updateCompany(input: $input) {
      id
      name
      image
      companyurl
      facebookurl
      linkedinurl
      twitterurl
      location
      founded
      founder
      businessvertical
      shortdescription
      longdescription
      longdescriptionupdated
      orgtype
      partnercount
      customercount
      awardcount
    }
  }
`;

//Merge Companies
export const mergeCompanies = gql`
  mutation merge($input: lists!) {
    mergeCompany(input: $input) {
      status
      vbeids
    }
  }
`;

///get batches list
export const getBatches = gql`
  query getList {
    getBatchesList(input: { offset: 0, limit: 50 }) {
      id
      name
      vbeid
    }
  }
`;

//list org Types
export const getOrgTypeList = gql`
  query orgTypes {
    listOrgtypes {
      name
      vbeid
    }
  }
`;

//update Org Type
export const updateOrgTypeToCompany = gql`
  mutation mapOrgType($input: companyinput!) {
    UpdateOrgType(input: $input) {
      id
    }
  }
`;

//get org type by company
export const getOrgTypeByCompanyId = gql`
  query orgTypeByCompanyId($input: companyinput!) {
    getOrgTypesByCompanyId(input: $input) {
      name
      id
      orgtype
    }
  }
`;

//get products list by batch id
export const getProducts = gql`
  query products($input: id!) {
    getProductsByBatch(input: $input) {
      id
      name
      shortdescription
      longdescription
      longdescriptionupdated
      producturl
      image
      companyname
      topiclist {
        name
        vbeid
      }
    }
  }
`;

//productelById for extracted long description

export const productelById = gql`
  query productelongdesc($input: productinput!) {
    productelById(input: $input) {
      longdescription
      elongdescription
    }
  }
`;

export const companyelById = gql`
  query companyLongDesc($input: id!) {
    companyelById(input: $input) {
      id
      vbeid
      longdescription
    }
  }
`;

export const usecaseselById = gql`
  query usecaseLongDesc($input: id!) {
    usecaseselById(input: $input) {
      id
      vbeid
      longdescription
    }
  }
`;

export const caseStudyelById = gql`
  query casestudyLongDesc($input: id!) {
    caseStudyelById(input: $input) {
      id
      vbeid
      longdescription
    }
  }
`;

//update product
export const updateProduct = gql`
  mutation updatingproduct($input: productinput!) {
    updateProduct(input: $input) {
      id
      name
      shortdescription
      longdescription
      producturl
      image
      companyname
      topiclist {
        name
        vbeid
      }
    }
  }
`;

//view similar products
export const viewSimilars = gql`
  query similars($input: id!) {
    getSimilarProducts(input: $input) {
      name
      id
      image
      shortdescription
      topicname
      topicvbeid
      companyname
    }
  }
`;

//view usecases by batch
export const getUsecases = gql`
  query usecases($input: id!) {
    getUsecasesByBatch(input: $input) {
      id
      name
      url
      shortdescription
      longdescription
      longdescriptionupdated
      industryvertical
      image
      productname
      productid
      companyname
      companyvbeid
    }
  }
`;

//update usecase
export const updateUsecase = gql`
  mutation updatingusecase($input: usecaseinput!) {
    updateusecase(input: $input) {
      id
      name
      url
      shortdescription
      longdescription
      longdescriptionupdated
      industryvertical
      image
      productname
      productid
      companyname
      companyvbeid
    }
  }
`;

//list Products
export const listProducts = gql`
  query listing($input: companyinput!) {
    productByComapnyId(input: $input) {
      name
      vbeid
    }
  }
`;

//view casestudies by batch
export const getCasestudies = gql`
  query usecases($input: id!) {
    getCaseStudiesByBatch(input: $input) {
      id
      name
      url
      shortdescription
      longdescription
      longdescriptionupdated
      businessvertical
      image
      productname
      productid
      companyname
      companyvbeid
      filepath
    }
  }
`;

//update usecase
export const updateCasestudy = gql`
  mutation updatingcasestudy($input: casestudyinput!) {
    updatecasestudy(input: $input) {
      id
      name
      url
      shortdescription
      longdescription
      longdescriptionupdated
      businessvertical
      image
      productname
      productid
      companyname
      companyvbeid
      filepath
    }
  }
`;

//upload casestudy file
export const updateCaseStudyFile = gql`
  mutation update($input: casestudyinput!) {
    updatecasestudy(input: $input) {
      filepath
      vbeid
    }
  }
`;

//mapping relation
export const mappingRelation = gql`
  mutation relationmap($input: id!) {
    relationshipmapping(input: $input) {
      id
      listdetails {
        name
        vbeid
      }
    }
  }
`;

//unmapping the relation
export const unMappingRelation = gql`
  mutation relationunmap($input: id!) {
    removerelationshipmapping(input: $input) {
      id
      listdetails {
        name
        vbeid
      }
    }
  }
`;
