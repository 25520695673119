import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import GetAppIcon from "@material-ui/icons/GetApp";

import { useLazyQuery, useQuery } from "@apollo/client";

import {
  GET_PO_DETAILS,
  GET_RESOURCE_LOGS,
  LIST_CANDIDATES,
} from "../../queries/query";

import {
  getActionValue,
  getCandidateStatus,
  getDate,
  getFormattedDate,
} from "../../../../../functions/helper";

import { getLongDescImage } from "../../../products/product2.0/query";

import CandidateActionSlider from "./CandidateActionSlider";
import PrimaryButton from "../../../common/PrimaryButton";
import CandidateDetailsForm from "./CandidateDetailsForm";
import ResourceSlider from "./ResourceProfileSlider";

export default function CandidateTable({ projectData, companies }) {
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [actionSlider, setActionSlider] = useState(false);
  const [action, setAction] = useState("");
  const [selectedResource, setSelectedResource] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState("");
  const [openAddSlider, setOpenAddSlider] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [profileSlider, setProfileSlider] = useState(false);
  const [profileUrl, setProfileUrl] = useState("");

  const menuOpen = Boolean(anchorEl);

  const handleClick = (event, index) => {
    setCurrentIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setCurrentIndex("");
    setAnchorEl(null);
  };

  const { data, refetch, loading } = useQuery(LIST_CANDIDATES, {
    variables: { input: { vbeid: id } },
    fetchPolicy: "no-cache",
  });

  const [getPODetails, { data: poDetails }] = useLazyQuery(GET_PO_DETAILS, {
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      setActionSlider(true);
      setAnchorEl(null);
    },
  });

  const [getUrl] = useLazyQuery(getLongDescImage, {
    onCompleted: (response) => {
      setProfileUrl(response.getSignedURL.signedurl);
      setProfileSlider(true);
    },
  });

  const [getResourceLogs, { data: resourceLogs }] = useLazyQuery(
    GET_RESOURCE_LOGS,
    {
      onCompleted: (response) => {},
    }
  );

  const getCandidateFile = (fileUrl) => {
    getUrl({
      variables: {
        input: {
          filetype: "pdf",
          key: fileUrl,
        },
      },
    });
  };

  const returnActionItems = (index) => {
    let currentResource = data.listResources[index];

    let action = getActionValue(currentResource.status);

    return (
      <div>
        <Menu
          id={index}
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {action === "requestForInterview" && (
            <div>
              <MenuItem
                key="schedule"
                onClick={() => {
                  onActionClick("schedule");
                }}
              >
                Request For Interview
              </MenuItem>

              <MenuItem
                key="generateSow"
                onClick={() => {
                  onActionClick("generateSow");
                }}
              >
                Generate SoW
              </MenuItem>

              <MenuItem
                key="reject"
                onClick={() => {
                  onActionClick("reject");
                }}
              >
                Reject Resource
              </MenuItem>
            </div>
          )}

          {action === "shortlist" && (
            <div>
              <MenuItem
                key="geneateSow"
                onClick={() => {
                  onActionClick("generateSow");
                }}
              >
                Generate SoW
              </MenuItem>

              <MenuItem
                key="shortlist"
                onClick={() => {
                  onActionClick("shortlist");
                }}
              >
                Shortlist Resource
              </MenuItem>

              <MenuItem
                key="reject"
                onClick={() => {
                  onActionClick("reject");
                }}
              >
                Reject Resource
              </MenuItem>
            </div>
          )}

          {action === "generateSow" && (
            <div>
              <MenuItem
                key="rateChange"
                onClick={() => {
                  onActionClick("requestRateChange");
                }}
              >
                Request for Rate Change
              </MenuItem>

              <MenuItem
                key="generateSow"
                onClick={() => {
                  onActionClick("generateSow");
                }}
              >
                Generate SoW
              </MenuItem>

              <MenuItem
                key="reject"
                onClick={() => {
                  onActionClick("reject");
                }}
              >
                Reject Resource
              </MenuItem>
            </div>
          )}

          {action === "generatePo" && (
            <div>
              <MenuItem
                key={index}
                id={index}
                onClick={() => {
                  onActionClick("generatePo");
                }}
              >
                Upload PO
              </MenuItem>

              <MenuItem
                key="reject"
                onClick={() => {
                  onActionClick("reject");
                }}
              >
                Reject Resource
              </MenuItem>
            </div>
          )}

          {action === "reject" && (
            <div>
              <MenuItem
                key="reject"
                onClick={() => {
                  onActionClick("reject");
                }}
              >
                Reject Resource
              </MenuItem>
            </div>
          )}

          {action === "requestedForRateChange" && (
            <div>
              <MenuItem
                key="rateChange"
                onClick={() => {
                  onActionClick("requestRateChange");
                }}
              >
                Request for Rate Change
              </MenuItem>

              <MenuItem
                key="reject"
                onClick={() => {
                  onActionClick("reject");
                }}
              >
                Reject Resource
              </MenuItem>
            </div>
          )}
        </Menu>
      </div>
    );
  };

  const onActionClick = (action, currentResource, logDetails) => {
    let selectedResource = data.listResources[currentIndex];

    if (currentIndex === "") {
      selectedResource = currentResource;
    }

    if (selectedResource.status === "UPLOADED_PO") {
      getPODetails({
        variables: {
          input: {
            resourceid: selectedResource.resourceid,
          },
        },
      });

      setAction(action);
      setSelectedResource(selectedResource);

      if (logDetails) {
        setRemarks(logDetails);
      }
    } else {
      setAction(action);
      setActionSlider(true);
      setSelectedResource(selectedResource);
      setAnchorEl(null);

      if (logDetails) {
        setRemarks(logDetails);
      }
    }
  };

  const onClose = () => {
    setActionSlider(false);
    setSelectedResource("");
    setOpenAddSlider(false);
    setProfileSlider(false);
  };

  const handleResourceLog = (index, candidate) => {
    if (open === index) {
      setOpen("");
    } else {
      setOpen(index);

      getResourceLogs({
        variables: {
          input: {
            resourceid: candidate.resourceid,
          },
        },
      });
    }
  };

  const viewSow = (index, remarks) => {
    let currentResource = data.listResources[index];

    setCurrentIndex(index);
    onActionClick("viewSow", currentResource, remarks);
  };

  const viewPO = (index) => {
    let currentResource = data.listResources[index];

    setCurrentIndex(index);
    onActionClick("viewPO", currentResource);
  };

  const viewRemarks = (index, logDetails) => {
    let currentResource = data.listResources[index];

    setCurrentIndex(index);
    onActionClick("viewRemarks", currentResource, logDetails);
  };

  const onResourceClick = (candidate) => {
    setSelectedResource(candidate);
    setOpenAddSlider(true);
  };

  const viewDetails = (candidate, action, remarks) => {
    onActionClick(action, candidate, remarks);
  };

  return (
    <>
      {data && data.listResources.length !== 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Resource Name</TableCell>
                <TableCell>Supplier Name</TableCell>
                <TableCell>Availability Date</TableCell>
                <TableCell>Profile</TableCell>
                <TableCell>Billing Mode</TableCell>
                <TableCell>Billing Rate</TableCell>
                <TableCell>Revised Rate</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
                <TableCell>Logs</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data ? (
                data.listResources.length !== 0 &&
                data.listResources.map((candidate, index) => (
                  <>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => {
                          onResourceClick(candidate);
                        }}
                        style={{ cursor: "pointer", fontWeight: "600" }}
                      >
                        {candidate.name}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {candidate.companyname}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {getFormattedDate(candidate.candidateavailableby)}
                      </TableCell>

                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          getCandidateFile(candidate.attachment);
                        }}
                      >
                        <GetAppIcon />
                      </TableCell>

                      <TableCell>Per hour</TableCell>

                      <TableCell>
                        {candidate.currency} {candidate.billingrate}
                      </TableCell>

                      <TableCell>
                        {candidate.revisedbillingrate ? (
                          <>
                            {candidate.currency} {candidate.revisedbillingrate}
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>

                      <TableCell>
                        {getCandidateStatus(candidate.status)}
                      </TableCell>

                      <TableCell>
                        {candidate.status !== "Withdrawn" &&
                          candidate.status !== "poGenerated" &&
                          candidate.status !== "acceptedSow" &&
                          candidate.status !== "UPLOADED_PO" &&
                          candidate.status !== "SELLER_WITHDRAWN_PO" &&
                          candidate.status !== "SELLER_RFM_PO" &&
                          candidate.status !== "SELLER_ACCEPTED_PO" &&
                          candidate.status !== "VBH_WITHDRAW_PO" &&
                          candidate.status !== "RESOURCE_ONBOARDED" && (
                            <>
                              <IconButton
                                aria-label="more"
                                id={index}
                                aria-controls="long-menu"
                                aria-expanded={menuOpen ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={(event) => {
                                  handleClick(event, index);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>

                              {menuOpen &&
                                currentIndex === index &&
                                returnActionItems(index)}
                            </>
                          )}
                      </TableCell>

                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            handleResourceLog(index, candidate);
                          }}
                        >
                          {open === index ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          backgroundColor: "#eee",
                        }}
                        colSpan={9}
                      >
                        <Collapse
                          in={open === index ? true : false}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            <Typography
                              variant="p"
                              gutterBottom
                              component="div"
                              style={{ fontWeight: "600" }}
                            >
                              Resource Logs
                            </Typography>

                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Status</TableCell>
                                  <TableCell>Date</TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>

                              {resourceLogs &&
                                resourceLogs.getResourceLogs.length !== 0 && (
                                  <TableBody>
                                    {resourceLogs?.getResourceLogs.map(
                                      (log, count) => {
                                        if (log.status !== "SAVED") {
                                          return (
                                            <TableRow key={count}>
                                              <TableCell>
                                                {getCandidateStatus(log.status)}
                                              </TableCell>

                                              <TableCell>
                                                {getDate(log.createdon)}
                                              </TableCell>

                                              <TableCell>
                                                {log.status ===
                                                "sowGenerated" ? (
                                                  <PrimaryButton
                                                    label="View SoW"
                                                    onClick={() =>
                                                      viewSow(
                                                        index,
                                                        log.remarks
                                                      )
                                                    }
                                                    variant="outlined"
                                                    size="small"
                                                  />
                                                ) : log.status ===
                                                  "UPLOADED_PO" ? (
                                                  <PrimaryButton
                                                    label="View PO"
                                                    onClick={() =>
                                                      viewPO(index)
                                                    }
                                                    variant="outlined"
                                                    size="small"
                                                  />
                                                ) : log.status ===
                                                    "requestForInterview" ||
                                                  log.status ===
                                                    "interviewScheduled" ? (
                                                  <PrimaryButton
                                                    label="View"
                                                    onClick={() => {
                                                      if (
                                                        log.status ===
                                                        "requestForInterview"
                                                      ) {
                                                        viewDetails(
                                                          candidate,
                                                          "viewInterviewDetails",
                                                          log.remarks
                                                        );
                                                      } else {
                                                        viewDetails(
                                                          candidate,
                                                          "viewSchedule",
                                                          log.remarks
                                                        );
                                                      }
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                  />
                                                ) : log.status !== "SAVED" &&
                                                  log.status !== "Submitted" &&
                                                  log.status !== "Added" &&
                                                  log.status !== "SUBMITTED" ? (
                                                  <PrimaryButton
                                                    label="View"
                                                    onClick={() =>
                                                      viewRemarks(
                                                        index,
                                                        log.remarks
                                                      )
                                                    }
                                                    variant="outlined"
                                                    size="small"
                                                  />
                                                ) : null}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        } else return null;
                                      }
                                    )}
                                  </TableBody>
                                )}
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              ) : (
                <div style={{ textAlign: "center", marginTop: "24px" }}>
                  <CircularProgress />
                </div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : loading ? (
        <div style={{ textAlign: "center", marginTop: "24px" }}>
          <CircularProgress />
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Typography
            style={{
              marginTop: "16px",
              fontStyle: "italic",
              color: "#999",
            }}
          >
            Suppliers are yet to submit Resource profiles
          </Typography>
        </div>
      )}

      {actionSlider && (
        <CandidateActionSlider
          actionSlider={actionSlider}
          onClose={onClose}
          action={action}
          refetch={refetch}
          selectedResource={selectedResource}
          projectData={projectData}
          poDetails={poDetails?.listPO}
          remarks={remarks}
        />
      )}

      {profileSlider && (
        <ResourceSlider
          open={profileSlider}
          onClose={onClose}
          selectedResource={selectedResource}
          profileDetails={profileUrl}
        />
      )}

      {selectedResource && openAddSlider && (
        <CandidateDetailsForm
          id={id}
          refetch={refetch}
          open={openAddSlider}
          onClose={onClose}
          projectData={projectData}
          selectedResource={selectedResource}
          readOnly={selectedResource.status === "Added"}
        />
      )}
    </>
  );
}
