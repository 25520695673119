import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Paper, CardContent, Card, Box, Grid } from "@material-ui/core";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

// import Policy from "../../../shared/policy";

import {
  getCompanyByCompanyId,
  getCompanyById,
  getProfileAdminsQuery,
  updateCompanyDetails,
  userInfoQuery,
} from "./query";
import { userPermission } from "../../groups2.0/query";

import { useStyles } from "./styles";

import DataPageHeader from "../../common/DataPageHeader";
import OnboardingSteps from "../../common/OnboardingSteps";

import DataLoading from "../../products/product2.0/DataLoading";
import LeftPaneProducts from "../../products/product2.0/LeftPaneProducts";

import CompanyTabs from "./companytabs";

import { getOrgType } from "../../../../functions/helper";

import StepperButtonGroup from "../../profile2.0/StepperButtonGroup";
import RequestForProfileAdminAccess from "../../profile2.0/RequestForProfileAdminAccess";

export default function Company({ toggleValue, userInfo, drawerOpen }) {
  const classes = useStyles();
  const { id } = useParams();
  const client = useApolloClient();
  const history = useHistory();

  const [editName, setEditName] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editNameValue, setEditNameValue] = useState("");
  const [editDescriptionValue, setEditDescriptionValue] = useState("");
  const [isReadOnly, setReadonly] = useState(true);
  const [isVbh, setVbh] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [isbuyer, setBuyer] = useState(true);

  const admin_role = "PROFILE_ADMIN";
  const request_admin_role = "PROFILE_ADMIN_ACCESS_REQUESTED";

  let pathName = history.location.pathname.split("/");

  const user = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  const showSteppers =
    user?.getLoginUserInfo.companyvbeid === "" ||
    user?.getLoginUserInfo.isProfileUpdated !== "true";
  //|| user?.getLoginUserInfo.onBoardingCompleted === false;

  const afterLoadingMainData = () => {
    setReadonly(
      !(
        user?.getLoginUserInfo.companyvbeid === id &&
        user?.getLoginUserInfo.role.includes(admin_role)
      )
    );

    setAdmin(user?.getLoginUserInfo.companyvbeid === id);

    setBuyer(
      user?.getLoginUserInfo.companyvbeid === id &&
        user?.getLoginUserInfo.roletype === "buyer"
    );
  };

  const { data } = useQuery(getCompanyById, {
    variables: { input: { vbeid: id } },
    onCompleted: (e) => {
      afterLoadingMainData();
    },
  });

  const { data: admin_data } = useQuery(getProfileAdminsQuery, {
    variables: { input: {} },
  });

  const { data: similarCompany } = useQuery(getCompanyByCompanyId, {
    variables: { input: { vbeid: id } },
  });

  const [postcompanymutation] = useMutation(updateCompanyDetails);

  const postUpdateCompany = (paramName, paramValue, id) => {
    postcompanymutation({
      variables: {
        input: {
          vbeid: id,
          [paramName]: paramValue,
        },
      },
    });
  };

  //update cache
  const updateCache = (paramName, paramValue) => {
    if (paramValue !== "") {
      let updatedCompany = { ...data.queryCompanybyid };
      updatedCompany[paramName] = paramValue;

      client.writeQuery({
        query: getCompanyById,
        data: {
          queryCompanybyid: updatedCompany,
        },

        variables: { input: { vbeid: id } },
      });

      postUpdateCompany(paramName, paramValue, id);
    }
  };

  const updateOrgTypeCache = (paramName, paramValue) => {
    let updatedCompany = { ...data.queryCompanybyid };

    let updatedOrgType = updatedCompany.orgtype;

    updatedOrgType = [...updatedOrgType, paramValue];

    updatedCompany.orgtype = updatedOrgType;

    client.writeQuery({
      query: getCompanyById,
      data: {
        queryCompanybyid: updatedCompany,
      },

      variables: { input: { vbeid: id } },
    });
  };

  const handleNameChange = (e) => {
    setEditNameValue(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setEditDescriptionValue(e.target.value);
  };

  const editNameClick = () => {
    setEditName(true);
  };

  const saveNameClick = () => {
    setEditName(false);
    updateCache("name", editNameValue);
  };

  const editDescriptionClick = () => {
    setEditDescription(true);
  };

  const saveDescriptionClick = () => {
    setEditDescription(false);

    updateCache("shortdescription", editDescriptionValue);
  };

  const onStepperClick = (nextIndex) => {
    history.push({ pathname: "/register", state: { index: nextIndex } });
  };

  if (admin_data && user?.getLoginUserInfo.companyvbeid === id) {
    if (admin_data.listProfileAdmin.length === 0) {
      history.push({ pathname: "/register", state: { index: 1 } });
    }
  }

  return (
    <div className={drawerOpen ? classes.drawerOpenRoot : classes.root}>
      {pathName[1] === "mycompany" && showSteppers && (
        <OnboardingSteps step={1} />
      )}

      <Box m={pathName[1] === "mycompany" ? 2 : 5}>
        <Grid
          container
          spacing={3}
          style={
            pathName[1] === "mycompany" ? { justifyContent: "center" } : {}
          }
        >
          <Grid item xs={12} sm={pathName[1] === "mycompany" ? 10 : 9}>
            {pathName[1] === "mycompany" && user?.getLoginUserInfo && (
              <div className={classes.stepTitleMessage}>
                {isAdmin && (
                  <RequestForProfileAdminAccess
                    request={
                      user?.getLoginUserInfo?.role.includes(
                        request_admin_role
                      ) &&
                      !user?.getLoginUserInfo.role.includes("PROFILE_ADMIN")
                    }
                    companyName={data?.queryCompanybyid.name}
                    isAdmin={isAdmin}
                    isCompanyAdmin={user.getLoginUserInfo.role.includes(
                      admin_role
                    )}
                    showSteppers={showSteppers}
                  />
                )}

                {data && showSteppers && (
                  <StepperButtonGroup
                    loading={false}
                    createCompanyLoading={false}
                    stepIndex={1}
                    firstTimeUser={true}
                    formikValid={true}
                    companyFormikValid={true}
                    formikDirty={false}
                    companyFormikDirty={false}
                    onBackClick={() => onStepperClick(0)}
                    saveCompanyProfile={() => onStepperClick(2)}
                    showSteppers={showSteppers}
                  />
                )}
              </div>
            )}

            <Paper elevation={1} square={true} style={{ marginBottom: "8px" }}>
              <Card className={classes.card}>
                {data ? (
                  <CardContent style={{ textAlign: "left" }}>
                    <DataPageHeader
                      data={data?.queryCompanybyid}
                      isReadOnly={isReadOnly}
                      vbeid={id}
                      updateCache={updateCache}
                      editName={editName}
                      editDescription={editDescription}
                      editNameClick={editNameClick}
                      handleNameChange={handleNameChange}
                      saveNameClick={saveNameClick}
                      editDescriptionClick={editDescriptionClick}
                      handleDescriptionChange={handleDescriptionChange}
                      saveDescriptionClick={saveDescriptionClick}
                      type="company"
                      isAdmin={isAdmin}
                      userPermission={userPermission}
                      isBuyer={isbuyer}
                    />

                    <CompanyTabs
                      isReadOnly={isReadOnly}
                      isVbh={isVbh}
                      isAdmin={isAdmin}
                      isBuyer={isbuyer}
                      orgType={getOrgType(data.queryCompanybyid.orgtype)}
                      image={data?.queryCompanybyid.image}
                      updateOrgTypeCache={updateOrgTypeCache}
                      toggleValue={toggleValue}
                      tabPath={pathName[3]}
                      userInfo={userInfo}
                      fixedbid={data?.queryCompanybyid?.fixedbid}
                      timerbid={data?.queryCompanybyid?.timeandmaterialbid}
                      productbid={data?.queryCompanybyid?.productbid}
                    />
                  </CardContent>
                ) : (
                  <DataLoading />
                )}
              </Card>
            </Paper>

            {pathName[1] === "mycompany" && showSteppers && (
              <StepperButtonGroup
                loading={false}
                createCompanyLoading={false}
                stepIndex={1}
                firstTimeUser={true}
                formikValid={true}
                companyFormikValid={true}
                formikDirty={false}
                companyFormikDirty={false}
                onBackClick={() => onStepperClick(0)}
                saveCompanyProfile={() => onStepperClick(2)}
              />
            )}
          </Grid>

          {!isAdmin && pathName[1] !== "mycompany" ? (
            <Grid item xs={12} sm={3}>
              {isAdmin && pathName[1] === "mycompany" ? null : (
                <LeftPaneProducts
                  title="Similar Companies"
                  companyName={data?.productById?.companyname}
                  products={similarCompany?.companysBycompanyID}
                  vbeid={id}
                  type="companies"
                  userCompany={user?.getLoginUserInfo.companyvbeid}
                />
              )}
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </div>
  );
}
