import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(7),
  },
  drawerOpenRoot: {
    marginLeft: 220,
    marginTop: theme.spacing(7),
  },
  card: {
    maxWidth: "100%",
    color: "#0e3046",
    lineHeight: "1.8",
    position: "relative",
  },
  square: {
    width: 100,
    height: 100,
    marginTop: "1%",
    marginLeft: "1%",
  },
  ngrid: {
    marginBottom: "3%",
  },
  subHeading: {
    color: theme.palette.secondary.main,
    textAlign: "left",
    textTransform: "capitalize",
  },
  mapProduct: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2),
  },
  leftPaneDiv: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2),
  },
  breadCrumbValue: {
    fontSize: "13px",
    textDecoration: "none",
    fontStyle: "italic",
  },
  documentList: {
    paddingRight: theme.spacing(8),
  },
  documentItem: {
    color: "primary",
    cursor: "pointer",
    textDecoration: "underline",
  },
  deleteIcon: {
    color: "primary",
    cursor: "pointer",
  },
  rootGrid: {
    alignItems: "baseline",
  },
  emptyDivMain: {
    paddingBottom: theme.spacing(1),
  },
  productsListRoot: {
    padding: theme.spacing(0, 0, 1, 0),
  },
  productItem: {
    padding: theme.spacing(0.5, 0),
  },
  tabRoot: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "white",
    marginTop: theme.spacing(1),
  },
  tabs: {
    fontSize: "0.8rem",
    textTransform: "none",
    "& span": {
      textTransform: "capitalize",
    },
  },
  loadingDiv: {
    marginTop: theme.spacing(1),
  },
  companyImage: {
    "& img": {
      objectFit: "contain",
    },
  },
}));
