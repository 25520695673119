import React, { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";

import {
  ClickAwayListener,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

import { addQuestion, listQuestionByProjectProduct } from "./query";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  textInput: {
    width: "100%",
    "& .MuiFilledInput-multiline": {
      padding: "12px 12px 10px",
    },
    "& textarea": {
      fontSize: "0.83rem",
    },
  },
  replyTextInput: {
    marginTop: theme.spacing(1),
    marginLeft: 0,
  },
  sendButton: {
    padding: "6px",
    "& :hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
}));

export default function QuestionForm({
  projectId,
  productId,
  saveMethod,
  id,
  type,
  closeQuestion,
}) {
  const classes = useStyles();
  const client = useApolloClient();

  const [questionText, setQuestionText] = useState("");

  const [addQuestionMutation] = useMutation(addQuestion, {
    onCompleted: (e) => {
      updateQuestionCache(e);
    },
  });

  const questions = client.readQuery({
    query: listQuestionByProjectProduct,
    variables: { input: { projectvbeid: projectId, productvbeid: productId } },
  });

  function sendQuestion() {
    addQuestionMutation({
      variables: {
        input: {
          projectvbeid: projectId,
          productvbeid: productId,
          question: questionText,
        },
      },
    });
  }

  function updateQuestionCache(e) {
    client.writeQuery({
      query: listQuestionByProjectProduct,
      data: {
        listQuestionByProjectProduct: [
          ...questions.listQuestionByProjectProduct,
        ].push(e.addQuestion),
      },
      variables: {
        input: { projectvbeid: projectId, productvbeid: productId },
      },
    });

    closeQuestion();
  }

  const getQuestionText = (e) => {
    setQuestionText(e.target.value);
  };

  const sendReply = () => {
    setQuestionText("");
    saveMethod(questionText);
  };

  const inputClass = clsx({
    [classes.textInput]: true,
    [classes.replyTextInput]: type === "reply",
  });

  return (
    <ClickAwayListener onClickAway={() => closeQuestion()}>
      <div style={{ marginTop: "17.5%" }}>
        <TextField
          id="filled-basic"
          placeholder={type === "question" ? "Start a new discussion" : "Reply"}
          variant="filled"
          className={inputClass}
          onChange={getQuestionText}
          value={questionText}
          multiline
        />

        <IconButton
          onClick={type === "reply" ? sendReply : sendQuestion}
          className={classes.sendButton}
        >
          <SendIcon />
        </IconButton>
      </div>
    </ClickAwayListener>
  );
}
