export const textProperties = {
  label: "TextField",
  max: "50",
  helpMessage: "",
};

export const textAreaProperties = {
  label: "TextArea",
  max: "3",
  helpMessage: "",
};

export const sectionProperties = {
  label: "Heading",
  helpMessage: "",
};

export const fileProperties = {
  label: "File",
  fileType: "image",
  helpMessage: "",
};

export const dropdownProperties = {
  label: "Select",
  options: "",
  helpMessage: "",
  custom: "",
};
