import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
   requestsDashboard:{
    paddingTop: theme.spacing(2)
   },
   arrowAlignInLeadOverview:{
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2)
   },
   backLink: {
    color: theme.palette.secondary.main,
    float: "left",
  },
  questionLength:{
    width:800,
    fontSize:'0.85rem'
  },
   breadCrumbAlignment:{
    paddingBottom: theme.spacing(4)
   },
   breadCrumbText:{
    textDecoration: "none",
    fontStyle: "italic"
   },
   requestListItemHeight:{
    height: 70
   },
   requestListItemText:{
    padding: "2.2rem"
   },
   vendorListSubText:{
    marginTop:'1px'
   },
   reviewAndSubmitTextAlign:{
    textAlign:'left'
   },
   proposalTextBox:{
    width:'900px'
   },
   attachmentsAlign:{
    paddingBottom:theme.spacing(2)
   },
   question: {
    fontSize: "0.83rem",
    "& p": {
      margin: "3px 0 0 0",
      fontSize: "0.85rem",
    },
    wordWrap:'break-word'
  },
  leadHeadShortDescription:{
    textAlign:'left', 
    paddingTop: theme.spacing(1),
    color:'#0e3046'
  },
  leadHeadTimelineTextAlign:{
    textAlign: "left"
  },
  leadHeadNameAndStatus:{
    paddingTop: theme.spacing(2),
     textAlign: "left"
  },
  projectName:{
    fontSize:'1rem',
    fontWeight: 600,
    color: "#0e3046"
  },
  projectHeadTimeline:{
    color: "black"
  },
  projectHeadTimelineUnderline:{
    color: "grey",
    borderBottomStyle: "dotted"
  },
  readMore: {
    fontSize: "0.85rem",
    fontWeight: "normal",
    color: "#0e3046"
  },
  statusInvitedLeadHead:{
    color: "primary", 
    fontSize:'1rem',
    fontWeight: 400,
  },
  statusApprovedLeadHead:{
    color: "green", 
    fontSize:'1rem',
    fontWeight: 400,
  },
  statusRejectedLeadHead:{
    color: "red", 
    fontSize:'1rem',
    fontWeight: 400,
  },
  noQuestionsListTextAlign:{
    fontSize:13, 
    fontWeight:'bold'
  },
  screeningQuestionsDefaultTextAlign:{
    paddingLeft:theme.spacing(12)
  },
  messageButtonLeadHead:{
    paddingLeft: theme.spacing(12),
    //paddingRight:theme.spacing(5),
    //marginBottom:'-12px',
    marginTop:'-4px',
    paddingBottom:theme.spacing(1)
  },
  acceptRejectButtonAlign:{
    marginTop:"6%",
    //paddingBottom:theme.spacing(1)
  },
  acceptRejectButtonSpacing:{
    paddingLeft:theme.spacing(10)
  },
  markResponse:{
    marginBottom:'-18%',
    fontSize:'11px'
  },
    projectPage: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
    },
  
    headName: {
      cursor: "pointer",
      fontWeight: 800,
      fontSize: 12
    },
    requestName: {
      fontWeight: 800,
      fontSize: 12,
    },
    productNameAlign:{
      marginTop:'10px'
    },
    bottomSpace:{
      paddingBottom:theme.spacing(1)
    },
  
    headNameSecondary: {
      color: "grey",
    },
    customerNameAdjustInRequestsList:{
        marginTop: "-2%"
    },
    projectInfoAlignInRequestsList:{
        marginTop: "-1%"
    },
    statusAlignInRequestsList:{
      marginTop:'5px'
    },
    nameAlignInRequestsList:{
        paddingBottom: theme.spacing(1)
    },
    buttonStyle: {
      borderRadius: "2rem",
      textTransform: "capitalize",
      fontSize:'0.7rem'
    },
    drawer: {
        maxWidth: theme.spacing(100),
        minWidth: theme.spacing(99),
        marginTop: theme.spacing(6),
        height: "100%",
      },
      appSliderBar: {
        backgroundColor: "white !important" ,
        position: "absolute !important",
      },
      discussionBody: {
        maxWidth: "50%",
      },
    tableImage: {
      "& img": {
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
      textFields: {
        width: "900px",
      },
    },
    requirementViewSideHeading:{
      textAlign:'left',
      fontSize:'1rem',
      fontWeight:600,
      color:'#0e3046'
    },
    tabRoot: {
        flexGrow: 1,
        width: "100%",
        marginTop:theme.spacing(1),      
      },
      leadTabs:{
        fontSize:"0.7em"
      },
      listingQuestionslessThanTwo:{
        height:200,
    },
      listingQuestions:{
          height:300,
        //   width: 950,
          overflowY: "scroll",
          overflowX: "hidden",
      },
      reviewAndSubmitAccordianHeading:{
        paddingLeft: theme.spacing(10),
        fontSize:'1rem',
        fontWeight:600,
        color:'#0e3046'
      },
      coverLetter:{
        width:'850px'
      },
      errorfields: {
        color: "brown",
        paddingLeft: theme.spacing(12),
        paddingBottom: theme.spacing(3),
        fontWeight: 450,
        fontSize: 12,
      },
      reviewQuestions:{
          width:'950px',
          paddingLeft:theme.spacing(10)
      },
      footerButtons:{
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(35),
    },
    requestHeader:{
      textAlign:'left',
      paddingLeft:theme.spacing(8),
      fontWeight:550,
        fontSize:12,
    },
    headerProjectName:{
      paddingLeft:theme.spacing(3)
    },
    headerProductName:{
      paddingLeft:theme.spacing(9)
    },
    headerStatus:{
      marginLeft:'-13px'
    }

  }));
  