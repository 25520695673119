import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Button, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import PropTypes from "prop-types";

import { customAlphabet } from "nanoid/non-secure";
import { SIGNED_URL, UPDATE_PRODUCT_COST } from "../../queries/query";
import { useState } from "react";
import VbridgeAlert from "../../../../shared/alert";

const axios = require("axios");
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz-", 10);

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  skillcol: {
    // width: theme.spacing(25),
  },
  numbercol: {
    // width: theme.spacing(15),
  },
  input: {
    display: "none",
  },
  download: {
    fontSize: theme.spacing(1.2),
    textTransform: "none",
    "& .MuiButton-label": {
      width: "130px",
      height: "18px",
      overflow: "hidden",
      alignItems: "flex-start",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      justifyContent: "flex-start",
    },
  },
  deleteFile: {
    fontSize: theme.spacing(1.5),
  },
  mainText: {
    fontWeight: "bold",
  },
  table: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 4px",
    },
    "& .MuiTableCell-head": {
      fontSize: "0.75rem",
    },
  },
}));

function LineItem({ data, readOnly, changeItem, addItem, removeItem }) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: data,
    onSubmit: (values) => {
      changeItem(data.id, values);
    },
  });

  React.useEffect(() => {
    if (formik.values !== formik.initialValues) {
      formik.handleSubmit();
    }
  }, [formik.values]);

  return (
    <TableRow>
      <TableCell align={"right"} className={classes.skillcol}>
        <TextField
          autoComplete="off"
          size="small"
          fullWidth
          variant="outlined"
          name="lineItem"
          onChange={formik.handleChange}
          value={formik.values.lineItem}
          placeholder="Line Item"
          disabled={readOnly}
        />
      </TableCell>

      <TableCell align={"right"} className={classes.numbercol}>
        <TextField
          autoComplete="off"
          size="small"
          fullWidth
          variant="outlined"
          name="unit"
          onChange={formik.handleChange}
          value={formik.values.unit}
          placeholder="Units"
          disabled={readOnly}
        />
      </TableCell>

      <TableCell align={"right"} className={classes.numbercol}>
        <TextField
          autoComplete="off"
          size="small"
          type="number"
          fullWidth
          variant="outlined"
          name="quantity"
          onChange={formik.handleChange}
          value={formik.values.quantity}
          placeholder="Count"
          disabled={readOnly}
        />
      </TableCell>

      <TableCell align={"right"} className={classes.numbercol}>
        <TextField
          autoComplete="off"
          size="small"
          type="number"
          fullWidth
          variant="outlined"
          name="rate"
          onChange={formik.handleChange}
          value={formik.values.rate}
          placeholder="USD"
          disabled={readOnly}
        />
      </TableCell>

      <TableCell align={"right"} className={classes.numbercol}>
        <Typography variant="subtitile2">
          {(formik.values.quantity * formik.values.rate).toLocaleString(
            "en-US",
            {
              style: "currency",
              currency: "USD",
            }
          )}{" "}
        </Typography>
      </TableCell>

      <TableCell align="right" autoComplete="off" className={classes.skillcol}>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          name="remarks"
          onChange={formik.handleChange}
          value={formik.values.remarks}
          placeholder="remarks"
          disabled={readOnly}
        />
      </TableCell>

      <TableCell align="right">
        <Grid container>
          <Grid item xs={6}>
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                addItem(formik.values.id);
              }}
              disabled={readOnly}
            >
              <AddIcon />
            </IconButton>
          </Grid>

          <Grid item xs={6}>
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                removeItem(formik.values.id);
              }}
              disabled={readOnly}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

function UploadFile({ uploadFile, upload, uploadPer, readOnly }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={8}>
        <input
          className={classes.input}
          id={"productcostfileinput"}
          type="file"
          onChange={(e) => {
            uploadFile(e);
          }}
        />

        <label htmlFor={"productcostfileinput"}>
          <Button
            variant="contained"
            size="small"
            component="span"
            startIcon={<CloudUploadIcon />}
            disabled={readOnly}
          >
            Upload
          </Button>
        </label>
      </Grid>

      <Grid item xs={4}>
        {upload ? (
          <CircularProgressWithLabel color="secondary" value={uploadPer} />
        ) : null}
      </Grid>
    </Grid>
  );
}

function DownLoadFile(props) {
  const classes = useStyles();

  const [dsignedUrl] = useMutation(SIGNED_URL, {
    onCompleted: (e) => {
      let url = e.gettemplateSignedURL.signedurl;
      window.open(url);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  function deleteFile() {
    let fileValues = JSON.parse(props.formik.values.value);

    let keepFiles = fileValues.filter((file) => {
      return file.name !== props.name;
    });

    props.setFileName(keepFiles);
  }

  function downLoadFile() {
    dsignedUrl({
      variables: {
        input: {
          projectid: props.projectid,
          productid: props.productvbeid,
          attachmentfilename: props.name,
          method: "get",
        },
      },
    });
  }

  return (
    <Grid container>
      <Grid item xs={11}>
        <Button
          onClick={downLoadFile}
          color="secondary"
          size="small"
          component="span"
          className={classes.download}
          startIcon={<AttachFileIcon fontSize="small" />}
        >
          {props.name}
        </Button>
      </Grid>

      {props.readOnly ? null : (
        <Grid item xs={1}>
          <IconButton onClick={deleteFile}>
            <CloseIcon
              color="secondary"
              className={classes.deleteFile}
              fontSize="small"
            />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

export default function ProductCost({
  costData,
  projectid,
  productvbeid,
  readOnly,
}) {
  const classes = useStyles();

  let fileProps = costData.fileName;

  if (fileProps !== "" && fileProps !== null) {
    fileProps = JSON.parse(costData.fileName);
  } else {
    fileProps = [];
  }

  const [data, setData] = useState(costData.data);
  const [fileName, setFileName] = useState(costData.fileName);
  const [filePath, setFilePath] = useState(null);
  const [upload, setUpload] = useState(false);
  const [uploadPer, setUploadPer] = useState(2);
  const [showUpload, setShowupload] = useState(
    costData.fileName ? false : true
  );
  const [filesUploaded, setFilesUploaded] = useState(fileProps);
  const [finalFiles, setFinalFiles] = useState(costData.fileName || "[]");

  const [updateItem, { loading, data: updateData }] = useMutation(
    UPDATE_PRODUCT_COST,
    {
      onCompleted: (e) => {},
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const [signedUrl] = useMutation(SIGNED_URL, {
    onCompleted: (e) => {
      let url = e.gettemplateSignedURL.signedurl;
      let attachment = e.gettemplateSignedURL.attachment;

      setFilePath(attachment);

      putFile(url, attachment);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  function uploadFile(e) {
    setUpload(true);
    var tfile = e.target.files[0];
    let name = tfile.name;

    signedUrl({
      variables: {
        input: {
          projectid: projectid,
          productid: productvbeid,
          attachmentfilename: name,
          method: "put",
        },
      },
    });
  }

  async function putFile(url, attachment) {
    let file = document.getElementById("productcostfileinput").files[0];

    await axios
      .request({
        method: "put",
        url: url,
        data: file,

        onUploadProgress: (p) => {
          const percent = Math.round((p.loaded / p.total) * 100);
          setUploadPer(percent);
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          let uploadedFile = filesUploaded;
          uploadedFile.push({ name: file.name, path: attachment });

          setUpload(false);
          setFilesUploaded(uploadedFile);
          setFileName(file.name);

          let newFiles = JSON.parse(finalFiles);
          newFiles.push({ name: file.name, path: attachment });

          setFinalFiles(JSON.stringify(newFiles));

          fileProps = uploadedFile;
        }
      });
  }

  if (data.length === 0) {
    addItem();
  }

  const reducer = (previousValue, currentValue) =>
    previousValue + currentValue.quantity * currentValue.rate;

  function changeItem(id, values) {
    var fdata = data.filter((item) => item.id === id);
    var cdata = [...data];
    var index = data.indexOf(fdata[0]);
    cdata[index] = values;
    setData(cdata);
  }

  function addItem(id) {
    const nano_id = nanoid();

    let values = {
      id: nano_id,
      lineItem: "",
      unit: "",
      quantity: 0,
      rate: 0,
      remarks: "",
    };

    if (id !== null) {
      var fdata = data.filter((item) => item.id === id);
      var cdata = [...data];
      var index = data.indexOf(fdata[0]);
      cdata.splice(index + 1, 0, values);
      setData(cdata);
      return 0;
    }
    setData([...data, values]);
  }

  function removeItem(id) {
    if (data.length > 1) {
      var fdata = data.filter((item) => item.id !== id);
      setData(fdata);
    }
  }

  function saveData() {
    var values = { fileName: finalFiles, data: data };
    var tempdata = JSON.stringify(values);

    updateItem({
      variables: {
        input: [
          {
            productvbeid: productvbeid,
            projectid: projectid,
            data: tempdata,
          },
        ],
      },
    });
  }

  return (
    <Box m={1}>
      {updateData && (
        <VbridgeAlert
          severity={"success"}
          message={"Draft saved successfully!"}
          open={true}
        />
      )}

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Time Money" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.skillcol}>
                Product Line Item
              </TableCell>
              <TableCell align="left">Measurement</TableCell>
              <TableCell align="left">Quantity</TableCell>
              <TableCell align="left">Unit Rate</TableCell>
              <TableCell align="left">Total</TableCell>
              <TableCell align="left">Remarks</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>

          {data.map((row, i) => {
            return (
              <LineItem
                key={row.id}
                data={row}
                addItem={addItem}
                removeItem={removeItem}
                changeItem={changeItem}
                readOnly={readOnly}
              />
            );
          })}

          <TableHead>
            <TableRow>
              <TableCell colSpan={1} align="right"></TableCell>

              <TableCell colSpan={3} align="left">
                Total
              </TableCell>

              <TableCell colSpan={3} align="left">
                {data.reduce(reducer, 0).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={1} align="right"></TableCell>

              <TableCell colSpan={3} align="left">
                Supporting Document
              </TableCell>

              <TableCell colSpan={2} align="left">
                <UploadFile
                  uploadFile={uploadFile}
                  upload={upload}
                  uploadPer={uploadPer}
                  readOnly={readOnly}
                />

                {filesUploaded.length !== 0 &&
                  filesUploaded.map((file) => {
                    return (
                      <DownLoadFile
                        name={file.name}
                        setUpload={setUpload}
                        setShowupload={setShowupload}
                        setUploadPer={setUploadPer}
                        readOnly={false}
                        updateItem={updateItem}
                        projectid={projectid}
                      />
                    );
                  })}
              </TableCell>
              <TableCell colSpan={1} align="right"></TableCell>
            </TableRow>

            {readOnly ? null : (
              <TableRow>
                <TableCell colSpan={4} align="right"></TableCell>
                <TableCell colSpan={3} align="right">
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={saveData}
                    disabled={readOnly || loading}
                  >
                    {loading ? <CircularProgress size={20} /> : "Save as Draft"}
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
        </Table>
      </TableContainer>
    </Box>
  );
}
