import React, { useRef } from "react";

import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

import QuestionForm from "./QuestionForm";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  questionForm: {
    backgroundColor: "#fff",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  sendButton: {
    position: "fixed",
    bottom: theme.spacing(2),
  },
  newQuestion: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    float: "left",
    fontSize: "0.8rem",
  },
  questionRoot: {
    paddingLeft: theme.spacing(1),
  },
}));

export default function NewQuestion({ projectId, productId }) {
  const classes = useStyles();
  const newQuestionRef = useRef(null);

  const [question, setQuestion] = React.useState(false);

  function openQuestion() {
    setQuestion(true);
  }

  function closeQuestion() {
    setQuestion(false);
  }

  return (
    <div className={classes.root}>
      {question ? (
        <Box p={1}>
          <Grid container justify="flex-start">
            <Grid
              item
              md={10}
              className={classes.questionRoot}
              ref={newQuestionRef}
            >
              <QuestionForm
                projectId={projectId}
                productId={productId}
                closeQuestion={closeQuestion}
                type="question"
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Button
          className={classes.newQuestion}
          startIcon={<QuestionAnswerIcon />}
          variant="contained"
          color="primary"
          onClick={openQuestion}
        >
          New Message
        </Button>
      )}
    </div>
  );
}
