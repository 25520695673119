import * as React from "react";

import { Button } from "@material-ui/core";


export default function CustomisedButton({buttonText, method, disable}){

    return (
        <Button
                    variant="contained"
                    onClick={method}
                    disabled={disable}
                    size="small"
                    color="primary"
                  >
                    {buttonText}
                  </Button>
    )
}