import { Modal } from "@material-ui/core";

import TemplatePreview from "./TemplatePreview";

export default function PreviewModal({
  open,
  template,
  handleClose,
  sectionsData,
  itemsData,
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ width: "80%", margin: "5% auto" }}
    >
      <TemplatePreview
        template={template}
        sections={sectionsData}
        items={itemsData}
        handleClose={handleClose}
      />
    </Modal>
  );
}
