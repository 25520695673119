import { useState } from "react";

import { useParams } from "react-router-dom";

import {
  Paper,
  Grid,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

import TemplateTableContainer from "./templateList";

import { GET_TEMPLATE_BY_PROJECT } from "../../queries/query";

import { useQuery } from "@apollo/client";

import { useStyles } from "./styles";

export default function MapEvaluationTemplate({ projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  const [for_, setFor_] = useState("all");

  const { data, refetch } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    variables: { input: { vbeid: id, typelist: ["evaluate_template"] } },
  });

  return (
    <Grid container alignItems="center" className={classes.templateRoot}>
      <Grid item xs={12}>
        <Grid container className={classes.templatelist}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.textFieldLabel}
            >
              Evaluation Templates
            </Typography>
          </Grid>
        </Grid>

        <Paper elevation={1} className={classes.templatelistPaper}>
          <Grid container>
            <Grid item xs={6}></Grid>

            <Grid item xs={6} style={{ textAlign: "right" }}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="for"
                  name="for"
                  value={for_}
                  onChange={(e) => {
                    setFor_(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio size="small" />}
                    label="All"
                    className={classes.for}
                  />

                  <FormControlLabel
                    value="public"
                    control={<Radio size="small" />}
                    label="vBridge Hub"
                    className={classes.for}
                  />

                  <FormControlLabel
                    value="company"
                    control={<Radio size="small" />}
                    label="My Company "
                    className={classes.for}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TemplateTableContainer
                id={"evaluate_template"}
                for={for_ === "all" ? ["public", "company"] : [for_]}
                status="published"
                refetch={refetch}
                selectedTemplate={data?.getTemplatesByProjectID[0]}
                templateType="evaluation"
                projectData={projectData}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
