import React, { useEffect } from "react";

import { useStyles } from "./styles";

import { BootstrapInput } from "../../../theme/theme";

import { InputLabel, FormControl } from "@material-ui/core";
//import Autocomplete from "@material-ui/lab/Autocomplete";

import { useFormik } from "formik";
import * as yup from "yup";
import { useQuery } from "@apollo/client";
import { listProducts } from "./query";
import SelectField from "../common/SelectField";

const verticals = require("../../../data/companyVerticals.json");

export default function UsecaseUpdateForm({ params, method }) {
  const classes = useStyles();
  const [values, setValues] = React.useState(params.productid);

  //query for products tagged to company
  const { loading: productsloading, data: products } = useQuery(listProducts, {
    variables: { input: { vbeid: params.companyvbeid } },
  });

  //   const getDefaultValue = () => {
  //     let defaultValue = [];

  //     products.productByComapnyId.forEach((product) => {
  //       if (product.vbeid === params.productid) defaultValue = product;
  //     });

  //     return defaultValue;

  //   };

  let companyVerticals = [];
  verticals.forEach((vertical) => {
    if (vertical["n.type"] === "Company Vertical") {
      companyVerticals.push({
        type: vertical["n.name"],
        value: vertical["n.name"],
      });
    }
  });

  //validationSchema
  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter Name")
      .min(3, "Name should be of minimum 2 characters length")
      .required("Name is required")
      .nullable(),

    usecaseurl: yup
      .string("Enter your Usecase URL")
      .nullable()
      .required("Usecase URL is required")
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        "Enter valid Usecase URL"
      ),
  });

  //filter params and updateForm values
  const formik = useFormik({
    initialValues: {
      name: params.name,
      usecaseurl: params.url,
      shortdescription: params.shortdescription,
      industryvertical: params.industryvertical ? params.industryvertical : [],
      productid: params.productid,
    },
    validationSchema: validationSchema,
  });

  //send Formik values
  useEffect(() => {
    method(formik.values, params.id, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, params.id, values]);

  return (
    <form>
      <FormControl fullWidth={true}>
        <InputLabel required={true} shrink focused={true}>
          Solution Name
        </InputLabel>

        <BootstrapInput
          required={true}
          value={formik.values.name}
          onChange={formik.handleChange("name")}
          onBlur={formik.handleBlur("name")}
          placeholder="Enter Usecase Name"
        />

        <div className={classes.errorfields}>
          {formik.touched.name && formik.errors.name ? (
            <div className="error-message">{formik.errors.name}</div>
          ) : null}
        </div>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel required={true} shrink focused={true}>
          Solution Url
        </InputLabel>

        <BootstrapInput
          required={true}
          value={formik.values.usecaseurl}
          onChange={formik.handleChange("usecaseurl")}
          onBlur={formik.handleBlur("usecaseurl")}
          placeholder="Enter Usecase Url"
        />

        <div className={classes.errorfields}>
          {formik.touched.usecaseurl && formik.errors.usecaseurl ? (
            <div className="error-message">{formik.errors.usecaseurl}</div>
          ) : null}
        </div>
      </FormControl>

      <FormControl fullWidth={true}>
        <InputLabel
          required={true}
          shrink
          id="demo-mutiple-checkbox-label"
          focused={true}
        >
          Business Vertical Applicable
        </InputLabel>

        {/* <Select
                      multiple
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-customized-select-native"
                      input={<BootstrapInput />}
                      value={formik.values.industryvertical}
                      onChange={formik.handleChange("industryvertical")}
                      onBlur={formik.handleBlur("industryvertical")}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {verticals.map((group) => {
                        if (group["n.type"] === "Company Vertical") {
                          return (
                            <MenuItem dense={true} value={group["n.name"]}>
                              <Checkbox />

                              <ListItemText primary={group["n.name"]} />
                            </MenuItem>
                          );
                        } else return null;
                      })}
                    </Select> */}

        <SelectField
          //label="Business Vertical"
          onChange={formik.handleChange("industryvertical")}
          value={formik.values.industryvertical}
          options={companyVerticals}
          multiple={true}
        />
      </FormControl>

      <div className={classes.productMapBoxAlign}>
        <FormControl fullWidth={true}>
          <InputLabel required={true} shrink htmlFor="my-input2" focused={true}>
            Choose Applicable Product
          </InputLabel>
          <SelectField
            //label="Applicable Product"
            onChange={formik.handleChange("productid")}
            value={formik.values.productid}
            options={
              productsloading
                ? []
                : products
                ? products.productByComapnyId
                : null
            }
            multiple={false}
          />

          {/* <Autocomplete
                      options={
                        productsloading
                          ? []
                          : products
                          ? products.productByComapnyId
                          : null
                      }
                    //   defaultValue={
                    //     products?.productByComapnyId ? getDefaultValue() : ""
                    //   }
                    defaultValue={params.productname}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newvalue) => {
                        setValues(newvalue ? newvalue.vbeid : null);
                      }}
                      renderOption={(option) => <>{option.name} </>}
                      renderInput={(params) => (
                        <TextField
                          fullwidth
                          {...params}
                          variant="outlined"
                          margin="dense"
                        />
                      )}
                    /> */}
        </FormControl>
      </div>

      <div className={classes.productMapBoxAlign}>
        <FormControl fullWidth={true}>
          <InputLabel required={true} shrink focused={true}>
            Short Description
          </InputLabel>

          <BootstrapInput
            multiline
            rows={3}
            rowsMax={5}
            required={true}
            value={formik.values.shortdescription}
            onChange={formik.handleChange("shortdescription")}
            onBlur={formik.handleBlur("shortdescription")}
            placeholder="Enter shortdescription"
          />

          <div className={classes.errorfields}>
            {formik.touched.shortdescription &&
            formik.errors.shortdescription ? (
              <div className="error-message">
                {formik.errors.shortdescription}
              </div>
            ) : null}
          </div>
        </FormControl>
      </div>
    </form>
  );
}
