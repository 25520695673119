import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import PreviewTemplate from "./preview";
import { useMutation } from "@apollo/client";
import { UPDATE_TEMPLATE } from "../../../queries/query";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainbuttons: {
    margin: theme.spacing(2),
  },
  captionText: {
    color: "red",
  },
}));

export default function PublishTemplate(props) {
  console.log(props);

  const classes = useStyles();
  const [sweight, setSweight] = React.useState(
    props.type === "evaluate_template" ? 0 : 100
  );

  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    onCompleted: (e) => {
      console.log(e);
    },
  });

  function publishTemplate() {
    var input = { vbeid: props.id, status: "published" };
    updateTemplate({ variables: { input: input } });

    props.setState(false);
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            size="small"
            className={classes.mainbuttons}
            onClick={publishTemplate}
            disabled={sweight !== 100}
          >
            Publish
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            size="small"
            className={classes.mainbuttons}
            onClick={() => {
              props.setState(false);
            }}
          >
            Close
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <PreviewTemplate
            id={props.id}
            type={props.type}
            setSweight={
              props.type === "evaluate_template" ? setSweight : () => {}
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}
