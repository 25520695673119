import React from "react";

import { useStyles } from "./styles";

import {
  Grid,
  List,
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import Confirmation from "./alertConfirmation";

export default function Attachmentslist({
  getAttachments,
  isOwner,
  CreateUrl,
  ExecuteDelete,
  display,
  addNew,
}) {
  const Title = "Are you sure to delete?";

  const classes = useStyles();

  return (
    <Grid
      container
      md={12}
      className={
        addNew === undefined && display === undefined
          ? classes.listAttachmentTextAdjustCreateFlow
          : classes.listAttachmentTextAdjust
      }
    >
      <Grid item>
        <List>
          {getAttachments.map((value) => {
            let attachmentName = value.split("/")[4];

            return (
              <ListItem id={value} className={classes.listAttachmentTextSize}>
                <ListItemIcon>
                  <AttachFileIcon fontSize="small" />
                </ListItemIcon>

                <ListItemText
                  id={value}
                  pathname={value}
                  onClick={CreateUrl}
                  className={classes.listAttachmentText}
                  primary={attachmentName}
                />

                {/* </ListItemText> */}

                {display ? null : (
                  <>
                    {isOwner ? (
                      <ListItemSecondaryAction>
                        <Confirmation
                          pathname={value}
                          Title={Title}
                          deleteMethod={ExecuteDelete}
                        />
                      </ListItemSecondaryAction>
                    ) : null}
                  </>
                )}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
}
