import React from "react";
import { useParams } from "react-router-dom";

import {
  Grid,
  Divider,
  Typography,
  ListItemSecondaryAction,
  ListItemText,
  List,
  ListItemIcon,
  ListItem,
} from "@material-ui/core";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import CustomCkeditor from "../../../shared/customckeditor";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import { theme } from "../../../../theme/theme";
import UploadDoc from "../../../shared/uploaddocument";
import {
  deleteDocument,
  getUsecaseById,
  listDocs,
  postUsecase,
  signed,
} from "./query";

import Load from "../../../shared/skeletons/skeleton";
import EmptyDiv from "../../companies/Company2.0/tabs/lists/emptyDiv";

import { useStyles } from "../../products/product2.0/styles";

export default function UsecaseOverview({ isReadOnly, UpdateUsecCaseCache }) {
  const { id } = useParams();
  const client = useApolloClient();
  const classes = useStyles();

  //list docs
  const { data: docs } = useQuery(listDocs, {
    variables: { input: { vbeid: id, type: "usecase" } },
  });

  ////update cache
  const updateCache = (paramName, e, paramName1, e1, paramName2, e2) => {
    let updatedDocs = {};

    updatedDocs[paramName] = e;
    updatedDocs[paramName1] = e1;
    updatedDocs[paramName2] = e2;

    let updatedCache = [...docs.listsupportDoc];
    updatedCache.unshift({ ...updatedDocs });

    client.writeQuery({
      query: listDocs,
      data: {
        __typename: "project",
        listsupportDoc: updatedCache,
      },

      variables: { input: { vbeid: id, type: "usecase" } },
    });
  };

  const [signedUrl] = useMutation(signed, {
    onCompleted: (e) => {
      window.open(e.signedURLforSupportDoc.signedurl, "_blank");
    },
  });

  const createUrl = (e) => {
    signedUrl({
      variables: {
        input: {
          method: "get",
          key: e.target.parentElement.getAttribute("pathname"),
          vbeid: id,
          type: "usecase",
        },
      },
    });
  };

  //post Requirement
  const [deletedocmutation] = useMutation(deleteDocument, {
    onCompleted: (e) => {
      updateDeleteCache(e);
    },
  });

  const updateDeleteCache = (e) => {
    let updatedCache = [...docs.listsupportDoc];
    updatedCache = updatedCache.filter(
      (document) => document.id !== e.deleteSupportDoc.id
    );

    client.writeQuery({
      query: listDocs,
      data: {
        __typename: "project",
        listsupportDoc: updatedCache,
      },

      variables: { input: { vbeid: id, type: "usecase" } },
    });
  };

  const deleteDoc = (e) => {
    deletedocmutation({
      variables: {
        input: {
          key: e.target.getAttribute("pathname"),
          vbeid: id,
          type: "usecase",
          timestamp: e.target.getAttribute("timestamp"),
        },
      },
    });
  };

  /////update mutation

  const [postUsecaseMutation] = useMutation(postUsecase, {
    onCompleted: (e) => {
      var longDesc = e.updateusecase.longdescription;
      UpdateUsecCaseCache(longDesc);
    },
  });

  ///save Data
  const saveData = (data) => {
    postUsecaseMutation({
      variables: {
        input: {
          longdescription: data,
          vbeid: id,
        },
      },
    });
  };

  const { loading, data: usecaseData } = useQuery(getUsecaseById, {
    variables: { input: { vbeid: id } },
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        {loading ? (
          <div className={classes.loadingDiv}>
            <Load />
          </div>
        ) : usecaseData && usecaseData.usecaseByID.id === id ? (
          usecaseData.usecaseByID.longdescription !== "" &&
          usecaseData.usecaseByID.longdescription !== null ? (
            <CustomCkeditor
              data={usecaseData.usecaseByID.longdescription}
              editorId={id}
              isReadOnly={isReadOnly ? true : false}
              saveMethod={saveData}
            />
          ) : !isReadOnly ? (
            <CustomCkeditor
              data={""}
              editorId={id}
              isReadOnly={isReadOnly ? true : false}
              saveMethod={saveData}
            />
          ) : (
            <EmptyDiv type="description" />
          )
        ) : null}

        <Divider />
      </Grid>

      <Grid container>
        {isReadOnly ? null : (
          <>
            <Grid
              item
              style={{
                paddingTop: theme.spacing(1),
              }}
            >
              <Typography variant="subtitle2" color="primary">
                Upload supporting documents
              </Typography>
            </Grid>

            {/* updateCacheMethod={updateCache} */}

            <Grid item>
              <UploadDoc
                vbeid={id}
                buttonmessage="upload file"
                type="usecase"
                buttontype="icon"
                inputid={id + "usecasedoc"}
                icon={<CloudUploadIcon fontSize="small" />}
                updateCacheMethod={updateCache}
                paramName="id"
                paramName1="name"
                paramName2="signedurl"
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid item md={12}>
        <List style={{ paddingRight: theme.spacing(8) }}>
          {docs
            ? docs.listsupportDoc.map((value) => {
                return (
                  <ListItem key={value.id}>
                    <ListItemIcon>
                      <AttachFileIcon fontSize="small" />
                    </ListItemIcon>

                    <ListItemText
                      pathname={value.signedurl}
                      onClick={createUrl}
                      style={{
                        color: "primary",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {value.name}
                    </ListItemText>

                    {isReadOnly ? null : (
                      <>
                        <ListItemSecondaryAction>
                          <Grid container spacing={2}>
                            <Grid item>
                              <DeleteOutlinedIcon
                                fontSize="small"
                                pathname={value.signedurl}
                                timestamp={value.id}
                                onClick={deleteDoc}
                                style={{
                                  color: "primary",
                                  cursor: "pointer",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </ListItemSecondaryAction>
                      </>
                    )}
                  </ListItem>
                );
              })
            : null}
        </List>
      </Grid>
    </Grid>
  );
}
