import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { CircularProgress, FormControl, Grid } from "@material-ui/core";

import InputField from "./InputField";

import * as yup from "yup";
import { useFormik } from "formik";
import { useStyles } from "./ConfirmActionStyles";

export default function ConfirmAction({
  open,
  action,
  onConfirm,
  onCancel,
  loading,
}) {
  const classes = useStyles();

  const validationSchema = yup.object().shape({
    remarks: yup
      .string("Enter some message")
      .min(2, "Message should be of minimum 2 characters length")
      .required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      remarks: "",
    },
    validationSchema: validationSchema,
  });

  const onClick = () => {
    onConfirm(action, formik.values.remarks);
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {action === "confirm"
            ? "Submit Ratings"
            : action === "evaluate"
            ? "Add To Evaluate"
            : action === "contract"
            ? "Add To Contract"
            : action === "award"
            ? "Add To Award"
            : action === "accept"
            ? "Pursue Opportunity"
            : "Decline Opportunity"}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {action === "confirm" ? (
              "You are submitting your ratings against this project. Once submitted, you cannot change the rating."
            ) : action === "evaluate" ? (
              "You are adding this supplier to evaluate. Once submitted, you cannot revert."
            ) : action === "contract" ? (
              "You are adding this supplier to contract. Once submitted, you cannot revert."
            ) : action === "award" ? (
              "You are adding this supplier to award. Once submitted, you cannot revert."
            ) : action === "accept" ? (
              <span>
                You will be charged a transaction fee of 3% on the order value
                (minus taxes and levies) by vBridge Hub upon client awarding the
                contract to you.
                <br /> Please enter a note of acknowledgement. This will be sent
                as an email to the buyer.
              </span>
            ) : (
              "Please enter a note. This will be sent as an email to the buyer."
            )}
          </DialogContentText>

          {(action === "accept" || action === "decline") && (
            <div className={classes.root}>
              <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.textfields}>
                    <InputField
                      value={formik.values.remarks}
                      onChange={formik.handleChange("remarks")}
                      onBlur={formik.handleBlur("remarks")}
                      placeholder="Enter message *"
                    />
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.remarks && formik.errors.remarks ? (
                    <div
                      className="error-message"
                      style={{ fontSize: "0.8rem" }}
                    >
                      {formik.errors.remarks}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>

          <Button
            onClick={onClick}
            color="primary"
            autoFocus
            variant="contained"
            size="small"
            disabled={
              action === "accept" || action === "decline"
                ? formik.values.remarks === "" || loading
                : loading
            }
          >
            {loading ? <CircularProgress /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
