import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 210;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
    top: "50px",
    backgroundColor: "transparent",
    borderRight: "none",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "baseline",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    minHeight: "30px !important",
    "& button": {
      padding: "5px",
    },
  },
  openDrawerHeader: {
    //marginTop: "60px",
    minWidth: 250,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 2, 2, 1),
    marginTop: "50px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  buttonsDiv: {
    margin: "16px",
  },
  linkButton: {
    color: "#232961",
    margin: "8px 0 8px",
    width: "100%",
    textAlign: "left",
    cursor: "pointer",
    fontWeight: "500",
    "&:hover": {
      color: "#4179BD",
      backgroundColor: "transparent",
    },
    fontSize: "0.9rem",
    textTransform: "capitalize",
    padding: 0,
  },
  imglarge: {
    width: theme.spacing(2),
    height: theme.spacing(2),

    "& img": {
      objectFit: "contain",
    },
  },
}));
