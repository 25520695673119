import * as React from "react";
import { Link } from "react-router-dom";

import { DataGrid } from "@material-ui/data-grid";
import { Paper, Typography, makeStyles } from "@material-ui/core";

import { listConciergerequests } from "./query";

import { useQuery } from "@apollo/client";

import renderCellExpand from "../../../shared/cellexpander";
import PrimaryButton from "../../common/PrimaryButton";
import ConciergeSlider from "../../project3.0/buyer/projectlist/ConciergeSlider";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(5),
  },

  heading: {
    textAlign: "left",
    paddingBottom: theme.spacing(1),
  },
  requesttable: {
    height: 400,
    width: "95%",
  },
}));

export default function ConciergeTable() {
  const classes = useStyles();

  const [conciergeOpen, setConciergeOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");

  const onMessageSupplier = (project) => {
    setSelectedProject(project);
    setConciergeOpen(true);
  };

  const onClose = () => {
    setSelectedProject("");
    setConciergeOpen(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Project Name",
      width: 200,
      renderCell: (params) => (
        <Link to={"/viewconcierge/" + params.id}>{params.value}</Link>
      ),
    },
    {
      field: "description",
      headerName: "Project Description",
      width: 250,
      renderCell: renderCellExpand,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 300,
      renderCell: renderCellExpand,
    },
    {
      field: "timeline",
      headerName: "Project Timeline",
      width: 100,
    },

    {
      field: "createdon",
      headerName: "Requested Date",
      width: 150,
      renderCell: (params) =>
        new Date(parseInt(params.value)).toDateString("en-US").substring(3),
    },

    {
      field: "message",
      headerName: "Message Supplier",
      width: 150,
      renderCell: (params) => (
        <PrimaryButton
          label="Message"
          variant="contained"
          onClick={() => {
            onMessageSupplier(params.row);
          }}
        />
      ),
    },
  ];

  ///List concierge
  const { loading, data } = useQuery(listConciergerequests, {
    variables: { input: {} },
  });

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary" className={classes.heading}>
        My Concierge Requests
      </Typography>

      <Paper className={classes.requesttable}>
        {data ? (
          <DataGrid
            loading={loading}
            rows={loading ? loading : data.listConcierge}
            pageSize={20}
            disableColumnMenu={true}
            disableColumnSelector={true}
            columns={columns.map((column) => ({
              ...column,
              sortable: false,
            }))}
          />
        ) : null}
      </Paper>

      {conciergeOpen && (
        <ConciergeSlider
          open={conciergeOpen}
          onClose={onClose}
          projectVbeid={selectedProject.vbeid}
          project={selectedProject}
        />
      )}
    </div>
  );
}
