import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "./styles";
import PrimaryButton from "../common/PrimaryButton";
import { CircularProgress } from "@material-ui/core";

export default function MapCompanyDailog({
  dopen,
  onClose,
  isUser,
  companyName,
  claimProfile,
  setStepIndexOnClick,
  mapedCompany,
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={dopen}
        // onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {mapedCompany ? (
          <>
            <DialogTitle id="alert-dialog-title" className={classes.title}>
              {companyName +
                "Your company profile is not claimed and completed on the platform. Some of the benefits of a claimed and completed profile include:"}
            </DialogTitle>

            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.dailogcontent}
              >
                {isUser === "seller" ? (
                  <>
                    <li>
                      An updated and rich company profile will greatly enhance
                      our AI algorithm to showcase your company profile when
                      Enterprise buyers are looking for solutions.
                    </li>
                    <li>
                      A claimed profile will allow you to receive and respond to
                      projects raised by Enterprise buyers.
                    </li>
                    <li>Become an Admin of the company profile.</li>
                  </>
                ) : (
                  <>
                    <li>
                      An updated and rich company profile will greatly enhance
                      our AI algorithm to provide recommendations during
                      discovery and sourcing process.
                    </li>
                    <li>Become an Admin of the company profile</li>
                  </>
                )}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <PrimaryButton
                onClick={() => {
                  setStepIndexOnClick(2);
                }}
                label="Skip, Will do later"
                variant="outlined"
              />

              <PrimaryButton
                onClick={claimProfile}
                label="Claim Profile"
                variant="contained"
              />
            </DialogActions>
          </>
        ) : (
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
