import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif !important"].join(
      ","
    ),
    marginTop: theme.spacing(13),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },

  tableContainer: {
    "& td": {
      padding: "2px !important",
    },
  },

  drawerOpenRoot: {
    marginLeft: 250,
    marginTop: theme.spacing(8),
  },

  drawerPaper: {
    width: 640,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },

  table: {
    paddingLeft: theme.spacing(1),
    "& th": {
      fontSize: theme.spacing(1.6),
      textAlign: "center !important",
    },
    "& td": {
      padding: "2px !important",
      fontSize: theme.spacing(1.6),
      textAlign: "center !important",
    },
  },
  menuitem: {
    fontSize: theme.spacing(1.6),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  iconstyle: {
    marginRight: theme.spacing(1.6),
  },
  docicon: {
    marginRight: theme.spacing(2.5),
  },
}));
