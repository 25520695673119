import React, { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import LabelIcon from "@material-ui/icons/Label";

import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import WorkIcon from "@material-ui/icons/Work";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import { useApolloClient } from "@apollo/client";

import UserAvatar from "./shortAvatar";

const useStyles = makeStyles({
  avatar: {
    cursor: "pointer",
  },
  singleAvatar: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  icon: {
    marginRight: "8px",
  },
});

export default function AppBarAvatar({ userInfo, showMenu, mainDrawerClick }) {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const client = useApolloClient();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const { logout } = useAuth0();

  const openProfileMenu = (e) => {
    setOpen(true);
  };

  const closeProfileMenu = (e) => {
    setOpen(false);
  };

  return (
    <>
      <div
        aria-describedby="avatar-popover"
        onClick={openProfileMenu}
        ref={anchorRef}
        className={
          (userInfo?.isProfileUpdated !== "true" &&
            userInfo?.companyvbeid === "") ||
          userInfo?.status === false
            ? classes.singleAvatar
            : classes.avatar
        }
      >
        <UserAvatar
          name={userInfo?.name}
          imagePath={
            userInfo?.imageurl ? userInfo?.imageurl : "/dummy/dummy/dummy"
          }
        />
      </div>

      <Popover
        id="avatar-popover"
        open={open}
        anchorEl={anchorRef.current}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ClickAwayListener onClickAway={closeProfileMenu}>
          <Paper>
            <MenuList>
              {showMenu && (
                <>
                  <MenuItem
                    dense={true}
                    className={classes.menu}
                    onClick={(e) => {
                      mainDrawerClick(e, "close");

                      setOpen(false);

                      history.push({
                        pathname: "/profile",
                        state: { index: 0 },
                      });
                    }}
                  >
                    <PersonIcon className={classes.icon} />
                    Personal Profile
                  </MenuItem>

                  <MenuItem
                    dense={true}
                    className={classes.menu}
                    onClick={(e) => {
                      mainDrawerClick(e, "close");

                      setOpen(false);
                      history.push("/mycompany/" + userInfo.companyvbeid);
                    }}
                  >
                    <BusinessIcon className={classes.icon} />
                    Company Profile
                  </MenuItem>

                  <MenuItem
                    dense={true}
                    className={classes.menu}
                    onClick={(e) => {
                      mainDrawerClick(e, "close");

                      setOpen(false);
                      history.push("/admin");
                    }}
                  >
                    <PeopleIcon className={classes.icon} />
                    Company Colleagues
                  </MenuItem>

                  <MenuItem
                    dense={true}
                    className={classes.menu}
                    onClick={(e) => {
                      mainDrawerClick(e, "close");

                      setOpen(false);
                      history.push("/agreements");
                    }}
                  >
                    <AssignmentTurnedIn className={classes.icon} />
                    Agreement
                  </MenuItem>

                  {userInfo?.role.includes("PROFILE_ADMIN") && (
                    <>
                      <MenuItem
                        dense={true}
                        className={classes.menu}
                        onClick={(e) => {
                          mainDrawerClick(e, "close");

                          setOpen(false);
                          history.push("/legalentity");
                        }}
                      >
                        <CreditCardIcon className={classes.icon} />
                        Legal Entity
                      </MenuItem>

                      <MenuItem
                        dense={true}
                        className={classes.menu}
                        onClick={(e) => {
                          mainDrawerClick(e, "close");

                          setOpen(false);
                          history.push("/rolemanagement");
                        }}
                      >
                        <WorkIcon className={classes.icon} />
                        Role Management
                      </MenuItem>

                      <MenuItem
                        dense={true}
                        className={classes.menu}
                        onClick={(e) => {
                          mainDrawerClick(e, "close");

                          setOpen(false);
                          history.push("/tagmanagement");
                        }}
                      >
                        <LabelIcon className={classes.icon} />
                        Tag Management
                      </MenuItem>

                      <MenuItem
                        dense={true}
                        className={classes.menu}
                        onClick={(e) => {
                          mainDrawerClick(e, "close");

                          setOpen(false);
                          history.push("/paymentterms");
                        }}
                      >
                        <AttachMoneyIcon className={classes.icon} />
                        Payment Terms
                      </MenuItem>

                      <MenuItem
                        dense={true}
                        className={classes.menu}
                        onClick={(e) => {
                          mainDrawerClick(e, "close");

                          setOpen(false);
                          history.push("/documentmanagement");
                        }}
                      >
                        <InsertDriveFileIcon className={classes.icon} />
                        Document Management
                      </MenuItem>
                    </>
                  )}
                </>
              )}

              <MenuItem
                dense={true}
                className={classes.menu}
                onClick={() => {
                  client.clearStore();
                  logout({ returnTo: window.location.origin });
                }}
              >
                <PowerSettingsNewIcon className={classes.icon} />
                Logout
              </MenuItem>
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </>
  );
}
