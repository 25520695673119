import {
  FormControl,
  IconButton,
  InputLabel,
  Tooltip,
} from "@material-ui/core";
import { useStyles } from "./styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SelectField from "../common/SelectField";
import {
  agreeValues,
  booleanValues,
  range1Values,
  range2Values,
  range3Values,
} from "./templateHelper";

export default function SelectPreview({ item }) {
  let itemProps = item?.properties;

  if (!item.properties) {
    itemProps = JSON.parse(item.metadata);
  }

  const { options, custom, label, helpMessage } = itemProps;

  const classes = useStyles();

  let optionValues = [];

  if (options === "boolean") {
    optionValues = booleanValues;
  } else if (options === "agree") {
    optionValues = agreeValues;
  } else if (options === "range1") {
    optionValues = range1Values;
  } else if (options === "range2") {
    optionValues = range2Values;
  } else if (options === "range3") {
    optionValues = range3Values;
  } else {
    let values = custom.split(",");

    values.forEach((option) => {
      optionValues.push({ type: option, value: option });
    });
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <InputLabel style={{ width: "100%" }}>{label || "Select"}</InputLabel>
      <FormControl
        className={classes.formControl}
        variant="outlined"
        fullWidth={true}
      >
        <div className={classes.selectPreview}>
          <SelectField
            label="Select"
            onChange={() => {}}
            value=""
            options={optionValues}
            multiline={false}
            disabled={true}
          />

          {helpMessage !== "" && (
            <Tooltip title={helpMessage} placement="top">
              <IconButton className={classes.helpButton}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </FormControl>
    </div>
  );
}
