import { Avatar, Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router";

import { VBRIDGEHUB_MEDIAURL } from "../../../constants";

import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { useStyles } from "./styles";

const country = require("../../../data/countriesdata.json");

export default function CategoryResultCard({
  productData,
  type,
  onProductCardClick,
  checked,
  onClick,
  evaluateAccess,
  userCompany,
  mainDrawerClick,
}) {
  const classes = useStyles();
  const history = useHistory();

  const onProductSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onClick(productData);
  };

  let setProductCountryName = "";

  country.forEach((name) => {
    if (productData.companylocation === name.countryvbeid) {
      setProductCountryName = name.countryname;
    }
  });

  return (
    <Paper
      elevation={3}
      square={true}
      className={classes.resultCard}
      onClick={onProductCardClick}
    >
      {productData && (
        <>
          {evaluateAccess && (
            <div
              style={{ textAlign: "right", marginBottom: "-4px" }}
              onClick={onProductSelect}
            >
              {checked ? (
                <CheckCircleIcon style={{ fontSize: "1rem" }} />
              ) : (
                <RadioButtonUncheckedIcon style={{ fontSize: "1rem" }} />
              )}
            </div>
          )}

          <div className={classes.resultDiv}>
            <Avatar
              variant="square"
              alt={productData.name}
              src={VBRIDGEHUB_MEDIAURL + "/" + productData.image}
              className={classes.productImage}
            >
              <CancelPresentationIcon />
            </Avatar>

            <div className={classes.productDetails}>
              <Typography
                variant="BUTTON TEXT"
                component="h2"
                className={classes.productName}
                onClick={(e) => {
                  mainDrawerClick(e, "close");

                  history.push("product/" + productData.vbeid);
                }}
              >
                {productData.name}{" "}
              </Typography>

              <Typography
                variant="BUTTON TEXT"
                component="h2"
                className={classes.companyName}
                onClick={(e) => {
                  mainDrawerClick(e, "close");

                  if (userCompany === productData.companyvbeid) {
                    history.push("mycompany/" + productData.companyvbeid);
                  } else history.push("company/" + productData.companyvbeid);
                }}
              >
                <span className={classes.bySubText}> by </span>
                {productData.companyname}
              </Typography>
            </div>
          </div>

          <div className={classes.location}>
            {setProductCountryName
              ? setProductCountryName
              : productData.companylocation
              ? productData.companylocation
              : "-"}
          </div>

          <div className={classes.productDesc}>
            {productData.shortdescription || "-"}
          </div>
        </>
      )}
    </Paper>
  );
}
