export const fieldTypes = [
  { type: "text50", value: "Text 50 characters" },
  { type: "text100", value: "Text 100 characters" },
  { type: "text150", value: "Text 150 characters" },
  { type: "text200", value: "Text 200 characters" },
  { type: "number", value: "Number" },
  { type: "currency", value: "Currency" },
  { type: "dropdown", value: "Options" },
  { type: "attachment", value: "Attachment" },
];

export const optionsTypes = [
  { type: "boolean", value: "True / False" },
  { type: "agree", value: "Yes / No" },
  { type: "range1", value: "Range 1-3" },
  { type: "range2", value: "Range 1-5" },
  { type: "range3", value: "Range 1-10" },
  { type: "custom", value: "Custom" },
];

export const booleanValues = [
  { value: "True", type: "true" },
  { value: "False", type: "false" },
];

export const agreeValues = [
  { value: "Yes", type: "yes" },
  { value: "No", type: "no" },
];

export const range1Values = [
  { value: "1", type: "1" },
  { value: "2", type: "2" },
  { value: "3", type: "3" },
];

export const range2Values = [
  { value: "1", type: "1" },
  { value: "2", type: "2" },
  { value: "3", type: "3" },
  { value: "4", type: "4" },
  { value: "5", type: "5" },
];

export const range3Values = [
  { value: "1", type: "1" },
  { value: "2", type: "2" },
  { value: "3", type: "3" },
  { value: "4", type: "4" },
  { value: "5", type: "5" },
  { value: "6", type: "6" },
  { value: "7", type: "7" },
  { value: "8", type: "8" },
  { value: "9", type: "9" },
  { value: "10", type: "10" },
];

export const getTemplateType = (type) => {
  if (type === "questionnaire") {
    return "Project Screening Questions";
  } else if (type === "response") {
    return "Project Response";
  } else if (type === "request") {
    return "Project Requirement";
  } else {
    return "Due Diligence";
  }
};
