import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import { GET_SECTIONS } from "../../queries/query";
import { Box, FormControl, Grid } from "@material-ui/core";
import RSection from "./evaluationsection";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  main: {
    width: theme.spacing(100),
  },
  formControlclass: {
    width: "100%",
    textAlign: "left",
  },
}));

export default function EvaluationForm(props) {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_SECTIONS, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: props.templateId } },
  });

  return (
    <div className={classes.root}>
      <Grid container className={classes.main}>
        <Grid item xs={12}>
          {data ? (
            <Box>
              <FormControl
                component="fieldset"
                className={classes.formControlclass}
              >
                {data.listSection.map((section, index) => {
                  var meta = JSON.parse(section.metadata);
                  meta = { ...meta, id: index + 1 };

                  return (
                    <RSection
                      productId={props.productId}
                      key={section.id}
                      name={section.name}
                      vbeid={section.id}
                      sid={section.id}
                      projectId={props.projectId}
                      id={index + 1}
                      metadata={meta}
                      preview={true}
                      type={props.type}
                      readOnly={props.readOnly}
                    />
                  );
                })}
              </FormControl>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
