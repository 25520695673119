import React from "react";
import { useParams, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useQuery } from "@apollo/client";

import TabPanel from "./listTabs";
import { listByListId } from "./query";
import ListInfo from "./ListInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(0),
    color: "#0e3046",
    lineHeight: "1.8",
    textAlign: "left",
  },
  backLink: {
    color: theme.palette.secondary.main,
    float: "left",
  },
  projectList: {
    paddingTop: theme.spacing(0),
  },
}));

export default function ListinList() {
  const classes = useStyles();

  let { id } = useParams();

  const { data } = useQuery(listByListId, {
    variables: { input: { vbeid: id } },
  });

  return (
    <div className={classes.root}>
      <Box m={12} className={classes.projectList}>
        <Grid container md={12}>
          <Grid item md={12}>
            <Link to={"/mylists"} variant="body2" className={classes.backLink}>
              <ArrowBackIcon />
            </Link>
          </Grid>
        </Grid>

        <Grid container md={12}>
          <Grid item md={12}>
            {data ? (
              <ListInfo
                name={data.getListByListId.name}
                description={data.getListByListId.description}
              />
            ) : null}
          </Grid>

          <Grid item md={12}>
            <TabPanel />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
