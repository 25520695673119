import * as React from "react";

import { useStyles } from "./styles";

import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

export default function Confirmation({ pathname, Title, deleteMethod }) {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  const handleClickOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function DeleteAttachment(e) {
    deleteMethod(e.target.parentNode.getAttribute("pathname"));
  }

  return (
    <div className={classes.listAttachmentsDeleteIconAlign}>
      <DeleteOutlinedIcon
        fontSize="small"
        onClick={handleClickOpen}
        className={classes.listAttachmentsDeleteIcon}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{Title}</DialogTitle>

        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button pathname={pathname} onClick={DeleteAttachment}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
