import React, { useState } from "react";

import { useParams } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import VbridgeAlert from "../../../../../shared/alert";
import MainList from "../../../../groups2.0/MainList";

import { getPartnersByCompanyId, removePartner } from "../../query";

import { useStyles } from "../../styles";

import AddNewButton from "../../../../products/product2.0/AddNew/AddNewButton";
import AddPartnerForm from "../../shorts/AddPartnerForm";
import AddInvite from "../../shorts/AddInvite";

export default function PartnersList({ isReadOnly, vbeid, isAdmin }) {
  const client = useApolloClient();
  const classes = useStyles();

  const [checked, setChecked] = useState([]);
  //   const [partnerid, setPartnerid] = useState("");
  const [values, setValues] = useState([]);
  const [checkValue, setCheckValue] = useState(false);

  let { id } = useParams();
  if (vbeid) {
    id = vbeid;
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const checkedValues = [];
    // setPartnerid(value.vbeid);

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    newChecked.map((value) => {
      return checkedValues.push(value.vbeid);
    });

    setChecked(newChecked);
    setValues(checkedValues);

    if (currentIndex === -1) {
      setCheckValue(true);
    } else if (currentIndex >= 1) {
      setCheckValue(true);
    } else if (newChecked.length < 1) {
      setCheckValue(false);
    }
  };

  const [
    removePartnerMutation,
    { error: mutationError, data: partnerRemove, loading },
  ] = useMutation(removePartner, {
    onCompleted: (e) => {
      updateDeleteCache(e);
      setCheckValue(false);
    },
  });

  const deletePartner = (e) => {
    // e.preventDefault();

    removePartnerMutation({
      variables: { input: { listvbeid: values } },
    });
  };

  const { data } = useQuery(getPartnersByCompanyId, {
    variables: { input: { vbeid: id } },
  });

  const updateDeleteCache = (e) => {
    let removeIds = [];
    e.removePartner.forEach((o) => {
      removeIds.push(o.id);
    });
    let listPartnerCache = data.partnersByCompanyId.filter(
      (partner) => !removeIds.includes(partner.id)
    );
    client.writeQuery({
      query: getPartnersByCompanyId,
      data: {
        partnersByCompanyId: listPartnerCache,
      },
      variables: { input: { vbeid: id } },
    });
  };

  return (
    <>
      {partnerRemove && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="Deleted Successfully "
        />
      )}

      {mutationError && (
        <VbridgeAlert
          severity="error"
          open={true}
          message="Something went wrong ... "
        />
      )}

      {loading && (
        <VbridgeAlert severity="info" open={true} message="Please wait" />
      )}

      <Grid
        container
        spacing={1}
        justify="flex-end"
        className={classes.buttonsDiv}
      >
        {isReadOnly ? null : (
          <Grid item>
            <AddPartnerForm />
          </Grid>
        )}

        {isReadOnly ? null : (
          <Grid item>
            <AddNewButton
              name="Remove Partner"
              handleClick={deletePartner}
              disabled={checkValue ? false : true}
              icon="remove"
            />
          </Grid>
        )}
      </Grid>

      <MainList
        vbeid={id}
        type="partners"
        tab="company"
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        onCheck={handleToggle}
        checkedValues={values}
      />
    </>
  );
}
