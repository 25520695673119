import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@material-ui/core";
import LoadingResult from "../../../../shared/LoadingResult";
import { VIEW_VENDOR_RESPONSE } from "../../queries/query";
import JDEditor from "../createProject/ServiceProject/JDEditor";

export default function ServiceVendorForm({
  id,
  companyId,
  readOnly,
  projectData,
}) {
  const { data } = useQuery(VIEW_VENDOR_RESPONSE, {
    variables: {
      input: { vbeid: id, companyvbeid: companyId },
      fetchPolicy: "no-cache",
    },
  });

  return (
    <Grid container>
      {data ? (
        <>
          <Grid item xs={12} style={{ margin: "8px 0" }}>
            <Typography>Executive Summary</Typography>

            <div
              style={{
                border: "1px solid #999",
                borderRadius: "5px",
                marginTop: "4px",
              }}
            >
              <JDEditor
                data={data.viewReposneByVendor.exsummary}
                editorId="summaryId"
                isReadOnly={true}
                saveMethod={() => {}}
                edit={false}
              />
            </div>
          </Grid>

          <Grid item xs={12} style={{ margin: "8px 0" }}>
            <Typography>Scope Understanding</Typography>

            <div
              style={{
                border: "1px solid #999",
                borderRadius: "5px",
                marginTop: "4px",
              }}
            >
              <JDEditor
                data={data.viewReposneByVendor.scopeunderstanding}
                editorId="scopeId"
                isReadOnly={true}
                saveMethod={() => {}}
                edit={false}
              />
            </div>
          </Grid>
        </>
      ) : (
        <LoadingResult />
      )}
    </Grid>
  );
}
