import { gql } from "@apollo/client";

//signed url

//list documents
export const listDocs = gql`
  query docs($input: projectinput!) {
    listsupportDoc(input: $input) {
      id
      name
      path
      createdby
    }
  }
`;

/////delete doc mutation
export const deletedoc = gql`
  mutation update($input: projectinput!) {
    deleteSupportDoc(input: $input) {
      id
    }
  }
`;

///create legal entity

export const CreateLegalEntity = gql`
  mutation createentity($input: projectinputV1!) {
    createLegalEntity(input: $input) {
      legalentity {
        legalentityname
        companyname
        address {
          address
          optionaladdress
          country
          state
          city
          postalcode
        }
        pangst {
          pannumber
          panattachments
          gstnumber
          gstattachments
        }
      }
      banking {
        country
        currency
        bankname
        accountnumber
        routingnumber
        ifsccode
        attachments
      }
      accountingcontactinfo {
        contactname
        contactemail
        contacttitle
      }
      legalcontactinfo {
        contactname
        contactemail
        contacttitle
      }
    }
  }
`;

// update legal entity

export const updateEditLegalEntity = gql`
  mutation createentity($input: projectinputV1!) {
    updateLegalEntity(input: $input) {
      responsestatus
    }
  }
`;

//get legal entity

export const listLegalEntity = gql`
  query docs {
    getLegalEntity(input: {}) {
      createdon
      status
      achieved
      legalentity {
        legalentityname
        companyname
        address {
          address
          optionaladdress
          country
          state
          city
          postalcode
        }
        pangst {
          pannumber
          panattachments
          gstnumber
          gstattachments
        }
      }
      banking {
        country
        currency
        bankname
        accountnumber
        routingnumber
        ifsccode
        attachments
      }
      accountingcontactinfo {
        contactname
        contactemail
        contacttitle
      }
      legalcontactinfo {
        contactname
        contactemail
        contacttitle
      }
    }
  }
`;

//signed

export const signed = gql`
  mutation upload($input: s3input!) {
    putSignedURL(input: $input) {
      signedurl
      key
    }
  }
`;

//get signed url

export const getSigned = gql`
  query files($input: s3input!) {
    getSignedURL(input: $input) {
      signedurl
    }
  }
`;
