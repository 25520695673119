import * as React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from "@material-ui/core/styles";
import {Tabs, Tab, Typography, Box} from '@material-ui/core';

import Response from './addProposal';
import ListQuestions from './listQuestionare';
//import ViewSubmittedRequirement from '../project/viewSubmittedRequirement';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box  p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    tabs: {
      fontSize: "0.6rem",
    },
  }));

export default function ResponseViewTabs({listdata, longtext, 
    productid, approved, submittedRequirement}) {
 
  const [value, setValue] = React.useState(0);
  
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function UpdateValue(){
    setValue(1);
  }

  return (
    <div className={classes.root}>
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="simple tabs example"
      indicatorColor="primary"
      variant="scrollable"
      scrollButtons="auto"
        >
          <Tab className={classes.tabs} label="Screening Questions" {...a11yProps(0)} />

          <Tab className={classes.tabs} label="Response Description" {...a11yProps(1)} />
          {/* <Tab className={classes.tabs} label="View Submitted Proposals" {...a11yProps(1)} /> */}

        </Tabs>
    

      

      <TabPanel value={value} index={0}>
      <ListQuestions changeTab={UpdateValue} productid={productid} approved={approved}/>
      </TabPanel>

      <TabPanel value={value} index={1}>
      <Response listdata={listdata} productid={productid}
    longtext={longtext} lead={true} submittedRequirement={submittedRequirement}/>
    
    </TabPanel>

      {/* <TabPanel value={value} index={2}>
      <ViewSubmittedRequirement lead={true} submittedRequirement={submittedRequirement}/>

      </TabPanel> */}
    
    </div>
  );
}
