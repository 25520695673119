import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import { gql, useQuery } from "@apollo/client";
import { Grid, Divider, ClickAwayListener } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Skeleton } from "@material-ui/lab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import HelpMenu from "../entities/Help/HelpMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: "24px",
  },

  icon: {
    color: "white",
    cursor: "pointer",
  },

  icon2: {
    color: "primary",
    marginRight: theme.spacing(1),
  },

  appBar: {
    backgroundColor: "white",
  },

  notificationList: {
    width: theme.spacing(40),
  },
  notificationsBadge: {
    "& .MuiBadge-badge": {
      backgroundColor: "#e80c0f",
    },
    alignItems: "center",
  },
  noNotificationText: {
    margin: theme.spacing(2),
    textAlign: "center",
    fontStyle: "italic",
    color: "#999",
  },
}));

///list information
const listNotifications = gql`
  query information($input: discussioninput!) {
    getNotificationByUser(input: $input) {
      id
      subject
      path
      projectvbeid
    }
  }
`;

///count information
const countInfo = gql`
  query info {
    getNotificationSummaryByUser {
      count
      id
    }
  }
`;

const Content = () => {
  const history = useHistory();
  const classes = useStyles();

  const { loading, data } = useQuery(listNotifications, {
    variables: { input: {} },
  });

  if (loading) {
    return (
      <Box p={1} className={classes.mainForumBox}>
        {Array(3)
          .fill(0)
          .map((value) => {
            return (
              <Box p={1} style={{ marginBottom: "2rem" }}>
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item md={1}>
                    <Skeleton variant="rect" height={50} />
                  </Grid>

                  <Grid item md={11}>
                    <Skeleton variant="rect" height={100} />
                    <Divider />
                    <Skeleton variant="rect" height={40} />
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </Box>
    );
  }

  return (
    <>
      {data ? (
        data.getNotificationByUser.length !== 0 ? (
          <List className={classes.root}>
            {data.getNotificationByUser.map((value) => {
              return (
                <>
                  <ListItem
                    key={value.id}
                    role={undefined}
                    dense
                    divider
                    button
                    onClick={() => {
                      history.push(value.path);
                    }}
                  >
                    <ListItemText
                      primary={value.subject}
                      secondary={
                        <Typography variant="caption" color="secondary">
                          {new Date(parseInt(value.id) * 1000).toDateString(
                            "en-US"
                          )}
                        </Typography>
                      }
                    />
                  </ListItem>
                </>
              );
            })}
          </List>
        ) : (
          <div className={classes.noNotificationText}>
            No Notifications to display
          </div>
        )
      ) : null}
    </>
  );
};

export default function Notifications() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function openNotifications(e) {
    setOpen(true);
  }

  function closeNotifications(e) {
    setOpen(false);
  }

  const { data: count } = useQuery(countInfo, { variables: { input: {} } });

  return (
    <>
      <div className={classes.root}>
        <Badge
          badgeContent={count ? count.getNotificationSummaryByUser.count : null}
          className={classes.notificationsBadge}
        >
          <HelpMenu />

          <NotificationsIcon
            color="primary"
            className={classes.icon}
            onClick={openNotifications}
          />
        </Badge>
      </div>

      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => {}}
        onOpen={() => {}}
      >
        <ClickAwayListener onClickAway={closeNotifications}>
          <div className={classes.notificationList}>
            <AppBar position="static" className={classes.appBar}>
              <Toolbar variant="dense">
                <NotificationsIcon color="primary" className={classes.icon2} />

                <Typography variant="button" display="block" color="primary">
                  Notifications
                </Typography>
              </Toolbar>
            </AppBar>

            <Content />
          </div>
        </ClickAwayListener>
      </SwipeableDrawer>
    </>
  );
}
