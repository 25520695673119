export const workLocationValues = [
  {
    type: "office",
    value: "In Office",
    label: "Where contract resources work in your or client location",
  },
  {
    type: "remote",
    value: "Remote",
    label: "Where contract resources work remote",
  },
  {
    type: "hybrid",
    value: "Hybrid",
    label: "Where contract resources work in office or remote",
  },
];

export const workExperienceValues = [
  { type: "0-2 Yrs", value: "0-2 Yrs" },
  { type: "2–5 Yrs", value: "2–5 Yrs" },
  { type: "5–7 Yrs", value: "5–7 Yrs" },
  { type: "7–10 Yrs", value: "7–10 Yrs" },
  { type: "10–15 Yrs", value: "10–15 Yrs" },
  { type: "20+ Years ", value: "20+ Years " },
];
