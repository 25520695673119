import { Grid } from "@material-ui/core";

import ResultLoading from "./ResultLoading";

export default function CategoryResultLoading() {
  return (
    <Grid container spacing={2}>
      <Grid item md={3}>
        <ResultLoading />
      </Grid>

      <Grid item md={3}>
        <ResultLoading />
      </Grid>

      <Grid item md={3}>
        <ResultLoading />
      </Grid>

      <Grid item md={3}>
        <ResultLoading />
      </Grid>
    </Grid>
  );
}
