import * as React from "react";

import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableContainer } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Chip } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { Grid } from "@material-ui/core";

import { GET_LEADS } from "../../queries/query";

import { useHistory } from "react-router-dom";

import { VBRIDGEHUB_MEDIAURL } from "../../../../../constants";
import { useStyles } from "./styles";

function getStatus(status) {
  let statusText = status[0];
  if (statusText === "INVITEDFORDISCUSSION") {
    statusText = status[1];
  }

  let returnStatus = statusText;

  if (statusText === "INVITED") {
    returnStatus = "Invited";
  }

  if (statusText === "APPROVED") {
    returnStatus = "Approved";
  }

  if (statusText === "REJECTED") {
    returnStatus = "Rejected";
  }

  if (statusText === "SUBMITTED") {
    returnStatus = "Submitted";
  }

  if (statusText === "AWARDED") {
    returnStatus = "Awarded";
  }

  if (
    statusText === "ADD_TO_AWARD" ||
    statusText === "ADD_TO_CONTRACT" ||
    statusText === "ADD_TO_EVALUATE"
  ) {
    returnStatus = "Shortlisted";
  }

  return returnStatus;
}

const ProjectMenu = ({ vbeid, productid, product }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const history = useHistory();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          key="View"
          onClick={() => {
            history.push(
              "/project-response/requirements/" + vbeid + "/" + productid
            );
          }}
        >
          {product.role.includes("SUBMITTED") ? "View" : "View and Submit"}
        </MenuItem>

        <MenuItem
          key="View"
          onClick={() => {
            history.push(
              "/project-response/messaging/" + vbeid + "/" + productid
            );
          }}
        >
          Message Client
        </MenuItem>
      </Menu>
    </div>
  );
};

export default function VendorProjects() {
  const classes = useStyles();
  const history = useHistory();

  const { loading, data } = useQuery(GET_LEADS);

  const onProjectClick = (project) => {
    history.push(
      "/project-response/requirements/" +
        project.vbeid +
        "/" +
        project.productvbeid
    );
  };

  return (
    <Box m={10}>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        className={classes.heading}
      >
        My Requests {">"} Dashboard
      </Typography>

      <TableContainer component={Paper}>
        <Table size="small" sx={{ minWidth: 650 }} aria-label="project leads">
          <TableHead>
            <TableRow>
              <TableCell align="left">CUSTOMER NAME</TableCell>
              <TableCell align="left">PROJECT NAME</TableCell>
              <TableCell align="left">PRODUCT CHOSEN</TableCell>
              <TableCell align="left">RECEIVED DATE</TableCell>
              <TableCell align="left">STATUS</TableCell>
              <TableCell align="left">ACTIONS</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data ? (
              <>
                {data.listProjectbyCompanyIDV1.map((row) => (
                  <TableRow
                    key={row.productvbeid + "-" + row.vbeid}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Avatar
                            alt="company logo"
                            variant="square"
                            className={classes.imgsmall}
                            src={VBRIDGEHUB_MEDIAURL + "/" + row.image}
                          />
                        </Grid>

                        <Grid item xs={8}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                          >
                            {row.companyname}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell align="left" component="th" scope="row">
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        onClick={() => {
                          onProjectClick(row);
                        }}
                        className={classes.tableProjectName}
                      >
                        {row.name}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" component="th" scope="row">
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        {row.productname}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" component="th" scope="row">
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        {new Date(parseInt(row.createdon))
                          .toLocaleDateString("en-US", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                          .toString()}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" component="th" scope="row">
                      <Chip
                        align="justify"
                        label={getStatus(row.role)}
                        size="small"
                        color="primary"
                        variant="outlined"
                      />
                    </TableCell>

                    <TableCell align="left" component="th" scope="row">
                      <ProjectMenu
                        vbeid={row.vbeid}
                        productid={row.productvbeid}
                        product={row}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableHead>
                <TableRow>
                  <TableCell colSpan={6}>
                    {loading ? "Please wait ..." : "No records found"}
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
