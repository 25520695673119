import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";

import { BootstrapInput } from "../../../../../theme/theme";

import { useFormik } from "formik";
import * as yup from "yup";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import SimpleSnackbar from "./snackbar";
import SimpleErrorSnackbar from "./errorsnackbar";

import CompanySearch from "../../../../shared/companySearch";
import {
  getAwardsByCompanyId,
  postAward,
  addAwardv1,
  addExistingAward,
  mapAwardTocompanyV1,
  mapNewAward,
} from "../query";
import AddNewButton from "../../../products/product2.0/AddNew/AddNewButton";

import { useStyles } from "./styles.js";
import SearchAward from "./SearchAward";
import { FormHelperText } from "@material-ui/core";
import InputField from "../../../common/InputField";
import PrimaryButton from "../../../common/PrimaryButton";

export default function AddAwardForm({ vbeid }) {
  const classes = useStyles();
  const client = useApolloClient();
  const timer = useRef();

  const [open, setOpen] = useState(false);
  const [awardCompany, setAwardsComapny] = useState(false);
  const [awardStatus, setAwardStatus] = useState(false);
  const [awardName, setAwardName] = useState("");

  //   const [loading, setLoading] = useState(false);

  let { id } = useParams();
  if (vbeid) {
    id = vbeid;
  }

  const [awardvbeid, setAwardVbeid] = useState("");

  const sentAwardVbeid = (awardId) => {
    setAwardVbeid(awardId);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!mutationLoading) {
      //   setLoading(true);

      timer.current = window.setTimeout(() => {
        // setLoading(false);
        handleClose();
      }, 500);
    }
  };

  const handleClickOpen = () => {
    setAwardStatus(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter your Award Name")
      .min(2, "Award Name should be of minimum 2 characters length")
      .required("Award Name is required"),

    awarderurl: yup
      .string("Enter your Awarder URL")
      .required("Awarder URL is required")
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        "Enter valid Awarder URL"
      ),

    awardyear: yup
      .string("Enter year")
      .required("Award Year is required")
      .matches(/^\d*\.?\d*$/, "Only numbers!"),

    year: yup
      .string("Enter year")
      .required("Awarder Year is required")
      .matches(/^\d*\.?\d*$/, "Only numbers!"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      awarderurl: "",
      year: "",
      companyid: "",
      awardyear: "",
    },
    validationSchema: validationSchema,
  });

  //Add New Award

  const [
    postAwardMutation,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(mapNewAward, {
    onCompleted: (response) => {
      updateAwardInformationCache(response.createAwardAndMapToCompanyV1);
    },
  });

  const createAwardShort = (e) => {
    e.preventDefault();

    postAwardMutation({
      variables: {
        input: {
          name: formik.values.name,
          //awarderurl: formik.values.awarderurl,
          year: formik.values.year,
          companyvbeid: id,
        },
      },
    });

    handleButtonClick();
  };

  const [mapExistingaward, { loading: awardload }] = useMutation(
    mapAwardTocompanyV1,
    {
      onCompleted: (response) => {
        updateAwardInformationCache(response.mapAwardTocompanyV1);
      },

      onError: (error) => {
        console.log("error", error);
      },
    }
  );

  const mapExistingAwardName = (e) => {
    e.preventDefault();

    mapExistingaward({
      variables: {
        input: {
          vbeid: awardvbeid,
          year: formik.values.awardyear,
          companyvbeid: id,
        },
      },
    });

    handleButtonClick();
  };

  ///update cache
  useQuery(getAwardsByCompanyId, {
    variables: { input: { companyid: id } },
  });

  const updateAwardInformationCache = (response) => {
    const { listAwardsByCompanyIDV1 } = client.readQuery({
      query: getAwardsByCompanyId,
      variables: { input: { companyvbeid: id } },
    });

    let listAwardsInformationCache = [response, ...listAwardsByCompanyIDV1];

    client.writeQuery({
      query: getAwardsByCompanyId,
      data: {
        listAwardsByCompanyIDV1: {
          __typename: "generic",
          listAwardsByCompanyIDV1: listAwardsInformationCache,
        },
      },
      variables: { input: { companyvbeid: id } },
    });
  };

  const setName = (text) => {
    console.log("text", text);
    if (text === "Didn't find award? Add award below manually.") {
      setAwardStatus(true);
    } else {
      setAwardName(text);
    }
  };

  const setDomain = (text) => {
    let url = "";
    if (text === "https://unavailable") {
      url = "https://";
    } else {
      url = text;
    }
    formik.values.awarderurl = url;
  };

  const setCompany = (value) => {
    setAwardsComapny(value);
  };

  const addNewAward = () => {
    setAwardStatus(true);
  };

  return (
    <div>
      {mutationError && <SimpleErrorSnackbar />}
      {data && <SimpleSnackbar type="success" />}
      {mutationLoading || awardload ? <SimpleSnackbar type="loading" /> : null}

      <AddNewButton name="Add Award" handleClick={handleClickOpen} />

      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Award</DialogTitle>

          <DialogContent dividers>
            <DialogContentText>
              Please enter Award details in the following fields.
            </DialogContentText>

            <div className={classes.root}>
              {!awardStatus ? (
                <>
                  <Grid container style={{ width: "100%" }}>
                    <Grid item md={10}>
                      <FormControl className={classes.textfields}>
                        <InputLabel
                          required={true}
                          shrink
                          htmlFor="searchAward"
                          focused={true}
                        >
                          Search Award
                        </InputLabel>

                        <SearchAward
                          id="searchAward"
                          setName={setName}
                          setDomain={setDomain}
                          setCompany={setCompany}
                          sentAwardVbeid={sentAwardVbeid}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container style={{ width: "100%" }}>
                    <Grid item md={10}>
                      <FormControl className={classes.textfields}>
                        <InputField
                          value={formik.values.awardyear}
                          onChange={formik.handleChange("awardyear")}
                          onBlur={formik.handleBlur("awardyear")}
                          placeholder="Awarded Year"
                          disabled={awardStatus ? true : false}
                          type="number"
                          label="Awarded Year"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item className={classes.awardyear}>
                      {formik.touched.awardyear &&
                      formik.errors.nawardyearame ? (
                        <div className="error-message">
                          {formik.errors.awardyear}
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>

                  <FormHelperText
                    margin="dense"
                    className={classes.noUrlText}
                    onClick={addNewAward}
                  >
                    {awardStatus
                      ? "Didn't find award? Click here to add an award manually."
                      : "Didn't find award? Click here to add an award manually."}
                  </FormHelperText>
                </>
              ) : null}

              {awardStatus ? (
                <>
                  <Grid container style={{ width: "100%" }}>
                    <Grid item md={10}>
                      <FormControl className={classes.textfields}>
                        <InputField
                          label="Award Name"
                          value={formik.values.name}
                          onChange={formik.handleChange("name")}
                          onBlur={formik.handleBlur("name")}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item className={classes.errorfields}>
                      {formik.touched.name && formik.errors.name ? (
                        <div className="error-message">
                          {formik.errors.name}
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container style={{ width: "100%" }}>
                    <Grid item md={10}>
                      <FormControl className={classes.textfields}>
                        <InputLabel
                          required={true}
                          shrink
                          htmlFor="my-input1"
                          focused={true}
                        >
                          Search Awarder
                        </InputLabel>

                        <CompanySearch
                          id="company-name-search"
                          setName={setName}
                          setDomain={setDomain}
                          setCompany={setCompany}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container style={{ width: "100%" }}>
                    <Grid item md={10}>
                      <FormControl className={classes.textfields}>
                        <InputLabel
                          required={true}
                          shrink
                          htmlFor="my-input2"
                          focused={true}
                        >
                          Awarder URL
                        </InputLabel>

                        <BootstrapInput
                          disabled={true}
                          required={true}
                          id="my-input2"
                          aria-describedby="my-helper-text"
                          value={formik.values.awarderurl}
                          onChange={formik.handleChange("awarderurl")}
                          onBlur={formik.handleBlur("awarderurl")}
                          placeholder="https://"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item className={classes.errorfields}>
                      {formik.touched.awarderurl && formik.errors.awarderurl ? (
                        <div className="error-message">
                          {formik.errors.awarderurl}
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container style={{ width: "100%" }}>
                    <Grid item md={10}>
                      <FormControl className={classes.textfields}>
                        <InputField
                          value={formik.values.year}
                          onChange={formik.handleChange("year")}
                          onBlur={formik.handleBlur("year")}
                          placeholder="Awarded Year"
                          type="number"
                          required={true}
                          label="Awarded Year"
                          min="1700"
                          max="2099"
                        />

                        {/* <InputLabel
                          required={true}
                          shrink
                          htmlFor="my-input3"
                          focused={true}
                        >
                          Awarded Year
                        </InputLabel>

                        <BootstrapInput
                          type="number"
                          min="1700"
                          max="2099"
                          required={true}
                          id="my-input3"
                          aria-describedby="my-helper-text"
                          value={formik.values.year}
                          onChange={formik.handleChange("year")}
                          onBlur={formik.handleBlur("year")}
                        /> */}
                      </FormControl>
                    </Grid>

                    <Grid item className={classes.errorfields}>
                      {formik.touched.year && formik.errors.year ? (
                        <div className="error-message">
                          {formik.errors.year}
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </div>
          </DialogContent>

          <DialogActions>
            <PrimaryButton
              label="Cancel"
              onClick={handleClose}
              variant="outlined"
            />

            <PrimaryButton
              label="Add"
              onClick={awardStatus ? createAwardShort : mapExistingAwardName}
              variant="contained"
              disabled={
                !awardStatus
                  ? awardName.length === 0 ||
                    formik.values.awardyear.length === 0
                    ? true
                    : false
                  : formik.values.year.length === 0 ||
                    formik.values.awarderurl.length === 0 ||
                    formik.values.name.length === 0
                  ? true
                  : false
              }

              // disabled={!(formik.isValid && formik.dirty)}
            />

            {mutationLoading || awardload ? (
              <CircularProgress size={24} className={classes.buttonProgress} />
            ) : null}
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
