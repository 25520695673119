import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  square: {
    width: 50,
    height: 50,
  },

  square1: {
    width: 400,
    height: 50,
  },

  ngrid: {
    marginTop: "0%",
  },
}));

export default function ProjectVendors() {
  const classes = useStyles();

  return (
    <>
      <List>
        {[0, 1, 2, 3, 4].map((value) => {
          return (
            <ListItem>
              <Grid container md={12}>
                <Grid item xs={2}>
                  <Skeleton variant="react" className={classes.square} />
                </Grid>

                <Grid item xs={10}>
                  <Skeleton variant="rect" className={classes.square1} />
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
