import { gql } from "@apollo/client";

export const productsByProjectId = gql`
  query productsinformation($input: projectinput!) {
    getProductsByProjectID(input: $input) {
      vbeid
      image
      description
      productname
      productvbeid
      companyvbeid
      companyname

      id
      role {
        vbeid
        staus
        remarks
        createdby
        createdon
      }
      productdescription
    }
  }
`;

export const listQuestionByProjectProduct = gql`
  query listQuestions($input: discussioninput!) {
    listQuestionByProjectProduct(input: $input) {
      id
      question
      totalreplies
      usercompanyid
      createdby
    }
  }
`;

export const getCompanyById = gql`
  query companyinfo($input: id!) {
    queryCompanybyid(input: $input) {
      name
      shortdescription
      vbeid
      image
      companyurl
    }
  }
`;

export const getUserById = gql`
  query userinfo($input: personinput!) {
    getPersonByVBEID(input: $input) {
      name
    }
  }
`;

export const listReplyByQuestion = gql`
  query listReplies($input: discussioninput!) {
    listReplyByQuestion(input: $input) {
      id
      reply
      usercompanyid
      createdby
    }
  }
`;

export const addReply = gql`
  mutation replyMutation($input: discussioninput!) {
    addReply(input: $input) {
      id
      reply
      usercompanyid
      createdby
    }
  }
`;

//send request to vendor
export const postrequest = gql`
  mutation requestfordiscussion($input: projectinput!) {
    requestToProductVendorDiscussion(input: $input) {
      id
    }
  }
`;
