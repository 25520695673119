import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import * as React from "react";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { useFormik } from "formik";
import { useMutation } from "@apollo/client";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Button, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import PropTypes from "prop-types";

import { customAlphabet } from "nanoid/non-secure";
import { SIGNED_URL, UPDATE_FIXED_COST } from "../../queries/query";
import { useState } from "react";

const axios = require("axios");
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz-", 10);

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  skillcol: {
    // width: theme.spacing(25),
    // width: "20%",
  },
  numbercol: {
    // width: theme.spacing(15),
    // width: "10%",
  },
  input: {
    display: "none",
  },
  download: {
    fontSize: theme.spacing(1.2),
    textTransform: "none",
    "& .MuiButton-label": {
      width: "130px",
      height: "18px",
      overflow: "hidden",
      alignItems: "flex-start",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      justifyContent: "flex-start",
    },
  },
  deleteFile: {
    fontSize: theme.spacing(1.5),
  },
  mainText: {
    fontWeight: "bold",
  },
  table: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px",
    },
  },
}));

const DATA = [];

function MileStone(props) {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: props.data,
    onSubmit: (values) => {
      props.changeItem(props.data.id, values);
    },
  });

  React.useEffect(() => {
    if (formik.values !== formik.initialValues) {
      formik.handleSubmit();
    }
  }, [formik.values]);

  return (
    <TableRow>
      <TableCell
        align={props.readOnly ? "left" : "right"}
        className={classes.skillcol}
      >
        {/* {props.readOnly ? (
          <Typography variant="caption" className={classes.mainText}>
            {formik.values.milestone}
          </Typography>
        ) : ( */}
        <TextField
          autoComplete="off"
          size="small"
          fullWidth
          variant="outlined"
          name="milestone"
          onChange={formik.handleChange}
          value={formik.values.milestone}
          placeholder="Milestone"
          disabled={props.readOnly}
        />
        {/* )} */}
      </TableCell>

      <TableCell align={"right"} className={classes.skillcol}>
        {/* {props.readOnly ? (
          <Typography variant="caption">
            {new Date(formik.values.date)
              .toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })
              .toString()}
          </Typography>
        ) : ( */}

        <TextField
          autoComplete="off"
          size="small"
          type="date"
          fullWidth
          variant="outlined"
          name="date"
          onChange={formik.handleChange}
          value={formik.values.date}
          disabled={props.readOnly}
        />
        {/* )} */}
      </TableCell>

      <TableCell align={"right"} className={classes.numbercol}>
        {/* {props.readOnly ? (
          <Typography variant="caption">
            {formik.values.price.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Typography>
        ) : ( */}
        <TextField
          autoComplete="off"
          size="small"
          type="number"
          fullWidth
          variant="outlined"
          name="price"
          onChange={formik.handleChange}
          value={formik.values.price}
          placeholder="USD"
          disabled={props.readOnly}
        />
        {/* )} */}
      </TableCell>

      <TableCell align={"right"} className={classes.skillcol}>
        {/* {props.readOnly ? (
          <Typography variant="caption">{formik.values.remarks}</Typography>
        ) : ( */}
        <TextField
          autoComplete="off"
          size="small"
          fullWidth
          variant="outlined"
          name="remarks"
          onChange={formik.handleChange}
          value={formik.values.remarks}
          placeholder="remarks"
          disabled={props.readOnly}
        />
        {/* )} */}
      </TableCell>

      <TableCell align="right">
        {/* {props.readOnly ? null : ( */}
        <Grid container>
          <Grid item xs={6}>
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                props.addItem(formik.values.id);
              }}
              disabled={props.readOnly}
            >
              <AddIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                props.removeItem(formik.values.id);
              }}
              disabled={props.readOnly}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
        </Grid>
        {/* )} */}
      </TableCell>
    </TableRow>
  );
}

function UploadFile(props) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={8}>
        <input
          className={classes.input}
          id={"milestonefileinput"}
          multiple
          type="file"
          onChange={(e) => {
            props.uploadFile(e);
          }}
          disabled={props.readOnly}
        />

        <label htmlFor={"milestonefileinput"}>
          <Button
            variant="contained"
            size="small"
            component="span"
            startIcon={<CloudUploadIcon />}
            disabled={props.readOnly}
          >
            Upload
          </Button>
        </label>
      </Grid>

      <Grid item xs={4}>
        {props.upload ? (
          <CircularProgressWithLabel
            color="secondary"
            value={props.uploadPer}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}

const DownLoadFile = (props) => {
  const classes = useStyles();

  const [dsignedUrl, { data: dsdata, loading: dsloading, error: dserror }] =
    useMutation(SIGNED_URL, {
      onCompleted: (e) => {
        console.log(e);

        let url = e.gettemplateSignedURL.signedurl;
        window.open(url);
      },
      onError: (err) => {
        console.log(err);
      },
    });

  const deleteFile = () => {
    props.setUpload(false);
    props.setUploadPer(2);
    props.setShowupload(true);
    props.setFileName(null);
  };

  function downLoadFile() {
    dsignedUrl({
      variables: {
        input: {
          projectid: props.projectid,
          productid: props.productvbeid,
          attachmentfilename: props.name,
          method: "get",
        },
      },
    });
  }

  return (
    <Grid container>
      <Grid item xs={11}>
        <Button
          onClick={downLoadFile}
          color="secondary"
          size="small"
          component="span"
          className={classes.download}
          startIcon={<AttachFileIcon fontSize="small" />}
        >
          {props.name}
        </Button>
      </Grid>

      {props.readOnly ? null : (
        <Grid item xs={1}>
          <IconButton onClick={deleteFile}>
            <CloseIcon
              color="secondary"
              className={classes.deleteFile}
              fontSize="small"
            />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default function MileStonePrice(props) {
  const classes = useStyles();

  const [date, setDate] = useState(props.data.date);
  const [data, setData] = useState(props.data.data);
  //   const [total, setTotal] = React.useState(0);
  const [fileName, setFileName] = useState(props.data.fileName);
  const [filePath, setFilePath] = useState(null);
  const [upload, setUpload] = useState(false);
  const [uploadPer, setUploadPer] = useState(2);
  const [showUpload, setShowupload] = useState(
    props.data.fileName ? false : true
  );

  const [updateItem, { data: udata, loading: uloading, error: uerror }] =
    useMutation(UPDATE_FIXED_COST, {
      onCompleted: (e) => {
        console.log(e);
      },
      onError: (err) => {
        console.log(err);
      },
    });

  const [signedUrl, { data: sdata, loading: sloading, error: serror }] =
    useMutation(SIGNED_URL, {
      onCompleted: (e) => {
        let url = e.gettemplateSignedURL.signedurl;
        let attachment = e.gettemplateSignedURL.attachment;
        setFilePath(attachment);
        putFile(url);
      },
      onError: (err) => {
        console.log(err);
      },
    });

  const uploadFile = (e) => {
    setUpload(true);

    var tfile = e.target.files[0];
    let name = tfile.name;

    setFileName(name);

    signedUrl({
      variables: {
        input: {
          projectid: props.projectid,
          productid: props.productvbeid,
          attachmentfilename: name,
          method: "put",
        },
      },
    });
  };

  async function putFile(url) {
    await axios
      .request({
        method: "put",
        url: url,
        data: document.getElementById("milestonefileinput").files[0],
        onUploadProgress: (p) => {
          const percent = Math.round((p.loaded / p.total) * 100);
          setUploadPer(percent);
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setShowupload(false);
          setUpload(false);
        }
      });
  }

  const addItem = (id) => {
    const nano_id = nanoid();
    let values = {
      id: nano_id,
      milestone: "",
      date: "",
      price: 0,
      remarks: "",
    };

    if (id !== null) {
      var fdata = data.filter((item) => item.id === id);
      var cdata = [...data];
      var index = data.indexOf(fdata[0]);
      cdata.splice(index + 1, 0, values);
      setData(cdata);
      return 0;
    }
    setData([...data, values]);
  };

  if (data.length === 0) {
    addItem();
  }

  const reducer = (previousValue, currentValue) =>
    previousValue + currentValue.price;

  const changeItem = (id, values) => {
    var fdata = data.filter((item) => item.id === id);
    var cdata = [...data];
    var index = data.indexOf(fdata[0]);
    cdata[index] = values;
    setData(cdata);
  };

  const removeItem = (id) => {
    if (data.length > 1) {
      var fdata = data.filter((item) => item.id !== id);
      setData(fdata);
    }
  };

  const saveData = () => {
    var values = { fileName: fileName, data: data, date: date };

    var tempdata = JSON.stringify(values);
    updateItem({
      variables: {
        input: [
          {
            productvbeid: props.productvbeid,
            projectid: props.projectid,
            data: tempdata,
          },
        ],
      },
    });
  };

  return (
    <Box m={1}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Time Money" size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="left">
                Project Start Date
              </TableCell>

              <TableCell colSpan={3} align="left">
                {props.readOnly ? (
                  <Typography className={classes.mainText} variant="caption">
                    {new Date(date)
                      .toLocaleDateString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                      .toString()}
                  </Typography>
                ) : (
                  <TextField
                    autoComplete="off"
                    size="small"
                    type="date"
                    fullWidth
                    variant="outlined"
                    name="date"
                    value={date}
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                    error={!data}
                  />
                )}
              </TableCell>

              {/* <TableCell colSpan={1} align="left"></TableCell> */}
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className={classes.skillcol}
                style={{ width: "" }}
              >
                Milestone{" "}
              </TableCell>

              <TableCell align="left" className={classes.skillcol}>
                {" "}
                Date
              </TableCell>

              <TableCell align="left" className={classes.numbercol}>
                Price
              </TableCell>

              <TableCell align="left" className={classes.skillcol}>
                Remarks
              </TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>

          {data.map((row, i) => {
            return (
              <MileStone
                key={row.id}
                data={row}
                addItem={addItem}
                removeItem={removeItem}
                changeItem={changeItem}
                readOnly={props.readOnly}
              />
            );
          })}

          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="left">
                Total
              </TableCell>

              <TableCell colSpan={3} align="left">
                {data.reduce(reducer, 0).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2} align="left">
                Supporting Document
              </TableCell>

              <TableCell colSpan={3} align="left">
                {showUpload ? (
                  <UploadFile
                    uploadFile={uploadFile}
                    upload={upload}
                    uploadPer={uploadPer}
                    readOnly={props.readOnly}
                  />
                ) : (
                  <DownLoadFile
                    name={fileName}
                    setUpload={setUpload}
                    setShowupload={setShowupload}
                    setFileName={setFileName}
                    setUploadPer={setUploadPer}
                    productvbeid={props.productvbeid}
                    projectid={props.projectid}
                    readOnly={props.readOnly}
                  />
                )}
              </TableCell>
            </TableRow>

            {props.readOnly ? null : (
              <TableRow>
                <TableCell colSpan={3} align="right"></TableCell>
                <TableCell colSpan={2} align="right">
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={saveData}
                    disabled={uloading}
                  >
                    Save As Draft
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
        </Table>
      </TableContainer>
    </Box>
  );
}
