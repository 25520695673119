import { useState } from "react";

import {
  Grid,
  List,
  ListItem,
  Avatar,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

import { VBRIDGEHUB_MEDIAURL } from "../../../constants";
import clsx from "clsx";

import ContactList from "./contactList";
import MessageList from "./messageList";

import "./messaging.css";
import { useStyles } from "./styles";

export default function Messaging(props) {
  const classes = useStyles();

  let { data } = props;
  let products = [];

  const [productname, setProductName] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [productid, setProductId] = useState("");

  const getValues = (companyName, productName, productId) => {
    setCompanyName(companyName);
    setProductName(productName);
    setProductId(productId);
  };

  if (data) {
    let productsData = data.getProductsByProjectIDV1;

    if (props.dataName) {
      productsData = data[props.dataName];
    }

    console.log("data", productsData);

    productsData.forEach((product) => {
      if (product.id !== "concierge0001") {
        products.push(product);
      }
    });

    if (products.length > 0 && productid === "") {
      let productId = products[0].productvbeid;
      setProductId(productId);
    }
  }

  const emptyBox = clsx({
    [classes.mainFlexBox]: true,
    [classes.emptyChatBox]: true,
  });

  const mainBox = clsx({
    [classes.mainFlexBox]: true,
    [classes.mainForumBox]: true,
  });

  return (
    <Grid container md={12} className={classes.chatRoot}>
      <Grid item md={3}>
        <List className={classes.listItem}>
          {data
            ? products.map((value) => {
                return (
                  <>
                    {value.role.length >= 1 ? (
                      <ListItem
                        key={value.productvbeid || value.vbeid}
                        style={{
                          height: 60,
                          cursor: "pointer",
                          padding: "4px 8px",
                        }}
                        selected={
                          value.productvbeid === productid ? true : false
                        }
                        dense
                        onClick={() =>
                          getValues(
                            value.companyname,
                            value.productname,
                            value.productvbeid
                          )
                        }
                      >
                        <ListItemAvatar className={classes.itemRoot}>
                          <Avatar
                            className={classes.large}
                            alt={value.name}
                            src={VBRIDGEHUB_MEDIAURL + "/" + value.image}
                          >
                            <CancelPresentationIcon />
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          disableTypography={true}
                          primary={
                            <ContactList
                              vbeid={value.productvbeid}
                              name={value.productname}
                              companyname={value.companyname}
                              sendValues={getValues}
                              selected={
                                value.productvbeid === productid ? true : false
                              }
                            />
                          }
                        />
                      </ListItem>
                    ) : null}
                  </>
                );
              })
            : null}
        </List>
      </Grid>

      {data && (
        <Grid item md={9}>
          {products.length >= 1 ? (
            <MessageList
              companyname={companyname || products[0].companyname}
              productname={productname || products[0].productname}
              productvbeid={productid || products[0].productvbeid}
              vbeid={props.id}
            />
          ) : (
            <div className={mainBox}>
              <div className={emptyBox}>
                <div>
                  {data.getProductsByProjectIDV1.length === 0 ? (
                    <div className={classes.newHeading}>
                      Please add vendors to start discussion.
                    </div>
                  ) : (
                    <div className={classes.newSubHeading}>
                      Please add vendors to start discussion.
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
}
