import React, { useState } from "react";

import BillingSwiper from "./LegalEntitySwiper";

import { useStyles } from "./styles";
import AlertBox from "../common/AlertBox";
import { Button, Divider, Grid, Paper, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ArchiveIcon from "@material-ui/icons/Archive";
import { useMutation } from "@apollo/client";
import { updateEditLegalEntity } from "./query";
import SimpleSnackbar from "../groups2.0/snackbar";
import SimpleErrorSnackbar from "../companies/Company2.0/tabs/errorsnackbar";

export default function EntityViewCard({
  legalentity,
  submitForm,
  userInfo,
  refetch,
}) {
  const [swipperOpen, setSwipperOpen] = useState(false);
  const [approveStatus, setApproveStatus] = useState(false);
  const [archiveStatus, setArchiveStatus] = useState(false);

  const classes = useStyles();

  const handleSwipperOpen = () => {
    setSwipperOpen(true);
  };

  const handleSwipperClose = () => {
    setSwipperOpen(false);
  };

  const handleArchive = () => {
    setArchiveStatus(true);
  };

  const handleApprove = () => {
    setApproveStatus(true);
  };

  const handleClose = () => {
    setArchiveStatus(false);
    setApproveStatus(false);
  };

  let approveValue = "declined";
  if (approveStatus) {
    approveValue = "approve";
  }

  let archivevalue = "no";
  if (archiveStatus) {
    archivevalue = "yes";
  }

  const { legalentityname, companyname } = legalentity?.legalentity;

  const { address, addressoptional, country, state, city, postalcode } =
    legalentity?.legalentity?.address;

  const { pannumber, gstnumber } = legalentity?.legalentity?.pangst;

  const { bankname, currency, accountnumber, ifsccode, routingnumber } =
    legalentity?.banking;

  const { contactname, contactemail, contacttitle } =
    legalentity?.accountingcontactinfo;

  const [
    postArchive,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(updateEditLegalEntity, {
    onCompleted: (e) => {
      refetch();
    },
  });

  function archiveLegalEntity() {
    // e.preventDefault();
    postArchive({
      variables: {
        input: {
          timestamp: legalentity.createdon,
          achieved: archivevalue,
        },
      },
    });
  }

  return (
    <>
      {" "}
      {mutationLoading ? <SimpleSnackbar type="loading" /> : null}
      {mutationError ? <SimpleErrorSnackbar /> : null}
      <Grid container className={classes.cards} direction="row">
        <Paper elevation={1}>
          <Grid container md={12}>
            <Grid
              container
              md={12}
              className={classes.cardhead}
              justifyContent="left"
              alignContent="left"
              alignItems="left"
            >
              <Grid item md={7}>
                <p className={classes.cardheader}>{legalentityname}</p>
              </Grid>

              {legalentity?.status !== null ? (
                <>
                  <Tooltip title="Legal Entity Status">
                    <Grid item md={3} className={classes.editbtn}>
                      <Button
                        className={
                          legalentity?.status === "approved"
                            ? classes.approve
                            : classes.decline
                        }
                        disabled={true}
                      >
                        {" "}
                        {legalentity?.status === "approved"
                          ? "Approved"
                          : "Declined"}
                      </Button>{" "}
                    </Grid>
                  </Tooltip>

                  {legalentity?.status === "declined" ? (
                    <Tooltip title="Edit">
                      <Grid item md={1} className={classes.editbtn}>
                        <EditIcon
                          className={classes.editicon}
                          variant="contained"
                          // type="contained"
                          onClick={handleSwipperOpen}
                          name="Edit"
                          label="Edit"
                        />{" "}
                      </Grid>
                    </Tooltip>
                  ) : null}

                  {legalentity?.status === "approved" ? (
                    <Grid item md={1} className={classes.editbtn}>
                      <ArchiveIcon
                        className={classes.editicon}
                        variant="contained"
                        // type="contained"
                        onClick={handleArchive}
                      />{" "}
                    </Grid>
                  ) : null}
                </>
              ) : null}

              {legalentity.status === null ? (
                <>
                  <Tooltip title="Legal Entity Status">
                    <Grid item md={3} className={classes.editbtn}>
                      <span className={classes.approve} disabled={true}>
                        {" "}
                        Approval Pending
                      </span>{" "}
                    </Grid>
                  </Tooltip>

                  <Tooltip title="Archive">
                    <Grid item md={1} className={classes.editbtn}>
                      <ArchiveIcon
                        className={classes.editicon}
                        variant="contained"
                        // type="contained"
                        onClick={handleArchive}
                      />{" "}
                    </Grid>
                  </Tooltip>

                  <Tooltip title="Edit">
                    <Grid item md={1} className={classes.editbtn}>
                      <EditIcon
                        className={classes.editicon}
                        variant="contained"
                        // type="contained"
                        onClick={handleSwipperOpen}
                        name="Edit"
                        label="Edit"
                      />{" "}
                    </Grid>
                  </Tooltip>
                </>
              ) : null}
            </Grid>

            <Grid container md={12} className={classes.details}>
              {/* <Grid item md={4}>
              <p className={classes.label}>Legal Entity Name </p>
            </Grid>
            <Grid item md={0.5}>
              <p className={classes.label}> : </p>
            </Grid>

            <Grid item md={6}>
              <p className={classes.value}>Legal Entity Name</p>
            </Grid> */}
              <Grid item md={4}>
                <p className={classes.label}>Company Name </p>
              </Grid>
              <Grid item md={0.5}>
                <p className={classes.label}> : </p>
              </Grid>
              <Grid item md={6}>
                <p className={classes.value}>{companyname}</p>
              </Grid>

              <Grid item md={4}>
                <p className={classes.label}>PAN Number</p>
              </Grid>
              <Grid item md={0.5}>
                <p className={classes.label}> : </p>
              </Grid>
              <Grid item md={6}>
                <p className={classes.value}>{pannumber}</p>
              </Grid>
              <Grid item md={4}>
                <p className={classes.label}>GST Number </p>
              </Grid>
              <Grid item md={0.5}>
                <p className={classes.label}> : </p>
              </Grid>
              <Grid item md={6}>
                <p className={classes.value}>{gstnumber}</p>
              </Grid>

              <Grid item md={4}>
                <p className={classes.label}>Address </p>
              </Grid>
              <Grid item md={0.5}>
                <p className={classes.label}> : </p>
              </Grid>
              <Grid item md={6}>
                <p className={classes.value}>{address}</p>
                <p className={classes.value}>
                  {city},{""}
                  {state},{""}
                  {country},{""}
                  {postalcode}
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <Divider />
          </Grid>

          {/* // Banking  Detailes */}

          <Grid container md={12}>
            <Grid container md={12} className={classes.details}>
              <Grid item md={4}>
                <p className={classes.label}>BankName </p>
              </Grid>
              <Grid item md={0.5}>
                <p className={classes.label}> : </p>
              </Grid>

              <Grid item md={6}>
                <p className={classes.value}>{bankname}</p>
              </Grid>

              <Grid item md={4}>
                <p className={classes.label}>A/C Number </p>
              </Grid>
              <Grid item md={0.5}>
                <p className={classes.label}> : </p>
              </Grid>
              <Grid item md={6}>
                <p className={classes.value}>{accountnumber}</p>
              </Grid>

              <Grid item md={4}>
                <p className={classes.label}>IFSC code</p>
              </Grid>
              <Grid item md={0.5}>
                <p className={classes.label}> : </p>
              </Grid>
              <Grid item md={6}>
                <p className={classes.value}>{ifsccode}</p>
              </Grid>

              <Grid item md={4}>
                <p className={classes.label}>Routing Number</p>
              </Grid>
              <Grid item md={0.5}>
                <p className={classes.label}> : </p>
              </Grid>
              <Grid item md={6}>
                <p className={classes.value}>{routingnumber}</p>
              </Grid>

              <Grid item md={4}>
                <p className={classes.label}>Country</p>
              </Grid>
              <Grid item md={0.5}>
                <p className={classes.label}> : </p>
              </Grid>
              <Grid item md={6}>
                <p className={classes.value}>{legalentity?.banking?.country}</p>
              </Grid>

              <Grid item md={4}>
                <p className={classes.label}>Currency</p>
              </Grid>
              <Grid item md={0.5}>
                <p className={classes.label}> : </p>
              </Grid>
              <Grid item md={6}>
                <p className={classes.value}>{currency}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>

          {/* Contact Info */}

          <Grid container md={12}>
            <Grid container md={12} className={classes.details}>
              <Grid container md={6}>
                <Grid item md={11}>
                  <p className={classes.label}>Accounting Contact</p>
                </Grid>
                <Grid item md={11}>
                  <p className={classes.value}>{contactname}</p>
                </Grid>
                <Grid item md={11}>
                  <p className={classes.value}>{contactemail}</p>
                </Grid>
                <Grid item md={11}>
                  <p className={classes.value}>{contacttitle}</p>
                </Grid>
              </Grid>
              <Grid container md={6}>
                <Grid item md={11}>
                  <p className={classes.label}>Legal Contact</p>
                </Grid>

                <Grid item md={11}>
                  <p className={classes.value}>
                    {legalentity?.legalcontactinfo?.contactname}
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p className={classes.value}>
                    {legalentity?.legalcontactinfo?.contactemail}
                  </p>
                </Grid>
                <Grid item md={11}>
                  <p className={classes.value}>
                    {legalentity?.legalcontactinfo?.contacttitle}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {swipperOpen ? (
            <BillingSwiper
              open={swipperOpen}
              onClose={handleSwipperClose}
              title="Update Billing and Legal Entity Details"
              type="edit"
              legalentity={legalentity}
              createdon={legalentity?.createdon}
              status={approveStatus || archiveStatus}
            />
          ) : null}
        </Paper>

        {archiveStatus ? (
          <AlertBox
            open={archiveStatus}
            onConfirm={archiveLegalEntity}
            onClose={handleClose}
            title="Archive"
            description="Are you sure you want to Archive Entity?"
            mutaionError=""
            data=""
          />
        ) : null}
      </Grid>
    </>
  );
}
