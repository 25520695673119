import { Grid, Paper, Typography } from "@material-ui/core";

import EmptyDiv from "../../companies/company/tabs/lists/emptyDiv";
import OtherProductsList from "./OtherProductsList";

import { useStyles } from "./styles";

export default function LeftPaneProducts({
  title,
  companyName,
  products,
  vbeid,
  type,
  userCompany,
}) {
  const classes = useStyles();
  return (
    <Grid item md={12}>
      <Paper elevation={1} square={true} className={classes.leftPaneDiv}>
        <Typography
          gutterBottom
          variant="button"
          className={classes.subHeading}
          component="caption"
          display="block"
        >
          {" "}
          {title} {companyName || null}
        </Typography>

        {products && products.length !== 0 ? (
          <OtherProductsList
            data={products}
            vbeid={vbeid}
            userCompany={userCompany}
          />
        ) : (
          <div className={classes.emptyDivMain}>
            <EmptyDiv type={type || "products"} margin="noMargin" />
          </div>
        )}
      </Paper>
    </Grid>
  );
}
