import React from "react";
import { useState } from "react";
import { useStyles } from "./styles";
import { useQuery } from "@apollo/client";
import Table from "@material-ui/core/Table";
import { CircularProgress } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { getResourcesByStatus } from "./query";
import { getFormattedDate } from "../../../../functions/helper";

export default function ViewPO({ drawerOpen }) {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  //   const [actionSlider, setActionSlider] = useState(false);
  //   const [anchorEl, setAnchorEl] = useState(null);
  //   const [selectedResource, setSelectedResource] = useState("");

  const { data, loading } = useQuery(getResourcesByStatus, {
    variables: { input: { status: "RESOURCE_ONBOARDED" } },
  });

  const resourceDatalength = data?.listResourcesByStatus?.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  //   const onActionClick = (currentResource) => {
  //     getPODetails({
  //       variables: {
  //         input: {
  //           resourceid: currentResource.resourceid,
  //         },
  //       },
  //     });

  //     setActionSlider(true);
  //     setSelectedResource(currentResource);
  //     setAnchorEl(null);
  //   };

  //   const onClose = () => {
  //     setActionSlider(false);
  //     setSelectedResource("");
  //   };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, resourceDatalength - page * rowsPerPage);

  return (
    <div className={drawerOpen ? classes.drawerOpenRoot : classes.root}>
      <p className={classes.heading}>Active Purchase Orders</p>

      <Paper className={classes.paper}>
        <TableContainer className="tableContainer">
          {loading ? (
            <CircularProgress className={classes.loadstyle} size={24} />
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2}>Project Name</TableCell>
                  <TableCell rowSpan={2}>Project Owner</TableCell>

                  <TableCell align="center" rowSpan={2}>
                    Supplier Name
                  </TableCell>

                  <TableCell align="center" rowSpan={2}>
                    Resource Name
                  </TableCell>

                  <TableCell
                    align="center"
                    colSpan={2}
                    className={classes.engagement}
                  >
                    Engagement
                  </TableCell>

                  <TableCell align="center" rowSpan={2}>
                    Currency
                  </TableCell>

                  <TableCell align="center" rowSpan={2}>
                    Billing Mode
                  </TableCell>

                  <TableCell align="center" rowSpan={2}>
                    Billing Rate
                  </TableCell>

                  {/* <TableCell align="center" rowSpan={2}>
                    Actions
                  </TableCell> */}
                </TableRow>

                <TableRow>
                  <TableCell align="center">Start Date</TableCell>
                  <TableCell align="center">End Date</TableCell>
                </TableRow>
              </TableHead>

              {data?.listResourcesByStatus.length ? (
                <TableBody>
                  {data?.listResourcesByStatus?.map((data) => {
                    return (
                      <TableRow>
                        <TableCell align="center">{data.projectName}</TableCell>

                        <TableCell align="center">
                          {data.projectOwner}
                        </TableCell>

                        <TableCell align="center">{data.companyname}</TableCell>
                        <TableCell align="center">{data.name}</TableCell>

                        <TableCell align="center">
                          {getFormattedDate(data.engagementStartDate)}
                        </TableCell>

                        <TableCell align="center">
                          {getFormattedDate(data.engagementEndDate)}
                        </TableCell>

                        <TableCell align="center">{data.currency}</TableCell>
                        <TableCell align="center">Per Hour</TableCell>

                        <TableCell align="center">
                          {data.revisedbillingrate || data.billingrate}
                        </TableCell>

                        {/* <TableCell align="center">
                          <IconButton
                            className={classes.iconstyle}
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell> */}

                        {/* <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem
                            className={classes.menuitem}
                            onClick={() => {
                              onActionClick(data);
                            }}
                          >
                            Upload PO
                          </MenuItem>
                        </Menu> */}
                      </TableRow>
                    );
                  })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={10}>No new Purchase Orders!</TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={resourceDatalength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* {actionSlider && (
        <CandidateActionSlider
          actionSlider={actionSlider}
          onClose={onClose}
          action="generatePo"
          refetch={refetch}
          selectedResource={selectedResource}
          projectData=""
          poDetails={poDetails?.listPO}
          remarks=""
        />
      )} */}
    </div>
  );
}
