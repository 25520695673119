import { Box, CardContent, Divider, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useStyles } from "./styles";

export default function DataLoading() {
  const classes = useStyles();
  return (
    <CardContent style={{ textAlign: "left" }}>
      <Box m={1}></Box>

      <Grid container className={classes.ngrid} justify="flex-start">
        <Grid item xs={2}>
          <Skeleton variant="rect" className={classes.square} />
        </Grid>

        <Grid item xs={10}>
          <Typography gutterBottom variant="BUTTON TEXT" component="h2">
            <Skeleton width="40%" />
          </Typography>

          <Typography variant="p" component="p">
            <Skeleton width="100%" height={100} variant="text" />
          </Typography>
        </Grid>
      </Grid>
      <Divider />

      <Skeleton width="100%" height={300} variant="text" />
    </CardContent>
  );
}
