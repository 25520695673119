import { Divider, IconButton, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import ItemsPreview from "./ItemsPreview";

import { useStyles } from "./styles";

// import { getItemsInSection } from "./query";
// import { useQuery } from "@apollo/client";

export default function SectionPreview({ section, items }) {
  //   const {
  //     data: itemList,
  //     loading,
  //     error,
  //   } = useQuery(getItemsInSection, {
  //     variables: { input: { vbeid: section.vbeid, timestamp: section.id } },
  //   });

  let sectionProps = section?.properties;
  if (!section.properties) {
    sectionProps = JSON.parse(section.metadata);
  }

  const { label, helpMessage } = sectionProps;

  const classes = useStyles();

  return (
    <>
      <div className={classes.sectionMain}>
        {label}{" "}
        {helpMessage !== "" && (
          <span>
            <Tooltip title={helpMessage} placement="top">
              <IconButton className={classes.helpButton}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </span>
        )}
      </div>

      <ItemsPreview
        items={
          items.length !== 0
            ? items.filter((item) => item.section === section.id)
            : []
        }
        sectionVbeid={section.vbeid}
        sectionId={section.id}
      />

      <Divider />
    </>
  );
}
