import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";

import { useFormik } from "formik";

import * as yup from "yup";

import { useMutation } from "@apollo/client";

import SimpleSnackbar from "./snackbar";
import SimpleErrorSnackbar from "./errorsnackbar";

import { inviteCompanyEmail } from "../query";
import AddNewButton from "./AddNewButton";
import InputField from "../../../common/InputField";
import { CircularProgress, Typography } from "@material-ui/core";
import { useStyles } from "../../../Help/HelpStyles";

export default function AddInvite({
  updateCache,
  inviteMsg,
  headername,
  dialogtitle,
  invite,
  supplieropen,
  supplierclose,
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   return () => {
  //     clearTimeout(timer.current);
  //   };
  // }, []);

  // const handleButtonClick = () => {
  //   if (!mutationLoading && !loading) {
  //     setLoading(true);

  //     timer.current = window.setTimeout(() => {
  //       // setLoading(false);
  //     }, 50000);
  //   }
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (supplierclose) {
      supplierclose();
    } else {
      setOpen(false);
    }
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter your Name")
      .nullable()
      .min(2, "Name should be of minimum 2 characters length")
      .required("Name is required"),

    companyurl: yup
      .string("Enter your Company URL")
      .required("Company URL is required")
      .nullable()
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        "Enter valid Company URL"
      ),

    // contactnumber: yup
    //   .string("Enter your contact number")
    //   .required("Contact number is required")
    //   // .min(8)
    //   .matches(
    //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //     "Phone number is not valid"
    //   ),

    personname: yup
      .string("Enter your Name")
      .nullable()
      .min(2, "Name should be of minimum 2 characters length")
      .required("Name is required"),

    email: yup
      .string("Enter your email")
      .email("Invalid email address format")
      .required("Email is required"),

    remarks: yup
      .string("Enter your remarks")
      .min(10, "Remarks should be of minimum 10 characters length")
      .nullable(),
    // .matches(
    //   /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
    //   "Enter valid remarks"
    // ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      companyurl: "",
      contactnumber: "",
      email: "",
    },
    validationSchema: validationSchema,
  });

  // invite

  const [
    postInviteCompanyMutation,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(inviteCompanyEmail, {
    onCompleted: (e) => {
      handleClose();
      afterSendInviteInformationCache(e);
    },
  });

  const createInvite = (e) => {
    e.preventDefault();

    postInviteCompanyMutation({
      variables: {
        input: {
          toCompanyName: formik.values.name,
          ToUserName: formik.values.personname,
          ToUserNameEmailid: formik.values.email,
          remarks: formik.values.remarks,
        },
      },
    });

    // handleButtonClick();
  };

  //cache for Invite company cache

  const afterSendInviteInformationCache = (e) => {
    let temp = [];
    formik.values.name = temp;
    formik.values.companyurl = temp;
    formik.values.personname = temp;
    formik.values.remarks = temp;
    formik.values.email = temp;
  };

  return (
    <div>
      {mutationError && <SimpleErrorSnackbar />}
      {data ? <SimpleSnackbar /> : null}

      {inviteMsg === "Send an Invite" ? (
        <Button className={classes.inviteBtn} onClick={handleClickOpen}>
          Send an Invite
        </Button>
      ) : null}
      <form>
        <Dialog
          open={supplieropen ? supplieropen : open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          className={classes.formMainDiv}
        >
          <DialogTitle id="form-dialog-title" className={classes.title}>
            {headername ? headername : "Invite"}
          </DialogTitle>

          <DialogContent dividers>
            <DialogContentText className={classes.stmt}>
              {dialogtitle
                ? dialogtitle
                : "Please enter the following details."}
            </DialogContentText>

            <div className={classes.root}>
              <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.inviteinlay}
                  >
                    <InputField
                      label="Company Name "
                      required={true}
                      value={formik.values.name}
                      onChange={formik.handleChange("name")}
                      onBlur={formik.handleBlur("name")}
                      // placeholder="Please enter name"
                    />
                    <div className={classes.errorfields}>
                      {formik.touched.name && formik.errors.name && (
                        <div className="error-message">
                          {formik.errors.name}
                        </div>
                      )}
                    </div>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.inviteinlay}
                  >
                    <InputField
                      label="Company Url *"
                      value={formik.values.companyurl}
                      onChange={formik.handleChange("companyurl")}
                      onBlur={formik.handleBlur("companyurl")}
                      placeholder="Company url should begin with https://"
                    />
                    <div className={classes.errorfields}>
                      {formik.touched.companyurl &&
                        formik.errors.companyurl && (
                          <div className="error-message">
                            {formik.errors.companyurl}
                          </div>
                        )}
                    </div>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.inviteinlay}
                  >
                    <InputField
                      label="Person Name "
                      required={true}
                      value={formik.values.personname}
                      onChange={formik.handleChange("personname")}
                      onBlur={formik.handleBlur("personname")}
                      // placeholder="Please enter name"
                    />
                    <div className={classes.errorfields}>
                      {formik.touched.personname &&
                        formik.errors.personname && (
                          <div className="error-message">
                            {formik.errors.personname}
                          </div>
                        )}
                    </div>
                  </FormControl>
                </Grid>
              </Grid>

              {/* <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputField
                      label="Contact Number *"
                      value={formik.values.contactnumber}
                      onChange={formik.handleChange("contactnumber")}
                      onBlur={formik.handleBlur("contactnumber")}
                    />
                    <div className={classes.errorfields}>
                      {formik.touched.contactnumber &&
                        formik.errors.contactnumber && (
                          <div className="error-message">
                            {formik.errors.contactnumber}
                          </div>
                        )}
                    </div>
                  </FormControl>
                </Grid>
              </Grid> */}

              <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.inviteinlay}
                  >
                    <InputField
                      label="Email *"
                      value={formik.values.email}
                      onChange={formik.handleChange("email")}
                      onBlur={formik.handleBlur("email")}
                      // placeholder="Please enter email"
                      //options={serviceTypeList}
                      // multiple={true}
                    />
                    <div className={classes.errorfields}>
                      {formik.touched.email && formik.errors.email && (
                        <div className="error-message">
                          {formik.errors.email}
                        </div>
                      )}
                    </div>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.inviteinlay}
                  >
                    <InputField
                      label="Remarks"
                      multiline
                      rows={3}
                      rowsMax={5}
                      value={formik.values.remarks}
                      onChange={formik.handleChange("remarks")}
                      onBlur={formik.handleBlur("remarks")}
                    />
                    <div className={classes.errorfields}>
                      {formik.touched.remarks && formik.errors.remarks && (
                        <div className="error-message">
                          {formik.errors.remarks}
                        </div>
                      )}
                    </div>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <Typography className={classes.message}>
                    Mail will be sent to beneficiary mentioned and to vBridge
                    Hub Helpdesk team.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </DialogContent>

          <DialogActions>
            <AddNewButton
              handleClick={handleClose}
              color="primary"
              className={classes.hbtn}
              variant="outlined"
              label="Cancel"
              name="Cancel"
            />

            <AddNewButton
              handleClick={createInvite}
              type="submit"
              color="primary"
              variant="contained"
              disabled={!(formik.isValid && formik.dirty)}
              className={classes.hbtn}
              label="Add"
              name="Add"
            />

            {mutationLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
