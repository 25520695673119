import { gql } from "@apollo/client";

export const deletelist = gql`
  mutation delete($input: id!) {
    removeList(input: $input) {
      vbeid
      id
    }
  }
`;

export const userLists = gql`
  query userlists {
    getListsByUser {
      id
      name
      description
      vbeid
    }
  }
`;

export const postlist = gql`
  mutation list($input: listinput!) {
    createList(input: $input) {
      name
      vbeid
    }
  }
`;

export const listByListId = gql`
  query listinformation($input: listinput!) {
    getListByListId(input: $input) {
      name
      description
    }
  }
`;
