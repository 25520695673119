import { gql } from "@apollo/client";

export const userInfoQuery = gql`
  query userinfo {
    getLoginUserInfo(input: {}) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
      isPrivatemail
    }
  }
`;

export const getProfileAdminsQuery = gql`
  query profileAdmins {
    listProfileAdmin(input: {}) {
      id
      vbeid
      name
      imageurl
    }
  }
`;

export const getProfileAdminsRequestQueue = gql`
  query profileAdminsRequestQueue {
    listProfileAdminRequest(input: {}) {
      id
      vbeid
      name
    }
  }
`;

export const getCompanyById = gql`
  query companyinfo($input: id!) {
    queryCompanybyid(input: $input) {
      name
      shortdescription
      vbeid
      image
      companyurl
    }
  }
`;

export const claimProfile = gql`
  mutation claimProfile {
    claimCompany {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
    }
  }
`;

export const createCompany = gql`
  mutation createCompany($input: companyinput!) {
    addMyCompany(input: $input) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
    }
  }
`;

export const requestProfileAdminAccess = gql`
  mutation requestAdminAccess {
    profileadminrequest(input: {}) {
      id
      role
    }
  }
`;

export const removeAdminAccess = gql`
  mutation removeProfileadmin($input: id!) {
    removeProfileadmin(input: $input) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
    }
  }
`;

export const acceptOrRejectAdminRequest = gql`
  mutation acceptOrRejectAdminRequest($input: id!) {
    profileaccessapproval(input: $input) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
    }
  }
`;

export const getNDAStatus = gql`
  query getStatus {
    getNDAStatus(input: {}) {
      status
    }
  }
`;

export const getCompanyNDAStatus = gql`
  query getCompanyNDAStatus {
    companyNDAStatus(input: {}) {
      status
      name
    }
  }
`;
