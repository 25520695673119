import * as yup from "yup";

const verticals = require("../../../data/companyVerticals.json");

const newVal = [];

verticals.map((group) => {
  if (group["n.type"] === "Company Vertical") {
    return newVal.push(group["n.name"]);
  } else return null;
});

export const productUpdateSchema = yup.object().shape({
  url: yup
    .string("Enter URL")
    .required("URL is required")
    .matches(
      /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      "Enter URL"
    ),
});

export const serviceUpdateSchema = yup.object().shape({
  url: yup
    .string("Enter URL")
    .required("URL is required")
    .matches(
      /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      "Enter URL"
    ),
});

export const solutionUpdateSchema = yup.object().shape({
  url: yup
    .string("Enter URL")
    // .required("URL is required")
    .matches(
      /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      "Enter URL"
    ),
  product: yup.array(),

  //   industryvertical: yup
  //     .string("Select a Vertical")
  //     .oneOf(newVal, "Please select a valid Vertical")
  //     .required("Vertical is required"),
});

export const successStoryUpdateSchema = yup.object().shape({
  url: yup
    .string("Enter URL")
    // .required("URL is required")
    .matches(
      /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      "Enter URL"
    ),
  product: yup.array(),

  //   industryvertical: yup
  //     .string("Select a Vertical")
  //     .oneOf(newVal, "Please select a valid Vertical")
  //     .required("Vertical is required"),
});
