import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paperRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "85%",
  },
  resultList: {
    minWidth: theme.spacing(50),
  },
  indextext: {
    marginLeft: theme.spacing(4),
    textAlign: "right",
    fontStyle: "italic",
    fontSize: theme.spacing(1.2),
    textTransform: "capitalize",
  },
  avatar: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "0.85rem",
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  productResultDiv: {
    margin: theme.spacing(2, 0, 4, 0),
    fontSize: "0.9rem",
  },
  productDiv: {
    margin: theme.spacing(2, 0, 1, 0),
    fontSize: "0.9rem",
  },
  productTitle: {
    textAlign: "left",
    marginBottom: theme.spacing(1),
    fontSize: "0.9rem",
    color: "#4179BD",
    fontWeight: "500",
  },
  productName: {
    alignItems: "center",
    fontSize: "0.85rem",
    fontWeight: "600",
    color: "#4179BD",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "80%",

    "&:hover, &:focus": {
      color: "#232961",
    },

    "&:active": {
      color: "#232961",
    },
  },
  companyName: {
    fontSize: "0.8rem",
    fontWeight: "500",
    cursor: "pointer",
    color: "#4179BD",
    textAlign: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "90%",

    "&:hover, &:focus": {
      color: "#232961",
    },
    "&:active": {
      color: "#232961",
    },
  },
  bySubText: {
    fontWeight: "normal",
    fontSize: "0.85rem",
    color: "#333",
  },
  resultCard: {
    padding: theme.spacing(1),
    margin: theme.spacing(0.5, 1),
    borderRadius: "5px",
    cursor: "pointer",
    "& svg": {
      fill: "#4179BD",
    },
  },
  resultDiv: {
    display: "flex",
    textAlign: "left",
  },
  productDetails: {
    marginLeft: theme.spacing(1),
    width: "100%",
  },
  productDesc: {
    textAlign: "left",
    margin: theme.spacing(1),
    fontSize: "0.8rem",
    height: "6.5rem",
    overflow: "hidden",
  },
  categoryBadge: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    "& div": {
      padding: "2px 4px",
    },
  },

  location: {
    fontSize: "0.75rem",
    textAlign: "left",
    margin: theme.spacing(1),
  },
  detailText: {
    fontSize: "0.7rem",
    color: "#000",
    marginLeft: theme.spacing(4),
    cursor: "pointer",
  },
  container: {
    width: "100%",
    height: "240px",
    position: "relative",
  },
  slickContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  productImage: {
    "& img": {
      objectFit: "contain",
    },
  },
  productDetailDiv: {
    "& .MuiDrawer-paper": {
      width: "60%",
      padding: theme.spacing(1),
    },

    "& .MuiAvatar-square": {
      width: "70px",
      height: "70px",
      "& img": {
        objectFit: "contain",
      },
    },

    "& .makeStyles-companyDetailsDiv-736": {
      margin: 0,
    },
  },
  projectDetailSlider: {
    "& .MuiDrawer-paper": {
      width: "35%",
      padding: theme.spacing(1),
    },

    "& .MuiAvatar-square": {
      width: "60px",
      height: "60px",
      "& img": {
        objectFit: "contain",
      },
    },

    "& .makeStyles-companyDetailsDiv-736": {
      margin: 0,
    },
  },
  productDescription: {
    overflow: "auto",
    height: "70vh",
  },
  productDescLoading: {
    textAlign: "center",
  },
  sliderDescription: {
    overflow: "auto",
    height: "70vh",
    padding: theme.spacing(0, 2, 2, 2),
  },
  rowHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    margin: theme.spacing(0, 3),
  },
  productDetailCard: {
    "& .MuiGrid-item": {
      margin: 0,
    },
  },
  longDescText: {
    color: "#bebebe",
    fontSize: "0.8rem",
    fontStyle: "italic",
  },
  searchProduct: {
    paddingRight: "30px",

    "& .MuiAutocomplete-inputRoot": {
      padding: "1px",
    },
  },
  mapHeadingText: {
    margin: theme.spacing(0, 0, 0.5),
    fontSize: "0.85rem",
  },
  blockedUserRoot: {
    marginTop: theme.spacing(10),
  },
  blockedText: {
    justifyContent: "center",
  },

  selectText: {
    fontSize: "0.8rem",

    "& input": {
      padding: "1.3px 1px !important",
    },

    "& .MuiAutocomplete-tag": {
      margin: "2px",
      fontSize: "12px !important",
    },
    "& svg": {
      height: "14px !important",
      width: "16px !important",
    },
    "& .MuiChip-root": {
      height: "20px !important",
      width: "40% !important",
    },
  },

  searchDropdown: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "0",
    textTransform: "capitalize",
    // backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
  compareadd: {
    display: "flex",
  },

  formControl: {
    //padding: "8px",
    margin: "8px",
  },

  info: {
    marginBottom: "15px",
    fontSize: "0.85rem",
  },
  heading: {
    marginBottom: "20px",
    fontSize: "0.9rem",
    fontWeight: "500",
  },

  addNewBtn: {
    margin: "8px",
    padding: "4px 4px",
    float: "left",
  },
  addNewBtnproduct: {
    // marginTop: theme.spacing(8),
    // marginLeft: theme.spacing(-51),
    marginTop: "65px !important",
    marginLeft: "-380px !important",
    float: "left",
    // padding: "4px 4px",
  },

  nameurl: {
    display: "flex",
  },

  investorurl: {
    // height: "6px",
  },

  tabs: {
    "& span": {
      textTransform: "none",
    },
  },
}));
