import React from "react";

import { Grid, Paper, Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { useStyles } from "./styles";

export default function ResultLoading() {
  const classes = useStyles();

  return (
    <Paper className={classes.card}>
      <Box p={2}>
        <Grid container>
          <Grid item xs={3}>
            <Skeleton width="50%" />
          </Grid>

          <Grid item xs={9}>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </Grid>
        </Grid>

        <Grid container>
          <Skeleton width="100%" height="100px" />
        </Grid>
      </Box>
    </Paper>
  );
}
