import { useState } from "react";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import {
  FormControl,
  FormGroup,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

import UserAvatar from "../../shared/shortAvatar";
import File from "../../shared/uploadfile";

import InputField from "../common/InputField";
import SelectField from "../common/SelectField";

import GroupCard from "./GroupCard";

import { buyerRoleOptions } from "./helper";

import { followGroup, unfollowGroup } from "../../knowledgebase2.0/query";
import { listFollowingGroups, updateProfile, userInfoQuery } from "./query";

import { useStyles } from "./styles";

import "../../../App.css";
import { Link } from "react-router-dom";

export default function PersonalProfileForm({ formik, data, onGroupsSelect }) {
  const classes = useStyles();
  const client = useApolloClient();

  const [selectedGroups, setSelectedGroups] = useState([]);

  const { vbeid, name, imageurl } = data;

  const [updateProfileMutation] = useMutation(updateProfile, {
    onCompleted: (e) => {
      updateUserProfileCache(e);
    },
  });

  const { data: followingGroupsList } = useQuery(listFollowingGroups);

  const [followMutation] = useMutation(followGroup, {
    onCompleted: (response) => {
      onGroupsSelect(selectedGroups);
    },
  });

  const [unFollowMutation] = useMutation(unfollowGroup, {
    onCompleted: (response) => {
      onGroupsSelect(selectedGroups);
    },
  });

  const updateUserProfileCache = (e) => {
    client.writeQuery({
      query: userInfoQuery,
      data: {
        getLoginUserInfo: { ...e.getLoginUserInfo },
      },
      variables: { input: {} },
    });
  };

  const uploadPersonImageCache = (name, path) => {
    updateProfileMutation({ variables: { input: { imageurl: path } } });
  };

  const onGroupSelect = (group) => {
    let selectedList = selectedGroups;
    let index = selectedList.indexOf(group);

    if (index === -1) {
      setSelectedGroups((prevList) => [...prevList, group]);

      followMutation({
        variables: { input: { vbeid: group } },
      });
    } else {
      setSelectedGroups([
        ...selectedList.slice(0, index),
        ...selectedList.slice(index + 1),
      ]);

      unFollowMutation({
        variables: { input: { vbeid: group } },
      });
    }
  };

  if (
    followingGroupsList &&
    followingGroupsList.getFollowingGroupsByUserId !== null &&
    followingGroupsList.getFollowingGroupsByUserId.length !== 0 &&
    selectedGroups.length === 0
  ) {
    let followingList = [];

    followingGroupsList.getFollowingGroupsByUserId.forEach((following) => {
      followingList.push(following.id);
    });

    setSelectedGroups(followingList);
  }

  return (
    <form>
      <FormGroup className={classes.createFormGroup}>
        <div className={classes.formDivLeft}>
          <FormControl fullWidth={true} className={classes.formControl}>
            <InputField
              label="Name *"
              onChange={formik.handleChange("Name")}
              value={formik.values.Name}
            />
          </FormControl>

          <FormControl fullWidth={true} className={classes.formControl}>
            <InputField
              label="Designation *"
              onChange={formik.handleChange("Designation")}
              value={formik.values.Designation}
            />
          </FormControl>

          <FormControl
            fullWidth={true}
            className={classes.formControl}
            variant="outlined"
          >
            <SelectField
              label="Role *"
              onChange={formik.handleChange("Role")}
              value={formik.values.Role || ""}
              options={buyerRoleOptions}
            />
          </FormControl>

          <label>Profile Picture</label>

          <div className={classes.profilePictureDiv}>
            <UserAvatar
              imagePath={imageurl ? imageurl : "/dummy/dummy/dummy"}
              name={name}
            />

            <File
              vbeid={vbeid}
              entity="userimage"
              filetype="image"
              fext=".png"
              paramName="image"
              inputid={"personImage" + vbeid}
              updateCacheMethod={uploadPersonImageCache}
              buttonmessage="upload logo"
              type="icon"
              icon={<PhotoCamera fontSize="small" />}
            />
          </div>
        </div>

        <div className={classes.formDivRight}>
          <div className={classes.groupListDiv}>
            <div className={classes.groupListHeading}>
              Areas of Interest
              <FormHelperText className={classes.helperText}>
                (Choose as appropriate. Helps to personalize knowledge feed.)
              </FormHelperText>
            </div>

            <div className={classes.groupDiv}>
              <GroupCard
                onGroupClick={onGroupSelect}
                selectedGroups={selectedGroups}
              />
            </div>
          </div>
        </div>
      </FormGroup>

      <div className={classes.terms}>
        <Typography className={classes.termsText}>
          I have read and understood the{" "}
          <Link
            target="_blank"
            to={{
              pathname:
                "https://www.vbridgehub.com/docs/buyer-terms-of-use.pdf",
            }}
          >
            Buyer Terms of Service{" "}
          </Link>
          &
          <Link
            target="_blank"
            to={{ pathname: "https://vbridgehub.com/privacypolicy" }}
          >
            {" "}
            Privacy Policy.
          </Link>
        </Typography>
      </div>
    </form>
  );
}
