/**
 * CSS styles for Agreements component
 */

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
  },
  drawerOpenRoot: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    marginLeft: 250,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  mainGrid: {
    justifyContent: "center",
  },
  body: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  tabContent: {
    fontSize: "0.85rem",
  },
  textLink: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
    fontWeight: 500,
    borderBottom: "2px solid",
  },
  agreementText: {
    height: "200px",
    textAlign: "left",
    marginTop: theme.spacing(2),
    overflowY: "scroll",
    overflowX: "hidden",
    padding: theme.spacing(3),
    backgroundColor: "#efefef",
    fontSize: "0.8rem",
  },
  checkBoxForm: {
    display: "grid",
    textAlign: "left",
    marginTop: theme.spacing(2),
    "& span": {
      fontSize: "0.85rem",
      fontWeight: "500",
    },
  },
  agreeButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    "& button": {
      marginRight: theme.spacing(1),
    },
  },
  inputField: {
    "& input": {
      fontSize: "0.85rem",
    },
  },
  contentText: {
    fontSize: theme.spacing(1.7),
    fontWeight: 600,
  },

  dailogroot: {
    "& button": {
      textTransform: "none !important",
    },
  },

  contentTitle: {
    padding: theme.spacing(1, 2),
    "& h2": {
      fontSize: theme.spacing(2),
    },
  },
  actions: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  checkBox: {
    padding: "4px 4px 8px 8px",
  },
  loadingBtn: {
    width: "20px !important",
    height: "20px !important",
    padding: theme.spacing(0.5),
  },
  heading: {
    margin: theme.spacing(3),
  },
  statusIcon: {
    color: "#4CAF50",
    fontSize: "9rem",
    marginTop: theme.spacing(3),
  },
  ndaText: {
    fontWeight: 500,
  },
  redStatusIcon: {
    color: "#DC004E",
  },
  loadingText: {
    marginTop: theme.spacing(2),
    fontSize: "1.2rem",
  },
  linkText: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  iframe: {
    width: "100%",
    height: "80vh",
  },
  redirectMessage: {
    color: "#999",
    marginTop: theme.spacing(4),
    fontStyle: "italic",
  },
  spacingDiv: {
    margin: "8px 0",
  },
  spacingListItem: {
    margin: "24px 0 8px",
  },
  listItem: {
    listStyleType: "lower-roman",
  },
  dateSpacing: {
    margin: "8px 0 32px",
  },
  linkDiv: {
    margin: "0 0 16px",
  },
  subheading: {
    textTransform: "capitalize",
  },
  agreementStatus: {
    fontSize: "0.9rem",
  },
  stepTitleMessage: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formHeaderText: {
    fontSize: "0.85rem",
    marginBottom: theme.spacing(1),
    textAlign: "left",
    fontStyle: "italic",
  },
  viewProfileBtn: {
    fontSize: "0.7rem",
    textTransform: "capitalize",
  },
  mapButton: {
    float: "none",
    marginTop: "16px",
  },
}));
