import React, { useState } from "react";

import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import { gql, useQuery } from "@apollo/client";
import BusinessIcon from "@material-ui/icons/Business";
export const VBRIDGEHUB_MEDIAURL = process.env.REACT_APP_VBRIDGEHUB_MEDIAURL

// const axios = require("axios");

const searchQuery = gql`
  query esQuery($input: esinput) {
    esQuery(input: $input) {
      result {
        name
        vbeid
        awardername
        image
      }
      total
    }
  }
`;

// const CLEARBIT_API =
//   "https://autocomplete.clearbit.com/v1/companies/suggest?query=";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    margin: "auto",
  },
  companyLogo: {
    minWidth: theme.spacing(4),
  },
  text: { textTransform: "capitalize" },
  loading: {
    fontSize: '12px !important',
    margin: '8px  16px !important',
    color: 'teal',
    fontWeight: '600 !important',
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    height: theme.spacing(1),
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

function ReultListItem(props) {
  const {
    item,
    handleClose,
    setValue,
    setDomain,
    setName,
    setCompany,
    sentAwardVbeid,
  } = props;
  const classes = useStyles();

  function handleItemClick(e) {
    e.preventDefault();

    let { name, domain, vbeid } = item;

    setCompany(true);
    setValue(name);
    setName(name);
    sentAwardVbeid(vbeid);
    setDomain("https://" + domain);

    handleClose();
  }

  return (
    <ListItem key={item.vbeid} button dense={true} onClick={handleItemClick}>
      <ListItemAvatar className={classes.companyLogo}>
      <Avatar
              className={classes.avatar}
              aria-describedby="avatar-popover"
              variant="square"
              size="small"
              //alt={}
              //src={item.image}
              src={VBRIDGEHUB_MEDIAURL + '/' + item.image}
            >
              {' '}
              <BusinessIcon />{' '}
            </Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={
          <Typography
            variant="button"
            display="block"
            color="primary"
            className={classes.text}
          >
            {item.name}
          </Typography>
        }
      />
    </ListItem>
  );
}

function ResultList({
  text,
  handleClose,
  setValue,
  setDomain,
  setName,
  setCompany,
  sentAwardVbeid,
}) {
  const { data,loading } = useQuery(searchQuery, {
    variables: { input: { index: "company", name: text } },
  });

  //   const [result, setResult] = useState([]);

  // let result = [];
  // result = data?.esQuery.result;

  const classes = useStyles();

  // axios
  //   .get(CLEARBIT_API + text)
  //   .then(function (response) {
  //     setResult(response.data);
  //   })
  //   .catch(function (error) {
  //     setResult([]);
  //   });

  let searchList = [];

  if (data?.esQuery.result.length !== 0) {
    searchList = data?.esQuery.result.map((r) => (
      <ReultListItem
        item={r}
        handleClose={handleClose}
        setValue={setValue}
        setDomain={setDomain}
        setName={setName}
        setCompany={setCompany}
        sentAwardVbeid={sentAwardVbeid}
      />
    ));
  } else {
    let noResult = {
      name: "Didn't find award? Add award below manually.",
      domain: "unavailable",
      logo: "unavailable",
    };

    searchList = (
      <ReultListItem
        item={noResult}
        handleClose={handleClose}
        setValue={setValue}
        setDomain={setDomain}
        setName={setName}
        setCompany={setCompany}
        sentAwardVbeid={sentAwardVbeid}
        loading={loading}
      />
    );
  }

  return (
    <>
    {!loading ? (
      <List dense={true} component="nav" className={classes.resultList}>
        {searchList}
      </List>
    ) : (
      <div className={classes.loading}>loading...</div>
    )}
  </>
  );
}

function SearchResult(props) {
  const classes = useStyles();
  const {
    id,
    open,
    anchorEl,
    setAnchorEl,
    text,
    setValue,
    setDomain,
    setName,
    setCompany,
    sentAwardVbeid,
  } = props;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      className={classes.popover}
      id={id}
      open={open}
      anchorEl={anchorEl}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {text ? (
        <ResultList
          setValue={setValue}
          text={text}
          handleClose={handleClose}
          setDomain={setDomain}
          setName={setName}
          sentAwardVbeid={sentAwardVbeid}
          setCompany={setCompany}
        />
      ) : null}
    </Popover>
  );
}

export default function SearchAward({
  setDomain,
  setName,
  setCompany,
  sentAwardVbeid,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [text, setText] = useState("");
  const [value, setValue] = useState("");

  const classes = useStyles();
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const handleInputChange = (e) => {
    e.preventDefault();

    setText(e.target.value);
    setValue(e.target.value);
    setAnchorEl(e.target.parentElement.parentElement);
  };

  return (
    <>
      <BootstrapInput
        value={value === "unavailable" ? "" : value}
        placeholder="Search"
        name="companySearch"
        onChange={handleInputChange}
        autoComplete="off"
      />

      <SearchResult
        className={classes.resultDiv}
        id={id}
        open={open}
        anchorEl={anchorEl}
        setValue={setValue}
        setAnchorEl={setAnchorEl}
        text={text}
        setDomain={setDomain}
        setName={setName}
        sentAwardVbeid={sentAwardVbeid}
        setCompany={setCompany}
      />
    </>
  );
}
