import { gql } from "@apollo/client";

///list products by id details
export const productsByListId = gql`
  query productsinformation($input: listinput!) {
    getProductsByListId(input: $input) {
      name
      image
      shortdescription
      id
      vbeid
    }
  }
`;

export const deletelist = gql`
  mutation delete($input: listinput!) {
    removeFromList(input: $input) {
      name
      id
    }
  }
`;

export const UsecasesByListId = gql`
  query usecaseinformation($input: listinput!) {
    getUsecasesByListId(input: $input) {
      name
      image
      shortdescription
      id
      vbeid
    }
  }
`;

export const deleteUseCaselist = gql`
  mutation delete($input: listinput!) {
    removeFromList(input: $input) {
      name
      id
    }
  }
`;

export const CasestudiesByListId = gql`
  query casestudyinformation($input: listinput!) {
    getCasestudiesByListId(input: $input) {
      name
      image
      shortdescription
      id
      vbeid
    }
  }
`;

export const deleteCaseStudieslist = gql`
  mutation delete($input: listinput!) {
    removeFromList(input: $input) {
      name
      id
    }
  }
`;
