import { useState } from "react";

import { Paper, Typography, Tabs, Tab, Box } from "@material-ui/core";

import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

import ViewResponseForm from "../../vendor/reponse/responseForm";
import ViewQAForm from "../../vendor/reponse/qaForm";

import ViewPriceForm from "./priceForm";

import DiscussionCard from "../../../messaging2.0/discussionCard";

import { useStyles } from "./styles";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ViewVendorResponse(props) {
  const classes = useStyles();
  const { id } = useParams();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.vendorResponseRoot}>
      <Paper elevation={1} className={classes.responsePaper}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={<Typography variant="caption">View Response </Typography>}
            {...a11yProps(0)}
            className={classes.tab}
          />

          <Tab
            label={<Typography variant="caption">View Q&A</Typography>}
            {...a11yProps(1)}
            className={classes.tab}
          />

          <Tab
            label={<Typography variant="caption">View Pricing</Typography>}
            {...a11yProps(2)}
            className={classes.tab}
          />

          <Tab
            label={<Typography variant="caption">Message Supplier</Typography>}
            {...a11yProps(3)}
            className={classes.tab}
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          <ViewResponseForm
            id={id}
            productId={props.productId}
            readOnly={true}
            projectData={props.projectData}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ViewQAForm
            id={id}
            productId={props.productId}
            readOnly={true}
            projectData={props.projectData}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <ViewPriceForm
            projectId={id}
            productId={props.productId}
            readOnly={true}
            projectData={props.projectData}
          />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <DiscussionCard
            productId={props.productId}
            projectId={id}
            type="question"
            projectData={props.projectData}
          />
        </TabPanel>
      </Paper>
    </div>
  );
}
