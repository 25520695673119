import ItemButton from "./ItemButton";

const groups = require("../../../data/products/groups.json");

export default function ProductsMenu({ handleDrawerClose }) {
  return (
    <ItemButton
      title="Products"
      groups={groups}
      handleDrawerClose={handleDrawerClose}
    />
  );
}
