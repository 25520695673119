import React, { useState } from "react";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";

import SearchIcon from "@material-ui/icons/Search";

import { useStyles } from "./styles";

import OutlinedButton from "../companies/company/OutlinedButton";
import SearchResult from "../../shared/SearchResult";

export default function SearchBox({
  onSearchClick,
  searchType,
  n,
  onSelect,
  toggleValue,
  handleDrawerClose,
}) {
  const classes = useStyles();

  const [text, setText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (event) => {
    let enteredText = event.target.value;

    if (searchType === "advanced") {
      setText(enteredText);
    } else {
      setAnchorEl(event.target.parentElement.parentElement);
      event.preventDefault();

      setText(enteredText);
    }
  };

  const onSearch = () => {
    onSearchClick(text);
    setMenuAnchorEl(null);
  };

  const onEnterKey = (e) => {
    if (e.code === "Enter") {
      e.preventDefault();

      onSearchClick(text);
    }
  };

  return (
    <div className={classes.root}>
      <Paper
        component="div"
        elevation={0}
        variant="outlined"
        className={classes.paperRoot}
      >
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>

        <InputBase
          className={classes.input}
          placeholder={
            searchType === "advanced"
              ? "Enter atleast 1 or 2 lines for best results"
              : "Enter keyword to search"
          }
          inputProps={{ "aria-label": "search" }}
          onChange={handleChange}
          multiline={searchType === "advanced" ? true : false}
          onKeyDown={onEnterKey}
          rows="2"
        />
      </Paper>

      {searchType === "keyword" && (
        <SearchResult
          className={classes.resultDiv}
          id={id}
          open={open}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          text={text}
          toggleValue={toggleValue}
          handleDrawerClose={handleDrawerClose}
        />
      )}

      {searchType === "advanced" && (
        <OutlinedButton
          disabled={false}
          onClick={onSearch}
          label="Search"
          type="contained"
          className={classes.searchBtn}
        />
      )}
    </div>
  );
}
