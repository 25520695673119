import React from "react";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  ListItemIcon,
  List,
  ListItem,
  Checkbox,
  Avatar,
  Grid,
  Button,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import { VBRIDGEHUB_MEDIAURL } from "../../../../../constants";
import Load from "../../../../shared/skeletons/skeleton";
import VbridgeAlert from "../../../../shared/alert";
// import { theme } from "../../../../../theme/theme";
import Heading from "./Heading";
import EmptyMessageList from "./EmptyListMessage";
import { deleteUseCaselist, UsecasesByListId } from "./query";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  list: {
    padding: theme.spacing(0),
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  productHead: {
    cursor: "pointer",
  },
}));

// function Heading(props) {
//   let history = useHistory();
//   const classes = useStyles();

//   return (
//     <>
//       <Typography
//         className={classes.productHead}
//         variant="h6"
//         onClick={() => {
//           history.push("/usecase/" + props.vbeid);
//         }}
//       >
//         {props.name}
//       </Typography>
//     </>
//   );
// }

export default function UseCases() {
  const classes = useStyles();
  let { id } = useParams();
  const client = useApolloClient();

  const [checked, setChecked] = React.useState([]);
  // const [limit, setLimit] = React.useState(10);
  // const [defaultData, setDefaultData] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [checkValue, setCheckValue] = React.useState(false);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const checkedValues = [];

    if (currentIndex === -1) {
      newChecked.push(value);

      newChecked.map((value) => {
        return checkedValues.push(value.vbeid);
      });
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setValues(checkedValues);

    if (currentIndex === -1) {
      setCheckValue(true);
    } else if (currentIndex >= 1) {
      setCheckValue(true);
    } else if (newChecked.length < 1) {
      setCheckValue(false);
    }
  };

  const { data } = useQuery(UsecasesByListId, {
    variables: { input: { vbeid: id } },
  });

  const [
    removelistmutation,
    { error: mutationError, data: removeList },
  ] = useMutation(deleteUseCaselist, {
    onCompleted: (e) => {
      UpdateDeleteCache(e);
    },
  });

  function handleDelete(e) {
    removelistmutation({
      variables: {
        input: { objectvbeid: values, vbeid: id },
      },
    });
  }

  //////update cache
  function UpdateDeleteCache(e) {
    let removeIds = [];
    e.removeFromList.forEach((o) => {
      removeIds.push(o.id);
    });

    let listusecasesCache = data.getUsecasesByListId.filter(
      (usecase) => !removeIds.includes(usecase.id)
    );

    client.writeQuery({
      query: UsecasesByListId,
      data: {
        getUsecasesByListId: {
          __typename: "usecase",
          getUsecasesByListId: listusecasesCache,
        },
      },
      variables: { input: { vbeid: id } },
    });
  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          {removeList ? (
            <VbridgeAlert
              severity="success"
              open={true}
              message="Deleted Successfully "
            />
          ) : null}
          {mutationError ? (
            <VbridgeAlert
              severity="error"
              open={true}
              message="Something went wrong ... "
            />
          ) : null}
        </Grid>
      </Grid>

      <Grid container md={12}>
        <Grid item md={12} style={{ marginBottom: "2%" }}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleDelete}
            disabled={checkValue ? false : true}
          >
            <DeleteOutlinedIcon />
            Remove From List
          </Button>
        </Grid>
      </Grid>

      {data ? (
        data.getUsecasesByListId.length > 0 ? (
          <Grid container className={classes.root}>
            <Grid item md={12}>
              <List className={classes.list}>
                {data ? (
                  data.getUsecasesByListId.map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value.vbeid}`;

                    return (
                      <ListItem key={value} divider dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="end"
                            onChange={handleToggle(value)}
                            checked={checked.indexOf(value) !== -1}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>

                        <ListItemAvatar>
                          <Avatar
                            className={classes.large}
                            variant="square"
                            alt={value.name}
                            src={VBRIDGEHUB_MEDIAURL + "/" + value.image}
                          >
                            {":("}
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          disableTypography={true}
                          style={{ padding: "2rem" }}
                          primary={
                            <Heading vbeid={value.vbeid} name={value.name} />
                          }
                          secondary={value.shortdescription}
                        />
                      </ListItem>
                    );
                  })
                ) : (
                  <Load />
                )}
              </List>
            </Grid>
          </Grid>
        ) : (
          <EmptyMessageList type="Use Cases" />
        )
      ) : null}
    </>
  );
}
