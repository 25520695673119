import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Tooltip,
} from '@material-ui/core'

import React, { useState } from 'react'

import { useStyles } from '../companies/Company2.0/shorts/styles'

import CompareIcon from '@material-ui/icons/Compare'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import CompareProductData from './CompareProductData'
import AddToProject from './addproducttoproject'
import './compare.css'

export default function CompareProduct({ products, disable, searchContext }) {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [checkedStatus, setCheckedStatus] = useState(true)
  const [newValue, setNewValue] = useState([])
  const [width, setWidth] = useState('md')
  //   const [selectedProducts, setSelectedProducts] = useState(products);

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(!open)
  }

  function checkedState(value) {
    setCheckedStatus(value)
  }

  function checkedList(value) {
    setNewValue(value)
  }

  const setWidthPaper = (props) => {
    if (props.length === 4) {
      setWidth('lg')
    } else if (props.length === 3) {
      setWidth('md')
    } else {
      setWidth('md')
    }
  }

  return (
    <>
      <div>
        {' '}
        <Button
          variant="outlined"
          color="primary"
          startIcon={<CompareIcon />}
          size="small"
          disabled={disable}
          onClick={handleClick}
          className={classes.addNewBtnComp}
        >
          Compare
        </Button>
      </div>

      {products.length <= 4 ? (
        <form className={classes.compareProducts}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            className={classes.formMainDiv}
            maxWidth={width}
            alignItems="center"
            scroll="paper"
          >
            <DialogTitle id="form-dialog-title" className={classes.formTitle}>
              Compare
              <Button className={classes.addNewBtn}>
                <HighlightOffIcon fontSize="small" onClick={handleClose} />
              </Button>
            </DialogTitle>

            <Divider />

            <DialogContent className={classes.formContent}>
              <DialogContentText>
                <div className={classes.evaluate}>
                  <div>
                    <Tooltip title="Select Only One Product to Create Project">
                      <AddToProject
                        productvbeid={newValue}
                        disable={newValue.length >= 1 ? false : true}
                        searchContext={searchContext}
                      />
                    </Tooltip>
                    {/* <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.addNewBtn}
                    disabled={newValue.length !== 0 ? false : true}
                    onClick={() => removeData(newValue)}
                  >
                    remove
                  </Button> */}
                  </div>
                </div>
              </DialogContentText>

              <div className={classes.croot}>
                <Grid container className={classes.widthSet}>
                  <Grid item>
                    <CompareProductData
                      productName={products}
                      setCheckedStatus={checkedState}
                      checkedList={checkedList}
                      setWidthPaper={setWidthPaper}
                    />
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
          </Dialog>
        </form>
      ) : (
        alert('You can compare only 4 Products at a time.')
      )}
    </>
  )
}
