import React from "react";

import { useParams } from "react-router-dom";

import {
  Grid,
  Divider,
  Typography,
  ListItemSecondaryAction,
  ListItemText,
  List,
  ListItemIcon,
  ListItem,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import CustomCkeditor from "../../../shared/customckeditor";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import UploadDoc from "../../../shared/uploaddocument";

import {
  deleteDocument,
  getLongDescImage,
  //   getProductInfoById,
  listDocs,
  postProduct,
  signed,
} from "./query";

import EmptyDiv from "../../companies/company/tabs/lists/emptyDiv";

import { useStyles } from "./styles";

import Load from "../../../shared/skeletons/skeleton";

export default function ProductOverview({
  isReadOnly,
  UpdateProductCache,
  isAdmin,
  type,
  setValu,
  info,
}) {
  const { id } = useParams();
  const client = useApolloClient();
  const classes = useStyles();

  let pageData = info?.productById;
  //   let imagePath = pageData.longdesimage;

  //list docs
  const { data: docs } = useQuery(listDocs, {
    variables: { input: { vbeid: id, type: type || "product" } },
  });

  //   const { loading, data: longDescImageData } = useQuery(getLongDescImage, {
  //     variables: {
  //       input: {
  //         method: "get",
  //         entity: "userimage",
  //         filetype: "image",
  //         filename: imagePath.split("/")[2],
  //         vbeid: imagePath.split("/")[0],
  //       },
  //     },
  //   });

  const [signedUrl] = useMutation(signed, {
    onCompleted: (e) => {
      window.open(e.signedURLforSupportDoc.signedurl, "_blank");
    },
  });

  const [deleteDocMutation] = useMutation(deleteDocument, {
    onCompleted: (e) => {
      updateDeleteCache(e);
    },
  });

  //   const { loading, data: productData } = useQuery(getProductInfoById, {
  //     variables: { input: { vbeid: id } },
  //   });

  //   if (type !== "service") {
  //     pageData = productData.productById;
  //   }

  let queryName = postProduct;
  //   if (type === "service") queryName = postService;

  const [postMutation] = useMutation(queryName, {
    onCompleted: (e) => {
      var longEDesc = e.updateProduct.longdescription;
      UpdateProductCache(longEDesc);
    },
  });

  const saveData = (data) => {
    postMutation({
      variables: {
        input: {
          longdescription: data,
          vbeid: id,
        },
      },
    });
  };

  ////update cache
  const updateCache = (paramName, e, paramName1, e1, paramName2, e2) => {
    let updatedDocs = {};

    updatedDocs[paramName] = e;
    updatedDocs[paramName1] = e1;
    updatedDocs[paramName2] = e2;

    let updatedCache = [...docs.listsupportDoc];
    updatedCache.unshift({ ...updatedDocs });

    client.writeQuery({
      query: listDocs,
      data: {
        __typename: "project",
        listsupportDoc: updatedCache,
      },

      variables: { input: { vbeid: id, type: type || "product" } },
    });
  };

  const createUrl = (e) => {
    signedUrl({
      variables: {
        input: {
          method: "get",
          key: e.target.parentElement.getAttribute("pathname"),
          vbeid: id,
          type: type || "product",
        },
      },
    });
  };

  const updateDeleteCache = (e) => {
    let updatedCache = [...docs.listsupportDoc];
    updatedCache = updatedCache.filter(
      (document) => document.id !== e.deleteSupportDoc.id
    );

    client.writeQuery({
      query: listDocs,
      data: {
        __typename: "project",
        listsupportDoc: updatedCache,
      },
      variables: { input: { vbeid: id, type: type || "product" } },
    });
  };

  const deleteDocumentQuery = (e) => {
    deleteDocMutation({
      variables: {
        input: {
          key: e.target.getAttribute("pathname"),
          vbeid: id,
          type: "product",
          timestamp: e.target.getAttribute("timestamp"),
        },
      },
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {pageData.vbeid === id &&
          (!info ? (
            <div className={classes.loadingDiv}>
              <Load />
            </div>
          ) : pageData?.longdescription !== "" &&
            pageData?.longdescription !== null ? (
            <CustomCkeditor
              data={pageData?.longdescription}
              editorId={id}
              isReadOnly={isReadOnly ? true : false}
              saveMethod={saveData}
            />
          ) : !isReadOnly ? (
            <CustomCkeditor
              data={""}
              editorId={id}
              isReadOnly={isReadOnly ? true : false}
              saveMethod={saveData}
            />
          ) : (
            //   ) : longDescImageData ? (
            //     <img
            //       src={longDescImageData.getSignedURL.signedurl}
            //       alt="longDescriptionImage"
            //     />
            <EmptyDiv type="description" />
          ))}
      </Grid>

      <Grid container className={classes.rootGrid}>
        {isReadOnly ? null : (
          <>
            <Grid item>
              <Typography variant="subtitle2" color="primary">
                {type === "service"
                  ? "Upload Service Brochure"
                  : "Upload Product Brochure"}
              </Typography>
            </Grid>

            <Grid item>
              <UploadDoc
                vbeid={id}
                buttonmessage="upload file"
                type="product"
                buttontype="icon"
                inputid={id + "productdoc"}
                icon={<CloudUploadIcon fontSize="small" />}
                updateCacheMethod={updateCache}
                paramName="id"
                paramName1="name"
                paramName2="signedurl"
              />
            </Grid>
          </>
        )}
      </Grid>

      {docs && docs.listsupportDoc.length !== 0 ? (
        <Grid item md={12}>
          <List className={classes.documentList}>
            <>
              <Divider />
              {docs.listsupportDoc.map((value) => {
                return (
                  <ListItem key={value.id}>
                    <ListItemIcon>
                      <AttachFileIcon fontSize="small" />
                    </ListItemIcon>

                    <ListItemText
                      pathname={value.signedurl}
                      onClick={createUrl}
                      className={classes.documentItem}
                    >
                      {value.name}
                    </ListItemText>

                    {isReadOnly ? null : (
                      <ListItemSecondaryAction>
                        <Grid container spacing={2}>
                          <Grid item>
                            <DeleteOutlinedIcon
                              fontSize="small"
                              pathname={value.signedurl}
                              timestamp={value.id}
                              onClick={deleteDocumentQuery}
                              className={classes.deleteIcon}
                            />
                          </Grid>
                        </Grid>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                );
              })}
            </>
          </List>
        </Grid>
      ) : null}
    </Grid>
  );
}
