import { Card, SwipeableDrawer } from "@material-ui/core";
import { useStyles } from "./styles";

export default function ResourceSlider({
  open,
  onClose,
  selectedResource,
  profileDetails,
}) {
  const classes = useStyles();

  const profileLink = profileDetails + "#toolbar=0&navpanes=0";

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      className={classes.actionDrawer}
      onClose={onClose}
    >
      <Card square className={classes.actionCard} elevation={0}>
        <iframe
          className={classes.iframe}
          title="document"
          src={profileLink}
          id="documentFrame"
        ></iframe>
      </Card>
    </SwipeableDrawer>
  );
}
