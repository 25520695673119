import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  square: {
    width: 100,
    height: 100,
  },

  ngrid: {
    marginTop: "-3%",
  },
}));

export default function Load() {
  const classes = useStyles();

  return (
    <>
      <Grid container justify="flex-start">
        <Grid item xs={2}>
          <Skeleton variant="rect" className={classes.square} />
        </Grid>

        <Grid item xs={10}>
          <Skeleton width="20%" />

          <Grid container>
            <Grid item xs={6}>
              <Skeleton width="30%" />
            </Grid>

            <Grid item xs={6} style={{ marginLeft: "-34%" }}>
              <Skeleton width="30%" />
            </Grid>
          </Grid>

          <Skeleton
            width="100%"
            height={100}
            variant="text"
            className={classes.ngrid}
          />
        </Grid>
      </Grid>
    </>
  );
}
