import React, { useState } from "react";

import { useQuery } from "@apollo/client";

import { Paper, Typography, Box, Grid } from "@material-ui/core";

// import MapGroupToTopic from "./maptopictogroup";
// import MapGroup from "./mapgroup";

import Policy from "../../shared/policy";

// import { userInfoQuery } from "./query";

import MainSkill from "./MainSkill";
import { useStyles } from "../topic2.0/styles";
import { userInfoQuery } from "../topic2.0/query";

export default function SkillPage({ toggleValue, drawerOpen }) {
  const classes = useStyles();

  const [isReadOnly, setReadonly] = useState(true);

  const afterLoadingMainData = (e) => {
    // setReadonly(e.getLoginUserInfo.isVbridgeUser === "false");
  };

  useQuery(userInfoQuery, {
    variables: { input: {} },
    onCompleted: (e) => {
      afterLoadingMainData(e);
    },
  });

  return (
    <div
      className={drawerOpen ? classes.drawerOpenTopicRoot : classes.topicRoot}
    >
      <Box m={5}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <MainSkill isReadOnly={isReadOnly} toggleValue={toggleValue} />
          </Grid>

          <Grid item xs={3}>
            {/* <Grid item md={12} className={classes.aligntopicslist}>
              <Paper elevation={1} square={true} className={classes.paperRoot}>
                <MapGroupToTopic isReadOnly={isReadOnly} />
              </Paper>
            </Grid> */}

            {/* {isReadOnly ? null : (
              <Grid item md={12} className={classes.addGroup}>
                <Paper elevation={1} square={true}>
                  <Typography
                    gutterBottom
                    variant="button"
                    style={{ textTransform: "capitalize" }}
                    className={classes.subHeading}
                    component="caption"
                    display="block"
                  >
                    Map Group
                  </Typography>

                  <MapGroup />
                </Paper>
              </Grid>
            )} */}

            <Policy />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
