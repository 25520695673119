import React, { useEffect, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import ProjectFormDailog from "./projectForm";

import { useMutation, gql, useQuery, useApolloClient } from "@apollo/client";

import { useFormik } from "formik";
import * as yup from "yup";

import VbridgeAlert from "../../shared/alert";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      textAlign: "center",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  createButton: {
    textTransform: "capitalize",
    float: "none",
  },
}));

export default function CreateProject({ variant, searchContext, refetch }) {
  const classes = useStyles();
  const client = useApolloClient();
  const timer = useRef();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearTimeout(timer.current);
    };
  }, []);

  const projectInput = {
    type:
      searchContext?.toLowerCase() === "products"
        ? "product"
        : searchContext?.toLowerCase() === "Services"
        ? "service"
        : "",
  };

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter your project Name")
      .min(5, "Project name should be of minimum 5 characters length")
      .required("Project name is required"),

    // description: yup
    //   .string("Enter description")
    //   .min(50, "Description should be of minimum 50 characters length"),
    //   .required("Description is required"),

    // timeline: yup.string("Timeline Required").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      //   description: "",
      //   timeline: "",
    },
    validationSchema: validationSchema,
  });

  const postproject = gql`
    mutation project($input: projectinputV1!) {
      createProjectV1(input: $input) {
        name
        id
      }
    }
  `;

  const [
    postProjectMutation,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(postproject, {
    onCompleted: (e) => {
      refetch();
      //   updateProjectCache(e);
    },
  });

  function CreateProject(e) {
    e.preventDefault();

    postProjectMutation({
      variables: {
        input: {
          name: formik.values.name,
          decisiontimeline: "",
          version: "v1",
          location: "",
          shortdescription: "",
          engagement_type: "-",
          type:
            searchContext.toLowerCase() === "products"
              ? "product"
              : searchContext.toLowerCase() === "services"
              ? "service"
              : "product",
        },
      },
    });

    handleClose();
  }

  //get user projects
  const userProjects = gql`
    query listUserProjects($input: projectinputV1!) {
      listProjectbyPersonV1(input: $input) {
        id
        name
        description
        vbeid
        projectstatus
        position
      }
    }
  `;

  useQuery(userProjects, {
    variables: {
      input: projectInput,
    },
  });

  function updateProjectCache(e) {
    const { listProjectbyPersonV1 } = client.readQuery({
      query: userProjects,
      variables: {
        input: projectInput,
      },
    });

    let listProjectsCache = [...listProjectbyPersonV1];
    listProjectsCache.unshift({ ...e.createProjectV1 });

    client.writeQuery({
      query: userProjects,
      data: {
        listProjectbyPersonV1: {
          __typename: "projectoutputV1",
          listProjectbyPersonV1: listProjectsCache,
        },
      },
      variables: {
        input: projectInput,
      },
    });
  }

  return (
    <div className={classes.root}>
      {mutationError && (
        <VbridgeAlert
          severity="error"
          open={true}
          message="Something went wrong."
        />
      )}

      {data && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="Your request has been completed successfully."
        />
      )}

      <Button
        color="primary"
        variant={variant}
        onClick={handleOpen}
        size="small"
        startIcon={<AddIcon fontSize="small" />}
        className={classes.createButton}
      >
        create project
      </Button>

      <ProjectFormDailog
        open={open}
        handleClose={handleClose}
        formik={formik}
        addmethod={CreateProject}
        mutationLoading={mutationLoading}
        title="Create Project"
        button="Add"
      />
    </div>
  );
}
