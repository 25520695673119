import React from 'react';

import {useStyles} from './styles'

import LinesEllipsis from "react-lines-ellipsis";

import {Grid, Typography} from '@material-ui/core';


  
export default function ProjectInfo({name, status, 
    description, timeline}) {
    const classes = useStyles();
    const [useEllipses, setUseEllipses] = React.useState(true);
  
    const onReadMore = () => {
      let prevState = useEllipses;
      setUseEllipses(!prevState);
    };
  
    
    function StatusMessage({ status }) {
        if (status === "INVITED") {
          return (
            <Typography
              variant="overline text"
              className={classes.statusInvitedLeadHead}
            >
              (Status: Invited)
            </Typography>
          );
        } else if (status === "APPROVED") {
          return (
            <Typography
              variant="overline text"
             className={classes.statusApprovedLeadHead}
            >
              (Status: Approved)
            </Typography>
          );
        } else if (status === "REJECTED") {
          return (
            <Typography
              variant="overline text"
              className={classes.statusRejectedLeadHead}
            >
              (Status: Rejected)
            </Typography>
          );
        } else if (status === "SUBMITTED") {
          return (
            <Typography
              variant="overline text"
              className={classes.statusApprovedLeadHead}
            >
              (Status: Proposal Submitted)
            </Typography>
          );
        } else if (status === "INVITEDFORDISCUSSION") {
          return (
            <Typography
              variant="overline text"
              className={classes.statusApprovedLeadHead}
            >
              (Status:Discussion Invite)
            </Typography>
          );
        }
      }
    

      
    return (
      <>
        <Grid container>
          <Grid item className={classes.leadHeadNameAndStatus}>
            <Grid container>
              <Typography variant="h5" className={classes.projectName}>
                {name + " "}
                {status ? <StatusMessage status={status} /> : null}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
  
        <Grid container spacing={1} className={classes.leadHeadTimelineTextAlign}>
          <Grid item>
            <Typography variant="caption" className={classes.readMore}>
              {" Indicative decision timeline: "} {timeline}
            </Typography>
          </Grid>
              
          
        </Grid>
  
        <div className={classes.leadHeadShortDescription}>
        {useEllipses ? (
              <div onClick={onReadMore}>
                <LinesEllipsis
                  text={description}
                  maxLine={2}
                  ellipsis=" ...Read More"
                  trimRight
                  basedOn="letters"
                  className={classes.question}
                />
              </div>
            ) : (
              <div className={classes.question}>
                {description}
                <button className="readLessBtn" onClick={onReadMore}>
                  Read Less
                </button>
              </div>
            )}
          </div>
      </>
    );
  }