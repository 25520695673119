import { useState } from "react";
import { useHistory, useParams } from "react-router";

import { Grid } from "@material-ui/core";

import { useStyles } from "./styles";

import {
  sectionProperties,
  textAreaProperties,
  textProperties,
  fileProperties,
  dropdownProperties,
} from "./components/properties";
import SectionComponent from "./components/SectionComponent";

import PrimaryButton from "../common/PrimaryButton";
import PreviewModal from "./PreviewModal";
import EditProperties from "./EditProperties";

import { useMutation, useQuery } from "@apollo/client";

import {
  addItem,
  addSection,
  deleteItem,
  deleteSection,
  editItem,
  editSection,
  getSectionsInTemplate,
} from "./query";

import VbridgeAlert from "../../shared/alert";

export default function CreateTemplate() {
  const classes = useStyles();

  const { id } = useParams();
  const history = useHistory();

  const template = history?.location?.state?.template;

  const { loading: loadingSections, data } = useQuery(getSectionsInTemplate, {
    variables: { input: { vbeid: template.vbeid } },
    fetchPolicy: "network-only",
  });

  const [formItems, setFormItems] = useState(data?.listSection || []);
  const [sectionItems, setSectionItems] = useState([]);
  const [propertyOpen, setPropertyOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);

  const [sectionValues, setSectionValues] = useState(false);

  if (data && !sectionValues) {
    let sections = data.listSection;

    let newSections = sections.map((section) => {
      if (!section.properties) {
        let sectionProps = JSON.parse(section.metadata);
        section = Object.assign({}, section, {
          properties: sectionProps,
          type: "section",
        });

        return section;
      } else return null;
    });

    setFormItems(newSections);
    setSectionValues(true);
  }

  const [
    addSectionMutation,
    { data: sectionData, loading: sectionLoading, error: sectionError },
  ] = useMutation(addSection, {
    onCompleted: (response) => {
      let responseSection = response.addSectionToTemplate[0];
      let sectionMeta = responseSection.metadata;
      let sectionData = JSON.parse(sectionMeta);

      let currentItems = sectionItems.filter((item) => {
        return item.section === sectionData.sectionId;
      });

      onAddItemToSection(responseSection, currentItems);
    },
  });

  const [
    editFieldMutation,
    { data: itemEdit, loading: itemEditLoading, error: editItemError },
  ] = useMutation(editItem);

  const [deleteItemMutation] = useMutation(deleteItem);

  const [deleteSectionMutation] = useMutation(deleteSection);

  const [
    editSectionMutation,
    { data: editData, loading: editLoading, error: editError },
  ] = useMutation(editSection, {
    onCompleted: (response) => {
      let responseSection = response.editSection;

      let currentItems = sectionItems.filter((item) => {
        return item.section === responseSection.id;
      });

      let newItems = [];
      let editItems = [];

      currentItems.forEach((item) => {
        if (typeof item.id === "number") {
          newItems.push(item);
        } else {
          editItems.push(item);
        }
      });

      if (newItems.length !== 0) {
        onAddItemToSection(responseSection, newItems);
      }

      if (editItems.length !== 0) {
        editItems.forEach((item) => {
          onEditItemInSection(item);
        });
      }
    },
  });

  const onAddItemToSection = (responseSection, currentItems) => {
    let itemsInputVariables = [];

    const { vbeid, id } = responseSection;

    currentItems.forEach((item) => {
      const { properties, type } = item;

      itemsInputVariables.push({
        name: properties.label,
        helpmessage: properties.helpMessage,
        type: type,
        vbeid: vbeid,
        timestamp: id,
        metadata: JSON.stringify(properties),
      });
    });

    addFieldMutation({
      variables: {
        input: itemsInputVariables,
      },
    });
  };

  const onEditItemInSection = (editItem) => {
    const { id, properties, type, itemId } = editItem;

    editFieldMutation({
      variables: {
        input: {
          vbeid: id,
          timestamp: itemId,
          metadata: JSON.stringify(properties),
          helpmessage: properties.helpMessage,
          name: properties.label,
          type: type,
        },
      },
    });
  };

  const [addFieldMutation, { data: itemData, error: itemError }] = useMutation(
    addItem,
    {
      onCompleted: (response) => {
        //   history.push("/templates");
      },
    }
  );

  const onAdd = (item) => {
    let element = {
      type: item,
      properties:
        item === "text"
          ? textProperties
          : item === "textarea"
          ? textAreaProperties
          : item === "section"
          ? sectionProperties
          : item === "dropdown"
          ? dropdownProperties
          : item === "file"
          ? fileProperties
          : "",
      id: Math.floor(Math.random() * 10000),
    };
    setFormItems((prevList) => [...prevList, element]);
  };

  const onAddItem = (type, item) => {
    let itemType = type;
    let element = {};

    if (typeof type === "object") {
      itemType = type.type;

      element = {
        type: itemType,
        properties: JSON.parse(type.metadata),
        id: type.vbeid,
        section: type.id,
        itemId: type.itemId,
      };

      setSectionItems((prevList) => [...prevList, element]);
    } else {
      element = {
        type: itemType,
        properties:
          itemType === "text"
            ? textProperties
            : itemType === "textarea"
            ? textAreaProperties
            : itemType === "file"
            ? fileProperties
            : itemType === "dropdown"
            ? dropdownProperties
            : "",
        id: Math.floor(Math.random() * 10000),
        section: item.id,
      };

      setSectionItems((prevList) => [...prevList, element]);
    }
  };

  const onRemove = (item, index) => {
    console.log("item", item);
    if (item.vbeid) {
      let itemList = formItems;

      deleteSectionMutation({
        variables: {
          input: {
            vbeid: item.vbeid,
            timestamp: item.id,
          },
        },
      });

      setFormItems([...itemList.slice(0, index), ...itemList.slice(index + 1)]);
    } else {
      if (item.type === "section") {
        let itemList = formItems;

        setFormItems([
          ...itemList.slice(0, index),
          ...itemList.slice(index + 1),
        ]);
      }
    }
  };

  const onRemoveItem = (type, item) => {
    if (typeof type === "object") {
      let index = sectionItems.findIndex((obj) => obj.itemId === type.itemId);
      let itemList = sectionItems;

      deleteItemMutation({
        variables: {
          input: {
            vbeid: type.id,
            timestamp: type.itemId,
          },
        },
      });

      setSectionItems([
        ...itemList.slice(0, index),
        ...itemList.slice(index + 1),
      ]);
    } else {
      let index = sectionItems.findIndex((obj) => obj.id === type.id);

      let itemList = sectionItems;
      setSectionItems([
        ...itemList.slice(0, index),
        ...itemList.slice(index + 1),
      ]);
    }
  };

  const onEditProperties = (item) => {
    setSelectedItem(item);
    setPropertyOpen(true);
  };

  const onEditItemProps = (item) => {
    setSelectedItem(item);
    setPropertyOpen(true);
  };

  const onSave = (label, max, file, option, custom, helpMessage, item) => {
    if (item.type === "section") {
      let listItems = [...formItems];

      let newProperties = { label: label, helpMessage: helpMessage };

      let objIndex = listItems.findIndex((obj) => obj.id === selectedItem.id);
      listItems[objIndex].properties = newProperties;

      let newArray = [
        ...listItems.slice(0, objIndex),
        Object.assign({}, listItems[objIndex]),
        ...listItems.slice(objIndex + 1),
      ];

      setFormItems(newArray);
      setSelectedItem("");
      setPropertyOpen(false);
    } else {
      let itemIndex;
      let sectionFields = sectionItems.filter((field, index) => {
        if (field.section === item.section) {
          itemIndex = index;
          return field.section === item.section;
        }
      });

      let newProperties = { label: label, max: max, helpMessage: helpMessage };

      if (item.type === "file") {
        newProperties.fileType = file;
      }

      if (item.type === "dropdown") {
        newProperties.options = option;

        if (option === "custom") {
          newProperties.custom = custom;
        }
      }

      let objIndex = sectionFields.findIndex(
        (obj) => obj.id === selectedItem.id && item.type === obj.type
      );

      sectionFields[objIndex].properties = newProperties;

      let newArray = [
        ...sectionFields.slice(0, objIndex),
        Object.assign({}, sectionFields[objIndex]),
        ...sectionFields.slice(objIndex + 1),
      ];

      let newSectionItems = [
        ...sectionItems.slice(0, itemIndex),
        Object.assign({}, newArray[objIndex]),
        ...sectionFields.slice(itemIndex + 1),
      ];

      setSectionItems(newSectionItems);
      setSelectedItem("");
      setPropertyOpen(false);
    }
  };

  const onClose = () => {
    setSelectedItem([]);
    setPropertyOpen(false);
  };

  const getFormItems = () => {
    return formItems.map((item, index) => {
      let sectionProps = item?.properties;

      return (
        <SectionComponent
          section={item}
          properties={sectionProps}
          onRemove={() => onRemove(item, index)}
          onEdit={() => onEditProperties(item)}
          onAddItem={(type) => onAddItem(type, item)}
          items={sectionItems.filter((field) => {
            if (typeof field.id === "number") {
              return field.section === item.id;
            } else {
              let itemVbeid = field.id.split("#");
              return itemVbeid[3] === item.id;
            }
          })}
          onRemoveItem={(type) => onRemoveItem(type, item)}
          onEditItemProps={onEditItemProps}
        />
      );
    });
  };

  const onPreview = () => {
    setPreviewOpen(true);
  };

  const handleClose = () => {
    setPreviewOpen(false);
  };

  const onSaveTemplate = () => {
    formItems.forEach((item) => {
      if (item.vbeid) {
        const { vbeid, id, properties } = item;
        const { label, helpMessage } = properties;

        editSectionMutation({
          variables: {
            input: {
              name: label,
              helpmessage: helpMessage,
              vbeid: vbeid,
              timestamp: id,
              metadata: JSON.stringify(item.properties),
            },
          },
        });
      } else {
        item.properties.sectionId = item.id;
        const { label, helpMessage } = item.properties;

        addSectionMutation({
          variables: {
            input: [
              {
                name: label,
                helpmessage: helpMessage,
                vbeid: id,
                metadata: JSON.stringify(item.properties),
              },
            ],
          },
        });
      }
    });
  };

  const onBack = () => {
    history.push("/templates");
  };

  return (
    <div className={classes.root}>
      {(sectionError || editItemError || editError || itemError) && (
        <VbridgeAlert
          severity="error"
          open={true}
          message="Something went wrong! Please try again later."
        />
      )}

      {(loadingSections ||
        sectionLoading ||
        itemEditLoading ||
        editLoading) && (
        <VbridgeAlert severity="info" open={true} message="Please wait" />
      )}

      {(sectionData || itemEdit || editData || itemData) && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="Template saved successfully"
        />
      )}

      <div className={classes.previewBtn}>
        <PrimaryButton
          label="Preview"
          variant="contained"
          onClick={() => onPreview()}
        />

        <PrimaryButton
          label="Save"
          variant="contained"
          onClick={() => onSaveTemplate()}
        />

        <div style={{ float: "left" }}>
          <PrimaryButton
            label="Back to Templates"
            variant="outlined"
            onClick={() => onBack()}
          />
        </div>
      </div>

      <Grid container className={classes.formDiv}>
        <Grid item md={12}>
          <PrimaryButton
            label="Add Section"
            variant="contained"
            onClick={() => onAdd("section")}
          />
        </Grid>

        <Grid item md={12} className={classes.sectionDiv}>
          <div className={classes.itemList}>
            {formItems.length !== 0 ? getFormItems() : "Add items here"}
          </div>
        </Grid>
      </Grid>

      {propertyOpen && (
        <EditProperties
          open={propertyOpen}
          item={selectedItem}
          onEdit={onSave}
          onClose={onClose}
        />
      )}

      <PreviewModal
        open={previewOpen}
        template={template}
        handleClose={handleClose}
        sectionsData={formItems}
        itemsData={sectionItems}
      />
    </div>
  );
}
