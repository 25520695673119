import { Button, CircularProgress } from "@material-ui/core";
import { useStyles } from "../../Agreements/styles";

export default function OutlinedButton({
  disabled,
  onClick,
  label,
  type,
  className,
}) {
  const classes = useStyles();

  return (
    <Button
      size="small"
      className={classes[`${className}`] || ""}
      variant={type === "contained" ? "contained" : "outlined"}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      startIcon={
        label === "Redirecting" || label === "Downloading" || label === "" ? (
          <CircularProgress className={classes.loadingBtn} color="#fff" />
        ) : null
      }
    >
      {label}
    </Button>
  );
}
