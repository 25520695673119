import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  helpBox: {
    padding: "16px",
  },

  mainDiv: {
    margin: "2px",
    display: "grid",
  },

  btn: {
    padding: "4px",
    justifyContent: "flex-start !important",

    "& span": {
      fontSize: "12px",
      fontWeight: "500",
      textTransform: "none",
      color: "#0e76a8",
    },

    "& p": {
      fontSize: "12px",
    },
  },

  ibtn: {
    padding: "4px",
    justifyContent: "flex-start !important",

    "& span": {
      fontSize: "12px",
      fontWeight: "500",
      textTransform: "none",
      color: "#0e76a8",
    },

    "& p": {
      fontSize: "12px",
    },
  },

  contactUs: {
    margin: "2px",
    padding: "8px",
    display: "grid",

    "& svg": {
      fontSize: "0.8rem",
      fontWeight: 800,
    },
  },

  contact: {
    fontSize: "12px",
    fontWeight: "500",
  },

  contactInfo: {
    fontSize: "12px",
    fontWeight: "400",
  },
  title: {
    padding: "8px 24px",

    "& h2": {
      fontSize: "16px",
    },
  },
  stmt: {
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "12px !important",
    marginLeft: theme.spacing(1.4),
  },
  inlay: {
    "& span": {
      fontSize: "12px",
      fontWeight: "600",
    },

    "& label": {
      fontSize: "14px",
      fontWeight: "500",
    },
  },

  TextField: {
    "& textarea": {
      fontSize: "14px",
      fontWeight: 400,
    },
    "& span": {
      fontSize: "12px",
    },
  },

  hbtn: {
    padding: "8px",
    "& span": {
      fontSize: "12px",
    },
  },

  reqBtn: {
    margin: "0px 0px 0px 0px",
    padding: "0px",
    textTransform: "none",
    minWidth: "100px",

    "& span": {
      padding: "0px 2px",
      fontSize: "8px",
      justifyContent: "center !important",
    },
  },

  HelpIcon: {
    cursor: "pointer",
    padding: "0px 8px",
    display: "flex",
    alignItems: "center",
    marginRight: "12px",
  },
  snackbar: {
    backgroundColor: "#00D2BC !important",
  },

  close: {
    cursor: "pointer",
  },
  mailicon: {
    cursor: "pointer",
  },

  errorfields: {
    color: "red",
    fontSize: "10px",
  },

  inviteinlay: {
    padding: "0px 0px 6px 12px",

    "& span": {
      fontSize: "12px",
      fontWeight: "600",
    },

    "& label": {
      fontSize: "14px",
      fontWeight: "500",
    },
  },

  inviteBtn: {
    padding: "0px",
  },

  message: {
    padding: "0px 0px 6px 16px",
    fontSize: "10px",
    fontWeight: 400,
    marginBottom: "8px !important",
  },
}));
