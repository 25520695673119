import { makeStyles } from "@material-ui/core";
import DiscussionCard from "../../../messaging2.0/discussionCard";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

export default function Concierge({ projectVbeid }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DiscussionCard
        productId={"concierge0001"}
        projectId={projectVbeid}
        type="question"
      />
    </div>
  );
}
