import * as React from "react";

import { useParams } from "react-router-dom";

import { Grid } from "@material-ui/core";

import { useMutation, useQuery } from "@apollo/client";

import {
  GET_PRODUCTS_BY_PROJECT,
  REMOVE_PRODUCT_FROM_PROJECT,
} from "../../queries/query";

import SearchResultCard from "../../../home/SearchResultCard";

export default function VendorList({ projectData, review }) {
  const { id } = useParams();

  const { data, refetch } = useQuery(GET_PRODUCTS_BY_PROJECT, {
    fetchPolicy: "cache-and-network",
    variables: { input: { vbeid: id } },
  });

  let products = [];

  if (data) {
    data.getProductsByProjectIDV1.forEach((product) => {
      if (product.id !== "concierge0001") {
        products.push(product);
      }
    });
  }

  const [removeMember] = useMutation(REMOVE_PRODUCT_FROM_PROJECT, {
    onCompleted: (e) => {
      refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const removeSupplier = (vbeid) => {
    removeMember({
      variables: { input: { vbeid: id, listvbeid: [vbeid] } },
    });
  };

  return (
    <Grid container>
      <Grid container xs={review ? 9 : 12}>
        {data ? (
          products.length !== 0 ? (
            products.map((product) => {
              return (
                <Grid item md={5}>
                  <SearchResultCard
                    product={product.productvbeid}
                    type="project"
                    evaluateAccess={false}
                    onRemove={() => removeSupplier(product.productvbeid)}
                  />
                </Grid>
              );
            })
          ) : (
            <div
              style={{
                fontSize: "0.7rem",
                fontStyle: "italic",
                color: "#999",
                marginTop: "8px",
              }}
            >
              No suppliers are added to the project. To add suppliers to the
              project, use the search bar or product/services supplier listing
              on the top menu to review and Add to Project.
            </div>
          )
        ) : null}
      </Grid>
    </Grid>
  );
}
