import * as React from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import { Button, Grid, IconButton, TextField, Typography ,Box,Divider} from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

import { FormControl } from '@material-ui/core';
import Select from '@material-ui/core/Select';


import Drawer from '@material-ui/core/Drawer';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useFormik } from 'formik';

import { useMutation,useApolloClient } from "@apollo/client";
import { DELETE_ITEM, EDIT_ITEM, GET_ITEMS_BY_SECTION } from '../../../queries/query';
import Checkbox from '@material-ui/core/Checkbox';
import {FormControlLabel} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root:{
        marginTop:theme.spacing(1)
    },
    textfield:{
        height:theme.spacing(100)
    },
    properties:{
        width: theme.spacing(50),
      },
      propertiestitle:{
          marginBottom:theme.spacing(2)
      },
      propsbuttongroups:{
          marginTop:theme.spacing(2)
      },
      propsbutton:{
          margin:theme.spacing(1)
      },
      helpicon:{
         fontSize: theme.spacing(1.5),
         cursor:'pointer'
      },
      select:{
        height:theme.spacing(4.5)
    },
    checkbox:{
        '& span':{
 
         fontSize: theme.spacing(1.4)
        }
    }
}))


function PropertiesForm(props)
{
    const classes = useStyles();

    const [editItem, { data, loading, error }] = useMutation(EDIT_ITEM,{
        onCompleted: (e) => {
         // console.log(e)
        }});

    const formik = useFormik({
        initialValues: props.metadata,
        onSubmit: values => {
            props.setMetadata(values)
            props.setState(false)
            let input = {sectionid:props.sid,id:props.id,metadata:JSON.stringify(values)}   
            editItem(({variables: {input:input}}))
          }
    });

    function onClose()
    {
        props.setState(false)
    }

    return(
        
    <Box m={2} className= {classes.properties}>
         <form onSubmit={formik.handleSubmit}>
        <Grid container>
            <Grid item xs={12} className= {classes.propertiestitle}>
            <Typography variant="subtitle" gutterBottom >Properties </Typography>
            <Divider/>
            </Grid>

        </Grid>
        
         <Grid item xs={12}>
            <Typography variant="caption" gutterBottom > Title </Typography>
            
            </Grid>
            <Grid item xs={12}>
            <TextField name="title" id="filled-full-width"  placeholder='Enter title' fullWidth  variant="outlined"  size="small"  onChange={formik.handleChange}
         value={formik.values.title}/>
            </Grid>
            <Grid item xs={12}>
            <Typography variant="caption" gutterBottom > Options</Typography>

            </Grid>

            <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth >
        <Select name="options" native className={classes.select} value={formik.values.options} onChange={formik.handleChange}>
           <option  >Choose One</option>
            <option  value="1,2,3,4,5,6,7,8,9,10" >1 to 10</option>
            <option  value="1,2,3,4,5" >1 to 5</option>
            <option  value="Yes,No" >Yes/No</option>
            <option  value="True,False" >True/False</option>
            <option  value="Accept,Reject" >Accept/Reject</option>
            <option  value="Agree,Disagree" >Agree/Disagree</option>
            <option  value="Mandatory,Optional" >Mandatory/Optional</option>
            
        </Select>
      
</FormControl>

            </Grid>

            <Grid item xs={12}>
            <Typography variant="caption" gutterBottom > Help Message </Typography>

            </Grid>
            <Grid item xs={12}>
            <TextField name="help_message" multiline rows={4} id="filled-full-width"   fullWidth  variant="outlined"  size="small" onChange={formik.handleChange}
         value={formik.values.help_message} />
            </Grid>
            <Grid item xs={12}>
       
          <FormControlLabel
          className={classes.checkbox}
         
          control={<Checkbox size='small' color="primary" checked={formik.values.remarks} name='remarks' onChange={formik.handleChange}/>}
          label="Enable Remarks"
          labelPlacement="end"
        />
            </Grid>


            <Grid item xs={12} className={classes.propsbuttongroups}>
                
                <Button variant="outlined"  startIcon={<CloseIcon/>} size="small" color="secondary" className={classes.propsbutton} onClick={onClose}>Cancel</Button>  
                <Button variant="outlined" type="submit" startIcon={<SaveIcon />} size="small" color="secondary" className={classes.propsbutton}>Save</Button>
            </Grid>
            </form>
    </Box>
    )
}


export default function SelectItem(props)
{

    const client = useApolloClient();
    const classes = useStyles();
    const [state,setState] = React.useState(false)
    const [preview,setPreview] = React.useState(props.preview)

    const [metadata,setMetadata] = React.useState(props.metadata)
    
    const [deleteItem] = useMutation(DELETE_ITEM,{
        onCompleted: (e) => {
          console.log(e)
        }});


    function deleteItemFromSection()
    { 
       
        const {listItem}  = client.readQuery({
            query: GET_ITEMS_BY_SECTION,
            variables:{input: { sectionid:props.sid }}
          });

          console.log(listItem)

          var newlist =  listItem.filter(item => item.id!=props.id)

          client.writeQuery({
            query: GET_ITEMS_BY_SECTION,
            data: {
                listItem:newlist
            },
            variables:{input: { sectionid:props.sid }}
          });

          deleteItem({variables: {input:{'sectionid':props.sid,'id':props.id}}})


    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState(!open);
      };


    return(
     
        <Grid container alignItems="center" className={classes.root} spacing={1}>
               <Drawer anchor={'left'} open={state} onClose={toggleDrawer(false)}>
         <PropertiesForm setState={setState} metadata={metadata} setMetadata={setMetadata} sid={props.sid} id={props.id}/>

         </Drawer>
            <Grid item xs={4}>
            <Typography variant="caption" gutterBottom > {metadata.title} </Typography>

            </Grid>
            <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth >
            <Select  native className={classes.select} disabled={props.disabled}>

            <option aria-label="None" value="" >Choose Any </option>
            {
            metadata.options.split(",").map((option)=>{

                return (
                    <option value={option}>{option}</option>
                   
                )
            })
         

            }
            </Select>
    
            </FormControl>
            </Grid>
            <Grid item xs={3}>
   { metadata.remarks?        <TextField  id="filled-full-width"  placeholder='Remarks' fullWidth  variant="outlined"  size="small" disabled={props.disabled}/>:null}
            </Grid>

            <Grid item xs={1}>
            {preview?null:<>
            <IconButton size='small'><DeleteIcon fontSize='small' onClick={deleteItemFromSection}/></IconButton>
            <IconButton size='small' onClick={()=>{setState(true)}}><EditIcon fontSize='small'/></IconButton>
            </>}

            </Grid>

        </Grid>
    )


}