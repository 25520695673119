import gql from "graphql-tag";

export const CREATE_TEMPLATE = gql`
  mutation createTemplate($input: templateinput!) {
    createTemplate(input: $input) {
      vbeid
      name
      shortdescription
      type
      for
    }
  }
`;

export const ADD_SECTION = gql`
  mutation section($input: templateinput!) {
    addSectionToTemplate(input: $input) {
      id
      name
      type
      metadata
    }
  }
`;

export const ADD_ITEM_SECTION = gql`
  mutation item($input: templateinput!) {
    addItemtoSection(input: $input) {
      id
      vbeid
    }
  }
`;

export const DELETE_ITEM = gql`
  mutation item($input: templateinput!) {
    deleteItem(input: $input) {
      id
    }
  }
`;

export const DELETE_SECTION = gql`
  mutation section($input: templateinput!) {
    deleteSection(input: $input) {
      id
    }
  }
`;

export const EDIT_SECTION = gql`
  mutation section($input: templateinput!) {
    editSection(input: $input) {
      id
      metadata
    }
  }
`;

export const EDIT_ITEM = gql`
  mutation item($input: templateinput!) {
    editItem(input: $input) {
      id
      metadata
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation template($input: templateinput!) {
    updateTemplate(input: $input) {
      vbeid
      status
    }
  }
`;

export const GET_DIVERSITY_LIST = gql`
  query getDiversityList($input: genericinput) {
    listDiversity(input: $input) {
      id
      vbeid
      name
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation project($input: projectinputV1!) {
    createProjectV1(input: $input) {
      vbeid
    }
  }
`;

export const CREATE_SERVICE_PROJECT = gql`
  mutation createServiceProject($input: projectinputV1) {
    addServiceProject(input: $input) {
      vbeid
    }
  }
`;

export const SUBMIT_PROJECT = gql`
  mutation project($input: projectinputV1!) {
    submitProjectV1(input: $input) {
      responsestatus
    }
  }
`;

export const SUBMIT_SERVICE_PROJECT = gql`
  mutation submitProject($input: projectinputV1!) {
    submitServiceProject(input: $input) {
      responsestatus
    }
  }
`;

export const VENDOR_SUBMIT_PROJECT = gql`
  mutation submitProject($input: projectinputV1!) {
    submitProposalV1(input: $input) {
      responsestatus
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation project($input: projectinputV1!) {
    updateProjectV1(input: $input) {
      vbeid
    }
  }
`;

export const REMOVE_PRODUCT_FROM_PROJECT = gql`
  mutation removeProduct($input: projectinputV1!) {
    removeProductsformProjectV1(input: $input) {
      responsestatus
    }
  }
`;

export const REMOVE_COMPANY_FROM_PROJECT = gql`
  mutation removeCompany($input: projectinputV1!) {
    removeCompanyFromProject(input: $input) {
      responsestatus
    }
  }
`;

export const MAP_TEMPLATE_TO_PROJECT = gql`
  mutation mapTemplate($input: projectinputV1!) {
    addTemplateToProject(input: $input) {
      responsestatus
    }
  }
`;

export const UPDATE_REQUIREMENT_ITEM = gql`
  mutation template($input: [projectinputV1!]) {
    addReqTemplateItemvalue(input: $input) {
      id
      value
      remarks
      timestamp
    }
  }
`;

export const LIST_CONTRACTITEMS_ITEM = gql`
  query template($input: projectinputV1!) {
    getContractTempItemvalue(input: $input) {
      id
      value
      remarks
      timestamp
      attachmentfilename
      attachment
    }
  }
`;

export const UPDATE_QA_ITEM = gql`
  mutation template($input: [projectinputV1!]) {
    addQuestionTempItemvalue(input: $input) {
      id
      value
      remarks
      timestamp
    }
  }
`;

export const UPDATE_SOW_ITEM = gql`
  mutation template($input: [projectinputV1!]) {
    addAwardTempItemvalue(input: $input) {
      id
      productvbeid
      value
      sectionid
      timestamp
    }
  }
`;

export const UPDATE_CONTRACT_ITEM = gql`
  mutation template($input: [projectinputV1!]) {
    addContractTempItemvalue(input: $input) {
      id
      value
      remarks
      timestamp
    }
  }
`;

export const UPDATE_EVALUATION_ITEM = gql`
  mutation template($input: [projectinputV1!]) {
    addEvaluationTempItemvalue(input: $input) {
      id
      value
      remarks
      timestamp
    }
  }
`;

export const GET_TIME_MONEY = gql`
  query timeMoney($input: projectinputV1!) {
    getTimeMaterialItemvalue(input: $input) {
      id
      vbeid
      timestamp
      productvbeid
      data
    }
  }
`;

export const GET_PRODUCT_COST = gql`
  query productcost($input: projectinputV1!) {
    getProductCostItemvalue(input: $input) {
      id
      vbeid
      timestamp
      productvbeid
      data
    }
  }
`;

export const GET_FIXED_COST = gql`
  query fixedcost($input: projectinputV1!) {
    getFixedPriceItemvalue(input: $input) {
      id
      vbeid
      timestamp
      productvbeid
      data
    }
  }
`;

export const UPDATE_TIME_MONEY = gql`
  mutation timeMoney($input: [projectinputV1!]) {
    addTimeMaterialItemvalue(input: $input) {
      id
      vbeid
      productvbeid
      data
    }
  }
`;

export const UPDATE_PRODUCT_COST = gql`
  mutation productcost($input: [projectinputV1!]) {
    addProductCostItemvalue(input: $input) {
      id
      vbeid
      productvbeid
      data
    }
  }
`;

export const UPDATE_FIXED_COST = gql`
  mutation fixedcost($input: [projectinputV1!]) {
    addFixedPriceItemvalue(input: $input) {
      id
      vbeid
      productvbeid
      data
    }
  }
`;

export const UPDATE_RESPONSE_ITEM = gql`
  mutation template($input: [projectinputV1!]) {
    addResTempItemvalue(input: $input) {
      id
      value
      remarks
      timestamp
    }
  }
`;

export const SIGNED_URL = gql`
  mutation signedurl($input: templateinput!) {
    gettemplateSignedURL(input: $input) {
      attachment
      signedurl
    }
  }
`;

export const REQUEST_FOR_CONCIERGE = gql`
  mutation concierge($input: projectinput) {
    RequestforConcierge(input: $input) {
      id
    }
  }
`;

export const ADD_TEAM_MEMBER = gql`
  mutation team_member($input: projectinputV1!) {
    addTeammemberV1(input: $input) {
      personvbeid
      personname
      image
      role
    }
  }
`;

export const REMOVE_TEAM_MEMBER = gql`
  mutation team_member($input: projectinputV1!) {
    removeTeammemberV1(input: $input) {
      responsestatus
    }
  }
`;

export const LIST_PROJECT_TEAM_MEMBERS = gql`
  query team_members($input: projectinputV1!) {
    getTeammembersByProjectIDV1(input: $input) {
      id
      vbeid
      personvbeid
      personemailid
      personname
      image
      relationvbeid
      status
      role
    }
  }
`;

export const GET_PROJECT = gql`
  query project($input: projectinputV1!) {
    getProjectByIDV1(input: $input) {
      vbeid
      name
      decisiontimeline
      type
      shortdescription
      version
      country
      state
      conciergestatus
      engagement_type
      role
      worklocation
      resourcecount
      workexperience
      workduration
      workstartby
      budget
      diversity
      profilereceivingdeadline
      indicativeinterviewschedule
      jobdescription
      primaryskill
      expinprimaryskill
      secondaryskill
      otherrequirement
      jobdescriptionfile
      currency
      companyname
      image
      PK
      SK
    }
  }
`;

export const AI_SEARCH = gql`
  query search($input: esinput!) {
    openAIsearch(input: $input) {
      label
    }
  }
`;

export const GET_TOPIC = gql`
  query topic($input: id) {
    queryTopicbyid(input: $input) {
      vbeid
      description
      image
      name
    }
  }
`;

export const GET_TEMPLATE_BY_PROJECT = gql`
  query template($input: projectinputV1!) {
    getTemplatesByProjectID(input: $input) {
      vbeid
      name
      shortdescription
      for
      type
      createdby
    }
  }
`;

export const GET_PRODUCTS_BY_PROJECT = gql`
  query products($input: projectinputV1!) {
    getProductsByProjectIDV1(input: $input) {
      id
      productvbeid
      productname
      image
      vbeid
      role
      name
      description
      savedrdversion
      companyname
      companyvbeid
    }
  }
`;

export const GET_COMPANIES_BY_PROJECT = gql`
  query companies($input: projectinputV1!) {
    listCompaniesByProjectID(input: $input) {
      id
      image
      vbeid
      name
      shortdescription
      location
      role
      employeeCount
    }
  }
`;

export const LIST_CANDIDATES = gql`
  query getCandidatesList($input: projectinputV1!) {
    listResources(input: $input) {
      vbeid
      name
      status
      attachment
      attachmentfilename
      primaryskill
      billingrate
      candidateavailableby
      currency
      emailId
      createdon
      experience
      resourceid
      vendorid
      remarks
      relocate
      secondaryskill
      additionalInformation
      revisedbillingrate
      baseLocation
      companyname
      image
      engagementStartDate
      engagementEndDate
      PK
      SK
    }
  }
`;

export const GET_RESOURCE_LOGS = gql`
  query getResourceLogs($input: projectinputV1!) {
    getResourceLogs(input: $input) {
      createdon
      status
      remarks
    }
  }
`;

export const UPDATE_RESOURCE_STATUS = gql`
  mutation updateStatus($input: projectinputV1!) {
    updateResourceStatus(input: $input) {
      responsestatus
    }
  }
`;

export const UPDATE_RESOURCE = gql`
  mutation updateResource($input: projectinputV1!) {
    updateResource(input: $input) {
      responsestatus
    }
  }
`;

export const GET_COMPANIES_BY_SKILL = gql`
  query companiesBySkill($input: companyinput) {
    getCompaniesbySkills(input: $input) {
      name
      vbeid
      shortdescription
      image
      location
      existingvendor
    }
  }
`;

export const GET_AWARD_TEMPLATE = gql`
  query template_products($input: projectinputV1!) {
    getTemplatesByProjectID(input: $input) {
      vbeid
      name
      shortdescription
      for
      type
      createdby
    }
  }
`;

export const GET_TEMPLATE_PRODUCTS_BY_PROJECT = gql`
  query template_products($input: projectinputV1!) {
    getTemplatesByProjectID(input: $input) {
      vbeid
      name
      shortdescription
      for
      type
      createdby
    }
    getProductsByProjectIDV1(input: $input) {
      id
      productvbeid
      productname
      image
      vbeid
      role
      name
      description
      savedrdversion
      companyname
    }
  }
`;

export const AWARD_PROJECT_TO_PRODUCT = gql`
  mutation awardProject($input: projectinputV1!) {
    addAwardedProjectToProductV1(input: $input) {
      responsestatus
    }
  }
`;

export const GET_TEMPLATES = gql`
  query listTemplates($input: templateinput!) {
    listTemplates(input: $input) {
      id
      vbeid
      name
      createdon
      createdby
      for
      shortdescription
      type
      status
    }
  }
`;

export const GET_ITEMS_BY_SECTION = gql`
  query items($input: templateinput!) {
    listItem(input: $input) {
      id
      vbeid
      type
      metadata
    }
  }
`;

export const LIST_REQUIREMENT_SECTIONS = gql`
  query items($tinput: templateinput!, $pinput: projectinputV1!) {
    listItem(input: $tinput) {
      id
      vbeid
      type
      metadata
    }

    getRequirementDocument(input: $pinput) {
      itemid
      value
      timestamp
      attachment
      attachmentfilename
      remarks
    }
  }
`;

export const LIST_RESPONSE_SECTIONS = gql`
  query items($tinput: templateinput!, $pinput: projectinputV1!) {
    listItem(input: $tinput) {
      id
      vbeid
      type
      metadata
    }

    getResTempItemvalue(input: $pinput) {
      itemid
      value
      productvbeid
      timestamp
      attachment
      attachmentfilename
      remarks
    }
  }
`;

export const LIST_EVALUATION_SECTIONS = gql`
  query items($tinput: templateinput!, $pinput: projectinputV1!) {
    listItem(input: $tinput) {
      id
      vbeid
      type
      metadata
    }

    getEvaluationTempItemvalue(input: $pinput) {
      itemid
      value
      timestamp
      productvbeid
      attachment
      attachmentfilename
      remarks
      createdby
    }
  }
`;

export const LIST_EVAL_SECTIONS = gql`
  query items($tinput: templateinput!) {
    listItem(input: $tinput) {
      id
      vbeid
      type
      metadata
    }
  }
`;

export const GET_ITEM_VALUES = gql`
  query itemValues($pinput: projectinputV1!) {
    getEvaluationTempItemvaluebyemp(input: $pinput) {
      itemid
      value
      timestamp
      productvbeid
      attachment
      attachmentfilename
      remarks
      createdby
    }
  }
`;

export const GET_ALL_ITEM_VALUES = gql`
  query itemValues($pinput: projectinputV1!) {
    getEvaluationTempItemvalue(input: $pinput) {
      itemid
      value
      timestamp
      productvbeid
      attachment
      attachmentfilename
      remarks
      createdby
    }
  }
`;

export const LIST_AWARD_SECTIONS = gql`
  query items($tinput: templateinput!, $pinput: projectinputV1!) {
    listItem(input: $tinput) {
      id
      vbeid
      type
      metadata
    }

    getAwardTempItemvalue(input: $pinput) {
      itemid
      value
      timestamp
      productvbeid
      attachment
      attachmentfilename
      remarks
    }
  }
`;

export const LIST_QA_SECTIONS = gql`
  query items($tinput: templateinput!, $pinput: projectinputV1!) {
    listItem(input: $tinput) {
      id
      vbeid
      type
      metadata
    }

    getQuestionTempItemvalue(input: $pinput) {
      itemid
      value
      timestamp
      productvbeid
      attachment
      attachmentfilename
      remarks
    }
  }
`;

export const GET_SECTIONS = gql`
  query sections($input: templateinput!) {
    listSection(input: $input) {
      id
      vbeid
      metadata
    }
  }
`;

export const GET_TEMPLATE = gql`
  query template($input: templateinput!) {
    getTemplateByid(input: $input) {
      id
      name
      status
    }
  }
`;

export const GET_PROJECT_FOR_VENDOR = gql`
  query project($input: projectinputV1!) {
    getProjectDetailsForVendorV1(input: $input) {
      vbeid
      name
      createdon
      engagement_type
      role
      type
      productvbeid
      productname
      companyname
      image
      role
    }
  }
`;

export const GET_LEADS = gql`
  query leads {
    listProjectbyCompanyIDV1(input: {}) {
      vbeid
      name
      createdon
      role
      type
      productvbeid
      productname
      companyname
      image
      role
    }
  }
`;

export const GET_PROJECTS_BY_USER = gql`
  query projects {
    listProjectbyPersonV1(input: {}) {
      vbeid
      name
      createdon
      createdby
      projectstatus
      type
      conciergestatus
    }
  }
`;

export const GET_SERVICE_PROJECTS_BY_USER = gql`
  query serviceProjects {
    listServiceProjects(input: {}) {
      vbeid
      name
      createdon
      createdby
      projectstatus
      type
      conciergestatus
      role
    }
  }
`;

export const GET_LOGIN_USER_INFO = gql`
  query userinfo {
    getLoginUserInfo(input: {}) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      roletype
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
      isPrivatemail
      status
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation response($input: projectinputV1!) {
    respondToRequestV1(input: $input) {
      responsestatus
    }
  }
`;

export const MOVE_TO_EVALUATE = gql`
  mutation moveToEvaluate($input: projectinputV1!) {
    addEvaluateProjectToProductV1(input: $input) {
      responsestatus
    }
  }
`;

export const MOVE_TO_CONTRACT = gql`
  mutation moveToContract($input: projectinputV1!) {
    addContractProjectToProductV1(input: $input) {
      responsestatus
    }
  }
`;

export const MOVE_TO_AWARD = gql`
  mutation moveToAward($input: projectinputV1!) {
    addAwardProjectToProductV1(input: $input) {
      responsestatus
    }
  }
`;

export const SUBMIT_EVALUATION = gql`
  mutation submitEvaluation($input: projectinputV1!) {
    submitEvaluationPersonToProjectV1(input: $input) {
      responsestatus
    }
  }
`;

export const getSignedUrlForDoc = gql`
  query upload($input: s3input!) {
    getSignedURL(input: $input) {
      signedurl
    }
  }
`;

export const VIEW_VENDOR_RESPONSE = gql`
  query viewVendorResponse($input: projectinputV1!) {
    viewReposneByVendor(input: $input) {
      exsummary
      scopeunderstanding
    }
  }
`;

export const ADD_PO_DETAILS = gql`
  mutation addPoDetails($input: projectinputV1!) {
    addPO(input: $input) {
      responsestatus
    }
  }
`;

export const ADD_WP_PO_DETAILS = gql`
  mutation addWPPOdetails($input: projectinputV1!) {
    wp_addPO(input: $input) {
      responsestatus
    }
  }
`;

export const UPDATE_PO_DETAILS = gql`
  mutation updatePoDetails($input: projectinputV1!) {
    updatePO(input: $input) {
      responsestatus
    }
  }
`;

export const GET_PO_DETAILS = gql`
  query getPoDetails($input: projectinputV1!) {
    listPO(input: $input) {
      status
      remarks
      porate
      postartdate
      poenddate
      podate
      ponumber
    }
  }
`;

export const ONBOARD_RESOURCE = gql`
  mutation onBoardResource($input: projectinputV1!) {
    onboardResource(input: $input) {
      responsestatus
    }
  }
`;

export const ONBOARD_SUPPLIER = gql`
  mutation onBoardSupplier($input: projectinputV1!) {
    wp_onboardSupplier(input: $input) {
      responsestatus
    }
  }
`;
