import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import {
  Paper,
  ListItemSecondaryAction,
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grid,
  Divider,
  FormControl,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Skeleton from "@material-ui/lab/Skeleton";
import BusinessIcon from "@material-ui/icons/Business";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ErrorIcon from "@material-ui/icons/Error";

import { theme, BootstrapInput } from "../../../../theme/theme";

import LinesEllipsis from "react-lines-ellipsis";
import {
  projectbyid,
  listVendorsconcierge,
  submitConcierge,
  removeVendorsinConcierge,
} from "./query";

import { useQuery, useApolloClient, useMutation } from "@apollo/client";

import ProjectVendors from "../../../shared/skeletons/newvendors";

import { VBRIDGEHUB_MEDIAURL } from "../../../../constants";

import VbridgeAlert from "../../../shared/alert";

import { LightTooltip, useStyles } from "./styles";

import { useFormik } from "formik";
import * as yup from "yup";

function ProjectHead(props) {
  const classes = useStyles();
  let { data } = props;

  const [useEllipses, setUseEllipses] = React.useState(true);

  const onReadMore = () => {
    let prevState = useEllipses;
    setUseEllipses(!prevState);
  };

  return (
    <Grid container md={12} justify="flex-start" style={{ textAlign: "left" }}>
      <Grid item md={12}>
        <Typography
          variant="h5"
          color="primary"
          style={{ font: "bold", paddingRight: theme.spacing(0) }}
        >
          {data.getProjectByID.name}
        </Typography>
      </Grid>

      <Grid
        item
        md={10}
        style={{ paddingTop: theme.spacing(1), marginBottom: "8px" }}
      >
        {data.getProjectByID.description ? (
          useEllipses ? (
            <div onClick={onReadMore}>
              <LinesEllipsis
                text={data.getProjectByID.description}
                maxLine={2}
                ellipsis=" ...Read More"
                trimRight
                basedOn="letters"
                className={classes.question}
              />
            </div>
          ) : (
            <div className={classes.question}>
              {data.getProjectByID.description}
              <button className="readLessBtn" onClick={onReadMore}>
                Read Less
              </button>
            </div>
          )
        ) : (
          <div style={{ color: "#999", fontStyle: "italic" }}>
            No description
          </div>
        )}
      </Grid>
    </Grid>
  );
}

function Heading(props) {
  let history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={0}>
        <Grid item>
          <Typography
            className={classes.productHead}
            variant="subtitle1"
            onClick={() => {
              history.push("/product/" + props.vbeid);
            }}
          >
            {props.name + " | "}
          </Typography>
        </Grid>

        <Grid item>
          <ButtonGroup
            disableElevation={false}
            size="small"
            variant="text"
            color="primary"
          >
            <LightTooltip title="Visit Company Page">
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  history.push("/company/" + props.companyvbeid);
                }}
                startIcon={<BusinessIcon />}
              >
                {props.companyname}
              </Button>
            </LightTooltip>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Typography varaint="OVERLINE TEXT" noWrap style={{ fontSize: 12 }}>
        {props.productdescription === "NaN" ? null : props.productdescription}
      </Typography>
    </>
  );
}

function ListAddedVendors(props) {
  const classes = useStyles();
  const client = useApolloClient();
  let { id } = useParams();

  ///list added vendors
  const { data: vendorlist } = useQuery(listVendorsconcierge, {
    variables: { input: { vbeid: id } },
    fetchPolicy: "cache-and-network",
  });

  ///delete vendor
  const [removeproductmutation, { error: mutationError, data: removeProduct }] =
    useMutation(removeVendorsinConcierge, {
      onCompleted: (e) => {
        UpdateDeleteCache(e);
      },
    });

  //////update cache for delete group
  function UpdateDeleteCache(e) {
    let removeIds = [];
    e.removeProductsFromProjectConcierge.forEach((o) => {
      removeIds.push(o.id);
    });

    let listProductCache = vendorlist.listProductsFromProjectConcierge.filter(
      (product) => !removeIds.includes(product.id)
    );

    client.writeQuery({
      query: listVendorsconcierge,
      data: {
        listProductsFromProjectConcierge: {
          __typename: "project",
          listProductsFromProjectConcierge: listProductCache,
        },
      },
      variables: { input: { vbeid: id } },
    });
  }

  function DeleteVendor(props) {
    const rvbeid = [props.vbeid];

    //     if(props.removeid)
    //     {
    //     {props.removeid.map((value)=>{

    //      return(rvbeid.push(value.vbeid))})
    //     }
    //   }

    function handleDelete(e) {
      removeproductmutation({
        variables: { input: { listvbeid: rvbeid, vbeid: id } },
      });
    }

    return (
      <DeleteOutlinedIcon
        style={{ cursor: "pointer" }}
        onClick={handleDelete}
      />
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          {mutationError ? (
            <VbridgeAlert
              severity="error"
              open={true}
              message="Something went wrong."
            />
          ) : null}
          {removeProduct ? (
            <VbridgeAlert
              severity="success"
              open={true}
              message="Vendor removed successfully"
            />
          ) : null}
        </Grid>
      </Grid>

      <div className={classes.listvendors}>
        <Typography variant="h6" color="primary">
          Vendors Added
        </Typography>
      </div>

      <div className={classes.fixvendorsheight}>
        <List>
          {vendorlist ? (
            vendorlist.listProductsFromProjectConcierge.map((value) => {
              return (
                <Paper square={true} style={{ width: "90%" }}>
                  <ListItem key={value.vbeid} style={{ height: 75 }} dense>
                    <ListItemAvatar>
                      <Avatar
                        className={classes.large}
                        variant="square"
                        alt={value.name}
                        src={VBRIDGEHUB_MEDIAURL + "/" + value.image}
                      >
                        {":("}
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      disableTypography={true}
                      style={{ padding: "2rem" }}
                      primary={
                        <Heading
                          vbeid={value.productvbeid}
                          name={value.productname}
                          productdescription={value.productdescription}
                          companyname={value.companyname}
                          companyvbeid={value.companyvbeid}
                        />
                      }
                    />

                    <ListItemSecondaryAction style={{ marginTop: "0%" }}>
                      <DeleteVendor vbeid={value.productvbeid} />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </Paper>
              );
            })
          ) : (
            <ProjectVendors />
          )}
        </List>
      </div>
    </>
  );
}

function AddCoverNote(props) {
  const classes = useStyles();
  let { method, formik } = props;

  return (
    <div className={classes.covernote}>
      <Typography variant="h6" color="primary">
        Cover Note
      </Typography>

      <Grid container md={12}>
        <Grid item md={12}>
          <FormControl className={classes.textfields}>
            <BootstrapInput
              required={true}
              multiline
              rows={6}
              rowsMax={10}
              value={formik.values.remarks}
              onChange={formik.handleChange("remarks")}
              onBlur={formik.handleBlur("remarks")}
            />
          </FormControl>
        </Grid>

        <Grid item className={classes.errorfields}>
          {formik.touched.remarks && formik.errors.remarks ? (
            <div>
              <Grid container spacing={1}>
                <Grid item>
                  <ErrorIcon fontSize="small" />
                </Grid>

                <Grid item>{formik.errors.remarks}</Grid>
              </Grid>
            </div>
          ) : null}
        </Grid>

        <div className={classes.buttonalign}>
          <Button
            onClick={method}
            variant="contained"
            size="small"
            color="primary"
            className={classes.buttonStyle}
          >
            Submit Request
          </Button>
        </div>
      </Grid>
    </div>
  );
}

export default function ViewConcierge() {
  const classes = useStyles();

  let { id } = useParams();

  //project by id info
  const { data } = useQuery(projectbyid, {
    variables: { input: { vbeid: id } },
  });

  const [
    submitconciergemutation,
    { error: mutationError, data: submitrequest },
  ] = useMutation(submitConcierge);

  const validationSchema = yup.object().shape({
    remarks: yup
      .string("Enter your Remarks")
      .min(10, "Cover Note should be of minimum 10 characters length")
      .required("Cover Note is required"),
  });

  const formik = useFormik({
    initialValues: {
      remarks: "",
    },
    validationSchema: validationSchema,
  });

  function SubmitConceriage() {
    submitconciergemutation({
      variables: {
        input: { remarks: formik.values.remarks, vbeid: id },
      },
    });
  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          {mutationError ? (
            <VbridgeAlert
              severity="error"
              open={true}
              message="Something went wrong."
            />
          ) : null}

          {submitrequest ? (
            <VbridgeAlert
              severity="success"
              open={true}
              message="Submitted successfully"
            />
          ) : null}
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Paper>
          <Grid container md={12}>
            <Grid item md={1} className={classes.linkalign}>
              <Link
                to={"/conciergerequests"}
                variant="body2"
                className={classes.backLink}
              >
                <ArrowBackIcon />
              </Link>
            </Grid>

            <Grid item md={11} className={classes.projecthead}>
              {data ? (
                <ProjectHead data={data} />
              ) : (
                <div style={{ width: 1000 }}>
                  <Skeleton animation={false} />
                  <Skeleton animation="wave" />
                </div>
              )}

              <Divider />

              <ListAddedVendors />

              <Divider />

              <AddCoverNote formik={formik} method={SubmitConceriage} />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
}
