import React from "react";

import { useQuery } from "@apollo/client";
import { Avatar, CardHeader, makeStyles } from "@material-ui/core";
import { getUserById } from "./query";
import { VBRIDGEHUB_MEDIAURL } from "../../../constants";
import { getDate } from "../../../functions/helper";

const useStyles = makeStyles((theme) => ({
  userInfo: {
    paddingTop: theme.spacing(1),
    fontSize: theme.spacing(5),
  },
  reply: {
    padding: "8px 8px 0 8px !important",
  },
}));

export default function UserInfo({ id, createdBy, userImage, type }) {
  const classes = useStyles();
  const { data } = useQuery(getUserById, {
    variables: { input: { vbeid: createdBy } },
  });

  const getTitle = () => {
    let name = data?.getPersonByVBEID.name;
    let date = getDate(id);

    return (
      <div class="name">
        {name}
        <span class="date">{date}</span>
      </div>
    );
  };

  return (
    <CardHeader
      avatar={
        <Avatar
          aria-label="avatar"
          className={classes.avatar}
          src={VBRIDGEHUB_MEDIAURL + "/" + userImage}
        ></Avatar>
      }
      title={getTitle()}
      className={classes.reply}
    ></CardHeader>
  );
}
