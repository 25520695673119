import { Button, CircularProgress, Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useStyles } from "../styles";

export default function NextPrevButtons({
  nextDisabled,
  prevDisabled,
  loading,
  nextLocation,
  prevLocation,
}) {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();

  return (
    <Grid container>
      <Grid item xs={4}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            if (prevLocation === "create") {
              history.push("/create-service-project-v3/" + id);
            } else {
              history.goBack();
            }
          }}
          disabled={prevDisabled}
        >
          Back
        </Button>
      </Grid>

      <Grid item xs={5}>
        <div className={classes.wrapper}>
          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            onClick={() => {
              if (nextLocation === "qna") {
                history.push("/service-response-template/" + id);
              } else if (nextLocation === "team") {
                history.push("/service-add-team/" + id);
              }
            }}
            disabled={nextDisabled || loading}
          >
            Next
          </Button>

          {loading ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : null}
        </div>
      </Grid>
    </Grid>
  );
}
