import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputIcon from "@material-ui/icons/Input";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FeedbackIcon from "@material-ui/icons/Feedback";
import GavelIcon from "@material-ui/icons/Gavel";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMutation, useQuery } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";

import { useApolloClient } from "@apollo/client";

import { useFormik } from "formik";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";

import {
  CREATE_TEMPLATE,
  GET_LOGIN_USER_INFO,
  GET_TEMPLATES,
} from "../../queries/query";

import PreviewTemplate from "./desing/preview";

const validateTemplateCreation = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.shortdescription) {
    errors.shortdescription = "Required";
  }

  return errors;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  subheader: {
    textAlign: "left",
    color: theme.palette.secondary.main,
  },
  tableContainer: {
    maxHeight: theme.spacing(60),
    minHeight: theme.spacing(50),
  },
  selectedicon: {
    color: theme.palette.secondary.main,
  },
  table: {
    maxHeight: theme.spacing(10),
  },
  tablehead: {
    padding: theme.spacing(2),
    textAlign: "left",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  tab: {
    textTransform: "none",
  },
  preview: {
    padding: theme.spacing(2),
    width: theme.spacing(120),
  },
}));

const Templates = [
  { name: "Requirement Templates", id: "requirement_template", vbridge: false },
  { name: "Response Templates", id: "response_template", vbridge: false },
  { name: "Q&A Templates", id: "qa_template", vbridge: false },
  { name: "Evaluate Templates", id: "evaluate_template", vbridge: false },
  {
    name: "Due Dilligence Templates",
    id: "due_dilligence_template",
    vbridge: true,
  },
  { name: "Feedback Templates", id: "feed_back_template", vbridge: true },
  { name: "Contract Templates", id: "contract_template", vbridge: true },
  { name: "SOW Templates", id: "sow_template", vbridge: true },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Template(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(false);

  let history = useHistory();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(!open);
  };

  return (
    <>
      <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
        <Grid container className={classes.preview}>
          <Grid item xs={12}>
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              onClick={() => {
                setState(false);
              }}
            >
              Close
            </Button>
          </Grid>
          <Grid item xs={12}>
            <PreviewTemplate
              id={props.id}
              setState={setState}
              type={props.type}
              setSweight={(e) => {}}
            />
          </Grid>
        </Grid>
      </Drawer>
      <TableRow key={props.name}>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle2" gutterBottom>
            {props.name}
          </Typography>

          <Typography variant="caption" display="block" gutterBottom>
            {props.description}
          </Typography>

          <React.Fragment>
            <Typography variant="caption" display="inline" gutterBottom>
              Authored by -
            </Typography>
            <Typography
              variant="caption"
              color="secondary"
              display="inline"
              gutterBottom
            >
              {props.for[0] === "public" ? "vBridge Hub" : props.author}
            </Typography>
          </React.Fragment>
        </TableCell>

        <TableCell>
          <Button color="secondary">
            {props.status === "published" ? "published" : "draft"}
          </Button>
        </TableCell>

        <TableCell>
          <Button
            color="primary"
            size="small"
            onClick={() => {
              setState(true);
            }}
          >
            Preview
          </Button>
          {props.status === "created" ? (
            <Button
              color="primary"
              size="small"
              onClick={() => {
                history.push("/create-template/" + props.type + "/" + props.id);
              }}
              className={classes.templateButton}
            >
              Edit
            </Button>
          ) : null}
        </TableCell>
      </TableRow>
    </>
  );
}

function TemplateTableContainer(props) {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_TEMPLATES, {
    variables: {
      input: {
        typelist: [props.id],
        statuslist: [props.status],
        forlist: props.for,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <TableContainer component="div" className={classes.tableContainer}>
      <Table
        stickyHeader
        className={classes.table}
        aria-label="simple table"
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>Template</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>

        {loading ? <caption>loading...</caption> : null}

        {error ? <caption>Something went wrong</caption> : null}

        {data ? (
          <TableBody className={classes.tablebody}>
            {data.listTemplates.map((row) => (
              <Template
                id={row.vbeid}
                name={row.name}
                description={row.shortdescription}
                author={row.createdby}
                status={row.status}
                type={row.type}
                for={props.for}
              />
            ))}
          </TableBody>
        ) : null}
      </Table>
    </TableContainer>
  );
}

export default function TemplateTable({ type }) {
  const classes = useStyles();
  let { id } = useParams();

  if (!id) {
    id = type;
  }

  let name = "";
  let templates = Templates.filter((template) => template.id === id);
  name = templates[0].name;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Paper>
        <Grid container>
          <Grid item xs={6} className={classes.tablehead}>
            <Typography variant="h6" gutterBottom>
              {name}
            </Typography>
          </Grid>

          <Grid item xs={6} className={classes.tablehead}>
            <CreateTemplate id={id} />
          </Grid>
        </Grid>

        <Tabs value={value} onChange={handleChange}>
          <Tab
            label="vBridgeHub (public) "
            {...a11yProps(0)}
            className={classes.tab}
          />
          <Tab
            label="My Company (private)"
            {...a11yProps(1)}
            className={classes.tab}
          />
          <Tab
            label="My Drafts (private)"
            {...a11yProps(1)}
            className={classes.tab}
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          <TemplateTableContainer id={id} for={["public"]} status="published" />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <TemplateTableContainer
            id={id}
            for={["company"]}
            status="published"
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <TemplateTableContainer
            id={id}
            for={["public", "company"]}
            status="created"
          />
        </TabPanel>
      </Paper>
    </Box>
  );
}

function GetIcon(props) {
  const classes = useStyles();

  switch (props.index) {
    case 0:
      return (
        <InputIcon
          className={
            props.selected === "requirement_template"
              ? classes.selectedicon
              : ""
          }
        />
      );
    case 1:
      return (
        <SettingsBackupRestoreIcon
          className={
            props.selected === "response_template" ? classes.selectedicon : ""
          }
        />
      );
    case 2:
      return (
        <HelpOutlineIcon
          className={
            props.selected === "qa_template" ? classes.selectedicon : ""
          }
        />
      );
    case 3:
      return (
        <PlaylistAddCheckIcon
          className={
            props.selected === "evaluate_template" ? classes.selectedicon : ""
          }
        />
      );
    case 4:
      return (
        <ListAltIcon
          className={
            props.selected === "due_dilligence_template"
              ? classes.selectedicon
              : ""
          }
        />
      );
    case 5:
      return (
        <FeedbackIcon
          className={
            props.selected === "feed_back_template" ? classes.selectedicon : ""
          }
        />
      );
    case 6:
      return (
        <GavelIcon
          className={
            props.selected === "contract_template" ? classes.selectedicon : ""
          }
        />
      );
    case 7:
      return (
        <AssignmentIcon
          className={
            props.selected === "sow_template" ? classes.selectedicon : ""
          }
        />
      );
  }
}

function CreateTemplate(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let history = useHistory();

  const client = useApolloClient();

  const user = client.readQuery({
    query: GET_LOGIN_USER_INFO,
    variables: { input: {} },
  });

  const [createTemplate, { data, loading, error }] = useMutation(
    CREATE_TEMPLATE,
    {
      onCompleted: (e) => {
        history.push(
          "/create-template/" +
            e.createTemplate.type +
            "/" +
            e.createTemplate.vbeid
        );
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      shortdescription: "",
      type: props.id,
      for:
        user.getLoginUserInfo.isVbridgeUser === "true" ? "public" : "company",
    },
    validate: validateTemplateCreation,
    onSubmit: (values) => {
      createTemplate({ variables: { input: { ...values, type: props.id } } });
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        size="small"
        onClick={handleClickOpen}
      >
        Create Template
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">New Template</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              error={formik.errors.name ? true : false}
              helperText={formik.errors.name ? formik.errors.name : ""}
              margin="dense"
              name="name"
              label="Template Name"
              variant="outlined"
              size="small"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <TextField
              error={formik.errors.shortdescription ? true : false}
              helperText={
                formik.errors.shortdescription
                  ? formik.errors.shortdescription
                  : ""
              }
              margin="dense"
              name="shortdescription"
              multiline
              rows={5}
              label="Template Description"
              variant="outlined"
              size="small"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.shortdescription}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              disabled={loading}
            >
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                disabled={loading}
              >
                Create
              </Button>
              {loading ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : null}
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

function TemplatesHome() {
  const classes = useStyles();
  let { id } = useParams();
  let history = useHistory();

  const [value, setValue] = React.useState(0);
  const client = useApolloClient();

  const user = client.readQuery({
    query: GET_LOGIN_USER_INFO,
    variables: { input: {} },
  });

  const [selectedIndex, setSelectedIndex] = React.useState(id);

  const handleListItemClick = (event, id) => {
    //setSelectedIndex(index);
    history.push("/project-templates/" + id);
  };

  return (
    <div className={classes.root}>
      <Box m={10}>
        <Paper>
          <Grid container>
            <Grid item xs={3}>
              <List
                component="nav"
                aria-label="Templates"
                dense
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    className={classes.subheader}
                  >
                    Templates
                  </ListSubheader>
                }
              >
                {Templates.map((template, i) => {
                  if (i > 3) {
                    if (user.getLoginUserInfo.isVbridgeUser === "true") {
                      return (
                        <>
                          <ListItem
                            button
                            selected={id === template.id}
                            onClick={(event) =>
                              handleListItemClick(event, template.id)
                            }
                          >
                            <ListItemIcon>
                              <GetIcon index={i} selected={id} />
                            </ListItemIcon>

                            <ListItemText primary={template.name} />
                          </ListItem>
                        </>
                      );
                    } else {
                      return null;
                    }
                  }

                  return (
                    <>
                      <ListItem
                        button
                        selected={id === template.id}
                        onClick={(event) =>
                          handleListItemClick(event, template.id)
                        }
                      >
                        <ListItemIcon>
                          <GetIcon index={i} selected={id} />
                        </ListItemIcon>

                        <ListItemText primary={template.name} />
                      </ListItem>
                    </>
                  );
                })}
              </List>
            </Grid>

            <Grid item xs={9}>
              <TemplateTable id={id} />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}
