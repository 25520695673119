import { Badge, Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useParams } from "react-router-dom";
import DiscussionCard from '../../../messaging2.0/discussionCard';



const useStyles = makeStyles((theme) => ({
    root: {
        
       
      flexWrap: 'wrap',    

    
      },

      tabname:{
          fontSize:theme.spacing(1.2)
      }
    
    
    }))



export default function ViewMessaging()
{
    const classes = useStyles();
    const [value, setValue] = React.useState(0);  

    let { id,productId } = useParams();  
   
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };


    return (
        <div className={classes.root}>
          
          <DiscussionCard productId={productId} projectId={id} type="question"/>
            
        </div>
    )
   


           


}