export const availabilityArray = [
  { value: "Cloud Hosted", type: "Cloud Hosted" },
  { value: "Self Hosted", type: "Self Hosted" },
  { value: "On-Premise License", type: "On-Premise License" },
  { value: "Others", type: "Others" },
];

export const sasBasedoptions = [
  {
    type: "Yes",
    value: "Yes",
  },
  {
    type: "No",
    value: "No",
  },
];

export const PricingModel = [
  {
    type: "Subscription",
    value: "Subscription",
  },
  {
    type: "Perpetual",
    value: "Perpetual",
  },
];

export const SupportCoverage = [
  {
    type: "24x7",
    value: "24x7",
  },
  {
    type: "12x7",
    value: "12x7",
  },
  {
    type: "Others",
    value: "Others",
  },
];

export const markets = [
  {
    type: "AWS",
    value: "AWS",
  },
  {
    type: "Azure",
    value: "Azure",
  },
  {
    type: "GCP",
    value: "GCP",
  },
];

export const supplierFilterOptions = [
  {
    value: "All",
    type: "all",
  },
  //   {
  //     value: "Existing Suppliers",
  //     type: "existing",
  //   },
  {
    value: "VBH Suggested",
    type: "platform",
  },
  //   {
  //     value: "Concierge Recommendations",
  //     type: "concierge",
  //   },
  {
    value: "Self Selected",
    type: "selected",
  },
];
