import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputLabel from '@material-ui/core/InputLabel'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'
import { mapAwardTocompanyV1, addVendors } from './query'
import { useStyles } from './styles.js'
import PrimaryButton from '../common/PrimaryButton'
import SearchAward from '../companies/Company2.0/shorts/SearchAward'
import VbridgeAlert from '../../shared/alert'

export default function AddVendor({ companyvbeid,open,setOpen,refetch }) {
  const classes = useStyles()
  const client = useApolloClient()
  const timer = useRef()

  //const [open, setOpen] = useState(false)
  const [venderCompany, setVenderComapny] = useState('')
  const [disable, setDisabled] = useState(true);

  const [vbeid, setCompanyVbeid] = useState('')
  


  const sentAwardVbeid = (awardId) => {
    setCompanyVbeid(awardId)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

    const handleButtonClick = () => {
      if (!mutationLoading) {
        //   setLoading(true);

        timer.current = window.setTimeout(() => {
          // setLoading(false);
          handleClose()
        }, 500)
      }
    }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    formik.resetForm()
  }

  const validationSchema = yup.object().shape({
    name: yup
      .string('Enter your Vendor Name')
      .min(2, 'Vendor Name should be of minimum 2 characters length')
      .required('Vendor Name is required'),

    // companyurl: yup
    //   .string('Enter your Awarder URL')
    //   .required('Vendor URL is required')
    //   .matches(
    //     /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
    //     'Enter valid Vendor URL',
    //   ),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: validationSchema,
  })

  //Add New Award

  //   const [
  //     postAwardMutation,
  //     { loading: mutationLoading, error: mutationError, data },
  //   ] = useMutation(mapNewAward, {
  //     onCompleted: (response) => {
  //       updateAwardInformationCache(response.createAwardAndMapToCompanyV1)
  //     },
  //   })

  //   const createAwardShort = (e) => {
  //     e.preventDefault()

  //     postAwardMutation({
  //       variables: {
  //         input: {
  //           name: formik.values.name,
  //           //awarderurl: formik.values.awarderurl,
  //           year: formik.values.year,
  //           companyvbeid: id,
  //         },
  //       },
  //     })

  //     handleButtonClick()
  //   }

  const [mapExistingVendor, { loading: mutationLoading,error:mutationError }] = useMutation(addVendors, {
    onCompleted: (response) => {
        refetch();
        setOpen(false);
      // updateAwardInformationCache(response.mapAwardTocompanyV1)
      setDisabled(true);
    },

    onError: (error) => {
      console.log('error', error)
    },
  })

  const mapExistingVendorName = (e) => {
    e.preventDefault()

    mapExistingVendor({
      variables: {
        input: {
          listvbeid: vbeid,
          vbeid: companyvbeid,
          name: formik.values.name,
          companyurl: formik.values.companyurl,
        },
      },
    })

    handleButtonClick()
  }

  ///update cache
  //   useQuery(getAwardsByCompanyId, {
  //     variables: { input: { companyid: id } },
  //   })

  //   const updateAwardInformationCache = (response) => {
  //     const { listAwardsByCompanyIDV1 } = client.readQuery({
  //       query: getAwardsByCompanyId,
  //       variables: { input: { companyvbeid: id } },
  //     })

  //     let listAwardsInformationCache = [response, ...listAwardsByCompanyIDV1]

  //     client.writeQuery({
  //       query: getAwardsByCompanyId,
  //       data: {
  //         listAwardsByCompanyIDV1: {
  //           __typename: 'generic',
  //           listAwardsByCompanyIDV1: listAwardsInformationCache,
  //         },
  //       },
  //       variables: { input: { companyvbeid: id } },
  //     })
  //   }

  const setName = (text) => {
    if (text === "Didn't find award? Add award below manually.") {
    } else {
      formik.values.name = text
    }
  }

  const setDomain = (text) => {
    let url = ''
    if (text === 'https://unavailable') {
      url = 'https://'
    } else {
      url = text
    }
    formik.values.companyurl = url
  }

  const setCompany = (value) => {
    setVenderComapny(value)
  }

  return (
    <div>
      {/* {mutationError && <SimpleErrorSnackbar />}
      {data && <SimpleSnackbar type="success" />}
      {mutationLoading || awardload ? <SimpleSnackbar type="loading" /> : null} */}

{/* <AddNewButton name="Add Vendor" handleClick={handleClickOpen} /> */}
{mutationError && (
        <VbridgeAlert
          severity="error"
          open={true}
          message="Something went wrong! Please try again later."
        />
      )}
      {mutationLoading  && (
        <VbridgeAlert
          severity="info"
          open={true}
          message="Added Successfully..."
        />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Supplier</DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            Please enter Supplier details in the following fields.
          </DialogContentText>

          <div className={classes.root}>
            <Grid container style={{ width: '100%' }}>
              <Grid item md={10}>
                <FormControl className={classes.textfields}>
                  <InputLabel
                    required={true}
                    shrink
                    htmlFor="searchVendor"
                    focused={true}
                  >
                    Search Supplier
                  </InputLabel>

                  <SearchAward
                    id="searchVendor"
                    setName={setName}
                    setDomain={setDomain}
                    setCompany={setCompany}
                    sentAwardVbeid={sentAwardVbeid}
                    setDisabled={setDisabled}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <DialogActions>
          <PrimaryButton
            label="Cancel"
            onClick={handleClose}
            variant="outlined"
          />

          <PrimaryButton
            label="Add"
            onClick={mapExistingVendorName}
            variant="contained"
            //   disabled={
            //     !awardStatus
            //       ? awardName.length === 0 ||
            //         formik.values.awardyear.length === 0
            //         ? true
            //         : false
            //       : formik.values.year.length === 0 ||
            //         formik.values.awarderurl.length === 0 ||
            //         formik.values.name.length === 0
            //       ? true
            //       : false
            //   }

            //disabled={!(formik.isValid && formik.dirty)}
            disabled={ formik.values.name.length === 0}
          />

          {mutationLoading ? (
              <CircularProgress size={24} className={classes.buttonProgress} />
            ) : null}
        </DialogActions>
      </Dialog>
    </div>
  )
}
