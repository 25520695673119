import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Avatar, Divider, Grid, Typography } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { getVendorList, removeVendor } from "./query";
import { VBRIDGEHUB_MEDIAURL } from "../../../constants";
import AddVendor from "./AddVendor";
import EnhancedTableHead from "../common/TableSort";
import PrimaryButton from "../common/PrimaryButton";
import AlertBox from "../common/AlertBox";
import VbridgeAlert from "../../shared/alert";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "auto",
    marginTop: theme.spacing(2),
  },
  remove: {
    color: "teal",
    fontWeight: "600",
    cursor: "pointer",

    "&:hover": {
      color: "#ab0000",
    },
  },
  message: {
    fontSize: "12px",

    color: "#cfd1d4",
  },
  subheading: {
    textTransform: "none",
    fontSize: theme.spacing(2),
    fontWeight: "bold",
    //margin: '8px',
    textAlign: "left",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: theme.spacing(16),
    marginRight: theme.spacing(16),
  },
  companyAndLogo: {
    display: "flex",

    alignItems: "center",
  },
  small: {
    marginRight: "8px",
  },
  company: {
    fontSize: "0.9rem",
    fontWeight: "500",
    cursor: "pointer",
    color: "#232961",

    "&:hover, &:focus": {
      color: "#4179BD",
    },
    "&:active": {
      color: "#232961",
    },
  },
  drawerOpenRoot: {
    marginTop: "5%",
    marginLeft: 200,
  },
  drawerCloseRoot: {
    marginTop: "5%",
  },
}));

const headCells = [
  {
    id: "image",
    numeric: true,
    disablePadding: false,
    label: "Supplier",
  },

  // { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
  // {
  //   id: 'companyurl',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Company Url',
  // },

  {
    id: "remove",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];
const removeHeadcell = [
  {
    id: "image",
    numeric: true,
    disablePadding: false,
    label: "Supplier",
  },

  // { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
  // {
  //   id: 'companyurl',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Company Url',
  // },
];

export default function VendorPage({
  companyvbeid,
  profileStatus,
  drawerOpen,
}) {
  const classes = useStyles();
  //const client = useApolloClient()
  const history = useHistory();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [vendorsAlerts, setVendorsAlert] = useState(false);
  const [removeId, setRemoveId] = useState("");

  const headCellNew = profileStatus ? headCells : removeHeadcell;

  const handleAlert = (id) => {
    setVendorsAlert(true);
    setRemoveId(id);
  };
  // const handleClose = () => {
  //   setOpen(false)
  // }

  const handleCloseAlert = () => {
    setVendorsAlert(false);
  };

  const {
    data,
    loading: vendorsLoading,
    refetch,
  } = useQuery(getVendorList, {
    variables: { input: { vbeid: companyvbeid } },

    onCompleted: (e) => {},
  });

  const [
    removetopicmutation,
    {
      error: mutationError,
      data: removevendor,
      loading: removemutationLoading,
    },
  ] = useMutation(removeVendor, {
    onCompleted: (e) => {
      refetch();
      //UpdateDeleteTopicCache(e);
    },
  });
  function handleDelete(id) {
    removetopicmutation({
      variables: {
        input: {
          listvbeid: id,
          vbeid: companyvbeid,
        },
      },
    });
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data?.listVBHCustomers.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const isSelected = (name) => selected.indexOf(name) !== -1

  // const onCancel = () => {
  //   setAlertOpen(false)
  // }
  // const onDisableCustomer = (e) => {
  //   setAlertOpen(true)
  //   setCustomersVbeid(e)
  // }

  const handleClickOpen = () => {
    setOpen(true);
  };

  function Row({ key, row, data }) {
    return (
      <>
        {removemutationLoading && (
          <VbridgeAlert
            severity="info"
            open={true}
            message="Removed Successfully..."
          />
        )}

        {data ? (
          <TableRow key={row.vbeid}>
            <TableCell className={classes.description} align="left">
              <div className={classes.companyAndLogo}>
                <Avatar
                  variant="square"
                  src={VBRIDGEHUB_MEDIAURL + "/" + row.image}
                  className={classes.small}
                />

                <Typography
                  className={classes.company}
                  onClick={() => {
                    history.push("company/" + row.vbeid);
                  }}
                >
                  {row.name}
                </Typography>
              </div>
            </TableCell>

            {/* <TableCell align="left">{row.name}</TableCell> */}
            {/* <TableCell align="left">{row.companyurl}</TableCell> */}

            {profileStatus && (
              <TableCell align="left">
                <span
                  className={classes.remove}
                  onClick={() => {
                    handleAlert(row.id);
                  }}
                >
                  Remove
                </span>
              </TableCell>
            )}
          </TableRow>
        ) : null}
      </>
    );
  }

  return (
    <div
      className={drawerOpen ? classes.drawerOpenRoot : classes.drawerCloseRoot}
    >
      <div className={classes.headingContainer}>
        <Typography
          variant="button"
          display="block"
          //color="primary"
          className={classes.subheading}
        >
          My Company Suppliers
        </Typography>

        {profileStatus && (
          <PrimaryButton
            label="+ Add Existing Supplier"
            variant="contained"
            onClick={handleClickOpen}
          />
        )}
      </div>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
          >
            <EnhancedTableHead
              classes={classes}
              headCells={headCellNew}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data?.listVendorsByCompany.length}
              profileStatus={profileStatus}
            />

            {data ? (
              <TableBody>
                {stableSort(
                  data?.listVendorsByCompany,
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return <Row key={row.vbeid} row={row} data={data} />;
                  })}
              </TableBody>
            ) : vendorsLoading ? (
              <Typography style={{ color: "#232961" }}>Loading...</Typography>
            ) : null}
          </Table>
        </TableContainer>
        {data?.listVendorsByCompany.length === 0 && (
          <Typography className={classes.message}>
            No Vendors Found...!!!
          </Typography>
        )}

        {vendorsAlerts ? (
          <AlertBox
            open={vendorsAlerts}
            onClose={handleCloseAlert}
            title="Remove Supplier"
            description="Are you sure you want to remove supplier?"
            onConfirm={() => handleDelete(removeId)}
            mutaionError={mutationError}
            data={removevendor}
          />
        ) : null}

        <AddVendor
          companyvbeid={companyvbeid}
          open={open}
          setOpen={setOpen}
          refetch={refetch}
        />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={data?.listVendorsByCompany.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
