import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export default function ProfileDataLoading() {
  return (
    <Box p={0} style={{ marginBottom: "8px" }}>
      <Grid container justify="flex-start">
        <Grid item md={12}>
          <Skeleton variant="rect" height={50} />
        </Grid>
      </Grid>
    </Box>
  );
}
