import React, { useState } from "react";

import PropTypes from "prop-types";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import UsecaseOverview from "./UsecaseOverview";

import { useStyles } from "./styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function SolutionTabs({
  isReadOnly,
  isAdmin,
  UpdateUsecCaseCache,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.tabRoot}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab className={classes.tabs} label="Overview" {...a11yProps(0)} />
      </Tabs>
      <Divider />

      <TabPanel value={value} index={0}>
        <UsecaseOverview
          isReadOnly={isReadOnly}
          isAdmin={isAdmin}
          UpdateUsecCaseCache={UpdateUsecCaseCache}
        />
      </TabPanel>
    </div>
  );
}
