import { useState } from "react";

import {
  Avatar,
  Grid,
  Typography,
  FormControl,
  Button,
  TextField,
  Snackbar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import { useFormik } from "formik";

import { useParams, useHistory } from "react-router-dom";

import {
  ADD_TEAM_MEMBER,
  GET_PROJECT,
  LIST_PROJECT_TEAM_MEMBERS,
  REMOVE_TEAM_MEMBER,
} from "../../queries/query";

import { useStyles } from "../createProject/styles";

import { userInfoQuery } from "../../../companies/Company2.0/query";
import LoadingResult from "../../../../shared/LoadingResult";
import PageButtons from "../createProject/WorkPackage/PageButtons";

const validateTeamMember = (values) => {
  const errors = {};

  if (!values.personemailid) {
    errors.personemailid = "Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.personemailid)
  ) {
    errors.personemailid = "Invalid email address";
  }

  return errors;
};

const ButtonGroup = ({ id, type, loading }) => {
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </Button>
      </Grid>

      <Grid item xs={5}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            if (type === "service") {
              history.push("/review-service-project/" + id);
            } else {
              history.push("/add-short-description/" + id);
            }
          }}
          disabled={loading}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

const TeamMenu = ({ id, pvbeid, refetch }) => {
  const [removeMember, { loading }] = useMutation(REMOVE_TEAM_MEMBER, {
    onCompleted: (e) => {
      refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <IconButton
      disabled={loading}
      onClick={() => {
        removeMember({
          variables: { input: { vbeid: id, listvbeid: [pvbeid] } },
        });
      }}
      edge="end"
      aria-label="delete"
    >
      <DeleteIcon />
    </IconButton>
  );
};

const Form = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  //   const handleClick = () => {
  //     setOpen(true);
  //   };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      vbeid: props.id,
      personemailid: "",
    },

    validate: validateTeamMember,

    onSubmit: (values) => {
      addMember({ variables: { input: values } });
    },
  });

  const [addMember, { loading }] = useMutation(ADD_TEAM_MEMBER, {
    onCompleted: (e) => {
      formik.setFieldValue("personemailid", "");
      props.refetch();
    },
    onError: (err) => {
      formik.setFieldValue("personemailid", "");
      setOpen(true);
    },
  });

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          Please add user belongs to the same domain!
        </Alert>
      </Snackbar>

      <form onSubmit={formik.handleSubmit} style={{ marginBottom: "16px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.textFieldLabel}
            >
              Add your project team here.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container style={{ alignItems: "center" }}>
              <Grid item xs={10}>
                <TextField
                  id="filled-full-width"
                  size="small"
                  name="personemailid"
                  helperText={formik.errors.personemailid}
                  value={formik.values.personemailid}
                  onChange={formik.handleChange}
                  placeholder="User Email"
                  fullWidth
                  variant="outlined"
                  error={
                    formik.touched.personemailid &&
                    Boolean(formik.errors.personemailid)
                  }
                />
              </Grid>

              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="small"
                  disabled={loading}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default function TeamForm({ type }) {
  const classes = useStyles();
  const client = useApolloClient();
  const { id } = useParams();

  const user = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  const { data: projectData } = useQuery(GET_PROJECT, {
    variables: { input: { vbeid: id } },
    fetchPolicy: "no-cache",
  });

  const { data, refetch } = useQuery(LIST_PROJECT_TEAM_MEMBERS, {
    variables: { input: { vbeid: id } },
  });

  let userRole = "member";

  if (data) {
    data.getTeammembersByProjectIDV1.forEach((members) => {
      if (
        members.role[0] === "MANAGES" &&
        members.personvbeid === user.getLoginUserInfo.vbeid
      ) {
        userRole = "owner";
      }
    });
  }

  return (
    <Grid className={classes.manageTeamRoot}>
      <Grid item xs={8} style={{ minHeight: "400px" }}>
        <FormControl component="fieldset" className={classes.teamFormControl}>
          {data ? (
            <>
              <Grid item xs={12}>
                {userRole === "owner" && <Form id={id} refetch={refetch} />}
              </Grid>

              <Grid item xs={12}>
                <List dense={true}>
                  {data
                    ? data.getTeammembersByProjectIDV1.map((row) => (
                        <ListItem key={row.id}>
                          <ListItemAvatar>
                            <Avatar></Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={
                              row.personname
                                ? row.personname
                                : row.personemailid
                            }
                            secondary={
                              row.role.indexOf("MANAGES") > -1
                                ? "Project Owner"
                                : "Project Member"
                            }
                          />

                          {userRole === "owner" &&
                            row.personvbeid !== user.getLoginUserInfo.vbeid && (
                              <ListItemSecondaryAction>
                                <TeamMenu
                                  id={id}
                                  pvbeid={row.personvbeid}
                                  refetch={refetch}
                                />
                              </ListItemSecondaryAction>
                            )}
                        </ListItem>
                      ))
                    : null}
                </List>
              </Grid>
            </>
          ) : (
            <LoadingResult />
          )}
        </FormControl>
      </Grid>

      {projectData && (
        <Grid item xs={12}>
          {projectData.getProjectByIDV1.type === "service-workpackage" ? (
            <PageButtons
              nextLocation="review"
              nextDisabled={false}
              prevDisabled={false}
            />
          ) : (
            <ButtonGroup id={id} type={type} />
          )}
        </Grid>
      )}
    </Grid>
  );
}
