import React from 'react';

import { useStyles } from './styles';

import { Button, Grid, Typography } from '@material-ui/core';

import { useQuery } from '@apollo/client';

import ReplyToQuestions from './replyToQuestion';
import { getQuestions } from './query';
import { useParams } from 'react-router';
import ProductLoad from '../../../shared/skeletons/productlist';

export default function ListQuestions({productid, review, approved, changeTab, questionCount}){
    let {id} = useParams();
    const classes = useStyles();
    const [submittedQuestions, setSubmittedQuestions] = React.useState([]);
    //console.log(submittedQuestions)

      //listquestions
  const {loading, refetch} =  useQuery(getQuestions,{variables:{input:{vbeid:id,productvbeid:productid}},
                                                fetchPolicy: "cache-and-network", onCompleted: (e)=>{SubmittedObjects(e)}});

    // if(questions){
    //     console.log('questions',questions)
    // }


  

    function SubmittedObjects(e){
       
    if(e.listDeclarationQuestionAnswer.length>=1){
    // eslint-disable-next-line array-callback-return
    let list = e.listDeclarationQuestionAnswer.map((value)=>{
        
        if(value.status==='submitted'){
           return value}
        //    return value.status==='submitted'? value:null
       }).filter(x=>x!==undefined)

        setSubmittedQuestions(list);
        
        var noresponse = 0;
        if(review){
            e.listDeclarationQuestionAnswer.map((value)=>{
              return(value.response==='No Response'?(noresponse++):null)
              })
              questionCount(noresponse);
                  }
        

    }}


    if(loading){
      return <ProductLoad/> 
    }

    function NextButton(){

        return(
            <Button variant='contained' color='primary' onClick={changeTab} 
            className={classes.buttonStyle}>
            Next
            </Button>
        )
    }

    return(
        <>

        {submittedQuestions.length>=1?
        <Grid container >

        <Grid item md={12}>
        <ReplyToQuestions approved={approved} productid={productid} review={review} 
        listQuestions={submittedQuestions} refetch={refetch}/>
        </Grid>

        {review?null:(
        <Grid item md={12}>
        <NextButton/>
        </Grid>)}

        </Grid>
         :(
             <div className={review?classes.screeningQuestionsDefaultTextAlign:null}>
         <Typography varaint='caption' className={classes.noQuestionsListTextAlign}>
                  No questions added by the client.
         </Typography>
         </div>
         )}

        </>
    )
}