import { useLazyQuery, useQuery } from "@apollo/client";
import { Grid, Typography } from "@material-ui/core";
import { getDiversityNames } from "../../../../../functions/helper";
import { getSigned } from "../../../CaseStudy2.0/casestudy/query";

import { GET_DIVERSITY_LIST } from "../../queries/query";

import JDEditor from "../createProject/ServiceProject/JDEditor";

import FormElement from "./FormElement";
import { useStyles } from "./styles";

export default function ServiceRequirementForm({ id, projectData, productId }) {
  const classes = useStyles();

  const {
    worklocation,
    workstartby,
    workduration,
    secondaryskill,
    resourcecount,
    profilereceivingdeadline,
    primaryskill,
    jobdescriptionfile,
    jobdescription,
    indicativeinterviewschedule,
    expinprimaryskill,
    diversity,
    budget,
    country,
    state,
    currency,
  } = projectData;

  const { data } = useQuery(GET_DIVERSITY_LIST, {
    fetchPolicy: "network-only",
  });

  const [getUrl] = useLazyQuery(getSigned, {
    onCompleted: (response) => {
      window.open(response.getSignedURL.signedurl);
    },
  });

  const onFileClick = () => {
    let fileUrlSplit = jobdescriptionfile.split("/");
    let fileName = fileUrlSplit[5].split("?");

    getUrl({
      variables: {
        input: {
          method: "get",
          filetype: "file",
          filename: fileName[0],
          vbeid: fileUrlSplit[3],
        },
      },
    });
  };

  let location = state + ", " + country;

  let secondarySkillValue = secondaryskill;

  if (secondaryskill.length > 1) {
    secondaryskill.forEach((skill, index) => {
      if (index === 0) {
        secondarySkillValue = skill;
      } else {
        secondarySkillValue = secondarySkillValue + ", " + skill;
      }
    });
  }

  let urlSplit = "";
  let fileNameSplit = "";
  let fileName = "";

  if (jobdescriptionfile && jobdescriptionfile !== "") {
    urlSplit = jobdescriptionfile.split("/");
    fileNameSplit = urlSplit[5].split("?");
    fileName = fileNameSplit[0];
  }

  return (
    <div className={classes.requirementForm}>
      <Grid container className={classes.mainContainer}>
        <Grid item md={12}>
          <Typography gutterBottom className={classes.sectionTitle}>
            Job Location
          </Typography>
        </Grid>

        <Grid item md={12} className={classes.subSection}>
          <Grid container>
            <Grid item md={4}>
              <FormElement name="Workplace Type" value={worklocation} />
            </Grid>

            <Grid item md={8}>
              {worklocation !== "remote" && (
                <FormElement name="Location" value={location} size="small" />
              )}
            </Grid>
          </Grid>

          <Grid container className={classes.interSection}>
            {data && diversity.length !== 0 && (
              <Grid item md={12}>
                <FormElement
                  name="Diversity Requirement"
                  value={getDiversityNames(diversity, data.listDiversity)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid md={12} className={classes.newSection}>
          <Typography gutterBottom className={classes.sectionTitle}>
            Skill Description
          </Typography>
        </Grid>

        <Grid item md={12} className={classes.subSection}>
          <Grid container>
            <Grid item md={4}>
              <FormElement name="Primary Skill" value={primaryskill} />
            </Grid>

            <Grid item md={4}>
              <FormElement
                name="Secondary Skills"
                value={secondarySkillValue}
              />
            </Grid>

            <Grid item md={4}>
              <FormElement
                name="Experience in Primary Skill"
                value={expinprimaryskill}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.interSection}>
            <Grid item md={4}>
              <FormElement name="No of Resources" value={resourcecount} />
            </Grid>

            <Grid item md={4}>
              <FormElement
                name="Work Duration (in months)"
                value={workduration}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid md={12} className={classes.newSection}>
          <Typography gutterBottom className={classes.sectionTitle}>
            Budget Details
          </Typography>
        </Grid>

        <Grid item md={12} className={classes.subSection}>
          <Grid container>
            <Grid item md={4}>
              <FormElement name="Currency" value={currency} />
            </Grid>

            <Grid item md={4}>
              <FormElement name="Budget (per hour)" value={budget} />
            </Grid>
          </Grid>
        </Grid>

        <Grid md={12} className={classes.newSection}>
          <Typography gutterBottom className={classes.sectionTitle}>
            Timelines
          </Typography>
        </Grid>

        <Grid item md={12} className={classes.subSection}>
          <Grid container>
            <Grid item md={4}>
              <FormElement name="Work Start by" value={workstartby} />
            </Grid>

            <Grid item md={8}>
              <FormElement
                name="Profiles Recieving Deadline"
                value={profilereceivingdeadline}
              />
            </Grid>

            <Grid item md={12} className={classes.interSection}>
              <FormElement
                name="Indicative Interview Schedule"
                value={indicativeinterviewschedule}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid md={12} className={classes.newSection}>
          <Typography gutterBottom className={classes.sectionTitle}>
            Job Description
          </Typography>
        </Grid>

        <Grid item md={11} className={classes.jdSection}>
          <JDEditor
            data={jobdescription}
            editorId="jdId"
            isReadOnly={true}
            saveMethod={() => {}}
            edit={false}
          />

          {jobdescriptionfile && jobdescription !== null ? (
            <div className={classes.interSection}>
              <FormElement
                name="Job Description File"
                value={fileName}
                type="file"
                onFileClick={onFileClick}
              />
            </div>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
