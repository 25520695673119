import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Snackbar,
  TextField,
} from "@material-ui/core";

import { gql, useMutation } from "@apollo/client";

import { useStyles } from "./HelpStyles";

import { useState } from "react";

import CloseIcon from "@material-ui/icons/Close";

import "./help.css";

import PrimaryButton from "../common/PrimaryButton";

export const sendMessage = gql`
  mutation addMessage($input: genericinput!) {
    helpEmail(input: $input) {
      id
    }
  }
`;

function HelpDailogBox({ heading, statement, inlay, open, close, message }) {
  const classes = useStyles();

  const [msg, setMessage] = useState("");
  const [Dailogopen, setDailogOpen] = useState(false);

  const getMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleClose = () => {
    setDailogOpen(false);
  };

  const [helpRequest] = useMutation(sendMessage, {
    onCompleted: (e) => {},
  });

  const sendRequest = () => {
    helpRequest({
      variables: {
        input: {
          remarks: msg,
        },
      },
    });

    setDailogOpen(true);

    close();
  };

  return (
    <div>
      <form>
        <Dialog open={open} onClose={close}>
          <DialogTitle className={classes.title}>{heading}</DialogTitle>

          <Divider />

          <DialogContent>
            <DialogContentText className={classes.stmt}>
              {statement}
            </DialogContentText>

            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.inlay}
            >
              <TextField
                required
                multiline
                rows={5}
                variant="outlined"
                placeholder={inlay}
                className={classes.TextField}
                onChange={getMessage}
              />

              <FormHelperText>
                {msg.length < 20
                  ? "Please enter a line for better understanding"
                  : null}
              </FormHelperText>
            </FormControl>

            <div style={{ float: "right" }}>
              <PrimaryButton
                variant="outlined"
                onClick={close}
                label="Cancel"
              />

              <PrimaryButton
                variant="contained"
                onClick={sendRequest}
                disabled={msg.length >= 15 ? false : true}
                label="Send"
              />
            </div>
          </DialogContent>
        </Dialog>
      </form>

      <Snackbar
        action={
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon fontSize="small" className={classes.close} />
          </IconButton>
        }
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Dailogopen}
        autoHideDuration={20000}
        onClose={handleClose}
        message={message}
      />
    </div>
  );
}

export default HelpDailogBox;
