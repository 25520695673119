import { useQuery } from "@apollo/client";

import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItemSecondaryAction,
  Paper,
  Typography,
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { GET_PROJECT } from "../../queries/query";
import ViewAwardForm from "../award/awardForm";
import ViewContract from "../contract/vendors";
import Overview from "../dashboard/overview";
import ServiceOverview from "../dashboard/ServiceOverview";

import ViewVendorEvaluationTable from "../evaluation/evalutionFormTable";
import ViewVendorEvaluationSummaryTable from "../evaluation/evalutionSummaryFormTable";
import TeamEvaluation from "../evaluation/team";

import Concierge from "../messaging/Concierge";
import ViewMessaging from "../messaging/messaging";

import MapEvaluationTemplate from "../requirements/mapEvaluationTemplate";
import ViewRequirements from "../requirements/viewRequirements";
import ViewServiceRequirements from "../requirements/ViewServiceRequirements";
import CandidateTable from "../response/CandidateTable";
import MilestoneTable from "../response/MilestoneTable";

import ViewResponse from "../response/response";
import ViewServiceResponse from "../response/ViewServiceResponse";

import TeamForm from "../team/manageTeamMain";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawerOpenRoot: {
    flexGrow: 1,
    marginLeft: 200,
  },
  main: {
    padding: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  mainmenu: {
    color: theme.palette.secondary.main,
  },
  backbutton: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(18),
  },
  projectName: {
    textAlign: "left",
    marginLeft: theme.spacing(-3),
  },
  menuList: {
    borderRight: "1px solid silver",
  },
  mainArea: {},
  sectionDivider: {
    margin: theme.spacing(1, 0),
  },
}));

function ProjectMenu({ projectData }) {
  const { type, id } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = React.useState(
    type === "evaluate0" ||
      type === "evaluate1" ||
      type === "evaluate2" ||
      type === "evaluate3"
  );

  const [responseOpen, setResponseOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleResponseClick = () => {
    setResponseOpen(!open);
  };

  return (
    <List component="nav" dense>
      <ListItem
        button
        selected={type === "overview" || type === "serviceOverview"}
        onClick={() => {
          history.push("/project/serviceOverview/" + id);
          //   if (projectData.type === "service-timematerial") {
          //     history.push("/project/serviceOverview/" + id);
          //   } else {
          //     history.push("/project/overview/" + id);
          //   }
        }}
      >
        <ListItemText primary="Project Dashboard" />
        {type === "overview" || type === "serviceOverview" ? (
          <SideIcon />
        ) : null}
      </ListItem>

      <Divider className={classes.sectionDivider} />

      <ListItem
        button
        selected={type === "team"}
        onClick={() => {
          history.push("/project/team/" + id);
        }}
      >
        <ListItemText primary="Project Team" />
        {type === "team" ? <SideIcon /> : null}
      </ListItem>

      <ListItem
        button
        selected={type === "messaging"}
        onClick={() => {
          history.push("/project/messaging/" + id);
        }}
      >
        <ListItemText primary="Message Suppliers" />
        {type === "messaging" ? <SideIcon /> : null}
      </ListItem>

      <ListItem
        button
        selected={type === "concierge"}
        onClick={() => {
          history.push("/project/concierge/" + id);
        }}
      >
        <ListItemText primary="Concierge" />
        {type === "concierge" ? <SideIcon /> : null}
      </ListItem>

      <Divider className={classes.sectionDivider} />

      <ListItem
        button
        selected={type === "requirements"}
        onClick={() => {
          history.push("/project/requirements/" + id);
        }}
      >
        <ListItemText primary="View Requirement" />
        {type === "requirements" ? <SideIcon /> : null}
      </ListItem>

      <ListItem
        button
        selected={type === "response"}
        onClick={() => {
          if (projectData.type === "service-timematerial") {
            handleResponseClick();
          } else {
            history.push("/project/response/" + id);
          }
        }}
      >
        <ListItemText primary="Supplier Responses" />
        {projectData.type === "service-timematerial" ? (
          responseOpen ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )
        ) : type === "response" ? (
          <SideIcon />
        ) : null}
      </ListItem>

      <Collapse in={responseOpen} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              history.push("/project/response/" + id);
            }}
          >
            <ListItemText primary="By Suppliers" />
            {type === "response" ? <SideIcon /> : null}
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              history.push("/project/consolidated/" + id);
            }}
          >
            <ListItemText
              primary={
                projectData.type === "service-workpackage"
                  ? "By Milestones"
                  : "By Resources"
              }
            />
            {type === "consolidated" ? <SideIcon /> : null}
          </ListItem>
        </List>
      </Collapse>

      {projectData.type !== "service-timematerial" && (
        <ListItem button onClick={handleClick}>
          <ListItemText primary="Project Evaluation" />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
      )}

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              history.push("/project/evaluate0/" + id);
            }}
          >
            <ListItemText primary="Select Template" />
            {type === "evaluate0" ? <SideIcon /> : null}
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              history.push("/project/evaluate1/" + id);
            }}
          >
            <ListItemText primary="Self Evaluation" />
            {type === "evaluate1" ? <SideIcon /> : null}
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              history.push("/project/evaluate2/" + id);
            }}
          >
            <ListItemText primary="Team Evaluation" />
            {type === "evaluate2" ? <SideIcon /> : null}
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              history.push("/project/evaluate3/" + id);
            }}
          >
            <ListItemText primary="Evaluation Summary" />
            {type === "evaluate3" ? <SideIcon /> : null}
          </ListItem>
        </List>
      </Collapse>

      {projectData.type !== "service-timematerial" && (
        <>
          <ListItem
            button
            selected={type === "contract"}
            onClick={() => {
              history.push("/project/contract/" + id);
            }}
          >
            <ListItemText primary="Contract" />
            {type === "contract" ? <SideIcon /> : null}
          </ListItem>

          <ListItem
            button
            selected={type === "award"}
            onClick={() => {
              history.push("/project/award/" + id);
            }}
          >
            <ListItemText primary="Award" />
            {type === "award" ? <SideIcon /> : null}
          </ListItem>
        </>
      )}
    </List>
  );
}

const SideIcon = () => {
  return (
    <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="arrow" color="secondary">
        <ArrowRightIcon />
      </IconButton>
    </ListItemSecondaryAction>
  );
};

export default function ProjectOverview({ drawerOpen }) {
  const classes = useStyles();
  const { id, type } = useParams();
  const history = useHistory();

  const { data } = useQuery(GET_PROJECT, {
    variables: { input: { vbeid: id }, fetchPolicy: "no-cache" },
  });

  return (
    <div className={drawerOpen ? classes.drawerOpenRoot : classes.root}>
      {data ? (
        <Box m={10}>
          <Paper className={classes.main}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={2} className={classes.backgrid}>
                    <IconButton
                      className={classes.backbutton}
                      onClick={() => {
                        history.push("/projects3.0/overview");
                      }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>

                  <Grid item xs={10} className={classes.projectName}>
                    <Typography variant="h6">
                      {data.getProjectByIDV1.name}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>

              <Grid item xs={2} className={classes.menuList}>
                <ProjectMenu projectData={data?.getProjectByIDV1} />
              </Grid>

              <Grid item xs={10} className={classes.mainArea}>
                {type === "overview" ? <ServiceOverview /> : null}

                {type === "serviceOverview" ? <ServiceOverview /> : null}

                {type === "team" ? <TeamForm /> : null}

                {type === "requirements" ? (
                  data?.getProjectByIDV1.type === "service-timematerial" ||
                  data?.getProjectByIDV1.type === "service-workpackage" ? (
                    <ViewServiceRequirements
                      projectData={data?.getProjectByIDV1}
                    />
                  ) : (
                    <ViewRequirements />
                  )
                ) : null}

                {type === "response" ? (
                  data?.getProjectByIDV1.type === "service-timematerial" ||
                  data?.getProjectByIDV1.type === "service-workpackage" ? (
                    <ViewServiceResponse projectData={data?.getProjectByIDV1} />
                  ) : (
                    <ViewResponse projectData={data?.getProjectByIDV1} />
                  )
                ) : null}

                {type === "consolidated" &&
                  (data?.getProjectByIDV1.type === "service-timematerial" ? (
                    <CandidateTable projectData={data?.getProjectByIDV1} />
                  ) : (
                    <MilestoneTable projectData={data?.getProjectByIDV1} />
                  ))}

                {type === "evaluate0" ? (
                  <MapEvaluationTemplate projectData={data?.getProjectByIDV1} />
                ) : null}

                {type === "evaluate1" ? (
                  <ViewVendorEvaluationTable
                    projectData={data?.getProjectByIDV1}
                  />
                ) : null}

                {type === "evaluate2" ? (
                  <TeamEvaluation projectData={data?.getProjectByIDV1} />
                ) : null}

                {type === "evaluate3" ? (
                  <ViewVendorEvaluationSummaryTable
                    projectData={data?.getProjectByIDV1}
                  />
                ) : null}

                {type === "contract" ? (
                  <ViewContract projectData={data?.getProjectByIDV1} />
                ) : null}

                {type === "messaging" ? (
                  <ViewMessaging projectData={data?.getProjectByIDV1} />
                ) : null}

                {type === "concierge" ? <Concierge projectVbeid={id} /> : null}

                {type === "award" ? (
                  <ViewAwardForm projectData={data?.getProjectByIDV1} />
                ) : null}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      ) : null}
    </div>
  );
}
