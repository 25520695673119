import { LinearProgress, makeStyles, withStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  drawerOpenRoot: {
    marginLeft: 200,
  },
  help: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(24),
  },
  helpmessage: {
    marginTop: theme.spacing(3),
  },
  formarea: {
    minHeight: theme.spacing(55),
  },
  pageDetails: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  projectFormRoot: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing(1),
    //marginLeft: theme.spacing(-5),
    padding: theme.spacing(0, 2),
  },
  jdRoot: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 2),
    //marginLeft: theme.spacing(-5),
  },
  textFieldLabel: {
    fontSize: theme.spacing(1.8),
    margin: theme.spacing(2, 0, 1, 0),
  },
  textFieldSubLabel: {
    fontSize: theme.spacing(1.5),
    margin: theme.spacing(0, 0, 0, 1),
    fontWeight: "normal",
  },
  typetext: {
    textAlign: "center",
    fontSize: theme.spacing(1.8),
  },
  formControlclass: {
    textAlign: "left",
  },
  wpFormControlClass: {
    width: "100%",
  },
  typecard: {
    margin: theme.spacing(1),
  },
  projectype: {
    margin: theme.spacing(0.5),
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    marginTop: theme.spacing(1),
    left: "80%",
  },
  wpButtonProgress: {
    color: theme.palette.secondary.main,
  },
  wrapper: {
    position: "relative",
  },
  loading: {
    margin: "35%",
  },
  engarement_type: {
    "& .MuiFormControlLabel-label": {
      fontSize: theme.spacing(1),
    },
  },
  engarement_type_label: {
    fontSize: theme.spacing(1.4),
  },
  manageTeamRoot: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing(1),
    //marginLeft: theme.spacing(-5),
    padding: theme.spacing(0, 2),
  },
  errorMessage: {
    color: "red",
    fontSize: "0.75rem",
  },
  descriptionRoot: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(-5),
  },
  mainForm: {
    minHeight: theme.spacing(52),
  },
  reqRoot: {
    display: "flex",
    flexWrap: "wrap",
  },
  reviewReqRoot: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "80px",
  },
  descFormControlclass: {
    width: "100%",
    textAlign: "left",
    marginLeft: theme.spacing(10),
  },
  serviceFormControl: {
    margin: theme.spacing(1, 0),
  },
  serviceErrorMessage: {
    color: "red",
    fontSize: "0.7rem",
    margin: theme.spacing(-1, 0, 0.5, 0),
  },
  radioButtonLabel: {
    "& .MuiTypography-root": {
      fontSize: "0.9rem",
    },
  },
}));

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);
