import * as yup from "yup";

export const companyValidationSchema = yup.object({
  companyName: yup
    .string("Enter your Company Name")
    .min(2, "Company Name should be of minimum 2 characters length")
    .required("Company Name is required"),
  companyURL: yup
    .string("Enter Company URL (ex:https://vbridgehub.com)")
    .required("Company URL is required (ex:https://vbridgehub.com)")
    .matches(
      /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      "Enter your Company URL (ex:https://vbridgehub.com)"
    ),
});
