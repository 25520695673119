import * as React from "react";

import { useStyles, EllipsisText, LightTooltip } from "./styles";

import {
  Grid,
  Avatar,
  Paper,
  Chip,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import PublishIcon from "@material-ui/icons/Publish";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import { VBRIDGEHUB_MEDIAURL } from "../../../constants";
import DataGridValidation from "./dataGrid";
import DialogEdit from "./dialogPopup";
import CustomisedDrawer from "./swipableDrawer";
import LongDescriptionComponent from "./longDescription";
import CasestudyUpdateForm from "./updateCasestudyForm";
import { updateCasestudy, caseStudyelById } from "./query";
import VbridgeAlert from "../../shared/alert";
import File from "../../shared/uploadfile";
import CaseStudyView from "../CaseStudy2.0/casestudy/CaseStudyView";

export default function CasestudyValidation({
  casestudyBatch,
  loading,
  updateBatch,
  pageChanging,
  fetch,
  batchvbeid,
}) {
  const classes = useStyles();
  const client = useApolloClient();

  const [value, setValue] = React.useState("");
  const [postId, setPostId] = React.useState("");

  const [casestudyData, setCasestudyData] = React.useState("");

  const { data } = useQuery(caseStudyelById, {
    variables: { input: { vbeid: casestudyData.id } },
  });

  function GetFormikValues(targetValues, targetId, proId) {
    setValue(targetValues);
    setPostId(targetId);
  }

  /////update casestudy mutation
  const [
    postCasestudyMutation,
    { data: casestudyUpdated, error: casestudyUpdationError },
  ] = useMutation(updateCasestudy, {
    onCompleted: (e) => {
      UpdateCasestudyCache(e);
    },
  });

  //post casestudy info
  const postCasestudy = (e) => {
    postCasestudyMutation({
      variables: {
        input: {
          name: value.name,
          shortdescription: value.shortdescription,
          url: value.casestudyurl,
          businessvertical: value.businessvertical,
          productid: value.productid,
          vbeid: postId,
        },
      },
    });
  };

  //update cache
  function UpdateCasestudyCache(e) {
    var updatedObj = e.updatecasestudy;
    var listObjs = [...casestudyBatch];
    var foundIndex = listObjs.findIndex((x) => x.id === updatedObj.id);
    listObjs[foundIndex] = updatedObj;
    updateBatch([...listObjs]);
  }
  // cache for status
  function UpdateStatusCache(e) {
    var updatedObj = e.updatecasestudy;

    //to add new variable to object: "updatedObj.longdescriptionupdated = true"

    // updatedObj.longdescription !== ""
    //   ? (updatedObj.longdescriptionupdated = true)
    //   : (updatedObj.longdescriptionupdated = false);

    var listObjs = [...casestudyBatch];

    var foundIndex = listObjs.findIndex((x) => x.id === updatedObj.id);
    listObjs[foundIndex] = updatedObj;

    updateBatch([...listObjs]);
  }

  //update cache for longdescription
  const UpdateCaseStudyLongDescCache = (e) => {
    let UpdatedlongDescData = e.updatecasestudy.longdescription;
    let updateLongDesc = { ...data.caseStudyelById };

    updateLongDesc.longdescription = UpdatedlongDescData;

    client.writeQuery({
      query: caseStudyelById,
      data: {
        caseStudyelById: updateLongDesc,
      },
      variables: { input: { vbeid: casestudyData.id } },
    });
  };

  //popup open for edit casestudy details
  const [popup, setPopup] = React.useState(false);
  const [param, setParam] = React.useState("");

  function OpenPopup(e) {
    setPopup(true);
  }

  function ClosePopup() {
    setPopup(false);
  }

  //swipable drawer edit for long description
  const [drawer, setDrawer] = React.useState(false);

  function OpenDrawer(e) {
    setDrawer(true);
  }

  function CloseDrawer() {
    setDrawer(false);
  }

  //swipable drawer for file upload
  const [fileDrawer, setFileDrawer] = React.useState(false);
  const [fileUpload, setFileUpload] = React.useState("");

  function OpenFileDrawer(e) {
    setFileDrawer(true);
  }

  function CloseFileDrawer() {
    setFileDrawer(false);
  }

  //file upload mutation
  const [postCaseStudyFileMutation] = useMutation(updateCasestudy, {
    onCompleted: (e) => {
      UpdateCasestudyCache(e);
    },
  });

  //function to upload casestudy file
  const postUpdateCasestudy = (paramName, paramValue) => {
    postCaseStudyFileMutation({
      variables: {
        input: {
          vbeid: fileUpload.row.id !== "" ? fileUpload.row.id : "",
          [paramName]: paramValue,
          longdescriptionupdated: paramValue ? true : false,
        },
      },
    });
  };

  function UploadSuccessStory({ id, path }) {
    return (
      <div>
        <div>
          <File
            vbeid={id}
            entity="casestudyfile"
            filetype="doc"
            fext=".pdf"
            inputid={id + "casestudyfile"}
            paramName="filepath"
            updateCacheMethod={postUpdateCasestudy}
            buttonmessage="upload Success Story File"
            type="button"
            icon={<PublishIcon fontSize="small" />}
          />
        </div>

        <div className={classes.caestudyFileUploadAlign}>
          {id !== "" ? <CaseStudyView id={id} filePath={path} /> : null}
        </div>
      </div>
    );
  }

  const columns = [
    {
      field: "image",
      headerName: "Company Name",
      width: 200,
      renderCell: (params) => (
        <Grid container md={12} spacing={1}>
          <Grid item md={2} className={classes.logoAlign}>
            <Avatar
              className={classes.logoSize}
              variant="square"
              src={VBRIDGEHUB_MEDIAURL + "/" + params.value}
            >
              {":("}
            </Avatar>
          </Grid>

          <Grid item md={10}>
            <LightTooltip title={params.row.companyname} placement="bottom">
              <Typography variant="caption">
                {params.row.companyname}
              </Typography>
            </LightTooltip>
          </Grid>
        </Grid>
      ),
    },

    {
      field: "productname",
      headerName: "Product Name",
      width: 120,
      renderCell: (params) => (
        <div>
          <LightTooltip title={params.value} placement="bottom">
            <Typography variant="caption">{params.value}</Typography>
          </LightTooltip>
        </div>
      ),
    },

    {
      field: "name",
      headerName: "Success Story",
      width: 200,
      renderCell: (params) => (
        <Grid container md={12} spacing={1}>
          <Grid item md={10}>
            <div
              onClick={() => {
                OpenPopup();
                setParam(params);
              }}
            >
              <Typography
                variant="caption"
                color="primary"
                className={classes.iconPointer}
              >
                {params.row.name}
              </Typography>
            </div>
          </Grid>
        </Grid>
      ),
    },

    {
      field: "shortdescription",
      headerName: " Short Description",
      width: 250,
      editable: true,
      renderCell: (params) => (
        <div>
          <LightTooltip title={params.value} placement="bottom-start">
            <Typography variant="caption">{params.value}</Typography>
          </LightTooltip>
        </div>
      ),
    },

    {
      field: "businessvertical",
      headerName: "Vertical",
      width: 100,
      renderCell: (params) => (
        <div>
          <LightTooltip title={params.value} placement="bottom">
            <Typography variant="caption">
              {params.value !== null ? (
                <Chip
                  label={<EllipsisText> {params.value} </EllipsisText>}
                  size="small"
                />
              ) : null}
            </Typography>
          </LightTooltip>
        </div>
      ),
    },

    {
      field: "url",
      headerName: "URL",
      width: 80,
      renderCell: (params) =>
        params.value !== null ? (
          <LanguageIcon
            onClick={(event) => window.open(params.value, "_blank")}
          />
        ) : null,
    },

    {
      field: "longdescription",
      headerName: "Long Description",
      width: 140,
      renderCell: (params) => (
        <div
          onClick={() => {
            OpenDrawer();
            setCasestudyData(params);
          }}
        >
          <Typography
            variant="caption"
            color="primary"
            className={classes.iconPointer}
          >
            Long Description
          </Typography>
        </div>
      ),
    },

    {
      field: "file",
      headerName: " File",
      width: 100,
      renderCell: (params) => (
        <div
          onClick={() => {
            OpenFileDrawer();
            setFileUpload(params);
          }}
        >
          <Typography
            variant="caption"
            color="primary"
            className={classes.iconPointer}
          >
            Upload
          </Typography>
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Typography variant="caption" color="primary">
          {params.row.longdescriptionupdated === true ||
          [
            "TV-Hospitality-Test-zdbcyytbul",
            "TV-BigDataAnalytics-3lvmcgdpgg",
            "TV-CASB-Test-2-s7fqpgrv1p",
            "TV-iPaas-AppDeliverNet-ContIntSoftware-9aivwkan5p",
            "TV-Devops-ContInt-AppLife-ContDep-ContDel-buaagygwud",
            "TV-ITFM-ITSM-UITM-kgksulqgu",
            "SP-List1-k11x5g0dvd",
            "TV-ApiManPlat-ApiTool-p3ehxbhb6",
            "TV-DAMAC-DecRequirement-SCM-GRC-lmfacfktlk",
          ].includes(batchvbeid) ? (
            <LightTooltip title="Edited description">
              <Chip label={<EllipsisText>Yes</EllipsisText>} size="small" />
            </LightTooltip>
          ) : (
            <LightTooltip title="Long description not yet edited">
              <Chip label={<EllipsisText>No</EllipsisText>} size="small" />
            </LightTooltip>
          )}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          {casestudyUpdationError ? (
            <VbridgeAlert
              severity="error"
              open={true}
              message="Something went wrong."
            />
          ) : null}
          {casestudyUpdated ? (
            <VbridgeAlert
              severity="success"
              open={true}
              message="Casestudy Information Updated successfully."
            />
          ) : null}
        </Grid>
      </Grid>

      {popup ? (
        <DialogEdit
          buttonMainText={() => {}}
          buttonText1="Cancel"
          openPop={popup}
          closePop={ClosePopup}
          buttonText2="Update"
          updateMethod={postCasestudy}
          dialogTitle="Update Success Story Details"
          dialogMainContent={
            <CasestudyUpdateForm method={GetFormikValues} params={param.row} />
          }
        />
      ) : null}

      {drawer ? (
        <CustomisedDrawer
          buttonMainText="Long Description"
          openDrawer={drawer}
          closeDrawer={CloseDrawer}
          sliderText="Update Casestudy Long Description"
          sliderComponent={
            <>
              {data ? (
                <LongDescriptionComponent
                  vbeid={casestudyData.row.id}
                  longContent={data?.caseStudyelById.longdescription}
                  updateCache={(e) => UpdateCaseStudyLongDescCache(e)}
                  UpdateStatusCache={(e) => UpdateStatusCache(e)}
                  queryName={updateCasestudy}
                  fetch={fetch}
                />
              ) : (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </>
          }
        />
      ) : null}

      {fileDrawer ? (
        <CustomisedDrawer
          buttonMainText="Upload File"
          openDrawer={fileDrawer}
          closeDrawer={CloseFileDrawer}
          sliderText="Update Casestudy file"
          sliderComponent={
            fileUpload.row.id !== "" ? (
              <UploadSuccessStory
                id={fileUpload.row.id}
                path={fileUpload.row.filepath}
              />
            ) : null
          }
        />
      ) : null}

      <Paper className={classes.dataGridPaper}>
        <DataGridValidation
          onEdit={() => {}}
          data={casestudyBatch}
          columns={columns}
          pageChange={pageChanging}
          loading={loading}
          method={() => {}}
          updateQueryName={updateCasestudy}
        />
      </Paper>
    </>
  );
}
