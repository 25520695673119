import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import "./groups.css";

import { useStyles } from "./styles";

export default function PercentIcon({ percent }) {
  const classes = useStyles();

  let iconColor = "#333";
  let scoreText = "Basic";

  if (percent >= 2 && percent < 5) {
    iconColor = "#70AD47";
    scoreText = "Intermediate";
  } else if (percent >= 5 && percent < 8) {
    iconColor = "#00B0F0";
    scoreText = "High";
  } else if (percent >= 8 && percent <= 10) {
    iconColor = "#2E75B6";
    scoreText = "Advanced";
  }

  return (
    <div>
      <div className={classes.scoreDiv}>
        <CircularProgressbarWithChildren
          value={percent}
          maxValue="10"
          strokeWidth={9}
          styles={{
            root: {
              width: "22px",
            },
            path: {
              stroke: iconColor,
            },
            background: {
              fill: "#999",
            },
            trail: {
              stroke: "#d6d6d6",
            },
          }}
        >
          <div className={classes.innerProgressBar}>
            <CircularProgressbar
              value={0}
              maxValue="10"
              text={percent}
              background
              styles={{
                root: {
                  width: "16px",
                },
                path: {
                  stroke: "#999",
                },
                text: {
                  fontSize: "55px",
                  fill: "#fff",
                },
                background: {
                  fill: "#999",
                },
                trail: {
                  stroke: "transparent",
                  strokeLinecap: "butt",
                },
              }}
            />
          </div>
        </CircularProgressbarWithChildren>
      </div>

      <div className={classes.scoreText}>{scoreText}</div>
    </div>
  );
}
