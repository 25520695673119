import React, { useState } from "react";

import { useMutation, gql } from "@apollo/client";
import { Button, IconButton, makeStyles, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const axios = require("axios");

const signed = gql`
  mutation upload($input: s3input!) {
    putSignedURL(input: $input) {
      signedurl
      key
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "15%",
    margin: "auto",
    marginTop: "5%",
  },
  logoImg: {
    width: "100%",
    marginBottom: "2%",
  },
  uploadBtn: {
    fontSize: "0.8rem",
  },
}));

export default function UploadFile({
  vbeid,
  entity,
  filetype,
  fext,
  query,
  inputid,
  paramName,
  updateCacheMethod,
  buttonmessage,
  type,
  icon,
  handleFiles,
  callAPi,
  storeFile,
  page,
  // fetch,
}) {
  const classes = useStyles();
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [uploadkeys, setUploadkeys] = useState([]);

  // const [signedUrl] = useMutation(signed, {
  //   onCompleted: (e) => {
  //     afterGettingSignedUrl(e);
  //   },
  // });

  // const getSignedUrl = () => {
  // const nid = nanoid(8);
  // const fext = ".".concat(
  //   document.getElementById(inputid).files[0].name.split(".")[1]
  // );
  //const fname = nid.concat(fext)
  // const fname = document.getElementById(inputid).files[0].name;

  //   signedUrl({
  //     variables: {
  //       input: {
  //         method: "put",
  //         filetype: filetype,
  //         filename: storeFile,
  //         vbeid: vbeid,
  //       },
  //     },
  //   });
  // };

  const startUploadFile = (e) => {
    handleFiles(e.target.value, filetype);

    // setMessage('Preparing for upload ..')
    // setStatus(true)
    // getSignedUrl()
    // fetch()
  };

  // const afterGettingSignedUrl = (e) => {
  //   uploadFile(e.putSignedURL.signedurl, e.putSignedURL.key);
  // };

  // async function uploadFile(url, key) {
  //   await axios
  //     .request({
  //       method: "put",
  //       url: url,
  //       data: document.getElementById(inputid).files[0],
  //       onUploadProgress: (p) => {
  //         const percent = Math.round((p.loaded / p.total) * 100);
  //         setMessage("Uploading ..." + percent + "%");
  //       },
  //     })
  //     .then(function (response) {
  //       setStatus(false);

  //       let newUrl = response.config.url.split("?")[0];
  //       let newFilepath = newUrl.substring(newUrl.lastIndexOf(".com") + 4);
  //       let filepath = newFilepath.substring(1);

  //       // let keysArray = uploadkeys

  //       // keysArray.push(key)

  //       // setUploadkeys(keysArray)

  //       updateCacheMethod(paramName, filepath, key);
  //     });
  // }

  return (
    <div style={{ textAlign: "center" }}>
      <Snackbar
        open={status}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="info">{message}</Alert>
      </Snackbar>

      <input
        type="file"
        id={inputid}
        onChange={(e) => {
          startUploadFile(e);
        }}
        hidden
      />

      <label htmlFor={inputid}>
        {buttonmessage === "icon" && (
          <>
            {type === "icon" ? (
              <IconButton
                variant="contained"
                color="primary"
                component="span"
                startIcon={icon}
                className={classes.uploadBtn}
                // onClick={getSignedUrl}
              >
                {icon}
              </IconButton>
            ) : (
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={icon}
                className={classes.uploadBtn}
              >
                {buttonmessage}
              </Button>
            )}
          </>
        )}
      </label>
    </div>
  );
}
