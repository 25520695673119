import { gql } from "@apollo/client";


///Requests list information
export const listLeads = gql`
  query leadinformation($input: projectinput!) {
    getLeadsByCompanyID(input: $input) {
      id
      vbeid
      name
      image
      startdate
      enddate
      productname
      productvbeid
      personname
      customername
      customervbeid
      companyname
      companyvbeid
      description
      longdescription
      role {
        staus
        createdby
        createdon
        vbeid
        remarks
      }
    }
  }
`;


///project information
export const projectbyid = gql`
query project($input: id!) {
  getProjectByID(input: $input) {
    name
    id
    description
    startdate
    enddate
    createdon
    createdby
    timeline
  }
}
`;

//list Questionare
export const getQuestions = gql`
  query questions($input: projectinput!) {
    listDeclarationQuestionAnswer(input: $input) {
      question
      id
      option
      type
      vbeid
      status
      response
      ansstatus
      createdon
    }
  }
`;


//add answer
export const postanswer = gql`
  mutation project($input: projectinput!) {
    addReplyToDeclarationQuestion(input: $input) {
      id
      question
      vbeid
      option
    }
  }
`;

//update Questionare
export const updateanswer = gql`
  mutation project($input: projectinput!) {
    updateReplyToDeclarationQuestion(input: $input) {
      id
      question
      vbeid
      option
    }
  }
`;

//add proposal
export const addproposal = gql`
  mutation proposal($input: projectinput!) {
    addProposal(input: $input) {
      id
    }
  }
`;


//update proposal
export const updateproposal = gql`
  mutation updateproposal($input: projectinput!) {
    updateProposalv1(input: $input) {
      id
    }
  }
`;


//list proposal
export const listproposal = gql`
  query proposal($input: projectinput!) {
    listProposal(input: $input) {
        attachments
        status
        longdescription
        id
    }
  }
`;


//submit proposal
export const submitproposal = gql`
  mutation proposal($input: projectinput!) {
    submitProposal(input: $input) {
      id
    }
  }
`;

