import { gql } from "@apollo/client";

export const postUsecase = gql`
  mutation usecase($input: usecaseinput!) {
    addUsecaseShort(input: $input) {
      vbeid
      name
      url
    }
  }
`;

export const postCaseStudy = gql`
  mutation casestudy($input: casestudyinput!) {
    addCasestudyShort(input: $input) {
      name
      url
      vbeid
    }
  }
`;
