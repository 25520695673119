import { useState } from "react";

import { useStyles } from "./HelpStyles";
import HelpDailogBox from "./HelpDailogBox";

import { Button } from "@material-ui/core";

export default function HelpRequest({ state, refer }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [heading, setHeading] = useState("");
  const [statement, setStatement] = useState("");
  const [finalMsg, setFinalMsg] = useState("");
  const [inlay, setInlay] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const openDailogBox = (heading, statement, inlay, finalMsg) => {
    setOpen(true);
    setHeading(heading);
    setStatement(statement);
    setInlay(inlay);
    setFinalMsg(finalMsg);
  };

  return (
    <>
      <div className={classes.HelpIcon}>
        <Button
          variant="outlined"
          className={classes.reqBtn}
          onClick={() => {
            refer === "category"
              ? openDailogBox(
                  "Request new Category ",
                  "Let us know and our team will work on discovering products in this category",
                  "Add your requirement here",
                  "We have made note for creation of new category. Our team will review the request and will revert to you for any specific requirement. We thank you for your suggestion."
                )
              : openDailogBox(
                  "Looking for additional coverage in this category ",
                  "Let us know and our team will work on discovering products in this category",
                  "Add your requirement here",
                  "We received your request for deeper curation. Our team will review the request and will revert to you for any specific requirement in the next 72 hours. We thank you for your suggestion."
                );
          }}
          color="primary"
        >
          {refer === "category"
            ? "Request new Category"
            : "Don’t see Company/Product? "}
        </Button>
      </div>

      <HelpDailogBox
        open={open}
        close={handleClose}
        heading={heading}
        statement={statement}
        inlay={inlay}
        message={finalMsg}
      />
    </>
  );
}
