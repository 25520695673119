import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export default function LoadingResult() {
  return (
    <Grid>
      <Box m={2}>
        <Skeleton variant="text" width={400} />
        <Skeleton variant="text" width={400} />
        <Skeleton variant="text" width={400} />
      </Box>
    </Grid>
  );
}
