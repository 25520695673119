import CategoryProducts from "./CategoryProducts";

import { useStyles } from "./styles";

export default function CategoriesSearchResults({
  categories,
  categoryNames,
  type,
  open,
  rowType,
  onProductCardClick,
  searchContext,
  toggleValue,
  mainDrawerClick,
}) {
  const classes = useStyles();

  return (
    <div className={classes.productDiv}>
      {categories.length !== 0
        ? categories.map((category, index) => {
            return (
              <CategoryProducts
                category={category.toLowerCase()}
                categoryName={
                  categoryNames.length !== 0 ? categoryNames[index] : ""
                }
                type={type}
                open={open}
                rowType={rowType}
                onProductCardClick={onProductCardClick}
                searchContext={searchContext}
                toggleValue={toggleValue}
                mainDrawerClick={mainDrawerClick}
              />
            );
          })
        : null}
    </div>
  );
}
