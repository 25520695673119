import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  row: {
    padding: theme.spacing(2),
    textAlign: "left",
  },
  reviewTextRow: {
    padding: theme.spacing(0, 2),
    textAlign: "left",
  },
  textFieldLabel: {
    fontSize: theme.spacing(1.8),
    marginTop: theme.spacing(2),
    textAlign: "left",
  },
  validateMessage: {
    fontSize: "0.9rem",
    color: "#000",
    marginBottom: theme.spacing(1),
  },
  responseHeading: {
    marginTop: "8px",
    padding: "8px 0",
    backgroundColor: "#eee",
    fontWeight: "500",
    textAlign: "center",
  },
}));
