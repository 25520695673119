import * as React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  Paper,
  TableBody,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useFormik } from "formik";
import {
  LIST_CONTRACTITEMS_ITEM,
  SIGNED_URL,
  UPDATE_CONTRACT_ITEM,
} from "../../queries/query";

const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    display: "none",
  },
}));

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function UploadFile(props) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={8}>
        <input
          className={classes.input}
          id={"contractformid"}
          multiple
          type="file"
          onChange={(e) => {
            props.uploadFile(e);
          }}
        />

        <label htmlFor={"contractformid"}>
          <Button
            variant="contained"
            size="small"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Upload
          </Button>
        </label>
      </Grid>
      <Grid item xs={4}>
        {props.upload ? (
          <CircularProgressWithLabel
            color="secondary"
            value={props.uploadPer}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}

function DownLoadFile(props) {
  const classes = useStyles();

  const [dsignedUrl, { data: dsdata, loading: dsloading, error: dserror }] =
    useMutation(SIGNED_URL, {
      onCompleted: (e) => {
        let url = e.gettemplateSignedURL.signedurl;
        window.open(url);
      },
      onError: (err) => {
        console.log(err);
      },
    });

  function deleteFile() {
    props.formik.setFieldValue("attachmentfilename", "");
    props.formik.setFieldValue("attachment", "");
    props.setUpload(false);
    props.setUploadPer(2);
    props.setShowupload(true);
  }

  function downLoadFile() {
    dsignedUrl({
      variables: {
        input: {
          projectid: props.formik.values.projectid,
          productid: props.formik.values.productvbeid,
          attachmentfilename: props.name,
          method: "get",
        },
      },
    });
  }

  return (
    <Grid container>
      <Grid item xs={11}>
        <Button
          onClick={downLoadFile}
          color="secondary"
          size="small"
          component="span"
          className={classes.download}
          startIcon={<AttachFileIcon fontSize="small" />}
        >
          {props.name}
        </Button>
      </Grid>
      {props.readOnly ? null : (
        <Grid item xs={1}>
          <IconButton onClick={deleteFile}>
            <CloseIcon
              color="secondary"
              className={classes.deleteFile123344}
              fontSize="small"
            />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

const validateItem = (values) => {
  const errors = {};
  if (!values.value) {
    errors.value = "Required";
  }

  return errors;
};

function ViewHistory(props) {
  const classes = useStyles();
  const [dsignedUrl, { data: dsdata, loading: dsloading, error: dserror }] =
    useMutation(SIGNED_URL, {
      onCompleted: (e) => {
        let url = e.gettemplateSignedURL.signedurl;
        window.open(url);
      },
      onError: (err) => {
        console.log(err);
      },
    });

  return (
    <>
      {props.data ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Attachment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.getContractTempItemvalue.map((item, key) => {
                console.log(item);

                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      {new Date(parseInt(item.timestamp) * 1000).toDateString(
                        "en-US"
                      )}
                    </TableCell>
                    <TableCell>{item.value}</TableCell>
                    <TableCell>
                      {" "}
                      <Button
                        onClick={() => {
                          dsignedUrl({
                            variables: {
                              input: {
                                projectid: props.projectid,
                                productid: props.productvbeid,
                                attachmentfilename: item.attachmentfilename,
                                method: "get",
                              },
                            },
                          });
                        }}
                        color="secondary"
                        size="small"
                        component="span"
                        className={classes.download}
                        startIcon={<AttachFileIcon fontSize="small" />}
                      >
                        {item.attachmentfilename}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>{" "}
        </TableContainer>
      ) : null}
    </>
  );
}

export default function ViewContractForm(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [showUpload, setShowupload] = React.useState(true);
  const [upload, setUpload] = React.useState(false);
  const [uploadPer, setUploadPer] = React.useState(2);

  const {
    loading: hloading,
    error: herror,
    data: hdata,
  } = useQuery(LIST_CONTRACTITEMS_ITEM, {
    variables: {
      input: {
        sectionid: 1,
        projectid: props.projectId,
        productvbeid: props.productId,
      },
    },
  });

  if (hdata) {
    console.log(hdata);
  }

  const [updateItem, { data, loading, error }] = useMutation(
    UPDATE_CONTRACT_ITEM,
    {
      onCompleted: (e) => {
        console.log(e);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      projectid: props.projectId,
      productvbeid: props.productId,
      sectionid: "1",
      itemid: "1",
      value: "",
      attachment: "",
      attachmentfilename: "",
    },
    validate: validateItem,
    onSubmit: (values) => {
      console.log(values);
      updateItem({ variables: { input: values } });
    },
  });

  const [signedUrl, { data: sdata, loading: sloading, error: serror }] =
    useMutation(SIGNED_URL, {
      onCompleted: (e) => {
        let url = e.gettemplateSignedURL.signedurl;
        let attachment = e.gettemplateSignedURL.attachment;
        formik.setFieldValue("attachment", attachment);
        putFile(url);
      },
      onError: (err) => {
        console.log(err);
      },
    });

  function uploadFile(e) {
    setUpload(true);
    var tfile = e.target.files[0];
    let name = tfile.name;
    formik.setFieldValue("attachmentfilename", name);
    signedUrl({
      variables: {
        input: {
          projectid: formik.values.projectid,
          productid: formik.values.productvbeid,
          attachmentfilename: name,
          method: "put",
        },
      },
    });
  }

  async function putFile(url) {
    await axios
      .request({
        method: "put",
        url: url,
        data: document.getElementById("contractformid").files[0],
        onUploadProgress: (p) => {
          const percent = Math.round((p.loaded / p.total) * 100);
          setUploadPer(percent);
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setShowupload(false);
          setUpload(false);
        }
      });
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            id="filled-full-width"
            name="value"
            onChange={formik.handleChange}
            helperText={formik.errors.value}
            error={Boolean(formik.errors.value)}
            value={formik.values.value}
            placeholder={props.placeholder}
            fullWidth
            variant="outlined"
            size="small"
          />

          {showUpload ? (
            <UploadFile
              id={props.id}
              uploadFile={uploadFile}
              upload={upload}
              uploadPer={uploadPer}
            />
          ) : (
            <DownLoadFile
              name={formik.values.attachmentfilename}
              setUpload={setUpload}
              setShowupload={setShowupload}
              setUploadPer={setUploadPer}
              formik={formik}
              readOnly={props.readOnly}
            />
          )}
          <Button
            variant="outlined"
            color="secondary"
            onClick={formik.handleSubmit}
            style={{ margin: "5px" }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <ViewHistory
            data={hdata}
            projectid={props.projectId}
            productvbeid={props.productId}
          />
        </Grid>
      </Grid>
    </div>
  );
}
