import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";

import { LIST_QA_SECTIONS } from "../../queries/query";
import { useQuery } from "@apollo/client";

// import { customAlphabet } from "nanoid/non-secure";
import RTextItem from "../Items/both/textItem";
import RTextAreaItem from "../Items/both/textAreaItem";
import RSelectItem from "../Items/both/selectItem";
import RCurrencyItem from "../Items/both/currencyItem";
import RFileItem from "../Items/both/fileupload";
import RDateItem from "../Items/both/dateItem";
import RNumberItem from "../Items/both/numberItem";
// const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz-", 10);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
  },
  heading: {
    fontSize: theme.spacing(2),
  },
  properties: {
    width: theme.spacing(50),
  },
  propertiestitle: {
    marginBottom: theme.spacing(2),
  },
  propsbuttongroups: {
    marginTop: theme.spacing(2),
  },
  propsbutton: {
    margin: theme.spacing(1),
  },
  helpicon: {
    fontSize: theme.spacing(1.5),
    cursor: "pointer",
  },
  weight: {
    fontSize: theme.spacing(1.5),
  },
  caption: {
    color: "red",
  },
  tableIndexCell: {
    width: theme.spacing(25),
    fontWeight: "bold",
  },
  hidecell: {
    display: "none",
  },
}));

export default function RSection(props) {
  const classes = useStyles();

  //   const [items, setItems] = React.useState([]);
  //   const [metadata, setMetadata] = React.useState(props.metadata);

  const { data } = useQuery(LIST_QA_SECTIONS, {
    variables: {
      tinput: { sectionid: props.sid },
      pinput: { sectionid: props.sid, projectid: props.projectId },
    },
  });

  function getItem(pid, key, type, id, metadata, values) {
    switch (type) {
      case "text_field":
        return (
          <TableCell
            className={
              props.showProducts.includes(pid)
                ? classes.showcell
                : classes.hidecell
            }
          >
            <RTextItem
              key={key}
              id={id}
              sid={props.sid}
              productId={props.productId}
              type={props.type}
              metadata={metadata}
              values={values}
              readOnly={true}
              hidetitle={true}
            />
          </TableCell>
        );

      case "text_area":
        return (
          <TableCell
            className={
              props.showProducts.includes(pid)
                ? classes.showcell
                : classes.hidecell
            }
          >
            <RTextAreaItem
              rows={4}
              id={id}
              sid={props.sid}
              productId={props.productId}
              type={props.type}
              metadata={metadata}
              values={values}
              readOnly={true}
              hidetitle={true}
            />
          </TableCell>
        );

      case "multi_choice":
        return (
          <TableCell
            className={
              props.showProducts.includes(pid)
                ? classes.showcell
                : classes.hidecell
            }
          >
            <RSelectItem
              id={id}
              sid={props.sid}
              productId={props.productId}
              type={props.type}
              metadata={metadata}
              values={values}
              readOnly={true}
              hidetitle={true}
            />
          </TableCell>
        );

      case "currency":
        return (
          <TableCell
            className={
              props.showProducts.includes(pid)
                ? classes.showcell
                : classes.hidecell
            }
          >
            <RCurrencyItem
              id={id}
              sid={props.sid}
              productId={props.productId}
              type={props.type}
              metadata={metadata}
              values={values}
              readOnly={true}
              hidetitle={true}
            />
          </TableCell>
        );

      case "attachment":
        return (
          <TableCell
            className={
              props.showProducts.includes(pid)
                ? classes.showcell
                : classes.hidecell
            }
          >
            <RFileItem
              id={id}
              sid={props.sid}
              productId={props.productId}
              type={props.type}
              metadata={metadata}
              values={values}
              readOnly={true}
              hidetitle={true}
            />
          </TableCell>
        );

      case "date":
        return (
          <TableCell
            className={
              props.showProducts.includes(pid)
                ? classes.showcell
                : classes.hidecell
            }
          >
            <RDateItem
              id={id}
              sid={props.sid}
              productId={props.productId}
              type={props.type}
              metadata={metadata}
              values={values}
              readOnly={true}
              hidetitle={true}
            />
          </TableCell>
        );

      case "number":
        return (
          <TableCell
            className={
              props.showProducts.includes(pid)
                ? classes.showcell
                : classes.hidecell
            }
          >
            <RNumberItem
              id={id}
              sid={props.sid}
              productId={props.productId}
              type={props.type}
              metadata={metadata}
              values={values}
              readOnly={true}
              hidetitle={true}
            />{" "}
          </TableCell>
        );

      default:
        break;
    }
  }

  function getValues(values, itemid, productid) {
    const result = values.filter(
      (value) => value.itemid === itemid && value.productvbeid === productid
    );
    result.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp));

    return result ? result[0] : {};
  }

  return (
    <React.Fragment>
      {data
        ? data.listItem.map((item, index) => {
            var meta = JSON.parse(item.metadata);
            return (
              <TableRow>
                <TableCell className={classes.tableIndexCell}>
                  {meta.title}
                </TableCell>

                {props.products.map((product, index) => {
                  var values = getValues(
                    data.getQuestionTempItemvalue,
                    item.id,
                    product.productvbeid
                  );

                  var input = {
                    itemid: item.id,
                    sectionid: props.sid,
                    projectid: props.projectId,
                    productvbeid: props.productId,
                    attachment: "",
                    attachmentfilename: "",
                    timestamp: null,
                    value: "",
                    remarks: "",
                  };

                  input = { ...input, ...values };
                  delete input.__typename;

                  return getItem(
                    product.productvbeid,
                    item.id,
                    item.type,
                    item.id,
                    meta,
                    { ...input }
                  );
                })}
              </TableRow>
            );
          })
        : null}
    </React.Fragment>
  );
}
