import React from "react";

import { useParams } from "react-router-dom";

import {useQuery} from "@apollo/client";
import { listproposal } from "./query";
import ProjectRequirementSkeleton from '../../../shared/skeletons/projectRequirement';
import ResponseViewTabs from './responseViewTabs';

export default function ProjectResponse({productid, approved}){
    let { id } = useParams(); 

    const [longtext, setLongText] = React.useState('');
    const [value, setValue] = React.useState(false);
    const [savedRequirement, setSavedRequirement] = React.useState([]);
    const [submittedRequirement, setSubmittedRequirement] = React.useState([]);

    //listProposal
   useQuery(listproposal, {
    variables: { input: { vbeid: id, productvbeid: productid } }, fetchPolicy: "cache-and-network",
    onCompleted: (e) => {
        SavedObjects(e); SubmittedObjects(e);
      },
  });

  // if(listdata){
  //     console.log('list proposal',listdata)
  // }


  function SavedObjects(e){

    if(e.listProposal.length>=1){
    // eslint-disable-next-line array-callback-return
    let list = e.listProposal.map((value)=>{

      // return value.status==='save'?value:null
        if(value.status==='save'){
           return value}
       }).filter(x=>x!==undefined)
   setSavedRequirement(list);
   
   if(list.length>=1){
   setLongText(list[0].longdescription);}
   
   }
   setValue(true);
   }

   //proposals
   function SubmittedObjects(e){
    
    // eslint-disable-next-line array-callback-return
    let list = e.listProposal.map((value)=>{

      // return value.status==='submitted'?value:null
        if(value.status==='submitted'){
           return value}
       }).filter(x=>x!==undefined)

   setSubmittedRequirement(list);
   }



    return(
       
        <>
        {value?
        
        // <Response listdata={savedRequirement[0]} productid={productid}
        // longtext={longtext!=''?longtext:''}/>

        <ResponseViewTabs listdata={savedRequirement[0]} productid={productid}
        longtext={longtext!==''?longtext:''} approved={approved} submittedRequirement={submittedRequirement}/>

         :(<ProjectRequirementSkeleton/>)}
        </>
        
    )
}