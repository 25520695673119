import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

import { Paper } from "@material-ui/core";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";

import Policy from "../../../shared/policy";
import {
  getCasestudyByCasestudyId,
  getCasestudyById,
  updateCaseStudy,
  userInfoQuery,
} from "./query";
import DataPageHeader from "../../common/DataPageHeader";
import { userPermission } from "../../groups2.0/query";
import CaseStudyTab from "./CaseStudyTab";
import DataLoading from "../../products/product2.0/DataLoading";
import LeftPaneProducts from "../../products/product2.0/LeftPaneProducts";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
  },

  card: {
    maxWidth: "100%",
    color: "#0e3046",
    lineHeight: "1.8",
    position: "relative",
  },
  addList: {
    display: "block",
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    height: "0",

    position: "absolute",
    overflow: "visible",
    right: theme.spacing(1),
  },
  square: {
    width: 100,
    height: 100,
    marginTop: "1%",
    marginLeft: "1%",
  },

  ngrid: {
    marginBottom: "3%",
  },

  descriptionfield: {
    width: 600,
    fontSize: "3em",
  },
  subHeading: {
    color: theme.palette.secondary.main,
    textAlign: "left",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));

export default function CaseStudyPage({ vbeid, userInfo }) {
  const classes = useStyles();
  const client = useApolloClient();

  const [editName, setEditName] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editNameValue, setEditNameValue] = useState("");
  const [editDescriptionValue, setEditDescriptionValue] = useState("");
  const [isReadOnly, setReadonly] = useState(true);
  const [isAdmin, setAdmin] = useState(true);

  let { id } = useParams();
  if (vbeid) {
    id = vbeid;
  }

  const admin_role = "PROFILE_ADMIN";

  const editNameClick = () => {
    setEditName(true);
  };

  const saveNameClick = () => {
    setEditName(false);
    updateCache("name", editNameValue);
  };

  const editDescriptionClick = () => {
    setEditDescription(true);
  };

  const saveDescriptionClick = () => {
    setEditDescription(false);
    updateCache("shortdescription", editDescriptionValue);
  };

  ///list usecases by id details

  const { data } = useQuery(getCasestudyById, {
    variables: { input: { vbeid: id } },
    fetchPolicy: "cache-and-network",
    onCompleted: (e) => {
      afterLoadingMainData(e);
    },
  });

  //user information

  //   const user = client.readQuery({
  //     query: userInfoQuery,
  //     variables: { input: {} },
  //   });

  const afterLoadingMainData = (e) => {
    setAdmin(userInfo.companyvbeid === e.casestudyByID.companyvbeid);

    setReadonly(
      !(
        userInfo.companyvbeid === e.casestudyByID.companyvbeid &&
        userInfo.role.includes(admin_role)
      )
    );
  };

  ///update casestudy

  const [postCaseStudyMutation] = useMutation(updateCaseStudy);

  const postUpdateCasestudy = (paramName, paramValue, id) => {
    postCaseStudyMutation({
      variables: {
        input: {
          vbeid: id,
          [paramName]: paramValue,
        },
      },
    });
  };

  ////update cache
  const updateCache = (paramName, paramValue) => {
    if (paramValue !== "") {
      let updatedCasestudy = { ...data.casestudyByID };
      updatedCasestudy[paramName] = paramValue;

      client.writeQuery({
        query: getCasestudyById,
        data: {
          casestudyByID: updatedCasestudy,
        },

        variables: { input: { vbeid: id } },
      });
      postUpdateCasestudy(paramName, paramValue, id);
    }
  };

  //update cache for longdescription
  function UpdateCaseStudyCache(e) {
    let UpdatedlongDescData = e;
    let updatedCaseStudy = { ...data.usecaseByID };
    updatedCaseStudy.longdescription = UpdatedlongDescData;

    client.writeQuery({
      query: getCasestudyById,
      data: {
        casestudyByID: updatedCaseStudy,
      },
      variables: { input: { vbeid: id } },
    });
  }

  const handleNameChange = (e) => {
    setEditNameValue(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setEditDescriptionValue(e.target.value);
  };

  ///similar casestudy

  const { data: similarCasestudies } = useQuery(getCasestudyByCasestudyId, {
    variables: { input: { vbeid: id } },
  });

  const userpermission = client.readQuery({
    query: userPermission,
    variables: { input: {} },
  });

  return (
    <div className={classes.root}>
      <Box m={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <Paper elevation={1} square={true}>
              <Card className={classes.card}>
                {data ? (
                  <CardContent style={{ textAlign: "left" }}>
                    <DataPageHeader
                      data={data?.casestudyByID}
                      isReadOnly={true}
                      vbeid={id}
                      updateCache={updateCache}
                      editName={editName}
                      editDescription={editDescription}
                      editNameClick={editNameClick}
                      handleNameChange={handleNameChange}
                      saveNameClick={saveNameClick}
                      editDescriptionClick={editDescriptionClick}
                      handleDescriptionChange={handleDescriptionChange}
                      saveDescriptionClick={saveDescriptionClick}
                      type="success story"
                      isAdmin={isAdmin}
                      userPermission={userpermission}
                      userInfo={userInfo}
                    />

                    <CaseStudyTab
                      isReadOnly={true}
                      isAdmin={isAdmin}
                      UpdateCaseStudyCache={(e) => UpdateCaseStudyCache(e)}
                    />
                  </CardContent>
                ) : (
                  <DataLoading />
                )}
              </Card>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={3}>
            {isAdmin ? null : (
              <LeftPaneProducts
                title="Similar Sucess Stories"
                companyName={data?.productById?.companyname}
                products={similarCasestudies?.usecaseByusecaseID}
                vbeid={id}
                type="Success Stories"
              />
            )}

            <Policy />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
