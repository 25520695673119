import { Button, CircularProgress } from "@material-ui/core";

import { useStyles } from "./styles";

export default function StepperButtonGroup({
  loading,
  createCompanyLoading,
  stepIndex,
  firstTimeUser,
  formikValid,
  companyFormikValid,
  formikDirty,
  companyFormikDirty,
  onBackClick,
  saveProfileClick,
  saveCompanyProfile,
  nextClick,
  companyVbeid,
  showSteppers,
  groupsEdited,
}) {
  const classes = useStyles();

  return (
    <div className={classes.buttonGroup}>
      {showSteppers && (
        <Button
          variant="outlined"
          color="primary"
          disabled={stepIndex === 0 || loading}
          onClick={onBackClick}
          className={classes.stepperButtons}
        >
          Back
        </Button>
      )}

      {stepIndex === 1 && firstTimeUser && !showSteppers ? null : (
        <Button
          variant="contained"
          color="primary"
          onClick={
            stepIndex === 0
              ? saveProfileClick
              : stepIndex === 1
              ? saveCompanyProfile
              : nextClick
          }
          disabled={
            stepIndex === 0
              ? !showSteppers
                ? formikDirty || groupsEdited
                  ? false
                  : true
                : firstTimeUser !== "true"
                ? !formikValid || !formikDirty
                : !formikValid || loading
              : companyVbeid === ""
          }
          className={classes.stepperButtons}
        >
          {loading ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : stepIndex === 0 && !formikDirty ? (
            showSteppers ? (
              "Next"
            ) : (
              "Save"
            )
          ) : stepIndex === 1 && firstTimeUser ? (
            "Next"
          ) : stepIndex === 2 ? (
            "Home"
          ) : (
            "Save"
          )}
        </Button>
      )}
    </div>
  );
}
