import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  infoData: {
    fontSize: "0.75rem",
    marginTop: theme.spacing(1),
  },
  companyInfoDetails: {
    display: "block",
    textAlign: "center",
  },
  infoTitle: {
    fontSize: "0.7rem",
    color: "#999",
  },
}));

const getVerticals = (data) => {
  return data.map((vertical) => {
    return <div>{vertical}</div>;
  });
};

export default function InfoGrid({ title, data }) {
  const classes = useStyles();
  return (
    <div className={classes.companyInfoDetails}>
      <Typography className={classes.infoTitle}>{title}</Typography>
      <Typography variant="body1" className={classes.infoData}>
        {title === "Vertical" ? getVerticals(data) : data}
      </Typography>
    </div>
  );
}
