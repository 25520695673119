import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";

import { useFormik } from "formik";

import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import {
  GET_PRODUCTS_BY_PROJECT,
  LIST_EVALUATION_SECTIONS,
  LIST_EVAL_SECTIONS,
} from "../../queries/query";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import { customAlphabet } from "nanoid/non-secure";

import RTextItem from "../Items/both/textItem";
import RTextAreaItem from "../Items/both/textAreaItem";
import RSelectItem from "../Items/both/selectItem";
import RCurrencyItem from "../Items/both/currencyItem";
import RFileItem from "../Items/both/fileupload";
import RDateItem from "../Items/both/dateItem";
import RNumberItem from "../Items/both/numberItem";

import { useStyles } from "./styles";

import EvaluateItemValue from "./EvaluateItemValue";

export default function RSection(props) {
  const classes = useStyles();

  const { data, refetch } = useQuery(LIST_EVAL_SECTIONS, {
    variables: {
      tinput: { sectionid: props.sid },
      //   pinput: {
      //     sectionid: props.sid,
      //     projectid: props.projectId,
      //     productvbeid: props.productId,
      //   },
    },
  });

  //   function getItem(pid, key, type, id, metadata, values) {
  //     switch (type) {
  //       case "text_field":
  //         return (
  //           <TableCell>
  //             <RTextItem
  //               key={key}
  //               id={id}
  //               sid={props.sid}
  //               productId={pid}
  //               type={props.type}
  //               metadata={metadata}
  //               values={values}
  //               hidetitle={true}
  //               refetch={refetch}
  //               readOnly={props.readOnly}
  //             />
  //           </TableCell>
  //         );

  //       case "text_area":
  //         return (
  //           <TableCell>
  //             <RTextAreaItem
  //               rows={4}
  //               id={id}
  //               sid={props.sid}
  //               productId={pid}
  //               type={props.type}
  //               metadata={metadata}
  //               values={values}
  //               hidetitle={true}
  //               refetch={refetch}
  //             />
  //           </TableCell>
  //         );

  //       case "multi_choice":
  //         return (
  //           <TableCell>
  //             <RSelectItem
  //               id={id}
  //               sid={props.sid}
  //               productId={pid}
  //               type={props.type}
  //               metadata={metadata}
  //               values={values}
  //               hidetitle={true}
  //             />
  //           </TableCell>
  //         );

  //       case "currency":
  //         return (
  //           <TableCell>
  //             <RCurrencyItem
  //               id={id}
  //               sid={props.sid}
  //               productId={pid}
  //               type={props.type}
  //               metadata={metadata}
  //               values={values}
  //               hidetitle={true}
  //             />
  //           </TableCell>
  //         );

  //       case "attachment":
  //         return (
  //           <TableCell>
  //             <RFileItem
  //               id={id}
  //               sid={props.sid}
  //               productId={pid}
  //               type={props.type}
  //               metadata={metadata}
  //               values={values}
  //               hidetitle={true}
  //             />
  //           </TableCell>
  //         );

  //       case "date":
  //         return (
  //           <TableCell>
  //             <RDateItem
  //               id={id}
  //               sid={props.sid}
  //               productId={pid}
  //               type={props.type}
  //               metadata={metadata}
  //               values={values}
  //               hidetitle={true}
  //             />
  //           </TableCell>
  //         );

  //       case "number":
  //         return (
  //           <TableCell>
  //             <RNumberItem
  //               id={id}
  //               sid={props.sid}
  //               productId={pid}
  //               type={props.type}
  //               metadata={metadata}
  //               values={values}
  //               hidetitle={true}
  //             />
  //           </TableCell>
  //         );

  //       default:
  //         break;
  //     }
  //   }

  //   function getValues(values, itemid, productid) {
  //     if (values.length !== 0) {
  //       const result = values.filter(
  //         (value) => value.itemid === itemid && value.productvbeid === productid
  //       );
  //       result.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp));

  //       return result ? result[0] : {};
  //     } else return {};
  //   }

  return (
    <React.Fragment>
      {data
        ? data.listItem.map((item, index) => {
            var meta = JSON.parse(item.metadata);

            return (
              <TableRow>
                <TableCell className={classes.tableIndexCell}>
                  {meta.title}
                </TableCell>

                {props.products.map((product, index) => {
                  return (
                    <EvaluateItemValue
                      item={item}
                      product={product}
                      sid={props.sid}
                      meta={meta}
                      templateType={props.type}
                      person={props.person}
                      readOnly={props.readOnly}
                    />
                  );
                })}
              </TableRow>
            );
          })
        : null}
    </React.Fragment>
  );
}
