import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";

import ProjectTypeButton from "./ProjectTypeButton";

export function CreateProjectModal({ open, handleClose, onCreateClick }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Create Project</DialogTitle>

      <DialogContent style={{ margin: "0 0 8px" }}>
        <DialogContentText>
          Select the type of Project you want to create
        </DialogContentText>

        <Grid container spacing={1}>
          <Grid md={5}>
            <ProjectTypeButton
              label="Work Package Services"
              onClick={() => {
                onCreateClick("workpackage");
              }}
              description="This will create a service project"
            />
          </Grid>

          <Grid md={2}></Grid>

          <Grid md={5}>
            <ProjectTypeButton
              label="Contract Resource Services"
              onClick={() => {
                onCreateClick("service-timeAndMaterial");
              }}
              description="This will create a contract resource project"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
