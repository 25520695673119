import * as React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import TextItem from "./items/textItem";
import TextAreaItem from "./items/textAreaItem";
import SelectItem from "./items/selectItem";
import FileItem from "./items/fileupload";
import TextShortItem from "./items/textshortItem";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddItemMenus from "./desing/addItem";
import Drawer from "@material-ui/core/Drawer";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

import { useFormik } from "formik";
import DateItem from "./items/dateItem";
import NumberItem from "./items/numberItem";
import CurrencyItem from "./items/currencyItem";

import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import {
  DELETE_SECTION,
  EDIT_SECTION,
  GET_SECTIONS,
  ADD_ITEM_SECTION,
  GET_ITEMS_BY_SECTION,
} from "../../queries/query";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import { customAlphabet } from "nanoid/non-secure";
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz-", 10);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
  },
  heading: {
    fontSize: theme.spacing(2),
  },
  properties: {
    width: theme.spacing(50),
  },
  propertiestitle: {
    marginBottom: theme.spacing(2),
  },
  propsbuttongroups: {
    marginTop: theme.spacing(2),
  },
  propsbutton: {
    margin: theme.spacing(1),
  },
  helpicon: {
    fontSize: theme.spacing(1.5),
    cursor: "pointer",
  },
}));

const TEXT_FIELD_PROPS = {
  title: "Please edit title",
  help_message: "",
  short: true,
};
const NUMBER_PROPS = {
  title: "Please edit title",
  help_message: "",
  short: true,
};
const TEXT_AREA_PROPS = {
  title: "Please edit title",
  help_message: "",
  rows: 4,
};
const MULTIPLE_CHOICE_PROPS = {
  title: "Plese edit title",
  help_message: "",
  options: "",
};
const CURRENCY_PROPS = {
  title: "Please edit title",
  help_message: "",
  currency: "USD",
};
const DATE_PROPS = { title: "Please edit title", help_message: "" };
const ATTACHMENT_PROPS = { title: "Please edit title", help_message: "" };

function SectionForm(props) {
  const classes = useStyles();

  const [editSection, { data, loading, error }] = useMutation(EDIT_SECTION, {
    onCompleted: (e) => {
      console.log(e);
    },
  });

  const formik = useFormik({
    initialValues: props.metadata,
    onSubmit: (values) => {
      props.setMetadata(values);
      props.setState(false);
      let input = {
        vbeid: props.vbeid,
        id: props.id,
        metadata: JSON.stringify(values),
      };
      // console.log(input)
      editSection({ variables: { input: input } });
    },
  });

  function onClose() {
    props.setState(false);
  }

  return (
    <Box m={2} className={classes.properties}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12} className={classes.propertiestitle}>
            <Typography variant="subtitle" gutterBottom>
              Properties{" "}
            </Typography>
            <Divider />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption" gutterBottom>
            {" "}
            Section title{" "}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="title"
            id="filled-full-width"
            placeholder="Enter section title"
            fullWidth
            variant="outlined"
            size="small"
            onChange={formik.handleChange}
            value={formik.values.title}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption" gutterBottom>
            {" "}
            Help Message{" "}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="help_message"
            multiline
            rows={4}
            id="filled-full-width"
            fullWidth
            variant="outlined"
            size="small"
            onChange={formik.handleChange}
            value={formik.values.help_message}
          />
        </Grid>

        <Grid item xs={12} className={classes.propsbuttongroups}>
          <Button
            variant="outlined"
            startIcon={<CloseIcon />}
            size="small"
            color="secondary"
            className={classes.propsbutton}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            type="submit"
            startIcon={<SaveIcon />}
            size="small"
            color="secondary"
            className={classes.propsbutton}
          >
            Save
          </Button>
        </Grid>
      </form>
    </Box>
  );
}

export default function RSection(props) {
  const classes = useStyles();
  const client = useApolloClient();

  const [state, setState] = React.useState(false);
  const [preview, setPreview] = React.useState(props.preview);

  const [items, setItems] = React.useState([]);
  const [metadata, setMetadata] = React.useState(props.metadata);

  const { loading, error, data } = useQuery(GET_ITEMS_BY_SECTION, {
    fetchPolicy: "network-only",
    variables: { input: { sectionid: props.sid } },
  });

  if (data) {
    //console.log(data)
  }

  const [createItem] = useMutation(ADD_ITEM_SECTION, {
    onCompleted: (e) => {
      // console.log(props.sid)
      //console.log(e)
    },
  });

  const [deleteSection] = useMutation(DELETE_SECTION, {
    onCompleted: (e) => {
      // console.log(e)
    },
  });

  function removeSection() {
    let values = {
      id: props.sid,
      vbeid: props.vbeid,
    };

    const { listSection } = client.readQuery({
      query: GET_SECTIONS,
      variables: { input: { vbeid: props.vbeid } },
    });

    var newlist = listSection.filter((section) => section.id != props.sid);

    client.writeQuery({
      query: GET_SECTIONS,
      data: {
        listSection: newlist,
      },
      variables: { input: { vbeid: props.vbeid } },
    });

    deleteSection({ variables: { input: values } });
  }

  function addItemToSection(sectionid, type, metadata) {
    const nano_id = nanoid();
    let values = {
      id: nano_id,
      vbeid: props.sid,
      type: type,
      metadata: JSON.stringify(metadata),
    };
    let cvalues = {
      id: nano_id,
      sectionid: props.sid,
      type: type,
      metadata: JSON.stringify(metadata),
    };

    createItem({ variables: { input: cvalues } });

    const { listItem } = client.readQuery({
      query: GET_ITEMS_BY_SECTION,
      variables: { input: { sectionid: props.sid } },
    });

    var newlist = [...listItem, { __typename: "template", ...values }];

    client.writeQuery({
      query: GET_ITEMS_BY_SECTION,
      data: {
        listItem: newlist,
      },
      variables: { input: { sectionid: props.sid } },
    });
  }

  function addItem(type) {
    switch (type) {
      case "text_field":
        var text_field = {
          id: 1,
          type: "text_field",
          metadata: TEXT_FIELD_PROPS,
        };
        addItemToSection(props.sid, "text_field", TEXT_FIELD_PROPS);
        break;

      case "text_area":
        var text_area = { id: 1, type: "text_area", metadata: TEXT_AREA_PROPS };
        addItemToSection(props.sid, "text_area", TEXT_AREA_PROPS);
        break;

      case "multi_choice":
        var multi_choice = {
          id: 1,
          type: "multi_choice",
          metadata: MULTIPLE_CHOICE_PROPS,
        };
        addItemToSection(props.sid, "multi_choice", MULTIPLE_CHOICE_PROPS);
        break;

      case "currency":
        var currency = { id: 1, type: "currency", metadata: CURRENCY_PROPS };
        addItemToSection(props.sid, "currency", CURRENCY_PROPS);
        break;

      case "attachment":
        var attachment = {
          id: 1,
          type: "attachment",
          metadata: ATTACHMENT_PROPS,
        };
        addItemToSection(props.sid, "attachment", ATTACHMENT_PROPS);
        break;

      case "date":
        var date = { id: 1, type: "date", metadata: DATE_PROPS };
        addItemToSection(props.sid, "date", DATE_PROPS);
        break;

      case "number":
        var number = { id: 1, type: "number", metadata: NUMBER_PROPS };
        addItemToSection(props.sid, "number", NUMBER_PROPS);
        break;

      default:
        break;
    }
  }

  function getItem(type, id, metadata) {
    switch (type) {
      case "text_field":
        return (
          <TextItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            preview={preview}
            disabled={props.disabled}
          />
        );
      case "text_area":
        return (
          <TextAreaItem
            rows={4}
            name="test"
            id={id}
            sid={props.sid}
            metadata={metadata}
            preview={preview}
            disabled={props.disabled}
          />
        );

      case "multi_choice":
        return (
          <SelectItem
            options={[]}
            id={id}
            sid={props.sid}
            metadata={metadata}
            preview={preview}
            disabled={props.disabled}
          />
        );

      case "currency":
        return (
          <CurrencyItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            preview={preview}
            disabled={props.disabled}
          />
        );

      case "attachment":
        return (
          <FileItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            preview={preview}
            disabled={props.disabled}
          />
        );

      case "date":
        return (
          <DateItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            preview={preview}
            disabled={props.disabled}
          />
        );

      case "number":
        return (
          <NumberItem
            id={id}
            sid={props.sid}
            metadata={metadata}
            preview={preview}
            disabled={props.disabled}
          />
        );

      default:
        break;
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(!open);
  };

  return (
    <Grid container className={classes.root}>
      <Drawer anchor={"left"} open={state} onClose={toggleDrawer(false)}>
        <SectionForm
          setState={setState}
          metadata={metadata}
          setMetadata={setMetadata}
          id={props.sid}
          vbeid={props.vbeid}
        />
      </Drawer>

      <Grid item xs={1}>
        <Avatar className={classes.small} color="primary">
          <Typography variant="caption" display="block">
            {metadata.id}
          </Typography>
        </Avatar>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="h6" display="block" className={classes.heading}>
          {metadata.title}
          <Tooltip title={metadata.help_message}>
            <HelpIcon fontSize="small" className={classes.helpicon} />
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item xs={2}>
        {props.type === "evaluate_template" ? (
          <Typography variant="caption" className={classes.weight}>
            Weight:{metadata.weight}
          </Typography>
        ) : null}
        {preview ? null : (
          <>
            <IconButton
              size="small"
              onClick={() => {
                removeSection();
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                setState(true);
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </>
        )}
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {preview ? null : <AddItemMenus addItem={addItem} />}
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        {data
          ? data.listItem.map((item) => {
              var meta = JSON.parse(item.metadata);

              return getItem(item.type, item.id, meta);
            })
          : null}
      </Grid>
    </Grid>
  );
}
