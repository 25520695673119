import React, { useEffect, useRef, useState } from 'react'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DayjsUtils from '@date-io/dayjs'
import { useParams } from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  CircularProgress,
  TextField,
} from '@material-ui/core'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

import SimpleSnackbar from './snackbar'
import SimpleErrorSnackbar from './errorsnackbar'

import { useMutation, useQuery, useApolloClient } from '@apollo/client'

import { useFormik } from 'formik'
import * as yup from 'yup'

import { getCompanyById, getCompanyVerticals, postCompanyInfo } from '../query'

import { useStyles } from '../styles'

import InputField from '../../../common/InputField'
import SelectField from '../../../common/SelectField'
import AutoCompleteField from '../../../common/AutoCompleteField'

const states = require('../../../../../data/states_bycountries.json')
const countries = require('../../../../../data/countriesdata.json')

export function NewGroup({ values, isReadOnly }) {
  const [open, setOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [scroll] = useState('paper')
  const [selectedValue, setSelectedValues] = useState(values.location)
  const [selectedState, setSelectedState] = useState(values.location_state)
  const [countrychange, setCountryChange] = useState(true)
  const [countryvbeid, setCountryvbeid] = useState('')
  const [verticalSelect, setVerticalSelect] = useState(values.businessvertical)
  //const [foundedYear, setFounded] = useState(values.founded)
  const [founded, handleDateChange] = useState(values.founded)

  const timer = useRef()

  const client = useApolloClient()
  const { id } = useParams()
  const classes = useStyles()

  const noOfEmployees = [
    'Upto 10',
    '11 to 20',
    '21 to 100',
    '101 to 1000',
    '1000 to 10,000',
    '10,000 & Above',
  ]

  const allCertificates = ['SoC2', 'ISO ', '27001']

  const revenueValues = [
    'Less than $1M',
    '$1M to $10M',
    '$10M to $50M',
    '$50M to 100M',
    '$100M to $500M',
    '$500M and $1B',
    '$1B to $10B',
    ' $10B and above',
  ]

  let optCountries = []
  countries.map((country) => {
    return optCountries.push({
      name: country.countryname,
      value: country.countryname,
      vbeid: country.countryvbeid,
    })
  })

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const handleButtonClick = () => {
    if (!mutationLoading) {
      timer.current = window.setTimeout(() => {}, 500)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    formik.resetForm()
  }

  const descriptionElementRef = useRef(null)
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const { data: companyVerticals } = useQuery(getCompanyVerticals, {
    variables: { input: { vbeid: id } },
  })

  const [
    postCompanyMutation,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(postCompanyInfo, {
    onCompleted: (e) => {
      handleClose()
      updateCache(e)
    },
  })

  const updateCompany = (e) => {
    e.preventDefault()

    const {
      founder,
      //founded,
      location,
      companyurl,
      linkedinurl,
      facebookurl,
      twitterurl,
      locationstate,
      addemployees,
      allCertificates,
      companyrevenue,
    } = formik.values

    postCompanyMutation({
      variables: {
        input: {
          founder: founder,
          founded: founded ? founded['$d'].getFullYear() : '-',
          location: location,
          companyurl: companyurl,
          linkedinurl: linkedinurl,
          facebookurl: facebookurl,
          twitterurl: twitterurl,
          businessvertical: verticalSelect,
          vbeid: id,
          employeeCount: addemployees,
          certifications: allCertificates,
          revenue: companyrevenue,
          location_state: locationstate,
        },
      },
    })

    handleButtonClick()
  }

  const lnurl =
    '^(http(s)?://)?(www.)?linkedin.com/(in|profile|pub|company)/([A-z 0-9 _ -]+)/?$'
  const fburl = '^(https?://)?(www.)?facebook.com/[a-zA-Z0-9(.?)?]'
  const turl = '^(https?://)?(www.)?twitter.com/[a-zA-Z0-9(.?)?]'

  const validationSchema = yup.object().shape({
    founder: yup
      .string('Enter Name')
      .min(3, 'Name should be of minimum 2 characters length')
      .required('Name is required'),

    // founded: yup.string().required('Year required*'),

    // businessvertical: yup
    //   .array("Enter your vertical Role")
    //   .required("vertical is required"),

    linkedinurl: yup
      .string('Enter your Linkedin URL')
      .nullable()
      .matches(lnurl, 'Enter valid Linkedin URL'),

    facebookurl: yup
      .string('Enter your Facebook URL')
      .nullable()
      .matches(fburl, 'Enter valid Facebook URL'),

    twitterurl: yup
      .string('Enter your Twitter URL')
      .nullable()
      .matches(turl, 'Enter valid Twitter URL'),

    companyurl: yup
      .string('Enter your Company URL')
      .nullable()
      .required('Company URL is required')
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        'Enter valid Company URL',
      ),

    companyrevenue: yup.string('Enter revenue').required('Revenue is required'),

    addemployees: yup
      .string()
      .oneOf(noOfEmployees, 'Select an option')
      .required('Employee count  is required'),

    // locationstate: yup.string("Enter state").required("State is required"),
  })

  const { data: list } = useQuery(getCompanyById, {
    variables: { input: { vbeid: id } },
  })

  /////update cache for companyinformation

  const updateCache = (e) => {
    const { queryCompanybyid } = client.readQuery({
      query: getCompanyById,
      variables: { input: { vbeid: id } },
    })
    let updatedCompany = { ...queryCompanybyid }
    updatedCompany = { ...e.UpdateCompany }

    client.writeQuery({
      query: getCompanyById,
      data: {
        queryCompanybyid: updatedCompany,
      },
      variables: { input: { vbeid: id } },
    })
  }

  const {
    founder,
    //founded,
    location,
    companyurl,
    linkedinurl,
    facebookurl,
    twitterurl,
    employeeCount,
    revenue,
    certifications,
    location_state,
    integrations,
    maturity,
  } = values

  let employees = []

  noOfEmployees.forEach((count) => {
    employees.push({
      value: count,
      type: count,
    })
  })

  //Certifications
  let certifcates = []

  allCertificates.forEach((name) => {
    certifcates.push({
      value: name,
      type: name,
    })
  })

  // revenue
  let selectedRevenue = []

  revenueValues.forEach((revenue) => {
    selectedRevenue.push({
      value: revenue,
      type: revenue,
    })
  })

  const formik = useFormik({
    initialValues: {
      founder: founder,
      founded: founded,
      location: location,
      companyurl: companyurl,
      linkedinurl: linkedinurl,
      facebookurl: facebookurl,
      twitterurl: twitterurl,
      addemployees: employeeCount,
      allCertificates: certifications,
      companyrevenue: revenue,
      locationstate: location_state,
    },

    validationSchema: validationSchema,
  })

  let optStates = []

  const onCountrySelect = (values, selectedVbeid) => {
    setCountryChange(false)
    setSelectedValues(values)
    formik.values.location = values
    setCountryvbeid(selectedVbeid)
  }

  let countryid = ''

  countries.map((country) => {
    if (country.countryname === selectedValue) {
      return (countryid = country.countryvbeid)
    } else return null
  })

  states.map((state) => {
    if (countryid === state['cntry.vbeid']) {
      return optStates.push({
        name: state['ste.name'],
        value: state['ste.name'],
        vbeid: state['cntry.vbeid'],
      })
    } else return null
  })

  const onStateSelect = (values, selectedVbeid) => {
    setCountryChange(false)
    setSelectedState(values)
    formik.values.state = values
    setCountryvbeid(selectedVbeid)
  }

  const onVerticalSelect = (values, selectedVbeid) => {
    let companyVertical = []

    values.forEach((vertical) => {
      companyVertical.push(vertical.vbeid)
    })

    setVerticalSelect(companyVertical)
  }

  let defaultVerticals = []

  if (companyVerticals) {
    companyVerticals.listCompanyVertical.forEach((companyVertical) => {
      verticalSelect.forEach((vertical) => {
        if (companyVertical.vbeid === vertical) {
          defaultVerticals.push(companyVertical)
        }
      })
    })
  }

  return (
    <div>
      {mutationError && <SimpleErrorSnackbar />}
      {data && <SimpleSnackbar />}

      {isReadOnly ? null : (
        <EditOutlinedIcon
          fontSize="small"
          onClick={() => {
            handleClickOpen()
          }}
        />
      )}

      <form>
        {list ? (
          <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <Grid>
              <DialogTitle
                id="scroll-dialog-title"
                className={classes.updateHeading}
              >
                Update Company Information
              </DialogTitle>

              <DialogContent
                dividers={scroll === 'paper'}
                className={classes.dialogContent}
              >
                <Grid container>
                  <div className={classes.management}>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.formControl}
                      >
                        <InputField
                          label="Management Team *"
                          value={formik.values.founder}
                          onChange={formik.handleChange('founder')}
                          onBlur={formik.handleBlur('founder')}
                          multiple={true}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={12} className={classes.errorfields}>
                      {formik.touched.founder && formik.errors.founder ? (
                        <div className="error-message">
                          {formik.errors.founder}
                        </div>
                      ) : null}
                    </Grid>
                  </div>

                  <div className={classes.headquarters}>
                    <Grid item md={12}>
                      <h6>Headquarters</h6>
                    </Grid>
                  </div>

                  <div className={classes.country}>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.formControl}
                      >
                        <AutoCompleteField
                          label="Country *"
                          options={optCountries}
                          setValues={onCountrySelect}
                          multiple={false}
                          defaultValue={selectedValue}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={6} className={classes.errorfields}>
                      {formik.touched.location && formik.errors.location ? (
                        <div className="error-message">
                          {formik.errors.location}
                        </div>
                      ) : null}
                    </Grid>
                  </div>

                  <div className={classes.country}>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.formControl}
                      >
                        <AutoCompleteField
                          label="State *"
                          options={optStates}
                          setValues={onStateSelect}
                          defaultValue={selectedState}
                          multiple={false}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={6} className={classes.errorfields}>
                      {formik.touched.locationstate &&
                      formik.errors.locationstate ? (
                        <div className="error-message">
                          {formik.errors.locationstate}
                        </div>
                      ) : null}
                    </Grid>
                  </div>

                  {/* <div className={classes.formDiv}>
                    <Grid item md={12}>
                      <MapCountries
                        addCountries={addCountries}
                        showLocations={showLocations}
                        onShowLocationClick={onShowLocationClick}
                        presentCountries={countryList}
                      />
                    </Grid>
                  </div> */}

                  <div className={classes.yrefields}>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.formControl}
                      >
                        {/* <InputField
                          label="Founded Year *"
                          value={formik.values.founded}
                          onChange={formik.handleChange('founded')}
                          onBlur={formik.handleBlur('founded')}
                          multiple={true}
                          type="number"
                          min="1700"
                          max="2022"
                        /> */}

                        <MuiPickersUtilsProvider utils={DayjsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            label={'Founded Year'}
                            views={['year']}
                            value={founded}
                            inputVariant="outlined"
                            onChange={handleDateChange}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={null}
                                size="small"
                              />
                            )}
                          />
                        </MuiPickersUtilsProvider>
                      </FormControl>
                    </Grid>

                    <Grid item md={12} className={classes.errorfields}>
                      {formik.touched.founded && formik.errors.founded ? (
                        <div className="error-message">
                          {formik.errors.founded}
                        </div>
                      ) : null}
                    </Grid>
                  </div>

                  <div className={classes.yrefields}>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.formControl}
                      >
                        <SelectField
                          label="Estimated Revenue*"
                          value={formik.values.companyrevenue}
                          onChange={formik.handleChange('companyrevenue')}
                          onBlur={formik.handleBlur('companyrevenue')}
                          options={selectedRevenue}
                          type="number"
                          field="companypage"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={12} className={classes.errorfields}>
                      {formik.touched.revenue && formik.errors.revenue ? (
                        <div className="error-message">
                          {formik.errors.revenue}
                        </div>
                      ) : null}
                    </Grid>
                  </div>

                  <div className={classes.yrefields}>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.formControl}
                      >
                        <SelectField
                          label="Employee Count *"
                          onChange={formik.handleChange('addemployees')}
                          onBlur={formik.handleBlur('addemployees')}
                          value={formik.values.addemployees}
                          options={employees}
                          type="employees"
                          field="companypage"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={12} className={classes.errorfields}>
                      {formik.touched.addemployees &&
                      formik.errors.addemployees ? (
                        <div className="error-message">
                          {formik.errors.addemployees}
                        </div>
                      ) : null}
                    </Grid>
                  </div>

                  {/* //certifcates */}

                  {/* <div className={classes.formDiv}>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.formControl}
                      >
                        <SelectField
                          label="Certifications *"
                          onChange={formik.handleChange("allCertificates")}
                          value={formik.values.allCertificates}
                          options={certifcates}
                          //multiple={true}
                          type="certificates"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={12} className={classes.errorfields}>
                      {formik.touched.allCertificates &&
                      formik.errors.allCertificates ? (
                        <div className="error-message">
                          {formik.errors.allCertificates}
                        </div>
                      ) : null}
                    </Grid>
                  </div> */}

                  <div className={classes.formDiv}>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.formControl}
                      >
                        <InputField
                          label="Company URL *"
                          value={formik.values.companyurl}
                          onChange={formik.handleChange('companyurl')}
                          onBlur={formik.handleBlur('companyurl')}
                          multiple={true}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={12} className={classes.errorfields}>
                      {formik.touched.companyurl && formik.errors.companyurl ? (
                        <div className="error-message">
                          {formik.errors.companyurl}
                        </div>
                      ) : null}
                    </Grid>
                  </div>

                  <div className={classes.formDiv}>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.formControl}
                      >
                        <InputField
                          label="Facebook URL"
                          value={formik.values.facebookurl}
                          onChange={formik.handleChange('facebookurl')}
                          // onBlur={formik.handleBlur("facebookurl")}
                          multiple={true}
                        />
                      </FormControl>
                    </Grid>

                    {/* <Grid item md={12} className={classes.errorfields}>
                      {formik.touched.facebookurl &&
                      formik.errors.facebookurl ? (
                        <div className="error-message">
                          {formik.errors.facebookurl}
                        </div>
                      ) : null}
                    </Grid> */}
                  </div>

                  <div className={classes.formDiv}>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.formControl}
                      >
                        <InputField
                          label="Twitter URL"
                          value={formik.values.twitterurl}
                          onChange={formik.handleChange('twitterurl')}
                          // onBlur={formik.handleBlur("twitterurl")}
                          multiple={true}
                        />
                      </FormControl>
                    </Grid>

                    {/* <Grid item md={12} className={classes.errorfields}>
                      {formik.touched.twitterurl && formik.errors.twitterurl ? (
                        <div className="error-message">
                          {formik.errors.twitterurl}
                        </div>
                      ) : null}
                    </Grid> */}
                  </div>

                  <div className={classes.formDiv}>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.formControl}
                      >
                        <InputField
                          label="LinkedIn URL"
                          value={formik.values.linkedinurl}
                          onChange={formik.handleChange('linkedinurl')}
                          // onBlur={formik.handleBlur("linkedinurl")}
                          multiple={true}
                        />
                      </FormControl>
                    </Grid>

                    {/* <Grid item md={12} className={classes.errorfields}>
                      {formik.touched.linkedinurl &&
                      formik.errors.linkedinurl ? (
                        <div className="error-message">
                          {formik.errors.linkedinurl}
                        </div>
                      ) : null}
                    </Grid> */}
                  </div>

                  <div className={classes.formDiv}>
                    {companyVerticals && (
                      <Grid item md={12}>
                        <FormControl
                          variant="outlined"
                          fullWidth={true}
                          className={classes.formControl}
                        >
                          <AutoCompleteField
                            label="Business Vertical *"
                            options={companyVerticals.listCompanyVertical}
                            setValues={onVerticalSelect}
                            multiple={true}
                            defaultValue={defaultVerticals}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item md={12} className={classes.errorfields}>
                      {formik.touched.businessvertical &&
                      formik.errors.businessvertical ? (
                        <div className="error-message">
                          {formik.errors.businessvertical}
                        </div>
                      ) : null}
                    </Grid>
                  </div>
                </Grid>
              </DialogContent>
            </Grid>

            <DialogActions>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleClose}
                className={classes.actionBtn}
              >
                Cancel
              </Button>

              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={updateCompany}
                  disabled={
                    verticalSelect.length !== 0
                      ? false
                      : selectedState !== location_state &&
                        formik.values.companyrevenue &&
                        formik.values.addemployees &&
                        formik.values.founded
                      ? formik.isValid && formik.dirty
                      : !(formik.isValid && formik.dirty)
                  }
                  className={classes.actionBtn}
                >
                  Save
                </Button>

                {mutationLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.updateButtonProgress}
                  />
                )}
              </div>
            </DialogActions>
          </Dialog>
        ) : null}
      </form>
    </div>
  )
}

export default NewGroup
