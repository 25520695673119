import { makeStyles } from "@material-ui/core/styles";

import { useParams } from "react-router-dom";

import { GET_AWARD_TEMPLATE } from "../../queries/query";

import { useQuery } from "@apollo/client";

import EvaluationForm from "../../forms/others/evaluationForm";

import LoadingResult from "../../../../shared/LoadingResult";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(2),
  },
  emptyText: {
    color: "#999",
    fontStyle: "italic",
  },
}));

export default function ViewAward({ projectData }) {
  const classes = useStyles();
  const { id, productId } = useParams();

  const { loading, data } = useQuery(GET_AWARD_TEMPLATE, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id, typelist: ["sow_template"] } },
  });

  return (
    <div className={classes.root}>
      {data ? (
        projectData.role.includes("ADD_TO_AWARD") ? (
          data.getTemplatesByProjectID.map((template, index) => {
            return (
              <EvaluationForm
                projectId={id}
                productId={productId}
                type="sow_template"
                templateId={template.vbeid}
                readOnly={true}
              />
            );
          })
        ) : (
          <div className={classes.emptyText}>
            Buyer is yet to finalise the contract to be awarded
          </div>
        )
      ) : loading ? (
        <LoadingResult />
      ) : (
        <div
          style={{
            textAlign: "center",
            color: "#999",
            fontStyle: "italic",
            margin: "16px",
          }}
        >
          You have not been awarded the Project.
        </div>
      )}
    </div>
  );
}
