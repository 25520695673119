import { useMutation } from "@apollo/client";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";

import { useFormik } from "formik";

import { useHistory, useParams } from "react-router-dom";

import InputField from "../../../../common/InputField";

import { CREATE_SERVICE_PROJECT, UPDATE_PROJECT } from "../../../queries/query";

import { useStyles } from "../styles";

import PageButtons from "./PageButtons";

export default function WorkPackageProjectForm(props) {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  let today = new Date().toISOString().split("T")[0];

  const validateProjectCreation = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Please enter project name.";
    }

    if (!values.decisiontimeline) {
      errors.decisiontimeline = "Please select a decision timeline";
    }

    if (values.engagement_type === "-") {
      errors.engagement_type = "Please select engagement type";
    }

    if (!values.workduration) {
      errors.worklocation = "Please enter work duration";
    }

    if (!values.workstartby) {
      errors.workstartby = "Please select estimated start date";
    }

    if (!values.proposalsubmissiondate) {
      errors.proposalsubmissiondate = "Please enter Proposal submission date";
    }

    if (values.workstartby < values.proposalsubmissiondate) {
      errors.workstartby =
        "Estimated start date cannot be before proposal submission deadline";
    }

    return errors;
  };

  const userRole = props.data?.role[0] || "";

  const formik = useFormik({
    initialValues: props.data
      ? {
          vbeid: props.data.vbeid,
          name: props.data.name,
          workstartby: props.data.workstartby || today,
          proposalsubmissiondate: props.data.proposalsubmissiondate || today,
          decisiontimeline: props.data.decisiontimeline,
          engagement_type: props.data.engagement_type,
          workduration: props.data.workduration,
        }
      : {
          name: "",
          workstartby: today,
          proposalsubmissiondate: today,
          decisiontimeline: "",
          engagement_type: "",
          workduration: "",
        },
    validate: validateProjectCreation,

    onSubmit: (values) => {
      createServiceProject(values);
    },
  });

  const [createProject, { loading }] = useMutation(CREATE_SERVICE_PROJECT, {
    onCompleted: (response) => {
      history.push("/wp-job-description/" + response.addServiceProject.vbeid);
    },
  });

  const [updateProject, { loading: uloading }] = useMutation(UPDATE_PROJECT, {
    onCompleted: (e) => {
      history.push("/wp-job-description/" + e.updateProjectV1.vbeid);
    },
  });

  const createServiceProject = (values) => {
    values.type = "service-workpackage";

    if (id !== "new") {
      values.vbeid = id;

      updateProject({ variables: { input: values } });
    } else {
      values.version = "v1";

      createProject({ variables: { input: values } });
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl component="fieldset" className={classes.wpFormControlClass}>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Give a name for this project *
        </Typography>

        <TextField
          id="filled-full-width"
          name="name"
          placeholder="Project Name *"
          variant="outlined"
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          disabled={userRole === "ADDED_TO"}
          size="small"
        />

        <FormHelperText className={classes.errorMessage}>
          {formik.errors.name}
        </FormHelperText>

        <Typography
          variant="caption"
          style={{ color: "red", fontWeight: "bold", margin: "5px" }}
        >
          Never include company names or any sensitive information in the
          project name
        </Typography>

        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.textFieldLabel}
            >
              Indicative decision timeline *
            </Typography>

            <RadioGroup
              aria-label="time"
              name="decisiontimeline"
              onChange={formik.handleChange}
              value={formik.values.decisiontimeline}
              error={
                formik.touched.decisiontimeline &&
                Boolean(formik.errors.decisiontimeline)
              }
            >
              <FormControlLabel
                value="Immediately"
                control={<Radio size="small" />}
                label="Immediately"
                className={classes.radioButtonLabel}
              />

              <FormControlLabel
                value="Within the next 3 months"
                control={<Radio size="small" />}
                label="Within the next 3 months"
                className={classes.radioButtonLabel}
              />

              <FormControlLabel
                value="Within the next 6 months"
                control={<Radio size="small" />}
                label="Within the next 6 months"
                className={classes.radioButtonLabel}
              />
            </RadioGroup>

            <FormHelperText className={classes.errorMessage}>
              {formik.errors.decisiontimeline}
            </FormHelperText>
          </Grid>

          <Grid item xs={6}>
            <Box m={1}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.textFieldLabel}
              >
                Engagement Type *
              </Typography>

              <RadioGroup
                aria-label="engagement_type"
                name="engagement_type"
                value={formik.values.engagement_type}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="fixed_price"
                  control={<Radio size="small" />}
                  label="Fixed Price"
                  className={classes.radioButtonLabel}
                />

                <FormControlLabel
                  value="time_material"
                  control={<Radio size="small" />}
                  label="Time & Material"
                  className={classes.radioButtonLabel}
                />

                <FormControlLabel
                  value="both"
                  control={<Radio size="small" />}
                  label="Both"
                  className={classes.radioButtonLabel}
                />
              </RadioGroup>

              <FormHelperText className={classes.errorMessage}>
                {formik.errors.engagement_type}
              </FormHelperText>
            </Box>
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Timelines (Define appropriate dates)
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.serviceFormControl}
            >
              <InputField
                label="Proposal Submission Date *"
                value={formik.values.proposalsubmissiondate}
                onChange={formik.handleChange("proposalsubmissiondate")}
                type="date"
              />
            </FormControl>

            <FormHelperText className={classes.serviceErrorMessage}>
              {formik.errors.proposalsubmissiondate}
            </FormHelperText>
          </Grid>

          <Grid item xs={4}>
            <FormControl
              className={classes.serviceFormControl}
              fullWidth={true}
            >
              <InputField
                label="Work Duration (in months) *"
                value={formik.values.workduration}
                onChange={formik.handleChange("workduration")}
              />
            </FormControl>

            <FormHelperText className={classes.serviceErrorMessage}>
              {formik.errors.workduration}
            </FormHelperText>
          </Grid>

          <Grid item xs={4}>
            <FormControl
              className={classes.serviceFormControl}
              fullWidth={true}
            >
              <InputField
                label="Estimated Start Date *"
                value={formik.values.workstartby}
                onChange={formik.handleChange("workstartby")}
                type="date"
              />
            </FormControl>

            <FormHelperText className={classes.serviceErrorMessage}>
              {formik.errors.workstartby}
            </FormHelperText>
          </Grid>
        </Grid>
      </FormControl>

      {props.type !== "review" && (
        <div style={{ margin: "24px 0" }}>
          <PageButtons
            nextLocation="jobDescription"
            nextDisabled={!formik.isValid || false}
            prevDisabled={true}
            loading={loading || uloading}
          />
        </div>
      )}
    </form>
  );
}
