import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { useStyles } from "./style";
import "./payterm.css";
import { useMutation, useQuery } from "@apollo/client";
import { Alert } from "@material-ui/lab";
import { getCompanyById, postCompanyInfo } from "../companies/Company2.0/query";
import InputField from "../common/InputField";
import OutlinedButton from "../companies/company/OutlinedButton";
import SimpleSnackbar from "../groups2.0/snackbar";
import { Divider, FormControl, FormHelperText, Grid } from "@material-ui/core";
import SimpleErrorSnackbar from "../groups2.0/errorsnackbar";

export default function PaymentTerm({ userInfo, companyvbeid }) {
  const { data: companyData, refetch } = useQuery(getCompanyById, {
    variables: { input: { vbeid: companyvbeid } },
  });
  const [edit, setEdit] = useState(false);

  const validationSchema = yup.object().shape({
    ContractNumber: yup
      .number("Only Positive Numbers")
      .min(0)
      .required("Field Required*"),

    PackageNumber: yup
      .number("Only Positive Numbers")
      .min(0)
      .required("Field Required*"),

    BillingNumberFrom: yup
      .number("Only Positive Numbers")
      .min(0)
      .max(31)
      .required("Field Required*"),

    BillingNumberTo: yup
      .number("Only Positive Numbers")
      .min(0)
      .max(31)
      .required("Field Required*"),

    Timesheet: yup
      .number("Only Positive Numbers")
      .min(0)
      .max(31)
      .required("Field Required*"),

    TimesheetApproval: yup
      .number("Only Positive Numbers")
      .min(0)
      .max(31)
      .required("Field Required*"),

    Invoice: yup
      .number("Only Positive Numbers")
      .min(0)
      .max(31)
      .required("Field Required*"),
  });

  const classes = useStyles();

  // console.log(
  //   "companyvbeid || userInfo?.companyvbeid",
  //   companyvbeid,
  //   userInfo?.companyvbeid
  // );

  // console.log("companyData", companyData);

  // const {
  //   contractresourceday,
  //   workpackageday,
  //   billingperioddayfrom,
  //   billingperioddayto,
  //   timesheetsubmissionday,
  //   timesheetapprovalday,
  //   invoicesubmissionday,
  // } = companyData?.queryCompanybyid;

  // packagenumber: workpackageday,
  // billingnumberfrom: billingperioddayfrom,
  // billingnumberto: billingperioddayto,
  // timesheet: timesheetsubmissionday,
  // timesheetapproval: timesheetapprovalday,
  // invoice: invoicesubmissionday,

  // contractnumber: "",
  //         packagenumber: "",
  //         billingnumberfrom: "",
  //         billingnumberto: "",
  //         timesheet: "",
  //         timesheetapproval: "",
  //         invoice: "",

  const formik = useFormik({
    initialValues: {
      ContractNumber: companyData?.queryCompanybyid?.contractresourceday,
      PackageNumber: companyData?.queryCompanybyid?.workpackageday,
      BillingNumberFrom: companyData?.queryCompanybyid?.billingperioddayfrom,
      BillingNumberTo: companyData?.queryCompanybyid?.billingperioddayto,
      Timesheet: companyData?.queryCompanybyid?.timesheetsubmissionday,
      TimesheetApproval: companyData?.queryCompanybyid?.timesheetapprovalday,
      Invoice: companyData?.queryCompanybyid?.invoicesubmissionday,
    },

    // initialValues: {
    //   contractnumber: contractresourceday,
    //   packagenumber: workpackageday,
    //   billingnumberfrom: billingperioddayfrom,
    //   billingnumberto: billingperioddayto,
    //   timesheet: timesheetsubmissionday,
    //   timesheetapproval: timesheetapprovalday,
    //   invoice: invoicesubmissionday,
    // },
    validationSchema: validationSchema,
  });

  const [
    postCompanyMutation,
    { loading: mutationLoading, error: mutationError, data: mutationdata },
  ] = useMutation(postCompanyInfo, {
    onCompleted: (e) => {
      setEdit(false);
      refetch();
    },
  });

  const updateCompany = (e) => {
    e.preventDefault();

    postCompanyMutation({
      variables: {
        input: {
          vbeid: userInfo?.companyvbeid,
          contractresourceday: formik.values.ContractNumber,
          workpackageday: formik.values.PackageNumber,
          billingperioddayfrom: formik.values.BillingNumberFrom,
          billingperioddayto: formik.values.BillingNumberTo,
          timesheetsubmissionday: formik.values.Timesheet,
          timesheetapprovalday: formik.values.TimesheetApproval,
          invoicesubmissionday: formik.values.Invoice,
        },
      },
    });
  };

  const handleSave = () => {
    setEdit(true);
  };

  return (
    <>
      {mutationLoading || mutationdata ? <SimpleSnackbar /> : null}
      {mutationError ? <SimpleErrorSnackbar /> : null}

      <Grid container md={12} className={classes.root}>
        <Grid container md={11}>
          <Grid item md={10}>
            <p className={classes.title}>Payment Terms</p>
          </Grid>

          <Grid item md={1} className={classes.btn}>
            <OutlinedButton
              label="Edit"
              type="outlined"
              // disabled={edit ? !(formik.isValid && formik.dirty) : true}
              disabled={edit ? true && formik.dirty : false}
              onClick={handleSave}
            />
          </Grid>

          {/* (formik.touched.ContractNumber ||
                  formik.touched.PackageNumber ||
                  formik.touched.BillingNumberFrom ||
                  formik.touched.BillingNumberTo ||
                  formik.touched.Timesheet ||
                  formik.touched.TimesheetApproval ||
                  formik.touched.Invoice) */}

          <Grid item md={0.8} className={classes.btn}>
            <OutlinedButton
              label="Save"
              type="contained"
              // disabled={edit ? !(formik.isValid && formik.dirty) : true}

              disabled={edit && formik.isValid && formik.dirty ? false : true}
              onClick={updateCompany}
            />
          </Grid>
        </Grid>

        <Grid item md={11}>
          <Alert severity="info" className={classes.item}>
            Define your standard payment terms for Contract Resourcing & Work
            Package Projects. Your Project requirements when shared with the
            suppliers will contain this information.
          </Alert>
        </Grid>

        {/* Work PAckage And contarct resource */}

        <Grid container md={5} className={classes.contract}>
          <Grid container md={12}>
            <Grid item md={6}>
              <p className={classes.header}>
                {" "}
                For Contract Resourcing Projects :{" "}
              </p>
            </Grid>

            {/* <Grid item md={2}>
              <p className={classes.monthday}> Every Month</p>
            </Grid> */}
            <Grid container md={3}>
              <Grid item md={12}>
                <FormControl variant="outlined" className={classes.daysfield}>
                  <InputField
                    id="outlined-adornment-weight"
                    value={formik.values.ContractNumber}
                    onChange={formik.handleChange("ContractNumber")}
                    type="number"
                    disabled={edit ? false : true}
                  />
                </FormControl>
              </Grid>

              <Grid item md={12}>
                <Grid item md={12}>
                  {formik.touched.ContractNumber ||
                  formik.errors.ContractNumber ? (
                    <div className={classes.errors}>
                      {formik.errors.ContractNumber}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={1}>
              <p className={classes.monthday}> Days</p>
            </Grid>

            {/* <Grid item md={12}>
              {formik.touched.contractnumber && formik.errors.contractnumber ? (
                <div className={classes.errors}>
                  {formik.errors.contractnumber}
                </div>
              ) : null}
            </Grid> */}
          </Grid>
        </Grid>

        <Grid container md={5} className={classes.contract}>
          <Grid container md={12}>
            <Grid item md={6}>
              <p className={classes.header}>For Work Package Projects : </p>
            </Grid>

            <Grid container md={3}>
              <Grid item md={12}>
                <FormControl variant="outlined" className={classes.daysfield}>
                  <InputField
                    id="outlined-adornment-weight"
                    value={formik.values.PackageNumber}
                    onChange={formik.handleChange("PackageNumber")}
                    type="number"
                    disabled={edit ? false : true}
                  />
                </FormControl>
              </Grid>

              <Grid item md={12}>
                <Grid item md={12}>
                  {formik.touched.PackageNumber ||
                  formik.errors.PackageNumber ? (
                    <div className={classes.errors}>
                      {formik.errors.PackageNumber}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={1}>
              <p className={classes.monthday}> Days</p>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Divider className={classes.divider} />
        </Grid>

        <Grid container md={11}>
          <Grid item md={11}>
            <p className={classes.title}>
              Invoice & Billing Cycle{" "}
              <FormHelperText>
                (Applicable for Contract Resourcing Projects)
              </FormHelperText>
            </p>
          </Grid>

          {/* <Grid item md={1} className={classes.btn}>
            <OutlinedButton
              name="Save"
              label="save"
              type="contained"
              // disabled={!(formik.isValid && formik.dirty)}
              onClick={updateCompany}
            />
          </Grid> */}
        </Grid>
        <Grid item md={11}>
          <Alert severity="info" className={classes.item}>
            Applicable for Contract Resourcing Project, define your
            organization’s standard practice around Invoice and Billing Dates.
            This will help in automating the Invoice Management process which
            will benefit your Finance/Accounts teams.
          </Alert>
        </Grid>

        <Grid container md={5} className={classes.billing}>
          <Grid container md={12}>
            <Grid item md={6}>
              <p className={classes.header}>Monthly Billing Period : </p>
            </Grid>
            <Grid container md={6}>
              <Grid item md={2}>
                <p className={classes.monthday}>From</p>
              </Grid>

              <Grid container md={3}>
                <Grid item md={12}>
                  <FormControl variant="outlined" className={classes.daysfield}>
                    <InputField
                      id="outlined-adornment-weight"
                      value={formik.values.BillingNumberFrom}
                      onChange={formik.handleChange("BillingNumberFrom")}
                      type="number"
                      disabled={edit ? false : true}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12}>
                  <Grid item md={12}>
                    {formik.touched.BillingNumberFrom ||
                    formik.errors.BillingNumberFrom ? (
                      <div className={classes.errors}>
                        {formik.errors.BillingNumberFrom}
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={2}>
                <p className={classes.monthday}> Day</p>
              </Grid>

              <Grid item md={1}>
                <p className={classes.monthday}>To</p>
              </Grid>

              <Grid container md={3}>
                <Grid item md={12}>
                  <FormControl variant="outlined" className={classes.daysfield}>
                    <InputField
                      id="outlined-adornment-weight"
                      value={formik.values.BillingNumberTo}
                      onChange={formik.handleChange("BillingNumberTo")}
                      type="number"
                      disabled={edit ? false : true}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <Grid item md={12}>
                    {formik.touched.BillingNumberTo ||
                    formik.errors.BillingNumberTo ? (
                      <div className={classes.errors}>
                        {formik.errors.BillingNumberTo}
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={1}>
                <p className={classes.monthday}> Day</p>
              </Grid>
              {/* 
              <FormControl
                variant="outlined"
                className={classes.daysfieldfromto}
              >
                <OutlinedInput
                  id="outlined-adornment-weight"
                  value={formik.values.billingnumberfrom}
                  onChange={formik.handleChange("billingnumberfrom")}
                  type="number"
                  // startAdornment={
                  //   <InputAdornment position="start">From</InputAdornment>
                  // }
                  endAdornment={
                    <InputAdornment position="end">Day</InputAdornment>
                  }
                />
                <FormHelperText> From</FormHelperText>
              </FormControl> */}

              {/* <Grid item md={6}>
              <FormControl variant="outlined" className={classes.daysfield}>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  value={formik.values.billingnumberto}
                  onChange={formik.handleChange("billingnumberto")}
                  type="number"
                  // startAdornment={
                  //   <InputAdornment position="start">To</InputAdornment>
                  // }
                  endAdornment={
                    <InputAdornment position="end">Day</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
                <FormHelperText> To</FormHelperText>
              </FormControl>
            </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid container md={5} className={classes.billing}>
          <Grid container md={12}>
            <Grid item md={6}>
              <p className={classes.header}>Timesheet Submission : </p>
            </Grid>
            <Grid item md={2}>
              <p className={classes.monthday}> Every Month</p>
            </Grid>

            <Grid container md={2}>
              <Grid item md={12}>
                <FormControl variant="outlined" className={classes.daysfield}>
                  <InputField
                    id="outlined-adornment-weight"
                    value={formik.values.Timesheet}
                    onChange={formik.handleChange("Timesheet")}
                    type="number"
                    disabled={edit ? false : true}
                  />
                </FormControl>
              </Grid>

              <Grid item md={12}>
                <Grid item md={12}>
                  {formik.touched.Timesheet || formik.errors.Timesheet ? (
                    <div className={classes.errors}>
                      {formik.errors.Timesheet}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1}>
              <p className={classes.monthday}> Day</p>
            </Grid>
          </Grid>
        </Grid>

        <Grid container md={5} className={classes.billing}>
          <Grid container md={12}>
            <Grid item md={6}>
              <p className={classes.header}>Timesheet Approval : </p>
            </Grid>

            <Grid item md={2}>
              <p className={classes.monthday}> Every Month</p>
            </Grid>
            <Grid container md={2}>
              <Grid item md={12}>
                <FormControl variant="outlined" className={classes.daysfield}>
                  <InputField
                    id="outlined-adornment-weight"
                    value={formik.values.TimesheetApproval}
                    onChange={formik.handleChange("TimesheetApproval")}
                    type="number"
                    disabled={edit ? false : true}
                  />
                </FormControl>
              </Grid>

              <Grid item md={12}>
                <Grid item md={12}>
                  {formik.touched.TimesheetApproval ||
                  formik.errors.TimesheetApproval ? (
                    <div className={classes.errors}>
                      {formik.errors.TimesheetApproval}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1}>
              <p className={classes.monthday}> Day</p>
            </Grid>
          </Grid>
        </Grid>

        <Grid container md={5} className={classes.billing}>
          <Grid container md={12}>
            <Grid item md={6}>
              <p className={classes.header}>Invoice Submission : </p>
            </Grid>

            <Grid item md={2}>
              <p className={classes.monthday}> Every Month</p>
            </Grid>
            <Grid container md={2}>
              <Grid item md={12}>
                <FormControl variant="outlined" className={classes.daysfield}>
                  <InputField
                    id="outlined-adornment-weight"
                    value={formik.values.Invoice}
                    onChange={formik.handleChange("Invoice")}
                    type="number"
                    disabled={edit ? false : true}
                  />
                </FormControl>
              </Grid>

              <Grid item md={12}>
                <Grid item md={12}>
                  {formik.touched.Invoice || formik.errors.Invoice ? (
                    <div className={classes.errors}>
                      {formik.errors.Invoice}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1}>
              <p className={classes.monthday}> Day</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
