import * as React from "react";

import { DataGrid } from "@material-ui/data-grid";

export default function ProdDataGrid({
  loading,
  rows,
  columns,
  setValuesId,
  method,
  onEdit,
  pageChange,
}) {
  return (
    <div>
      {rows ? (
        <DataGrid
          rows={loading ? [] : rows}
          columns={columns}
          checkboxSelection={true}
          loading={loading}
          // getRowId={(row) => console.log("onclickdg", row.id)}
          // onClick={(e) => {
          //   console.log("onclickdg", e.row.rowIndex);
          // }}
          disableSelectionOnClick={true}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = rows.filter((row) =>
              selectedIDs.has(row.id.toString())
            );
            // console.log("selectedRowData", selectedRowData);
            const nowSelected = selectedRowData.map((meow) => meow.id);
            console.log("nowSelected", nowSelected);
            setValuesId(nowSelected);
          }}
          rowHeight={100}
          // density="compact"
          autoHeight={true}
          rowsPerPageOptions={[5]}
          // pageSize={15}
          // rowsPerPageOptions={[5]}
        />
      ) : null}
    </div>
  );
}

// import React from "react";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
// import ListItemText from "@material-ui/core/ListItemText";
// import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Checkbox from "@material-ui/core/Checkbox";
// import Avatar from "@material-ui/core/Avatar";
// import { ListItemIcon } from "@material-ui/core";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: "100%",
//       maxWidth: 360,
//       backgroundColor: theme.palette.background.paper,
//     },
//   })
// );

// export default function ProdDataGrid({ data, loading }) {
//   const classes = useStyles();

//   // const { data } = props;
//   const [checked, setChecked] = React.useState([1]);

//   if (data) {
//     console.log("dataaa", data);
//   }

//   const handleToggle = (value: number) => () => {
//     const currentIndex = checked.indexOf(value);
//     const newChecked = [...checked];

//     if (currentIndex === -1) {
//       newChecked.push(value);
//     } else {
//       newChecked.splice(currentIndex, 1);
//     }

//     setChecked(newChecked);
//   };

//   return (
//     <List dense className={classes.root}>
//       {data?.map((value) => {
//         console.log("value", value);
//         const labelId = `checkbox-list-secondary-label-${value}`;
//         return (
//           <ListItem key={value} button>
//             <ListItemIcon>
//               <Checkbox
//                 edge="start"
//                 checked={checked.indexOf(value) !== -1}
//                 tabIndex={-1}
//                 disableRipple
//                 inputProps={{ "aria-labelledby": labelId }}
//               />
//             </ListItemIcon>
//             <ListItemAvatar>
//               <Avatar
//               // alt={`Avatar n°${value + 1}`}
//               // src={`/static/images/avatar/${value + 1}.jpg`}
//               >
//                 {value.image}
//               </Avatar>
//             </ListItemAvatar>
//             <ListItemText>{value.name}</ListItemText>
//             <ListItem>
//               <ListItemText styles={{ marginLeft: "60%" }}>
//                 {value.shortdescription}
//               </ListItemText>
//             </ListItem>
//           </ListItem>
//         );
//       })}
//     </List>
//   );
// }
