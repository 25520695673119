import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  main: {
    width: theme.spacing(100),
  },
  formControlclass: {
    width: "100%",
    textAlign: "left",
  },
  tableIndexCell: {
    width: theme.spacing(25),
    fontWeight: "bold",
  },
}));
