import * as React from "react";

import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { useMutation } from "@apollo/client";
import { SIGNED_URL, UPDATE_REQUIREMENT_ITEM } from "../../../queries/query";

import { useFormik } from "formik";

import PropTypes from "prop-types";

import { useStyles } from "./styles";

import { useState } from "react";

const axios = require("axios");

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function UploadFile(props) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={8}>
        <input
          className={classes.input}
          id={"file" + props.id}
          type="file"
          onChange={(e) => {
            props.uploadFile(e);
          }}
        />

        <label htmlFor={"file" + props.id}>
          <Button
            variant="contained"
            size="small"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Upload
          </Button>
        </label>
      </Grid>

      <Grid item xs={4}>
        {props.upload ? (
          <CircularProgressWithLabel
            color="secondary"
            value={props.uploadPer}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}

function DownLoadFile(props) {
  const classes = useStyles();

  const [dsignedUrl] = useMutation(SIGNED_URL, {
    onCompleted: (e) => {
      console.log(e);

      let url = e.gettemplateSignedURL.signedurl;
      window.open(url);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const deleteFile = () => {
    let fileValues = JSON.parse(props.formik.values.value);

    let keepFiles = fileValues.filter((file) => {
      return file.name !== props.name;
    });

    props.formik.setFieldValue("attachmentfilename", "");
    props.formik.setFieldValue("value", keepFiles);
    props.formik.setFieldValue("action", "delete");
    props.formik.handleSubmit();
  };

  function downLoadFile() {
    dsignedUrl({
      variables: {
        input: {
          projectid: props.formik.values.projectid,
          attachmentfilename: props.name,
          method: "get",
        },
      },
    });
  }

  return (
    <Grid container>
      <Grid item xs={11} className={classes.downloadBtn}>
        <Button
          onClick={downLoadFile}
          color="secondary"
          size="small"
          component="span"
          className={classes.download}
          startIcon={<AttachFileIcon fontSize="small" />}
        >
          {props.name}
        </Button>
      </Grid>

      {props.readOnly ? null : (
        <Grid item xs={1}>
          <IconButton onClick={deleteFile}>
            <CloseIcon
              color="secondary"
              className={classes.deleteFile}
              fontSize="small"
            />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

export default function RFileItem(props) {
  const classes = useStyles();

  let fileProps = props.values.value;

  if (fileProps !== "") {
    fileProps = JSON.parse(props.values.value);
  } else {
    fileProps = [];
  }

  const [metadata, setMetadata] = useState(props.metadata);
  const [showUpload, setShowupload] = useState(
    props.values.value === null || props.values.value === ""
  );
  const [upload, setUpload] = useState(false);
  const [uploadPer, setUploadPer] = useState(2);
  const [filesUploaded, setFilesUploaded] = useState(fileProps);

  const [updateItem, { data, loading, error }] = useMutation(
    UPDATE_REQUIREMENT_ITEM,
    {
      onCompleted: (e) => {
        formik.setFieldValue(
          "timestamp",
          e.addReqTemplateItemvalue[0].timestamp
        );

        fileProps = JSON.parse(e.addReqTemplateItemvalue[0].value);

        setFilesUploaded(fileProps);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const [signedUrl, { data: sdata, loading: sloading, error: serror }] =
    useMutation(SIGNED_URL, {
      onCompleted: (e) => {
        let url = e.gettemplateSignedURL.signedurl;
        let attachment = e.gettemplateSignedURL.attachment;

        formik.setFieldValue("value", attachment);

        putFile(url);
      },
      onError: (err) => {
        console.log(err);
      },
    });

  const formik = useFormik({
    initialValues: props.values,

    onSubmit: (values) => {
      var input = { ...values };

      if (input.action !== "delete") {
        let oldFileProps = fileProps;

        if (data) {
          oldFileProps = JSON.parse(data.addReqTemplateItemvalue[0].value);
        }

        let fileValues = {
          path: input.value,
          name: input.attachmentfilename,
        };

        oldFileProps.push(fileValues);

        input.value = JSON.stringify(oldFileProps);
      } else {
        input.value = JSON.stringify(input.value);
      }

      if (input.timestamp === null) {
        delete input.timestamp;
      }

      delete input.action;

      updateItem({ variables: { input: [input] } });
    },
  });

  function uploadFile(e) {
    setUpload(true);

    var tfile = e.target.files[0];
    let name = tfile.name;

    // let nano_id = nanoid();

    signedUrl({
      variables: {
        input: {
          projectid: formik.values.projectid,
          attachmentfilename: name,
          method: "put",
        },
      },
    });
  }

  async function putFile(url) {
    let file = document.getElementById("file" + props.id).files[0];

    await axios
      .request({
        method: "put",
        url: url,
        data: file,
        onUploadProgress: (p) => {
          const percent = Math.round((p.loaded / p.total) * 100);
          setUploadPer(percent);
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          //   setShowupload(false);
          let uploadedFile = filesUploaded;
          uploadedFile.push(file.name);

          setUpload(false);
          setFilesUploaded(uploadedFile);

          formik.setFieldValue("attachmentfilename", file.name);
          formik.setFieldValue("action", "add");

          formik.handleSubmit();
        }
      });
  }

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs={4}>
        <Typography
          variant="caption"
          gutterBottom
          className={props.readOnly ? classes.rtitle : ""}
        >
          {metadata.title}
        </Typography>
      </Grid>

      <Grid item xs={5}>
        {!props.readOnly && (
          <UploadFile
            id={props.id}
            uploadFile={uploadFile}
            upload={upload}
            uploadPer={uploadPer}
          />
        )}

        {filesUploaded.length !== 0 &&
          filesUploaded.map((file) => {
            return (
              <DownLoadFile
                name={file.name}
                setUpload={setUpload}
                setShowupload={setShowupload}
                setUploadPer={setUploadPer}
                formik={formik}
                readOnly={props.readOnly}
                updateItem={updateItem}
              />
            );
          })}
      </Grid>

      <Grid item xs={3}>
        {metadata.remarks ? (
          props.readOnly ? (
            <Typography variant="caption">{formik.values.remarks}</Typography>
          ) : (
            <TextField
              disabled={loading}
              id="filled-full-width"
              name="remarks"
              autoComplete="off"
              value={formik.values.remarks}
              onChange={formik.handleChange}
              placeholder="Remarks"
              fullWidth
              variant="outlined"
              size="small"
              onBlur={formik.handleSubmit}
            />
          )
        ) : null}
      </Grid>
    </Grid>
  );
}
