import React, { useState } from "react";
import { Router, Route, Switch } from "react-router-dom";

import { createBrowserHistory } from "history";
import "./App.css";

import Company from "./components/entities/companies/Company2.0/CompanyPage";
import ProductPage from "./components/entities/products/product2.0/ProductPage";
import UsecasePage from "./components/entities/Usecase2.0/usecase/UsecasePage";
import CaseStudyPage from "./components/entities/CaseStudy2.0/casestudy/CaseStudyPage";

import Groups from "./components/entities/groups2.0/group";
import GroupList from "./components/entities/groups/grouplist";

import TopicPage from "./components/entities/topic2.0/topic";
import topiclist from "./components/entities/topic/topiclist";

import Registration from "./components/entities/profile2.0/Registration";

import { ThemeProvider } from "@material-ui/core/styles";

import { theme } from "./theme/theme";

import { gql, useQuery } from "@apollo/client";
import MainLoading from "./components/shared/loading";

import ListinList from "./components/entities/lists/listsinlist";
import ProductsProfileList from "./components/entities/products/admin/productsprofile";
//import CompanyProfileValidation from "./components/entities/companies/admin/companyprofile";

import ErrorBoundary from "./components/shared/error";

import LeadsGrid from "./components/entities/project/leads/leadstable";

import CreateCompanyShort from "./components/entities/shorts/createcompanyshort";

// import Knowledgebase from "./components/knowledgebase2.0/knowledgebase";

import LeadOverview from "./components/entities/projectV2.1/leads/leadOverview";

import Agreements from "./components/entities/Agreements/agreements";

import ConciergeTable from "./components/entities/project v2.0/concierge/requeststable";
import ViewConcierge from "./components/entities/project v2.0/concierge/viewconcierge";

import AgreementSuccess from "./components/entities/Agreements/AgreementSuccess";
import AgreementFail from "./components/entities/Agreements/AgreementFail";
import HomePage from "./components/entities/home/HomePage";
import MainAppBar from "./components/shared/MainAppBar";

//data validation imports
//import DataValidationTabs from './components/entities/DataValidation/validationTabs';
import CompanyGrid from "./components/entities/DataValidation/companyGrid";
import ProductGrid from "./components/entities/DataValidation/productGrid";
import UsecaseGrid from "./components/entities/DataValidation/useCaseGrid";
import CasestudyGrid from "./components/entities/DataValidation/caseStudyGrid";

// import ServicePage from "./components/entities/services/ServicePage";
import AdminPage from "./components/entities/AdminDashboard/AdminPage";
import BlockedUser from "./components/entities/home/BlockedUser";

import TemplatesPage from "./components/entities/Templates/TemplatesPage";
import CreateTemplate from "./components/entities/Templates/CreateTemplate";

//project 3.0

// import BuyerProjectHome from "./components/entities/project3.0/buyer/buyer";
import VendorProjects from "./components/entities/project3.0/vendor/projectlist/projects";
import ProjectFlowHome from "./components/entities/project3.0/buyer/createProject/projectflow";
import DesignTemplate from "./components/entities/project3.0/buyer/templates/desing/design";
import ProjectOverview from "./components/entities/project3.0/buyer/projectOverview/projectoverview";
import ProjectReview from "./components/entities/project3.0/buyer/review/review";

//templates

import TemplatesHome from "./components/entities/project3.0/buyer/templates/templates";
import ResponseOverview from "./components/entities/project3.0/vendor/projectlist/projectResponse";

import ProjectMain from "./components/entities/project3.0/buyer/projectlist/projectmain";
import productsList from "./components/entities/ProductModule/productsList";
import UnauthorisedUser from "./components/entities/home/UnauthorisedUser";

import RoleManagement from "./components/entities/rolemanagement/RoleManagement";
import TagManagement from "./components/entities/rolemanagement/TagManagement";
import PaymentTerm from "./components/entities/paymentterm/PaymentTerm";
import DocumentMangement from "./components/entities/documentmanagement/DocumentManagemt";
import LegalEntityPage from "./components/entities/legalentity/LegalEntityPage";
import License from "./components/entities/home/License";
import ServiceReview from "./components/entities/project3.0/buyer/review/ServiceReview";
import VendorPage from "./components/entities/myvendor/VendorPage";
import InvoiceBuyerScreen2 from "./components/entities/project3.0/Invoice/Invoice2";
import InvoiceBuyerScreen4 from "./components/entities/project3.0/Invoice/Invoice4";
import InvoiceBuyerScreen8 from "./components/entities/project3.0/Invoice/Invoice8";

import PoUpload from "./components/entities/project3.0/purchase-order/PoUpload";
import PoChanges from "./components/entities/project3.0/purchase-order/PoChanges";
import ResourceOnboarding from "./components/entities/project3.0/purchase-order/ResourceOnboarding.js";
import ViewPO from "./components/entities/project3.0/purchase-order/ViewPO";
import SkillPage from "./components/entities/Skills/SkillPage";
import SupplierOnboarding from "./components/entities/project3.0/purchase-order/SupplierOnboarding";
import ApproveMilestone from "./components/entities/project3.0/Invoice/ApproveMilestone";
import ApproveInvoice from "./components/entities/project3.0/Invoice/ApproveInvoice";
import ListApprovedInvoices from "./components/entities/project3.0/Invoice/ListApprovedInvoices";
// import SkillPage from "./components/entities/Skills/SkillPage";
// import CompanyTabs from "./components/entities/companies/Company2.0/companytabs";

var hist = createBrowserHistory();

const userInfoQuery = gql`
  query userinfo {
    getLoginUserInfo(input: {}) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      roletype
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
      isValidLicense
      isPrivatemail
      status
      companyname
      onBoardingCompleted
    }
  }
`;

//know authorisation
const userPermission = gql`
  query identity($input: personinput!) {
    amiauthorized(input: $input) {
      status
    }
  }
`;

function App() {
  const pathname = hist.location.pathname;

  const [toggleValue, setToggleValue] = useState("products");
  const [drawerOpen, setdrawerOpen] = useState(
    pathname !== "/unauthorised" &&
      pathname !== "/register" &&
      pathname !== "/unlicensed"
      ? true
      : false
  );

  const mainDrawerClick = (e, close) => {
    if (close === "close") {
      setdrawerOpen(false);
    } else {
      setdrawerOpen(!drawerOpen);
    }
  };

  const {
    loading,
    data,
    refetch: userInfofetch,
  } = useQuery(userInfoQuery, {
    fetchPolicy: "network-only",
  });

  useQuery(userPermission, {
    variables: { input: {} },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <MainLoading />;
  }

  if (data) {
    if (
      data?.getLoginUserInfo.isProfileUpdated !== "true" ||
      data?.getLoginUserInfo.companyvbeid === ""
    ) {
      hist.push("/register");
    }

    if (data.getLoginUserInfo.roletype === "seller") {
      hist.push("/unauthorised");
    }

    if (
      data?.getLoginUserInfo.roletype === "buyer" &&
      data?.getLoginUserInfo.isValidLicense === "false"
    ) {
      hist.push("/unlicensed");
    }
  }

  const handleChange = (value) => {
    setToggleValue(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router history={hist}>
          <ErrorBoundary>
            <MainAppBar
              userInfo={data?.getLoginUserInfo}
              onChange={handleChange}
              toggleValue={toggleValue}
              mainDrawerClick={mainDrawerClick}
              drawerOpen={drawerOpen}
            />

            <Switch>
              <Route
                exact
                path="/"
                render={() =>
                  data?.getLoginUserInfo.status === false ? (
                    <BlockedUser />
                  ) : (
                    <HomePage
                      toggleValue={toggleValue}
                      onChange={handleChange}
                      drawerOpen={drawerOpen}
                      mainDrawerClick={mainDrawerClick}
                    />
                  )
                }
              />
              <Route path="/unauthorised" component={UnauthorisedUser} />

              <Route
                path="/unauthorised"
                render={() => (
                  <UnauthorisedUser
                    drawerOpen={drawerOpen}
                    mainDrawerClick={mainDrawerClick}
                  />
                )}
              />

              <Route
                path="/accept-timesheet"
                render={() => <InvoiceBuyerScreen2 drawerOpen={drawerOpen} />}
              />

              <Route
                path="/accept-milestone"
                render={() => <ApproveMilestone drawerOpen={drawerOpen} />}
              />

              <Route
                path="/accept-invoice"
                render={() => <InvoiceBuyerScreen4 drawerOpen={drawerOpen} />}
              />

              <Route
                path="/wp-accept-invoice"
                render={() => <ApproveInvoice drawerOpen={drawerOpen} />}
              />

              <Route
                path="/invoice-list"
                render={() => <InvoiceBuyerScreen8 drawerOpen={drawerOpen} />}
              />

              <Route
                path="/wp-invoice-list"
                render={() => <ListApprovedInvoices drawerOpen={drawerOpen} />}
              />

              <Route
                path="/po-changes"
                render={() => <PoChanges drawerOpen={drawerOpen} />}
              />

              <Route
                path="/resource-onboarding"
                render={() => <ResourceOnboarding drawerOpen={drawerOpen} />}
              />

              <Route
                path="/supplier-onboarding"
                render={() => <SupplierOnboarding drawerOpen={drawerOpen} />}
              />

              <Route
                path="/view-purchase-orders"
                render={() => <ViewPO drawerOpen={drawerOpen} />}
              />

              <Route path="/unlicensed" render={() => <License />} />

              <Route
                path="/purchaseOrders"
                render={() => <PoUpload drawerOpen={drawerOpen} />}
              />

              <Route
                path="/company/:id"
                render={() => (
                  <Company
                    toggleValue={toggleValue}
                    userInfo={data?.getLoginUserInfo}
                    drawerOpen={drawerOpen}
                  />
                )}
              />

              <Route
                path="/mycompany/:id"
                render={() => (
                  <Company
                    toggleValue={toggleValue}
                    userInfo={data?.getLoginUserInfo}
                    drawerOpen={drawerOpen}
                  />
                )}
              />

              <Route
                path="/product/:id"
                render={() => (
                  <ProductPage
                    type="product"
                    drawerOpen={drawerOpen}
                    mainDrawerClick={mainDrawerClick}
                  />
                )}
              />

              <Route
                path="/service/:id"
                render={() => (
                  <ProductPage type="service" drawerOpen={drawerOpen} />
                )}
              />

              <Route path="/usecase/:id" component={UsecasePage} />

              <Route
                path="/casestudy/:id"
                render={() => (
                  <CaseStudyPage userInfo={data?.getLoginUserInfo} />
                )}
              />

              <Route
                path="/group/:typeValue/:id"
                render={() => (
                  <Groups toggleValue={toggleValue} drawerOpen={drawerOpen} />
                )}
              />

              <Route
                path="/topic/:typeValue/:id"
                render={() => (
                  <TopicPage
                    toggleValue={toggleValue}
                    drawerOpen={drawerOpen}
                  />
                )}
              />

              <Route
                path="/skill/:typeValue/:id"
                render={() => (
                  <SkillPage
                    toggleValue={toggleValue}
                    drawerOpen={drawerOpen}
                  />
                )}
              />

              <Route path="/groups" component={GroupList} />

              <Route path="/topics" component={topiclist} />

              <Route
                path="/rolemanagement"
                render={() => (
                  <RoleManagement userInfo={data?.getLoginUserInfo} />
                )}
              />

              <Route
                path="/tagmanagement"
                render={() => (
                  <TagManagement userInfo={data?.getLoginUserInfo} />
                )}
              />

              <Route
                path="/venders"
                render={(props) => (
                  <VendorPage
                    companyvbeid={data?.getLoginUserInfo.companyvbeid}
                    profileStatus={data?.getLoginUserInfo.role.includes(
                      "PROFILE_ADMIN"
                    )}
                    drawerOpen={drawerOpen}
                  />
                )}
              />

              <Route
                path="/register"
                render={(props) => (
                  <Registration isProfile={false} drawerOpen={drawerOpen} />
                )}
              />

              <Route
                path="/profile"
                render={(props) => (
                  <Registration isProfile={true} drawerOpen={drawerOpen} />
                )}
              />

              <Route
                path="/admin"
                render={(props) => (
                  <AdminPage
                    userInfo={data?.getLoginUserInfo}
                    refetch={userInfofetch}
                    drawerOpen={drawerOpen}
                  />
                )}
              />

              <Route
                path="/legalentity"
                render={() => (
                  <LegalEntityPage userInfo={data?.getLoginUserInfo} />
                )}
              />

              <Route
                path="/paymentterms"
                render={() => (
                  <PaymentTerm
                    userInfo={data?.getLoginUserInfo}
                    companyvbeid={data?.getLoginUserInfo.companyvbeid}
                  />
                )}
              />

              <Route
                path="/documentmanagement"
                render={() => (
                  <DocumentMangement
                    userInfo={data?.getLoginUserInfo}
                    companyvbeid={data?.getLoginUserInfo.companyvbeid}
                  />
                )}
              />

              <Route path="/lists/:id" component={ListinList} />
              <Route path="/products" component={ProductsProfileList} />
              {/* <Route path="/companies" component={CompanyProfileValidation} /> */}
              <Route path="/dataValidation/companies" component={CompanyGrid} />
              <Route path="/dataValidation/products" component={ProductGrid} />
              <Route path="/dataValidation/usecases" component={UsecaseGrid} />
              <Route
                path="/dataValidation/casestudies"
                component={CasestudyGrid}
              />
              <Route path="/leads" component={LeadsGrid} />
              <Route
                path="/lead/:id/:productid/:invitedid"
                component={LeadOverview}
              />
              <Route path="/addcompany" component={CreateCompanyShort} />
              {/* <Route path="/knowledgebase" component={Knowledgebase} /> */}

              <Route
                path="/agreements"
                render={() => <Agreements drawerOpen={drawerOpen} />}
              />

              <Route path="/agreementAccepted" component={AgreementSuccess} />
              <Route path="/agreementSkip" component={AgreementFail} />
              <Route path="/agreementDeclined" component={AgreementFail} />

              <Route path="/conciergerequests" component={ConciergeTable} />
              <Route path="/viewconcierge/:id" component={ViewConcierge} />

              <Route path="/templates" component={TemplatesPage} />
              <Route path="/createTemplate/:id" component={CreateTemplate} />

              <Route path="/vprojects3.0" component={VendorProjects} />

              <Route
                path="/projects3.0/:type"
                render={() => (
                  <ProjectMain
                    userInfo={data?.getLoginUserInfo}
                    companyvbeid={data?.getLoginUserInfo.companyvbeid}
                    drawerOpen={drawerOpen}
                    mainDrawerClick={mainDrawerClick}
                  />
                )}
              />

              <Route
                path="/create-project-v3/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />

              <Route
                path="/create-service-project-v3/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />

              <Route
                path="/create-workpackage-project-v3/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />

              <Route
                path="/job-description/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />

              <Route
                path="/wp-job-description/:id"
                render={() => (
                  <ProjectFlowHome
                    drawerOpen={drawerOpen}
                    projectType="workpackage"
                  />
                )}
              />

              <Route
                path="/recommended-providers/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />

              <Route
                path="/wp-templates/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />

              <Route
                path="/add-team-to-project/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />
              <Route
                path="/service-add-team/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />
              <Route
                path="/add-short-description/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />
              <Route
                path="/add-vendors/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />
              <Route
                path="/service-response-template/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />
              <Route
                path="/requirement-template/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />
              <Route
                path="/update-requirements/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />
              <Route
                path="/other-templates/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />
              <Route
                path="/response-templates/:id"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />
              <Route
                path="/review-project/:id"
                render={() => <ProjectReview drawerOpen={drawerOpen} />}
              />
              <Route
                path="/review-service-project/:id"
                render={() => <ServiceReview drawerOpen={drawerOpen} />}
              />

              <Route
                path="/wp-project-review/:id"
                render={() => <ServiceReview drawerOpen={drawerOpen} />}
              />

              <Route
                path="/project/:type/:id"
                render={() => <ProjectOverview drawerOpen={drawerOpen} />}
              />

              <Route
                path="/project"
                render={() => <ProjectOverview drawerOpen={drawerOpen} />}
              />

              <Route
                path="/project-response/:type/:id/:productId"
                render={() => <ResponseOverview drawerOpen={drawerOpen} />}
              />

              <Route
                path="/other-templates"
                render={() => <ProjectFlowHome drawerOpen={drawerOpen} />}
              />

              <Route
                path="/create-template/:type/:id"
                component={DesignTemplate}
              />

              <Route path="/project-templates/:id" component={TemplatesHome} />

              <Route path="/productMap" component={productsList} />
            </Switch>
          </ErrorBoundary>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
