import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ProjectConfirm({
  open,
  onConfirm,
  onCancel,
  templateType,
}) {
  const onClick = () => {
    onConfirm();
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Template</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {templateType === "qna"
              ? "You are about to select this Q&A template which will be made available to suppliers to respond. You can always switch to alternate templates or create new template till you submit the project."
              : templateType === "response"
              ? "You are about to select this Response template which will be made available to suppliers to provide overall response. You can always switch to alternate templates or create new template till you submit the project."
              : templateType === "evaluation"
              ? "You are about to select this evaluation template to evaluate the suppliers you have shortlisted. Click Confirm to select the template"
              : "You are about to select this requirement template to define your requirements. Should you want to switch to another template after writing content in the next tab, content will not switch into the new template. Click “Confirm” to select the template."}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>

          <Button
            onClick={onClick}
            color="primary"
            variant="contained"
            autoFocus
            size="small"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
