import React, { useState } from "react";

import { Grid, Tabs, Tab, Divider } from "@material-ui/core";

import { useStyles } from "./styles";

import { a11yProps } from "../../../functions/helper";

import TabPanel from "../common/TabPanel";

import TemplateTypeButtons from "./TemplateTypeButtons";
import TemplatesList from "./TemplatesList";

import { useApolloClient, useQuery } from "@apollo/client";
import { getTemplates } from "./query";

import { getTemplateType } from "./templateHelper";

export default function TemplatesPage({ userInfo }) {
  const classes = useStyles();
  const client = useApolloClient();

  const [selectedTemplate, setSelectedTemplate] = useState("questionnaire");

  const [value, setValue] = useState(0);

  const { data: templatesList, loading } = useQuery(getTemplates, {
    variables: { input: {} },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onTemplateClick = (option) => {
    setSelectedTemplate(option);
  };

  const getFilteredList = (tab) => {
    let filteredList = [];

    if (templatesList) {
      templatesList.listTemplates.forEach((template, index) => {
        if (
          (getTemplateType(template.type) ===
            getTemplateType(selectedTemplate) ||
            template.type === getTemplateType(selectedTemplate)) &&
          template.for === tab
        ) {
          filteredList.push(
            Object.assign({}, template, { id: template.vbeid })
          );
        }
      });
    }

    return filteredList;
  };

  const updateListCache = (template) => {
    const { listTemplates } = client.readQuery({
      query: getTemplates,
      variables: { input: {} },
    });

    let listCache = [template.createTemplate, ...listTemplates];

    client.writeQuery({
      query: getTemplates,
      data: {
        listTemplates: {
          __typename: "generic",
          listTemplates: listCache,
        },
      },
      variables: { input: {} },
    });
  };

  const updateTemplateCache = (template) => {
    const { listTemplates } = client.readQuery({
      query: getTemplates,
      variables: { input: {} },
    });

    let templateIndex = listTemplates.findIndex(
      (temp) => temp.vbeid === template.updateTemplate.vbeid
    );

    let listCache = [...listTemplates];

    listCache.splice(templateIndex, 1, template.updateTemplate);

    client.writeQuery({
      query: getTemplates,
      data: {
        listTemplates: {
          __typename: "generic",
          listTemplates: listCache,
        },
      },
      variables: { input: {} },
    });
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid md={12} item>
          Templates
        </Grid>

        <Grid item md={2} className={classes.templateOptionsDiv}>
          <TemplateTypeButtons
            name="Project Screening Questions"
            selectedTemplate={selectedTemplate}
            onTemplateClick={onTemplateClick}
            type="questionnaire"
          />

          <TemplateTypeButtons
            name="Project Response"
            selectedTemplate={selectedTemplate}
            onTemplateClick={onTemplateClick}
            type="response"
          />

          <TemplateTypeButtons
            name="Project Request"
            selectedTemplate={selectedTemplate}
            onTemplateClick={onTemplateClick}
            type="request"
          />

          <TemplateTypeButtons
            name="Due Diligence"
            selectedTemplate={selectedTemplate}
            onTemplateClick={onTemplateClick}
            type="diligence"
          />
        </Grid>

        <Grid item md={1}></Grid>

        <Grid item md={8} className={classes.dataGridDiv}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab className={classes.tabs} label="Public" {...a11yProps(0)} />

            <Tab
              className={classes.tabs}
              label="Organisation"
              {...a11yProps(1)}
            />

            <Tab
              className={classes.tabs}
              label="Individual"
              {...a11yProps(2)}
            />
          </Tabs>

          <Divider />

          <TabPanel value={value} index={0}>
            <TemplatesList
              type={selectedTemplate}
              tab="public"
              list={getFilteredList("public")}
              update={updateListCache}
              updateTemplateCache={updateTemplateCache}
              loading={loading}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <TemplatesList
              type={selectedTemplate}
              tab="company"
              list={getFilteredList("company")}
              update={updateListCache}
              updateTemplateCache={updateTemplateCache}
              loading={loading}
            />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <TemplatesList
              type={selectedTemplate}
              tab="person"
              list={getFilteredList("person")}
              update={updateListCache}
              updateTemplateCache={updateTemplateCache}
              loading={loading}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}
