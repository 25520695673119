import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, gql, useQuery } from "@apollo/client";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BootstrapInput } from "../../../../../theme/theme";
import InputLabel from "@material-ui/core/InputLabel";
import VbridgeAlert from "../../../../shared/alert";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  errorfields: {
    color: "red",
  },
  textfields: {
    width: "100%",
  },
  buttonStyle: {
    borderRadius: "2rem",
    textTransform: "capitalize",
    fontSize: "0.7rem",
  },
  buttonsAlign: {
    paddingBottom: theme.spacing(2),
  },
}));

//const responseOptions = ['APPROVED', 'REJECTED'];

export default function SendResponse(props) {
  const classes = useStyles();
  // const client = useApolloClient();
  const [open, setOpen] = React.useState(false);
  const timer = React.useRef();
  //const [ setLoading] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const { refetch } = props;

  React.useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearTimeout(timer.current);
    };
  }, []);

  // const handleButtonClick = () => {
  //   if (!mutationLoading) {
  //     setLoading(true);
  //     timer.current = window.setTimeout(() => {
  //       setLoading(false);
  //       handleClose();
  //     }, 500);
  //   }
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = yup.object().shape({
    remarks: yup
      .string("Enter personal message")
      .min(2, "Message should be of minimum 2 characters length")
      .required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      remarks: "",
    },
    validationSchema: validationSchema,
  });

  //send response
  const postresponse = gql`
    mutation response($input: projectinput!) {
      repondToRequest(input: $input) {
        id
        vbeid
        status
      }
    }
  `;

  const [
    postresponsemutation,
    { loading: mutationLoading, error: responseError, data: response },
  ] = useMutation(postresponse, {
    onCompleted: (e) => {
      refetch();
    },
  });

  //,{onCompleted:(e)=>{UpdateCache(e)}}

  function CreateResponse(e) {
    e.preventDefault();

    // console.log({vbeid:props.relationid,
    //   status:status,
    //   remarks:formik.values.remarks
    // })

    postresponsemutation({
      variables: {
        input: {
          vbeid: props.relationid,
          status: status,
          remarks: formik.values.remarks,
        },
      },
    });
    //handleButtonClick();
  }

  ///list information
  const listLeads = gql`
    query leadinformation($input: projectinput!) {
      getLeadsByCompanyID(input: $input) {
        id
        vbeid
        name
        image
        productname
        productvbeid
        personname
        customername
        customervbeid
        companyname
        companyvbeid
        description
        status
        relationvbeid
        longdescription
      }
    }
  `;

  useQuery(listLeads, { variables: { input: { status: "INVITED" } } });

  //   function UpdateCache(e)
  // {
  //     const {getLeadsByCompanyID} =  client.readQuery({query:listLeads,variables:{input:{}}});
  //     let listCache = {...getLeadsByCompanyID};
  //     listCache = listCache.filter(value =>value.id!==e.repondToRequest.id)

  //     client.writeQuery({query:listLeads,
  //         data:{
  //           getLeadsByCompanyID: listCache
  //         },
  //         variables:{input:{}}});
  // }

  return (
    <>
      <Grid container>
        <Grid item xs={10}>
          {response ? (
            <VbridgeAlert
              severity="success"
              open={true}
              message="Response Sent Successfully, Navigate to Submit Proposal Tab to fill your proposal. "
            />
          ) : null}
          {responseError ? (
            <VbridgeAlert
              severity="error"
              open={true}
              message="Something went wrong ... "
            />
          ) : null}
        </Grid>
      </Grid>

      <div>
        <Grid container md={12}>
          {/* <ButtonGroup disableElevation={false}  size='small' variant="text" color="primary" style={{marginBottom:"3%"}}> */}

          <Grid item md={12} className={classes.buttonsAlign}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleClickOpen();
                setStatus("APPROVED");
              }}
              size="small"
              className={classes.buttonStyle}
              startIcon={<DoneIcon />}
            >
              Pursue Opportunity
            </Button>
          </Grid>

          <Grid item md={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleClickOpen();
                setStatus("REJECTED");
              }}
              size="small"
              className={classes.buttonStyle}
              startIcon={<CloseIcon />}
            >
              Decline Opportunity
            </Button>
          </Grid>

          {/* </ButtonGroup> */}
        </Grid>

        <form>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Send Response</DialogTitle>
            <DialogContent dividers>
              <DialogContentText>
                Please enter a note of acknowledgement. This will be sent as a
                notification to the buyer.{" "}
              </DialogContentText>
              <div className={classes.root}>
                <Grid container style={{ width: "100%" }}>
                  <Grid item md={10}>
                    <FormControl className={classes.textfields}>
                      <InputLabel
                        required={true}
                        shrink
                        htmlFor="my-input"
                        focused={true}
                      >
                        Message
                      </InputLabel>
                      <BootstrapInput
                        required={true}
                        id="my-input"
                        aria-describedby="my-helper-text"
                        value={formik.values.remarks}
                        onChange={formik.handleChange("remarks")}
                        onBlur={formik.handleBlur("remarks")}
                        placeholder="Enter message"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item className={classes.errorfields}>
                    {formik.touched.remarks && formik.errors.remarks ? (
                      <div className="error-message">
                        {formik.errors.remarks}
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>

              <Button
                onClick={CreateResponse}
                type="submit"
                color="primary"
                disabled={!(formik.isValid && formik.dirty)}
              >
                Send
              </Button>
              {mutationLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </DialogActions>
          </Dialog>
        </form>
      </div>
    </>
  );
}
