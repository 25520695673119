import * as React from "react";

import { useStyles } from "./styles";

import { Grid } from "@material-ui/core";

import { useFormik } from "formik";
import { useLazyQuery, useQuery } from "@apollo/client";

import { getUsecases, getBatches } from "./query";
import UsecaseFilters from "./useCaseFilters";
import UsecaseValidation from "./useCaseValidation";

export default function UsecaseGrid() {
  const classes = useStyles();
  const [usecaseBatch, setUsecaseBatch] = React.useState([]);

  //filter params and updateForm values
  const formik = useFormik({
    initialValues: {
      pagefrom: "",
      pageto: "",
      batchvbeid: "",
    },
  });

  const [pageOffset, setPageOffset] = React.useState("");

  //getBatches
  const { data: listBatches } = useQuery(getBatches);

  //query to get the usecase list
  const [getUsecaseResults, { loading, refetch }] = useLazyQuery(getUsecases, {
    onCompleted: (e) => {
      setUsecaseBatch(e.getUsecasesByBatch);
    },
  });

  //function to query the usecase batch results
  function QueryBatch() {
    getUsecaseResults({
      variables: {
        input: {
          offset: formik.values.pagefrom,
          limit: formik.values.pageto,
          batchvbeid: formik.values.batchvbeid,
        },
      },
    });

    setPageOffset(formik.values.pageto);
  }

  //query to get the usecases list
  const [getUsecasePageChangeResults] = useLazyQuery(getUsecases, {
    onCompleted: (e) => {
      setUsecaseBatch([...usecaseBatch, ...e.getUsecasesByBatch]);
    },
  });

  //onpage change in datagrid api call
  function QueryBatchOnPageChange(e) {
    getUsecasePageChangeResults({
      variables: {
        input: {
          offset: pageOffset,
          limit: formik.values.pageto,
          batchvbeid: formik.values.batchvbeid,
        },
      },
    });

    setPageOffset(pageOffset + formik.values.pageto);
  }

  return (
    <Grid container md={12} className={classes.dataGridAlign}>
      <Grid item md={10}>
        {listBatches ? (
          <UsecaseFilters
            batches={listBatches.getBatchesList.length >= 1 ? listBatches : ""}
            formik={formik}
            method={QueryBatch}
          />
        ) : null}
      </Grid>

      <Grid item md={12}>
        <UsecaseValidation
          usecaseBatch={usecaseBatch}
          loading={loading}
          fetch={refetch}
          updateBatch={setUsecaseBatch}
          pageChanging={QueryBatchOnPageChange}
          batchvbeid={formik.values.batchvbeid}
        />
      </Grid>
    </Grid>
  );
}
