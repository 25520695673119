import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";

import { useFormik } from "formik";
import * as yup from "yup";

import { DialogContentText, FormHelperText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";

import AddNewButton from "../AddNew/AddNewButton";

import { postUseCase } from "../query";
import {
  getUsecasesByCompanyId,
  listProducts,
} from "../../../companies/Company2.0/query";

import SimpleErrorSnackbar from "./errorsnackbar";

import InputField from "../../../common/InputField";
import SelectField from "../../../common/SelectField";

import { useStyles } from "./styles";

const verticals = require("../../../../../data/companyVerticals.json");

export default function AddUseCaseForm({ updateCache }) {
  const classes = useStyles();
  const { id } = useParams();
  const timer = useRef();

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [noUrl, setNoUrl] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!mutationLoading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter Resource Name")
      .min(2, "Resource Name should be of minimum 2 characters length")
      .required("Resource Name is required"),

    url: yup
      .string("Enter Resource URL")
      //   .required("Resource URL is required")
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        "Enter Resource URL"
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      url: "",
      businessvertical: [],
      product: "",
    },
    validationSchema: validationSchema,
  });

  const { data: products } = useQuery(listProducts, {
    variables: { input: { vbeid: id } },
  });

  useQuery(getUsecasesByCompanyId, {
    variables: { input: { vbeid: id } },
  });

  let productIds = [];
  products?.productByComapnyId.forEach((product) => {
    productIds.push({
      type: product.vbeid,
      value: product.name,
    });
  });

  const [
    postUsecaseMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(postUseCase, {
    onCompleted: (e) => {
      handleClose();
      updateCache(e);
    },
  });

  const createUsecaseShort = (e) => {
    e.preventDefault();

    postUsecaseMutation({
      variables: {
        input: {
          name: formik.values.name,
          productlist: values,
          url: formik.values.url,
          industryvertical: formik.values.businessvertical,
        },
      },
    });

    handleButtonClick();
  };

  let VerticalList = [];

  verticals.forEach((name) => {
    VerticalList.push({
      type: name["n.vbeid"],
      value: name["n.name"],
    });
  });

  const handleOnChange = (event) => {
    let selectedValues = values;
    let newValue = event.target.value;

    let index = selectedValues.indexOf(newValue);

    if (index !== -1) {
      setValues([
        ...selectedValues.slice(0, index),
        ...selectedValues.slice(index + 1),
      ]);
    } else {
      setValues(newValue);
    }
  };

  const onNoUrlClick = () => {
    setNoUrl(!noUrl);
  };

  return (
    <div>
      {mutationError && <SimpleErrorSnackbar />}
      <AddNewButton name="Add New Resource" handleClick={handleClickOpen} />

      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className={classes.title}>
            Add Resource
          </DialogTitle>

          <DialogContent dividers>
            <DialogContentText>
              Please enter following details to create a resource.
            </DialogContentText>

            <div className={classes.root}>
              <Grid container>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputField
                      label="Resource Name *"
                      value={formik.values.name}
                      onChange={formik.handleChange("name")}
                      onBlur={formik.handleBlur("name")}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={11}>
                  {formik.errors.name ? (
                    <div className={classes.errorMsg}>{formik.errors.name}</div>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputField
                      label={!noUrl ? "Resource URL *" : "No URL available"}
                      value={formik.values.url}
                      onChange={formik.handleChange("url")}
                      onBlur={formik.handleBlur("url")}
                      disabled={noUrl}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={11}>
                  {formik.errors.url && !noUrl ? (
                    <div className={classes.errorMsg}>{formik.errors.url}</div>
                  ) : null}
                </Grid>

                <FormHelperText margin="dense">
                  * Please add a resource URL as available on your website for
                  the resource. Our AI will also use this content and metadata
                  when buyers are using Advanced Search on vBridge Hub.
                </FormHelperText>

                <FormHelperText
                  margin="dense"
                  className={classes.noUrlText}
                  onClick={onNoUrlClick}
                >
                  {!noUrl
                    ? "Click here if you do not have a URL for the resource."
                    : "Click here to enter a URL for the resource."}
                </FormHelperText>
              </Grid>

              {/* <Grid container> */}
              {/* <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <SelectField
                      label=" Customer Business Vertical *"
                      value={formik.values.businessvertical}
                      onChange={formik.handleChange("businessvertical")}
                      onBlur={formik.handleBlur("businessvertical")}
                      options={VerticalList}
                      multiple={true}
                    />
                  </FormControl>
                </Grid>
              </Grid> */}

              <Grid container>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <SelectField
                      label="Map Applicable Product"
                      options={productIds}
                      onChange={handleOnChange}
                      value={values}
                      multiple={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              className={classes.addNewBtn}
            >
              Cancel
            </Button>

            <Button
              onClick={createUsecaseShort}
              type="submit"
              color="primary"
              disabled={!(formik.isValid && formik.dirty) || loading}
              className={classes.addNewBtn}
              variant="contained"
            >
              Add
            </Button>

            {mutationLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
