import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from "@material-ui/data-grid";
import ProductFilters from "./productFilters";
import ProdDataGrid from "./ProdDataGrid";
import ProductPages from "./ProductPages";
import { useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { RecGetProductsbyMappedType, RecListBatches } from "./query";
import { Paper } from "@material-ui/core";
import { useStyles } from "./styles";

export default function DataTable() {
  const classes = useStyles();

  //filter params and updateForm values
  const formik = useFormik({
    initialValues: {
      pagefrom: "",
      pageto: "",
      batchvbeid: "",
    },
  });

  const [pageOffset, setPageOffset] = React.useState("");

  // const { data: ListBatches } = useQuery(RecListBatches, {
  //   variables: {
  //     input: {},
  //   },
  // });

  // if (ListBatches) {
  //   console.log(
  //     "ListBatches",
  //     ListBatches?.RecListBatches.map((element) => {
  //       return element.id;
  //     })
  //   );
  // }

  const { data, loading, refetch } = useQuery(RecGetProductsbyMappedType, {
    variables: {
      input: {
        offset: formik.values.pagefrom,
        limit: formik.values.pageto,
        vbeid: formik.values.batchvbeid,
        type: "mapped",
      },
    },
  });

  if (data) {
    console.log("datav", data?.RecGetProductsbyMappedType);
  }

  const [checked, setChecked] = React.useState([]);

  console.log("checked", checked);

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  return (
    <>
      <ProductFilters
        fetch={refetch}
        formik={formik}
        method={data?.RecGetProductsbyMappedType}
      />

      <div className={classes.mainroot}>
        <Paper>
          <ProductPages
            data={data?.RecGetProductsbyMappedType}
            fetch={refetch}
            loading={loading}
            handleToggle={handleToggle}
            checked={checked}
          />
        </Paper>
      </div>
    </>
  );
}
