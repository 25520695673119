import React from "react";

import { useParams } from "react-router";

import { Typography } from "@material-ui/core";

import { useQuery } from "@apollo/client";

import { listrequirement } from "../project/query";
import RequirementTabs from "./requirementTabs";
import { getQuestions } from "./query";

export default function ViewRequirement() {
  let { id, productid } = useParams();

  const [submittedRequirement, setSubmittedRequirement] = React.useState([]);
  const [submittedQuestions, setSubmittedQuestions] = React.useState([]);

  //listquestions
  useQuery(getQuestions, {
    variables: { input: { vbeid: id, productvbeid: productid } },
    fetchPolicy: "cache-and-network",
    onCompleted: (e) => {
      SubmittedQuestionObjects(e);
    },
  });

  useQuery(listrequirement, {
    variables: { input: { vbeid: id, status: "submitted" } },
    fetchPolicy: "cache-and-network",
    onCompleted: (e) => {
      SubmittedObjects(e);
    },
  });

  function SubmittedObjects(e) {
    // eslint-disable-next-line array-callback-return
    let list = e.listsupportDoc
      .map((value) => {
        //  return value.status==='submitted'?value:null
        if (value.status === "submitted") {
          return value;
        }
      })
      .filter((x) => x !== undefined);

    setSubmittedRequirement(list);
  }

  function SubmittedQuestionObjects(e) {
    if (e.listDeclarationQuestionAnswer.length >= 1) {
      // eslint-disable-next-line array-callback-return
      let list = e.listDeclarationQuestionAnswer
        .map((value) => {
          // return value.status==='submitted'?value:null
          if (value.status === "submitted") {
            return value;
          }
        })
        .filter((x) => x !== undefined);
      setSubmittedQuestions(list);
    }
  }

  const columns = [
    { field: "question", headerName: "Question", width: 450 },
    {
      field: "option",
      headerName: "Mandatory/Optional",
      width: 200,
      renderCell: (params) => (
        <Typography variant="caption">
          {params.getValue("option") === "True" ? "Mandatory" : "Optional"}
        </Typography>
      ),
    },
  ];

  return (
    <>
      {/* {submittedRequirement[0]!==undefined ?(
        <ViewSubmittedRequirement lead={true} submittedRequirement={submittedRequirement}/>
        ):(null)} */}

      <RequirementTabs
        lead={true}
        submittedRequirement={submittedRequirement}
        submittedQuestions={submittedQuestions}
        columns={columns}
      />
    </>
  );
}
