/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { useStyles } from "./styles";

import "./cssstyles.css";
import SelectField from "../common/SelectField";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {
  CreateLegalEntity,
  listLegalEntity,
  signed,
  updateEditLegalEntity,
  getSigned,
} from "./query";

import {
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import SimpleSnackbar from "../groups2.0/snackbar";
import UploadFile from "./UploadFile";
import OutlinedButton from "../companies/company/OutlinedButton";
import InputField from "../common/InputField";
import AutoCompleteField from "./AutoCompleteField";

const states = require("../../../data/states_bycountries.json");
const countries = require("../../../data/countriesdata.json");
const axios = require("axios");

export default function AddLegalEntity({
  userInfo,
  onClose,
  pageType,
  legalentity,
  createdon,
  clearForm,
  approveState,
  archiveState,
  status,
  submitForm,
}) {
  const validationSchema = yup.object().shape({
    legalentity: yup
      .string("Enter Legal Entity Name")
      .min(2, "Name should be of minimum 2 characters length")
      .required("Field required"),

    companyname: yup
      .string("Enter Company Name")
      .min(2, "Name should be of minimum 2 characters length")
      .required("Field required"),

    address: yup.string("Enter Address").required("Field required"),

    postalcode: yup.string("Enter Postal Code").required("Field required"),

    pannumber: yup.string("Enter  PAN Number").required("Field required"),

    gstnumber: yup.string("Enter  GST Number").required("Field required"),

    currency: yup.string("Select Currency").required("Field required"),

    bankname: yup.string("Enter Bank Name").required("Field required"),

    region: yup.string("Enter Region"),

    accountnumber: yup
      .string("Enter Account Number")
      .required("Field required"),

    contactname: yup.string("Enter Name").required("Field required"),

    contactemail: yup
      .string("Invalid email")
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid email address"
      ),
    contacttitle: yup.string("Enter Title").required("Field required"),

    legalcontactname: yup.string("Enter Name").required("Field required"),

    legalcontactemail: yup
      .string("Invalid email")
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid email address"
      ),

    legalcontacttitle: yup.string("Enter Title").required("Field required"),

    routingnumber: yup.string().required("Field required"),

    ifsccode: yup.string().required("Field required"),
  });

  const { data: list, refetch } = useQuery(listLegalEntity, {
    variables: { input: {} },
  });

  let entityData = [];

  if (pageType === "edit") {
    let panDocs = [];
    let gstDocs = [];
    let bankDocs = [];

    legalentity?.legalentity?.pangst.panattachments?.map((attachments) => {
      return panDocs.push(attachments);
    });

    legalentity?.legalentity?.pangst.gstattachments?.map((attachments) => {
      return gstDocs.push(attachments);
    });

    legalentity?.banking?.attachments?.map((attachments) => {
      return bankDocs.push(attachments);
    });

    list?.getLegalEntity?.map((legalentity) => {
      if (legalentity.createdon === createdon) {
        return entityData.push({
          panDoc: panDocs,
          gstDoc: gstDocs,
          bankDoc: bankDocs,

          leEntityName: legalentity.legalentity.legalentityname,
          leCompany: legalentity.legalentity.companyname,
          leaddress: legalentity?.legalentity?.address.address,
          optaddress: legalentity?.legalentity?.address.optionaladdress,
          country: legalentity?.legalentity?.address.country,
          state: legalentity?.legalentity?.address.state,
          city: legalentity?.legalentity?.address.city,
          postalcode: legalentity?.legalentity?.address.postalcode,
          pannumber: legalentity?.legalentity?.pangst.pannumber,
          gstnumber: legalentity?.legalentity?.pangst.gstnumber,
          bankname: legalentity?.banking?.bankname,
          accountnumber: legalentity?.banking?.accountnumber,
          ifsccode: legalentity?.banking?.ifsccode,
          bankcountry: legalentity?.banking?.country,
          currency: legalentity?.banking?.currency,
          routingnumber: legalentity?.banking?.routingnumber,
          acname: legalentity?.accountingcontactinfo?.contactname,
          acemail: legalentity?.accountingcontactinfo?.contactemail,
          actitle: legalentity?.accountingcontactinfo?.contacttitle,
          lename: legalentity?.legalcontactinfo?.contactname,
          lemail: legalentity?.legalcontactinfo?.contactemail,
          letitle: legalentity?.legalcontactinfo?.contacttitle,
        });
      } else return null;
    });
  }

  const classes = useStyles();
  const [selectedValue, setSelectedValues] = useState(entityData[0]?.country);
  const [selectedState, setSelectedState] = useState(entityData[0]?.state);
  const [fileName, setFileName] = useState("");
  const [fileNameGst, setFileNameGst] = useState("");
  const [fileNameBank, setFileNameBank] = useState("");
  const [selectedBankCountry, setSelectedCountry] = useState(
    entityData[0]?.bankcountry
  );
  const [selectedVbeid, setselectedVbeid] = useState("");
  const [callAPi, setCallApi] = useState(false);
  const [statusMsg, setStatusMsg] = useState(false);
  const [message, setMessage] = useState("");

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [storeFile, setStoreFiles] = useState(
    pageType === "edit" ? entityData[0]?.panDoc : []
  );
  // pageType === "edit" ?

  const [uploadedFilesGst, setUploadedFilesGst] = useState([]);
  const [storeFileGst, setStoreFilesGst] = useState(
    pageType === "edit" ? entityData[0]?.gstDoc : []
  );

  const [uploadedFilesBank, setUploadedFilesBank] = useState([]);
  const [storeFileBank, setStoreFilesBank] = useState(
    pageType === "edit" ? entityData[0]?.bankDoc : []
  );

  //files Show
  const [signedUrl, { data: url, loading: getUrlLoading }] = useLazyQuery(
    getSigned,
    {
      onCompleted: (e) => {
        window.open(e.getSignedURL.signedurl, "_blank");
      },
    }
  );

  function CreateUrl(key, filetype) {
    signedUrl({
      variables: {
        input: {
          key: key,
          filetype: filetype,
        },
      },
    });
  }

  const formik = useFormik({
    initialValues: entityData
      ? {
          legalentity: entityData[0]?.leEntityName,
          companyname: entityData[0]?.leCompany,
          address: entityData[0]?.leaddress,
          addressoptional: entityData[0]?.optaddress,
          region: entityData[0]?.city,
          postalcode: entityData[0]?.postalcode,
          pannumber: entityData[0]?.pannumber,
          gstnumber: entityData[0]?.gstnumber,
          currency: entityData[0]?.currency,
          bankname: entityData[0]?.bankname,
          accountnumber: entityData[0]?.accountnumber,
          contactname: entityData[0]?.acname,
          contactemail: entityData[0]?.acemail,
          contacttitle: entityData[0]?.actitle,
          legalcontactname: entityData[0]?.lename,
          legalcontactemail: entityData[0]?.lemail,
          legalcontacttitle: entityData[0]?.letitle,
          routingnumber: entityData[0]?.routingnumber,
          ifsccode: entityData[0]?.ifsccode,
        }
      : {
          legalentity: "",
          companyname: "",
          address: "",
          addressoptional: "",
          region: "",
          postalcode: "",
          pannumber: "",
          gstnumber: "",
          currency: "",
          bankname: "",
          accountnumber: "",
          contactname: "",
          contactemail: "",
          contacttitle: "",
          legalcontactname: "",
          legalcontactemail: "",
          legalcontacttitle: "",
          routingnumber: "",
          ifsccode: "",
        },

    validationSchema: validationSchema,
  });

  let optCountries = [];
  let optStates = [];
  let countryid = "";

  countries?.map((country) => {
    return optCountries.push({
      name: country.countryname,
      value: country.countryname,
      vbeid: country.countryvbeid,
    });
  });

  const onCountrySelect = (values, selectedVbeid) => {
    setSelectedValues(values);
    setSelectedState("");
  };

  const onCountryBankSelect = (values, selectedVbeid) => {
    setSelectedCountry(values);
  };

  countries?.map((country) => {
    if (country.countryname === selectedValue) {
      return (countryid = country.countryvbeid);
    } else return null;
  });

  states?.map((state) => {
    if (countryid === state["cntry.vbeid"]) {
      return optStates.push({
        name: state["ste.name"],
        value: state["ste.name"],
        vbeid: state["cntry.vbeid"],
      });
    } else return null;
  });

  const onStateSelect = (values, selectedVbeid) => {
    setSelectedState(values);
    formik.values.locationstate = values;
  };

  const currecyType = [
    { type: "INR", value: "INR" },
    { type: "EUR", value: "EUR" },
    { type: "GBP", value: "GBP" },
    { type: "USD", value: "USD" },
    { type: "AUD", value: "AUD" },
    { type: "SGD", value: "SGD" },
    { type: "CAD", value: "CAD" },
    { type: "SAR", value: "SAR" },
    { type: "MYR", value: "MYR" },
    { type: "ZAR", value: "ZAR" },
    { type: "JPY", value: "JPY" },
    { type: "CHF", value: "CHF" },
    { type: "SEK", value: "SEK" },
    { type: "PLN", value: "PLN" },
  ];

  const [
    legalEntitySubmit,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(CreateLegalEntity, {
    onCompleted: (e) => {
      refetch();
      onClose();
      clearForm();
    },
  });

  const addLegalEntity = (e) => {
    // e.preventDefault();

    legalEntitySubmit({
      variables: {
        input: {
          // createdon: legalentity?.createdon,
          legalentity: {
            legalentityname: formik.values.legalentity,
            companyname: formik.values.companyname,
            address: {
              address: formik.values.address,
              optionaladdress: formik.values.addressoptional,
              country: selectedValue,
              state: selectedState,
              city: formik.values.region,
              postalcode: formik.values.postalcode,
            },
            pangst: {
              pannumber: formik.values.pannumber,
              panattachments: uploadedFiles,
              gstnumber: formik.values.gstnumber,
              gstattachments: uploadedFilesGst,
            },
          },

          banking: {
            country: selectedValue,
            currency: formik.values.currency,
            bankname: formik.values.bankname,
            accountnumber: formik.values.accountnumber,
            routingnumber: formik.values.routingnumber,
            ifsccode: formik.values.ifsccode,
            attachments: uploadedFilesBank,
          },
          accountingcontactinfo: {
            contactname: formik.values.contactname,
            contactemail: formik.values.contactemail,
            contacttitle: formik.values.contacttitle,
          },

          legalcontactinfo: {
            contactname: formik.values.legalcontactname,
            contactemail: formik.values.legalcontactemail,
            contacttitle: formik.values.legalcontacttitle,
          },
        },
      },
    });
  };

  //update legal entity

  const [
    editLegalEntity,
    {
      loading: updatemutationLoading,
      error: updatemutationError,
      data: update,
    },
  ] = useMutation(updateEditLegalEntity, {
    onCompleted: (e) => {
      refetch();
      onClose();
    },
  });

  const updateLegalEntity = (e) => {
    editLegalEntity({
      variables: {
        input: {
          timestamp: createdon,
          legalentity: {
            legalentityname: formik.values.legalentity,
            companyname: formik.values.companyname,
            address: {
              address: formik.values.address,
              optionaladdress: formik.values.addressoptional,
              country: selectedValue,
              state: selectedState,
              city: formik.values.region,
              postalcode: formik.values.postalcode,
            },
            pangst: {
              pannumber: formik.values.pannumber,
              panattachments: uploadedFiles,
              gstnumber: formik.values.gstnumber,
              gstattachments: uploadedFilesGst,
            },
          },

          banking: {
            country: selectedValue,
            currency: formik.values.currency,
            bankname: formik.values.bankname,
            accountnumber: formik.values.accountnumber,
            routingnumber: formik.values.routingnumber,
            ifsccode: formik.values.ifsccode,
            attachments: uploadedFilesBank,
          },
          accountingcontactinfo: {
            contactname: formik.values.contactname,
            contactemail: formik.values.contactemail,
            contacttitle: formik.values.contacttitle,
          },

          legalcontactinfo: {
            contactname: formik.values.legalcontactname,
            contactemail: formik.values.legalcontactemail,
            contacttitle: formik.values.legalcontacttitle,
          },
        },
      },
    });
  };

  clearForm = () => {
    formik.resetForm();
    setSelectedState("");
    setSelectedValues("");
    setSelectedCountry("");
  };

  const [signedUrls] = useMutation(signed, {
    onCompleted: (e) => {
      afterGettingSignedUrl(e);
    },
  });

  const afterGettingSignedUrl = (e) => {
    uploadFileToAxious(e.putSignedURL.signedurl, e.putSignedURL.key);
  };

  async function uploadFileToAxious(url, key) {
    let keySplit = key.split("/");

    await axios
      .request({
        method: "put",
        url: url,
        data: document.getElementById(keySplit[1]).files[0],
        onUploadProgress: (p) => {
          const percent = Math.round((p.loaded / p.total) * 100);
          setMessage("Uploading ..." + percent + "%");
        },
      })
      .then(function (response) {
        setStatusMsg(false);

        let newUrl = response.config.url.split("?")[0];
        let newFilepath = newUrl.substring(newUrl.lastIndexOf(".com") + 4);
        let filepath = newFilepath.substring(1);

        saveFile(key, keySplit[1]);
      });
  }

  const saveFile = (key, keySplit) => {
    if (keySplit === "pan") {
      let filesArray = uploadedFiles;
      filesArray.push(key);
      setUploadedFiles(filesArray);
    } else if (keySplit === "gst") {
      let filesArray = uploadedFilesGst;
      filesArray.push(key);
      setUploadedFilesGst(filesArray);
    } else if (keySplit === "banking") {
      let filesArray = uploadedFilesBank;
      filesArray.push(key);
      setUploadedFilesBank(filesArray);
    }

    if (pageType === "edit") {
      updateLegalEntity();
    } else {
      addLegalEntity();
    }
  };

  // console.log("fileField", fileField);

  // let allFiles, storedFile, uploadDoc, setUpload;

  // if (fileField === "pan") {
  //   allFiles = setStoreFiles;
  //   storedFile = storeFile;
  //   uploadDoc = uploadedFiles;
  //   setUpload = setUploadedFiles;
  // } else if (fileField === "gst") {
  //   allFiles = setStoreFilesGst;
  //   storedFile = storeFileGst;
  //   uploadDoc = uploadedFilesGst;
  //   setUpload = setUploadedFilesGst;
  // } else if (fileField === "bank") {
  //   allFiles = setStoreFilesBank;
  //   storedFile = storeFileBank;
  //   uploadDoc = uploadedFilesBank;
  //   setUpload = setUploadedFilesBank;
  // }

  const handleFiles = (values, filetype) => {
    setStoreFiles((prevList) => [...prevList, values]);
  };

  const handleFilesGst = (values, filetype) => {
    setStoreFilesGst((prevList) => [...prevList, values]);
  };

  const handleFilesBank = (values, filetype) => {
    setStoreFilesBank((prevList) => [...prevList, values]);
  };

  const deleteAttachments = (index) => {
    let prevFiles = storeFile;
    setStoreFiles([
      ...prevFiles.slice(0, index),
      ...prevFiles.slice(index + 1),
    ]);
  };

  const deleteAttachmentsGst = (index) => {
    let prevFiles = storeFileGst;

    setStoreFilesGst([
      ...prevFiles.slice(0, index),
      ...prevFiles.slice(index + 1),
    ]);
  };

  const deleteAttachmentsBank = (index) => {
    let prevFiles = storeFileBank;

    setStoreFilesBank([
      ...prevFiles.slice(0, index),
      ...prevFiles.slice(index + 1),
    ]);
  };

  // let allFiles;

  // if (fileField === "pan") {
  //   allFiles = storeFile;
  // } else if (fileField === "gst") {
  //   allFiles = storeFileGst;
  // } else if (fileField === "bank") {
  //   allFiles = storeFileBank;
  // }

  const uploadFile = () => {
    storeFile?.forEach((file) => {
      let fileName = file.split("\\").pop();
      putSignedUrl(fileName, "pan");
    });

    storeFileGst?.forEach((file) => {
      let fileNameGst = file.split("\\").pop();
      putSignedUrl(fileNameGst, "gst");
    });

    storeFileBank?.forEach((file) => {
      let fileNameBank = file.split("\\").pop();
      putSignedUrl(fileNameBank, "banking");
    });
  };

  const putSignedUrl = (fileName, filetype) => {
    signedUrls({
      variables: {
        input: {
          method: "put",
          filetype: filetype,
          filename: fileName,
          vbeid: userInfo?.companyvbeid,
        },
      },
    });
  };

  // console.log("pan", uploadedFiles);
  // console.log("gst", uploadedFilesGst);
  // console.log("bank", uploadedFilesBank);

  return (
    <>
      {mutationLoading || updatemutationLoading ? (
        <SimpleSnackbar type="loading" />
      ) : null}

      <Grid container md={12} className={classes.addlegalroot}>
        <Grid container md={12}>
          <Grid item md={11}>
            <p className={classes.addheader}>{"   "}</p>
          </Grid>

          <Grid container md={6} className={classes.field}>
            <Grid item md={12}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputField
                  label="Legal Entity Name *"
                  value={formik.values.legalentity}
                  onChange={formik.handleChange("legalentity")}
                  onBlur={formik.handleBlur("legalentity")}
                  multiple={true}
                />
              </FormControl>
              <Grid item md={12} className={classes.errorfields}>
                {formik.touched.legalentity && formik.errors.legalentity ? (
                  <div className="error-message">
                    {formik.errors.legalentity}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid container md={5} className={classes.field}>
            <Grid item md={12}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputField
                  label="Company Name *"
                  value={formik.values.companyname}
                  onChange={formik.handleChange("companyname")}
                  onBlur={formik.handleBlur("companyname")}
                  multiple={true}
                />
              </FormControl>
              <Grid item md={12} className={classes.errorfields}>
                {formik.touched.companyname && formik.errors.companyname ? (
                  <div className="error-message">
                    {formik.errors.companyname}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid container md={6} className={classes.addressfield}>
            <Grid item md={12}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputField
                  label="Address *"
                  value={formik.values.address}
                  onChange={formik.handleChange("address")}
                  onBlur={formik.handleBlur("address")}
                  multiple={true}
                />
              </FormControl>
              <Grid item md={12} className={classes.errorfields}>
                {formik.touched.address && formik.errors.address ? (
                  <div className="error-message">{formik.errors.address}</div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid container md={5} className={classes.addressfield}>
            <Grid item md={12}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputField
                  label="Address (optional)"
                  value={formik.values.addressoptional}
                  onChange={formik.handleChange("addressoptional")}
                  onBlur={formik.handleBlur("addressoptional")}
                  multiple={true}
                />
              </FormControl>
              <Grid item md={12} className={classes.errorfields}>
                {formik.touched.addressoptional &&
                formik.errors.addressoptional ? (
                  <div className="error-message">
                    {formik.errors.addressoptional}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid container md={6} className={classes.field}>
            <Grid container md={6}>
              <Grid item md={11}>
                <FormControl
                  variant="outlined"
                  fullWidth={true}
                  className={classes.countrystate}
                >
                  <AutoCompleteField
                    label="Country *"
                    options={optCountries}
                    setValues={onCountrySelect}
                    multiple={false}
                    defaultvalue={selectedValue}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container md={6}>
              <Grid item md={12}>
                <FormControl
                  variant="outlined"
                  fullWidth={true}
                  className={classes.countrystate}
                >
                  <AutoCompleteField
                    label="State/Province*"
                    options={optStates}
                    setValues={onStateSelect}
                    defaultvalue={selectedState}
                    multiple={false}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid container md={5} className={classes.cityfield}>
            <Grid container md={7}>
              <Grid item md={11}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputField
                    label="City/Region*"
                    value={formik.values.region}
                    onChange={formik.handleChange("region")}
                    onBlur={formik.handleBlur("region")}
                    multiple={true}
                  />
                </FormControl>
                <Grid item md={12} className={classes.errorfields}>
                  {formik.touched.region && formik.errors.region ? (
                    <div className="error-message">{formik.errors.region}</div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid container md={5}>
              <Grid item md={12}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputField
                    label="Postal Code *"
                    value={formik.values.postalcode}
                    onChange={formik.handleChange("postalcode")}
                    onBlur={formik.handleBlur("postalcode")}
                    multiple={true}
                    type="number"
                  />
                </FormControl>
                <Grid item md={12} className={classes.errorfields}>
                  {formik.touched.postalcode && formik.errors.postalcode ? (
                    <div className="error-message">
                      {formik.errors.postalcode}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container md={6} className={classes.field}>
            <Grid container md={6}>
              <Grid item md={11}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputField
                    label="PAN Number *"
                    value={formik.values.pannumber}
                    onChange={formik.handleChange("pannumber")}
                    onBlur={formik.handleBlur("pannumber")}
                    multiple={true}
                  />
                </FormControl>
                <Grid item md={6} className={classes.errorfields}>
                  {formik.touched.pannumber && formik.errors.pannumber ? (
                    <div className="error-message">
                      {formik.errors.pannumber}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid container md={5}>
              <Grid item md={11.5}>
                <Grid container className={classes.uploadDoc}>
                  <Grid item>
                    <Typography variant="subtitle2" color="primary">
                      Upload doc
                    </Typography>
                  </Grid>

                  <Grid item>
                    <UploadFile
                      vbeid={userInfo?.companyvbeid}
                      filetype="pan"
                      fext=""
                      inputid="pan"
                      buttonmessage="icon"
                      type="icon"
                      handleFiles={handleFiles}
                      callAPi={callAPi}
                      icon={
                        <CloudUploadIcon
                          fontSize="small"
                          className={classes.uploadIcon}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {storeFile?.length !== 0
              ? storeFile?.map((value, index) => {
                  return (
                    <Grid
                      container
                      md={8}
                      component="Paper"
                      className={classes.docs}
                    >
                      <Grid item md={12}>
                        <List>
                          <ListItem key={value}>
                            <Grid item md={2} className={classes.pdf}>
                              <PictureAsPdfIcon />
                            </Grid>
                            <Grid item md={8}>
                              <ListItemText
                                pathname={value}
                                onClick={() => {
                                  CreateUrl(
                                    value,
                                    value?.split(".").pop().split(/\#|\?/)[0]
                                  );
                                }}
                                className={classes.fileName}
                              >
                                {pageType === "edit"
                                  ? value?.split("/").pop()
                                  : value?.split("\\").pop()}
                                {/* {value} */}
                              </ListItemText>
                            </Grid>
                            <Grid item md={2}>
                              <DeleteOutlineIcon
                                fontSize="small"
                                onClick={() => deleteAttachments(index)}
                                style={{
                                  color: "primary",
                                  cursor: "pointer",
                                  marginRight: "20%",
                                }}
                              />
                            </Grid>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  );
                })
              : null}
          </Grid>

          <Grid container md={5} className={classes.field}>
            <Grid container md={7}>
              <Grid item md={11}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputField
                    label="GST Number*"
                    value={formik.values.gstnumber}
                    onChange={formik.handleChange("gstnumber")}
                    onBlur={formik.handleBlur("gstnumber")}
                    multiple={true}
                  />
                </FormControl>
                <Grid item md={10} className={classes.errorfields}>
                  {formik.touched.gstnumber && formik.errors.gstnumber ? (
                    <div className="error-message">
                      {formik.errors.gstnumber}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid container md={5}>
              <Grid item md={11.5}>
                <Grid container className={classes.uploadDoc}>
                  <Grid item>
                    <Typography variant="subtitle2" color="primary">
                      Upload doc
                    </Typography>
                  </Grid>

                  <Grid item>
                    <UploadFile
                      vbeid={userInfo?.companyvbeid}
                      filetype="gst"
                      fext=""
                      // inputid={"complianceImage" + selectedVbeid}
                      inputid="gst"
                      buttonmessage="icon"
                      type="icon"
                      handleFiles={handleFilesGst}
                      callAPi={callAPi}
                      icon={
                        <CloudUploadIcon
                          fontSize="small"
                          className={classes.uploadIcon}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {storeFileGst?.length !== 0
              ? storeFileGst?.map((value, index) => {
                  return (
                    <Grid
                      container
                      md={10}
                      component="Paper"
                      className={classes.docs}
                    >
                      <Grid item md={12}>
                        <List>
                          <ListItem key={value}>
                            <Grid item md={2} className={classes.pdf}>
                              <PictureAsPdfIcon />
                            </Grid>
                            <Grid item md={8}>
                              <ListItemText
                                pathname={value}
                                onClick={() => {
                                  CreateUrl(
                                    value,
                                    value?.split(".").pop().split(/\#|\?/)[0]
                                  );
                                }}
                                className={classes.fileName}
                              >
                                {pageType === "edit"
                                  ? value?.split("/").pop()
                                  : value?.split("\\").pop()}{" "}
                              </ListItemText>
                            </Grid>
                            <Grid item md={2}>
                              <DeleteOutlineIcon
                                fontSize="small"
                                onClick={() => deleteAttachmentsGst(index)}
                                style={{
                                  color: "primary",
                                  cursor: "pointer",
                                  marginRight: "20%",
                                }}
                              />
                            </Grid>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Divider className={classes.divider} />
        </Grid>

        {/* Banking Details */}

        <Grid container md={12}>
          <Grid item md={11}>
            <p className={classes.addheader}>Banking Details </p>
          </Grid>
          <Grid container md={6} className={classes.field}>
            <Grid container md={6}>
              <Grid item md={11}>
                <FormControl
                  variant="outlined"
                  fullWidth={true}
                  className={classes.bankCountry}
                >
                  <AutoCompleteField
                    label="Country *"
                    options={optCountries}
                    setValues={onCountryBankSelect}
                    multiple={false}
                    defaultvalue={selectedBankCountry}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container md={6} className={classes.currencyfield}>
              <Grid item md={12}>
                <FormControl
                  variant="outlined"
                  fullWidth={true}
                  // className={classes.countrystate}
                >
                  <SelectField
                    label="Currency*"
                    options={currecyType}
                    value={formik.values.currency}
                    onChange={formik.handleChange("currency")}
                    // onBlur={formik.handleBlur("currency")}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container md={5} className={classes.field}>
            <Grid container md={7}>
              <Grid item md={11}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputField
                    label="Bank Name"
                    value={formik.values.bankname}
                    onChange={formik.handleChange("bankname")}
                    onBlur={formik.handleBlur("bankname")}
                    multiple={true}
                  />
                </FormControl>
                <Grid item md={12} className={classes.errorfields}>
                  {formik.touched.bankname && formik.errors.bankname ? (
                    <div className="error-message">
                      {formik.errors.bankname}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid container md={5}>
              <Grid item md={12}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputField
                    label="Account Number *"
                    value={formik.values.accountnumber}
                    onChange={formik.handleChange("accountnumber")}
                    onBlur={formik.handleBlur("accountnumber")}
                    multiple={true}
                  />
                </FormControl>
                <Grid item md={12} className={classes.errorfields}>
                  {formik.touched.accountnumber &&
                  formik.errors.accountnumber ? (
                    <div className="error-message">
                      {formik.errors.accountnumber}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container md={6} className={classes.field}>
            <Grid container md={6}>
              <Grid item md={11}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputField
                    label="Routing Number "
                    value={formik.values.routingnumber}
                    onChange={formik.handleChange("routingnumber")}
                    onBlur={formik.handleBlur("routingnumber")}
                    multiple={true}
                  />
                </FormControl>
                <Grid item md={12} className={classes.errorfields}>
                  {formik.touched.routingnumber &&
                  formik.errors.routingnumber ? (
                    <div className="error-message">
                      {formik.errors.routingnumber}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid container md={6}>
              <Grid item md={12}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputField
                    label="IFSC Code"
                    value={formik.values.ifsccode}
                    onChange={formik.handleChange("ifsccode")}
                    onBlur={formik.handleBlur("ifsccode")}
                    multiple={true}
                  />
                </FormControl>
                <Grid item md={12} className={classes.errorfields}>
                  {formik.touched.ifsccode && formik.errors.ifsccode ? (
                    <div className="error-message">
                      {formik.errors.ifsccode}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container md={5} className={classes.field}>
            <>
              <Grid container md={5}>
                <Grid item md={11.5}>
                  <Grid container className={classes.uploadDoc}>
                    <Grid item>
                      <Typography variant="subtitle2" color="primary">
                        Upload doc
                      </Typography>
                    </Grid>

                    <Grid item>
                      <UploadFile
                        vbeid={userInfo?.companyvbeid}
                        filetype="banking"
                        fext=""
                        // inputid={"complianceImage" + selectedVbeid}
                        inputid="banking"
                        buttonmessage="icon"
                        type="icon"
                        handleFiles={handleFilesBank}
                        callAPi={callAPi}
                        icon={
                          <CloudUploadIcon
                            fontSize="small"
                            className={classes.uploadIcon}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {storeFileBank?.length !== 0
                ? storeFileBank?.map((value, index) => {
                    return (
                      <Grid
                        container
                        md={10}
                        component="Paper"
                        className={classes.docs}
                      >
                        <Grid item md={12}>
                          <List>
                            <ListItem key={value}>
                              <Grid item md={2} className={classes.pdf}>
                                <PictureAsPdfIcon />
                              </Grid>
                              <Grid item md={8}>
                                <ListItemText
                                  pathname={value}
                                  onClick={() => {
                                    CreateUrl(
                                      value,
                                      value?.split(".").pop().split(/\#|\?/)[0]
                                    );
                                  }}
                                  className={classes.fileName}
                                >
                                  {pageType === "edit"
                                    ? value?.split("/").pop()
                                    : value?.split("\\").pop()}{" "}
                                </ListItemText>
                              </Grid>
                              <Grid item md={2}>
                                <DeleteOutlineIcon
                                  fontSize="small"
                                  onClick={() => deleteAttachmentsBank(index)}
                                  style={{
                                    color: "primary",
                                    cursor: "pointer",
                                    marginRight: "20%",
                                  }}
                                />
                              </Grid>
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    );
                  })
                : null}
            </>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Divider className={classes.divider} />
        </Grid>

        {/* Accounting Contact Information*/}

        <Grid container md={12} className={classes.accounting}>
          <Grid item md={11}>
            <p className={classes.addheader}>Accounting Contact Information </p>
          </Grid>

          <Grid container md={6} className={classes.field}>
            <Grid container md={6}>
              <Grid item md={11}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputField
                    label="Contact Name*"
                    value={formik.values.contactname}
                    onChange={formik.handleChange("contactname")}
                    onBlur={formik.handleBlur("contactname")}
                    multiple={true}
                  />
                </FormControl>
                <Grid item md={12} className={classes.errorfields}>
                  {formik.touched.contactname && formik.errors.contactname ? (
                    <div className="error-message">
                      {formik.errors.contactname}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid container md={6}>
              <Grid item md={12}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputField
                    label="Contact Email *"
                    value={formik.values.contactemail}
                    onChange={formik.handleChange("contactemail")}
                    onBlur={formik.handleBlur("contactemail")}
                    multiple={true}
                  />
                </FormControl>
                <Grid item md={12} className={classes.errorfields}>
                  {formik.touched.contactemail && formik.errors.contactemail ? (
                    <div className="error-message">
                      {formik.errors.contactemail}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container md={5} className={classes.field}>
            <Grid item md={12}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputField
                  label="Contact Title*"
                  value={formik.values.contacttitle}
                  onChange={formik.handleChange("contacttitle")}
                  onBlur={formik.handleBlur("contacttitle")}
                  multiple={true}
                />
              </FormControl>
              <Grid item md={12} className={classes.errorfields}>
                {formik.touched.contacttitle && formik.errors.contacttitle ? (
                  <div className="error-message">
                    {formik.errors.contacttitle}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Divider className={classes.divider} />
        </Grid>

        {/* Legal Contact Information*/}

        <Grid container md={12}>
          <Grid item md={11}>
            <p className={classes.addheader}>Legal Contact Information </p>
          </Grid>

          <Grid container md={6} className={classes.field}>
            <Grid container md={6}>
              <Grid item md={11}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputField
                    label="Contact Name*"
                    value={formik.values.legalcontactname}
                    onChange={formik.handleChange("legalcontactname")}
                    onBlur={formik.handleBlur("legalcontactname")}
                    multiple={true}
                  />
                </FormControl>
                <Grid item md={12} className={classes.errorfields}>
                  {formik.touched.legalcontactname &&
                  formik.errors.legalcontactname ? (
                    <div className="error-message">
                      {formik.errors.legalcontactname}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid container md={6}>
              <Grid item md={12}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputField
                    label="Contact Email *"
                    value={formik.values.legalcontactemail}
                    onChange={formik.handleChange("legalcontactemail")}
                    onBlur={formik.handleBlur("legalcontactemail")}
                    multiple={true}
                  />
                </FormControl>
                <Grid item md={12} className={classes.errorfields}>
                  {formik.touched.legalcontactemail &&
                  formik.errors.legalcontactemail ? (
                    <div className="error-message">
                      {formik.errors.legalcontactemail}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container md={5} className={classes.field}>
            <Grid item md={12}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputField
                  label="Contact Title*"
                  value={formik.values.legalcontacttitle}
                  onChange={formik.handleChange("legalcontacttitle")}
                  onBlur={formik.handleBlur("legalcontacttitle")}
                  multiple={true}
                />
              </FormControl>
              <Grid item md={12} className={classes.errorfields}>
                {formik.touched.legalcontacttitle &&
                formik.errors.legalcontacttitle ? (
                  <div className="error-message">
                    {formik.errors.legalcontacttitle}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.btn}>
          <Grid item md={1} className={classes.cancelbtn}>
            <OutlinedButton label="Cancel" onClick={onClose} />
          </Grid>

          {pageType === "edit" ? (
            <Grid item md={1}>
              <OutlinedButton
                label="Update"
                type="contained"
                disabled={
                  storeFile?.length !==
                    legalentity?.legalentity?.pangst?.panattachments?.length ||
                  storeFileGst?.length !==
                    legalentity?.legalentity?.pangst?.gstattachments?.length ||
                  storeFileBank?.length !==
                    legalentity?.banking?.attachments.length
                    ? false
                    : !(formik.dirty && formik.isValid)
                }
                onClick={
                  storeFile?.length !==
                    legalentity?.legalentity?.pangst?.panattachments?.length ||
                  storeFileGst?.length !==
                    legalentity?.legalentity?.pangst?.gstattachments?.length ||
                  storeFileBank?.length !==
                    legalentity?.banking?.attachments.length
                    ? uploadFile
                    : updateLegalEntity
                }
                // onClick={addEmployeeAccess}
              />
            </Grid>
          ) : (
            <Grid item md={1}>
              <OutlinedButton
                label="Submit"
                type="contained"
                disabled={!(formik.dirty && formik.isValid)}
                onClick={
                  storeFile?.length > 0 ||
                  storeFileGst?.length > 0 ||
                  storeFileBank?.length > 0
                    ? uploadFile
                    : addLegalEntity
                }
                // onClick={addEmployeeAccess}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
