import * as React from "react";
//import { Link } from "react-router-dom";

import { LightTooltip, EllipsisText } from "../DataValidation/styles";

import {
  Grid,
  Avatar,
  Paper,
  Chip,
  Typography,
  Drawer,
  Tooltip,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { VBRIDGEHUB_MEDIAURL } from "../../../constants";
import SimilarProdDataGrid from "./SimilarProdDataGrid";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  ReclistSimilarProductsByProduct,
  RecMapProductsToCategory,
  RecRemoveMapProductsToCategory,
} from "./query";
import ProdDataGrid from "./ProdDataGrid";
import { useStyles } from "./styles";
import TopicMapping from "./TopicMapping";
import ListProd from "./ListProd";

// const drawerWidth = 800;

// const useStyles = makeStyles((theme) => ({
// root: {
//   float: "left",
// },
// title: {
//   flexGrow: 1,
// },
// textfield: {
//   "& svg": {
//     fontSize: "0.9rem",
//     cursor: "pointer",
//   },
// },
// textfield1: {
//   fontSize: "0.85rem",
//   fontWeight: 200,
//   color: "blue",
//   cursor: "pointer",
//   "& textarea": {
//     fontSize: "0.85rem",
//     fontWeight: 200,
//   },
// },
// similarcount: {
//   fontSize: "0.85rem",
//   fontWeight: 600,
//   color: "#f50057",
//   cursor: "pointer",
//   "& textarea": {
//     fontSize: "0.85rem",
//     fontWeight: 200,
//   },
// },
// drawer: {
//   width: drawerWidth,
//   flexShrink: 0,
// },
// drawerPaper: {
//   width: drawerWidth,
//   backgroundColor: "#eee",
// },
// drawerHeader: {
//   display: "flex",
//   padding: theme.spacing(4, 2),
//   ...theme.mixins.toolbar,
//   justifyContent: "flex-start",
// },
// content: {
//   flexGrow: 1,
//   padding: theme.spacing(3),
//   transition: theme.transitions.create("margin", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   marginRight: -drawerWidth,
// },
// contentShift: {
//   transition: theme.transitions.create("margin", {
//     easing: theme.transitions.easing.easeOut,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   marginRight: 0,
// },
// doubleArrowIcon: {
//   height: 25,
//   right: theme.spacing(0.5),
//   top: theme.spacing(28),
// },
// }));

export default function ProductPages({
  data,
  checked,
  fetch,
  handleToggle,
  loading,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = React.useState("");
  const [postId, setPostId] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [id, setID] = React.useState([checked]);
  const [getId, setGetId] = React.useState("");
  const [valuesId, setValuesId] = React.useState([]);

  const [prodId, setProdId] = React.useState([]);

  console.log("prodId", prodId);

  const client = useApolloClient();

  console.log("getId", getId);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function toggle() {
    setOpen((wasOpened) => !wasOpened);
  }

  const {
    data: similarProduct,
    loading: similarProductLoading,
    refetch: fetching,
  } = useQuery(ReclistSimilarProductsByProduct, {
    variables: {
      input: {
        vbeid: getId,
      },
    },
  });

  if (similarProduct) {
    console.log("dataview", similarProduct?.ReclistSimilarProductsByProduct);
  }

  function handleDelete(topicvbeid, productvbeid) {
    UnmappedProductsToTopics(topicvbeid, productvbeid);
  }

  function handleDeleteSimilar(topicvbeid, productvbeid) {
    UnmappedSimilarsProductsToTopic(topicvbeid, productvbeid);
  }

  //deleting mapped products

  const [
    deleteProductsToTopics,
    {
      data: removedData,
      loading: mutationRemoveLoading,
      error: mutationRemoveError,
    },
  ] = useMutation(RecRemoveMapProductsToCategory, {
    onCompleted: (e) => {
      fetch();
      CacheForDeletingProducts(e.RecRemoveMapProductsToCategory);
      // AfterMerge(e);
    },
  });

  //function to delete topics
  function UnmappedProductsToTopics(tvbeid, pvbeid) {
    deleteProductsToTopics({
      variables: {
        input: {
          vbeid: tvbeid,
          listvbeid: [pvbeid],
        },
      },
    });
  }

  //deleting similar mapped products

  const [
    deleteSimilarProductsToTopics,
    {
      data: removedSimilarData,
      loading: mutationRemoveSimilarLoading,
      error: mutationRemoveSimilarError,
    },
  ] = useMutation(RecRemoveMapProductsToCategory, {
    onCompleted: (e) => {
      fetching();
      CacheForDeletingProducts(e.RecRemoveMapProductsToCategory);
      // AfterMerge(e);
    },
  });

  //function to delete topics
  function UnmappedSimilarsProductsToTopic(tvbeid, pvbeid) {
    deleteSimilarProductsToTopics({
      variables: {
        input: {
          vbeid: tvbeid,
          listvbeid: [pvbeid],
        },
      },
    });
  }

  function CacheForDeletingProducts(e) {
    console.log("eeeeeeeeeee", e);
  }

  //product columns
  const columns = [
    // {
    //   field: "checkbox",
    //   // headerName: "Product Name",
    //   width: 200,
    //   renderCell: (params) => (
    //     <Grid container md={12} spacing={1}>
    //       <Grid item md={2} className={classes.logoAlign}>
    //         <Checkbox
    //           onClick={() => {
    //             setID(checked);
    //           }}
    //         />

    //         {/* <Avatar
    //           className={classes.logoSize}
    //           variant="square"
    //           src={VBRIDGEHUB_MEDIAURL + "/" + params.value}
    //         >
    //           {":("}
    //         </Avatar> */}
    //         {/* <Typography
    //           onClick={() => {
    //             toggle();
    //             handleToggle(params.row.id);
    //           }}
    //         >
    //           {params.row.name}
    //         </Typography> */}
    //       </Grid>
    //     </Grid>
    //   ),
    // },
    {
      field: "name",
      headerName: "Product Names",
      width: 200,
      renderCell: (params) => (
        <Grid container md={12} spacing={1}>
          <Grid item md={2} className={classes.logoAlign}>
            <div style={{ display: "flex" }}>
              <Avatar
                className={classes.logoSize}
                variant="square"
                src={VBRIDGEHUB_MEDIAURL + "/" + params.value}
              >
                {":("}
              </Avatar>
              <Typography
                className={classes.name}
                style={{ marginTop: "10px" }}
                onClick={() => {
                  toggle();
                  handleToggle(params.row.id);
                  setGetId(params.row.id);
                }}
              >
                {params.row.name}
              </Typography>
            </div>
          </Grid>
        </Grid>
      ),
    },

    // {
    //   field: "name",
    //   headerName: "Product Name",
    //   width: 200,
    //   renderCell: (params) => (
    //     console.log("paramsss", params),
    //     (
    //       <Grid container md={12} spacing={1}>
    //         <Grid item md={10}>
    //         <Typography
    //             onClick={() => {
    //               toggle();
    //               handleToggle(params.row.id);
    //               setGetId(params.row.id);
    //             }}
    //           >
    //             {params.row.name}
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //     )
    //   ),
    // },

    {
      field: "shortdescription",
      headerName: " Short Description",
      width: 450,
      whiteSpace: "initial",
      // editable: true,
      renderCell: (params) => (
        <div>
          {/* <LightTooltip title={params.value} placement="bottom-start">
              <Typography variant="caption">{params.value}</Typography>
            </LightTooltip> */}
          <LightTooltip title={params.value} placement="bottom-start">
            <Typography className={classes.shortdescription}>
              {params.row.shortdescription}
            </Typography>
          </LightTooltip>
        </div>
      ),
    },

    {
      field: "topiclists",
      headerName: "Topics List",
      width: 280,
      renderCell: (params) => (
        <div className={classes.categoryTab}>
          <TopicMapping
            classname="categoryTab"
            productIds={valuesId}
            fetch={fetch}
            data={data}
            fetching={fetching}
          />
          {/* <LightTooltip title={params.value} placement="bottom">
            <Chip
              label={<EllipsisText>{params.value}</EllipsisText>}
              size="small"
            />
          </LightTooltip> */}
          {/* {params.row.topiclist.length} */}
        </div>
      ),
    },

    {
      field: "list",
      headerName: "Mapped Topic Names",
      width: 450,

      renderCell: (params) => (
        // setProdId(params.row.id),

        <div className={classes.categoryTab}>
          {params.row.topiclist?.map((val) => (
            <LightTooltip title={val.name} placement="bottom">
              <Chip
                label={<EllipsisText>{val.name}</EllipsisText>}
                className={classes.chipDesign}
                size="small"
                onDelete={() => handleDelete(val.vbeid, params.row.id)}
              />
            </LightTooltip>
          ))}
        </div>
      ),
    },
  ];

  const similarColumns = [
    {
      field: "image",
      headerName: "Product Name",
      width: 150,
      renderCell: (params) => (
        <Grid container md={12} spacing={1}>
          <Grid item md={2} className={classes.logoAlign}>
            <div style={{ display: "flex" }}>
              <Avatar
                className={classes.logoSize}
                variant="square"
                src={VBRIDGEHUB_MEDIAURL + "/" + params.value}
              >
                {":("}
              </Avatar>
              <LightTooltip title={params.row.name} placement="bottom-start">
                <Typography
                  style={{ marginTop: "10px" }}
                  // onClick={() => {
                  //   toggle();
                  //   handleToggle(params.row.id);
                  //   setGetId(params.row.id);
                  // }}
                  className={classes.name}
                >
                  {params.row.name}
                </Typography>
              </LightTooltip>
            </div>
          </Grid>
        </Grid>
      ),
    },

    {
      field: "shortdescription",
      headerName: "Short Description",
      minWidth: 300,
      // editable: true,
      renderCell: (params) => (
        <div>
          <LightTooltip title={params.value} placement="bottom-start">
            <Typography className={classes.shortdescription}>
              {params.row.shortdescription}
            </Typography>
          </LightTooltip>
        </div>
      ),
    },
    {
      field: "topiclist",
      headerName: "Mapped Topic Names",
      width: 280,
      renderCell: (params) => (
        // setProdId(params.row.id),
        <div className={classes.categoryTab}>
          {params.row.topiclist?.map((val) => (
            <LightTooltip title={val.name} placement="bottom">
              <Chip
                label={<EllipsisText>{val.name}</EllipsisText>}
                className={classes.chipDesign}
                size="small"
                onDelete={() => handleDeleteSimilar(val.vbeid, params.row.id)}
              />
            </LightTooltip>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div>
      <ProdDataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        loading={loading}
        setValuesId={setValuesId}
      />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* <div className={classes.drawerHeader}> */}
        <SimilarProdDataGrid
          rows={similarProduct?.ReclistSimilarProductsByProduct}
          columns={similarColumns}
          loading={similarProductLoading}
          pageSize={5}
          checkboxSelection
          disableSelectionOnClick
        />
        {/* </div> */}
      </Drawer>
    </div>
  );
}
