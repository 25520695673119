import { useHistory } from "react-router";

import { Grid, IconButton, TextField, Typography } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SaveIcon from "@material-ui/icons/Save";

import { VBRIDGEHUB_MEDIAURL } from "../../../constants";

import TypeBadge from "../../shared/TypeBadge";
import ImageLink from "../companies/Company2.0/tabs/companyInfo/ImageLink";

import GroupLogo from "../groups2.0/GroupLogo";
import ReadMore from "../groups2.0/ReadMore";
import { useStyles } from "../groups2.0/styles";
import PercentIcon from "../groups2.0/PercentIcon";

import AddToProject from "../project/addproducttoproject";
import UpdateCompanyInformation from "../companies/Company2.0/tabs/updatecompanyinformation";
import CompanyInformation from "../companies/Company2.0/CompanyInformation";

import { getOrgType } from "../../../functions/helper";
import HelpRequest from "../Help/HelpRequest";
import ViewMoreButton from "./ViewMoreButton";
import ListMultiCategory from "./ListMultiCategory";

export default function DataPageHeader({
  data,
  isReadOnly,
  vbeid,
  updateCache,
  editName,
  editDescription,
  editNameClick,
  handleNameChange,
  saveNameClick,
  editDescriptionClick,
  handleDescriptionChange,
  saveDescriptionClick,
  type,
  isAdmin,
  userPermission,
  userInfo,
  isBuyer,
  logo,
  mainDrawerClick,
}) {
  const classes = useStyles();
  const history = useHistory();

  const {
    image,
    name,
    topiclist,
    companyurl,
    facebookurl,
    twitterurl,
    linkedinurl,
    companyvbeid,
    companyname,
    description,
    shortdescription,
    topicvbeid,
    orgtype,
    productid,
    productname,
    securityscorecard_grade_url,
    knpashot_score,
    type: productType,
  } = data;

  return (
    <>
      <div className={classes.help}>
        {type === "group" || type === "topic" ? (
          <HelpRequest refer="coveragecategory" />
        ) : null}
      </div>

      <Grid
        container
        spacing={1}
        className={classes.ngrid}
        justify="flex-start"
      >
        <Grid item md={2}>
          {data ? (
            <GroupLogo
              image={
                type === "services" || type === "service"
                  ? logo
                  : VBRIDGEHUB_MEDIAURL + "/" + image
              }
              name={name ? name : null}
              isReadOnly={isReadOnly}
              vbeid={vbeid}
              updateCache={updateCache}
            />
          ) : null}
        </Grid>

        <Grid item md={10}>
          <Grid container md={12}>
            <Grid item md={type === "company" ? 8 : 12}>
              {!editName ? (
                <Typography
                  variant="BUTTON TEXT"
                  component="h2"
                  className={
                    type === "product" ||
                    type === "products" ||
                    type === "services" ||
                    type === "service"
                      ? classes.productName
                      : classes.groupName
                  }
                >
                  {name ? name : null}
                  {(type === "product" ||
                    type === "products" ||
                    type === "services" ||
                    type === "service") &&
                  topiclist?.length !== 0 ? (
                    <>
                      <span className={classes.subText}>in</span>

                      <ListMultiCategory
                        title="Visit Category Page"
                        data={topiclist}
                        length={1}
                        type="group"
                        typeValue={type === "product" ? "products" : "services"}
                      />

                      {topiclist.length > 1 ? (
                        <ViewMoreButton
                          data={topiclist}
                          categoriesCount={topiclist.length - 1}
                          length={1}
                          topicvbeid={topicvbeid ? topicvbeid : null}
                          type="group"
                          typeValue={
                            type === "product" ? "products" : "services"
                          }
                        />
                      ) : null}

                      {!isReadOnly && (
                        <span>
                          <IconButton>
                            <EditOutlinedIcon
                              fontSize="small"
                              onClick={editNameClick}
                            />
                          </IconButton>
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      <TypeBadge name={type.toUpperCase()} type={type} />

                      {!isReadOnly && (
                        <span>
                          <IconButton>
                            <EditOutlinedIcon
                              fontSize="small"
                              onClick={editNameClick}
                            />
                          </IconButton>
                        </span>
                      )}

                      {type === "company" && (
                        <div className={classes.iconDiv}>
                          {securityscorecard_grade_url && (
                            <img
                              className={classes.icon}
                              src={securityscorecard_grade_url}
                              alt="grade"
                            />
                          )}
                          {knpashot_score && (
                            <PercentIcon percent={knpashot_score} />
                          )}
                        </div>
                      )}
                    </>
                  )}
                </Typography>
              ) : null}

              {editName ? (
                <Grid container spacing={2}>
                  <Grid item>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      defaultValue={name}
                      onChange={handleNameChange}
                    />
                  </Grid>

                  <Grid item>
                    <IconButton onClick={saveNameClick}>
                      <SaveIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>

            {type === "company" && (
              <Grid item md={4} className={classes.companyLinks}>
                {companyurl && <ImageLink url={companyurl} type="website" />}
                {facebookurl && <ImageLink url={facebookurl} type="facebook" />}
                {twitterurl && <ImageLink url={twitterurl} type="twitter" />}
                {linkedinurl && <ImageLink url={linkedinurl} type="linkedin" />}

                {!isReadOnly && (
                  <Typography variant="body2" className={classes.action}>
                    <span>
                      <IconButton>
                        <UpdateCompanyInformation
                          values={data}
                          isReadOnly={isReadOnly}
                        />
                      </IconButton>
                    </span>
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>

          {(type === "product" ||
            type === "products" ||
            type === "services" ||
            type === "service" ||
            type === "resource" ||
            type === "success story") && (
            <Grid item className={classes.companyDetailsDiv}>
              {type === "resource" && productid && (
                <div
                  className={classes.companyName}
                  onClick={() => {
                    history.push("/product/" + productid);
                  }}
                >
                  {productname}
                </div>
              )}

              <div
                className={classes.companyName}
                onClick={(e) => {
                  mainDrawerClick(e, "close");

                  if (userInfo.companyvbeid === companyvbeid) {
                    history.push("/mycompany/" + companyvbeid);
                  } else history.push("/company/" + companyvbeid);
                }}
              >
                <span className={classes.bySubText}> by </span> {companyname}
              </div>
            </Grid>
          )}

          <Grid item style={{ display: "flex" }}>
            {!editDescription ? (
              <Typography
                variant="p"
                component="p"
                className={classes.descriptionText}
              >
                <ReadMore
                  type="main"
                  text={
                    description
                      ? description
                      : null || shortdescription
                      ? shortdescription
                      : null
                  }
                />
              </Typography>
            ) : null}

            {!isReadOnly && !editDescription && (
              <IconButton>
                <EditOutlinedIcon
                  fontSize="small"
                  onClick={editDescriptionClick}
                />
              </IconButton>
            )}

            {editDescription ? (
              <Grid container spacing={2}>
                <Grid item>
                  <TextField
                    label="Enter Description"
                    variant="outlined"
                    className={classes.descriptionfield}
                    defaultValue={shortdescription}
                    multiline
                    rows={4}
                    rowsMax={6}
                    onChange={handleDescriptionChange}
                  />
                </Grid>

                <Grid item>
                  <IconButton onClick={saveDescriptionClick}>
                    <SaveIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          {type === "company" && (
            <Grid item className={classes.companyInfo}>
              <CompanyInformation
                data={data}
                orgType={getOrgType(orgtype)}
                isBuyer={isBuyer}
              />
            </Grid>
          )}
        </Grid>

        <Grid item md={12}>
          <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
            {type === "product" ||
            type === "products" ||
            type === "service" ||
            type === "services" ||
            (type === "company" && orgtype.includes("Service Provider")) ? (
              <AddToProject
                productvbeid={vbeid}
                disable={false}
                searchContext={
                  (type === "product" && productType === "service") ||
                  type === "service"
                    ? "services"
                    : type === "product"
                    ? "products"
                    : type
                }
                checkedObj={[companyvbeid]}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
