import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(3),
  },
  textFieldLabel: {
    fontSize: theme.spacing(1.8),
    marginTop: theme.spacing(2),
  },
  formControlclass: {
    width: "100%",
    textAlign: "left",
    minHeight: theme.spacing(55),
  },
  teamPaper: {
    width: "100%",
    padding: theme.spacing(2),
  },
}));
