import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainroot: {
    width: "100%",
  },

  title: {
    flexGrow: 1,
  },

  batches: {
    width: 150,
    height: "10px !important",
  },

  filterSize: {
    width: 70,
  },

  fromFilter: {
    // width: 200,
  },

  textfield: {
    "& svg": {
      fontSize: "0.9rem",
      cursor: "pointer",
    },
  },
  textfield1: {
    fontSize: "0.85rem",
    fontWeight: 200,
    color: "blue",
    cursor: "pointer",
    "& textarea": {
      fontSize: "0.85rem",
      fontWeight: 200,
    },
  },

  similarcount: {
    fontSize: "0.85rem",
    fontWeight: 600,
    color: "#f50057",
    cursor: "pointer",
    "& textarea": {
      fontSize: "0.85rem",
      fontWeight: 200,
    },
  },

  drawer: {
    width: 800,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 800,
    // backgroundColor: "#eee",
  },
  drawerHeader: {
    display: "flex",

    padding: theme.spacing(4, 2),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -800,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  doubleArrowIcon: {
    height: 25,

    right: theme.spacing(0.5),
    top: theme.spacing(28),
  },
  root: { marginTop: "60px" },

  refreshButton: {
    marginTop: "10px",
  },

  name: {
    fontSize: "0.75rem",
    fontWeight: 400,
    marginLeft: " 10px",
  },
  shortdescription: {
    fontSize: "0.75rem",
    fontWeight: 400,
    marginLeft: " 10px",
    whiteSpace: "initial",
    wordWrap: "break-word",
  },
  topicTab: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(64),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0),
  },
  categoryTab: {
    whiteSpace: "initial",
    lineHeight: "15px !important",
    marginTop: theme.spacing(0),
    padding: theme.spacing(0),
  },
  chipDesign: {
    width: "80px",
    marginBottom: "2px",
  },
  mapButton: {
    margin: "15px 0px 0px 0px !important",
    padding: "0px !important",
    // // textTransform: "none",
    minWidth: "30px !important",
    height: "20px !important",
    "& span": {
      // padding: "0px 2px",
      fontSize: "9px",
      justifyContent: "center !important",
    },
  },
}));
