import React from "react";
import { useParams } from "react-router";

import { useStyles } from "./styles";

import {
  Typography,
  List,
  MenuItem,
  Select,
  ListItem,
  ListItemText,
  InputLabel,
  ListItemSecondaryAction,
} from "@material-ui/core";

import { useMutation } from "@apollo/client";

import { BootstrapInput } from "../../../../theme/theme";

import { postanswer, updateanswer } from "./query";
import VbridgeAlert from "../../../shared/alert";

export default function ReplyToQuestions({
  listQuestions,
  productid,
  refetch,
  review,
  approved,
}) {
  let { id } = useParams();
  const classes = useStyles();

  function QuestionList({ question }) {
    const classes = useStyles();

    return <div className={classes.questionLength}>{question}</div>;
  }

  //answer to a question
  const [postquestionmutation, { error: mutationError, data: postData }] =
    useMutation(postanswer);

  //update answer to a question
  const [updatequestionmutation, { error: updateError, data: updateData }] =
    useMutation(updateanswer);

  function QuestionAddedDate(props) {
    let createdDate = new Date(parseInt(props.createdon * 1000))
      .toDateString("en-US")
      .substring(3);
    let position = 7;
    let newStr = ",";

    return (
      <Typography variant="caption">
        Added on:{" "}
        {[
          createdDate.slice(0, position),
          newStr,
          createdDate.slice(position),
        ].join("")}
      </Typography>
    );
  }

  function AnswerBox({ timestamp, createdon, response }) {
    const [value, setValue] = React.useState(response);

    function Setselectedvalue(e) {
      setValue(e.target.value);
    }

    ///function for posting answer
    function PostDeclaration(e) {
      postquestionmutation({
        variables: {
          input: {
            timestamp: timestamp,
            response: e.target.value,
            productvbeid: productid,
            remarks: "Answers",
            status: "submitted",
            ansstatus: "save",
            vbeid: id,
          },
        },
      });
    }

    ///function for updating answer
    function UpdateDeclaration(e) {
      updatequestionmutation({
        variables: {
          input: {
            id: createdon,
            timestamp: timestamp,
            response: e.target.value,
            productvbeid: productid,
            remarks: "Answers",
            status: "submitted",
            ansstatus: "save",
            vbeid: id,
          },
        },
      });
    }

    return (
      <form>
        <InputLabel className={classes.markResponse}>Mark response</InputLabel>
        <Select
          input={<BootstrapInput />}
          id="demo-simple-select"
          value={value}
          onChange={(value) => {
            Setselectedvalue(value);
            createdon == null
              ? PostDeclaration(value)
              : UpdateDeclaration(value);
            refetch();
          }}
        >
          <MenuItem value={"True"}>Yes</MenuItem>
          <MenuItem value={"False"}>No</MenuItem>
        </Select>
      </form>
    );
  }

  return (
    <>
      {mutationError || updateError ? (
        <VbridgeAlert
          severity="error"
          open={true}
          message="Something went wrong ... "
        />
      ) : null}
      {postData || updateData ? (
        <VbridgeAlert
          severity="success"
          open={true}
          message="Response Updated successfully. "
        />
      ) : null}

      <List
        className={
          review
            ? classes.reviewQuestions
            : listQuestions.length >= 3
            ? classes.listingQuestions
            : classes.listingQuestionslessThanTwo
        }
      >
        {listQuestions.map((value) => {
          return (
            <ListItem>
              <ListItemText
                primary={<QuestionList question={value.question} />}
                secondary={<QuestionAddedDate createdon={value.id} />}
              />

              {review ? (
                <ListItemSecondaryAction>
                  <AnswerBox
                    response={value.response}
                    timestamp={value.id}
                    createdon={value.createdon}
                  />
                </ListItemSecondaryAction>
              ) : approved.length >= 1 ? (
                <ListItemSecondaryAction>
                  <AnswerBox
                    response={value.response}
                    timestamp={value.id}
                    createdon={value.createdon}
                  />
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
