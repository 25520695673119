import * as React from "react";

import { Box, FormControl, Grid } from "@material-ui/core";

import { useQuery } from "@apollo/client";

import { GET_SECTIONS } from "../../queries/query";

import RSection from "./responsesection";

import { useStyles } from "./styles";

export default function ResponseForm({
  projectId,
  productId,
  type,
  templateId,
  readOnly,
}) {
  const classes = useStyles();

  const { data } = useQuery(GET_SECTIONS, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: templateId } },
  });

  return (
    <div className={classes.root}>
      <Grid container className={classes.main}>
        <Grid item xs={12}>
          {data ? (
            <Box style={{ padding: "8px" }}>
              <FormControl
                component="fieldset"
                className={classes.formControlclass}
              >
                {data.listSection.map((section, index) => {
                  var meta = JSON.parse(section.metadata);
                  meta = { ...meta, id: index + 1 };

                  return (
                    <RSection
                      productId={productId}
                      key={section.id}
                      name={section.name}
                      sid={section.id}
                      projectId={projectId}
                      id={index + 1}
                      metadata={meta}
                      preview={true}
                      type={type}
                      readOnly={readOnly}
                    />
                  );
                })}
              </FormControl>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
