import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";

import { LIST_EVALUATION_SECTIONS } from "../../queries/query";
import { useQuery } from "@apollo/client";

import EvaluateSummaryItemValue from "./EvaluateSummaryItemsValue";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
  },
  heading: {
    fontSize: theme.spacing(2),
  },
  properties: {
    width: theme.spacing(50),
  },
  propertiestitle: {
    marginBottom: theme.spacing(2),
  },
  propsbuttongroups: {
    marginTop: theme.spacing(2),
  },
  propsbutton: {
    margin: theme.spacing(1),
  },
  helpicon: {
    fontSize: theme.spacing(1.5),
    cursor: "pointer",
  },
  weight: {
    fontSize: theme.spacing(1.5),
  },
  caption: {
    color: "red",
  },
  tableIndexCell: {
    width: theme.spacing(25),
    fontWeight: "bold",
  },
  hidecell: {
    display: "none",
  },
}));

export default function RSection(props) {
  const classes = useStyles();

  const { loading, error, data, refetch } = useQuery(LIST_EVALUATION_SECTIONS, {
    variables: {
      tinput: { sectionid: props.sid },
      pinput: {
        sectionid: props.sid,
        projectid: props.projectId,
        productvbeid: props.product.productvbeid,
      },
    },
  });

  if (data) {
    let totalValue = 0;

    data.getEvaluationTempItemvalue.forEach((item) => {
      totalValue = totalValue + parseInt(item.value);
    });

    let sectionAverage = totalValue / data.getEvaluationTempItemvalue.length;

    let sectionTotalWeight = (
      (sectionAverage * props.sectionMeta.weight) /
      100
    ).toFixed(2);

    props.getSectionTotal(
      parseFloat(sectionTotalWeight),
      props.sid,
      props.product.productvbeid
    );
  }

  return (
    <>
      {data
        ? data.listItem.map((item) => {
            var meta = JSON.parse(item.metadata);

            return (
              <TableRow>
                <TableCell className={classes.tableIndexCell}>
                  {meta.title}
                </TableCell>

                {props.products.map((product) => {
                  return (
                    <EvaluateSummaryItemValue
                      item={item}
                      product={product}
                      sid={props.sid}
                      meta={meta}
                      templateType={props.type}
                      person={props.person}
                      sectionMeta={props.sectionMeta}
                    />
                  );
                })}
              </TableRow>
            );
          })
        : null}
    </>
  );
}
