import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormControl,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import { useFormik } from "formik";

import * as yup from "yup";

import {
  useMutation,
  useApolloClient,
  useQuery,
  useLazyQuery,
} from "@apollo/client";

import SimpleSnackbar from "./snackbar";
import SimpleErrorSnackbar from "./errorsnackbar";

import {
  //   addServiceToCompany,
  editService,
  getCategoriesByServiceType,
  getProductsByCompanyId,
  getServicesByCompanyId,
  getServiceTypes,
  postProduct,
} from "../query";

import AddNewButton from "../../../products/product2.0/AddNew/AddNewButton";

import { useStyles } from "./styles";

import InputField from "../../../common/InputField";
// import SelectField from "../../../common/SelectField";
import AddServiceTypeForm from "./AddServiceTypeForm";
import PrimaryButton from "../../../common/PrimaryButton";

export default function AddServicesForm({
  serviceId,
  serviceName,
  serviceShortDesc,
  serviceLongDesc,
  action,
  updateCache,
}) {
  const classes = useStyles();
  const { id } = useParams();
  const client = useApolloClient();
  const timer = useRef();

  let serviceTypeList = [];

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState([]);
  const [serviceTypeCount, setServiceTypeCount] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [currentService, setCurrentService] = useState(0);
  //   const [categorySelect, setCategorySelect] = useState(false);
  const [newList, setNewList] = useState(false);

  serviceTypeList.push(
    {
      type: "Consulting Services",
      value: "Consulting Services",
    },
    { type: "Professional Services", value: "Professional Services" },
    { type: "Managed Services", value: "Managed Services" },
    { type: "Development Services", value: "Development Services" }
  );

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!mutationLoading && !loading) {
      setLoading(true);

      timer.current = window.setTimeout(() => {
        setLoading(false);
        handleClose();
      }, 50000);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter Service Name")
      .min(2, "Service Name should be of minimum 2 characters length")
      .required("Service Name is required"),

    // shortDescription: yup
    //   .string("Enter Service Short Description")
    //   .required("Service Short Description is required"),

    url: yup
      .string("Enter Service URL")
      .required("Service URL is required")
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        "Enter Service URL"
      ),
  });

  const formik = useFormik(
    action === "edit"
      ? {
          initialValues: {
            name: serviceName,
            shortDescription: serviceShortDesc,
            longDescription: serviceLongDesc,
            categories: [],
            serviceType: [],
            url: "",
          },
          validationSchema: validationSchema,
        }
      : {
          initialValues: {
            name: "",
            shortDescription: "",
            longDescription: "",
            categories: [],
            url: "",
          },
          validationSchema: validationSchema,
        }
  );

  const [
    postServiceMutation,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(postProduct, {
    onCompleted: (response) => {
      updateServiceInformationCache(response);
      servicefetch();
    },
  });

  const [updateServiceMutation] = useMutation(editService, {
    onCompleted: (response) => {},
  });

  const getTypeName = (serviceTypeId) => {
    let service = serviceTypeList.filter((serviceType) => {
      return serviceType.type === serviceTypeId;
    });

    return service[0]?.value;
  };

  const [getCategories, { loading: categoriesLoading }] = useLazyQuery(
    getCategoriesByServiceType,
    {
      variables: {
        input: {
          name: getTypeName(selectedType[currentService]),
        },
      },
      onCompleted: (response) => {
        let currentList = response.listTopicsByServicetypeName;

        let mainCategoriesList = categoryList;
        mainCategoriesList[currentService] = currentList;

        setCategoryList(mainCategoriesList);

        setNewList(!newList);
      },
    }
  );

  const createService = (e) => {
    e.preventDefault();

    const { name, shortDescription, longDescription, url } = formik.values;

    let finalCategories = [];

    selectedCategories.forEach((category) => {
      finalCategories.push(category);
    });

    postServiceMutation({
      variables: {
        input: {
          name: name,
          companyid: id,
          producturl: url,
          type: "service",
        },
      },
    });

    handleButtonClick();
  };

  const updateService = (e) => {
    e.preventDefault();

    const { name, shortDescription, longDescription } = formik.values;

    updateServiceMutation({
      variables: {
        input: {
          name: name,
          shortdescription: shortDescription,
          longdescription: longDescription,
          vbeid: serviceId,
        },
      },
    });

    handleButtonClick();
  };

  // const { data: productData, refetch: listservice } = useQuery(
  //   getServicesByCompanyId,
  //   {
  //     variables: { input: { companyvbeid: id } },
  //   }
  // );

  const { data: servicetData, refetch: servicefetch } = useQuery(
    getProductsByCompanyId,
    {
      variables: { input: { vbeid: id, type: "service" } },
    }
  );

  const updateServiceInformationCache = (serviceData) => {
    const { productByComapnyId } = client.readQuery({
      query: getProductsByCompanyId,
      variables: { input: { vbeid: id, type: "service" } },
    });

    let listServiceInformationCache = [
      serviceData.addProductShort,
      ...productByComapnyId,
    ];

    client.writeQuery({
      query: getProductsByCompanyId,
      data: {
        productByComapnyId: {
          __typename: "service",
          productByComapnyId: listServiceInformationCache,
        },
      },
      variables: { input: { companyvbeid: id } },
    });

    setOpen(false);
    updateCache(serviceData.addProductShort);
  };

  const handleChange = (e, index) => {
    let selectedTypes = selectedType;

    selectedTypes[index] = e.target.value;

    // console.log("selectedTypes", selectedType);

    setCurrentService(index);
    setSelectedType(selectedTypes);

    let mainCategoriesList = categoryList;
    mainCategoriesList[index] = [];
    setCategoryList(mainCategoriesList);

    getCategories();
  };

  const addServiceType = () => {
    let newCount = serviceTypeCount + 1;
    setServiceTypeCount(newCount);
  };

  const handleCategorySelect = (e, index) => {
    // let selectCategories = e;
    // setSelectedCategories(e);
    // let currentCategories = selectedCategories[index];
    // console.log("eee", e);
    var select = e.map((val) => {
      return val.vbeid;
    });
    setSelectedCategories(select);
    // if (currentCategories) {
    //   currentCategories = e.target.value;
    // } else {
    //   currentCategories = e.target.value;
    // }

    // console.log("eee", e);

    // prevSelectedCategories[index] = currentCategories;

    // setSelectedCategories(prevSelectedCategories);
    // setCategorySelect(!categorySelect);
  };

  const returnServiceTypes = () => {
    let returnTypes = [];

    for (var i = 0; i < serviceTypeCount; i++) {
      let index = i;

      returnTypes.push(
        <AddServiceTypeForm
          handleChange={(e) => handleChange(e, index)}
          selectedType={selectedType[i]}
          serviceTypeList={serviceTypeList}
          categoryList={categoryList[i]}
          index={i}
          handleCategorySelect={(e) => handleCategorySelect(e, index)}
          selectedCategories={selectedCategories}
          categoriesLoading={categoriesLoading}
        />
      );
    }

    return returnTypes;
  };

  return (
    <div>
      {mutationError && <SimpleErrorSnackbar />}
      {data && <SimpleSnackbar />}

      {action === "edit" ? (
        <IconButton>
          <EditOutlinedIcon fontSize="small" onClick={handleClickOpen} />
        </IconButton>
      ) : (
        <AddNewButton name="Add New Service" handleClick={handleClickOpen} />
      )}

      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          className={classes.formMainDiv}
        >
          <DialogTitle id="form-dialog-title" className={classes.formTitle}>
            {action === "edit" ? "Edit Service" : "Add Service"}
          </DialogTitle>

          <DialogContent dividers className={classes.formContent}>
            <DialogContentText className={classes.stmt}>
              Please enter the following details to create a Service.
            </DialogContentText>

            <div className={classes.root}>
              {/* <Grid
                container
                style={{ width: "100%", display: "flex", alignItems: "center" }}
                spacing={1}
              >
                {returnServiceTypes()}

                {serviceTypeCount < 5 && (
                  <Grid item md={2}>
                    <IconButton onClick={addServiceType}>
                      <AddIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                )}
              </Grid> */}

              <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <FormControl fullWidth={true} className={classes.formControl}>
                    <InputField
                      label="Service Name *"
                      onChange={formik.handleChange("name")}
                      value={formik.values.name}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={11}>
                  {formik.errors.name ? (
                    <div className={classes.errorMsg}>{formik.errors.name}</div>
                  ) : null}
                </Grid>
              </Grid>

              {/* <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <FormControl fullWidth={true} className={classes.formControl}>
                    <InputField
                      label="Service Short Description *"
                      onChange={formik.handleChange("shortDescription")}
                      value={formik.values.shortDescription}
                      multiline={true}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={11}>
                  {formik.errors.shortDescription ? (
                    <div className={classes.errorMsg}>
                      {formik.errors.shortDescription}
                    </div>
                  ) : null}
                </Grid>
              </Grid> */}

              <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <FormControl fullWidth={true} className={classes.formControl}>
                    <InputField
                      label="Service URL *"
                      onChange={formik.handleChange("url")}
                      value={formik.values.url}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={11}>
                  {formik.errors.url ? (
                    <div className={classes.errorMsg}>{formik.errors.url}</div>
                  ) : null}
                </Grid>

                <FormHelperText margin="dense" className={classes.helperText}>
                  * Please add a service URL as available on your website for
                  the Service. Our AI will also use this content and metadata
                  when buyers are using Advanced Search on vBridge Hub.
                </FormHelperText>
              </Grid>

              {/* <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <FormControl fullWidth={true} className={classes.formControl}>
                    <InputField
                      label="Service Long Description *"
                      onChange={formik.handleChange("longDescription")}
                      value={formik.values.longDescription}
                    />  qAS3
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.longDescription &&
                  formik.errors.longDescription ? (
                    <div className="error-message">
                      {formik.errors.longDescription}
                    </div>
                  ) : null}
                </Grid>
              </Grid> */}

              {/* <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <InputLabel required={true} shrink focused={true}>
                    Map Topics to Service
                  </InputLabel>

                  <FormControl className={classes.textfields}>
                    <Autocomplete
                      multiple
                      id="mapTopic"
                      options={topics}
                      getOptionLabel={(option) => option["n.name"]}
                      style={{ width: "100%" }}
                      clearOnBlur="true"
                      onChange={(event, newvalue) => {
                        let selectedTopics = [];

                        newvalue.forEach((value) => {
                          selectedTopics.push(value["n.vbeid"]);
                        });

                        setCategories(selectedTopics);
                      }}
                      renderOption={(option) => <>{option["n.name"]} </>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Topic"
                          variant="outlined"
                          margin="dense"
                          id="input"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.producturl && formik.errors.producturl ? (
                    <div className="error-message">
                      {formik.errors.producturl}
                    </div>
                  ) : null}
                </Grid>
              </Grid> */}

              {/* <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.textfields}>
                    <InputLabel
                      required={true}
                      shrink
                      htmlFor="my-input1"
                      focused={true}
                    >
                      Tag Products
                    </InputLabel>

                    <BootstrapInput
                      required={true}
                      id="my-input1"
                      aria-describedby="my-helper-text"
                      value={formik.values.producturl}
                      onChange={formik.handleChange("producturl")}
                      onBlur={formik.handleBlur("producturl")}
                      placeholder="Product URL should begin with https://"
                    />
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.producturl && formik.errors.producturl ? (
                    <div className="error-message">
                      {formik.errors.producturl}
                    </div>
                  ) : null}
                </Grid>
              </Grid> */}

              {/* <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.textfields}>
                    <InputLabel
                      required={true}
                      shrink
                      htmlFor="my-input1"
                      focused={true}
                    >
                      Service Success Story
                    </InputLabel>

                    <BootstrapInput
                      required={true}
                      id="my-input1"
                      aria-describedby="my-helper-text"
                      value={formik.values.producturl}
                      onChange={formik.handleChange("producturl")}
                      onBlur={formik.handleBlur("producturl")}
                      placeholder="Product URL should begin with https://"
                    />
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.producturl && formik.errors.producturl ? (
                    <div className="error-message">
                      {formik.errors.producturl}
                    </div>
                  ) : null}
                </Grid>
              </Grid> */}

              {/* <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.textfields}>
                    <InputLabel
                      required={true}
                      shrink
                      htmlFor="my-input1"
                      focused={true}
                    >
                      Locations served
                    </InputLabel>

                    <BootstrapInput
                      required={true}
                      id="my-input1"
                      aria-describedby="my-helper-text"
                      value={formik.values.producturl}
                      onChange={formik.handleChange("producturl")}
                      onBlur={formik.handleBlur("producturl")}
                      placeholder="Product URL should begin with https://"
                    />
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.producturl && formik.errors.producturl ? (
                    <div className="error-message">
                      {formik.errors.producturl}
                    </div>
                  ) : null}
                </Grid>
              </Grid> */}
            </div>
          </DialogContent>

          <DialogActions>
            <PrimaryButton
              onClick={handleClose}
              variant="outlined"
              label="Cancel"
            />

            <PrimaryButton
              onClick={action === "edit" ? updateService : createService}
              variant="contained"
              label={action === "edit" ? "Edit" : "Add"}
              disabled={!(formik.isValid && formik.dirty)}
            />

            {mutationLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
