import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Paper, Card, CardContent, Grid } from '@material-ui/core'

import { useStyles } from './styles'

import { useQuery, useMutation, useApolloClient } from '@apollo/client'

import SimpleSnackbar from './snackbar'
import SimpleErrorSnackbar from './errorsnackbar'

import { getGroupById, updategroup } from './query'

import GroupTabs from './GroupTabs'
import MainFilters from './MainFilters'
import DataPageHeader from '../common/DataPageHeader'
import MainGroupLoading from './MainGroupLoading'

export default function MainGroup({ isReadOnly, setName, toggleValue }) {
  const classes = useStyles()
  const { id, typeValue } = useParams()

  const { data, error } = useQuery(getGroupById, {
    variables: { input: { vbeid: id } },
  })

  const [editName, setEditName] = useState(false)
  const [editDescription, setEditDescription] = useState(false)
  const [editNameValue, setEditNameValue] = useState('')
  const [editDescriptionValue, setEditDescriptionValue] = useState('')

  const [yearList, setYearList] = useState([])
  const [partnerList, setPartnerList] = useState([])
  const [locationsList, setLocationList] = useState([])
  const [awardsList, setAwardList] = useState([])
  const [fundingRoundsList, setFundingRoundsList] = useState([])
  const [maturityList, setMaturityList] = useState([])
  const [skillList, setSkillList] = useState([])

  const client = useApolloClient()

  const editNameClick = () => {
    setEditName(true)
  }

  const saveNameClick = () => {
    setEditName(false)
    updateCache('name', editNameValue)
  }

  const editDescriptionClick = () => {
    setEditDescription(true)
  }

  const saveDescriptionClick = () => {
    setEditDescription(false)
    updateCache('description', editDescriptionValue)
  }

  //update cache for update group
  const updateCache = (paramName, paramValue) => {
    if (paramValue !== '') {
      const { queryGroupbyid } = client.readQuery({
        query: getGroupById,
        variables: { input: { vbeid: id } },
      })

      let updatedGroup = { ...queryGroupbyid }
      updatedGroup[paramName] = paramValue

      client.writeQuery({
        query: getGroupById,
        data: {
          queryGroupbyid: updatedGroup,
        },

        variables: { input: { vbeid: id } },
      })

      postUpdateGroup(paramName, paramValue, id)
    }
  }

  const handleNameChange = (e) => {
    setEditNameValue(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setEditDescriptionValue(e.target.value)
  }

  const [
    postgroupmutation,
    { loading: updateLoading, error: mutationError },
  ] = useMutation(updategroup)

  const postUpdateGroup = (paramName, paramValue, groupid) => {
    postgroupmutation({
      variables: {
        input: {
          groupid: groupid,
          [paramName]: paramValue,
        },
      },
    })
  }

  const onFilterSet = (id, checkedValue) => {
    let filterList = locationsList

    if (checkedValue) {
      setLocationList((prevList) => [...prevList, id])
    } else {
      let index = filterList.indexOf(id)
      if (index !== -1) {
        setLocationList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ])
      }
    }
  }

  const onFilterAwards = (id, checkedValue) => {
    let filterList = awardsList

    if (checkedValue) {
      setAwardList((prevList) => [...prevList, id])
    } else {
      let index = filterList.indexOf(id)
      if (index !== -1) {
        setAwardList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ])
      }
    }
  }

  const onFilterYear = (id, checkedValue) => {
    let filterList = yearList

    if (checkedValue) {
      setYearList((prevList) => [...prevList, id])
    } else {
      let index = filterList.indexOf(id)
      if (index !== -1) {
        setYearList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ])
      }
    }
  }

  const onFilterPartner = (id, checkedValue) => {
    let filterList = partnerList

    if (checkedValue) {
      setPartnerList((prevList) => [...prevList, id])
    } else {
      let index = filterList.indexOf(id)
      if (index !== -1) {
        setPartnerList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ])
      }
    }
  }

  const onFilterFundingRound = (id, checkedValue) => {
    let filterList = fundingRoundsList

    if (checkedValue) {
      setFundingRoundsList((prevList) => [...prevList, id])
    } else {
      let index = filterList.indexOf(id)
      if (index !== -1) {
        setFundingRoundsList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ])
      }
    }
  }

  const onFilterMaturity = (id, checkedValue) => {
    let filterList = maturityList

    if (checkedValue) {
      setMaturityList((prevList) => [...prevList, id])
    } else {
      let index = filterList.indexOf(id)
      if (index !== -1) {
        setMaturityList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ])
      }
    }
  }

  const onFilterSkills = (id, checkedValue) => {
    let filterList = skillList

    if (checkedValue) {
      setSkillList((prevList) => [...prevList, id])
    } else {
      let index = filterList.indexOf(id)
      if (index !== -1) {
        setSkillList([
          ...filterList.slice(0, index),
          ...filterList.slice(index + 1),
        ])
      }
    }
  }

  if (data) {
    setName(data.queryGroupbyid.name)
  }

  useEffect(() => {
    setYearList([])
    setPartnerList([])
    setLocationList([])
    setAwardList([])
    setFundingRoundsList([])
    setMaturityList([])
    setSkillList([])
  }, [id])

  return (
    <>
      {mutationError && <SimpleErrorSnackbar />}
      {updateLoading && <SimpleSnackbar />}
      {/* {error && <SimpleErrorSnackbar msg="No group available" />} */}

      {
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={1} square={true}>
              <Card className={classes.root}>
                {data ? (
                  <CardContent style={{ textAlign: 'left' }}>
                    <DataPageHeader
                      data={data?.queryGroupbyid}
                      isReadOnly={isReadOnly}
                      vbeid={id}
                      updateCache={updateCache}
                      editName={editName}
                      editDescription={editDescription}
                      editNameClick={editNameClick}
                      handleNameChange={handleNameChange}
                      saveNameClick={saveNameClick}
                      editDescriptionClick={editDescriptionClick}
                      handleDescriptionChange={handleDescriptionChange}
                      saveDescriptionClick={saveDescriptionClick}
                      type="group"
                    />

                    <Grid container spacing={2}>
                      <MainFilters
                        onFilterSet={onFilterSet}
                        onFilterAwards={onFilterAwards}
                        onFilterYear={onFilterYear}
                        onFilterPartner={onFilterPartner}
                        onFilterFundingRound={onFilterFundingRound}
                        onFilterMaturity={onFilterMaturity}
                        onFilterSkills={onFilterSkills}
                        locationsList={locationsList}
                        awardsList={awardsList}
                        partnerList={partnerList}
                        fundingRoundsList={fundingRoundsList}
                        yearList={yearList}
                        maturityList={maturityList}
                        skillList={skillList}
                        type="group"
                        typeValue={typeValue}
                      />

                      <Grid item md={9} className={classes.mainTabsDiv}>
                        <GroupTabs
                          type="group"
                          data={data?.queryGroupbyid}
                          locationsList={locationsList}
                          awardsList={awardsList}
                          partnerList={partnerList}
                          fundingRoundsList={fundingRoundsList}
                          yearList={yearList}
                          maturityList={maturityList}
                          skillList={skillList}
                          isReadOnly={isReadOnly}
                          toggleValue={toggleValue}
                          typeValue={typeValue}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                ) : (
                  <MainGroupLoading error={error} />
                )}
              </Card>
            </Paper>
          </Grid>
        </Grid>
      }
    </>
  )
}
