import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { useHistory, useParams } from "react-router-dom";

import {
  AWARD_PROJECT_TO_PRODUCT,
  GET_TEMPLATE_PRODUCTS_BY_PROJECT,
} from "../../queries/query";

import { useQuery, useMutation } from "@apollo/client";

import EvaluationForm from "../../forms/others/evaluationForm";

import PropTypes from "prop-types";

import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import PrimaryButton from "../../../common/PrimaryButton";
import LoadingResult from "../../../../shared/LoadingResult";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ViewAwardForm({ projectData }) {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const [value, setValue] = useState(0);

  const { loading, data } = useQuery(GET_TEMPLATE_PRODUCTS_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id, typelist: ["sow_template"] } },
  });

  const [awardProduct, { loading: awardLoading }] = useMutation(
    AWARD_PROJECT_TO_PRODUCT,
    {
      onCompleted: (e) => {
        history.push("/project/overview/" + id);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSubmit = (productVbeid) => {
    awardProduct({
      variables: { input: { vbeid: id, productvbeid: productVbeid } },
    });
  };

  let awardProducts = [];

  if (data) {
    awardProducts = data.getProductsByProjectIDV1.filter((product) => {
      return product.role.includes("ADD_TO_AWARD");
    });
  }

  return (
    <div className={classes.root}>
      {data && awardProducts.length !== 0 ? (
        <>
          <Tabs value={value} onChange={handleChange}>
            {awardProducts.map((product, index) => {
              return (
                <Tab
                  label={
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{
                          textTransform: "capitalize",
                          textAlign: "left",
                          fontSize: "0.9rem",
                        }}
                      >
                        {product.companyname}
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          textTransform: "capitalize",
                          textAlign: "left",
                          fontSize: "0.8rem",
                        }}
                      >
                        {product.productname}
                      </Grid>

                      <Grid item xs={6}>
                        {projectData.role.includes("MANAGES") && (
                          <PrimaryButton
                            variant="contained"
                            label="Submit"
                            size="small"
                            onClick={() => onSubmit(product.productvbeid)}
                            disabled={
                              awardLoading || product.role.includes("AWARDED")
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  }
                  {...a11yProps(index)}
                  className={classes.tab}
                ></Tab>
              );
            })}
          </Tabs>

          <>
            {data.getProductsByProjectIDV1.map((product, index) => {
              return (
                <TabPanel value={value} index={index}>
                  <EvaluationForm
                    projectId={id}
                    productId={product.productvbeid}
                    type="sow_template"
                    templateId={data.getTemplatesByProjectID[0].vbeid}
                    readOnly={
                      product.role.includes("AWARDED") ||
                      projectData.role.includes("ADDED_TO")
                    }
                  />
                </TabPanel>
              );
            })}
          </>
        </>
      ) : loading ? (
        <LoadingResult />
      ) : (
        <div
          style={{
            textAlign: "center",
            color: "#999",
            fontStyle: "italic",
            margin: "16px",
          }}
        >
          No Suppliers have been moved to Award
        </div>
      )}
    </div>
  );
}
