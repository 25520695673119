import React, { useState } from "react";

import { useParams } from "react-router-dom";

import { Grid } from "@material-ui/core";

import { useMutation, useQuery } from "@apollo/client";

import { getCompanyById, postCompanyDesc } from "../query";

import OverviewTabs from "./OverviewTabs";
import AboutCompany from "./AboutCompany";

import AwardsList from "./lists/AwardsList";
import CustomersList from "./lists/CustomersList";
import PartnersList from "./lists/PartnersList";

export default function CompanyOverview({
  isReadOnly,
  orgType,
  isBuyer,
  isAdmin,
}) {
  const { id } = useParams();

  const [currentTab, setCurrentTab] = useState("about");

  const [postCompanyMutation] = useMutation(postCompanyDesc);

  const { data: companyData } = useQuery(getCompanyById, {
    variables: { input: { vbeid: id } },
  });

  ///save Data
  const saveData = (data) => {
    postCompanyMutation({
      variables: {
        input: {
          longdescription: data,
          vbeid: id,
        },
      },
    });
  };

  const onTabClick = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={2}>
        <OverviewTabs
          currentTab={currentTab}
          onTabClick={onTabClick}
          orgType={orgType}
          isBuyer={isBuyer}
        />
      </Grid>

      <Grid item md={10}>
        {currentTab === "about" && companyData ? (
          <AboutCompany
            companyData={companyData?.queryCompanybyid}
            id={id}
            isReadOnly={isReadOnly}
            saveData={saveData}
          />
        ) : currentTab === "awards" ? (
          <AwardsList isReadOnly={isReadOnly} isAdmin={isAdmin} />
        ) : currentTab === "customers" && orgType !== "Enterprise" ? (
          <CustomersList isReadOnly={isReadOnly} isAdmin={isAdmin} />
        ) : currentTab === "partners" && orgType !== "Enterprise" ? (
          <PartnersList isReadOnly={isReadOnly} isAdmin={isAdmin} />
        ) : null}
      </Grid>
    </Grid>
  );
}

// : currentTab === "investors" ? (
//   <InvestorsList isReadOnly={isReadOnly} isAdmin={isAdmin} />
// )
