import { useParams } from "react-router-dom";

import {
  Paper,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

import PropTypes from "prop-types";

import {
  GET_COMPANIES_BY_PROJECT,
  GET_SECTIONS,
  GET_TEMPLATE_BY_PROJECT,
} from "../../queries/query";

import { useQuery } from "@apollo/client";

import QASection from "../../forms/others/qaTablesesection";

import LoadingResult from "../../../../shared/LoadingResult";

import { useStyles } from "./styles";

import CandidateTable from "./CandidateTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const QASectionForm = (props) => {
  const classes = useStyles();

  const { loading, data } = useQuery(GET_SECTIONS, {
    variables: { input: { vbeid: props.templateId } },
  });

  return (
    <>
      {data ? (
        data.listSection.map((section, index) => {
          let obj = JSON.parse(section.metadata);

          return (
            <>
              <TableRow selected={true}>
                <TableCell
                  colSpan={props.products.length + 1}
                  className={classes.tableIndexCell}
                >
                  {obj.title}
                </TableCell>
              </TableRow>

              <QASection
                sid={section.id}
                projectId={props.projectId}
                products={props.products}
                type="qa_template"
                showProducts={props.showProducts}
              />
            </>
          );
        })
      ) : loading ? (
        <LoadingResult />
      ) : null}
    </>
  );
};

const QASections = ({ id, products, showProducts }) => {
  const { loading, data } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    variables: { input: { vbeid: id, typelist: ["qa_template"] } },
  });

  return (
    <>
      {data && data.getTemplatesByProjectID.length !== 0 ? (
        <QASectionForm
          templateId={data.getTemplatesByProjectID[0].vbeid}
          projectId={id}
          products={products}
          showProducts={showProducts}
        />
      ) : loading ? (
        <LoadingResult />
      ) : null}
    </>
  );
};

export default function ViewServiceResponseTable({ companies, projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  const { data } = useQuery(GET_COMPANIES_BY_PROJECT, {
    variables: { input: { vbeid: id } },
  });

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader={true} aria-label="vendors">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableIndexCell}></TableCell>

              {data
                ? data.listCompaniesByProjectID.map((company, index) => {
                    return (
                      <TableCell
                        key={index}
                        className={
                          companies.includes(company.vbeid)
                            ? classes.showcell
                            : classes.hidecell
                        }
                      >
                        {company.name}
                      </TableCell>
                    );
                  })
                : null}
            </TableRow>
          </TableHead>

          <TableBody>
            {data ? (
              <QASections
                id={id}
                products={data.listCompaniesByProjectID}
                showProducts={companies}
              />
            ) : null}

            {data ? (
              <CandidateTable
                id={id}
                companies={data.listCompaniesByProjectID}
              />
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
