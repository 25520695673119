import gql from "graphql-tag";

export const listChannels = gql`
  query channelsQuery($input: id!) {
    getFeedEnabledGroups(input: $input) {
      id
      image
      name
      count
      followinggroup
    }
  }
`;

export const listTopics = gql`
  query topicsQuery($input: id!) {
    getLabelByGroupId(input: $input) {
      id
      count
      name
    }
  }
`;

export const followGroup = gql`
  mutation followGroup($input: id!) {
    followGroup(input: $input) {
      id
    }
  }
`;

export const unfollowGroup = gql`
  mutation unfollowGroup($input: id!) {
    unfollowGroup(input: $input) {
      id
    }
  }
`;

export const listarticalByGroupID = gql`
  query articalQuery($input: id!) {
    getFeedByGroupId(input: $input) {
      author
      companyid
      companyname
      companylogo
      name
      categories
      description
      guid
      id
      longdescription
      pubdate
      title
      url
      vbeid
      image
      likedartical
      followingartical
    }
  }
`;

export const getNewArtical = gql`
  query articalQuery($input: id!) {
    getNewFeeds(input: $input) {
      author
      companyid
      companyname
      companylogo
      name
      categories
      description
      guid
      id
      longdescription
      pubdate
      title
      url
      vbeid
      image
      likedartical
      followingartical
    }
  }
`;

export const getFeedByFollowingCompany = gql`
  query articalQuery($input: id!) {
    getFeedByFollowingCompany(input: $input) {
      author
      companyid
      companyname
      companylogo
      name
      categories
      description
      guid
      id
      longdescription
      pubdate
      title
      url
      vbeid
      image
      likedartical
      followingartical
    }
  }
`;

export const listarticlesByLikes = gql`
  query listlikedFeed($input: id!) {
    listlikedFeed(input: $input) {
      author
      companyid
      companyname
      companylogo
      name
      categories
      description
      guid
      id
      longdescription
      pubdate
      title
      url
      vbeid
      image
      likedartical
      followingartical
    }
  }
`;

export const listSavedArticles = gql`
  query listSaveFeed($input: id!) {
    listSaveFeed(input: $input) {
      author
      companyid
      companyname
      companylogo
      name
      categories
      description
      guid
      id
      longdescription
      pubdate
      title
      url
      vbeid
      image
      likedartical
      followingartical
    }
  }
`;

export const getFollowers = gql`
  query getFollowersbycompanyid($input: id!) {
    getFollowersbycompanyid(input: $input) {
      count
    }
  }
`;

export const amifollowingCompany = gql`
  query amifollowingCompany($input: id!) {
    amifollowingCompany(input: $input) {
      id
      followingcompany
    }
  }
`;

export const likeFeed = gql`
  mutation likeMutation($input: id!) {
    likeFeed(input: $input) {
      id
    }
  }
`;

export const unlikeFeed = gql`
  mutation unlikeMutation($input: id!) {
    unlikeFeed(input: $input) {
      id
    }
  }
`;

export const saveFeed = gql`
  mutation saveFeedMutation($input: id!) {
    saveFeed(input: $input) {
      id
    }
  }
`;

export const followCompany = gql`
  mutation followCompany($input: id!) {
    followCompany(input: $input) {
      id
    }
  }
`;

export const unfollowCompany = gql`
  mutation unfollowCompany($input: id!) {
    unfollowCompany(input: $input) {
      id
    }
  }
`;
