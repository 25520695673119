import { makeStyles, withStyles, Tooltip } from "@material-ui/core";
import { GridOverlay } from "@material-ui/data-grid";

export const useStyles = makeStyles((theme) => ({
  projectRoot: {
    paddingTop: theme.spacing(2),
  },
  projectDashboardBreadcrumb: {
    paddingBottom: theme.spacing(4),
  },
  breadCrumbLink: {
    textDecoration: "none",
    fontStyle: "italic",
  },
  breadCrumbText: {
    fontStyle: "italic",
  },
  textSpacingForNotAuthorized: {
    paddingBottom: theme.spacing(0.1),
  },
  textAlignForNotAuthorized: {
    textAlign: "left",
  },
  listHeightForProjectList: {
    height: 60,
  },
  listitemPaddingForProjectlist: {
    //padding: "0.3rem"
    paddingLeft: theme.spacing(2),
  },
  projectHeadlineProjectNameSpace: {
    marginLeft: "-65px",
  },
  projectHeadlineProjectCreatedSpace: {
    marginLeft: "5px",
  },
  projectHeadlineProjectOwnerSpace: {
    marginLeft: "20px",
  },
  projectHeadlineProjectStatuSpace: {
    marginLeft: "18px",
  },
  projectHeadlineVendorSelectedSpace: {
    marginRight: "-15px",
  },
  projectHeadlineVendorSubmittedSpace: {
    marginRight: "-5px",
  },
  projectHeadline: {
    textAlign: "left",
    fontWeight: 550,
    fontSize: 12,
    paddingLeft: theme.spacing(13),
  },
  projectLineItemNumbers: {
    textAlign: "left",
    fontWeight: 500,
    fontSize: 12,
  },
  projectLineItems: {
    textAlign: "left",
    fontWeight: 500,
    fontSize: 12,
  },
  projectCreatedByLineItems: {
    textAlign: "left",
    fontWeight: 500,
    fontSize: 12,
  },
  projectLineItemsAlign: {
    marginTop: "-3px",
  },
  projectLineItemStatus: {
    textAlign: "left",
    fontWeight: 500,
    fontSize: 12,
    //paddingRight:theme.spacing(2)
  },
  projectLineItemsSpacing: {
    paddingRight: theme.spacing(5),
  },
  projectPage: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },

  headName: {
    cursor: "pointer",
    fontWeight: 800,
    fontSize: 12,
  },
  headNameSecondary: {
    color: "grey",
  },
  drawer: {
    backgroundColor: "white",
    maxWidth: theme.spacing(200),
    minWidth: theme.spacing(110),
    marginTop: theme.spacing(2),
    height: "100%",
    // padding:theme.spacing(0),
  },

  buttonStyle: {
    borderRadius: "2rem",
    textTransform: "capitalize",
    fontSize: "0.7rem",
  },
  messageButtonStyle: {
    borderRadius: "2rem",
    textTransform: "capitalize",
    fontSize: 10,
  },
  formPaper: {
    width: "140%",
    padding: "6%",
    textAlign: "center",
  },
  formBox: {
    marginTop: "5%",
    marginLeft: "20%",
    marginRight: "45%",
  },
  buttonAlignForNotAuthorizeduser: {
    margin: "auto",
    marginTop: theme.spacing(2),
  },

  headerArrow: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },

  headerText1: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(22),
    fontSize: "1rem",
    fontWeight: 600,
    color: "#0e3046",
  },

  headerText2: {
    paddingBottom: theme.spacing(1),
    fontSize: "0.85rem",
    fontWeight: "normal",
    color: "#0e3046",
  },

  backArrowLink: {
    color: theme.palette.secondary.main,
    float: "left",
    textDecoration: "none",
  },
  footerButtons: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(35),
  },
  footerButtonForScreeningQuestions: {
    marginTop: "-7%",
  },
  vendorsListCompanyName: {
    color: "#4179BD",
    cursor: "pointer",
  },

  textfields: {
    width: "80%",
  },
  createProjectRoot: {
    paddingTop: theme.spacing(2),
  },
  createProjectFormFields: {
    paddingLeft: theme.spacing(11),
  },
  timelineTextField: {
    width: "40%",
    //textAlign: 'left',
  },
  timelineTextFieldAlign: {
    textAlign: "left",
    paddingLeft: theme.spacing(12),
  },
  timelineTextFieldAlignReview: {
    textAlign: "left",
    paddingLeft: theme.spacing(11),
  },
  saveButtonReviewForUpdateProject: {
    paddingRight: theme.spacing(15),
  },
  saveButtonReviewForUpdateRequirement: {
    paddingRight: theme.spacing(15),
  },
  projectDashboardDefaultText: {
    paddingBottom: theme.spacing(4),
  },
  vendorListSubText: {
    marginTop: "6px",
    fontSize: "0.2rem",
  },
  errorfields: {
    color: "brown",
    paddingLeft: theme.spacing(12),
    paddingBottom: theme.spacing(0),
    fontWeight: 450,
    fontSize: 12,
  },
  infoblock: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(32),
  },
  addAttachment: {
    paddingLeft: theme.spacing(15),
    paddingBottom: theme.spacing(2),
  },
  addDraftAttachment: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  addAttachmentText: {
    paddingLeft: theme.spacing(3),
  },
  attachmentbutton: {
    borderRadius: "2rem",
    textTransform: "capitalize",
    fontSize: 10,
  },
  listAttachmentText: {
    color: "primary",
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "1rem",
  },
  listAttachmentTextSize: {
    fontSize: "0.8rem",
  },
  listAttachmentTextAdjust: {
    paddingLeft: theme.spacing(0),
    marginBottom: "-1%",
    marginTop: "-2%",
  },
  listAttachmentTextAdjustCreateFlow: {
    paddingLeft: theme.spacing(14),
    marginBottom: "-1%",
    marginTop: "-2%",
  },
  listAttachmentsDeleteIcon: {
    color: "primary",
    cursor: "pointer",
  },
  vendorsListForSubmitInRequirementTab: {
    paddingLeft: theme.spacing(0),
    width: 900,
    height: 200,
    overflowY: "scroll",
    overflowX: "hidden",
  },
  vendorsList: {
    paddingLeft: theme.spacing(13),
    width: 900,
    height: 300,
  },
  vendorsListScroll: {
    paddingLeft: theme.spacing(13),
    width: 900,
    height: 300,
    overflowY: "scroll",
    overflowX: "hidden",
  },
  vendorsListHeight: {
    height: 75,
  },
  vendorsListImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  vendorsListText: {
    padding: "2rem",
  },
  vendorsListDescription: {
    fontSize: 12,
  },
  textPointer: {
    cursor: "pointer",
  },
  noVendorsListText: {
    paddingTop: theme.spacing(15),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(23),
    fontStyle: "italic",
  },
  noVendorsListTextAlign: {
    fontSize: 13,
    fontWeight: "bold",
  },
  vendorsLoading: {
    paddingLeft: theme.spacing(23),
  },
  addMember: {
    paddingLeft: theme.spacing(11),
  },
  projectAddMember: {
    paddingLeft: theme.spacing(0),
    marginTop: "-6%",
  },
  projectAddMemberTextField: {
    paddingLeft: theme.spacing(0),
  },
  projectAddMemberField: {
    paddingTop: theme.spacing(0),
  },
  projectAddMemberFieldWidth: {
    width: "90%",
    height: "10%",
  },
  projectAddMemberButton: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  addMemberTextField: {
    paddingLeft: theme.spacing(4),
  },
  addMemberField: {
    paddingTop: theme.spacing(5),
  },
  addMemberFieldWidth: {
    width: "80%",
  },
  addMemberButton: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(22),
  },
  addMemberPaper: {
    width: 950,
  },
  addMemberDivAlign: {
    paddingLeft: theme.spacing(10),
  },
  addMemberList: {
    paddingLeft: theme.spacing(10),
    height: 300,
  },
  addMemberListScroll: {
    paddingLeft: theme.spacing(10),
    height: 300,
    overflowY: "scroll",
    overflowX: "hidden",
  },
  projectAddMemberList: {
    height: 100,
  },
  projectOwnerMemberListScroll: {
    height: 150,
    overflowY: "scroll",
    overflowX: "hidden",
  },
  projectMemberListScroll: {
    height: 200,
    overflowY: "scroll",
    overflowX: "hidden",
  },
  projectTeamMemberList: {
    height: 50,
  },
  teamMemberList: {
    height: 60,
  },
  projectMemberListText: {
    padding: "0.1rem",
  },
  projectMembersTextSize: {
    fontSize: "12px",
  },

  projectSidesHeading: {
    color: theme.palette.secondary.main,
    textAlign: "left",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    textTransform: "capitalize",
  },
  reviewAndSubmitAccordianHeading: {
    paddingLeft: theme.spacing(10),
    fontSize: "1rem",
    fontWeight: 600,
    color: "#0e3046",
  },
  reviewProjectFormFields: {
    width: "170%",
    paddingLeft: theme.spacing(14),
    paddingBottom: theme.spacing(1),
  },
  reviewProjectFields: {
    width: "100%",
  },
  reviewVendorsList: {
    paddingRight: theme.spacing(8),
  },
  submitReqestRoot: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  submitRequestErrorFields: {
    color: "red",
  },
  submitRequestTextFields: {
    width: "100%",
  },
  addNewSendRequest: {
    width: "115%",
  },
  submitRequestFormWidth: {
    width: "100%",
  },
  projectview: {
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(2),
  },
  projectViewBackLinkAlign: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  projectViewBackLink: {
    color: theme.palette.secondary.main,
    float: "left",
  },
  projectHeadTextAlign: {
    textAlign: "left",
  },
  projectName: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#0e3046",
  },
  projectHeadTimeline: {
    color: "black",
  },
  coverLetterBuyerSide: {
    fontSize: 12,
    textDecoration: "underline",
  },
  latestResponded: {
    fontSize: 11,
    color: "grey",
    fontStyle: "italic",
  },
  spaceBetweenCoverAndDate: {
    paddingTop: theme.spacing(1),
  },
  coverLetterBuyerSideDescription: {
    fontSize: 12,
  },
  addAttachmentButtonAlign: {
    marginTop: "12px",
  },
  projectHeadTimelineUnderline: {
    color: "grey",
    borderBottomStyle: "dotted",
  },
  readMore: {
    fontSize: "0.85rem",
    fontWeight: "normal",
    color: "#0e3046",
    wordWrap: "break-word",
    // "& p": {
    //   margin: "3px 0 0 0",
    //   fontSize: "0.85rem",
    // },
  },
  readMoreAlign: {
    paddingTop: theme.spacing(1),
  },
  projectHeadContext: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  projectSidesCardHeight: {
    height: 260,
  },
  projectSidesCardMembersHeight: {
    height: 210,
  },
  projectSidesCardspacing: {
    paddingBottom: theme.spacing(1),
  },
  conciergeRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 260,
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  emptyBox: {
    color: "#999",
    fontSize: "0.7rem",
  },
  conciergeLine1: {
    fontSize: "0.8rem",
    fontStyle: "italic",
  },
  conciergeLine2: {
    fontStyle: "italic",
    marginTop: theme.spacing(1),
  },
  conciergebutton: {
    paddingTop: theme.spacing(2),
    "& button": {
      float: "none",
    },
  },
  addTeamButtonStyle: {
    borderRadius: "2rem",
    textTransform: "capitalize",
    fontSize: 10,
  },
  addTeambuttonAlign: {
    //paddingTop:theme.spacing(1),
    //paddingRight:theme.spacing(20)
  },

  addQuestionButtonAlign: {
    paddingRight: theme.spacing(13),
  },

  norows: {
    flexDirection: "column",
    "& .ant-empty-img-1": {
      fill: theme.palette.type === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.type === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.type === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.type === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.type === "light" ? "0.8" : "0.08",
      fill: theme.palette.type === "light" ? "#f5f5f5" : "#fff",
    },
  },
  headlineTextQuestionForm: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  screeningQuestionsReviewAddButton: {
    paddingLeft: theme.spacing(103),
  },
  questionTableButtonAlign: {
    marginTop: "-3%",
  },
  screeningQuestionsReviewAlign: {
    marginLeft: theme.spacing(4),
  },
  emptyDesc: {
    color: "#bebebe",
    fontSize: "0.75rem",
    fontStyle: "italic",
  },
  textAdjustToLeft: {
    textAlign: "left",
  },
  textLineAdjustRequirement: {
    paddingBottom: theme.spacing(1),
  },
  textLineStylesRequirement: {
    paddingBottom: theme.spacing(1),
    fontSize: 13,
  },
  buttonAlignmentForEmptyRequirement: {
    paddingLeft: theme.spacing(40),
    paddingBottom: theme.spacing(1),
  },
  submittedRequirementView: {
    fontSize: "0.85rem",
    fontWeight: "normal",
    color: "#0e3046",
    padding: "20px",
    wordWrap: "break-word",
    // overflowX: "hidden",
    // width:650
  },
  borderForLongDescription: {
    //borderRadius:'40px',
    background: "#ebebeb",
  },
  requirementViewSideHeading: {
    textAlign: "left",
    fontSize: "1rem",
    fontWeight: 600,
    color: "#0e3046",
  },
  listAttachmentsDeleteIconAlign: {
    marginRight: "-40px",
  },
  requirementInAddNew: {
    width: "110%",
  },
  questionareTableInAddNew: {
    width: "1050px",
  },
  avatarSizeResponses: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  responsesListItemHeight: {
    height: 95,
  },
  responsesListItemPadding: {
    padding: "0.5rem",
  },
  responseDrawer: {
    backgroundColor: "white",
    maxWidth: theme.spacing(100),
    minWidth: theme.spacing(99),
    marginTop: theme.spacing(0),
    height: "100%",
    padding: theme.spacing(1),
  },
  responseDrawerAdjust: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  responseDrawerCompanyNameAdjust: {
    paddingTop: theme.spacing(1),
  },
}));

//light Tooltip
export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export function CustomNoRowsOverlay() {
  const classes = useStyles();

  return (
    <GridOverlay className={classes.norows}>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>

      <div className={classes.emptyDesc}>
        Add your questions to send them to seller by clicking Add Question.
      </div>
    </GridOverlay>
  );
}
