import { useState } from "react";

import {
  Paper,
  Grid,
  Typography,
  FormControl,
  Button,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import TemplateTableContainer from "./templateList";

import { GET_PROJECT, GET_TEMPLATE_BY_PROJECT } from "../../queries/query";

import { useQuery } from "@apollo/client";

import { useParams, useHistory } from "react-router-dom";

import { useStyles } from "./styles";
import PageButtons from "../createProject/WorkPackage/PageButtons";

const ButtonGroup = ({ id, loading }) => {
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            history.push("/add-vendors/" + id);
          }}
        >
          Back
        </Button>
      </Grid>

      <Grid item xs={5}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            history.push("/update-requirements/" + id);
          }}
          disabled={loading}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

export default function MapRequirementTemplate({ type }) {
  const classes = useStyles();
  const { id } = useParams();

  const [for_, setFor_] = useState("all");

  const { data: projectData } = useQuery(GET_PROJECT, {
    variables: { input: { vbeid: id }, fetchPolicy: "no-cache" },
  });

  const { data, refetch } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    variables: { input: { vbeid: id, typelist: ["requirement_template"] } },
  });

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs={12}>
        <Grid container className={classes.templatelist}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.textFieldLabel}
            >
              Requirement Templates (Select One)
            </Typography>
          </Grid>
        </Grid>

        <Paper elevation={1} className={classes.templatelistPaper}>
          <Grid container>
            <Grid item xs={6}></Grid>

            <Grid item xs={6}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="for"
                  name="for"
                  value={for_}
                  onChange={(e) => {
                    setFor_(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio size="small" />}
                    label="All"
                    className={classes.for}
                  />

                  <FormControlLabel
                    value="public"
                    control={<Radio size="small" />}
                    label="vBridge Hub"
                    className={classes.for}
                  />

                  <FormControlLabel
                    value="company"
                    control={<Radio size="small" />}
                    label="My Company "
                    className={classes.for}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TemplateTableContainer
                id={"requirement_template"}
                forList={for_ === "all" ? ["public", "company"] : [for_]}
                status="published"
                refetch={refetch}
                selectedTemplate={
                  data && data.getTemplatesByProjectID.length !== 0
                    ? data?.getTemplatesByProjectID[0]
                    : []
                }
                projectData={projectData?.getProjectByIDV1}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {projectData &&
        (projectData.getProjectByIDV1.type !== "service-workpackage" ? (
          <Grid item xs={9}>
            <ButtonGroup id={id} />
          </Grid>
        ) : (
          <PageButtons
            nextLocation="updateTemplate"
            nextDisabled={false}
            prevDisabled={false}
          />
        ))}
    </Grid>
  );
}
