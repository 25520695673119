import React, { useState } from "react";

import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useStyles } from "./styles";
import { Button, Grid } from "@material-ui/core";
import "./role.css";
import AddUser from "./AddUser";
import { useQuery } from "@apollo/client";
import { getUsersList } from "../AdminDashboard/query";
import {
  removeProjectOwner,
  removeOperationsEmp,
  removeFinanceEmp,
} from "./query";

import { useMutation } from "@apollo/client";
import SimpleSnackbar from "../companies/Company2.0/shorts/snackbar";
import SimpleErrorSnackbar from "../companies/Company2.0/shorts/errorsnackbar";
import { postCompanyInfo } from "../DataValidation/query";
import AlertBox from "../common/AlertBox";
import { getCompanyById } from "../companies/Company2.0/query";
import AddSwiper from "./AddSwiper";
import { Alert } from "@material-ui/lab";

function Row({ role, userInfo }) {
  const { data: companyData, refetch: companyRefetch } = useQuery(
    getCompanyById,
    {
      variables: { input: { vbeid: userInfo?.companyvbeid } },
    }
  );

  const [open, setOpen] = useState(false);
  const [alertBox, setAlertBox] = useState(false);
  const [alertBoxEnable, setAlertBoxEnable] = useState(false);
  const [projectEnable, setProjectEnable] = useState(
    companyData?.queryCompanybyid?.projectowner
  );
  const [swipperOpen, setSwipperOpen] = useState(false);

  const [userId, setUserId] = useState("");
  const classes = useStyles();

  const {
    data: usersList,
    loading,
    error: userError,
    refetch,
  } = useQuery(getUsersList, {
    variables: { input: { companyid: userInfo?.companyvbeid } },
  });

  const handleRemoveOpen = (id) => {
    setAlertBox(true);
    setUserId(id);
  };
  const handleRemoveClose = () => {
    setAlertBox(false);
  };

  const handleEnableOpen = (id) => {
    setProjectEnable(!projectEnable);
    setAlertBoxEnable(true);
  };
  const handleEnableClose = () => {
    setAlertBoxEnable(false);
  };

  const handleSwipperOpen = () => {
    setSwipperOpen(true);
  };

  const handleSwipperClose = () => {
    setSwipperOpen(false);
  };

  let roleField,
    roleType,
    roleid,
    title = "";

  if (role.id === "project") {
    roleField = removeProjectOwner;
    roleType = "FROM_PROJECTOWNER_TEAM";
    roleid = "project";
    title = "Add user to Project Owner role";
  } else if (role.id === "operation") {
    roleField = removeOperationsEmp;
    roleType = "FROM_OPERATION_DEPT";
    roleid = "operation";
    title = "Add user to Operations/Resourcing role";
  } else if (role.id === "finance") {
    roleField = removeFinanceEmp;
    roleType = "FROM_FINANCE_DEPT";
    roleid = "finance";
    title = "Add user to Finance/Billing/Accounting role";
  }

  //Remove

  const [
    removeEmployee,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(roleField, {
    onCompleted: (e) => {
      refetch();
    },
  });

  const removeEmployeeAccess = () => {
    removeEmployee({
      variables: {
        input: {
          vbeid: userId,
        },
      },
    });
  };

  //Enable and disable

  const [
    enableDisable,
    { loading: mutationLoad, error: mutationErrors, data: mutationdata },
  ] = useMutation(postCompanyInfo, {
    onCompleted: (e) => {
      companyRefetch();
    },
  });

  const handleEnableDisable = () => {
    enableDisable({
      variables: {
        input: {
          vbeid: userInfo.companyvbeid,
          projectowner: projectEnable,
        },
      },
    });
  };

  const handleExpand = () => {
    if (companyData?.queryCompanybyid?.projectowner === false) {
      setOpen(false);
    }
    setOpen(!open);
  };

  return (
    <React.Fragment>
      {mutationLoading || mutationLoad || mutationdata ? (
        <SimpleSnackbar type="loading" />
      ) : null}
      {mutationError || mutationErrors ? (
        <SimpleErrorSnackbar msg="Something Went Wrong" />
      ) : null}
      <>
        <TableRow className={classes.root} key={role.id}>
          <TableCell
            align="left"
            className={
              role.id === "project" &&
              companyData?.queryCompanybyid?.projectowner === false
                ? classes.disabledata
                : null
            }
          >
            {role.name}
          </TableCell>
          <TableCell
            align="left"
            className={
              role.id === "project" &&
              companyData?.queryCompanybyid?.projectowner === false
                ? classes.disabledata
                : null
            }
          >
            {role.description}
          </TableCell>
          <TableCell align="left">
            <Button
              className={classes.button}
              disabled={role.id === "project" ? false : true}
              onClick={() => handleEnableOpen()}
            >
              {" "}
              {role.id === "project"
                ? companyData?.queryCompanybyid?.projectowner
                  ? "Disable"
                  : "Enable"
                : "Enable"}
            </Button>
          </TableCell>

          <TableCell align="left">
            <IconButton
              aria-label="expand row"
              size="small"
              align="left"
              onClick={handleExpand}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          <TableCell
            align="left"
            className={
              role.id === "project" &&
              companyData?.queryCompanybyid?.projectowner === false
                ? classes.disabledata
                : null
            }
          >
            <Button
              className={classes.button}
              onClick={handleSwipperOpen}
              disabled={
                role.id === "project"
                  ? companyData?.queryCompanybyid?.projectowner === true
                    ? false
                    : true
                  : false
              }
            >
              Add
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
            className={classes.expandtable}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid
                container
                md={12}
                justifyContent="center"
                className={classes.collapseGrid}
              >
                <Grid item md={10}>
                  {/* <Grid container md={12}>
                    <Grid item md={12}>
                      <AddUser
                        refetch={refetch}
                        id={role.id}
                        userInfo={userInfo}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <p className={classes.boxheading}>Users of {role.name}</p>
                    </Grid>
                  </Grid> */}

                  <Paper elevation={2} className={classes.paper}>
                    <Table size="small" aria-label="purchases" key={role.id}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Name</TableCell>
                          <TableCell align="left">Email</TableCell>
                          <TableCell align="left">Action</TableCell>
                        </TableRow>
                      </TableHead>

                      {usersList?.getUsersbyCompany.map((userdata) => {
                        if (
                          role.id === roleid &&
                          userdata.role.includes(roleType)
                        ) {
                          return (
                            <>
                              <TableBody key={role.id}>
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    {userdata.name ? (
                                      userdata.name
                                    ) : (
                                      <div className={classes.yettoonboard}>
                                        Yet to Onboard
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>{userdata.emailid}</TableCell>
                                  <TableCell align="left">
                                    <div
                                      className={classes.spanbutton}
                                      onClick={() =>
                                        handleRemoveOpen(userdata.id)
                                      }
                                    >
                                      Remove
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </>
                          );
                        } else if (
                          role.id === roleid &&
                          userdata.role.includes(!roleType)
                        ) {
                          return (
                            <>
                              <TableBody>
                                <h4>No Data Found</h4>
                              </TableBody>
                            </>
                          );
                        } else return "";
                      })}
                    </Table>
                  </Paper>
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      </>

      {alertBox ? (
        <AlertBox
          // id={userId}
          open={alertBox}
          title="Remove User"
          onClose={handleRemoveClose}
          description="Are you sure you want to remove the user?"
          onConfirm={removeEmployeeAccess}
        />
      ) : null}

      {alertBoxEnable ? (
        <AlertBox
          open={alertBoxEnable}
          title="Project Owner"
          onClose={handleEnableClose}
          description={
            companyData?.queryCompanybyid?.projectowner
              ? "Are you sure you want to disable option ?"
              : "Are you sure you want to enable option ?"
          }
          onConfirm={handleEnableDisable}
        />
      ) : null}

      {swipperOpen ? (
        <AddSwiper
          open={swipperOpen}
          onClose={handleSwipperClose}
          refetch={refetch}
          id={role.id}
          userInfo={userInfo}
          title={title}
          type="role"
        />
      ) : null}
    </React.Fragment>
  );
}

export default function RoleManagement({ userInfo }) {
  const classes = useStyles();
  const roleData = require("./roletable.json");

  return (
    <div className={classes.table}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={11}>
          {" "}
          <h4
            style={{
              textAlign: "left",
              padding: "8px",
              margin: "0px",
              fontWeight: "500",
            }}
          >
            Role Management
          </h4>
        </Grid>

        <Grid item md={11} className={classes.entityinfo}>
          <Alert severity="info" className={classes.item}>
            Please add stakeholders from your organization who have role to play
            in the life cycle of a project. The life cycle of a project includes
            Supplier identification, Requirement drafting, Supplier
            shortlisting, SoW management, Purchase Order release, Invoice
            management and making payments against the project. Users specific
            to a project can be added into the project by the Project Owner.
          </Alert>
        </Grid>
        <Grid item md={11}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="center">Action</TableCell>
                  <TableCell align="left">Users</TableCell>
                  <TableCell align="center">Add User</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roleData?.map((role) => {
                  return <Row role={role} userInfo={userInfo} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
