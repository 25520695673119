import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";

import { addDocuments, addTag } from "./query";
import {
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import SimpleSnackbar from "../groups2.0/snackbar";
import SimpleErrorSnackbar from "../groups2.0/errorsnackbar";
import InputField from "../common/InputField";
import SelectField from "../common/SelectField";
import OutlinedButton from "../companies/company/OutlinedButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { getSigned, signed } from "../legalentity/query";
import UploadFile from "../legalentity/UploadFile";

const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    margin: theme.spacing(1),
    padding: theme.spacing(0.4),
  },
  heading: {
    fontSize: theme.spacing(1.6),
    fontWeight: 600,
  },

  field: {
    width: "100% !important",
    margin: theme.spacing(1),
  },
  errors: {
    color: "red !important",
    padding: "2px",
    fontSize: theme.spacing(1.4),
  },

  btn: {
    margin: "8px 0px 8px 16px !important",
  },
  cancelbtn: {
    marginRight: "8px !important",
  },
  formControl: {
    width: "100% !important",
  },
  container: {
    margin: theme.spacing(2, 0),
  },
}));

function AddDocument({ refetch, id, userInfo, onClose }) {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [docs, setDocs] = useState([]);

  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter Document Name")
      .min(2, "Minimum 2 Characters!")
      .required("Enter Name"),

    description: yup
      .string("Enter Value")
      .min(4, "Minimum 2 Characters!")
      .required("Please select document type "),

    // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    //   error = 'Invalid email address';
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      description: "",
      dropdown: "",
    },

    validationSchema: validationSchema,
  });

  const [
    addDocumentsToTable,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(addDocuments, {
    onCompleted: (e) => {
      refetch();
      onClose();
      formik.resetForm();
    },
    onError: (e) => {
      setError(e.graphQLErrors[0].message);
      formik.resetForm();
    },
  });

  const addDocumentsTable = (e) => {
    // e.preventDefault();

    addDocumentsToTable({
      variables: {
        input: {
          name: formik.values.name,
          type: formik.values.type,
          shortdescription: formik.values.description,
          action: true,
          attachment: uploadedFiles?.toString() || "",
        },
      },
    });
  };

  const type = [" Contract Resourcing Project", "Work Package Project"];
  let typeOptions = [];
  type.map((type) => {
    return typeOptions.push({ type: type, value: type });
  });

  const typeValue = ["BE1 ", "BE2", "BE3", "BE4"];
  let typeOptionsValues = [];
  typeValue.map((type) => {
    return typeOptionsValues.push({ type: type, value: type });
  });

  // files

  const [callAPi, setCallApi] = useState(false);
  const [statusMsg, setStatusMsg] = useState(false);
  const [message, setMessage] = useState("");

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [storeFile, setStoreFiles] = useState([]);

  const [signedUrl, { data: url, loading: getUrlLoading }] = useLazyQuery(
    getSigned,
    {
      onCompleted: (e) => {
        window.open(e.getSignedURL.signedurl, "_blank");
      },
    }
  );

  function CreateUrl(key, filetype) {
    signedUrl({
      variables: {
        input: {
          key: key,
          filetype: filetype,
        },
      },
    });
  }

  const [signedUrls, { loading }] = useMutation(signed, {
    onCompleted: (e) => {
      afterGettingSignedUrl(e);
    },
  });

  const afterGettingSignedUrl = (e) => {
    uploadFileToAxious(e.putSignedURL.signedurl, e.putSignedURL.key);
  };

  async function uploadFileToAxious(url, key) {
    let keySplit = key.split("/");

    await axios
      .request({
        method: "put",
        url: url,
        data: document.getElementById(keySplit[1]).files[0],
        onUploadProgress: (p) => {
          const percent = Math.round((p.loaded / p.total) * 100);
          setMessage("Uploading ..." + percent + "%");
        },
      })
      .then(function (response) {
        setStatusMsg(false);

        let newUrl = response.config.url.split("?")[0];
        let newFilepath = newUrl.substring(newUrl.lastIndexOf(".com") + 4);
        let filepath = newFilepath.substring(1);

        saveFile(key, keySplit[1]);
      });
  }

  const saveFile = (key, keySplit) => {
    let filesArray = uploadedFiles;
    filesArray.push(key);
    setUploadedFiles(filesArray);

    addDocumentsTable();
  };

  const handleFiles = (values, filetype) => {
    console.log("test");
    setStoreFiles((prevList) => [...prevList, values]);
  };

  const deleteAttachments = (index) => {
    let prevFiles = storeFile;
    setStoreFiles([
      ...prevFiles.slice(0, index),
      ...prevFiles.slice(index + 1),
    ]);
  };

  const uploadFile = () => {
    storeFile?.forEach((file) => {
      let fileName = file.split("\\").pop();
      putSignedUrl(fileName, "doc");
    });
  };

  const putSignedUrl = (fileName, filetype) => {
    signedUrls({
      variables: {
        input: {
          method: "put",
          filetype: filetype,
          filename: fileName,
          vbeid: userInfo?.companyvbeid,
        },
      },
    });
  };

  // let documnet = "attachment.klh/attcachment.jpg";
  // let result = documnet.split(".").pop().split(/\#|\?/)[0];
  // console.log("result", result);
  return (
    <>
      {mutationLoading || loading ? <SimpleSnackbar type="loading" /> : null}
      {mutationError ? <SimpleErrorSnackbar msg={error} /> : null}

      {/* <Grid item md={12}>
        {" "}
        <h4
          style={{
            textAlign: "left",
            padding: "0px 8px ",
            margin: "8px",
            fontWeight: "500",
            fontSize: "14px",
          }}
        >
          Add Tag
        </h4>
      </Grid> */}

      <Grid
        container
        md={12}
        direction="row"
        className={classes.container}
        spacing={2}
      >
        {/* <Paper elevation={2} className={classes.paper}> */}
        <Grid container md={5} className={classes.field}>
          <Grid item md={12}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputField
                label="Document Name"
                placeholder="Enter Document Name"
                required={false}
                value={formik.values.name}
                onChange={formik.handleChange("name")}
                onBlur={formik.handleBlur("name")}
              />
            </FormControl>
          </Grid>

          <Grid item md={12}>
            {formik.touched.name && formik.errors.name ? (
              <div className={classes.errors}>{formik.errors.name}</div>
            ) : null}
          </Grid>
        </Grid>

        <Grid container md={5} className={classes.field}>
          <Grid item md={11}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.formControl}
            >
              <SelectField
                label="Project Type "
                options={typeOptions}
                variant="outlined"
                required={true}
                fullWidth={true}
                value={formik.values.type}
                onChange={formik.handleChange("type")}
                onBlur={formik.handleBlur("type")}
                field="companypage"
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            {formik.touched.type && formik.errors.type ? (
              <div className={classes.errors}>{formik.errors.type}</div>
            ) : null}
          </Grid>
        </Grid>

        {/* 
        <Grid container md={2} className={classes.field}>
          <Grid item md={12}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.formControl}
            >
              <SelectField
                label="Type Value "
                options={typeOptionsValues}
                variant="outlined"
                required={true}
                fullWidth={true}
                value={formik.values.dropdown}
                onChange={formik.handleChange("dropdown")}
                onBlur={formik.handleBlur("dropdown")}
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            {formik.touched.dropdown && formik.errors.dropdown ? (
              <div className={classes.errors}>{formik.errors.dropdown}</div>
            ) : null}
          </Grid>
        </Grid> */}

        <Grid container md={10} className={classes.field}>
          <Grid item md={12}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputField
                label="Description"
                placeholder="Enter Description"
                disabled={formik.values.type.length !== 0 ? false : true}
                required={false}
                value={formik.values.description}
                onChange={formik.handleChange("description")}
                onBlur={formik.handleBlur("description")}
                multiline={true}
                rows={4}
              />
            </FormControl>
          </Grid>
          <Grid container md={10} className={classes.field}>
            {" "}
            <Grid item md={12}>
              {" "}
              <Grid container md={5}>
                <Grid item md={11.5}>
                  <Grid container className={classes.uploadDoc}>
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        className={classes.docs}
                      >
                        {storeFile?.length > 0 ? "Uploaded doc" : "Upload doc"}
                      </Typography>
                    </Grid>

                    <Grid item className={classes.cloudicon}>
                      <UploadFile
                        vbeid={userInfo?.companyvbeid}
                        // entity="userimage"
                        filetype="doc"
                        fext=""
                        // paramName="image"
                        inputid="doc"
                        // updateCacheMethod={saveFile}
                        buttonmessage={
                          storeFile?.length === 1 ? "file" : "icon"
                        }
                        type="icon"
                        page="docpage"
                        handleFiles={handleFiles}
                        callAPi={callAPi}
                        // handleType={handleType}
                        storeFile={storeFile}
                        icon={
                          <CloudUploadIcon
                            fontSize="small"
                            className={classes.uploadIcon}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {storeFile?.length !== 0
                ? storeFile?.map((value, index) => {
                    return (
                      <Grid
                        container
                        md={8}
                        component="Paper"
                        className={classes.docs}
                      >
                        <Grid item md={12}>
                          <List>
                            <ListItem key={value}>
                              <Grid item md={2} className={classes.pdf}>
                                <PictureAsPdfIcon />
                              </Grid>
                              <Grid item md={8}>
                                <ListItemText
                                  pathname={value}
                                  onClick={() => {
                                    CreateUrl(
                                      value,
                                      value?.split(".").pop().split(/\#|\?/)[0]
                                    );
                                  }}
                                  className={classes.fileName}
                                >
                                  {value?.split("\\").pop()}
                                </ListItemText>
                              </Grid>
                              <Grid item md={2}>
                                <DeleteOutlineIcon
                                  fontSize="small"
                                  onClick={() => deleteAttachments(index)}
                                  style={{
                                    color: "primary",
                                    cursor: "pointer",
                                    marginRight: "20%",
                                  }}
                                />
                              </Grid>
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          </Grid>

          <Grid item md={12}>
            {formik.touched.description && formik.errors.description ? (
              <div className={classes.errors}>{formik.errors.description}</div>
            ) : null}
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.btn}>
          <Grid item md={1} className={classes.cancelbtn}>
            <OutlinedButton label="Cancel" onClick={onClose} />
          </Grid>
          <Grid item md={1}>
            <OutlinedButton
              label="Add"
              type="contained"
              disabled={!(formik.dirty && formik.isValid)}
              onClick={storeFile.length !== 0 ? uploadFile : addDocumentsTable}
            />
          </Grid>
        </Grid>
        {/* </Paper> */}
      </Grid>
    </>
  );
}

export default AddDocument;
