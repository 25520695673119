import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  emptyMessage: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(10),
    color: "#999",
    fontStyle: "italic",
    fontSize: "0.85rem",
  },
}));

export default function EmptyMessageList({ type }) {
  const classes = useStyles();

  return (
    <Typography
      variant="body1"
      color="primary"
      className={classes.emptyMessage}
    >
      Add {type} to the List to keep track. You can add by using the search bar
      or the listing in the top menu bar.
    </Typography>
  );
}
