import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {  gql, useQuery, useMutation, useApolloClient } from '@apollo/client';
import { XGrid  } from '@material-ui/x-grid';
import { Icon } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import ErrorSnackbar from "./errorsnackbar";
import SimpleSnackbar from "./topicdeletesnackbar";
import DeleteIcon from '@material-ui/icons/Delete';
import { Waypoint } from 'react-waypoint';
import ListItem from '@material-ui/core/ListItem';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
     marginTop:"1%"
       },
       deleteicon:{
        cursor:"pointer"
    }
  }));

 

export default function TopicTable(){
    const classes = useStyles();
    const client = useApolloClient();
   // const [isReadOnly] = React.useState(true);
    const [limit, setLimit] = React.useState(10);
    const [defaultData,setDefaultData] = React.useState([]);
    let history = useHistory();

const deletetopic = gql`
mutation delete($input:id! ) {
  removetopic(input:$input) {
  id
  }
}`; 

const [removetopicmutation,{error: mutationError, data:removetopic }] =  useMutation(deletetopic,{onCompleted:(e)=>{UpdateDeleteTopicCache(e)}});

function handleDelete(e){
if(e.field==="action")
{
  var r = window.confirm("Are you sure you want to delete topic ?");
  if(r)
  {
  removetopicmutation(
          {
            variables:{
              input:{
                vbeid : e.id
                    }
                      }
          }
                     );
        }
          }    
  }

const listtopics=gql`
query listing($offset: Int, $limit: Int) { 
  listtopic(input:{offset: $offset, limit: $limit}) {
    total
    topics {
      alias
      id
      name
      type
    }
  }
}`;

const { loading, data,fetchMore} =  useQuery(listtopics,{variables:
  {
    offset: 0,
    limit: limit
}});

function UpdateDeleteTopicCache(e)
{
    const {listtopic} =  client.readQuery({query:listtopics,variables:{input:{}}});
    let listTopicCache = {...listtopic};
    listTopicCache.total = listTopicCache.total-1
    listTopicCache.topics = listTopicCache.topics.filter(topic =>topic.id!==e.removetopic.id)
   
    

    client.writeQuery({query:listtopics,
        data:{
          listtopic: listTopicCache
        },
        variables:{input:{}}});
}

  
    const columns = [
    
        { field: 'name', headerName: 'Topic Name',width:200,
        renderCell: (params) => (
            
          <ListItem style={{cursor:'pointer'}} key={params.getValue('id')} divider dense onClick={()=>{history.push("/topic/"+params.getValue('id'))}} >
            
          {params.value}
          
          </ListItem>)
    },
        { field: 'type', headerName: 'Type', width:200},
    
        
        {field:'action', headerName: 'Action' ,
        width: 200,
        renderCell: (params) => (
          <strong>
             
            <Icon className={classes.deleteicon}>
            <DeleteIcon />
            </Icon>
    
            </strong>
        )},
        
        ];



    return(
<div className={classes.root}>
<Grid container>
      <Grid item xs={3}>

      {mutationError && <ErrorSnackbar/>}
      {removetopic? <SimpleSnackbar/> : null}
      
      </Grid>
      </Grid>

      <XGrid 
 loading={loading}
  pagination={false}  
  sortable={true}    
 density="compact"       
 disableColumnMenu	={false}
 sortingOrder='asc'
  autoHeight={true}        
 autoPageSize={true}
 onCellClick={handleDelete}


      rows={loading ?defaultData:data.listtopic.topics} 
      
      columns={columns.map((column) => ({
        ...column,
        sortable: false,
      }))}
        /><Waypoint onEnter={()=>{

          if(data)
          {
             const currentLength = data.listtopic.topics.length;
            
             fetchMore({
             variables: 
               {
                 offset: currentLength,
                 limit:10
             }
          
           }).then(fetchMoreResult => {
             setDefaultData(data.listtopic.topics)
             setLimit(currentLength + fetchMoreResult.data.listtopic.topics.length);
             
           })
          }
          }}/>

</div>
    );
}