import {
  Avatar,
  Box,
  Divider,
  Grid,
  Typography,
  FormControl,
  InputBase,
  Button,
  TextField,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
} from "@material-ui/core";
import * as React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useMutation, useQuery } from "@apollo/client";
import { Paper } from "@material-ui/core";
import { useFormik } from "formik";
import { useParams, useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  ADD_TEAM_MEMBER,
  GET_PRODUCTS_BY_PROJECT,
  LIST_PROJECT_TEAM_MEMBERS,
  REMOVE_TEAM_MEMBER,
} from "../../queries/query";
import { Alert } from "@material-ui/lab";
import ViewVendorEvaluationTeamTable from "./evalutionTeamFormTable";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(3),
  },
  textFieldLabel: {
    fontSize: theme.spacing(1.8),
    marginTop: theme.spacing(2),
  },
  formControlclass: {
    width: "100%",
    textAlign: "left",
    minHeight: theme.spacing(55),
  },
  teamPaper: {
    width: "100%",
    padding: theme.spacing(2),
  },
  drawer: {
    padding: theme.spacing(2),
  },
}));

const validateTeamMember = (values) => {
  const errors = {};
  if (!values.personemailid) {
    errors.personemailid = "Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.personemailid)
  ) {
    errors.personemailid = "Invalid email address";
  }

  return errors;
};

function TeamMenu(props) {
  const [removeMember, { data, loading, error }] = useMutation(
    REMOVE_TEAM_MEMBER,
    {
      onCompleted: (e) => {
        console.log(e);
        props.refetch();
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  return (
    <IconButton
      disabled={loading}
      onClick={() => {
        console.log(props);
        removeMember({
          variables: { input: { vbeid: props.id, listvbeid: [props.pvbeid] } },
        });
      }}
      edge="end"
      aria-label="delete"
    >
      <DeleteIcon />
    </IconButton>
  );
}

export default function TeamEvaluation({ projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  const [state, setState] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  const { data } = useQuery(LIST_PROJECT_TEAM_MEMBERS, {
    variables: { input: { vbeid: id } },
  });

  const { data: productsData } = useQuery(GET_PRODUCTS_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id, type: "SUBMITTED" } },
  });

  let evaluationStarted = false;

  if (productsData && productsData.getProductsByProjectIDV1.length !== 0) {
    productsData.getProductsByProjectIDV1.forEach((product) => {
      if (product.role.includes("ADD_TO_EVALUATE")) {
        evaluationStarted = true;
      }
    });
  }

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Drawer anchor="right" open={state} onClose={setState}>
        <Grid container className={classes.drawer}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setState(false);
              }}
            >
              Close
            </Button>
          </Grid>

          <Grid item xs={12}>
            <ViewVendorEvaluationTeamTable person={selectedUser} />
          </Grid>
        </Grid>
      </Drawer>

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data
              ? data.getTeammembersByProjectIDV1.map((row) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {row.personname ? row.personname : row.personemailid}
                      </TableCell>

                      <TableCell>
                        {row.status[0] === "SUBMITTED_EVALUATION"
                          ? "Submitted"
                          : evaluationStarted
                          ? "In-Progress"
                          : "Pending"}
                      </TableCell>

                      <TableCell>
                        {" "}
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => {
                            if (row.status[0] === "SUBMITTED_EVALUATION") {
                              setSelectedUser(row.personvbeid);
                              setState(true);
                            }
                          }}
                          disabled={row.status[0] !== "SUBMITTED_EVALUATION"}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
