import * as React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";

import ViewSubmittedRequirement from "../project/viewSubmittedRequirement";
import ListQuestionare from "../project/listQuestionare";
//import { useStyles } from '../leads/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabs: {
    fontSize: "0.6rem",
  },
  noQuestionsListTextAlign: {
    fontSize: 13,
    fontWeight: "bold",
  },
}));

export default function RequirementTabs({
  submittedRequirement,
  lead,
  columns,
  submittedQuestions,
}) {
  const [value, setValue] = React.useState(0);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          className={classes.tabs}
          label="Requirement Description"
          {...a11yProps(0)}
        />
        <Tab
          className={classes.tabs}
          label="Screening Questions"
          {...a11yProps(1)}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        {submittedRequirement[0] !== undefined ? (
          <ViewSubmittedRequirement
            lead={true}
            submittedRequirement={submittedRequirement}
          />
        ) : null}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {submittedQuestions.length >= 1 ? (
          <ListQuestionare columns={columns} questionare={submittedQuestions} />
        ) : (
          <Typography
            varaint="caption"
            className={classes.noQuestionsListTextAlign}
          >
            No questions added by the client.
          </Typography>
        )}
      </TabPanel>
    </div>
  );
}
