import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import { getCustomersByCompanyId, removeCustomer } from "../../query";

import MainList from "../../../../groups2.0/MainList";
import AddCustomerForm from "../../shorts/AddCustomerForm";
import AddNewButton from "../../../../products/product2.0/AddNew/AddNewButton";
import VbridgeAlert from "../../../../../shared/alert";

import { useStyles } from "../../styles";

export default function CustomersList({ isReadOnly, vbeid, isAdmin }) {
  const client = useApolloClient();
  const classes = useStyles();

  const [checked, setChecked] = useState([]);
  //   const [customerid, setCustomerid] = useState("");
  const [values, setValues] = useState([]);
  const [checkValue, setCheckValue] = useState(false);

  let { id } = useParams();
  if (vbeid) {
    id = vbeid;
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const checkedValues = [];
    // setCustomerid(value.vbeid);

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    newChecked.map((value) => {
      return checkedValues.push(value.vbeid);
    });

    setChecked(newChecked);
    setValues(checkedValues);

    if (currentIndex === -1) {
      setCheckValue(true);
    } else if (currentIndex >= 1) {
      setCheckValue(true);
    } else if (newChecked.length < 1) {
      setCheckValue(false);
    }
  };

  const [
    removeCustomerMutation,
    { error: mutationError, data: customerremove, loading },
  ] = useMutation(removeCustomer, {
    onCompleted: (e) => {
      updateDeleteCache(e);
      setCheckValue(false);
    },
  });

  const deleteCustomer = (e) => {
    // e.preventDefault();

    removeCustomerMutation({
      variables: { input: { listvbeid: values } },
    });
  };

  const { data } = useQuery(getCustomersByCompanyId, {
    variables: { input: { vbeid: id } },
  });

  const updateDeleteCache = (e) => {
    let removeIds = [];
    e.removeCustomer.forEach((o) => {
      removeIds.push(o.id);
    });

    let listCustomerCache = data.customersByCompanyId.filter(
      (customer) => !removeIds.includes(customer.id)
    );

    client.writeQuery({
      query: getCustomersByCompanyId,
      data: {
        customersByCompanyId: listCustomerCache,
      },
      variables: { input: { vbeid: id } },
    });
  };

  return (
    <>
      {customerremove && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="Deleted Successfully "
        />
      )}

      {mutationError && (
        <VbridgeAlert
          severity="error"
          open={true}
          message="Something went wrong ... "
        />
      )}

      {loading && (
        <VbridgeAlert severity="info" open={true} message="Please wait" />
      )}

      <Grid
        container
        spacing={1}
        justify="flex-end"
        className={classes.buttonsDiv}
      >
        {isReadOnly ? null : (
          <Grid item>
            <AddCustomerForm />
          </Grid>
        )}

        {isReadOnly ? null : (
          <Grid item>
            <AddNewButton
              name="Remove Customer"
              handleClick={deleteCustomer}
              disabled={checkValue ? false : true}
              icon="remove"
            />
          </Grid>
        )}
      </Grid>

      <MainList
        vbeid={id}
        type="customers"
        tab="company"
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        onCheck={handleToggle}
        checkedValues={values}
      />
    </>
  );
}
