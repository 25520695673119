import { Tooltip, withStyles } from "@material-ui/core";
import clsx from "clsx";

import { useStyles } from "./styles";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#eee",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function LinkCategory({ title, name, onClick, type }) {
  const classes = useStyles();

  const groupBadge = clsx({
    [classes.typeBadge]: true,
    [classes.groupType]: true,
  });

  const categoryBadge = clsx({
    [classes.typeBadge]: true,
    [classes.categoryType]: true,
  });

  return (
    <LightTooltip title={title}>
      <div
        className={type === "group" ? groupBadge : categoryBadge}
        onClick={onClick}
      >
        {name}
      </div>
    </LightTooltip>
  );
}
