import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useQuery } from "@apollo/client";

import {
  Avatar,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";

import { useStyles } from "../home/styles";

import {
  GET_COMPANIES_BY_PROJECT,
  GET_PRODUCTS_BY_PROJECT,
  GET_PROJECT,
} from "../project3.0/queries/query";

import { VBRIDGEHUB_MEDIAURL } from "../../../constants";

import VendorActions from "../project3.0/buyer/vendors/VendorActions";

import DiscussionCard from "../messaging2.0/discussionCard";
import Messaging from "../messaging2.0/messaging";

import PrimaryButton from "./PrimaryButton";

import LoadingResult from "../../shared/LoadingResult";
import CompanyMessaging from "../messaging2.0/CompanyMessaging";

export default function ProjectSlider({
  open,
  projectVbeid,
  onClose,
  showAll,
  userCompany,
  project,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [messageContent, setMessageContent] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [selectedDetails, setSelectedDetails] = useState([]);

  let queryName = GET_PRODUCTS_BY_PROJECT;
  let dataName = "getProductsByProjectIDV1";

  if (project.type === "service-timematerial") {
    queryName = GET_COMPANIES_BY_PROJECT;
    dataName = "listCompaniesByProjectID";
  }

  const { data: projectData } = useQuery(GET_PROJECT, {
    variables: { input: { vbeid: projectVbeid } },
  });

  const { data: products, refetch } = useQuery(queryName, {
    variables: { input: { vbeid: projectVbeid } },
    fetchPolicy: "no-cache",
  });

  const showMessages = (productVbeid, rowDetails) => {
    setSelectedSupplier(productVbeid);
    setSelectedDetails(rowDetails);
    setMessageContent(true);
  };

  const onBackClick = () => {
    setMessageContent(false);
    setSelectedDetails([]);
    setSelectedSupplier("");
  };

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={open}
        className={
          showAll ? classes.productDetailDiv : classes.projectDetailSlider
        }
        onClose={onClose}
      >
        <Card square className={classes.productDetailCard} elevation={0}>
          {projectData && (
            <div style={{ margin: "8px", fontSize: "1.2rem" }}>
              {projectData.getProjectByIDV1.name}
            </div>
          )}

          <Divider />

          <Grid container>
            {showAll ? (
              <CompanyMessaging
                data={products}
                id={projectVbeid}
                dataName={dataName}
              />
            ) : messageContent ? (
              <Grid item xs={12}>
                <div
                  style={{
                    margin: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {selectedDetails.productname}
                  <PrimaryButton
                    label="Back to Products List"
                    variant="outlined"
                    onClick={onBackClick}
                  />
                </div>

                <DiscussionCard
                  productId={selectedSupplier}
                  projectId={projectVbeid}
                  type="question"
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <div style={{ margin: "8px" }}>Selected Products</div>

                <List dense={true}>
                  {products ? (
                    products[dataName].length !== 0 ? (
                      products[dataName].map((product) => {
                        return (
                          <ListItem key={product.id}>
                            <ListItemAvatar style={{ margin: "0 16px" }}>
                              <Avatar
                                variant="square"
                                src={VBRIDGEHUB_MEDIAURL + "/" + product.image}
                                className={classes.imglarge}
                              >
                                :(
                              </Avatar>
                            </ListItemAvatar>

                            <div className={classes.productDetails}>
                              <Typography
                                variant="BUTTON TEXT"
                                component="h2"
                                className={classes.productName}
                                onClick={() => {
                                  history.push(
                                    "product/" + product.productvbeid
                                  );
                                }}
                              >
                                {product.productname || "-"}
                              </Typography>

                              <Typography
                                variant="BUTTON TEXT"
                                component="h2"
                                className={classes.companyName}
                                onClick={() => {
                                  if (userCompany === product.id) {
                                    history.push(
                                      "mycompany/" + product.companyvbeid
                                    );
                                  } else
                                    history.push(
                                      "company/" + product.companyvbeid
                                    );
                                }}
                              >
                                <span className={classes.bySubText}>by </span>
                                {product.companyname || "-"}
                              </Typography>
                            </div>

                            <ListItemSecondaryAction>
                              <VendorActions
                                refetch={refetch}
                                id={product.vbeid}
                                pvbeid={product.productvbeid}
                                messageOption={true}
                                onMessageClick={() =>
                                  showMessages(product.productvbeid, product)
                                }
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          margin: "8px",
                          color: "#999",
                          fontStyle: "italic",
                        }}
                      >
                        No products are added to this project
                      </div>
                    )
                  ) : (
                    <LoadingResult />
                  )}
                </List>
              </Grid>
            )}
          </Grid>
        </Card>
      </SwipeableDrawer>
    </>
  );
}
