import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {Grid, Box} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  square: {
    width: 700,
    height: 100
  },
  attchments: {
    width: 400,
    height: 20
  },

  spacing: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(10)
  },
  attachmentsAndButtons: {
    
    paddingLeft: theme.spacing(10)
  }
}));

export default function ProjectRequirementSkeleton() {
  const classes = useStyles();

  return (
    <Box m={5}>
    <Grid container md={12} spacing={2}>

        <Grid item md={12}>
        <div className={classes.spacing}>
          <Skeleton variant="rect" className={classes.square} />
        </div>
        </Grid>
        
        </Grid>
 
 <div className={classes.attachmentsAndButtons}>
        <div className={classes.spacing}>
          <Skeleton variant="rect" className={classes.attchments} />
        </div>

        <div className={classes.spacing}>
          <Skeleton variant="rect" className={classes.attchments} />
        </div>

    </div>

    </Box>
  );
}
