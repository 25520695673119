import { CircularProgress, Grid } from "@material-ui/core";

import { useQuery } from "@apollo/client";

import { GET_PROJECT } from "../../../queries/query";

import { useParams } from "react-router-dom";

import ProvidersList from "./ProvidersList";
import NextPrevButtons from "./NextPrevButtons";
import PageButtons from "../WorkPackage/PageButtons";

export default function RecommendedProviders() {
  const { id } = useParams();

  const { data } = useQuery(GET_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id } },
  });

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          {data ? <ProvidersList projectData={data} /> : <CircularProgress />}
        </Grid>
      </Grid>

      <div style={{ marginTop: "16px" }}>
        {data &&
          (data.getProjectByIDV1.type === "service-workpackage" ? (
            <PageButtons
              nextDisabled={false}
              prevDisabled={false}
              nextLocation="team"
              prevLocation="recommended"
            />
          ) : (
            <NextPrevButtons
              nextDisabled={false}
              prevDisabled={false}
              nextLocation="qna"
              prevLocation="recommended"
            />
          ))}
      </div>
    </div>
  );
}
