import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing(1),
  },
  textFieldLabel: {
    fontSize: theme.spacing(1.8),
    marginTop: theme.spacing(2),
    textAlign: "left",
  },
  formControlclass: {
    width: "100%",
    textAlign: "left",
  },
  templateButton: {
    marginRight: theme.spacing(2),
  },
  selectedTemplateBtn: {
    marginRight: theme.spacing(2),
  },
  noTemplate: {
    border: "2px solid silver",
    borderStyle: "dotted",
  },
  templatelistPaper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  for: {
    "& .MuiFormControlLabel-label": {
      fontSize: theme.spacing(1.5),
    },
  },
  template: {
    textAlign: "left",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: theme.spacing(40),
    minHeight: theme.spacing(20),
  },
  table: {
    maxHeight: theme.spacing(10),
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    marginTop: theme.spacing(1.1),
    top: "100%",
    left: "30%",
  },
  wrapper: {
    position: "relative",
    marginTop: theme.spacing(0.5),
  },
  preview: {
    padding: theme.spacing(2),
    width: theme.spacing(120),
  },
  otherTemplateRoot: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing(1),
    //marginLeft: theme.spacing(-5),
    padding: theme.spacing(0, 2),
  },
  reqRoot: {
    display: "flex",
    flexWrap: "wrap",
  },
  reviewReqRoot: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "80px",
  },
  templateRoot: {
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(1, 3),
  },
  otherTemplateReviewRoot: {
    display: "flex",
    flexWrap: "wrap",
  },
  reviewRoot: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "80px",
  },
  reqViewRoot: {
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(3),
  },
  paper: {
    width: "100%",
  },
  emptyText: {
    color: "#999",
    fontStyle: "italic",
  },
  requirementForm: {
    backgroundColor: "#eee",
    padding: theme.spacing(1),
  },
  mainContainer: {
    margin: theme.spacing(1),
  },
  sectionTitle: {
    fontSize: "0.9rem !important",
    fontWeight: "600 !important",
  },
  subSection: {
    padding: theme.spacing(0, 3),
  },
  newSection: {
    marginTop: theme.spacing(3),
  },
  jdSection: {
    marginBottom: "12px",
    padding: theme.spacing(0, 2),
  },
  interSection: {
    marginTop: "12px !important",
  },
  subSectionTitle: {
    fontSize: "0.8rem !important",
    fontWeight: "bold !important",
  },
  subSectionValue: {
    fontWeight: "normal",
    fontSize: "0.75rem",
    textTransform: "capitalize",
  },
  fileSubSectionValue: {
    fontWeight: "normal",
    fontSize: "0.75rem",
    textTransform: "capitalize",
    cursor: "pointer",
    color: "#4179BD",
  },
  spanText: {
    fontStyle: "italic",
    textTransform: "none",
  },
  actionDrawer: {
    "& .MuiDrawer-paper": {
      width: "60%",
      padding: "8px",
    },

    "& .MuiAvatar-square": {
      width: "70px",
      height: "70px",
      "& img": {
        objectFit: "contain",
      },
    },

    "& .makeStyles-companyDetailsDiv-736": {
      margin: 0,
    },
  },
}));
