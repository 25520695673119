import { useHistory } from "react-router";

import { useQuery } from "@apollo/client";

import { Avatar, Paper, Typography } from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { VBRIDGEHUB_MEDIAURL } from "../../../constants";

import { getProductById } from "../products/product2.0/query";

import { useStyles } from "./styles";

import LinkCategory from "../groups2.0/LinkCategory";

import ResultLoading from "./ResultLoading";
import ListMultiCategory from "../common/ListMultiCategory";
import ViewMoreButton from "../common/ViewMoreButton";
import PrimaryButton from "../common/PrimaryButton";

export default function SearchResultCard({
  product,
  type,
  onProductCardClick,
  checked,
  onClick,
  evaluateAccess,
  userCompany,
  onRemove,
  concierge,
  addLoading,
  selected,
  toggleValue,
  mainDrawerClick,
}) {
  const classes = useStyles();
  const history = useHistory();

  let productData = [];

  const { data, loading } = useQuery(getProductById, {
    variables: { input: { vbeid: product } },
  });

  const handleClick = () => {
    onProductCardClick(productData);
  };

  const onProductSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onClick(productData);
  };

  if (data) {
    productData = data.productById;
  }

  if (loading) {
    return <ResultLoading />;
  }

  return (
    <Paper elevation={3} square={true} className={classes.resultCard}>
      {productData ? (
        <>
          {evaluateAccess && productData.vbeid ? (
            <div
              style={{ textAlign: "right", marginBottom: "-4px" }}
              onClick={onProductSelect}
            >
              {checked ? (
                <CheckCircleIcon style={{ fontSize: "1rem" }} />
              ) : (
                <RadioButtonUncheckedIcon style={{ fontSize: "1rem" }} />
              )}
            </div>
          ) : (
            <div
              style={{ textAlign: "right", marginTop: "16px" }}
              onClick={onProductSelect}
            ></div>
          )}

          <div className={classes.resultDiv}>
            <Avatar
              variant="square"
              alt={productData.name}
              src={VBRIDGEHUB_MEDIAURL + "/" + productData.image}
              className={classes.productImage}
            >
              <CancelPresentationIcon />
            </Avatar>

            <div className={classes.productDetails}>
              <Typography
                variant="BUTTON TEXT"
                component="h2"
                className={classes.productName}
                onClick={(e) => {
                  mainDrawerClick(e, "close");

                  history.push("product/" + productData.vbeid);
                }}
              >
                {productData.name || "-"}
              </Typography>

              <Typography
                variant="BUTTON TEXT"
                component="h2"
                className={classes.companyName}
                onClick={(e) => {
                  mainDrawerClick(e, "close");

                  if (userCompany === productData.companyvbeid) {
                    history.push("mycompany/" + productData.companyvbeid);
                  } else history.push("company/" + productData.companyvbeid);
                }}
              >
                {productData.companyvbeid && (
                  <span className={classes.bySubText}> by </span>
                )}
                {productData.companyname || "-"}
              </Typography>
            </div>
          </div>

          {productData && productData?.topiclist ? (
            <div className={classes.categoryBadge}>
              <ListMultiCategory
                data={productData?.topiclist}
                title="Visit Category Page"
                type="group"
                length={1}
                typeValue={toggleValue}
              />

              <div style={{ marginTop: "-4px" }}>
                {productData.topiclist?.length > 1 ? (
                  <ViewMoreButton
                    length={1}
                    data={productData?.topiclist}
                    categoriesCount={productData?.topiclist.length - 1}
                    typeValue={toggleValue}
                  />
                ) : null}
              </div>
            </div>
          ) : (
            <div className={classes.categoryBadge}>
              <LinkCategory name="-" type="topic" />
            </div>
          )}

          <div className={classes.location}>
            {productData.companylocation || "-"}
          </div>

          <div
            className={classes.productDesc}
            onClick={productData.vbeid ? handleClick : null}
          >
            {productData.shortdescription || "-"}
          </div>

          {type === "project" && (
            <div style={{ display: "flex" }}>
              {concierge ? (
                <PrimaryButton
                  size="small"
                  variant="contained"
                  label="Select Supplier"
                  onClick={onRemove}
                  disabled={addLoading || selected}
                />
              ) : (
                <PrimaryButton
                  size="small"
                  variant="outlined"
                  label="Remove Supplier"
                  onClick={onRemove}
                />
              )}
            </div>
          )}
        </>
      ) : null}
    </Paper>
  );
}
