import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
  },
  responsePaper: {
    width: "100%",
  },
  tabname: {
    fontSize: theme.spacing(1.2),
  },
  tableIndexCell: {
    width: theme.spacing(20),
    fontWeight: "bold",
  },
  hidecell: {
    display: "none",
  },
  heading: {
    padding: theme.spacing(2),
  },
  emptyText: {
    color: "#999",
    fontStyle: "italic",
    marginTop: theme.spacing(1),
  },
  evaluateRoot: {
    padding: theme.spacing(3),
  },
  productNameCell: {
    textAlign: "center",
  },
}));
