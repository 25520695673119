import { gql } from "@apollo/client";


//know authorisation
export const userPermission = gql`
query identity($input:personinput!) {
  amiauthorized (input:$input){
    status
  }
}
`;

//get user projects
export const userProjects = gql`
  query projects {
    listProjectByPersonID {
      id
      name
      description
      startdate
      enddate
      vbeid
      createdon
      addedproducts
      invitedproducts
      approvedproducts
      personcount
      status
      createdby
      projectstate
    }
  }
`;

///get project information
export const projectbyid = gql`
  query project($input: id!) {
    getProjectByID(input: $input) {
      name
      id
      description
      startdate
      enddate
      timeline
      createdon
      createdby
    }
  }
`;


//user information
export const userInfoQuery = gql`
  query userinfo {
    getLoginUserInfo(input: {}) {
      vbeid
      name
      role
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
    }
  }
`;


//list team members
export const teamMembers = gql`
  query teaminfo($input: projectinput!) {
    getMembersByProjectID(input: $input) {
      name
      personname
      personvbeid
      image
      status
      id
      vbeid
      relationvbeid
      updatedon
      personemailid
    }
  }
`;


///Create Project Mutation
export const postproject = gql`
  mutation project($input: projectinput!) {
    addProject(input: $input) {
      name
      startdate
      enddate
      description
      id
    }
  }
`;

///update Project Mutation
export const postupdateproject = gql`
  mutation project($input: projectinput!) {
    updateProject(input: $input) {
      name
      description
      startdate
      enddate
      id
    }
  }
`;

//signed url for uploading the supporting doc's
export const signed = gql`
  mutation upload($input: projectinput!) {
    signedURLforProjectSupportDoc(input: $input) {
      signedurl
      id
      path
      name
    }
  }
`;


//adding Requirement
export const addrequirement = gql`
  mutation requirement($input: projectinput!) {
    addRequirement(input: $input) {
      id
      attachments
      longdescription
      status
    }
  }
`;


//Updating Requirement
export const updaterequirement = gql`
  mutation updaterequirement($input: projectinput!) {
    updateRequirement(input: $input) {
      id
      attachments
      longdescription
      status
    }
  }
`;



//listing Requirement
export const listrequirement = gql`
query listrequire ($input: projectinput!){
  listsupportDoc(input: $input) {
  id
  longdescription
  status
  attachments
  }
  }
`;

/////delete doc mutation
export const deletedoc = gql`
  mutation update($input: projectinput!) {
    deleteDeclarationDoc(input: $input) {
      id
    }
  }
`;


////delete Mutation for removing vendor
export const deleteproduct = gql`
  mutation delete($input: projectinput!) {
    removeMembers(input: $input) {
      name
      id
    }
  }
`;

///list products by id details
export const productsByProjectId = gql`
  query productsinformation($input: projectinput!) {
    getProductsByProjectID(input: $input) {
      vbeid
      image
      description
      productname
      productvbeid
      companyvbeid
      companyname
      id
      role {
        vbeid
        staus
        createdby
        createdon
        remarks
      }
      productdescription
    }
  }
`;


///Add Team member Mutation
export const postperson = gql`
  mutation list($input: projectinput!) {
    addPersonToProject(input: $input) {
      id
      vbeid
      personname
      personvbeid
      updatedon
      personemailid
    }
  }
`;

////delete Team Member Mutation
export const deleteperson = gql`
  mutation delete($input: projectinput!) {
    removeMembers(input: $input) {
      name
      id
    }
  }
`;


///Submitting the Request
export const postrequest = gql`
mutation request($input:projectinput! ) {
  requestToProductVendor(input:$input) {
        id
        vbeid
}
}`;


///Add Question Mutation
export const postquestion = gql`
  mutation project($input: projectinput!) {
    addDeclarationQuestion(input: $input) {
      id
      question
      vbeid
      option
    }
  }
`;

///Update Question Mutation
export const updatequestion = gql`
  mutation project($input: projectinput!) {
    updateDeclarationQuestion(input: $input) {
      id
      question
      vbeid
      option
    }
  }
`;

///Delete Question Mutation
export const deletequestion = gql`
  mutation project($input: projectinput!) {
    deleteDeclarationQuestion(input: $input) {
      status
      id
    }
  }
`;

//get questions
export const getQuestions = gql`
  query questions($input: projectinput!) {
    listDeclarationQuestion(input: $input) {
      question
      id
      option
      type
      vbeid
      status
    }
  }
`;

//list Questionare
export const getQuestionsandAnswers = gql`
  query questions($input: projectinput!) {
    listDeclarationQuestionAnswer(input: $input) {
      question
      id
      option
      type
      vbeid
      status
      response
      createdon
      ansstatus
    }
  }
`;

//Submit Project API for scenario 1
export const submitProject = gql`
  mutation submit($input: projectinput!) {
    submitProject(input: $input) {
      status
    }
  }
`;


//list proposal
export const listproposal = gql`
  query proposal($input: projectinput!) {
    listProposal(input: $input) {
        attachments
        status
        longdescription
        id
    }
  }
`;
