import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  imglarge: {
    width: theme.spacing(5),
    height: theme.spacing(5),

    "& img": {
      objectFit: "contain",
    },
  },
  textFieldLabel: {
    fontSize: theme.spacing(1.8),
    marginTop: theme.spacing(2),
    textAlign: "left",
  },
  message: {
    fontStyle: "italic",
    color: "#999",
    fontSize: "0.8rem",
  },
  root: {
    marginLeft: "80px",
  },
  conciergeRoot: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(-5),
  },
  formControlclass: {
    width: "100%",
    textAlign: "left",
    maxHeight: theme.spacing(30),
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    marginTop: theme.spacing(1),
    left: "90%",
  },
  mainForm: {
    minHeight: theme.spacing(52),
  },
  startMessage: {
    border: "2px solid silver",
    borderStyle: "dotted",
    padding: theme.spacing(3),
  },
  tab: {
    textTransform: "none",
  },
  loading: {
    margin: "35%",
  },
}));
