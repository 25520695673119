import { useState } from "react";

import { useQuery } from "@apollo/client";
import { TableCell } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { GET_ALL_ITEM_VALUES } from "../../queries/query";

export default function EvaluateSummaryItemValue({
  item,
  product,
  sid,
  meta,
  templateType,
  sectionMeta,
  itemData,
  getSectionValue,
}) {
  const { id } = useParams();

  const { data } = useQuery(GET_ALL_ITEM_VALUES, {
    variables: {
      pinput: {
        sectionid: sid,
        projectid: id,
        productvbeid: product.productvbeid,
      },
    },
  });

  const getValues = (values, itemId, productId) => {
    let totalScore = 0;
    let averageScore = 1;

    if (values.length !== 0) {
      const result = values.filter(
        (value) => value.itemid === itemId && value.productvbeid === productId
      );

      result.forEach((value) => {
        totalScore = totalScore + parseInt(value.value);
      });

      averageScore = totalScore / result.length;

      return averageScore || {};
    } else return {};
  };

  const getItemValues = () => {
    var values = getValues(
      data.getEvaluationTempItemvalue,
      item.id,
      product.productvbeid
    );

    var input = {
      itemid: item.id,
      sectionid: sid,
      projectid: id,
      productvbeid: product.productvbeid,
      attachment: "",
      attachmentfilename: "",
      timestamp: null,
      value: values,
      remarks: "",
    };

    input = { ...input, ...values };
    delete input.__typename;

    return getItem(product.productvbeid, item.id, item.type, item.id, meta, {
      ...input,
    });
  };

  const getItem = (pid, key, type, id, metadata, values) => {
    return (
      <TableCell style={{ textAlign: "center" }}>
        <div>{values.value}</div>
      </TableCell>
    );

    // switch (type) {
    //   case "text_field":
    //     return (
    //       <TableCell>
    //         <RTextItem
    //           key={key}
    //           id={id}
    //           sid={sid}
    //           productId={pid}
    //           type={templateType}
    //           metadata={metadata}
    //           values={values}
    //           hidetitle={true}
    //           refetch={refetch}
    //         />
    //       </TableCell>
    //     );

    //   case "text_area":
    //     return (
    //       <TableCell>
    //         <RTextAreaItem
    //           rows={4}
    //           id={id}
    //           sid={sid}
    //           productId={pid}
    //           type={templateType}
    //           metadata={metadata}
    //           values={values}
    //           hidetitle={true}
    //           refetch={refetch}
    //         />
    //       </TableCell>
    //     );

    //   case "multi_choice":
    //     return (
    //       <TableCell>
    //         <RSelectItem
    //           id={id}
    //           sid={sid}
    //           productId={pid}
    //           type={templateType}
    //           metadata={metadata}
    //           values={values}
    //           hidetitle={true}
    //         />
    //       </TableCell>
    //     );

    //   case "currency":
    //     return (
    //       <TableCell>
    //         <RCurrencyItem
    //           id={id}
    //           sid={sid}
    //           productId={pid}
    //           type={templateType}
    //           metadata={metadata}
    //           values={values}
    //           hidetitle={true}
    //         />
    //       </TableCell>
    //     );

    //   case "attachment":
    //     return (
    //       <TableCell>
    //         <RFileItem
    //           id={id}
    //           sid={sid}
    //           productId={pid}
    //           type={templateType}
    //           metadata={metadata}
    //           values={values}
    //           hidetitle={true}
    //         />
    //       </TableCell>
    //     );

    //   case "date":
    //     return (
    //       <TableCell>
    //         <RDateItem
    //           id={id}
    //           sid={sid}
    //           productId={pid}
    //           type={templateType}
    //           metadata={metadata}
    //           values={values}
    //           hidetitle={true}
    //         />
    //       </TableCell>
    //     );

    //   case "number":
    //     return (
    //       <TableCell>
    //         <RNumberItem
    //           id={id}
    //           sid={sid}
    //           productId={pid}
    //           type={templateType}
    //           metadata={metadata}
    //           values={values}
    //           hidetitle={true}
    //         />
    //       </TableCell>
    //     );

    //   default:
    //     break;
    // }
  };

  return (
    <>{data && data.getEvaluationTempItemvalue ? getItemValues() : null}</>
    // <>{itemData ? getItemValues() : null}</>
  );
}
