import gql from "graphql-tag";

export const GET_MILESTONES = gql`
  query getMilestones($input: projectinputV1!) {
    wp_listWorkPackageByStatus(input: $input) {
      vbeid
      PK
      SK
      buyerid
      vendorid
      engagementStartDate
      engagementEndDate
      remarks
      data
      status
      createdon
      createdby
      updatedon
      updatedby
    }
  }
`;

export const UPDATE_MILESTONE_STATUS = gql`
  mutation updateStatus($input: projectinputV1!) {
    wp_updateWorkPackageStatus(input: $input) {
      responsestatus
    }
  }
`;

export const GET_MILESTONES_BY_STATUS = gql`
  query getMilestonesByStatus($input: projectinputV1!) {
    wp_listWorkPackageByStatus(input: $input) {
      vbeid
      PK
      SK
      buyerid
      vendorid
      engagementStartDate
      engagementEndDate
      remarks
      data
      status
      createdon
      createdby
      updatedon
      updatedby
      companyname
      projectName
      projectOwner
      currency
      podate
      ponumber
    }
  }
`;
