import React from "react";

import {
  Toolbar,
  Typography,
  SwipeableDrawer,
  ClickAwayListener,
  AppBar,
  Button,
} from "@material-ui/core";

import ForumIcon from "@material-ui/icons/Forum";
import ChatIcon from "@material-ui/icons/Chat";

import DiscussionCard from "../../messaging2.0/discussionCard";
import { useStyles } from "./styles";

export default function DiscussionForum({
  id,
  name,
  lead,
  productId,
  projectId,
}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  //console.log(name)

  function openDiscussions(e) {
    setOpen(true);
  }

  function closeDiscussion(e) {
    setOpen(false);
  }

  return (
    <>
      <Button
        className={classes.buttonStyle}
        variant="contained"
        size="small"
        color="primary"
        onClick={openDiscussions}
        startIcon={lead ? <ChatIcon /> : null}
      >
        Message Client
      </Button>

      <SwipeableDrawer anchor="right" open={open}>
        <div className={classes.discussionBody}>
          <AppBar position="fixed" className={classes.appSliderBar}>
            <Toolbar variant="dense">
              <ForumIcon color="primary" className={classes.icon2} />

              <Typography variant="button" display="block" color="primary">
                {name}
              </Typography>
            </Toolbar>
          </AppBar>

          <ClickAwayListener onClickAway={closeDiscussion}>
            <div className={classes.drawer}>
              <DiscussionCard
                name={name}
                productId={productId}
                projectId={projectId}
                buttonvbeid={projectId}
                type="request"
              />
            </div>
          </ClickAwayListener>
        </div>
      </SwipeableDrawer>
    </>
  );
}
