import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import MessageIcon from "@material-ui/icons/Message";
import { Button, Drawer, Grid, Typography } from "@material-ui/core";
import DiscussionCard from "../../../messaging2.0/discussionCard";
import PrimaryButton from "../../../common/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  reponsePaper: {
    width: "100%",
  },
  tab: {
    width: theme.spacing(30),
  },
  heading: {
    padding: theme.spacing(2),
  },
  label: {
    "& .MuiFormControlLabel-label": {
      fontSize: theme.spacing(1.2),
    },
  },
  drawer: {
    width: "90%",
  },
}));

export default function MessageWindow(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(false);

  return (
    <>
      <Drawer anchor="right" open={state} onClose={setState}>
        <Grid container className={classes.heading}>
          <Grid item xs={10}>
            <Typography variant="h5">{props.productname}</Typography>
          </Grid>

          <Grid item xs={2}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                setState(false);
              }}
            >
              Close
            </Button>
          </Grid>

          <Grid item xs={12}>
            <DiscussionCard
              productId={props.productId}
              projectId={props.id}
              type="question"
            />
          </Grid>
        </Grid>
      </Drawer>

      <PrimaryButton
        label="Message Supplier"
        variant="outlined"
        size="small"
        startIcon={<MessageIcon />}
        onClick={() => {
          setState(true);
        }}
      />

      {/* <Button
        startIcon={<MessageIcon />}
        size="small"
        color="secondary"
        style={{ fontSize: "10px" }}
        onClick={() => {
          setState(true);
        }}
      >
        Message Supplier
      </Button> */}
    </>
  );
}
