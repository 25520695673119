import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    backgroundColor: "#232961",
    zIndex: "999",
  },
  servicesAppBar: {
    backgroundColor: "#8C2785",
  },
  appBarSingle: {
    height: "50px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 16px",
  },
  logo: {
    marginRight: theme.spacing(2),
    textAlign: "left",
    width: "10%",
    cursor: "pointer",
  },
  singleDiv: {
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  menuBox: {
    overflow: "auto",
    maxHeight: theme.spacing(45),
  },
  logoImg: {
    width: "100%",
  },
  singleLogo: {
    width: "10%",
    margin: "10px 16px",
  },
  mainMenu: {
    overflow: "hidden",
  },
  search: {
    width: "30%",
  },
  searchTitle: {
    textAlign: "left",
    fontSize: "0.9rem",
    fontWeight: "500",
    marginBottom: "8px",
    color: "#4179BD",
  },
  searchBoxDiv: {
    margin: theme.spacing(0, 3),
  },
  searchTypeForm: {
    width: "95%",

    "& svg": {
      fontSize: "18px",
    },
  },

  radioButtonLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.85rem",
    },
    "& .MuiIconButton-label svg": {
      width: "20px",
      height: "20px",
    },
  },

  radioButtonHelp: {
    "& span": {
      textTransform: "none",
      fontSize: "8px",
      padding: "0px",
    },
  },

  resultList: {
    minWidth: theme.spacing(60),
    maxWidth: theme.spacing(90),
  },
  indextext: {
    margin: theme.spacing(0.5, 0, 0, 0),
    textAlign: "right",
    fontStyle: "italic",
    fontSize: theme.spacing(1.2),
    textTransform: "capitalize",
  },
  companyText: {
    margin: theme.spacing(0.5, 0.5, 0, 0),
    textAlign: "right",
    fontStyle: "italic",
    fontSize: theme.spacing(1.2),
    textTransform: "capitalize",
  },
  avatar: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  searchResultItem: {
    padding: theme.spacing(0.5, 0.5, 1, 0.5),
    "& .MuiListItemAvatar-root": {
      minWidth: "36px",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  linkButton: {
    textTransform: "capitalize",
    color: "white",
    margin: "0 24px",
  },

  dropdownMenu: {
    marginRight: "16px",
    display: "flex",
  },
  headerSearch: {
    marginLeft: theme.spacing(10),
    width: "600px",
  },

  all: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginLeft: theme.spacing(3),
  },

  helpdiv: {
    padding: "12px  20px 2px 0px",
  },
  noResultsText: {
    fontStyle: "italic",
    color: "#999",
    fontSize: "0.9rem",
  },
  resultMainDiv: {
    margin: theme.spacing(0.5, 0),
  },
  resultListMain: {
    margin: theme.spacing(1),
  },
  resultListDiv: {
    maxHeight: "250px",
    overflow: "auto",
    margin: theme.spacing(1, 0.5, 0),
  },
  searchAvatar: {
    width: "30px",
    height: "30px",
  },
  listItemText: {
    "& span": {
      fontSize: "0.8rem",
    },
    "& .MuiListItemText-secondary": {
      fontSize: "0.75rem",
    },
  },
  listItemStyle: {
    display: "block",
    width: "100%",
  },
  listItemTextMain: {
    display: "flex",
  },
  shortDescriptionStyle: {
    fontSize: "0.7rem",
    marginTop: "-6px",
    height: "30px",
    overflow: "hidden",
  },
  shortDescForProduct: {
    fontSize: "0.7rem",
    height: "30px",
    overflow: "hidden",
  },
  headingText: {
    color: "#232961",
  },
}));
