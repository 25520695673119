import React from "react";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  Checkbox,
  Avatar,
  ListItemIcon,
  Grid,
  Button,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import { VBRIDGEHUB_MEDIAURL } from "../../../../../constants";
import Load from "../../../../shared/skeletons/skeleton";
import VbridgeAlert from "../../../../shared/alert";

import { deletelist, productsByListId } from "./query";
import Heading from "./Heading";
import EmptyMessageList from "./EmptyListMessage";

// const LightTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//   },
// }))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  list: {
    padding: theme.spacing(0),
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  productHead: {
    cursor: "pointer",
  },
  square: {
    width: 100,
    height: 100,
  },
}));

export default function Products() {
  const classes = useStyles();
  let { id } = useParams();
  const client = useApolloClient();

  const [checked, setChecked] = React.useState([]);
  const [values, setValues] = React.useState([]);
  // const [count, setCount] = React.useState("0");
  const [checkValue, setCheckValue] = React.useState(false);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const checkedValues = [];

    if (currentIndex === -1) {
      newChecked.push(value);

      newChecked.map((value) => {
        return checkedValues.push(value.vbeid);
      });
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setValues(checkedValues);

    if (currentIndex === -1) {
      setCheckValue(true);
    } else if (currentIndex >= 1) {
      setCheckValue(true);
    } else if (newChecked.length < 1) {
      setCheckValue(false);
    }
  };

  const { data } = useQuery(productsByListId, {
    variables: { input: { vbeid: id } },
  });

  const [
    removelistmutation,
    { error: mutationError, data: removeList },
  ] = useMutation(deletelist, {
    onCompleted: (e) => {
      UpdateDeleteCache(e);
    },
  });

  function handleDelete() {
    removelistmutation({
      variables: {
        input: { objectvbeid: values, vbeid: id },
      },
    });
  }

  //////update cache for delete group
  function UpdateDeleteCache(e) {
    let removeIds = [];
    e.removeFromList.forEach((o) => {
      removeIds.push(o.id);
    });

    let listProductCache = data.getProductsByListId.filter(
      (product) => !removeIds.includes(product.id)
    );

    client.writeQuery({
      query: productsByListId,
      data: {
        getProductsByListId: {
          __typename: "product",
          getProductsByListId: listProductCache,
        },
      },
      variables: { input: { vbeid: id } },
    });
  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          {removeList ? (
            <VbridgeAlert
              severity="success"
              open={true}
              message="Deleted Successfully "
            />
          ) : null}
          {mutationError ? (
            <VbridgeAlert
              severity="error"
              open={true}
              message="Something went wrong ... "
            />
          ) : null}
        </Grid>
      </Grid>

      <Grid container md={12}>
        <Grid item md={12} style={{ marginBottom: "2%" }}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleDelete}
            disabled={checkValue ? false : true}
          >
            <DeleteOutlinedIcon />
            Remove From List
          </Button>
        </Grid>
      </Grid>

      {data ? (
        data.getProductsByListId.length > 0 ? (
          <Grid container className={classes.root}>
            <Grid item md={12}>
              <List className={classes.list}>
                {data ? (
                  data.getProductsByListId.map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value.vbeid}`;
                    return (
                      <ListItem key={value} divider dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="end"
                            onChange={handleToggle(value)}
                            checked={checked.indexOf(value) !== -1}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>

                        <ListItemAvatar>
                          <Avatar
                            className={classes.large}
                            variant="square"
                            alt={value.name}
                            src={VBRIDGEHUB_MEDIAURL + "/" + value.image}
                          >
                            {":("}
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          disableTypography={true}
                          style={{ padding: "2rem" }}
                          primary={
                            <Heading vbeid={value.vbeid} name={value.name} />
                          }
                          secondary={value.shortdescription}
                        />
                      </ListItem>
                    );
                  })
                ) : (
                  <Load />
                )}
              </List>
            </Grid>
          </Grid>
        ) : (
          <EmptyMessageList type="Products/Vendors" />
        )
      ) : null}
    </>
  );
}
