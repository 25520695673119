import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    width: "16px",
    height: "16px",
  },
  logo: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  logoImg: {
    width: "30%",
  },

  info: {
    marginBottom: "15px",
    fontSize: "0.8rem",
    fontWeight: 500,
  },
  heading: {
    marginBottom: "15px",
    fontSize: "0.9rem",
    fontWeight: "600",
    color: "rgb(65,121,189)",
  },
  formPaper: {
    padding: theme.spacing(2, 4),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  formComboBox: {
    height: theme.spacing(0.1),
  },
  formHeaderText: {
    fontSize: "0.85rem",
    marginBottom: theme.spacing(1),
    textAlign: "left",
    fontStyle: "italic",
  },
  formSubText: {
    fontSize: "0.85rem",
    textAlign: "left",
  },
  formSecondText: {
    fontSize: "0.85rem",
    textAlign: "left",
    margin: theme.spacing(1.5, 0, 1),
  },
  buttonRow: {
    marginBottom: "3%",
  },
  bgroup: {
    margin: "auto",
    marginTop: theme.spacing(2),
  },
  createFormGroup: {
    display: "flex",
    flexDirection: "row !important",
    flexWrap: "nowrap !important",
    justifyContent: "space-between",
  },
  helperText: {
    fontStyle: "italic",
    fontSize: "0.65rem",
    margin: 0,
  },
  roleHelperText: {
    fontStyle: "italic",
    fontSize: "0.65rem",
    fontWeight: "500",
    margin: 0,
    color: "red",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "end",
    marginBottom: theme.spacing(1),
    "& button": {
      marginLeft: theme.spacing(1),
    },
  },
  formDivLeft: {
    width: "50%",
    textAlign: "left",
    paddingRight: "60px",
  },
  agreementStatus: {
    textAlign: "center !important",
    fontSize: "0.9rem",
    marginTop: theme.spacing(1),
  },
  formDivRight: {
    width: "50%",
    padding: theme.spacing(0, 0, 1, 3),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    "& .MuiAvatar-root": {
      width: "80px",
      height: "80px",
    },

    "& label": {
      marginBottom: theme.spacing(1),
      fontSize: "0.9rem",
    },
  },
  subheading: {
    textTransform: "capitalize",
    textAlign: "left",
  },
  claimDiv: {
    textAlign: "center",
    alignItems: "center",
  },
  claimBtn: {
    float: "none",
    marginTop: theme.spacing(1),
  },
  normalGroupChip: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: "0.75rem",
  },
  selectedGroupChip: {
    backgroundColor: "rgb(24, 28, 67) !important",
    color: "#fff",
  },
  emptyDesc: {
    color: "#999",
    fontSize: "0.75rem",
    fontStyle: "italic",
    marginBottom: theme.spacing(2),
  },
  requestText: {
    color: "#999",
    fontSize: "0.75rem",
    fontStyle: "italic",
  },
  requestBtn: {
    fontSize: "0.7rem",
  },
  viewMoreBtn: {
    fontSize: "0.75rem",
    textTransform: "capitalize",
  },
  viewMoreDiv: {
    width: "100%",
    "& button": {
      padding: "0 8px",
    },
  },
  groupListDiv: {
    textAlign: "left",
  },
  groupListHeading: {
    margin: "0",
  },
  groupDiv: {
    height: "280px",
  },
  tabPanelRoot: {
    height: "230px",
    overflow: "auto",
    "& .MuiBox-root": {
      padding: theme.spacing(1, 0),
    },
  },
  profilePictureDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiAvatar-root": {
      width: "80px",
      height: "80px",
      marginTop: theme.spacing(2),
    },
  },
  stepperRoot: {
    width: "100%",
    //marginTop: "20px",
    marginTop: "80px",
  },
  adminsDiv: {
    padding: theme.spacing(1, 2),
  },
  adminRequestDiv: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  requestButtonDiv: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  memberDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: theme.spacing(2),
  },
  adminListText: {
    fontWeight: "500",
    fontStyle: "italic",
    color: theme.palette.secondary.main,
  },
  stepTitleMessage: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  stepperButtons: {
    padding: "0 8px",
    fontSize: "0.8rem",
    "& span": {
      textTransform: "capitalize",
      width: "auto",
    },
  },
  textLink: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
    fontWeight: 500,
    borderBottom: "2px solid",
  },
  tabRoot: {
    "& span": {
      textTransform: "capitalize",
      fontSize: "0.8rem",
    },
  },
  claimBtnDiv: {
    display: "flex",
    justifyContent: "center",
    padding: "8px",
    marginTop: "12px",
  },

  title: {
    padding: "8px 12px",

    "& h2": {
      fontSize: "16px",
    },
  },

  hbtn: {
    padding: "8px",
    "& span": {
      textTransform: "none",
      fontSize: "12px",
    },
  },

  dailogcontent: {
    fontSize: "14px",
    color: "black",
    "& li": {
      padding: "4px",
    },
  },

  errorfields: {
    color: "red",
    fontSize: "10px",
  },

  errorMsg: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#8B0000",
    paddingBottom: "8px",
    marginTop: "-12px",
  },
  terms: {
    textAlign: "left",
    marginTop: "8px",
  },
  termsText: {
    fontSize: "0.9rem",
    "& a": {
      fontSize: "0.9rem",
      color: "#4179BD !important",
    },
  },
  drawerOpenRoot: {
    marginLeft: 200,
  },
}));
