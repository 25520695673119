import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { useFormik } from "formik";
import * as yup from "yup";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import SimpleSnackbar from "./snackbar";
import SimpleErrorSnackbar from "./errorsnackbar";
import { BootstrapInput } from "../../../../../theme/theme";
import CompanySearch from "../../../../shared/companySearch";
import { useStyles } from "./styles";
import { getPartnersByCompanyId, listProducts, postPartner } from "../query";
import AddNewButton from "../../../products/product2.0/AddNew/AddNewButton";
import InputField from "../../../common/InputField";
import PrimaryButton from "../../../common/PrimaryButton";

const groups = require("../../../../../data/groups.json");

const partnerstype = ["Resell", "Professional Services"];

export default function AddPartnerForm({ vbeid }) {
  const classes = useStyles();
  const client = useApolloClient();
  const timer = useRef();

  const [open, setOpen] = useState(false);
  const [companyPresent, setCompanyPresent] = useState(true);
  const [companySelected, setCompanySelected] = useState("");

  //   const [setLoading] = useState(false);
  //   const [setGroupValue] = useState([]);

  let { id } = useParams();
  if (vbeid) {
    id = vbeid;
  }

  const groupCheck = () => {
    const newVal = [];

    groups.map((group) => {
      if (group["n.type"] === "Vertical") {
        return newVal.push(group["n.name"]);
      } else return null;
    });

    // setGroupValue(newVal);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!mutationLoading) {
      //   setLoading(true);
      timer.current = window.setTimeout(() => {
        // setLoading(false);
      }, 500);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    groupCheck();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const { data: products } = useQuery(listProducts, {
    variables: { input: { vbeid: id, type: "all" } },
  });

  let productsList = [];

  products?.productByComapnyId.forEach((product) => {
    productsList.push({
      type: product.vbeid,
      value: product.name,
    });
  });

  const validationSchema = yup.object().shape({
    partnerurl: yup
      .string("Enter your Partner URL")
      .required("Partner URL is required")
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        "Enter valid Partner URL"
      ),

    partnertype: yup
      .string("Enter your partner type")
      .oneOf(partnerstype, "Please select a valid partner type")
      .required("Partner type is required"),

    partnershipComments: yup.string("Enter your partnership Comments"),

    mapProduct: yup
      .string("Choose Product")
      //   .oneOf(productsList, "Please select a product")
      .required("Product is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      partnerurl: "",
      partnertype: "",
      companyid: "",
      partnershipComments: "",
      mapProduct: "",
    },
    validationSchema: validationSchema,
  });

  const [
    postPartnerMutation,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(postPartner, {
    onCompleted: (e) => {
      updatePartnersInformationCache(e);
    },
  });

  const createPartnerShort = (e) => {
    e.preventDefault();

    const { name, partnerurl, partnertype, partnershipComments, mapProduct } =
      formik.values;

    postPartnerMutation({
      variables: {
        input: {
          name: name,
          partnerurl: partnerurl,
          partnertype: partnertype,
          //   partnerComments: partnershipComments,
          companyid: id,
          productid: mapProduct,
        },
      },
    });

    handleButtonClick();
  };

  useQuery(getPartnersByCompanyId, {
    variables: { input: { vbeid: id } },
  });

  const updatePartnersInformationCache = (e) => {
    const { partnersByCompanyId } = client.readQuery({
      query: getPartnersByCompanyId,
      variables: { input: { vbeid: id } },
    });

    let listPartnersInformationCache = [...partnersByCompanyId];

    client.writeQuery({
      query: getPartnersByCompanyId,
      data: {
        partnersByCompanyId: listPartnersInformationCache.unshift({
          ...e.addPartner,
          __typename: "company",
        }),
      },
      variables: { input: { vbeid: id } },
    });

    handleClose();
  };

  const setName = (text) => {
    formik.values.name = text;
  };

  const setDomain = (text) => {
    formik.values.partnerurl = text;
    setCompanySelected(text);
  };

  const setCompany = (value) => {
    setCompanyPresent(value);
  };

  return (
    <div>
      {mutationError && <SimpleErrorSnackbar />}
      {data && <SimpleSnackbar type="success" />}
      {mutationLoading && <SimpleSnackbar type="loading" />}

      <AddNewButton name="Add New Partner" handleClick={handleClickOpen} />

      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Partner</DialogTitle>

          <DialogContent dividers>
            <DialogContentText>
              Please enter Partner details in the following fields.
            </DialogContentText>

            <div className={classes.root}>
              <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.textfields}>
                    <InputLabel
                      required={true}
                      shrink
                      htmlFor="my-input2"
                      focused={true}
                    >
                      Partner Type
                    </InputLabel>

                    <Select
                      id="grouped-native-select"
                      input={<BootstrapInput />}
                      value={formik.values.partnertype}
                      onChange={formik.handleChange("partnertype")}
                      onBlur={formik.handleBlur("partnertype")}
                    >
                      {partnerstype.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.partnertype && formik.errors.partnertype ? (
                    <div className="error-message">
                      {formik.errors.partnertype}
                    </div>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.textfields}>
                    <InputLabel shrink htmlFor="partnerComment" focused={true}>
                      Partnership Comments
                    </InputLabel>

                    <BootstrapInput
                      multiline
                      id="partnerComment"
                      aria-describedby="my-helper-text"
                      value={formik.values.partnershipComments}
                      onChange={formik.handleChange("partnershipComments")}
                      onBlur={formik.handleBlur("partnershipComments")}
                      placeholder="Partnership Comments"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.textfields}>
                    <InputLabel
                      required={true}
                      shrink
                      htmlFor="my-input"
                      focused={true}
                    >
                      Partner Name
                    </InputLabel>

                    <CompanySearch
                      id="company-name-search"
                      setName={setName}
                      setDomain={setDomain}
                      setCompany={setCompany}
                    />
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.name && formik.errors.name ? (
                    <div className="error-message">{formik.errors.name}</div>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.textfields}>
                    {/* <InputField
                      label="Partner URL"
                      value={formik.values.partnerurl}
                      onChange={formik.handleChange("partnerurl")}
                      onBlur={formik.handleBlur("partnerurl")}
                      placeholder="Partner URL should begin with https://"
                    /> */}
                    <InputLabel
                      required={true}
                      shrink
                      htmlFor="my-input1"
                      focused={true}
                    >
                      Partner URL
                    </InputLabel>

                    <BootstrapInput
                      disabled={true}
                      required={true}
                      id="my-input1"
                      aria-describedby="my-helper-text"
                      value={formik.values.partnerurl}
                      onChange={formik.handleChange("partnerurl")}
                      onBlur={formik.handleBlur("partnerurl")}
                      placeholder="Partner URL should begin with https://"
                    />
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.partnerurl && formik.errors.partnerurl ? (
                    <div className="error-message">
                      {formik.errors.partnerurl}
                    </div>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.textfields}>
                    <InputLabel
                      required={true}
                      shrink
                      htmlFor="productMap"
                      focused={true}
                    >
                      Map Product / Service
                    </InputLabel>

                    <Select
                      id="productMap"
                      input={<BootstrapInput />}
                      value={formik.values.mapProduct}
                      onChange={formik.handleChange("mapProduct")}
                      onBlur={formik.handleBlur("mapProduct")}
                    >
                      {productsList.map((name) => (
                        <MenuItem key={name.type} value={name.type}>
                          {name.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.mapProduct && formik.errors.mapProduct ? (
                    <div className="error-message">
                      {formik.errors.mapProduct}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </div>
          </DialogContent>

          <DialogActions>
            <PrimaryButton
              onClick={handleClose}
              variant="outlined"
              label="Cancel"
            />

            <PrimaryButton
              onClick={createPartnerShort}
              variant="contained"
              label="Add"
              disabled={!(formik.isValid && formik.dirty)}
            />

            {/* <Button
              onClick={createPartnerShort}
              type="submit"
              color="primary"
              //disabled={!(formik.isValid && formik.dirty)}
              className={classes.addNewBtn}
            >
              Add
            </Button> */}

            {mutationLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
