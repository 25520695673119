import { gql } from "@apollo/client";

export const updateProfile = gql`
  mutation updateProfile($input: personinput!) {
    updateProfile(input: $input) {
      id
      vbeid
      name
      imageurl
      designation
      role
      roletype
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
      isPrivatemail
      status
      onBoardingCompleted
    }
  }
`;

export const userInfoQuery = gql`
  query userinfo {
    getLoginUserInfo(input: {}) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      roletype
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
      isPrivatemail
      companyname
      onBoardingCompleted
    }
  }
`;

export const listLeads = gql`
  query leadinformation($input: projectinput!) {
    getLeadsByCompanyID(input: $input) {
      id
    }
  }
`;

export const listGroups = gql`
  query listing($offset: Int, $limit: Int) {
    listGroup(input: { offset: $offset, limit: $limit }) {
      total
      groups {
        alias
        id
        name
        type
        image
        followinggroup
      }
    }
  }
`;

export const listFollowingGroups = gql`
  query listFollowingGroups {
    getFollowingGroupsByUserId(input: {}) {
      id
      name
    }
  }
`;
