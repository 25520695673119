import React, { useState } from "react";

import { useQuery } from "@apollo/client";
import { Divider, Grid, makeStyles } from "@material-ui/core";

import { getCompanyById } from "./query";

import UserInfo from "./userInfo";

import LinesEllipsis from "react-lines-ellipsis";

import "./messaging.css";

const useStyles = makeStyles((theme) => ({
  question: {
    marginLeft: "65px",
    fontSize: "0.83rem",
    paddingBottom: theme.spacing(1),
    "& p": {
      margin: "3px 0 0 0",
      fontSize: "0.85rem",
    },
  },
  mainGrid: {
    background: "#F7F7F7",
  },
}));

export default function ReplyContent({ reply }) {
  const classes = useStyles();
  const { data } = useQuery(getCompanyById, {
    variables: { input: { vbeid: reply.usercompanyid } },
  });

  const [useEllipses, setUseEllipses] = useState(true);

  const onReadMore = () => {
    let prevState = useEllipses;
    setUseEllipses(!prevState);
  };

  return (
    <Grid container justify="flex-start" className={classes.mainGrid}>
      <Grid item md={12}>
        <div>
          <UserInfo
            type="reply"
            id={reply.id}
            createdBy={reply.createdby}
            userImage={data?.queryCompanybyid?.image}
          />

          {useEllipses ? (
            <div onClick={onReadMore}>
              <LinesEllipsis
                text={reply.reply}
                maxLine={2}
                ellipsis=" ...Read More"
                trimRight
                basedOn="letters"
                className={classes.question}
              />
            </div>
          ) : (
            <div className={classes.question}>
              {reply.reply}
              <button className="readLessBtn" onClick={onReadMore}>
                Read Less
              </button>
            </div>
          )}
        </div>
        <Divider />
      </Grid>
    </Grid>
  );
}
