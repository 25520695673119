import { makeStyles, withStyles, Tooltip } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  dataGridPaper: {
    width: "100%",
  },
  searchButton: {
    marginTop: "10px",
  },
  swipableDrawer: {
    width: 800,
  },
  mergeButton: {
    paddingTop: theme.spacing(1),
  },
  filterBatch: {
    width: 200,
  },
  filterSize: {
    width: 100,
  },
  orderBySize: {
    width: 180,
  },
  dataGridAlign: {
    paddingTop: theme.spacing(8),
  },
  logoSize: {
    height: 20,
    width: 20,
  },
  logoAlign: {
    marginTop: theme.spacing(1),
    display: "flex",
  },
  linkStyle: {
    fontSize: "11px",
    textDecoration: "none",
    //fontStyle: "italic",
  },
  actionEditLinkAlign: {
    marginTop: "1%",
  },
  actionLongDescriptionLinkAlign: {
    //marginTop:'-10%'
  },
  iconPointer: {
    cursor: "pointer",
  },
  buttonStyle: {
    borderRadius: "2rem",
    textTransform: "capitalize",
    fontSize: "0.7rem",
  },
  updateOrgButton: {
    marginTop: "1%",
  },
  categorySearchAlign: {
    marginLeft: "3%",
  },
  mapButtonAlign: {
    marginTop: "1%",
  },
  headingAlign: {
    marginLeft: "4%",
    textTransform: "capitalize",
    color: theme.palette.secondary.main,
    textAlign: "left",
  },
  chipRealign: {},
  productMapBoxAlign: {
    paddingTop: theme.spacing(3),
  },

  caestudyFileUploadAlign: {
    paddingTop: theme.spacing(3),
  },

  unmapBox: {
    width: "100%",
  },

  mapButtonsAlign: {
    marginTop: "10%",
  },
}));

export const EllipsisText = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 200,
        fontSize: 8,
      }}
    >
      {children}
    </div>
  );
};

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: theme.spacing(2),
  },
}))(Tooltip);
