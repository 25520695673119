import React, { useState } from "react";
import { useHistory } from "react-router";

import clsx from "clsx";

import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import HomeSearch from "../../shared/homeSearch";
import VbridgeAlert from "../../shared/alert";

import { useApolloClient, useMutation } from "@apollo/client";

import { userInfoQuery } from "../companies/Company2.0/query";

import { useStyles } from "./drawerStyles";

import InviteUser from "../Agreements/InviteUser";
import { inviteUser } from "../Agreements/query";

import AddInvite from "../companies/Company2.0/shorts/AddInvite";

export default function HomePage({
  toggleValue,
  onChange,
  drawerOpen,
  mainDrawerClick,
}) {
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();

  const [open, setOpen] = useState(true);
  const [inviteModal, setInviteModal] = useState(false);
  const [inviteError, setInviteError] = useState(
    "Something went wrong. Please try again."
  );
  const [invitesupplier, setInviteSupplier] = useState(false);

  if (localStorage.urlpath !== "/") {
    let path = localStorage.urlpath;
    localStorage.setItem("urlpath", "/");

    mainDrawerClick("", "close");

    history.push(path);
  }

  const data = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  //   const handleDrawerClose = () => {
  //     setOpen(false);
  //   };

  const onSearchActive = () => {
    setOpen(false);
  };

  const onTypeSelect = (value) => {
    onChange(value);
  };

  const closeModal = () => {
    setInviteModal(false);
  };

  const onSupplierClose = () => {
    setInviteSupplier(false);
  };

  const [
    inviteUserMutation,
    { loading: inviteLoading, error, data: inviteUserSuccess },
  ] = useMutation(inviteUser, {
    onCompleted: (e) => {
      closeModal();
    },
    onError: (e) => {
      setInviteError(e.graphQLErrors[0].message);
    },
  });

  const submitUserInvite = (emailId) => {
    inviteUserMutation({
      variables: {
        input: { personemailid: emailId },
      },
    });
  };

  return (
    <div className={classes.root}>
      {inviteUserSuccess && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="User invited successfully"
        />
      )}

      {error && (
        <VbridgeAlert severity="error" open={true} message={inviteError} />
      )}

      <div
        className={clsx(classes.drawerHeader, {
          [classes.openDrawerHeader]: drawerOpen,
        })}
      >
        <IconButton onClick={handleDrawerOpen}>
          <ChevronRightIcon />
        </IconButton>
      </div>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <HomeSearch
          onSearchActive={onSearchActive}
          open={open}
          toggleValue={toggleValue}
          onSelect={onTypeSelect}
          mainDrawerClick={mainDrawerClick}
        />

        <InviteUser
          open={inviteModal}
          closeModal={closeModal}
          loading={inviteLoading}
          submitUserInvite={submitUserInvite}
        />

        <AddInvite
          invite="invite supplier"
          headername="Invite a Supplier to vBridge Hub"
          dialogtitle="Know of any Tech Vendor and/or Service Provider who benefits from the platform?"
          supplieropen={invitesupplier}
          supplierclose={onSupplierClose}
        />
      </main>
    </div>
  );
}
