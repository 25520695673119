import { useParams } from "react-router-dom";

import {
  Avatar,
  Grid,
  Typography,
  FormControl,
  Button,
  TextField,
  Snackbar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Paper,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import { Alert } from "@material-ui/lab";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import { useFormik } from "formik";

import {
  ADD_TEAM_MEMBER,
  LIST_PROJECT_TEAM_MEMBERS,
  REMOVE_TEAM_MEMBER,
} from "../../queries/query";

import { useStyles } from "./styles";
import { useState } from "react";
import { userInfoQuery } from "../../../companies/Company2.0/query";
import LoadingResult from "../../../../shared/LoadingResult";

const validateTeamMember = (values) => {
  const errors = {};
  if (!values.personemailid) {
    errors.personemailid = "Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.personemailid)
  ) {
    errors.personemailid = "Invalid email address";
  }

  return errors;
};

const TeamMenu = (props) => {
  const [removeMember, { loading }] = useMutation(REMOVE_TEAM_MEMBER, {
    onCompleted: (e) => {
      props.refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <IconButton
      disabled={loading}
      onClick={() => {
        removeMember({
          variables: { input: { vbeid: props.id, listvbeid: [props.pvbeid] } },
        });
      }}
      edge="end"
      aria-label="delete"
    >
      <DeleteIcon />
    </IconButton>
  );
};

function Form(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      vbeid: props.id,
      personemailid: "",
    },
    validate: validateTeamMember,
    onSubmit: (values) => {
      addMember({ variables: { input: values } });
    },
  });

  const [addMember, { loading }] = useMutation(ADD_TEAM_MEMBER, {
    onCompleted: (e) => {
      formik.setFieldValue("personemailid", "");
      props.refetch();
    },
    onError: (err) => {
      formik.setFieldValue("personemailid", "");
      setOpen(true);
    },
  });

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          Please add a user that belongs to the same domain!
        </Alert>
      </Snackbar>

      <form onSubmit={formik.handleSubmit}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.textFieldLabel}
          >
            Add your project team here.
          </Typography>
        </Grid>

        <Grid item>
          <Grid container>
            <Grid item xs={10}>
              <TextField
                id="filled-full-width"
                size="small"
                name="personemailid"
                helperText={formik.errors.personemailid}
                value={formik.values.personemailid}
                onChange={formik.handleChange}
                placeholder="User Email"
                fullWidth
                variant="outlined"
                error={
                  formik.touched.personemailid &&
                  Boolean(formik.errors.personemailid)
                }
              />
            </Grid>

            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="small"
                disabled={loading}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default function TeamForm() {
  const classes = useStyles();
  const { id } = useParams();
  const client = useApolloClient();

  const user = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  const { data, refetch } = useQuery(LIST_PROJECT_TEAM_MEMBERS, {
    variables: { input: { vbeid: id } },
  });

  let userRole = "member";

  if (data) {
    data.getTeammembersByProjectIDV1.forEach((members) => {
      if (
        members.role.includes("MANAGES") &&
        members.personvbeid === user.getLoginUserInfo.vbeid
      ) {
        userRole = "owner";
      }
    });
  }

  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <Paper elevation={1} className={classes.teamPaper}>
          <FormControl
            component="fieldset"
            className={classes.formControlclass}
          >
            {data ? (
              <>
                {userRole === "owner" && <Form id={id} refetch={refetch} />}
                <Grid item xs={6}>
                  <List dense={true}>
                    {data
                      ? data.getTeammembersByProjectIDV1.map((row) => (
                          <ListItem key={row.id}>
                            <ListItemAvatar>
                              <Avatar></Avatar>
                            </ListItemAvatar>

                            <ListItemText
                              primary={
                                row.personname
                                  ? row.personname
                                  : row.personemailid
                              }
                              secondary={
                                row.role.indexOf("MANAGES") > -1
                                  ? "Project Owner"
                                  : "Project Member"
                              }
                            />

                            {userRole === "owner" &&
                              row.personvbeid !==
                                user.getLoginUserInfo.vbeid && (
                                <ListItemSecondaryAction>
                                  <TeamMenu
                                    id={id}
                                    pvbeid={row.personvbeid}
                                    refetch={refetch}
                                  />
                                </ListItemSecondaryAction>
                              )}
                          </ListItem>
                        ))
                      : null}
                  </List>
                </Grid>
              </>
            ) : (
              <LoadingResult />
            )}
          </FormControl>
        </Paper>
      </Grid>
    </>
  );
}
