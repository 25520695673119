import React from "react";
import { useState } from "react";
import { useStyles } from "./styles";
import { useLazyQuery, useQuery } from "@apollo/client";
import Table from "@material-ui/core/Table";
import { CircularProgress } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getResourcesByStatus } from "./query";
import { GET_PO_DETAILS } from "../queries/query";
import CandidateActionSlider from "../buyer/response/CandidateActionSlider";
import { getFormattedDate } from "../../../../functions/helper";
import { GET_MILESTONES_BY_STATUS } from "../buyer/response/query";

export default function PoUpload({ drawerOpen }) {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [actionSlider, setActionSlider] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedResource, setSelectedResource] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");
  const [action, setAction] = useState("");

  const { data, loading, refetch } = useQuery(getResourcesByStatus, {
    variables: { input: { status: "acceptedSow" } },
    fetchPolicy: "no-cache",
  });

  const {
    data: milestonesData,
    loading: milestonesLoading,
    refetch: milestonesRefetch,
  } = useQuery(GET_MILESTONES_BY_STATUS, {
    variables: { input: { status: "acceptedSow" } },
    fetchPolicy: "no-cache",
  });

  const [getPODetails, { data: poDetails }] = useLazyQuery(GET_PO_DETAILS, {
    fetchPolicy: "no-cache",
  });

  const resourceDatalength = data?.listResourcesByStatus?.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, index) => {
    setCurrentIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onActionClick = (currentResource, action) => {
    if (action !== "uploadPO") {
      getPODetails({
        variables: {
          input: {
            resourceid: currentResource.resourceid,
          },
        },
      });
    }

    setAction(action);
    setSelectedResource(currentResource);
    setActionSlider(true);
    setAnchorEl(null);
  };

  const onClose = () => {
    setActionSlider(false);
    setSelectedResource("");
  };

  const getStartDate = (workPackage) => {
    let milestoneResponse = JSON.parse(workPackage.data);

    return getFormattedDate(milestoneResponse.projectStartDate);
  };

  const getEndDate = (workPackage) => {
    let milestoneResponse = JSON.parse(workPackage.data);

    return getFormattedDate(milestoneResponse.projectEndDate);
  };

  const getMilestoneTotalPrice = (workPackage) => {
    let milestonePriceTotal = 0;
    let milestoneResponse = JSON.parse(workPackage.data);

    milestoneResponse.milestoneList.forEach((milestone) => {
      let price = parseInt(milestone.price);
      milestonePriceTotal += price;
    });

    return milestonePriceTotal;
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, resourceDatalength - page * rowsPerPage);

  return (
    <div className={drawerOpen ? classes.drawerOpenRoot : classes.root}>
      <>
        <p className={classes.heading}>Purchase Orders - Contract Resourcing</p>

        <Paper className={classes.paper}>
          <TableContainer className="tableContainer">
            {loading ? (
              <CircularProgress className={classes.loadStyle} size={24} />
            ) : (
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell rowSpan={2}>Project Name</TableCell>
                    <TableCell rowSpan={2}>Project Owner</TableCell>

                    <TableCell align="center" rowSpan={2}>
                      Supplier Name
                    </TableCell>

                    <TableCell align="center" rowSpan={2}>
                      Resource Name
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={2}
                      className={classes.engagement}
                    >
                      Engagement Period
                    </TableCell>

                    <TableCell align="center" rowSpan={2}>
                      Currency
                    </TableCell>

                    <TableCell align="center" rowSpan={2}>
                      Billing Mode
                    </TableCell>

                    <TableCell align="center" rowSpan={2}>
                      Billing Rate
                    </TableCell>

                    <TableCell align="center" rowSpan={2}>
                      Actions
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center">Start</TableCell>
                    <TableCell align="center">End</TableCell>
                  </TableRow>
                </TableHead>

                {data?.listResourcesByStatus.length ? (
                  <TableBody>
                    {data?.listResourcesByStatus?.map((data, index) => {
                      return (
                        <TableRow>
                          <TableCell align="center">
                            {data.projectName}
                          </TableCell>

                          <TableCell align="center">
                            {data.projectOwner}
                          </TableCell>

                          <TableCell align="center">
                            {data.companyname}
                          </TableCell>
                          <TableCell align="center">{data.name}</TableCell>

                          <TableCell align="center">
                            {getFormattedDate(data.engagementStartDate)}
                          </TableCell>

                          <TableCell align="center">
                            {getFormattedDate(data.engagementEndDate)}
                          </TableCell>

                          <TableCell align="center">{data.currency}</TableCell>
                          <TableCell align="center">Per Hour</TableCell>
                          <TableCell align="center">
                            {data.revisedbillingrate || data.billingrate}
                          </TableCell>

                          <TableCell align="center">
                            <IconButton
                              className={classes.iconstyle}
                              onClick={(event) => {
                                handleClick(event, index);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>

                          {currentIndex === index && (
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                            >
                              <MenuItem
                                className={classes.menuitem}
                                onClick={() => {
                                  onActionClick(data, "generatePO");
                                }}
                              >
                                Upload PO
                              </MenuItem>
                            </Menu>
                          )}
                        </TableRow>
                      );
                    })}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 33 * emptyRows }}>
                        <TableCell colSpan={10} />
                      </TableRow>
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={10}>
                        No new Purchase Orders!
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            )}
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={resourceDatalength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </>

      <>
        <p className={classes.heading}>Purchase Orders - Work Package</p>

        <Paper className={classes.paper}>
          <TableContainer className="tableContainer">
            {loading ? (
              <CircularProgress className={classes.loadStyle} size={24} />
            ) : (
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell rowSpan={2}>Project Name</TableCell>
                    <TableCell rowSpan={2}>Project Owner</TableCell>

                    <TableCell align="center" rowSpan={2}>
                      Supplier Name
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={2}
                      className={classes.engagement}
                    >
                      Engagement Period
                    </TableCell>

                    <TableCell align="center" rowSpan={2}>
                      Currency
                    </TableCell>

                    <TableCell align="center" rowSpan={2}>
                      Order Value
                    </TableCell>

                    <TableCell align="center" rowSpan={2}>
                      Actions
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center">Start</TableCell>
                    <TableCell align="center">End</TableCell>
                  </TableRow>
                </TableHead>

                {milestonesData?.wp_listWorkPackageByStatus.length ? (
                  <TableBody>
                    {milestonesData?.wp_listWorkPackageByStatus?.map(
                      (workPackage, index) => {
                        return (
                          <TableRow>
                            <TableCell align="center">
                              {workPackage.projectName}
                            </TableCell>

                            <TableCell align="center">
                              {workPackage.projectOwner}
                            </TableCell>

                            <TableCell align="center">
                              {workPackage.companyname}
                            </TableCell>

                            <TableCell align="center">
                              {getStartDate(workPackage)}
                            </TableCell>

                            <TableCell align="center">
                              {getEndDate(workPackage)}
                            </TableCell>

                            <TableCell align="center">
                              {workPackage.currency}
                            </TableCell>

                            <TableCell align="center">
                              {getMilestoneTotalPrice(workPackage)}
                            </TableCell>

                            <TableCell align="center">
                              <IconButton
                                className={classes.iconstyle}
                                onClick={(event) => {
                                  handleClick(event, index);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>

                            {currentIndex === index && (
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  className={classes.menuitem}
                                  onClick={() => {
                                    onActionClick(workPackage, "uploadPO");
                                  }}
                                >
                                  Upload PO
                                </MenuItem>
                              </Menu>
                            )}
                          </TableRow>
                        );
                      }
                    )}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 33 * emptyRows }}>
                        <TableCell colSpan={10} />
                      </TableRow>
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={10}>
                        No new Purchase Orders!
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            )}
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={resourceDatalength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </>

      {actionSlider && (
        <CandidateActionSlider
          actionSlider={actionSlider}
          onClose={onClose}
          action={action}
          refetch={refetch}
          selectedResource={selectedResource}
          selectedWorkPackage={selectedResource}
          projectData=""
          poDetails={poDetails?.listPO}
          remarks=""
        />
      )}
    </div>
  );
}
