import { Card, CardContent, Grid, SwipeableDrawer } from "@material-ui/core";

import { useStyles } from "../../../../home/styles";

import ProviderCard from "./ProviderCard";

export default function SelectedSuppliersSlider({
  open,
  selectedCompanies,
  onClose,
  onSelect,
}) {
  const classes = useStyles();

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={open}
        className={classes.productDetailDiv}
        onClose={onClose}
      >
        <Card square className={classes.productDetailCard} elevation={0}>
          <CardContent>
            <div style={{ fontWeight: "600" }}>
              Suppliers selected to receive your requirements
            </div>

            <Grid container spacing={1}>
              {selectedCompanies.map((company) => (
                <Grid item md={4}>
                  <ProviderCard
                    company={company}
                    onSelect={() => onSelect(company.vbeid)}
                    type="selected"
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </SwipeableDrawer>
    </>
  );
}
