import { FormControl, Grid, makeStyles, Paper } from "@material-ui/core";
import React, { useState } from "react";
import InputField from "../common/InputField";
import { useFormik } from "formik";
import * as yup from "yup";
import OutlinedButton from "../companies/company/OutlinedButton";
import { useMutation } from "@apollo/client";
import {
  addProjectOwner,
  addOperationEmp,
  addFinanceEmp,
  addTag,
} from "./query";
import SimpleSnackbar from "../companies/Company2.0/shorts/snackbar";
import SimpleErrorSnackbar from "../groups2.0/errorsnackbar";
import SelectField from "../common/SelectField";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    margin: theme.spacing(1),
    padding: theme.spacing(0.4),
  },
  heading: {
    fontSize: theme.spacing(1.6),
    fontWeight: 600,
  },

  field: {
    width: "100% !important",
    margin: theme.spacing(1),
  },
  errors: {
    color: "red !important",
    padding: "2px",
    fontSize: theme.spacing(1.4),
  },

  btn: {
    margin: "8px 0px 8px 16px !important",
  },
  cancelbtn: {
    marginRight: "8px !important",
  },
  formControl: {
    width: "100% !important",
  },
  container: {
    margin: theme.spacing(1, 0.5),
  },
}));

function TagAddUser({ refetch, id, userInfo, onClose }) {
  const classes = useStyles();
  const [error, setError] = useState("");

  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter Tag Name")
      .min(2, "Minimum 2 Characters!")
      .required("Enter Tag Name"),

    description: yup
      .string("Enter Value")
      .min(4, "Minimum 2 Characters!")
      .required("Please Select Type to Enable"),

    // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    //   error = 'Invalid email address';
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      description: "",
      dropdown: "",
    },

    validationSchema: validationSchema,
  });

  const [
    addTagsToTable,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(addTag, {
    onCompleted: (e) => {
      refetch();
      onClose();
      formik.resetForm();
    },
    onError: (e) => {
      setError(e.graphQLErrors[0].message);
      formik.resetForm();
    },
  });

  const addTagTable = (e) => {
    e.preventDefault();

    addTagsToTable({
      variables: {
        input: {
          name: formik.values.name,
          type: formik.values.type,
          value: formik.values.description,
          action: true,
        },
      },
    });
  };

  const type = ["Free Text", "Drop Down"];
  let typeOptions = [];
  type.map((type) => {
    return typeOptions.push({ type: type, value: type });
  });

  const typeValue = ["BE1 ", "BE2", "BE3", "BE4"];
  let typeOptionsValues = [];
  typeValue.map((type) => {
    return typeOptionsValues.push({ type: type, value: type });
  });

  return (
    <>
      {mutationLoading ? <SimpleSnackbar type="loading" /> : null}
      {mutationError ? <SimpleErrorSnackbar msg={error} /> : null}

      {/* <Grid item md={12}>
        {" "}
        <h4
          style={{
            textAlign: "left",
            padding: "0px 8px ",
            margin: "8px",
            fontWeight: "500",
            fontSize: "14px",
          }}
        >
          Add Tag
        </h4>
      </Grid> */}

      <Grid
        container
        md={12}
        direction="row"
        className={classes.container}
        spacing={2}
      >
        {/* <Paper elevation={2} className={classes.paper}> */}
        <Grid container md={7} className={classes.field}>
          <Grid item md={12}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputField
                label="Tag Name"
                placeholder="Enter Tag Name"
                required={false}
                value={formik.values.name}
                onChange={formik.handleChange("name")}
                onBlur={formik.handleBlur("name")}
              />
            </FormControl>
          </Grid>

          <Grid item md={12}>
            {formik.touched.name && formik.errors.name ? (
              <div className={classes.errors}>{formik.errors.name}</div>
            ) : null}
          </Grid>
        </Grid>

        <Grid container md={3} className={classes.field}>
          <Grid item md={11}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.formControl}
            >
              <SelectField
                label="Type "
                options={typeOptions}
                variant="outlined"
                required={true}
                fullWidth={true}
                value={formik.values.type}
                onChange={formik.handleChange("type")}
                onBlur={formik.handleBlur("type")}
                field="companypage"
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            {formik.touched.type && formik.errors.type ? (
              <div className={classes.errors}>{formik.errors.type}</div>
            ) : null}
          </Grid>
        </Grid>

        {/* 
        <Grid container md={2} className={classes.field}>
          <Grid item md={12}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.formControl}
            >
              <SelectField
                label="Type Value "
                options={typeOptionsValues}
                variant="outlined"
                required={true}
                fullWidth={true}
                value={formik.values.dropdown}
                onChange={formik.handleChange("dropdown")}
                onBlur={formik.handleBlur("dropdown")}
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            {formik.touched.dropdown && formik.errors.dropdown ? (
              <div className={classes.errors}>{formik.errors.dropdown}</div>
            ) : null}
          </Grid>
        </Grid> */}

        <Grid container md={10} className={classes.field}>
          <Grid item md={12}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.formControl}
            >
              <InputField
                label={
                  formik.values.type === "Free Text"
                    ? " Description"
                    : "Enter Value"
                }
                placeholder={
                  formik.values.type === "Free Text"
                    ? " Enter Description"
                    : "Enter comma separated values (ex: value1,value2,value3) "
                }
                required={false}
                value={formik.values.description}
                onChange={formik.handleChange("description")}
                onBlur={formik.handleBlur("description")}
                disabled={formik.values.type ? false : true}
                multiline={true}
                rows={formik.values.type === "Free Text" ? 4 : 1}
              />
            </FormControl>
          </Grid>

          <Grid item md={12}>
            {formik.touched.description && formik.errors.description ? (
              <div className={classes.errors}>{formik.errors.description}</div>
            ) : null}
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.btn}>
          <Grid item md={1} className={classes.cancelbtn}>
            <OutlinedButton label="Cancel" onClick={onClose} />
          </Grid>
          <Grid item md={1}>
            <OutlinedButton
              label="Add"
              type="contained"
              disabled={!(formik.dirty && formik.isValid)}
              onClick={addTagTable}
            />
          </Grid>
        </Grid>
        {/* </Paper> */}
      </Grid>
    </>
  );
}

export default TagAddUser;
