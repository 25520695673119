import { Grid } from "@material-ui/core";

import * as React from "react";

import { useQuery } from "@apollo/client";

import { GET_TIME_MONEY } from "../../queries/query";

import TimeMoney from "./timeMoney";
import LoadingResult from "../../../../shared/LoadingResult";

const getValues = (values) => {
  if (values.length > 0) {
    return JSON.parse(values[0].data);
  } else {
    return { fileName: null, data: [] };
  }
};

export default function TimeAndMoneyList({
  projectid,
  productvbeid,
  readOnly,
}) {
  const { loading, data: rdata } = useQuery(GET_TIME_MONEY, {
    fetchPolicy: "no-cache",
    variables: {
      input: { projectid: projectid, productvbeid: productvbeid },
    },
  });

  return (
    <>
      {rdata ? (
        <TimeMoney
          productvbeid={productvbeid}
          projectid={projectid}
          data={getValues(rdata.getTimeMaterialItemvalue)}
          readOnly={readOnly}
        />
      ) : null}

      {loading ? (
        <Grid
          container
          xs={10}
          style={{
            display: "flex",
            justifyContent: "center",
            // margin: "32px 0",
          }}
        >
          <LoadingResult />
        </Grid>
      ) : null}
    </>
  );
}
