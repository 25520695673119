/* eslint-disable no-unreachable */
import React, { useEffect } from "react";

import { useStyles } from "./styles";

import { BootstrapInput } from "../../../theme/theme";

import {
  InputLabel,
  FormControl,
  TextField,
  Button,
  Grid,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useFormik } from "formik";
import * as yup from "yup";

import { mappingRelation, unMappingRelation } from "./query";
//import MapTopic from "../../shared/mapping";
import { useMutation } from "@apollo/client";
import VbridgeAlert from "../../shared/alert";

const topics = require("../../../data/services/topics.json");
const producttopics = require("../../../data/products/topics.json");
const allTopics = [...topics, ...producttopics];

export default function ProductUpdateForm({
  params,
  method,
  updateMapCache,
  updateUnMapCache,
}) {
  const classes = useStyles();

  const [mapValues, setMapValues] = React.useState("");
  const [values, setValues] = React.useState("");

  //validationSchema
  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter Name")
      .min(3, "Name should be of minimum 2 characters length")
      .required("Name is required")
      .nullable(),

    producturl: yup
      .string("Enter your Product URL")
      .nullable()
      .required("Product URL is required")
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        "Enter valid Product URL"
      ),
  });

  //filter params and updateForm values
  const formik = useFormik({
    initialValues: {
      name: params.name,
      producturl: params.producturl,
      shortdescription: params.shortdescription,
    },
    validationSchema: validationSchema,
  });

  //send Formik values
  useEffect(() => {
    method(formik.values, params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, params.id]);

  //function to map category
  const [mapCategoryMutation, { error: mutationMapError, data: mapData }] =
    useMutation(mappingRelation, {
      onCompleted: (e) => {
        updateMapCache(e.relationshipmapping);
      },
    });

  function MapCategory() {
    mapCategoryMutation({
      variables: {
        input: {
          relationname: "BELONGS_TO",
          listvbeid: mapValues,
          vbeid: params.id,
        },
      },
    });
  }

  //function to unmap category
  const [
    unmapCategoryMutation,
    { error: mutationunMapError, data: unmapData },
  ] = useMutation(unMappingRelation, {
    onCompleted: (e) => {
      updateUnMapCache(e.removerelationshipmapping);
    },
  });

  function UnMapCategory() {
    unmapCategoryMutation({
      variables: {
        input: {
          relationname: "BELONGS_TO",
          listvbeid: values,
          vbeid: params.id,
        },
      },
    });
  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          {mutationMapError ? (
            <VbridgeAlert
              severity="error"
              open={true}
              message="Something went wrong."
            />
          ) : null}
          {mapData ? (
            <VbridgeAlert
              severity="success"
              open={true}
              message="Product Mapped successfully"
            />
          ) : null}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={3}>
          {mutationunMapError ? (
            <VbridgeAlert
              severity="error"
              open={true}
              message="Something went wrong."
            />
          ) : null}
          {unmapData ? (
            <VbridgeAlert
              severity="success"
              open={true}
              message="Product UnMapped successfully"
            />
          ) : null}
        </Grid>
      </Grid>

      <form>
        <FormControl fullWidth={true}>
          <InputLabel required={true} shrink focused={true}>
            Product Name
          </InputLabel>

          <BootstrapInput
            required={true}
            value={formik.values.name}
            onChange={formik.handleChange("name")}
            onBlur={formik.handleBlur("name")}
            placeholder="Enter Product Name"
          />

          <div className={classes.errorfields}>
            {formik.touched.name && formik.errors.name ? (
              <div className="error-message">{formik.errors.name}</div>
            ) : null}
          </div>
        </FormControl>

        <FormControl fullWidth={true}>
          <InputLabel required={true} shrink focused={true}>
            Product Url
          </InputLabel>

          <BootstrapInput
            required={true}
            value={formik.values.producturl}
            onChange={formik.handleChange("producturl")}
            onBlur={formik.handleBlur("producturl")}
            placeholder="Enter Product Url"
          />

          <div className={classes.errorfields}>
            {formik.touched.producturl && formik.errors.producturl ? (
              <div className="error-message">{formik.errors.producturl}</div>
            ) : null}
          </div>
        </FormControl>

        <FormControl fullWidth={true}>
          <InputLabel required={true} shrink focused={true}>
            Short Description
          </InputLabel>

          <BootstrapInput
            multiline
            rows={3}
            rowsMax={5}
            required={true}
            value={formik.values.shortdescription}
            onChange={formik.handleChange("shortdescription")}
            onBlur={formik.handleBlur("shortdescription")}
            placeholder="Enter shortdescription"
          />

          <div className={classes.errorfields}>
            {formik.touched.shortdescription &&
            formik.errors.shortdescription ? (
              <div className="error-message">
                {formik.errors.shortdescription}
              </div>
            ) : null}
          </div>
        </FormControl>

        {/* <MapTopic
                      id={params.id}
                      name="BELONGS_TO"
                      currentvbeid={params.topiclist}
                    /> */}

        <Box p={1} className={classes.unmapBox}>
          <Grid container>
            <Grid item md={8}>
              <Autocomplete
                multiple
                options={allTopics}
                getOptionLabel={(option) => option["n.name"]}
                //defaultValue={params.topiclist.map((value)=>{return value.name})}
                clearOnBlur="true"
                onChange={(event, newvalue) => {
                  let selectedValue = [];
                  newvalue.forEach((value) => {
                    selectedValue.push(value["n.vbeid"]);
                  });
                  setMapValues(selectedValue);
                }}
                renderOption={(option) => (
                  <React.Fragment>{option["n.name"]} </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Topic To Map"
                    variant="outlined"
                    margin="dense"
                    id="input"
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={mapValues ? false : true}
                size="small"
                className={classes.mapButtonsAlign}
                onClick={MapCategory}
              >
                Map
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* params.topiclist.map((value)=>{return value.name}) */}
        <Box p={1} className={classes.unmapBox}>
          <Grid container>
            <Grid item md={8}>
              <Autocomplete
                multiple
                options={params.topiclist}
                getOptionLabel={(option) => option["name"]}
                //defaultValue={params.topiclist.map((value)=>{return value.name})}
                clearOnBlur="true"
                onChange={(event, newvalue) => {
                  let selectedValue = [];
                  newvalue.forEach((value) => {
                    selectedValue.push(value["vbeid"]);
                  });

                  setValues(selectedValue);
                }}
                renderOption={(option) => (
                  <React.Fragment>{option["name"]} </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose Topic from the list to Unmap"
                    variant="outlined"
                    margin="dense"
                    id="input"
                  />
                )}
              />
            </Grid>

            <Grid item md={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={values ? false : true}
                size="small"
                className={classes.mapButtonsAlign}
                onClick={UnMapCategory}
              >
                Unmap
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
}
