import React, { useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { Box, IconButton, Link } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { BorderLinearProgress, useStyles } from "./styles";

import { GetMainForm, getPageMessage, getPageProgress } from "./getHelpers";

import PrimaryButton from "../../../common/PrimaryButton";
import ConciergeSlider from "../projectlist/ConciergeSlider";

import { useQuery } from "@apollo/client";

import { GET_PROJECT } from "../../queries/query";

const getSteps = () => {
  return ["Create", "Suppliers", "Requirements", "Response"];
};

const Help = (props) => {
  const classes = useStyles();

  const help = getPageMessage(props.page);

  return (
    <Box m={2} className={classes.help}>
      <Typography variant="h6" gutterBottom component="div" align="left">
        {help.title}
      </Typography>

      <Typography
        className={classes.helpmessage}
        variant="body2"
        gutterBottom
        align="left"
      >
        {help.message}

        {props.page === "/requirement-template" ||
        props.page === "/other-templates" ||
        props.page === "/response-templates" ? (
          <Typography
            className={classes.helpmessage}
            variant="body2"
            component="p"
            gutterBottom
            align="left"
          >
            You can always create your custom template to add to this list. To
            create a new template,{" "}
            <Link
              href=""
              onClick={() => {
                window.open("/projects3.0/requirement_template");
              }}
              variant="body2"
              style={{ color: "#232961" }}
            >
              CLICK HERE
            </Link>{" "}
            and publish to your company templates repository.
          </Typography>
        ) : null}
      </Typography>
    </Box>
  );
};

const getAlign = (index) => {
  switch (index) {
    case 0:
      return "left";
    case 3:
      return "right";
    default:
      return "left";
  }
};

const ProgressBar = (props) => {
  const steps = getSteps();

  return (
    <Box m={2}>
      <Grid container>
        <Grid item xs={12}>
          <BorderLinearProgress
            variant="determinate"
            value={getPageProgress(props.page)}
          />
        </Grid>

        {steps.map((label, index) => {
          return (
            <Grid item xs={3}>
              <Typography
                variant="caption"
                gutterBottom
                component="div"
                align={getAlign(index)}
              >
                {label}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default function ProjectFlowHome({ drawerOpen }) {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  let path = "/" + history.location.pathname.split("/")[1];

  const [page, setPage] = useState(path);
  const [conciergeOpen, setConciergeOpen] = useState(false);

  if (page !== path) {
    setPage(path);
  }

  const { data } = useQuery(GET_PROJECT, {
    variables: { input: { vbeid: id } },
    skip: id === "new",
    fetchPolicy: "network-only",
  });

  const onConciergeClick = () => {
    setConciergeOpen(true);
  };

  const onClose = () => {
    setConciergeOpen(false);
  };

  return (
    <div className={drawerOpen ? classes.drawerOpenRoot : ""}>
      <Box m={9}>
        <Grid container style={{ alignItems: "center" }}>
          <Grid item xs={1}>
            <IconButton
              style={{ marginRight: "50%" }}
              color="primary"
              onClick={() => {
                history.push("/projects3.0/overview");
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>

          <Grid item xs={9}></Grid>

          <Grid item xs={2} style={{ textAlign: "right" }}>
            {id !== "new" && (
              <PrimaryButton
                label="Concierge"
                variant="contained"
                onClick={onConciergeClick}
              />
            )}
          </Grid>
        </Grid>

        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={4} className={classes.pageDetails}>
              <ProgressBar page={page} />

              <Help page={page} />
            </Grid>

            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.formarea}>
                  <GetMainForm page={page} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {conciergeOpen && id !== "new" && (
          <ConciergeSlider
            open={conciergeOpen}
            onClose={onClose}
            projectVbeid={id}
            project={data?.getProjectByIDV1}
          />
        )}
      </Box>
    </div>
  );
}
