import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SelectPreview from "../SelectPreview";

import { useStyles } from "../styles";

export default function DropdownComponent({
  item,
  properties,
  onRemove,
  onEdit,
}) {
  const { label } = properties;
  const classes = useStyles();

  console.log("properties", properties);

  return (
    <div className={classes.textField}>
      <InputLabel>
        {/* {label || "Select"} */}

        <span className={classes.itemText}>
          <DeleteIcon onClick={onRemove} />
        </span>

        <span className={classes.itemText}>
          <EditIcon onClick={onEdit} />
        </span>
      </InputLabel>

      {/* <InputLabel style={{ width: "80%" }}>{label || "Select"}</InputLabel> */}

      <SelectPreview item={item} />

      {/* <FormControl variant="outlined">
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Select"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl> */}
    </div>
  );
}
