import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@material-ui/core";

import { useStyles } from "./styles";
import { validateEmail } from "../../../functions/helper";
import OutlinedButton from "../companies/company/OutlinedButton";
import InputField from "../common/InputField";

export default function InviteUser({
  open,
  closeModal,
  loading,
  submitUserInvite,
}) {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [emailId, setEmail] = useState("");

  const onClick = () => {
    setDisabled(true);
    submitUserInvite(emailId);
  };

  const handleClose = () => {
    setEmail("");
    closeModal();
  };

  const handleChange = (e) => {
    let email = e.target.value;
    setEmail(email);

    let valid = validateEmail(email);
    if (valid) setDisabled(false);
    else setDisabled(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dailogroot}
    >
      <DialogTitle
        id="form-dialog-title"
        className={classes.contentTitle}
        color="primary"
      >
        Invite Colleague
      </DialogTitle>
      <Divider />

      <DialogContent>
        <DialogContentText className={classes.contentText}>
          Please enter an email address to invite colleague.
        </DialogContentText>

        <InputField
          autoFocus
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          value={emailId}
          onChange={handleChange}
          className={classes.inputField}
          disabled={disabled && loading}
        />
      </DialogContent>

      <DialogActions className={classes.actions}>
        <OutlinedButton
          disabled={disabled && loading}
          onClick={onClick}
          label={loading ? "Sending Invite" : "Invite Colleague"}
          type="contained"
        />
      </DialogActions>
    </Dialog>
  );
}
