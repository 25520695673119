import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

// import CaseStudypdf from "./viewpdf";
// import CaseStudyPdf from "./CaseStudyOverview";
import CaseStudyOverview from "./CaseStudyOverview";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "white",
  },
  tabs: {
    fontSize: "0.7em",
    textTransform: "capitalize !important",
    "& span": {
      textTransform: "capitalize",
    },
  },
}));

export default function CaseStudyTab(props) {
  const classes = useStyles();

  return (
    <div className={classes.tabRoot}>
      <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          className={classes.tabs}
          label="Success Story Document"
          {...a11yProps(0)}
        />
      </Tabs>

      <Divider />

      <TabPanel value={0} index={0}>
        <CaseStudyOverview
          isReadOnly={props.isReadOnly}
          isAdmin={props.isAdmin}
          UpdateCaseStudyCache={props.UpdateCaseStudyCache}
        />
      </TabPanel>
    </div>
  );
}
