import { useLazyQuery } from "@apollo/client";
import {
  Grid,
  InputLabel,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";

import {
  getFormattedDate,
  getVbeidOfSelectedCountry,
} from "../../../../../functions/helper";
import { getSigned } from "../../../CaseStudy2.0/casestudy/query";

import { useStyles } from "./styles";

const countries = require("../../../../../data/countriesdata.json");
const states = require("../../../../../data/states_bycountries.json");

export default function CandidateDetailsForm({
  refetch,
  id,
  open,
  onClose,
  projectData,
  selectedResource,
  readOnly,
}) {
  const classes = useStyles();

  let today = new Date().toISOString().split("T")[0];

  const [getUrl] = useLazyQuery(getSigned, {
    onCompleted: (response) => {
      window.open(response.getSignedURL.signedurl);
    },
  });

  let countryVbeid = "";
  let prevState = "";
  let prevCountry = "";

  let cityValue = "";

  if (selectedResource && selectedResource.baseLocation) {
    let locationSplit = selectedResource.baseLocation.split(", ");

    cityValue = locationSplit[2];
    prevCountry = locationSplit[0];

    countryVbeid = getVbeidOfSelectedCountry(locationSplit[0]);
    prevState = locationSplit[1];
  }

  let optCountries = [];
  let optStates = [];

  countries.map((country) => {
    return optCountries.push({
      name: country.countryname,
      value: country.countryname,
      vbeid: country.countryvbeid,
    });
  });

  let secondarySkillsObj = [];

  if (selectedResource && selectedResource.secondaryskill.length !== 0) {
    selectedResource.secondaryskill.forEach((skill) => {
      secondarySkillsObj.push({ type: skill, value: skill });
    });
  }

  states.map((state) => {
    if (
      prevCountry === state["cntry.name"] ||
      countryVbeid === state["cntry.vbeid"]
    ) {
      return optStates.push({
        name: state["ste.name"],
        value: state["ste.name"],
        vbeid: state["cntry.vbeid"],
      });
    } else return null;
  });

  const formik = useFormik({
    initialValues: {
      name: selectedResource?.name || "",
      candidateavailableby: selectedResource?.candidateavailableby || today,
      relocate: selectedResource?.relocate || "",
      billingrate: selectedResource?.billingrate || "",
      attachment: selectedResource?.attachment || "",
      attachmentfilename: selectedResource?.attachmentfilename || "",
      primaryskill: selectedResource?.primaryskill || "",
      currency: selectedResource?.currency || "",
      experience: selectedResource?.experience || "",
      additionalInformation: selectedResource?.additionalInformation || "",
      city: cityValue || "",
    },
  });

  const downloadFile = () => {
    let fileUrlSplit = selectedResource.attachment.split("/");
    let fileName = fileUrlSplit[5].split("?");

    getUrl({
      variables: {
        input: {
          method: "get",
          filetype: "file",
          filename: fileName[0],
          vbeid: fileUrlSplit[3],
        },
      },
    });
  };

  let secondarySkillValue = selectedResource?.secondaryskill;

  if (selectedResource.secondaryskill.length > 1) {
    selectedResource.secondaryskill.forEach((skill, index) => {
      if (index === 0) {
        secondarySkillValue = skill;
      } else {
        secondarySkillValue = secondarySkillValue + ", " + skill;
      }
    });
  }

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      className={classes.actionDrawer}
      onClose={onClose}
    >
      <div style={{ paddingLeft: "16px" }}>
        <div className={classes.sowDiv}>Resource Details</div>

        <Grid container spacing={1}>
          <Grid item md={4} className={classes.firstFields}>
            <div className={classes.section}>
              <InputLabel className={classes.fieldTitle}>
                Resource Name :
                <Typography className={classes.fieldValue}>
                  {" "}
                  {formik.values.name}
                </Typography>
              </InputLabel>
            </div>
          </Grid>

          <Grid item xs={6} className={classes.firstFields}>
            <div className={classes.section}>
              <InputLabel className={classes.fieldTitle}>
                Resource Profile :
                <Typography
                  className={classes.fieldSelectValue}
                  onClick={downloadFile}
                >
                  {selectedResource?.attachmentfilename}
                </Typography>
              </InputLabel>
            </div>
          </Grid>

          <Grid item md={12}>
            <Typography className={classes.fieldTitle}>
              Resource Skill Details
            </Typography>
          </Grid>

          <Grid item md={4}>
            <div className={classes.section}>
              <InputLabel className={classes.fieldTitle}>
                Primary Skill :{" "}
                <Typography className={classes.fieldValue}>
                  {formik.values.primaryskill}
                </Typography>
              </InputLabel>
            </div>
          </Grid>

          <Grid item xs={8}>
            <div className={classes.section}>
              <InputLabel className={classes.fieldTitle}>
                Secondary Skills :{" "}
                <Typography className={classes.fieldValue}>
                  {secondarySkillValue || "NA"}
                </Typography>
              </InputLabel>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.section}>
              <InputLabel className={classes.fieldTitle}>
                Experience :{" "}
                <Typography className={classes.fieldValue}>
                  {formik.values.experience}
                </Typography>
              </InputLabel>
            </div>
          </Grid>

          <Grid item md={12}>
            <Typography className={classes.fieldTitle}>
              Resource Availability
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "4px" }}>
            <div className={classes.section}>
              <InputLabel className={classes.fieldTitle}>
                Base Location :{" "}
                <Typography className={classes.fieldValue}>
                  {formik.values.city}, {prevState || "-"}, {prevCountry || "-"}
                </Typography>
              </InputLabel>
            </div>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "4px" }}>
            <div className={classes.section}>
              <InputLabel className={classes.fieldTitle}>
                Resource Available By :{" "}
                <Typography className={classes.fieldValue}>
                  {getFormattedDate(formik.values.candidateavailableby)}
                </Typography>
              </InputLabel>
            </div>
          </Grid>

          {projectData.worklocation !== "remote" && (
            <Grid item xs={4} style={{ marginTop: "4px" }}>
              <div className={classes.section}>
                <InputLabel className={classes.fieldTitle}>
                  Willing to Relocate? :{" "}
                  <Typography className={classes.fieldValue}>
                    {formik.values.relocate || "NA"}
                  </Typography>
                </InputLabel>
              </div>
            </Grid>
          )}

          <Grid item md={12}>
            <Typography className={classes.fieldTitle}>
              Resource Pricing
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <div className={classes.section}>
              <InputLabel className={classes.fieldTitle}>
                Currency :{" "}
                <Typography className={classes.fieldValue}>
                  {formik.values.currency}
                </Typography>
              </InputLabel>
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className={classes.section}>
              <InputLabel className={classes.fieldTitle}>
                Rate (per hour) :{" "}
                <Typography className={classes.fieldValue}>
                  {formik.values.billingrate}
                </Typography>
              </InputLabel>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.fieldTitle}>
              <InputLabel className={classes.fieldTitle}>
                Additional Information :{" "}
                <Typography className={classes.fieldValue}>
                  {formik.values.additionalInformation}
                </Typography>
              </InputLabel>
            </div>
          </Grid>
        </Grid>
      </div>
    </SwipeableDrawer>
  );
}
