import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";

import { useMutation, useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";

import VbridgeAlert from "../../../../../shared/alert";

import { getAwardsByCompanyId, removeAward } from "../../query";

import MainList from "../../../../groups2.0/MainList";
import { useStyles } from "../../styles";
import AddAwardForm from "../../shorts/AddAwardForm";
import AddNewButton from "../../../../products/product2.0/AddNew/AddNewButton";

export default function AwardsList({ isReadOnly, vbeid, isAdmin }) {
  const client = useApolloClient();
  const classes = useStyles();

  const [checked, setChecked] = useState([]);
  const [values, setValues] = useState([]);
  const [checkValue, setCheckValue] = useState(false);
  //   const [awardId, setAwardId] = useState([]);

  let { id } = useParams();
  if (vbeid) {
    id = vbeid;
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const checkedValues = [];

    // setAwardId(value.vbeid);

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    newChecked.map((value) => {
      return checkedValues.push(value.vbeid);
    });

    setChecked(newChecked);
    setValues(checkedValues);

    if (currentIndex === -1) {
      setCheckValue(true);
    } else if (currentIndex >= 1) {
      setCheckValue(true);
    } else if (newChecked.length < 1) {
      setCheckValue(false);
    }
  };

  const [
    removeawardmutation,
    { error: mutationError, data: removeAwardData, loading },
  ] = useMutation(removeAward, {
    onCompleted: (e) => {
      UpdateDeleteCache(e);
      setCheckValue(false);
    },
  });

  const deleteAward = (e) => {
    // e.preventDefault();

    removeawardmutation({
      variables: { input: { listvbeid: values, companyvbeid: id } },
    });
  };

  //update cache for delete
  const UpdateDeleteCache = (e) => {
    const { listAwardsByCompanyIDV1 } = client.readQuery({
      query: getAwardsByCompanyId,
      variables: { input: { companyvbeid: id } },
    });

    let listAwardCache = listAwardsByCompanyIDV1;

    listAwardCache = listAwardCache.filter((award) => {
      return values.map((id) => {
        return id !== award.id;
      });
    });

    client.writeQuery({
      query: getAwardsByCompanyId,
      data: {
        listAwardsByCompanyIDV1: {
          __typename: "generic",
          listAwardsByCompanyIDV1: listAwardCache,
        },
      },
      variables: { input: { companyvbeid: id } },
    });
  };

  return (
    <>
      {removeAwardData && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="Deleted Successfully"
        />
      )}

      {mutationError && (
        <VbridgeAlert
          severity="error"
          open={true}
          message="Something went wrong!"
        />
      )}

      {loading && (
        <VbridgeAlert severity="info" open={true} message="Please wait" />
      )}

      <Grid
        container
        spacing={1}
        justify="flex-end"
        className={classes.buttonsDiv}
      >
        {isReadOnly ? null : (
          <Grid item>
            <AddAwardForm />
          </Grid>
        )}

        {isReadOnly ? null : (
          <Grid item>
            <AddNewButton
              name="Remove Award"
              handleClick={deleteAward}
              disabled={checkValue ? false : true}
              icon="remove"
            />
          </Grid>
        )}
      </Grid>

      <MainList
        vbeid={id}
        type="awards"
        tab="company"
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        onCheck={handleToggle}
        checkedValues={values}
      />
    </>
  );
}
