import { useParams } from "react-router-dom";

import { Grid, Typography, CircularProgress } from "@material-ui/core";

import RequirementForm from "../../forms/requirement/requirementForm";

import { GET_TEMPLATE_BY_PROJECT } from "../../queries/query";

import { useQuery } from "@apollo/client";

import { useStyles } from "./styles";

export default function UpdateRequirementForm({ type, projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  const { data } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id, typelist: ["requirement_template"] } },
  });

  return (
    <Grid
      container
      alignItems="center"
      className={type === "review" ? classes.reviewReqRoot : classes.reqRoot}
    >
      <Grid item xs={12}>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Project Requirements *
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {data ? (
          data.getTemplatesByProjectID.length !== 0 ? (
            <RequirementForm
              projectId={id}
              templateId={data.getTemplatesByProjectID[0].vbeid}
              projectData={projectData}
            />
          ) : (
            <Typography variant="caption">
              Please choose a requirement template
            </Typography>
          )
        ) : (
          <CircularProgress size={20} />
        )}
      </Grid>
    </Grid>
  );
}
