import { Tooltip, withStyles } from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { useStyles } from "../groups2.0/styles";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#eee",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function ListMultiCategory({
  data,
  topicVbeid,
  groupVbeid,
  title,
  type,
  length,
  typeValue,
}) {
  const classes = useStyles();
  const history = useHistory();

  const groupBadge = clsx({
    [classes.typeBadge]: true,
    [classes.groupType]: true,
  });

  const categoryBadge = clsx({
    [classes.typeBadge]: true,
    [classes.categoryType]: true,
  });

  return type === "topic" ? (
    <LightTooltip title={title}>
      <div
        className={type === "group" ? groupBadge : categoryBadge}
        onClick={() => {
          history.push("/group/" + typeValue + "/" + groupVbeid);
        }}
      >
        {data.toUpperCase()}
      </div>
    </LightTooltip>
  ) : (
    data.map((val, index) => {
      if (index < length) {
        return (
          <LightTooltip title={title}>
            <div
              className={type === "group" ? groupBadge : categoryBadge}
              onClick={() => {
                history.push("/topic/" + typeValue + "/" + val.vbeid);
              }}
            >
              {val.name.toUpperCase()}
            </div>
          </LightTooltip>
        );
      } else {
        return null;
      }
    })
  );
}
