import { useState } from "react";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import {
  Card,
  CardContent,
  Divider,
  SwipeableDrawer,
  Tab,
  Tabs,
} from "@material-ui/core";

import CustomCkeditor from "../../shared/customckeditor";

import DataPageHeader from "../common/DataPageHeader";

import {
  //   mapServiceTypeToService,
  updateCaseStudy,
  userInfoQuery,
} from "../companies/Company2.0/query";
import { userPermission } from "../groups2.0/query";
import { getUsecaseById, updateUsecaseData } from "../Usecase2.0/usecase/query";

import { getCasestudyById } from "../CaseStudy2.0/casestudy/query";
import {
  getProductById,
  updateProductInfo,
} from "../products/product2.0/query";

import { useStyles } from "../home/styles";

import ProfileDataLoading from "../home/ProfileDataLoading";
import EditUsecase from "../companies/Company2.0/EditUsecase";

import VbridgeAlert from "../../shared/alert";
import {
  mapNewSkillToProduct,
  mapSkillsToProduct,
  mapTopicToProduct,
} from "../../shared/query";
import TabPanel from "./TabPanel";

export default function UpdateSlider({ open, product, onClose, type, logo }) {
  const classes = useStyles();
  const client = useApolloClient();

  const { vbeid } = product;

  const [editName, setEditName] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editNameValue, setEditNameValue] = useState("");
  const [editDescriptionValue, setEditDescriptionValue] = useState("");
  const [isReadOnly, setReadonly] = useState(true);
  const [isAdmin, setAdmin] = useState(true);

  const admin_role = "PROFILE_ADMIN";

  const user = client.readQuery({
    query: userInfoQuery,
    variables: { input: {} },
  });

  const userpermission = client.readQuery({
    query: userPermission,
    variables: { input: {} },
  });

  let queryName = getCasestudyById;
  let dataName = "casestudyByID";
  let updateQuery = updateCaseStudy;

  if (type === "usecases" || type === "productUseCase") {
    queryName = getUsecaseById;
    dataName = "usecaseByID";
    updateQuery = updateUsecaseData;
  } else if (type === "products" || type === "services") {
    queryName = getProductById;
    dataName = "productById";
    updateQuery = updateProductInfo;
  }

  let dataId = product?.id;
  if (!dataId) {
    dataId = vbeid;
  }

  const afterLoadingMainData = (e) => {
    setAdmin(user.getLoginUserInfo.companyvbeid === e[dataName].companyvbeid);

    setReadonly(
      !(
        (user.getLoginUserInfo.companyvbeid === e[dataName].companyvbeid &&
          user.getLoginUserInfo.role.includes(admin_role)) ||
        user.getLoginUserInfo.isVbridgeUser === "true"
      )
    );
  };

  let productInput = {
    vbeid: dataId,
    type: type === "services" ? "service" : null,
  };

  let otherInput = {
    vbeid: dataId,
  };

  const { data, loading, refetch } = useQuery(queryName, {
    variables: {
      input:
        type === "products" || type === "services" ? productInput : otherInput,
    },
    onCompleted: (e) => {
      afterLoadingMainData(e);
    },
  });

  const [
    postUsecaseMutation,
    { loading: updateLoading, data: updateData, error: updateError },
  ] = useMutation(updateQuery, {
    onCompleted: (response) => {
      updateDetailsCache(response);
    },
  });

  const [
    postDetailsMutation,
    { loading: detailsLoading, data: detailsData, error: detailsError },
  ] = useMutation(updateQuery, {
    onCompleted: (response) => {
      updateDetailsCache(response);
    },
  });

  const updateDetailsCache = (response) => {
    let updatedData = { ...data[dataName] };

    if (type === "products" || type === "services") {
      updatedData.producturl = response.updateProduct.producturl;
      updatedData.longdescription = response.updateProduct.longdescription;
      updatedData.shortdescription = response.updateProduct.shortdescription;
    } else if (type === "usecases") {
      updatedData.url = response.updateusecase.url;
      updatedData.industryvertical = response.updateusecase.industryvertical;
      updatedData.productlist = response.updateusecase.productlist.map(
        (products) => {
          return products.name;
        }
      );
      updatedData.longdescription = response.updateusecase.longdescription;
      updatedData.shortdescription = response.updateusecase.shortdescription;
    } else {
      updatedData.url = response.updatecasestudy.url;
      updatedData.businessvertical = response.updatecasestudy.businessvertical;
      updatedData.productlist = response.updatecasestudy.productlist.map(
        (products) => {
          return products.name;
        }
      );
      updatedData.longdescription = response.updatecasestudy.longdescription;
      updatedData.shortdescription = response.updatecasestudy.shortdescription;
    }

    client.writeQuery({
      query: queryName,
      data: {
        [dataName]: updatedData,
      },
      variables: { input: { vbeid: dataId } },
    });
  };

  const postUpdateUsecase = (paramName, paramValue, dataId) => {
    postUsecaseMutation({
      variables: {
        input: {
          vbeid: dataId,
          [paramName]: paramValue,
        },
      },
    });
  };

  //   const [mapTopicMutation] = useMutation(mapTopicToService, {
  //     onCompleted: (response) => {
  //       // updateCache("topicobj", values);
  //     },
  //   });

  //   const [mapProductMutation] = useMutation(mapProductToService, {
  //     onCompleted: (response) => {
  //       //   updateCache("productobj", values);
  //     },
  //   });

  //   const [addServiceTypeToService] = useMutation(mapServiceTypeToService, {
  //     onCompleted: (response) => {
  //       updateServiceTypeCache(response);
  //     },
  //   });

  // const [mapRelationMutation, { data: mapData, error: mutationError }] =
  //   useMutation(mappingRelation, {
  //     onCompleted: (response) => {
  //       updateCache("topiclist", response.relationshipmapping.listdetails);
  //     },
  //   });

  const [mapTopicProduct, { data: mapData, error: mutationError }] =
    useMutation(mapTopicToProduct, {
      onCompleted: () => {
        refetch();
      },
    });

  const [mapSkills] = useMutation(mapSkillsToProduct, {
    onCompleted: (response) => {
      refetch();
    },
  });

  const [mapNewSkill] = useMutation(mapNewSkillToProduct, {
    onCompleted: (response) => {
      refetch();
    },
  });

  const onEditUsecase = (formik, values, selectedSkills, newSkill) => {
    const {
      product,
      url,
      industryvertical,
      changeAvailability,
      sasbases,
      prices,
      supports,
      marketplace,
    } = formik.values;

    let vertical = "businessvertical";
    if (type === "usecases" || type === "productUseCase") {
      vertical = "industryvertical";
    }

    let inputVariables = {
      productlist: product,
      url: url,
      [vertical]: industryvertical,
      vbeid: dataId,
    };

    if (type === "products" || type === "services") {
      let urlInput = "producturl";

      inputVariables = {
        [urlInput]: url,
        vbeid: dataId,
        availability: changeAvailability,
        saasBased: sasbases,
        pricingModel: prices,
        supportCoverage: supports,
        marketplaceListing: marketplace,
      };
    }

    if (
      (type !== "services" && type !== "products") ||
      url !== formik.initialValues.url ||
      changeAvailability !== formik.initialValues.availability ||
      sasbases !== formik.initialValues.sasbases ||
      prices !== formik.initialValues.prices ||
      supports !== formik.initialValues.supports ||
      marketplace !== formik.initialValues.marketplace
    ) {
      postDetailsMutation({
        variables: {
          input: inputVariables,
        },
      });
    }

    if (type === "services") {
      if (values.length !== 0 && !values[0].type) {
        mapTopicProduct({
          variables: {
            input: {
              relationname: "BELONGS_TO",
              listvbeid: values,
              vbeid: dataId,
            },
          },
        });
      }

      mapSkills({
        variables: {
          input: {
            listvbeid: selectedSkills,
            vbeid: dataId,
          },
        },
      });

      if (newSkill !== "") {
        //   mapNewSkill({
        //     variables: {
        //       input: {
        //         relationname: "BELONGS_TO",
        //         listvbeid: values,
        //         vbeid: dataId,
        //       },
        //     },
        //   });
      }

      // if (!equalsIgnoreOrder(serviceType, formik.initialValues.serviceType)) {
      //   addServiceTypeToService({
      //     variables: {
      //       input: {
      //         vbeid: dataId,
      //         servicetypevbeid: serviceType,
      //       },
      //     },
      //   });
      // }
    } else if (type === "products") {
      if (values.length !== 0 && !values[0].type) {
        mapTopicProduct({
          variables: {
            input: {
              relationname: "BELONGS_TO",
              listvbeid: values,
              vbeid: dataId,
            },
          },
        });
      }
    }
  };

  //   const updateServiceTypeCache = (response) => {
  //     let updatedService = { ...data.serviceById };
  //     updatedService["servicetypelist"] =
  //       response.addServiceTypeToService.servicetypelist;

  //     client.writeQuery({
  //       query: getServiceById,
  //       data: {
  //         serviceById: updatedService,
  //       },
  //       variables: { input: { vbeid: product.id } },
  //     });
  //   };

  const handleNameChange = (e) => {
    setEditNameValue(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setEditDescriptionValue(e.target.value);
  };

  const editNameClick = () => {
    setEditName(true);
  };

  const saveNameClick = () => {
    setEditName(false);
    updateCache("name", editNameValue);
  };

  const editDescriptionClick = () => {
    setEditDescription(true);
  };

  const saveDescriptionClick = () => {
    setEditDescription(false);
    updateCache("shortdescription", editDescriptionValue);
  };

  const updateCache = (paramName, paramValue) => {
    if (paramValue !== "") {
      let updatedUsecase = { ...data[dataName] };

      updatedUsecase[paramName] = paramValue;

      if (paramName !== "topiclist") {
        client.writeQuery({
          query: queryName,
          data: {
            [dataName]: updatedUsecase,
          },

          variables: { input: { vbeid: dataId } },
        });

        if (paramName !== "servicetypelist") {
          postUpdateUsecase(paramName, paramValue, dataId);
        }
      }
    }
  };

  const saveData = (data) => {
    postUsecaseMutation({
      variables: {
        input: {
          longdescription: data,
          vbeid: dataId,
        },
      },
    });
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {(updateData || detailsData || mapData) && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="Updated Successfully "
        />
      )}

      {(updateError || detailsError || mutationError) && (
        <VbridgeAlert
          severity="error"
          open={true}
          message="Something went wrong!"
        />
      )}

      {(updateLoading || detailsLoading) && (
        <VbridgeAlert severity="info" open={true} message="Please wait" />
      )}

      <SwipeableDrawer
        anchor="right"
        open={open}
        className={classes.productDetailDiv}
        onClose={onClose}
      >
        <Card square className={classes.productDetailCard} elevation={0}>
          {!loading && data && (
            <DataPageHeader
              data={data[dataName]}
              isReadOnly={isReadOnly}
              vbeid={dataId}
              updateCache={updateCache}
              editName={editName}
              editDescription={editDescription}
              editNameClick={editNameClick}
              handleNameChange={handleNameChange}
              saveNameClick={saveNameClick}
              editDescriptionClick={editDescriptionClick}
              handleDescriptionChange={handleDescriptionChange}
              saveDescriptionClick={saveDescriptionClick}
              type={type}
              isAdmin={isAdmin}
              userPermission={userpermission}
              userInfo={user?.getLoginUserInfo}
              logo={logo}
            />
          )}

          {loading && <ProfileDataLoading />}

          <Divider />

          {type === "products" || type === "services" ? (
            <>
              <div className={classes.tabs}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Information" />
                  <Tab label="Long Description" />
                </Tabs>

                {data ? (
                  <TabPanel value={value} index={0}>
                    <EditUsecase
                      data={data}
                      id={dataId}
                      updateData={updateQuery}
                      onClick={onEditUsecase}
                      queryName={queryName}
                      dataName={dataName}
                      type={type}
                    />
                  </TabPanel>
                ) : (
                  <ProfileDataLoading />
                )}
                <TabPanel value={value} index={1}>
                  <>
                    {data ? (
                      <CardContent className={classes.sliderDescription}>
                        {data[dataName].longdescription !== "" &&
                        data[dataName].longdescription !== null ? (
                          <CustomCkeditor
                            data={data[dataName].longdescription}
                            editorId={dataId}
                            isReadOnly={isReadOnly}
                            saveMethod={saveData}
                          />
                        ) : (
                          <CustomCkeditor
                            data={data[dataName].longdescription}
                            editorId={dataId}
                            isReadOnly={isReadOnly}
                            saveMethod={saveData}
                            state={true}
                            editField="editLongDesc"
                          />
                        )}
                      </CardContent>
                    ) : (
                      <ProfileDataLoading />
                    )}
                  </>
                </TabPanel>
              </div>
            </>
          ) : (
            <>
              {data ? (
                <EditUsecase
                  data={data}
                  id={dataId}
                  updateData={updateQuery}
                  onClick={onEditUsecase}
                  queryName={queryName}
                  dataName={dataName}
                  type={type}
                />
              ) : (
                <ProfileDataLoading />
              )}

              <Divider style={{ marginTop: "16px" }} />

              {data ? (
                <CardContent className={classes.sliderDescription}>
                  {data[dataName].longdescription !== "" &&
                  data[dataName].longdescription !== null ? (
                    <CustomCkeditor
                      data={data[dataName].longdescription}
                      editorId={dataId}
                      isReadOnly={isReadOnly}
                      saveMethod={saveData}
                    />
                  ) : (
                    <CustomCkeditor
                      data={data[dataName].longdescription}
                      editorId={dataId}
                      isReadOnly={isReadOnly}
                      saveMethod={saveData}
                      state={true}
                      editField="editLongDesc"
                    />
                  )}
                </CardContent>
              ) : (
                <ProfileDataLoading />
              )}
            </>
          )}
        </Card>
      </SwipeableDrawer>
    </>
  );
}
