import * as React from "react";

import { useStyles } from "./styles";

import {
  TextField,
  Select,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import CustomisedButton from "./shared";

export default function CasestudyFilters({ formik, method, batches }) {
  const classes = useStyles();

  return (
    <Grid container md={12} spacing={1}>
      <Grid item>
        <FormControl
          className={classes.filterBatch}
          variant="outlined"
          margin="dense"
        >
          <InputLabel>Select Batch Id</InputLabel>

          <Select
            label="Batch"
            value={formik.values.batchvbeid}
            onChange={formik.handleChange("batchvbeid")}
          >
            {batches.getBatchesList.map((value) => {
              return <MenuItem value={value.id}>{value.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <TextField
          margin="dense"
          label="From"
          variant="outlined"
          value={formik.values.pagefrom}
          onChange={formik.handleChange("pagefrom")}
        />
      </Grid>

      <Grid item>
        <FormControl
          className={classes.filterSize}
          variant="outlined"
          margin="dense"
        >
          <InputLabel htmlFor="outlined-age-native-simple">Size</InputLabel>

          <Select
            label="size"
            value={formik.values.pageto}
            onChange={formik.handleChange("pageto")}
          >
            <MenuItem value={31}>30</MenuItem>
            <MenuItem value={51}>50</MenuItem>
            <MenuItem value={71}>70</MenuItem>
            <MenuItem value={101}>100</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item className={classes.searchButton}>
        <CustomisedButton buttonText="Search" method={method} />
      </Grid>
    </Grid>
  );
}
