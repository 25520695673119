import { useMutation, useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import AddNewButton from "../products/product2.0/AddNew/AddNewButton";
import EntityViewCard from "./EntityViewCard";
import LegalEntitySwiper from "./LegalEntitySwiper";
import { listLegalEntity, updateEditLegalEntity } from "./query";

import { useStyles } from "./styles";

export default function LegalEntityPage({ userInfo }) {
  const [swipperOpen, setSwipperOpen] = useState(false);
  const classes = useStyles();

  const handleSwipperOpen = () => {
    setSwipperOpen(true);
  };

  const handleSwipperClose = () => {
    setSwipperOpen(false);
    // clearForm();
  };

  // list Legal Entity Submit Api Call
  const { data: list, refetch } = useQuery(listLegalEntity, {
    variables: { input: {} },
  });

  const [
    editLegalEntity,
    {
      loading: updatemutationLoading,
      error: updatemutationError,
      data: update,
    },
  ] = useMutation(updateEditLegalEntity, {
    onCompleted: (e) => {
      refetch();
      handleSwipperClose();
    },
  });

  const submitForm = (values) => {
    editLegalEntity({
      variables: {
        input: values,
      },
    });
  };

  return (
    <>
      {" "}
      {/* {updatemutationLoading ? <SimpleSnackbar type="loading" /> : null} */}
      <Grid container md={12} className={classes.root}>
        <Grid item md={12} className={classes.entityinfo}>
          <Alert severity="info" className={classes.item}>
            Please add a valid legal entity to your company profile (accounting
            email, tax id, and tax forms are set via legal entities and may
            require contacting your internal teams for such information). This
            section contains your company's billing information including:
            <br />
            <span style={{ marginLeft: "16px" }}>i. Billing Addresses </span>
            <br />
            <span style={{ marginLeft: "16px" }}>ii. Banking Details </span>
            <br />
            <span style={{ marginLeft: "16px" }}>
              iii. Accounting / Invoicing Contacts
            </span>
            <br />
            <span style={{ marginLeft: "16px" }}>
              iv. Tax / VAT Information{" "}
            </span>
            <br />
            Please Note: For all legal entities added under your company
            profile, you are responsible for them in accordance with Section A
            of the{" "}
            <a
              className={classes.link}
              href="https://www.vbridgehub.com/docs/buyer-terms-of-use.pdf"
            >
              Buyer Terms of Use.
            </a>
          </Alert>
        </Grid>

        <Grid container md={12} className={classes.item}>
          <Grid item md={12} className={classes.entityinfo}>
            <AddNewButton
              label="Create a Legal Entity"
              name="Create a Legal Entity"
              variant="contained"
              type="contained"
              handleClick={handleSwipperOpen}
            />
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.cardgrid} direction="row">
          {list?.getLegalEntity?.length >= 1 ? (
            <Grid item md={10}>
              <p className={classes.header}>Legal Entity Details</p>
            </Grid>
          ) : null}
          {list?.getLegalEntity?.map((legalentity) => {
            if (legalentity.achieved !== "yes") {
              return (
                <Grid item md={4} className={classes.entitycard}>
                  <EntityViewCard
                    submitForm={submitForm}
                    legalentity={legalentity}
                    userInfo={userInfo}
                    refetch={refetch}
                  />
                </Grid>
              );
            } else return null;
          })}
        </Grid>

        {swipperOpen ? (
          <LegalEntitySwiper
            open={swipperOpen}
            onClose={handleSwipperClose}
            title="Legal Entity"
            helpertext="(i.e. the name of your organization used within contracts/agreements/Purchase Orders/Invoices)"
            userInfo={userInfo}
            submitForm={submitForm}
            // clearForm={clearForm}
          />
        ) : null}
      </Grid>
    </>
  );
}
