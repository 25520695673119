import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
  },
  card: {
    maxWidth: "100%",
    color: "#0e3046",
    lineHeight: "1.8",
    position: "relative",
  },
  addList: {
    display: "block",
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    height: "0",

    position: "absolute",
    overflow: "visible",
    right: theme.spacing(1),
  },
  square: {
    width: 100,
    height: 100,
    marginTop: "1%",
    marginLeft: "1%",
  },
  ngrid: {
    marginBottom: "3%",
  },
  subHeading: {
    color: theme.palette.secondary.main,
    textAlign: "left",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  tabRoot: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "white",
  },
  tabs: {
    fontSize: "0.8rem",
    textTransform: "none",
    "& span": {
      textTransform: "capitalize",
    },
  },
}));
