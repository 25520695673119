import { Avatar, Chip, Grid, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { theme } from "../../../../theme/theme";
import CheckIcon from "@material-ui/icons/Check";
import { useQuery } from "@apollo/client";
import { getCompanyById } from "./query";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  mainListGrid: {
    margin: theme.spacing(3, 1),
    display: "flex",
  },
  skillname: {
    margin: theme.spacing(1),
  },
  skills: {
    margin: theme.spacing(1),
  },
  item: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
  avtar: {
    margin: theme.spacing(1),
    color: "#232961 !important",

    "& div": {},
  },
}));

function Skills(props) {
  const classes = useStyles();

  const { id } = useParams();

  const { data } = useQuery(getCompanyById, {
    variables: { input: { vbeid: id } },
  });

  return (
    <div container className={classes.mainListGrid}>
      <Grid container md={12}>
        {data.queryCompanybyid?.skilllist.map((data) => {
          return (
            <div className={classes.item}>
              <Grid item md={2}>
                <Chip
                  avatar={
                    <Avatar className={classes.avtar}>
                      <CheckIcon />
                    </Avatar>
                  }
                  label={data.name}
                />
              </Grid>
            </div>
          );
        })}
      </Grid>
    </div>
  );
}

export default Skills;
