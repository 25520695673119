import { gql } from "@apollo/client";

export const getServiceById = gql`
  query serviceInformation($input: serviceinput!) {
    serviceById(input: $input) {
      name
      shortdescription
      vbeid
      companyvbeid
      companyname
      longdescription
      url
      servicetypeobj {
        name
        vbeid
      }
      topicobj {
        name
        vbeid
      }
      casestudyobj {
        name
        vbeid
      }
      productobj {
        name
        vbeid
      }
      countryobj {
        name
        vbeid
      }
    }
  }
`;

export const getServices = gql`
  query getServices($input: serviceinput!) {
    servicesById(input: $input) {
      name
      shortdescription
      vbeid
      companyvbeid
      longdescription
    }
  }
`;

export const getSimilarServices = gql`
  query getSimilarServices($input: serviceinput!) {
    servicesBySServiceId(input: $input) {
      name
      vbeid
      id
    }
  }
`;

export const updateServiceInfo = gql`
  mutation update($input: serviceinput!) {
    updateService(input: $input) {
      name
      longdescription
    }
  }
`;

export const postService = gql`
  mutation updateService($input: serviceinput!) {
    updateService(input: $input) {
      longdescription
    }
  }
`;

export const mapTopicToService = gql`
  mutation mapTopicToService($input: serviceinput!) {
    addServiceToTopic(input: $input) {
      id
    }
  }
`;

export const mapProductToService = gql`
  mutation mapProductToService($input: serviceinput!) {
    addServiceToProduct(input: $input) {
      id
    }
  }
`;

export const addCountryToService = gql`
  mutation addCountryToService($input: serviceinput!) {
    addCountrytoService(input: $input) {
      id
    }
  }
`;
