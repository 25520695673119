import React, { useState } from "react";

import { useStyles } from "./styles";

import { useLazyQuery, useQuery } from "@apollo/client";
import { addDocuments, getlistDocuments } from "./query";

import { useMutation } from "@apollo/client";

import AddSwiper from "./AddSwiper";
import SimpleSnackbar from "../groups2.0/snackbar";
import SimpleErrorSnackbar from "../groups2.0/errorsnackbar";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AlertBox from "../common/AlertBox";
import OutlinedButton from "../companies/company/OutlinedButton";
import Paper from "@material-ui/core/Paper";
import { Alert } from "@material-ui/lab";
import { getSigned } from "../legalentity/query";

function Row({ doc, docFetch, userInfo }) {
  const [alertBoxEnable, setAlertBoxEnable] = useState(false);
  const [tagEnable, setTagEnable] = useState(doc?.action);

  const classes = useStyles();

  const [signedUrl, { data: url, loading: getUrlLoading }] = useLazyQuery(
    getSigned,
    {
      onCompleted: (e) => {
        window.open(e.getSignedURL.signedurl, "_blank");
      },
    }
  );

  function CreateUrl(key, filetype) {
    signedUrl({
      variables: {
        input: {
          key: key,
          filetype: filetype,
        },
      },
    });
  }

  const handleEnableOpen = (id) => {
    setTagEnable(!tagEnable);
    setAlertBoxEnable(true);
  };
  const handleEnableClose = () => {
    setAlertBoxEnable(false);
  };

  //Remove

  //Enable and disable

  const [
    enableDisable,
    { loading: mutationLoad, error: mutationErrors, data: mutationdata },
  ] = useMutation(addDocuments, {
    onCompleted: (e) => {
      docFetch();
    },
  });

  const handleEnableDisable = () => {
    // e.preventDefault();
    enableDisable({
      variables: {
        input: {
          timestamp: doc?.createdon,
          action: tagEnable.toString(),
          name: doc?.name,
          type: doc?.type,
          shortdescription: doc?.shortdescription,
          attachment: doc?.attachment,
        },
      },
    });
  };

  return (
    <React.Fragment>
      {mutationLoad || mutationdata ? <SimpleSnackbar type="loading" /> : null}
      {mutationErrors ? (
        <SimpleErrorSnackbar msg="Something Went Wrong" />
      ) : null}

      <>
        <TableRow className={classes.root} key={doc.id}>
          <TableCell
            align="left"
            className={doc.action === "false" ? classes.disabledata : null}
          >
            {doc.name}
          </TableCell>
          <TableCell
            align="left"
            className={doc.action === "false" ? classes.disabledata : null}
          >
            {doc.type}
          </TableCell>

          <TableCell
            align="left"
            className={doc.action === "false" ? classes.disabledata : null}
          >
            {doc.shortdescription}
          </TableCell>

          <TableCell
            align="left"
            className={
              doc.action === "false" ? classes.disabledata : classes.attachment
            }
            onClick={() =>
              CreateUrl(
                doc?.attachment,
                doc?.attachment?.split(".").pop().split(/\#|\?/)[0]
              )
            }
          >
            {doc?.attachment?.split("/").pop() || (
              <span style={{ color: "#828282" }}>No attachment found</span>
            )}
          </TableCell>

          <TableCell align="left">
            <span
              className={classes.button}
              // disabled={tag.id === "project" ? false : true}
              onClick={() => handleEnableOpen()}
            >
              {" "}
              {doc.action === "true" ? "Disable" : "Enable"}
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
            className={classes.expandtable}
          ></TableCell>
        </TableRow>
      </>

      {alertBoxEnable ? (
        <AlertBox
          open={alertBoxEnable}
          title={doc.action === true ? "Disable Document " : "Enable Document"}
          onClose={handleEnableClose}
          description={
            doc.action === "true" || doc.action === null
              ? "Are you sure you want to disable document ?"
              : "Are you sure you want to enable document ?"
          }
          onConfirm={handleEnableDisable}
        />
      ) : null}
    </React.Fragment>
  );
}

export default function DocumentMangement({ userInfo }) {
  const classes = useStyles();

  const { data: listDoc, refetch: docFetch } = useQuery(getlistDocuments, {
    variables: { input: {} },
  });
  const [swipperOpen, setSwipperOpen] = useState(false);

  const handleSwipperOpen = () => {
    setSwipperOpen(true);
  };

  const handleSwipperClose = () => {
    setSwipperOpen(false);
  };

  return (
    <div className={classes.tagtable}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={8}>
          {" "}
          <h4
            style={{
              textAlign: "left",
              // padding: "8px",
              marginBottom: "16px",
              fontWeight: "500",
            }}
          >
            Document Management
          </h4>
        </Grid>
        <Grid
          item
          md={3}
          style={{
            textAlign: "left",
            // padding: "8px",
            marginBottom: "2px",
            fontWeight: "500",
          }}
        >
          <OutlinedButton
            label="+ Add Documnet"
            type="contained"
            onClick={handleSwipperOpen}
          />
        </Grid>

        <Grid item md={11} className={classes.entityinfo}>
          <Alert severity="info" className={classes.item}>
            Define the documents that your organization has to receive from the
            suppliers during the various stages of Project life cycle.
          </Alert>
        </Grid>

        <Grid item md={11}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Project Type</TableCell>
                  <TableCell align="center">Description</TableCell>
                  <TableCell align="center">Attachment</TableCell>

                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              {listDoc?.listDocuments.length !== 0 ? (
                <TableBody>
                  {listDoc?.listDocuments?.map((doc) => {
                    return (
                      <Row doc={doc} userInfo={userInfo} docFetch={docFetch} />
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  {" "}
                  <TableRow>
                    <TableCell align="center !important" colSpan={2}>
                      <p>No Documents Found</p>
                    </TableCell>
                  </TableRow>
                  {/* <p className={classes.nodocs}>No Tags found</p> */}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {swipperOpen ? (
        <AddSwiper
          open={swipperOpen}
          onClose={handleSwipperClose}
          type="documnet"
          title="Document Management"
          refetch={docFetch}
          userInfo={userInfo}
          // id={role.id}
          // userInfo={userInfo}
          // title={title}
        />
      ) : null}
    </div>
  );
}
