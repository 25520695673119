import {
  Avatar,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useState } from "react";

import { VBRIDGEHUB_MEDIAURL } from "../../../../../constants";
import { getFormattedDate } from "../../../../../functions/helper";

import InputField from "../../../common/InputField";

import dateDiff from "./dateDiff";
import JDSlider from "./JDSlider";

import { useStyles } from "./styles";

export default function SowForm({
  formik,
  selectedResource,
  projectData,
  submitFileUpload,
  attachments,
  view,
  formikError,
}) {
  const classes = useStyles();

  const [jdSliderOpen, setJDSliderOpen] = useState(false);

  //   const getAttachments = () => {
  //     return attachments.map((file) => {
  //       return (
  //         <div style={{ fontSize: "0.8rem", marginBottom: "4px" }}>
  //           {file.name}
  //         </div>
  //       );
  //     });
  //   };

  const getDuration = () => {
    let startDate = formik.values.startDate;
    let endDate = formik.values.endDate;

    let jsStartDate = new Date(startDate);
    let jsEndDate = new Date(endDate);

    let timeDiff = dateDiff(jsStartDate, jsEndDate);

    return timeDiff.months + " months, " + timeDiff.days + " days";
  };

  const onViewJD = () => {
    setJDSliderOpen(true);
  };

  const onClose = () => {
    setJDSliderOpen(false);
  };

  return (
    <>
      <Grid container className={classes.sowDiv}>
        <Grid item md={4}>
          <Avatar
            alt="company logo"
            variant="square"
            className={classes.logoimage}
            src={VBRIDGEHUB_MEDIAURL + "/" + selectedResource?.image}
          >
            :(
          </Avatar>
        </Grid>

        <Grid item md={4}></Grid>

        <Grid item md={4} className={classes.sowLogo}>
          <Avatar
            alt="company logo"
            variant="square"
            className={classes.logoimage}
            src={VBRIDGEHUB_MEDIAURL + "/" + projectData.image}
          >
            :(
          </Avatar>
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.sowTitle}>
        <Grid item md={12}>
          <Typography className={classes.sowHeading}>
            SoW against {projectData.name}
          </Typography>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "600" }} rowSpan={2}>
                Supplier Name
              </TableCell>

              <TableCell style={{ fontWeight: "600" }} rowSpan={2}>
                Candidate Name
              </TableCell>

              <TableCell
                style={{ fontWeight: "600" }}
                colSpan={2}
                align="center"
              >
                Engagement Period
              </TableCell>

              <TableCell style={{ fontWeight: "600" }} rowSpan={2}>
                Billing Mode
              </TableCell>

              <TableCell style={{ fontWeight: "600" }} rowSpan={2}>
                Currency
              </TableCell>

              <TableCell style={{ fontWeight: "600" }} rowSpan={2}>
                Finalised Rate
              </TableCell>

              <TableCell style={{ fontWeight: "600" }} rowSpan={2}>
                Job Description
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: "600" }} align="center">
                Start
              </TableCell>

              <TableCell style={{ fontWeight: "600" }} align="center">
                End
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>{selectedResource.companyname}</TableCell>

              <TableCell>{selectedResource.name}</TableCell>

              <TableCell>
                <FormControl fullWidth={true} style={{ verticalAlign: "sub" }}>
                  {view === "viewSow" ? (
                    <div>{getFormattedDate(formik.values.startDate)}</div>
                  ) : (
                    <InputField
                      label=""
                      onChange={formik.handleChange("startDate")}
                      value={formik.values.startDate}
                      type="date"
                      variant="standard"
                      placeholderColor="red"
                    />
                  )}
                </FormControl>
              </TableCell>

              <TableCell>
                <FormControl fullWidth={true} className={classes.sowSubValue}>
                  {view === "viewSow" ? (
                    <div>{getFormattedDate(formik.values.endDate)}</div>
                  ) : (
                    <InputField
                      label=""
                      onChange={formik.handleChange("endDate")}
                      value={formik.values.endDate}
                      type="date"
                      variant="standard"
                      placeholderColor="red"
                    />
                  )}
                </FormControl>
              </TableCell>

              <TableCell>Per Hour</TableCell>

              <TableCell>{selectedResource.currency}</TableCell>

              <TableCell>
                {selectedResource.revisedbillingrate ||
                  selectedResource.billingrate}
              </TableCell>

              <TableCell onClick={onViewJD} style={{ cursor: "pointer" }}>
                View
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {formik.values.startDate && formik.values.endDate && (
        <Grid container spacing={1}>
          <Grid md={12} style={{ textAlign: "center", fontSize: "0.85rem" }}>
            <p>Duration: {getDuration()}</p>
          </Grid>
        </Grid>
      )}

      <JDSlider
        projectData={projectData}
        jdSliderOpen={jdSliderOpen}
        onClose={onClose}
      />
    </>
  );
}
