import { useMutation } from "@apollo/client";

import { Card, Divider, Grid, SwipeableDrawer } from "@material-ui/core";

import { useStyles } from "../../../home/styles";

import DiscussionCard from "../../../messaging2.0/discussionCard";

import { REQUEST_FOR_CONCIERGE } from "../../queries/query";

export default function ConciergeSlider({
  open,
  onClose,
  projectVbeid,
  project,
}) {
  const classes = useStyles();

  const [requestConcierge, { data }] = useMutation(REQUEST_FOR_CONCIERGE, {
    onCompleted: (e) => {},
  });

  if (!project.conciergestatus && !data) {
    requestConcierge({
      variables: { input: { vbeid: projectVbeid, remarks: "" } },
    });
  }

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      className={classes.productDetailDiv}
      onClose={onClose}
    >
      <Card square className={classes.productDetailCard} elevation={0}>
        <div style={{ margin: "8px", fontSize: "1.2rem" }}>Concierge</div>
        <Divider />

        <Grid container style={{ marginTop: "8px" }}>
          <Grid item xs={12}>
            <DiscussionCard
              productId={"concierge0001"}
              projectId={projectVbeid}
              type="question"
            />
          </Grid>
        </Grid>
      </Card>
    </SwipeableDrawer>
  );
}
