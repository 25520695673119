import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { Grid, Typography } from "@material-ui/core";
// import Button from '@material-ui/core/Button';
// import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    marginLeft: "40%",
    marginTop: "-8%",
  },

  logo: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  logoImg: {
    width: "30%",
  },
  formPaper: {
    padding: "6%",
  },
  formBox: {
    marginTop: "5%",
    marginLeft: "30%",
    marginRight: "30%",
  },
  formControl: {
    marginBottom: "3%",
  },
  formComboBox: {
    height: theme.spacing(0.1),
  },
  buttonRow: {
    marginBottom: "3%",
  },
  bgroup: {
    margin: "auto",
    marginTop: theme.spacing(2),
  },
}));

function Logo() {
  const classes = useStyles();
  return (
    <div className={classes.logo}>
      <Link href="/">
        <img
          alt="img"
          src="https://media-dev.vbridgehub.com/vbridgelogo.png"
          className={classes.logoImg}
        />
      </Link>
    </div>
  );
}

export default function EmailError(props) {
  const classes = useStyles();

  return (
    <Box className={classes.formBox}>
      <Paper elevation={0} className={classes.formPaper}>
        <Logo />
        <Typography>
          An account with this email has been registered but not activated.
          Please check your mail for activation link or register again.
        </Typography>
        <Grid container></Grid>
      </Paper>
    </Box>
  );
}
