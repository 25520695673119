/**
 * Component to check Agreement Signing Status on Zoho
 */
import React from "react";
import { useHistory } from "react-router-dom";

import { useQuery } from "@apollo/client";

import { useStyles } from "./styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import MainLoading from "../../shared/loading";

import { getNDAStatus } from "../companies/company/query";
import OutlinedButton from "../companies/company/OutlinedButton";

export default function AgreementSuccess() {
  const classes = useStyles();
  const history = useHistory();

  const { loading, data } = useQuery(getNDAStatus, {
    variables: {},
    fetchPolicy: "network-only",
  });

  if (loading) {
    return (
      <>
        <MainLoading />
        <div className={classes.loadingText}>
          Please wait while we get a confirmation
        </div>
      </>
    );
  }

  return (
    <div className={classes.root}>
      {data && data.getNDAStatus ? (
        data.getNDAStatus?.status === "completed" ? (
          <>
            <CheckCircleOutlineIcon className={classes.statusIcon} />

            <div className={classes.ndaText}>Accepted</div>

            <div className={classes.heading}>
              Mutual NDA document is successfully signed and accepted.
            </div>

            <div>
              You will get a badge -{" "}
              <span className={classes.ndaText}>“NDA”</span> - against your
              profile which will be visible to visitors on your profile page.{" "}
            </div>

            <div className={classes.agreeButton}>
              <OutlinedButton
                label="Go to Home Page"
                onClick={() => {
                  history.push("/");
                }}
                type="contained"
              />
            </div>
          </>
        ) : (
          <>
            <div className={classes.heading} style={{ marginTop: "150px" }}>
              Something went wrong!
            </div>
            <div className={classes.agreeButton}>
              <OutlinedButton
                label="Go to Home Page"
                onClick={() => {
                  history.push("/");
                }}
                type="contained"
              />
            </div>
          </>
        )
      ) : null}
    </div>
  );
}
