import { useParams, useHistory } from "react-router-dom";

import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";

import RequirementForm from "../../forms/requirement/requirementForm";

import { GET_PROJECT, GET_TEMPLATE_BY_PROJECT } from "../../queries/query";

import { useQuery } from "@apollo/client";
import { useStyles } from "./styles";
import PageButtons from "../createProject/WorkPackage/PageButtons";

const ButtonGroup = ({ id, loading }) => {
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            history.push("/requirement-template/" + id);
          }}
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={5}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            history.push("/other-templates/" + id);
          }}
          disabled={loading}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

export default function UpdateRequirementForm() {
  const classes = useStyles();
  const { id } = useParams();

  const { data: projectData } = useQuery(GET_PROJECT, {
    variables: { input: { vbeid: id }, fetchPolicy: "no-cache" },
  });

  const { data } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id, typelist: ["requirement_template"] } },
  });

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Write your requirements
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {data ? (
          data.getTemplatesByProjectID.length !== 0 ? (
            <RequirementForm
              projectId={id}
              templateId={data.getTemplatesByProjectID[0].vbeid}
              projectData={projectData?.getProjectByIDV1}
            />
          ) : (
            <Typography variant="caption">
              Please choose a requirement template
            </Typography>
          )
        ) : (
          <CircularProgress size={20} />
        )}
      </Grid>

      {projectData && (
        <Grid item xs={12} style={{ marginTop: "32px" }}>
          {projectData?.getProjectByIDV1.type === "service-workpackage" ? (
            <PageButtons
              nextLocation="requirementTemplate"
              nextDisabled={false}
              prevDisabled={false}
            />
          ) : (
            <ButtonGroup id={id} />
          )}
        </Grid>
      )}
    </Grid>
  );
}
