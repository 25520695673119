import { useQuery } from "@apollo/client";

import { Popover } from "@material-ui/core";

import { searchQuery } from "../entities/home/query";

import { useStyles } from "./appBarStyles";

import LoadingResult from "./LoadingResult";
import SearchResultList from "./SearchResultList";

export default function SearchResult({
  id,
  open,
  anchorEl,
  setAnchorEl,
  text,
  n,
  type,
  onSelect,
  toggleValue,
  handleDrawerClose,
}) {
  const classes = useStyles();

  let searchInput = { name: text };
  if (type === "service") {
    searchInput = { name: text, index: "company" };
  }

  const { loading, data } = useQuery(searchQuery, {
    variables: { input: searchInput },
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectResult = (result) => {
    onSelect(result);
  };

  return (
    <Popover
      className={classes.popover}
      id={id}
      open={open}
      anchorEl={anchorEl}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {loading ? (
        <LoadingResult />
      ) : (
        data && (
          <SearchResultList
            result={data.esQuery?.result.slice(0, n)}
            handleClose={handleClose}
            type={type}
            onSelect={onSelectResult}
            toggleValue={toggleValue}
            handleDrawerClose={handleDrawerClose}
          />
        )
      )}
    </Popover>
  );
}
