import * as React from "react";

import { useQuery } from "@apollo/client";

import { Grid } from "@material-ui/core";

import { GET_FIXED_COST } from "../../queries/query";

import MileStonePrice from "./milestone";
import LoadingResult from "../../../../shared/LoadingResult";

const getValues = (values) => {
  if (values.length > 0) {
    return JSON.parse(values[0].data);
  } else {
    return { fileName: null, data: [], date: null };
  }
};

export default function MileStoneList(props) {
  const { loading, data: rdata } = useQuery(GET_FIXED_COST, {
    fetchPolicy: "no-cache",
    variables: {
      input: { projectid: props.projectid, productvbeid: props.productvbeid },
    },
  });

  return (
    <>
      {rdata ? (
        <MileStonePrice
          productvbeid={props.productvbeid}
          projectid={props.projectid}
          data={getValues(rdata.getFixedPriceItemvalue)}
          readOnly={props.readOnly}
        />
      ) : null}

      {loading ? (
        <Grid
          container
          xs={10}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "32px 0",
          }}
        >
          <LoadingResult />
        </Grid>
      ) : null}
    </>
  );
}
