import { Card, CardHeader, Grid, SwipeableDrawer } from "@material-ui/core";
import { useState } from "react";
import AddUser from "./AddUser";
import { useStyles } from "./styles";
import TagAddUser from "./TagAddUser";

export default function AddSwiper({
  open,
  product,
  onClose,
  type,
  logo,
  refetch,
  id,
  userInfo,
  title,
}) {
  const classes = useStyles();

  const [swiperOpen, setSwiperOpen] = useState(false);

  const handleOpen = () => {
    setSwiperOpen(true);
  };

  return (
    <>
      {/* {(updateData || detailsData || mapData) && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="Updated Successfully "
        />
      )}

      {(updateError || detailsError || mutationError) && (
        <VbridgeAlert
          severity="error"
          open={true}
          message="Something went wrong!"
        />
      )}

      {(updateLoading || detailsLoading) && (
        <VbridgeAlert severity="info" open={true} message="Please wait" />
      )} */}

      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={onClose}
        className={classes.swiper}
      >
        <Card square className={classes.card} elevation={0}>
          <Grid container>
            <Grid item md={12}>
              <p className={classes.sliderTitle}>{title}</p>
            </Grid>
            {type === "tag" && (
              <TagAddUser refetch={refetch} onClose={onClose} />
            )}

            {type === "role" && (
              <AddUser
                refetch={refetch}
                id={id}
                userInfo={userInfo}
                onClose={onClose}
              />
            )}
          </Grid>
        </Card>
      </SwipeableDrawer>
    </>
  );
}
