import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  responseHeading: {
    marginTop: "8px",
    padding: "8px 0",
    backgroundColor: "#eee",
    fontWeight: "500",
  },
}));
