import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  dropdown: {
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  dropbtn: {
    color: "white",
    padding: "12px 16px",
    margin: "0 8px",
    textTransform: "capitalize",
    fontSize: "0.85rem",
  },
  serviceDropbtn: {
    color: "white",
    padding: "4px 16px",
    margin: "0 8px",
    textTransform: "capitalize",
    fontSize: "0.85rem",
  },
  dropdownContent: {
    display: "block",
    position: "absolute",
    backgroundColor: "#f9f9f9",
    minWidth: "160px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: "1",
    marginTop: "48px",
  },
  dropdownItem: {
    color: "black",
    padding: "8px 16px",
    cursor: "pointer",
    textAlign: "left",
    fontSize: "0.9rem",
    "&:hover": {
      backgroundColor: "#efefef",
    },
  },
  dropdownGroupContent: {
    position: "absolute",
    backgroundColor: "#f9f9f9",
    minWidth: "200px",
    maxWidth: "200px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: "1",
    margin: "48px 0 0 -200px",
    maxHeight: "400px",
    overflow: "auto",
    padding: "12px 0 16px",
  },
  groupItem: {
    color: "black",
    padding: "6px 12px",
    cursor: "pointer",
    textAlign: "left",
    fontSize: "0.8rem",
    "&:hover": {
      backgroundColor: "#ebebeb",
    },
  },
  techGroupContent: {
    minWidth: "500px",
    margin: "50px 0 0 -500px",
  },
  groupColumn: {
    width: "50%",
  },
  groupsList: {
    display: "flex",
  },
}));
