import React, { useState } from "react";
import PropTypes from "prop-types";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import ProductOverview from "./ProductOverview";
import { useStyles } from "./styles";
import MainList from "../../groups2.0/MainList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function ProductTabs({
  isReadOnly,
  isAdmin,
  type,
  UpdateProductCache,
  data,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  console.log("type", type);

  let serviceTabIndex = 2;
  if (type === "service") serviceTabIndex = 1;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.tabRoot}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          className={classes.tabs}
          label={type === "product" ? "Overview" : "Service Overview"}
          {...a11yProps(0)}
        />

        <Tab
          className={classes.tabs}
          label="Success Stories"
          {...a11yProps(serviceTabIndex)}
        />
        {type !== "service" && (
          <Tab className={classes.tabs} label="Resources" {...a11yProps(1)} />
        )}
      </Tabs>
      <Divider />

      <TabPanel value={value} index={0}>
        <ProductOverview
          isReadOnly={isReadOnly}
          isAdmin={isAdmin}
          type={type}
          info={data}
          UpdateProductCache={UpdateProductCache}
        />
      </TabPanel>

      {type !== "service" && (
        <>
          <TabPanel value={value} index={serviceTabIndex}>
            <MainList
              type="productUseCase"
              tab="usecase"
              isReadOnly={isReadOnly}
              isAdmin={isAdmin}
            />
          </TabPanel>
        </>
      )}

      <TabPanel value={value} index={1}>
        <MainList
          type={type === "service" ? "serviceCaseStudy" : "productCaseStudy"}
          tab="caseStudy"
          isReadOnly={isReadOnly}
          isAdmin={isAdmin}
        />
      </TabPanel>
    </div>
  );
}
