import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { useFormik } from "formik";
import * as yup from "yup";

import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import SimpleSnackbar from "./snackbar";
import SimpleErrorSnackbar from "./errorsnackbar";

import { BootstrapInput } from "../../../../../theme/theme";
import CompanySearch from "../../../../shared/companySearch";
import { getCustomersByCompanyId, postCustomer } from "../query";
import AddNewButton from "../../../products/product2.0/AddNew/AddNewButton";

import { useStyles } from "./styles";
import { OpenInBrowserOutlined } from "@material-ui/icons";

const verticals = require("../../../../../data/companyVerticals.json");

export default function AddCustomerForm({ vbeid }) {
  const classes = useStyles();
  const client = useApolloClient();
  const timer = useRef();

  const [open, setOpen] = useState(false);
  const [groupValue, setGroupValue] = useState([]);
  const [customerValue, setCustomerValue] = useState("");

  let { id } = useParams();
  if (vbeid) {
    id = vbeid;
  }

  const groupCheck = () => {
    const newVal = [];

    verticals.map((group) => {
      if (group["n.type"] === "Company Vertical") {
        return newVal.push(group["n.name"]);
      } else return null;
    });

    setGroupValue(newVal);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!mutationLoading) {
      //   setLoading(true);

      timer.current = window.setTimeout(() => {
        // setLoading(false);
      }, 500);
    }
  };

  const handleformik = () => {};

  const handleClickOpen = () => {
    setOpen(true);
    groupCheck();
  };

  const references = ["Yes", "No"];

  const validationSchema = yup.object().shape({
    customerurl: yup
      .string("Enter your Customer URL")
      .required("Customer URL is required")
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        "Enter valid Customer URL"
      ),

    vertical: yup
      .string("Enter your Vertical")
      .oneOf(groupValue, "Please select a valid Vertical")
      .required("Vertical is required"),

    name: yup
      .string("Enter your Customer Name")
      .min(2, "Customer Name should be of minimum 2 characters length")
      .required("Customer Name is required"),

    reference: yup.string("Enter Reference Type").required("required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      customerurl: "",
      vertical: "",
      companyid: "",
      reference: "",
    },
    validationSchema: validationSchema,
  });

  const [customerUrlData, setCustomerUrlData] = useState("");

  const setName = (text) => {
    formik.values.name = text;
  };

  const setDomain = (text) => {
    formik.values.customerurl = text;
    setCustomerUrlData(text);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    // let emptyUrl = "";
    setCustomerUrlData("");

    // formik.current.reset();

    // formik.setFieldValue(formik.values.customerurl, "null");
    // // console.log(" formik.setFieldValue", formik.setFieldValue);
    // console.log(
    //   " formik.resetForm",
    //   formik.setFieldValue(formik.values.customerurl, "null")
    // );
  };

  const [
    postCustomerMutation,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(postCustomer, {
    onCompleted: (e) => {
      updateCustomersInformationCache(e);
    },
  });

  let refvalue = true;

  const setrefValue = (value) => {
    refvalue = value;
  };

  if (formik.values.reference === references[0]) {
    setrefValue(true);
  } else {
    setrefValue(false);
  }

  const createCustomerShort = (e) => {
    postCustomerMutation({
      variables: {
        input: {
          name: formik.values.name,
          customerurl: formik.values.customerurl,
          vertical: formik.values.vertical,
          referenceable: refvalue,
          companyid: id,
        },
      },
    });

    handleButtonClick();
  };

  useQuery(getCustomersByCompanyId, {
    variables: { input: { vbeid: id } },
  });

  const updateCustomersInformationCache = (e) => {
    const { customersByCompanyId } = client.readQuery({
      query: getCustomersByCompanyId,
      variables: { input: { vbeid: id } },
    });

    let listCustomersInformationCache = [...customersByCompanyId];

    client.writeQuery({
      query: getCustomersByCompanyId,
      data: {
        customersByCompanyId: listCustomersInformationCache.unshift({
          ...e.addCustomer,
          __typename: "company",
        }),
      },
      variables: { input: { vbeid: id } },
    });

    handleClose();
  };

  const setCompany = (value) => {
    setCustomerValue(value);
  };

  return (
    <div>
      {mutationError && <SimpleErrorSnackbar />}
      {data && <SimpleSnackbar type="success" />}
      {mutationLoading && <SimpleSnackbar type="loading" />}

      <AddNewButton name="Add New Customer" handleClick={handleClickOpen} />

      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Customer</DialogTitle>

          <DialogContent dividers>
            <DialogContentText>
              Please Enter Customer Details in the following fields.
            </DialogContentText>

            <div className={classes.root}>
              <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.textfields}>
                    <InputLabel
                      required={true}
                      shrink
                      htmlFor="my-input"
                      focused={true}
                    >
                      Search Customer Name
                    </InputLabel>

                    <CompanySearch
                      id="company-name-search"
                      setName={setName}
                      setDomain={setDomain}
                      setCompany={setCompany}
                    />
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.name && formik.errors.name ? (
                    <div className="error-message">{formik.errors.name}</div>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.textfields}>
                    <InputLabel required={true} shrink htmlFor="my-input1">
                      Customer URL
                    </InputLabel>

                    <BootstrapInput
                      disabled={true}
                      required={true}
                      id="my-input1"
                      aria-describedby="my-helper-text"
                      value={customerUrlData}
                      onChange={formik.handleChange("customerurl")}
                      onBlur={formik.handleBlur("customerurl")}
                      placeholder="Customer URL should begin with https://"
                    />
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.customerurl && formik.errors.customerurl ? (
                    <div className="error-message">
                      {formik.errors.customerurl}
                    </div>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.verticalfield}>
                    <InputLabel
                      required={true}
                      shrink
                      htmlFor="demo-customized-select-native"
                      focused={true}
                    >
                      Customer Business Vertical
                    </InputLabel>

                    <Select
                      id="grouped-native-select"
                      input={<BootstrapInput />}
                      value={formik.values.vertical}
                      onChange={formik.handleChange("vertical")}
                      onBlur={formik.handleBlur("vertical")}
                    >
                      {verticals.map((group) => {
                        if (group["n.type"] === "Company Vertical") {
                          return (
                            <MenuItem dense={true} value={group["n.name"]}>
                              {group["n.name"]}
                            </MenuItem>
                          );
                        } else return null;
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.vertical && formik.errors.vertical ? (
                    <div className="error-message">
                      {formik.errors.vertical}
                    </div>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <Grid item md={10}>
                  <FormControl className={classes.textfields}>
                    <InputLabel
                      required={true}
                      shrink
                      htmlFor="refer"
                      focused={true}
                    >
                      Referenceable
                    </InputLabel>

                    <Select
                      id="refer"
                      input={<BootstrapInput />}
                      value={formik.values.reference}
                      onChange={formik.handleChange("reference")}
                      onBlur={formik.handleBlur("reference")}
                    >
                      {references.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item className={classes.errorfields}>
                  {formik.touched.reference && formik.errors.reference ? (
                    <div className="error-message">
                      {formik.errors.reference}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              className={classes.addNewBtn}
            >
              Cancel
            </Button>

            <Button
              onClick={createCustomerShort}
              type="submit"
              color="primary"
              disabled={!(formik.isValid && formik.dirty)}
              className={classes.addNewBtn}
            >
              Add
            </Button>

            {mutationLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
