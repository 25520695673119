import * as React from "react";

import {
  TextField,
  Select,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import CustomisedButton from "../DataValidation/shared";
import { useStyles } from "./styles";
import { RecListBatches } from "./query";
import { useQuery } from "@apollo/client";

export default function ProductFilters({ formik, fetch, method, batches }) {
  const classes = useStyles();

  const { data: ListBatches } = useQuery(RecListBatches, {
    variables: {
      input: {},
    },
  });

  // if (ListBatches) {
  //   console.log(
  //     "ListBatches",
  //     ListBatches?.RecListBatches.map((element) => {
  //       return element.id;
  //     })
  //   );
  // }

  return (
    <Grid container md={12} spacing={1} className={classes.root}>
      <Grid item>
        <FormControl
          className={classes.batches}
          variant="outlined"
          margin="dense"
        >
          <InputLabel>Select Batch Id</InputLabel>

          <Select
            label="Batch"
            value={formik.values.batchvbeid}
            onChange={formik.handleChange("batchvbeid")}
          >
            <MenuItem value="">None</MenuItem>

            {ListBatches?.RecListBatches.map((value) => {
              return <MenuItem value={value.id}>{value.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <TextField
          margin="dense"
          label="From"
          variant="outlined"
          className={classes.fromFilter}
          value={formik.values.pagefrom}
          onChange={formik.handleChange("pagefrom")}
        />
      </Grid>
      <Grid item>
        <FormControl
          className={classes.filterSize}
          variant="outlined"
          margin="dense"
        >
          <InputLabel htmlFor="outlined-age-native-simple">Size</InputLabel>

          <Select
            label="size"
            value={formik.values.pageto}
            onChange={formik.handleChange("pageto")}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item className={classes.refreshButton}>
        <CustomisedButton buttonText="Refresh" method={method} />
      </Grid>
    </Grid>
  );
}
