import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import {
  makeStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import Brightness1Icon from "@material-ui/icons/Brightness1";

import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { deleteTopicGroup, listGroupTopics } from "./query";

import HelpDailogBox from "../Help/HelpDailogBox";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "2%",
  },
  deleteicon: {
    margin: "5%",
    cursor: "pointer",
  },
  aligntopicslist: {
    marginRight: "60%",
  },
  topicName: {
    "& span": {
      fontSize: "0.8rem",
      display: "flex",
      alignItems: "baseline",
    },
  },
  listRoot: {
    padding: 0,
  },
  listItem: {
    padding: theme.spacing(0),
  },
  listIcon: {
    fontSize: "0.4rem",
    marginRight: theme.spacing(1),
  },
  hoverText: {
    fontStyle: "italic",
    color: "#999",
  },
  emptyCategory: {
    color: "#999 !important",
  },
}));

export default function TopicsList({
  isReadOnly,
  type,
  selectedGroupId,
  topicId,
}) {
  let { id, typeValue } = useParams();
  const client = useApolloClient();
  const history = useHistory();
  const classes = useStyles();

  const [hoveredTopic, setHoveredTopic] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  let queryName = listGroupTopics;
  let dataName = "listTopicsByGroupId";

  if (type === "topic") {
    queryName = listGroupTopics;
    dataName = "listTopicsByGroupId";
    id = selectedGroupId;
  }

  const { data: list } = useQuery(queryName, {
    variables: { input: { groupid: id } },
  });

  const [removetopicgroupmutation] = useMutation(deleteTopicGroup, {
    onCompleted: (e) => {
      updateDeleteCache(e);
    },
  });

  const handleDeleteTopic = (e) => {
    removetopicgroupmutation({
      variables: {
        input: {
          topicid: e.target.parentElement.getAttribute("data-topicid"),
          groupid: id,
        },
      },
    });
  };

  //update cache for delete topics from group
  const updateDeleteCache = (e) => {
    const { listTopicsByGroupId } = client.readQuery({
      query: listGroupTopics,
      variables: { input: { groupid: id } },
    });

    let listGroupTopicsCache = { ...listTopicsByGroupId };
    listGroupTopicsCache.topics = listGroupTopicsCache.topics.filter(
      (topic) => topic.id !== e.removetopictogroup.topicid
    );

    client.writeQuery({
      query: listGroupTopics,
      data: {
        listTopicsByGroupId: listGroupTopicsCache,
      },
      variables: { input: { groupid: id } },
    });
  };

  const onHover = (topic) => {
    if (topic.tag === "Topic_NotMapTo_Prod") {
      setHoveredTopic(topic.id);
    }
  };

  const onTopicClick = (topic) => {
    if (topic.tag !== "Topic_NotMapTo_Prod") {
      history.push("/topic/" + typeValue + "/" + topic.id);
    } else {
      setShowDialog(true);
    }
  };

  const onNotHover = () => {
    setHoveredTopic("");
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <List className={classes.listRoot}>
      {list
        ? list[dataName].topics.map((topic) => {
            if (topic.id !== topicId) {
              return (
                <Grid container xs={12}>
                  <Grid item xs={isReadOnly ? 12 : 10}>
                    <ListItem
                      className={classes.listItem}
                      key={topic}
                      dense
                      button
                      onClick={() => {
                        onTopicClick(topic);
                      }}
                      onMouseEnter={() => {
                        onHover(topic);
                      }}
                      onMouseLeave={onNotHover}
                    >
                      <ListItemText
                        className={classes.topicName}
                        primary={
                          <span>
                            <Brightness1Icon
                              className={classes.listIcon}
                              fontSize="small"
                            />

                            {hoveredTopic === topic.id ? (
                              <span className={classes.hoverText}>
                                Curation on Request
                              </span>
                            ) : (
                              <span
                                className={
                                  topic.tag !== null
                                    ? classes.emptyCategory
                                    : classes.nonEmptyCategory
                                }
                              >
                                {topic.name}
                              </span>
                            )}
                          </span>
                        }
                      />
                    </ListItem>
                  </Grid>

                  {!isReadOnly && (
                    <Grid item xs={2}>
                      {isReadOnly ? null : (
                        <DeleteIcon
                          fontSize="small"
                          data-topicid={topic.id}
                          onClick={handleDeleteTopic}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              );
            } else {
              return null;
            }
          })
        : null}

      <HelpDailogBox
        open={showDialog}
        close={handleClose}
        heading="Do you want us to curate this category?"
        statement="Let us know & we will work on curating products into this category."
        inlay="Add any additional information here"
        message=""
      />
    </List>
  );
}
