import React from 'react';
import { makeStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    square: {
        width: 100,
        height: 100,
       
      },
    
      ngrid:
        {
          marginTop: "0%"
        },
    }));


export default function ProductLoad() {
    const classes = useStyles();

    return(

        <>
        <Grid container  justify="flex-start">
              <Grid item xs={2}>
              <Skeleton variant="rect" className={classes.square} />
                </Grid>
                
                <Grid item xs={8} >

                <Skeleton width="20%" />

         <Grid container>
           <Grid item xs={10}>
                <Skeleton width="95%" height= {60} variant="text" className={classes.ngrid}/>
           </Grid>

         </Grid>
  
                </Grid>
      

                </Grid>
          
          </>  


    );
}