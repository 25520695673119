import { gql } from "@apollo/client";

export const addProjectOwner = gql`
  mutation projectowner($input: personinput!) {
    addProjectOwnershipToEmployee(input: $input) {
      responsestatus
    }
  }
`;

export const removeProjectOwner = gql`
  mutation removeprojectowner($input: personinput!) {
    removeProjectOwnershipFromEmployee(input: $input) {
      responsestatus
    }
  }
`;

export const addOperationEmp = gql`
  mutation addOperationemp($input: personinput!) {
    addOperationsToEmployee(input: $input) {
      responsestatus
    }
  }
`;

export const removeOperationsEmp = gql`
  mutation removeoperationemp($input: personinput!) {
    removeOperationsFromEmployee(input: $input) {
      responsestatus
    }
  }
`;

export const addFinanceEmp = gql`
  mutation addfinanceemp($input: personinput!) {
    addFinanceToEmployee(input: $input) {
      responsestatus
    }
  }
`;

export const removeFinanceEmp = gql`
  mutation removefinanceemp($input: personinput!) {
    removeFinanceFromEmployee(input: $input) {
      responsestatus
    }
  }
`;

export const getlistDocuments = gql`
  query listDocuments {
    listDocuments(input: {}) {
      name
      type
      shortdescription
      action
      createdon
      attachment
    }
  }
`;

export const addDocuments = gql`
  mutation adddocs($input: projectinputV1!) {
    addDocument(input: $input) {
      responsestatus
    }
  }
`;

// export const editTags = gql`
//   mutation editTags($input: genericinput!) {
//     editTag(input: $input) {
//       responsestatus
//     }
//   }
// `;

// addTag	name,type,value,action
// listTag	no input
// editTag	timestamp,action	timestamp is createdon value from listTag
