import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  Paper,
  Grid,
  Typography,
  Button,
  Box,
  Divider,
  Drawer,
} from "@material-ui/core";

import { GET_TEMPLATE_BY_PROJECT } from "../../queries/query";

import { useQuery } from "@apollo/client";

import PreviewTemplate from "../templates/desing/preview";

import PropTypes from "prop-types";

import { useStyles } from "./styles";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

const Template = ({ data, type, createdby }) => {
  const classes = useStyles();
  const [state, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(!open);
  };

  return (
    <Grid container alignItems="center">
      <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
        <Grid container className={classes.preview}>
          <Grid item xs={12}>
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              onClick={() => {
                setState(false);
              }}
            >
              Close
            </Button>
          </Grid>

          <Grid item xs={12}>
            <PreviewTemplate
              id={data.vbeid}
              setState={setState}
              type={type}
              setSweight={(e) => {}}
            />
          </Grid>
        </Grid>
      </Drawer>

      <Grid item xs={8}>
        <Typography variant="subtitle2" gutterBottom>
          {data.name}
        </Typography>

        <Typography variant="caption" display="block" gutterBottom>
          {data.shortdescription}
        </Typography>

        <>
          <Typography variant="caption" display="inline" gutterBottom>
            Authored by -
          </Typography>

          <Typography
            variant="caption"
            color="secondary"
            display="inline"
            gutterBottom
          >
            {data.for === "public" ? "vBridge Hub" : createdby}
          </Typography>
        </>
      </Grid>

      <Grid item xs={4}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            setState(true);
          }}
        >
          preview
        </Button>
      </Grid>
    </Grid>
  );
};

const ReponseTemplate = (props) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Response Template
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={1} className={classes.template}>
          <Box m={1}>
            <Template data={props.data} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export const QATemplate = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Q & A Template
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={1} className={classes.template}>
          <Box m={1}>
            <Template data={data} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default function MapOtherTemplate({ type, projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  const { data } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    variables: {
      input: { vbeid: id, typelist: ["response_template", "qa_template"] },
    },
  });

  return (
    <Grid
      container
      alignItems="center"
      className={
        type === "review" ? classes.reviewRoot : classes.otherTemplateReviewRoot
      }
    >
      <Grid item xs={9}>
        {data ? (
          data.getTemplatesByProjectID.length !== 0 ? (
            data.getTemplatesByProjectID.map((temp, i) => {
              if (temp.type === "response_template") {
                return <ReponseTemplate data={temp} />;
              }
              if (temp.type === "qa_template") {
                return <QATemplate data={temp} />;
              }

              return null;
            })
          ) : (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.textFieldLabel}
                >
                  Selected Response Template
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Paper elevation={1} className={classes.template}>
                  <Box m={1}>
                    <Typography variant="caption">
                      No Templates Found
                    </Typography>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.textFieldLabel}
                >
                  Selected Q & A Template
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Paper elevation={1} className={classes.template}>
                  <Box m={1}>
                    <Typography variant="caption">
                      No Templates Found
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            </>
          )
        ) : (
          <Typography variant="caption">No Templates Found</Typography>
        )}
      </Grid>
    </Grid>
  );
}
