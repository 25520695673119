import { useState } from "react";

import { useParams, useHistory } from "react-router-dom";

import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  ListItemSecondaryAction,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import ViewResponse from "../reponse/viewResponse";

import { useMutation, useQuery } from "@apollo/client";

import { GET_PROJECT_FOR_VENDOR, UPDATE_STATUS } from "../../queries/query";

import { VBRIDGEHUB_MEDIAURL } from "../../../../../constants";

import ViewMessaging from "../messaging/messaging";

import PrimaryButton from "../../../common/PrimaryButton";
import ConfirmAction from "../../../common/ConfirmAction";

import ViewRequirements from "../requirements/viewRequirements";

import { useStyles } from "./styles";

import ViewAward from "./ViewAward";

const SideIcon = () => {
  return (
    <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="arrow" color="secondary">
        <ArrowRightIcon />
      </IconButton>
    </ListItemSecondaryAction>
  );
};

const ProjectMenu = () => {
  let { type, id, productId } = useParams();
  const history = useHistory();

  return (
    <List component="nav" dense>
      <ListItem
        button
        selected={type === "messaging"}
        onClick={() => {
          history.push("/project-response/messaging/" + id + "/" + productId);
        }}
      >
        <ListItemText primary="Message customer" />
        {type === "messaging" ? <SideIcon /> : null}
      </ListItem>

      <ListItem
        button
        selected={type === "requirements"}
        onClick={() => {
          history.push(
            "/project-response/requirements/" + id + "/" + productId
          );
        }}
      >
        <ListItemText primary="View Requirements" />
        {type === "requirements" ? <SideIcon /> : null}
      </ListItem>

      <ListItem button selected={type === "response"}>
        <ListItemText
          primary="Prepare Response"
          onClick={() => {
            history.push("/project-response/response/" + id + "/" + productId);
          }}
        />
        {type === "response" ? <SideIcon /> : null}
      </ListItem>

      {/* <ListItem button selected={type === "contract"}>
        <ListItemText
          primary="Contract"
          onClick={() => {
            history.push("/project-response/contract/" + id + "/" + productId);
          }}
        />
        {type === "contract" ? <SideIcon /> : null}
      </ListItem> */}

      <ListItem
        button
        selected={type === "award"}
        onClick={() => {
          history.push("/project-response/award/" + id + "/" + productId);
        }}
      >
        <ListItemText primary="Award" />
        {type === "award" ? <SideIcon /> : null}
      </ListItem>
    </List>
  );
};

export default function ResponseOverview({ drawerOpen }) {
  const classes = useStyles();
  const { type, id, productId } = useParams();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");

  const { data, refetch } = useQuery(GET_PROJECT_FOR_VENDOR, {
    variables: { input: { vbeid: id, productvbeid: productId } },
  });

  const [updateStatusMutation, { loading: mutationLoading }] = useMutation(
    UPDATE_STATUS,
    {
      onCompleted: (e) => {
        refetch();
        setOpen(false);
      },
    }
  );

  const onButtonClick = (action) => {
    setAction(action);
    setOpen(true);
  };

  const onCancel = () => {
    setAction("");
    setOpen(false);
  };

  const onConfirm = (action, remarks) => {
    updateStatusMutation({
      variables: {
        input: {
          vbeid: id,
          status: action === "accept" ? "APPROVED" : "REJECTED",
          productvbeid: productId,
          remarks: remarks,
        },
      },
    });
  };

  return (
    <div className={drawerOpen ? classes.drawerOpenRoot : classes.responseRoot}>
      {data ? (
        <Box m={10}>
          <Paper className={classes.main}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={2} className={classes.backgrid}>
                    <IconButton
                      className={classes.backbutton}
                      onClick={() => {
                        history.push("/vprojects3.0");
                      }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>

                  <Grid item xs={1}>
                    <Avatar
                      alt="company logo"
                      variant="square"
                      className={classes.logoimage}
                      src={
                        VBRIDGEHUB_MEDIAURL +
                        "/" +
                        data.getProjectDetailsForVendorV1.image
                      }
                    >
                      :(
                    </Avatar>
                  </Grid>

                  <Grid item xs={9} className={classes.projectName}>
                    <Typography variant="h6">
                      {data.getProjectDetailsForVendorV1.name}
                    </Typography>

                    {!data?.getProjectDetailsForVendorV1.role.includes(
                      "APPROVED"
                    ) &&
                      !data?.getProjectDetailsForVendorV1.role.includes(
                        "REJECTED"
                      ) && (
                        <div>
                          <PrimaryButton
                            label="Pursue Opportunity"
                            onClick={() => onButtonClick("accept")}
                            disabled={mutationLoading}
                          />

                          <PrimaryButton
                            label="Decline Opportunity"
                            onClick={() => onButtonClick("decline")}
                            disabled={mutationLoading}
                          />
                        </div>
                      )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={2} className={classes.menuList}>
                <ProjectMenu />
              </Grid>

              <Grid item xs={10}>
                {type === "requirements" ? (
                  <ViewRequirements
                    projectData={data?.getProjectDetailsForVendorV1}
                  />
                ) : null}

                {type === "response" ? (
                  <ViewResponse
                    projectData={data?.getProjectDetailsForVendorV1}
                    refetch={refetch}
                  />
                ) : null}

                {type === "messaging" ? <ViewMessaging /> : null}

                {type === "award" && data ? (
                  <ViewAward projectData={data?.getProjectDetailsForVendorV1} />
                ) : null}
              </Grid>
            </Grid>
          </Paper>

          <ConfirmAction
            open={open}
            action={action}
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
        </Box>
      ) : null}
    </div>
  );
}
