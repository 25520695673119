import React from "react";

import { Grid, makeStyles } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";

const useStyles = makeStyles((theme) => ({
  imageLink: {
    cursor: "pointer",
    color: "#333",
    margin: theme.spacing(0, 1),
    "&:hover": {
      color: "#4179BD",
    },
  },
  imageSvg: {
    fontSize: "1.2rem",
  },
}));

export default function ImageLink({ url, type }) {
  const classes = useStyles();

  const MyComponents = {
    ImageIcon: function ImageIcon({ fontSize, onClick }) {
      if (type === "website")
        return (
          <LanguageIcon
            className={classes.imageSvg}
            fontSize={fontSize}
            onClick={onClick}
          />
        );
      else if (type === "facebook")
        return (
          <FacebookIcon
            className={classes.imageSvg}
            fontSize={fontSize}
            onClick={onClick}
          />
        );
      else if (type === "linkedin")
        return (
          <LinkedInIcon
            className={classes.imageSvg}
            fontSize={fontSize}
            onClick={onClick}
          />
        );
      else if (type === "twitter")
        return (
          <TwitterIcon
            className={classes.imageSvg}
            fontSize={fontSize}
            onClick={onClick}
          />
        );
    },
  };

  return (
    <Grid item className={classes.imageLink}>
      <MyComponents.ImageIcon onClick={(event) => window.open(url, "_blank")} />
    </Grid>
  );
}
