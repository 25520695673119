import React, { useState } from "react";

import { Button, Grid } from "@material-ui/core";
import { useStyles } from "./styles";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import { getUsersList } from "./query";
import { updateProfile } from "../profile2.0/query";

import VbridgeAlert from "../../shared/alert";

import {
  acceptOrRejectAdminRequest,
  getCompanyById,
  getProfileAdminsRequestQueue,
} from "../companies/Company2.0/query";
import { inviteUser } from "../Agreements/query";

import PrimaryButton from "../common/PrimaryButton";
import ConfirmAlert from "../common/ConfirmAlert";

import InviteUser from "../Agreements/InviteUser";

import AdminDataGrid from "./AdminDataGrid";
import { Alert } from "@material-ui/lab";

export default function AdminPage({ userInfo, refetch, drawerOpen }) {
  const classes = useStyles();
  const client = useApolloClient();

  const admin_role = "PROFILE_ADMIN";

  const [openAlert, setOpenAlert] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [userAction, setUserAction] = useState("");
  const [inviteModal, setInviteModal] = useState(false);
  const [inviteError, setInviteError] = useState(
    "Something went wrong. Please try again."
  );
  const [action, setAction] = useState("");

  const [
    inviteUserMutation,
    { loading: inviteLoading, error, data: inviteUserSuccess },
  ] = useMutation(inviteUser, {
    onCompleted: (e) => {
      closeModal();
    },
    onError: (e) => {
      setInviteError(e.graphQLErrors[0].message);
    },
  });

  const {
    data: usersList,
    loading,
    error: userError,
  } = useQuery(getUsersList, {
    variables: { input: { companyid: userInfo.companyvbeid } },
  });

  const { data: companyData } = useQuery(getCompanyById, {
    variables: { input: { vbeid: userInfo.companyvbeid } },
  });

  const [
    updateProfileMutation,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useMutation(updateProfile, {
    onCompleted: (response) => {
      setSelectedUser("");
      setUserAction("");
      updateUserProfileCache(response);
    },
  });

  const onBlockClick = (row, action) => {
    setUserAction(action);
    setOpenAlert(true);
    setSelectedUser(row);
  };

  const onUserBlock = () => {
    setOpenAlert(false);

    updateProfileMutation({
      variables: {
        input: {
          status: userAction === "block" ? false : true,
          vbeid: selectedUser.id,
        },
      },
    });
  };

  const onCancel = () => {
    setOpenAlert(false);
    setSelectedUser("");
    setUserAction("");
  };

  const onInviteUser = (e) => {
    e.preventDefault();
    setInviteModal(true);
  };

  const closeModal = () => {
    setInviteModal(false);
  };

  const submitUserInvite = (emailId) => {
    inviteUserMutation({
      variables: {
        input: { personemailid: emailId },
      },
    });
  };

  const updateUserProfileCache = (response) => {
    let users = usersList.getUsersbyCompany;
    users.forEach((user) => {
      if (user.vbeid === response.updateProfile.vbeid) {
        user = response.updateProfile;
      }
    });
  };

  let companyUsers = [];
  if (usersList) {
    usersList?.getUsersbyCompany?.forEach((user) => {
      if (user.id) {
        companyUsers.push(user);
      }
    });
  }

  let members = [];

  usersList?.getUsersbyCompany?.forEach((data) => {
    members.push(data.role);
  });

  const { data: adminRequestsList } = useQuery(getProfileAdminsRequestQueue, {
    variables: { input: {} },
  });

  const [acceptOrRejectAdminRequestMutation, { loading: adminRequestloading }] =
    useMutation(acceptOrRejectAdminRequest, {
      onCompleted: (e) => {
        refetch();
        if (action === "rejected") {
          updateRequestQueue(e);
        }
      },
    });

  const updateRequestQueue = (e) => {
    let queue = [...adminRequestsList.listProfileAdminRequest];

    client.writeQuery({
      query: getProfileAdminsRequestQueue,
      data: {
        listProfileAdminRequest: queue.filter(
          (person) => person.vbeid !== e.profileaccessapproval.vbeid
        ),
      },
      variables: { input: {} },
    });
  };

  const onRequestClick = (action, vbeid) => {
    setAction(action);

    acceptOrRejectAdminRequestMutation({
      variables: {
        input: {
          action: action,
          vbeid: vbeid,
        },
      },
    });
  };

  let isAdmin = userInfo.role.includes(admin_role);

  const columns = [
    {
      field: "name",
      headerName: "User Name",
      width: 180,
    },
    {
      field: "jobrole",
      headerName: "Job Role",
      width: 180,
    },
    {
      headerName: "Role Type",
      width: 180,
      field: "roletype",
      renderCell: (params) => (
        <div className={classes.user}>{params.value}</div>
      ),
    },
    {
      field: "role",
      headerName: "User Role",
      width: 190,
      renderCell: (params) => (
        <Grid container direction="column" className={classes.userrole}>
          <Grid item md={1} className={classes.user}>
            {params.value?.includes("PROFILE_ADMIN") ||
            members.includes("PROFILE_ADMIN") ? (
              <div>Admin</div>
            ) : (
              <div>Member</div>
            )}
          </Grid>

          {params.row.id !== userInfo.id &&
          params.row.role?.includes("PROFILE_ADMIN_ACCESS_REQUESTED") ? (
            <Grid item md={1} className={classes.adminRequest}>
              <div className={classes.user}>Admin Request</div>
              <Grid>
                <span
                  onClick={() => onRequestClick("approved", params.row.id)}
                  className={classes.acceptbtn}
                >
                  Accept
                </span>
                {" or  "}
                <span
                  onClick={() => onRequestClick("rejected", params.row.id)}
                  className={classes.rejectbtn}
                >
                  Reject
                </span>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      ),
    },

    {
      field: "status",
      headerName: "Block / Unblock",
      width: 190,
      renderCell: (params) => (
        <>
          {params.row.id !== userInfo.id &&
            (params.value || params.value === null ? (
              <Button
                onClick={() => onBlockClick(params.row, "block")}
                className={classes.blockBtn}
                disabled={!isAdmin}
              >
                Block User
              </Button>
            ) : (
              <Button
                onClick={() => onBlockClick(params.row, "unblock")}
                className={classes.blockBtn}
                disabled={!isAdmin}
              >
                Unblock User
              </Button>
            ))}
        </>
      ),
    },
  ];

  return (
    <div className={drawerOpen ? classes.drawerOpenRoot : classes.root}>
      {(error || updateError) && (
        <VbridgeAlert
          severity="error"
          open={true}
          message="Something went wrong! Please try again later."
        />
      )}

      {userError && (
        <VbridgeAlert severity="error" open={true} message={inviteError} />
      )}

      {(updateLoading || inviteLoading) && (
        <VbridgeAlert severity="info" open={true} message="Please wait" />
      )}

      {updateData && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="User updated successfully"
        />
      )}

      {adminRequestloading && action === "rejected" && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="Admin request rejected Successfully"
        />
      )}

      {adminRequestloading && action === "approved" && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="Admin request aceepted Successfully"
        />
      )}

      {inviteUserSuccess && (
        <VbridgeAlert
          severity="success"
          open={true}
          message="User invited successfully"
        />
      )}

      <ConfirmAlert
        open={openAlert}
        action={userAction}
        onConfirm={onUserBlock}
        onCancel={onCancel}
      />

      <InviteUser
        open={inviteModal}
        closeModal={closeModal}
        loading={inviteLoading}
        submitUserInvite={submitUserInvite}
      />

      <Grid container justifyContent="center">
        <Grid item md={2}></Grid>

        <Grid item md={10}>
          <Grid container>
            <Grid alignItems="" md={10} className={classes.btn}>
              <Grid item md={12}>
                <PrimaryButton
                  variant="contained"
                  label="Invite Colleague"
                  onClick={onInviteUser}
                />
              </Grid>
            </Grid>

            <Grid item md={10}>
              <Grid md={12} item className={classes.heading}>
                <Alert severity="info">
                  {companyData && (
                    <div className={classes.formHeaderText}>
                      Listing of colleagues from{" "}
                      <span className={classes.companyName}>
                        {companyData?.queryCompanybyid.name}
                      </span>
                      . You may ask other colleagues to join the network by
                      clicking on the Invite Colleague button.
                    </div>
                  )}
                </Alert>
              </Grid>
            </Grid>

            {usersList && (
              <Grid item md={10} className={classes.dataGridDiv}>
                <AdminDataGrid
                  loading={loading}
                  data={usersList.getUsersbyCompany ? companyUsers : []}
                  columns={columns}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
