import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useFormik } from "formik";

import * as yup from "yup";

import { useMutation, useApolloClient, useQuery } from "@apollo/client";

import SimpleSnackbar from "./snackbar";
import SimpleErrorSnackbar from "./errorsnackbar";

import { getProductsByCompanyId, postProduct } from "../query";
import AddNewButton from "../../../products/product2.0/AddNew/AddNewButton";
import { useStyles } from "./styles";
import InputField from "../../../common/InputField";

export default function AddProductForm({ updateCache }) {
  const classes = useStyles();
  const { id } = useParams();
  const client = useApolloClient();
  //   const history = useHistory();
  const timer = useRef();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!mutationLoading && !loading) {
      setLoading(true);

      timer.current = window.setTimeout(() => {
        // setLoading(false);
      }, 50000);
    }
  };

  //   const route = (e) => {
  //     history.push("/product/" + e.addProductShort.vbeid);
  //   };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string("Enter your Product Name")
      .min(2, "Product Name should be of minimum 2 characters length")
      .required("Product Name is required"),

    producturl: yup
      .string("Enter your Product URL")
      .required("Product URL is required")
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        "Enter your Product URL"
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      producturl: "",
    },
    validationSchema: validationSchema,
  });

  ///add product

  const [
    postProductMutation,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation(postProduct, {
    onCompleted: (e) => {
      updateProductInformationCache(e);
    },
  });

  const createProduct = (e) => {
    e.preventDefault();

    postProductMutation({
      variables: {
        input: {
          name: formik.values.name,
          producturl: formik.values.producturl,
          companyid: id,
        },
      },
    });

    handleButtonClick();
  };

  ///list products

  const { data: productData } = useQuery(getProductsByCompanyId, {
    variables: { input: { vbeid: id } },
  });

  const updateProductInformationCache = (e) => {
    const { productByComapnyId } = client.readQuery({
      query: getProductsByCompanyId,
      variables: { input: { vbeid: id } },
    });

    let listProductInformationCache = [
      e.addProductShort,
      ...productByComapnyId,
    ];

    client.writeQuery({
      query: getProductsByCompanyId,
      data: {
        productByComapnyId: {
          __typename: "product",
          productByComapnyId: listProductInformationCache,
        },
      },
      variables: { input: { vbeid: id } },
    });

    setOpen(false);
    updateCache(e.addProductShort);
  };

  if (productData) {
  }

  return (
    <div>
      {mutationError && <SimpleErrorSnackbar />}
      {data ? <SimpleSnackbar /> : null}

      <AddNewButton name="Add New Product" handleClick={handleClickOpen} />

      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          className={classes.formMainDiv}
        >
          <DialogTitle id="form-dialog-title" className={classes.title}>
            Add Product
          </DialogTitle>

          <DialogContent dividers className={classes.formContent}>
            <DialogContentText className={classes.stmt}>
              Please enter the following details to create a Product.
            </DialogContentText>

            <div className={classes.root}>
              <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputField
                      label="Product Name *"
                      value={formik.values.name}
                      onChange={formik.handleChange("name")}
                      onBlur={formik.handleBlur("name")}
                      multiple={true}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={11}>
                  {formik.errors.name ? (
                    <div className={classes.errorMsg}>{formik.errors.name}</div>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <Grid item md={11}>
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={classes.formControl}
                  >
                    <InputField
                      label="Product URL *"
                      value={formik.values.producturl}
                      onChange={formik.handleChange("producturl")}
                      onBlur={formik.handleBlur("producturl")}
                      placeholder="https://www.vbridgehub.com/products/"
                      //options={serviceTypeList}
                      multiple={true}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={11}>
                  {formik.errors.producturl ? (
                    <div className={classes.errorMsg}>
                      {formik.errors.producturl}
                    </div>
                  ) : null}
                </Grid>

                <FormHelperText margin="dense" className={classes.helperText}>
                  * Please add a product URL as available on your website for
                  the Product. Our AI will also use this content and metadata
                  when buyers are using Advanced Search on vBridge Hub.
                </FormHelperText>
              </Grid>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              className={classes.addNewBtn}
            >
              Cancel
            </Button>

            <Button
              onClick={createProduct}
              type="submit"
              color="primary"
              disabled={!(formik.isValid && formik.dirty)}
              className={classes.addNewBtn}
            >
              Add
            </Button>

            {mutationLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
