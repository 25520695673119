/**
 * Component for Read More and Reaad Less options
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: "pointer",
  },
  actionText: {
    color: theme.palette.secondary.main,
    fontSize: "0.78rem",
  },
}));

export default function ReadMore({ children }) {
  const classes = useStyles();

  const text = children;
  const [isReadMore, setIsReadMore] = React.useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      {text == null || text.length <= 230 ? (
        text
      ) : (
        <>
          {isReadMore ? text.slice(0, 230) : text}
          <span onClick={toggleReadMore} className={classes.icon}>
            {isReadMore ? (
              <>
                ... <span className={classes.actionText}>Read More</span>{" "}
              </>
            ) : (
              <span className={classes.actionText}> Read Less</span>
            )}
          </span>
        </>
      )}
    </>
  );
}
