import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(7),
    justifyContent: "center",

    "& button": {
      textTransform: "none !important",
    },
  },

  title: {
    justifyContent: "left",
    fontSize: theme.spacing(2),
    fontWeight: "500 !important",
    margin: "8px 2px !important",
  },

  header: {
    justifyContent: "left",
    fontSize: theme.spacing(1.8),
    fontWeight: "500 !important",
    margin: "8px 2px !important",
  },
  contract: {
    margin: theme.spacing(2, 1),
  },

  billing: {
    margin: theme.spacing(3, 1, 1, 1),
  },

  daysfield: {
    "& input": {
      padding: theme.spacing(1.4),
    },

    "& p": {
      fontSize: theme.spacing(1.6),
      color: "#000000",
    },

    "& div": {},
  },

  daysfieldfromto: {
    marginRight: "8px !importnat",

    "& input": {
      padding: theme.spacing(1.4),
    },

    "& p": {
      fontSize: theme.spacing(1.6),
      color: "#000000",
    },
  },

  divider: {
    borderColor: "#E8E8E8 !important",
    fontSize: "2px !important",
    borderWidth: "2px !important",
    marginBottom: theme.spacing(1),
  },

  btn: {
    // marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.4),
  },

  monthday: {
    justifyContent: "left",
    fontSize: theme.spacing(1.6),
    fontWeight: "400 !important",
    margin: "8px 2px !important",
  },

  errors: {
    fontSize: theme.spacing(1.3),
    color: "#9b0000",
    fontWeight: "500 !important",
  },
}));
