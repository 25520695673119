export const Templates = [
  { name: "Requirement Templates", id: "requirement_template", vbridge: false },
  { name: "Response Templates", id: "response_template", vbridge: false },
  { name: "Q&A Templates", id: "qa_template", vbridge: false },
  { name: "Evaluate Templates", id: "evaluate_template", vbridge: false },
  {
    name: "Due Dilligence Templates",
    id: "due_dilligence_template",
    vbridge: true,
  },
  { name: "Feedback Templates", id: "feed_back_template", vbridge: true },
  { name: "Contract Templates", id: "contract_template", vbridge: true },
  { name: "SOW Templates", id: "sow_template", vbridge: true },
];
