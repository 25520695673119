import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '15%',
    margin:'auto',
    marginTop:'20%'
  },
  logoImg:{
    width:"100%",
    marginBottom:"2%"
  },
  bar:{
      height:theme.spacing(0.3)
  }

}));

export default function MainLoading() {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  //const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        //setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        //const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        //setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
 
    <div className={classes.root}>
    <img alt='img' src="https://media-dev.vbridgehub.com/vbridgelogo.png" className={classes.logoImg}/>
      <LinearProgress  color="primary"  className={classes.bar}/>
    </div>
    
  );
}
