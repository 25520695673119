import { useQuery } from "@apollo/client";

import { getItemsInSection } from "./query";

import InputPreview from "./InputPreview";
import SelectPreview from "./SelectPreview";
import { useStyles } from "./styles";

export default function ItemsPreview({ items, sectionVbeid, sectionId }) {
  const classes = useStyles();

  let listItems = items;

  const { data: itemList } = useQuery(getItemsInSection, {
    variables: { input: { vbeid: sectionVbeid, timestamp: sectionId } },
  });

  if (items.length === 0 && itemList) {
    listItems = itemList.listItem;
  }

  return (
    <div className={classes.itemsPreviewDiv}>
      {listItems?.map((item) => {
        const { type } = item;
        if (type === "text" || type === "textarea") {
          return <InputPreview item={item} />;
        } else if (type === "dropdown") {
          return <SelectPreview item={item} />;
        } else return null;
      })}
    </div>
  );
}
