import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: "8px",
  },
  addNewBtn: {
    fontSize: "0.7rem",
    marginBottom: theme.spacing(1),
  },
  errorMsg: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#8B0000",
    paddingBottom: "8px",
  },

  title: {
    padding: "4px 12px",

    "& h2": {
      fontSize: "16px",
    },
  },
  stmt: {
    padding: "4px 2px 0px 10px",
    fontSize: "12px",
    fontWeight: 600,
    marginBottom: "8px !important",
  },
  noUrlText: {
    color: "#232961",
    cursor: "pointer",
    marginBottom: "8px",
  },

  mainDialogBox: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "400px",
      maxWidth: "400px",
    },
  },
}));
