import * as React from "react";

import { useQuery } from "@apollo/client";

import { GET_SECTIONS } from "../../queries/query";

import { Box, FormControl, Grid } from "@material-ui/core";

import RSection from "./section";

import { useStyles } from "./styles";

export default function RequirementForm(props) {
  const classes = useStyles();

  const { data } = useQuery(GET_SECTIONS, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: props.templateId } },
  });

  let readOnly = props.projectData.role[0] === "ADDED_TO";

  return (
    <div className={classes.root}>
      <Grid container className={classes.main}>
        <Grid item xs={12}>
          {data ? (
            <Box m={1}>
              <FormControl
                component="fieldset"
                className={classes.formControlclass}
              >
                {data.listSection.map((section, index) => {
                  var meta = JSON.parse(section.metadata);
                  meta = { ...meta, id: index + 1 };

                  return (
                    <RSection
                      key={section.id}
                      name={section.name}
                      vbeid={props.id}
                      sid={section.id}
                      projectId={props.projectId}
                      id={index + 1}
                      metadata={meta}
                      preview={true}
                      type={props.type}
                      readOnly={readOnly}
                    />
                  );
                })}
              </FormControl>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
