import { useMutation, useQuery } from "@apollo/client";

import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

import { useFormik } from "formik";

import { useHistory, useParams } from "react-router-dom";

import InputField from "../../../../common/InputField";
import SelectField from "../../../../common/SelectField";

import {
  CREATE_SERVICE_PROJECT,
  GET_DIVERSITY_LIST,
  UPDATE_PROJECT,
} from "../../../queries/query";

import { useStyles } from "../styles";

import { workLocationValues } from "./DropdownData";
import NextPrevButtons from "./NextPrevButtons";

export default function ServiceProjectForm(props) {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  let today = new Date().toISOString().split("T")[0];

  const validateProjectCreation = (values) => {
    const errors = {};
    if (!values.name || values.name.trim().length === 0) {
      errors.name = "Please enter project name.";
    }

    if (!values.worklocation) {
      errors.worklocation = "Please select a Workplace type";
    }

    if (!values.workstartby) {
      errors.workstartby = "Please select work start by date";
    }

    if (!values.profilereceivingdeadline) {
      errors.profilereceivingdeadline =
        "Please enter profiles receiving deadline";
    }

    if (values.workstartby < values.profilereceivingdeadline) {
      errors.profilereceivingdeadline =
        "Work start by date cannot be before profile receiving deadline";
    }

    if (!values.indicativeinterviewschedulefrom) {
      errors.indicativeinterviewschedulefrom =
        "Please enter interview schedule date";
    }

    if (!values.indicativeinterviewscheduleto) {
      errors.indicativeinterviewscheduleto =
        "Please enter interview schedule date";
    }

    if (
      values.indicativeinterviewschedulefrom >
      values.indicativeinterviewscheduleto
    ) {
      errors.indicativeinterviewscheduleto =
        "To date cannot be before From date";
    }

    if (values.workstartby < values.indicativeinterviewschedulefrom) {
      errors.indicativeinterviewschedulefrom =
        "Indicative interview schedule cannot be after Work start by date";
    }

    if (values.workstartby < values.indicativeinterviewscheduleto) {
      errors.indicativeinterviewscheduleto =
        "Indicative interview schedule cannot be after Work start by date";
    }

    return errors;
  };

  const userRole = props.data?.role || "";

  const formik = useFormik({
    initialValues: props.data
      ? {
          vbeid: props.data.vbeid,
          name: props.data.name,
          worklocation: props.data.worklocation,
          workstartby: props.data.workstartby || today,
          diversity: props.data.diversity,
          profilereceivingdeadline:
            props.data.profilereceivingdeadline || today,
          indicativeinterviewschedule: props.data.indicativeinterviewschedule,
          indicativeinterviewschedulefrom: props.data
            .indicativeinterviewschedule
            ? props.data.indicativeinterviewschedule[0]
            : today,
          indicativeinterviewscheduleto: props.data.indicativeinterviewschedule
            ? props.data.indicativeinterviewschedule[1]
            : today,
        }
      : {
          name: "",
          worklocation: "",
          workstartby: today,
          diversity: [],
          profilereceivingdeadline: today,
          indicativeinterviewschedulefrom: today,
          indicativeinterviewscheduleto: today,
        },
    validate: validateProjectCreation,

    onSubmit: (values) => {
      createServiceProject(values);
    },
  });

  const { data } = useQuery(GET_DIVERSITY_LIST, {
    fetchPolicy: "network-only",
  });

  let diversityValues = [];

  if (data) {
    data.listDiversity.forEach((diversity) => {
      diversityValues.push({ type: diversity.vbeid, value: diversity.name });
    });
  }

  const [createProject, { loading }] = useMutation(CREATE_SERVICE_PROJECT, {
    onCompleted: (response) => {
      history.push("/job-description/" + response.addServiceProject.vbeid);
    },
  });

  const [updateProject, { loading: uloading }] = useMutation(UPDATE_PROJECT, {
    onCompleted: (e) => {
      history.push("/job-description/" + e.updateProjectV1.vbeid);
    },
  });

  const createServiceProject = (values) => {
    values.type = "service-timematerial";

    if (id !== "new") {
      values.vbeid = id;

      values.indicativeinterviewschedule = [
        values.indicativeinterviewschedulefrom,
        values.indicativeinterviewscheduleto,
      ];

      delete values.indicativeinterviewschedulefrom;
      delete values.indicativeinterviewscheduleto;

      updateProject({ variables: { input: values } });
    } else {
      values.version = "v1";

      values.indicativeinterviewschedule = [
        values.indicativeinterviewschedulefrom,
        values.indicativeinterviewscheduleto,
      ];

      delete values.indicativeinterviewschedulefrom;
      delete values.indicativeinterviewscheduleto;

      createProject({ variables: { input: values } });
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl component="fieldset" className={classes.formControlclass}>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Give a name for this project *
        </Typography>

        <TextField
          id="filled-full-width"
          name="name"
          placeholder="Project Name *"
          fullWidth
          variant="outlined"
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          disabled={
            userRole !== ""
              ? userRole.includes("ADDED_TO") && !userRole.includes("MANAGES")
              : false
          }
        />

        <FormHelperText className={classes.errorMessage}>
          {formik.errors.name}
        </FormHelperText>

        <Typography
          variant="caption"
          style={{ color: "red", fontWeight: "bold", margin: "5px" }}
        >
          Never include company names or any sensitive information in the
          project name
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Job Location
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.serviceFormControl}
            >
              <SelectField
                label="Workplace type *"
                onChange={formik.handleChange("worklocation")}
                value={formik.values.worklocation}
                options={workLocationValues}
                type="workplaceOption"
              />

              <FormHelperText className={classes.errorMessage}>
                {formik.errors.worklocation}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={8}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.serviceFormControl}
            >
              <SelectField
                label="Diversity Requirement"
                onChange={formik.handleChange("diversity")}
                value={formik.values.diversity}
                options={diversityValues}
                multiple={true}
                type="diversity"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          gutterBottom
          className={classes.textFieldLabel}
        >
          Timelines (Define appropriate dates)
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl
              className={classes.serviceFormControl}
              fullWidth={true}
            >
              <InputField
                label="Work Start by *"
                value={formik.values.workstartby}
                onChange={formik.handleChange("workstartby")}
                type="date"
              />
            </FormControl>

            <FormHelperText className={classes.serviceErrorMessage}>
              {formik.errors.workstartby}
            </FormHelperText>
          </Grid>

          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.serviceFormControl}
            >
              <InputField
                label="Profiles Receiving Deadline *"
                value={formik.values.profilereceivingdeadline}
                onChange={formik.handleChange("profilereceivingdeadline")}
                type="date"
              />
            </FormControl>

            <FormHelperText className={classes.serviceErrorMessage}>
              {formik.errors.profilereceivingdeadline}
            </FormHelperText>
          </Grid>

          <Typography
            variant="h6"
            gutterBottom
            className={classes.textFieldSubLabel}
          >
            Indicative Interview Schedule
          </Typography>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.serviceFormControl}
            >
              <InputField
                label="From *"
                value={formik.values.indicativeinterviewschedulefrom}
                onChange={formik.handleChange(
                  "indicativeinterviewschedulefrom"
                )}
                type="date"
              />
            </FormControl>

            <FormHelperText className={classes.serviceErrorMessage}>
              {formik.errors.indicativeinterviewschedulefrom}
            </FormHelperText>
          </Grid>

          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              className={classes.serviceFormControl}
            >
              <InputField
                label="To *"
                value={formik.values.indicativeinterviewscheduleto}
                onChange={formik.handleChange("indicativeinterviewscheduleto")}
                type="date"
              />
            </FormControl>

            <FormHelperText className={classes.serviceErrorMessage}>
              {formik.errors.indicativeinterviewscheduleto}
            </FormHelperText>
          </Grid>
        </Grid>
      </FormControl>

      {props.type !== "review" && (
        <div style={{ margin: "8px 0" }}>
          <NextPrevButtons
            nextLocation="create"
            nextDisabled={!formik.isValid || false}
            prevDisabled={true}
            loading={loading || uloading}
          />
        </div>
      )}
    </form>
  );
}
