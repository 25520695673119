import { useHistory } from "react-router";

import { useQuery } from "@apollo/client";

import Load from "../../shared/skeletons/skeleton";

import { getCompanyNDAStatus } from "../companies/Company2.0/query";

import { useStyles } from "./styles";

export default function Marketplace() {
  const classes = useStyles();
  const history = useHistory();

  const { loading, data } = useQuery(getCompanyNDAStatus, {
    variables: { input: {} },
  });

  if (data) {
    if (data.companyNDAStatus.status !== "completed") {
      history.push("/agreements");
    }
  }

  if (loading) {
    return <Load />;
  }

  return (
    <div className={classes.claimDiv}>
      <div className={classes.agreementDiv}></div>
    </div>
  );
}
