import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

// import SimpleSnackbar from './snackbar'
// import SimpleErrorSnackbar from './errorsnackbar'
import { makeStyles } from '@material-ui/core'
import SimpleErrorSnackbar from '../companies/Company2.0/shorts/errorsnackbar'
import SimpleSnackbar from '../companies/Company2.0/shorts/snackbar'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0.2,0)',
    },
  },
}))

export default function AlertBox({
  open,
  onConfirm,
  onClose,
  title,
  description,
  mutaionError,
  data,
  type,
}) {
  const classes = useStyles()
  const onClick = (e) => {
    e.preventDefault()
    onConfirm()
    onClose()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      {mutaionError ? <SimpleErrorSnackbar /> : null}
      {data ? <SimpleSnackbar /> : null}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>

          <Button onClick={onClick} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
