import React from "react";
import { useHistory } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";

import AppBarAvatar from "./appBarAvatar";

// import TechMenu from "./techMenu";
// import FunctionMenu from "./functionMenu";
// import VerticalMenu from "./verticalMenu";

import Notifications from "./notifications";

import LogoImage from "./../../img/vbridge-logo.png";
// import { useQuery } from "@apollo/client";

// import { listLeads } from "../entities/profile2.0/query";
// import RequestsCount from "../entities/home/RequestsCount";

import { useStyles } from "./appBarStyles";

import ProductsMenu from "./AppBarMenuItems/ProductsMenu";
import ServicesMenu from "./AppBarMenuItems/ServicesMenu";
import SearchBox from "../entities/home/SearchBox";
import MenuDrawer from "./MenuDrawer";

// import RequestsCount from "../entities/home/RequestsCount";
// import HelpMenu from "../entities/Help/HelpMenu";

function Logo({ userInfo, showMenu }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div
      className={showMenu ? classes.logo : classes.singleDiv}
      onClick={() => {
        if (showMenu) {
          history.push("/");
        }
      }}
    >
      <img
        alt="img"
        src={LogoImage}
        className={showMenu ? classes.logoImg : classes.singleLogo}
      />
    </div>
  );
}

export default function MainAppBar({
  userInfo,
  onChange,
  toggleValue,
  drawerOpen,
  mainDrawerClick,
}) {
  const classes = useStyles();

  //   const [drawerOpen, setdrawerOpen] = useState(true);

  //   const closeMainDrawer = (e) => {
  //     setdrawerOpen(false);
  //   };

  //   const openMainDrawer = (e) => {
  //     setdrawerOpen(!drawerOpen);
  //   };

  //   const toggleDrawer = (anchor, open) => (event) => {
  //     if (
  //       event.type === "keydown" &&
  //       (event.key === "Tab" || event.key === "Shift")
  //     ) {
  //       return;
  //     }
  //   };

  const onSearch = (text) => {
    // setSearchText(text);
  };

  const onTypeSelect = (value) => {
    onChange(value);
  };

  const showMenu =
    userInfo?.isProfileUpdated === "true" &&
    userInfo?.companyvbeid !== "" &&
    userInfo?.status !== false &&
    //userInfo?.onBoardingCompleted !== false &&
    //userInfo?.roletype !== "seller" &&
    userInfo?.isValidLicense !== "false";

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={showMenu ? classes.appBar : classes.appBarSingle}
      >
        {showMenu ? (
          <Toolbar variant="dense">
            <IconButton
              onClick={mainDrawerClick}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>

            <Hidden only={["xs", "sm"]}>
              <Logo userInfo={userInfo} showMenu={showMenu} />
            </Hidden>

            <div className={classes.headerSearch}>
              <SearchBox
                onSearchClick={onSearch}
                searchType="keyword"
                onSelect={onTypeSelect}
                toggleValue={toggleValue}
                handleDrawerClose={mainDrawerClick}
              />
            </div>

            <div className={classes.root} />

            <div className={classes.dropdownMenu}>
              <ProductsMenu handleDrawerClose={mainDrawerClick} />

              <ServicesMenu
                onChange={onChange}
                handleDrawerClose={mainDrawerClick}
              />
            </div>

            <Notifications />

            {userInfo ? (
              <AppBarAvatar
                userInfo={userInfo}
                showMenu={showMenu}
                mainDrawerClick={mainDrawerClick}
              />
            ) : null}
          </Toolbar>
        ) : (
          <Hidden only={["xs", "sm"]}>
            <Logo userInfo={userInfo} />
            <AppBarAvatar
              userInfo={userInfo}
              showMenu={showMenu}
              mainDrawerClick={mainDrawerClick}
            />
          </Hidden>
        )}
      </AppBar>

      <MenuDrawer
        open={drawerOpen}
        handleDrawerClose={mainDrawerClick}
        userInfo={userInfo}
      />
    </div>
  );
}
