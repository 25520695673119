import React from "react";

import { CustomNoRowsOverlay } from "./styles";

import { Paper } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

export default function ListQuestionare({ questionare, columns }) {
  return (
    <div>
      {/* <Box m={10}> */}
      <Paper square={true}>
        <DataGrid
          pagination
          density="compact"
          autoHeight={true}
          disableColumnMenu={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          sortingmode="server"
          rowHeight={80}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          rows={questionare}
          columns={columns.map((column) => ({
            ...column,
            sortable: false,
          }))}
          pageSize={5}
        />
      </Paper>
      {/* </Box> */}
    </div>
  );
}
