import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Tabs, Tab, Grid, Box, Divider } from "@material-ui/core";

import CompanyOverview from "./tabs/CompanyOverview";
import ClaimOrgType from "./tabs/ClaimOrgType";

import {
  a11yProps,
  ENTERPRISE,
  ENTERPRISE_SERVICE,
  ENTERPRISE_TECH,
  EP_TECH_SERVICE,
  SERVICE_PROVIDER,
  TECH_SERVICE,
  TECH_VENDOR,
} from "../../../../functions/helper";

import MainList from "../../groups2.0/MainList";
import Skills from "./Skills";
import { useParams } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "white",
  },

  tabs: {
    fontSize: "0.8em",
    width: theme.spacing(18),
    "& span": {
      textTransform: "capitalize !important",
    },
  },

  servicetabs: {
    lineHeight: "1.3 !important",
    fontSize: "0.8em",
    width: theme.spacing(18),

    "& span": {
      textTransform: "capitalize !important",
    },
  },
}));

export default function CompanyTabs({
  isReadOnly,
  isVbh,
  isAdmin,
  isBuyer,
  orgType,
  image,
  updateOrgTypeCache,
  toggleValue,
  tabPath,
  fixedbid,
  timerbid,
  productbid,
  userInfo,
}) {
  const classes = useStyles();

  // const showProducts =
  //   orgType === TECH_VENDOR ||
  //   orgType === TECH_SERVICE ||
  //   orgType === ENTERPRISE_TECH ||
  //   orgType === EP_TECH_SERVICE ||
  //   isAdmin;

  let initialIndex = 0;

  // if (tabPath) {
  //   if (tabPath === "Resources") {
  //     initialIndex = showServices || isAdmin ? 5 : 4;
  //   }
  //   if (tabPath === "SuccessStories") {
  //     initialIndex = showServices || isAdmin ? 4 : 3;
  //   }
  // }

  const [value, setValue] = useState(initialIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { id } = useParams();

  //   let indexP =[0,1,2];
  // if(productbid){indexP.push(3)}
  // if(timerbid){indexP.push(4)}
  // if(fixedbid){indexP.push(5)}

  //   let indexx = [0,1,2];
  //   if (productbid)=1
  // //  let indexP=[];

  // {
  //   !timerbid ? 1 : 2;
  // }

  // {
  //   !timerbid && !fixebid
  //     ? 1
  //     : timerbid || !fixedbid
  //     ? 2
  //     : !timerbid || fixedbid
  //     ? 2
  //     : 3;
  // }

  // {
  //   !timerbid;
  // }

  let tabIndex = [0, 1, 2];

  if (productbid) {
    tabIndex.push(3);
  }
  if (timerbid) {
    tabIndex.push(4);
  }
  if (fixedbid) {
    tabIndex.push(5);
  }

  // let timerBidIndex = 1;
  // let fixedbidIndex = 2;
  // let productBidIndex = 3;

  // console.log("tabIndex", tabIndex);

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab className={classes.tabs} label="Company" {...a11yProps(0)} />

        {timerbid && userInfo.companyvbeid !== id && (
          <Tab
            className={classes.servicetabs}
            label="Contract Resources Services"
            {...a11yProps(1)}
          />
        )}

        {fixedbid && userInfo.companyvbeid !== id && (
          <Tab
            className={classes.servicetabs}
            label="Work Package Services"
            {...a11yProps(!timerbid ? 1 : 2)}
          />
        )}

        {productbid && userInfo.companyvbeid !== id && (
          <Tab
            className={classes.tabs}
            label="Products"
            {...a11yProps(
              timerbid && fixedbid ? 3 : timerbid || fixedbid ? 2 : 1
            )}
          />
        )}

        {orgType !== "Enterprise" && userInfo.companyvbeid !== id && (
          <Tab
            className={classes.tabs}
            label="Success Stories"
            {...a11yProps(tabIndex.length - 2)}
          />
        )}

        {/* {orgType !== ENTERPRISE && userInfo.companyvbeid !== id && (
          <Tab
            className={classes.tabs}
            label="Resources"
            {...a11yProps(tabIndex.length - 1)}
          />
        )} */}

        {/* {orgType === ENTERPRISE ? (
          <Tab
            className={classes.tabs}
            label="VBridge Hub Admins"
            {...a11yProps(1)}
          />
        ) : orgType !== ENTERPRISE ? (
          <Tab
            className={classes.tabs}
            label="VBridge Hub Admin"
            {...a11yProps(5)}
          />
        ) : null} */}
      </Tabs>
      <Divider />

      <TabPanel value={value} index={0}>
        <CompanyOverview
          isReadOnly={isReadOnly}
          orgType={orgType}
          isBuyer={isBuyer}
          isAdmin={isAdmin}
        />
      </TabPanel>

      {isBuyer ? null : (
        <>
          {timerbid && (
            <TabPanel value={value} index={1}>
              <Grid container spacing={1} direction="row" alignItems="center">
                <Grid item md={12}>
                  <Box m={1}>
                    <Skills
                      type="skills"
                      tab="company"
                      isReadOnly={isReadOnly}
                      isAdmin={isAdmin}
                      toggleValue={toggleValue}
                    />
                  </Box>
                </Grid>
              </Grid>{" "}
            </TabPanel>
          )}

          {fixedbid && (
            <TabPanel value={value} index={!timerbid ? 1 : 2}>
              <MainList
                type="services"
                tab="company"
                isReadOnly={isReadOnly}
                isAdmin={isAdmin}
                image={image}
                toggleValue={toggleValue}
              />
            </TabPanel>
          )}

          {productbid && (
            <TabPanel
              value={value}
              index={timerbid && fixedbid ? 3 : timerbid || fixedbid ? 2 : 1}
            >
              <Grid container spacing={1} direction="row" alignItems="center">
                <Grid item md={12}>
                  <Box m={1}>
                    <MainList
                      type="products"
                      tab="company"
                      isReadOnly={isReadOnly}
                      isAdmin={isAdmin}
                      toggleValue={toggleValue}
                    />
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
          )}
          {/* 
          {(showServices || isAdmin) && (
            // index={showProducts ? 3 : 2}
            <TabPanel value={value} index={2}>
              {orgType !== TECH_SERVICE &&
              orgType !== SERVICE_PROVIDER &&
              orgType !== ENTERPRISE_SERVICE &&
              orgType !== EP_TECH_SERVICE ? (
                <ClaimOrgType
                  orgType="Service Provider"
                  type="service"
                  updateOrgTypeCache={updateOrgTypeCache}
                />
              ) : (
                <MainList
                  type="services"
                  tab="company"
                  isReadOnly={isReadOnly}
                  isAdmin={isAdmin}
                  image={image}
                  toggleValue={toggleValue}
                />
              )}
            </TabPanel>
          )} */}

          {/* {(orgType === TECH_VENDOR ||
            orgType === TECH_SERVICE ||
            orgType === ENTERPRISE_TECH ||
            orgType === EP_TECH_SERVICE ||
            isAdmin) && (
            <TabPanel
              value={value}
              index={!fixedbid && !timerbid ? 1 : fixedbid || timerbid ? 2 : 3}
            >
              {isAdmin &&
              orgType !== TECH_SERVICE &&
              orgType !== TECH_VENDOR &&
              orgType !== ENTERPRISE_TECH &&
              orgType !== EP_TECH_SERVICE ? (
                <ClaimOrgType
                  orgType="Tech Vendor"
                  type="product"
                  updateOrgTypeCache={updateOrgTypeCache}
                />
              ) : (
                <Grid container spacing={1} direction="row" alignItems="center">
                  <Grid item md={12}>
                    <Box m={1}>
                      <MainList
                        type="products"
                        tab="company"
                        isReadOnly={isReadOnly}
                        isAdmin={isAdmin}
                        toggleValue={toggleValue}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </TabPanel>
          )} */}

          <TabPanel value={value} index={tabIndex.length - 2}>
            <MainList
              type="caseStudies"
              tab="company"
              isReadOnly={isReadOnly}
              isAdmin={isAdmin}
              toggleValue={toggleValue}
            />
          </TabPanel>

          {/* <TabPanel value={value} index={tabIndex.length - 1}>
            <MainList
              type="usecases"
              tab="company"
              isReadOnly={isReadOnly}
              isAdmin={isAdmin}
              toggleValue={toggleValue}
            />
          </TabPanel> */}
        </>
      )}
      {/* 
      {vbhUser && (
        <TabPanel value={value} index={orgType === ENTERPRISE ? 1 : 5}>
          <Admin />
        </TabPanel>
      )} */}
    </div>
  );
}
