import clsx from "clsx";
import { useStyles } from "./styles";

export default function TemplateTypeButtons({
  name,
  selectedTemplate,
  onTemplateClick,
  type,
}) {
  const classes = useStyles();

  const selectedTemplateOption = clsx({
    [classes.templateOptionsList]: true,
    [classes.selectedOption]: true,
  });

  const templateOption = clsx({
    [classes.templateOptionsList]: true,
    [classes.selectedOption]: false,
  });

  return (
    <div
      className={
        selectedTemplate === type ? selectedTemplateOption : templateOption
      }
      onClick={() => onTemplateClick(type)}
    >
      {name} Templates
    </div>
  );
}
