import { useState } from "react";
import { useParams } from "react-router-dom";

import { FormControl, Grid } from "@material-ui/core";

import { useMutation, useQuery } from "@apollo/client";

import {
  GET_COMPANIES_BY_PROJECT,
  GET_COMPANIES_BY_SKILL,
  REMOVE_COMPANY_FROM_PROJECT,
} from "../../../queries/query";

import { addCompanyToProject } from "../../../../groups2.0/query";

import SelectField from "../../../../common/SelectField";
import PrimaryButton from "../../../../common/PrimaryButton";

import { supplierFilterOptions } from "../../../../../../functions/options";

//import { listsuggestedvendors } from "../../../../project v2.0/concierge/query";

import ProviderCard from "./ProviderCard";
import SelectedSuppliersSlider from "./SelectedSuppliersSlider";
import CompanyDetail from "./CompanyDetails";

import { useStyles } from "./styles";

export default function ProvidersList({ projectData, type }) {
  const { id } = useParams();
  const classes = useStyles();

  const [selectedFilter, setSelectedFilter] = useState(
    type === "review" ? "selected" : "all"
  );
  const [showSelected, setShowSelected] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [openDetail, setOpenDetail] = useState(false);

  const { data: companiesByProject, refetch } = useQuery(
    GET_COMPANIES_BY_PROJECT,
    {
      fetchPolicy: "cache-and-network",
      variables: { input: { vbeid: id } },
    }
  );

  let inputVariables = {
    skilllist: projectData.getProjectByIDV1.secondaryskill,
  };

  if (projectData.getProjectByIDV1.type !== "service-workpackage") {
    inputVariables = {
      skilllist: [projectData.getProjectByIDV1.primaryskill],
      location: projectData.getProjectByIDV1.country,
    };
  }

  const { data: companiesBySkill } = useQuery(GET_COMPANIES_BY_SKILL, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: inputVariables,
    },
  });

  //   const { data: suggested } = useQuery(listsuggestedvendors, {
  //     variables: { input: { vbeid: id } },
  //     fetchPolicy: "cache-and-network",
  //   });

  const getIndexOf = (provider) => {
    let providerIndex = "-1";

    allProviders.forEach((company, index) => {
      if (company.vbeid === provider.vbeid) {
        providerIndex = index;
      }
    });

    return providerIndex;
  };

  let recommendedProviders = [];
  let allProviders = [];

  if (companiesByProject && companiesBySkill) {
    recommendedProviders = [...companiesBySkill.getCompaniesbySkills];

    companiesByProject.listCompaniesByProjectID.forEach((company) => {
      let newObj = Object.assign({}, company, {
        selfSelected: true,
      });

      allProviders.push(newObj);
    });

    recommendedProviders.forEach((provider, index) => {
      if (index < 6) {
        let present = allProviders.some(
          (company) => company.vbeid === provider.vbeid
        );

        if (present) {
          let presentIndex = getIndexOf(provider);

          let newObj = Object.assign({}, provider, {
            selected: true,
          });

          recommendedProviders[index] = newObj;
          allProviders[presentIndex] = newObj;
        } else {
          let newObj = Object.assign({}, provider, {
            selected: false,
          });

          allProviders.push(newObj);
        }
      }
    });

    companiesByProject.listCompaniesByProjectID.forEach((company) => {
      companiesBySkill.getCompaniesbySkills.forEach((recommended, index) => {
        if (index < 6) {
          if (company.vbeid === recommended.vbeid) {
            let newObj = Object.assign({}, recommended, {
              selected: true,
            });

            recommendedProviders[index] = newObj;
          }
        }
      });
    });
  }

  const [addCompany] = useMutation(addCompanyToProject, {
    onCompleted: (e) => {
      refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [removeCompany] = useMutation(REMOVE_COMPANY_FROM_PROJECT, {
    onCompleted: (e) => {
      refetch();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const removeSupplier = (vbeid) => {
    removeCompany({
      variables: { input: { vbeid: id, listvbeid: [vbeid] } },
    });
  };

  const onSelect = (companyVbeid) => {
    addCompany({
      variables: { input: { vbeid: id, listvbeid: [companyVbeid] } },
    });
  };

  const onFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const viewSelectedSuppliers = () => {
    setShowSelected(true);
  };

  const onClose = () => {
    setShowSelected(false);
    setOpenDetail(false);
    setSelectedCompany([]);
  };

  const onCardClick = (company) => {
    setSelectedCompany(company);
    setOpenDetail(true);
  };

  return (
    <>
      <Grid
        container
        className={
          type !== "review" ? classes.providerList : classes.reviewList
        }
      >
        {type !== "review" && companiesByProject && (
          <>
            <Grid item md={3} className={classes.filterDiv}>
              <FormControl variant="outlined" fullWidth={true}>
                <SelectField
                  label="Filter by"
                  onChange={onFilterChange}
                  value={selectedFilter}
                  options={supplierFilterOptions}
                />
              </FormControl>
            </Grid>

            <Grid item md={5}></Grid>

            <Grid item md={4} className={classes.selectedButton}>
              <PrimaryButton
                label={`View Selected (${companiesByProject.listCompaniesByProjectID.length})`}
                onClick={viewSelectedSuppliers}
                variant="outlined"
              />
            </Grid>
          </>
        )}

        <Grid container spacing={2}>
          {selectedFilter === "selected" && companiesByProject ? (
            companiesByProject.listCompaniesByProjectID.length !== 0 ? (
              companiesByProject.listCompaniesByProjectID.map((company) => {
                return (
                  <Grid item md={type === "review" ? 3 : 4}>
                    <ProviderCard
                      company={company}
                      removeSupplier={() => removeSupplier(company.vbeid)}
                      type="selected"
                      onClick={() => {
                        onCardClick(company);
                      }}
                    />
                  </Grid>
                );
              })
            ) : (
              <div className={classes.emptyListText}>
                No suppliers are added to the project. To add suppliers to the
                project, use the search bar or product/services supplier listing
                on the top menu to review and Add to Project.
              </div>
            )
          ) : null}

          {type !== "review" &&
          selectedFilter === "platform" &&
          companiesBySkill ? (
            recommendedProviders.length !== 0 ? (
              recommendedProviders.map((company, index) => {
                if (index < 6) {
                  return (
                    <Grid item md={4}>
                      <ProviderCard
                        company={company}
                        onSelect={() => onSelect(company.vbeid)}
                        type="skillMatch"
                        onClick={() => {
                          onCardClick(company);
                        }}
                        removeSupplier={() => removeSupplier(company.vbeid)}
                      />
                    </Grid>
                  );
                } else return null;
              })
            ) : (
              <div className={classes.emptyListText}>
                No suppliers were found matching your selected skill and
                location
              </div>
            )
          ) : null}

          {type !== "review" &&
            selectedFilter === "all" &&
            allProviders.map((provider) => (
              <Grid item md={4}>
                <ProviderCard
                  company={provider}
                  onSelect={() => onSelect(provider.vbeid)}
                  type={provider.selfSelected ? "selected" : "skillMatch"}
                  onClick={() => {
                    onCardClick(provider);
                  }}
                  removeSupplier={() => removeSupplier(provider.vbeid)}
                />
              </Grid>
            ))}

          {/* {type !== "review" &&
        (selectedFilter === "all" || selectedFilter === "concierge") &&
        projectData.getProjectByIDV1.conciergestatus !== true
          ? selectedFilter !== "all" && (
              <div className={classes.emptyListText}>
                You have not opted for Concierge services. To seek
                recommendations, please send a note to vBridge Hub Concierge
                team by using the button on top.
              </div>
            )
          : suggested
          ? suggested.listSuggestedProductsFromConcierge.map(
              (company, index) => {
                if (index < 6) {
                  return (
                    <Grid item md={4}>
                      <ProviderCard
                        company={company}
                        onSelect={() => onSelect(company.vbeid)}
                        type="concierge"
                      />
                    </Grid>
                  );
                } else return null;
              }
            )
          : null} */}
        </Grid>
      </Grid>

      {companiesByProject && (
        <SelectedSuppliersSlider
          selectedCompanies={companiesByProject?.listCompaniesByProjectID}
          open={showSelected}
          onClose={onClose}
          onSelect={onSelect}
        />
      )}

      {openDetail && (
        <CompanyDetail
          open={openDetail}
          company={selectedCompany}
          onClose={onClose}
        />
      )}
    </>
  );
}
