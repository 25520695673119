import { useRef } from "react";

import {
  Chip,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectText: {
    fontSize: "0.8rem",
    "& .MuiOutlinedInput-input": {
      padding: "9.5px 14px",
    },
    "& legend": {
      width: "80% !important",
    },
  },
  optionValues: {
    fontSize: "0.8rem",
  },
  inputLabel: {
    marginLeft: theme.spacing(1),
  },
  checkbox: {
    padding: theme.spacing(0, 0.5, 0, 0),
  },
  checkboxIcon: {
    minWidth: "35px",
  },
  checkboxText: {
    "& span": {
      fontSize: "0.85rem",
    },
  },

  label: {
    fontSize: "14px",
  },

  comapnypage: {
    fontSize: "0.8rem",
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px",
    },
    "& legend": {
      width: "80% !important",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    fontSize: "0.75rem",
    height: "24px",
  },
  workPlaceText: {
    whiteSpace: "break-spaces",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

export default function SelectField({
  label,
  onChange,
  value,
  options,
  multiple,
  type,
  params,
  disabled,
  field,
}) {
  const classes = useStyles();
  const inputLabel = useRef(null);

  const getRenderValue = (selected) => {
    if (!multiple) {
      let selectedValues = [];
      let renderValue = "";

      if (options.length !== 0) {
        options.forEach((option) => {
          if (selected.includes(option.type || option.vbeid)) {
            selectedValues.push(option.value || option.name);
          }
        });
      }

      renderValue = selectedValues.join(", ");

      return renderValue;
    } else {
      let selectedValues = [];

      if (options.length !== 0) {
        options.forEach((option) => {
          if (selected.includes(option.type || option.vbeid)) {
            selectedValues.push(option.value || option.name);
          }
        });
      }

      return (
        <div className={classes.chips}>
          {selectedValues.map((value) => (
            <Chip key={value} label={value} className={classes.chip} />
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <InputLabel
        ref={inputLabel}
        id="demo-simple-select-outlined-label"
        className={classes.label}
      >
        {label}
      </InputLabel>

      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        className={
          field === "companypage" ? classes.comapnypage : classes.selectText
        }
        multiple={multiple}
        params={params}
        value={value}
        onChange={onChange}
        renderValue={(selected) => getRenderValue(selected)}
        MenuProps={MenuProps}
        disabled={disabled}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.type || option.vbeid}
            value={option.type || option.vbeid}
            className={type === "workplaceOption" ? classes.workPlaceText : ""}
          >
            <ListItemText
              primary={option.value || option.name}
              secondary={option.label}
              className={classes.checkboxText}
            />
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
