import React, {useEffect} from "react";
import { useParams } from "react-router-dom";

import { useStyles } from "./styles";

import {
    Grid,
    Typography,
    Button
  } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";

import { useMutation } from "@apollo/client";

import {signed} from './query';

const axios = require("axios");

export default function AddAttachments(props){
    const classes = useStyles();
    let {id} = useParams();
    let {attachMethod, isOwner, addNew, docType} = props;

    const [status, setStatus] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const [fileName, setFileName] = React.useState('');
    
    const [listFileNames, setFileNames] = React.useState([]);

    useEffect(() => {
        SendFileNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listFileNames]);

    function SendFileNames(){
        attachMethod(listFileNames);
    }

    function ChangeFileName(e){
        setFileName(e.target.files[0].name);
        //SendFileNames();
    }


    const [signedUrl] = useMutation(
        signed,
        {
          onCompleted: (e) => {
            setFileNames([...listFileNames, e.signedURLforProjectSupportDoc.path]);
            afterGettingSignedUrl(e);
          },
        }
      );
    
      function getSignedUrl(e) {
        signedUrl({
          variables: {
            input: {
              method: "put",
              name: e,
              vbeid: id,
              type: docType,
            },
          },
        });
      }
    
      function startUploadFile(e) {

        setMessage("Preparing for upload ..");
        setStatus(true);
        getSignedUrl(fileName);
      }
    
      function afterGettingSignedUrl(e) {
       
        uploadFile(e.signedURLforProjectSupportDoc.signedurl);
      }
    
      async function uploadFile(url) {
        await axios
          .request({
            method: "put",
            url: url,
            data: document.getElementById(id).files[0],
            onUploadProgress: (p) => {
              const percent = Math.round((p.loaded / p.total) * 100);
              setMessage("Uploading ..." + percent + "%");
            },
          })
          .then(function (response) {
            setStatus(false);
            //SendFileNames();
          });
      }
    


    return(
<>
        <Snackbar
        open={status}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="info">{message}</Alert>

      </Snackbar>

        <Grid container spacing={2} className={addNew?classes.addDraftAttachment:classes.addAttachment}>
        
        <Grid item className={classes.addAttachmentText}>
          {isOwner?(
               <Typography variant="subtitle2" color="primary">
               Upload supporting documents
             </Typography>
          ):(
            <Typography variant="subtitle2" color="primary">
            Supporting documents
          </Typography>
          )}
          

        </Grid>

        {isOwner?(
        <Grid item>
        <label htmlFor={id}>
        <input id={id} type="file" onChange={ChangeFileName}/>
        </label>
        </Grid>
        ):(null)}

        {fileName!==''?(
        <Grid item>
        <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.attachmentbutton}
            onClick={startUploadFile}
          >
            Upload
          </Button>
        </Grid>
        ):(null)}

        </Grid>
</>
    )
}