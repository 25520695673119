import { useState } from "react";

import { Paper, Typography, Tabs, Tab, Box } from "@material-ui/core";

import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

import ViewQAForm from "../../vendor/reponse/qaForm";

import DiscussionCard from "../../../messaging2.0/discussionCard";

import { useStyles } from "./styles";
import CandidateTable from "./CandidateTable";
import ServiceVendorForm from "./ServiceVendorForm";
import { GET_TEMPLATE_BY_PROJECT } from "../../queries/query";
import { useQuery } from "@apollo/client";
import MilestoneTable from "./MilestoneTable";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function VendorServiceResponse({ companyId, projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  const [value, setValue] = useState(0);

  const { data, loading } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id, typelist: ["qa_template"] } },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.vendorResponseRoot}>
      <Paper elevation={1} className={classes.responsePaper}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={<Typography variant="caption">View Response</Typography>}
            {...a11yProps(0)}
            className={classes.tab}
          />

          {data && data.getTemplatesByProjectID.length !== 0 && (
            <Tab
              label={<Typography variant="caption">View Q&A</Typography>}
              {...a11yProps(1)}
              className={classes.tab}
            />
          )}

          <Tab
            label={
              <Typography variant="caption">
                {projectData.type === "service-workpackage"
                  ? "Milestones"
                  : "Profiles of Resources"}
              </Typography>
            }
            {...a11yProps(2)}
            className={classes.tab}
          />

          <Tab
            label={<Typography variant="caption">Message Supplier</Typography>}
            {...a11yProps(3)}
            className={classes.tab}
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          <ServiceVendorForm
            id={id}
            companyId={companyId}
            readOnly={true}
            projectData={projectData}
          />
        </TabPanel>

        {data && data.getTemplatesByProjectID.length !== 0 && (
          <TabPanel value={value} index={1}>
            <ViewQAForm
              id={id}
              companyId={companyId}
              readOnly={true}
              projectData={projectData}
            />
          </TabPanel>
        )}

        <TabPanel
          value={value}
          index={data && data.getTemplatesByProjectID.length !== 0 ? 2 : 1}
        >
          {projectData.type === "service-workpackage" ? (
            <MilestoneTable projectData={projectData} />
          ) : (
            <CandidateTable projectData={projectData} />
          )}
        </TabPanel>

        <TabPanel
          value={value}
          index={data && data.getTemplatesByProjectID.length !== 0 ? 3 : 2}
        >
          <DiscussionCard
            productId={companyId}
            projectId={id}
            type="question"
            projectData={projectData}
          />
        </TabPanel>
      </Paper>
    </div>
  );
}
