import { gql } from "@apollo/client";

export const getInvoiceResourcesByStatus = gql`
  query getInvoiceResourcesByStatus($input: invoiceinput) {
    listInvoiceByStatus(input: $input) {
      projectName
      resourcename
      timesheetapprovalby
      startdate
      enddate
      numberofhours
      invoicenumber
      invoicedate
      invoiceamount
      invoicecopy
      invoicenumber
      engagementStartDate
      engagementEndDate
      companyname
      projectOwner
      PK
      SK
      currency
    }
  }
`;

export const GET_INVOICES_BY_STATUS = gql`
  query getWPInvoiceByStatus($input: invoiceinput) {
    wp_listInvoiceByStatus(input: $input) {
      projectName
      startdate
      enddate
      invoicenumber
      invoicedate
      invoiceamount
      invoicecopy
      engagementStartDate
      engagementEndDate
      companyname
      projectOwner
      PK
      SK
      currency
      price
      remarks
    }
  }
`;

export const ACCEPT_TIMESHEET = gql`
  mutation acceptTimesheet($input: invoiceinput!) {
    acceptTimesheet(input: $input) {
      responsestatus
    }
  }
`;

export const REJECT_TIMESHEET = gql`
  mutation rejectTimesheet($input: invoiceinput!) {
    rejectTimesheet(input: $input) {
      responsestatus
    }
  }
`;

export const ACCEPT_MILESTONE = gql`
  mutation acceptMilestone($input: invoiceinput!) {
    wp_acceptMilestone(input: $input) {
      responsestatus
    }
  }
`;

export const REJECT_MILESTONE = gql`
  mutation rejectMilestone($input: invoiceinput!) {
    wp_rejectMilestone(input: $input) {
      responsestatus
    }
  }
`;

export const ACCEPT_INVOICE = gql`
  mutation acceptInvoice($input: invoiceinput!) {
    acceptInvoice(input: $input) {
      responsestatus
    }
  }
`;

export const REJECT_INVOICE = gql`
  mutation rejectInvoice($input: invoiceinput!) {
    rejectInvoice(input: $input) {
      responsestatus
    }
  }
`;

export const WP_ACCEPT_INVOICE = gql`
  mutation acceptInvoice($input: invoiceinput!) {
    wp_acceptInvoice(input: $input) {
      responsestatus
    }
  }
`;

export const WP_REJECT_INVOICE = gql`
  mutation rejectInvoice($input: invoiceinput!) {
    wp_rejectInvoice(input: $input) {
      responsestatus
    }
  }
`;
