import React, { useState } from "react";
import { useStyles } from "./styles";
import { CircularProgress } from "@material-ui/core";
import Table from "@material-ui/core/Table";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useQuery } from "@apollo/client";
import { getFormattedDate } from "../../../../functions/helper";
import CandidateActionSlider from "../buyer/response/CandidateActionSlider";
import { GET_MILESTONES_BY_STATUS } from "../buyer/response/query";

export default function SupplierOnboarding({ drawerOpen }) {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [actionSlider, setActionSlider] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedResource, setSelectedResource] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");

  const { data, loading, refetch } = useQuery(GET_MILESTONES_BY_STATUS, {
    variables: { input: { status: "SELLER_ACCEPTED_PO" } },
    fetchPolicy: "no-cache",
  });

  const resourceDatalength = data?.wp_listWorkPackageByStatus?.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClose = () => {
    setActionSlider(false);
    setSelectedResource("");
  };

  const onActionClick = (currentResource) => {
    setActionSlider(true);
    setSelectedResource(currentResource);
    setAnchorEl(null);
  };

  const getStartDate = (workPackage) => {
    let milestoneResponse = JSON.parse(workPackage.data);

    return getFormattedDate(milestoneResponse.projectStartDate);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, resourceDatalength - page * rowsPerPage);

  return (
    <div className={drawerOpen ? classes.drawerOpenRoot : classes.root}>
      <p className={classes.heading}>Supplier Onboarding</p>

      <Paper className={classes.paper}>
        <TableContainer className="tableContainer">
          {loading ? (
            <CircularProgress className={classes.loadStyle} size={24} />
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Project Owner</TableCell>
                  <TableCell align="center">Supplier Name</TableCell>
                  <TableCell align="center">PO Number</TableCell>
                  <TableCell align="center">PO Date</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>

              {data?.wp_listWorkPackageByStatus?.length ? (
                <TableBody>
                  {data?.wp_listWorkPackageByStatus?.map(
                    (workPackage, index) => {
                      return (
                        <TableRow>
                          <TableCell align="left">
                            {workPackage.projectName}
                          </TableCell>

                          <TableCell align="center">
                            {workPackage.projectOwner}
                          </TableCell>

                          <TableCell align="center">
                            {workPackage.companyname}
                          </TableCell>

                          <TableCell align="center">
                            {workPackage.ponumber}
                          </TableCell>

                          <TableCell align="center">
                            {getStartDate(workPackage)}
                          </TableCell>

                          <TableCell align="center">
                            <IconButton
                              className={classes.iconstyle}
                              onClick={(event) => {
                                handleClick(event, index);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>

                          {currentIndex === index && (
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                            >
                              <MenuItem
                                className={classes.menuitem}
                                onClick={() => {
                                  onActionClick(workPackage);
                                }}
                              >
                                Onboard Supplier
                              </MenuItem>

                              {/* <MenuItem
                                className={classes.menuitem}
                                onClick={() => {
                                  onActionClick(workPackage);
                                }}
                              >
                                Supplier Not Joined
                              </MenuItem> */}
                            </Menu>
                          )}
                        </TableRow>
                      );
                    }
                  )}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7}>No new Suppliers!</TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={resourceDatalength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {actionSlider && (
        <CandidateActionSlider
          actionSlider={actionSlider}
          onClose={onClose}
          action="onboardSupplier"
          refetch={refetch}
          selectedResource={selectedResource}
          projectData=""
          //poDetails={poDetails?.listPO}
          remarks=""
        />
      )}
    </div>
  );
}
