import React, { useState } from "react";

import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useQuery } from "@apollo/client";
import { getCompanyById } from "./query";

import ContentCard from "./contentCard";
import "./messaging.css";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
  },
  question: {
    margin: "0",
  },
}));

export default function QuestionContent({
  projectId,
  productId,
  id,
  question,
  companyid,
  createdBy,
  totalReplies,
}) {
  const classes = useStyles();

  const { data } = useQuery(getCompanyById, {
    variables: { input: { vbeid: companyid } },
  });

  const [replycount, setReplycount] = useState(
    totalReplies ? parseInt(totalReplies) : 0
  );

  function updateRepliesCount() {
    setReplycount(totalReplies ? replycount + 1 : 1);
  }

  return (
    <Box p={1} className={classes.root}>
      <Grid container justify="flex-start">
        <Grid item md={12}>
          <ContentCard
            id={id}
            question={question}
            createdBy={createdBy}
            userImage={data?.queryCompanybyid?.image}
            totalReplies={replycount}
            projectId={projectId}
            productId={productId}
            updateRepliesCount={updateRepliesCount}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
