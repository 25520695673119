import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import { Chip, ListItemIcon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useQuery, useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { useHistory } from "react-router-dom";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import EmptyDiv from "../companies/Company2.0/tabs/lists/emptyDiv";
import { RecGetProductsbyMappedType } from "./query";
import { VBRIDGEHUB_MEDIAURL } from "../../../constants";
import TopicList from "../topic/topiclist";
import TopicMapping from "./TopicMapping";
import { EllipsisText } from "../DataValidation/styles";
import Paper from "@material-ui/core/Paper";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#eee",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  list: {
    padding: theme.spacing(0),
  },

  name: {
    fontSize: "0.75rem",
    fontWeight: 400,
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  productHead: {
    cursor: "pointer",
  },
}));

function Heading(props) {
  let history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.name} variant="h6">
        {props.name}
      </Typography>
    </>
  );
}

export default function ListProd(props) {
  const classes = useStyles();
  let { id } = useParams();
  const client = useApolloClient();

  const [checked, setChecked] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [checkValue, setCheckValue] = React.useState(false);
  const [awardid, setAwardid] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const checkedValues = [];

    setAwardid(value.vbeid);

    if (currentIndex === -1) {
      newChecked.push(value);

      newChecked.map((value) => {
        return checkedValues.push(value.vbeid);
      });
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setValues(checkedValues);

    if (currentIndex === -1) {
      setCheckValue(true);
    } else if (currentIndex >= 1) {
      setCheckValue(true);
    } else if (newChecked.length < 1) {
      setCheckValue(false);
    }
  };

  const { data, loading, refetch } = useQuery(RecGetProductsbyMappedType, {
    variables: {
      input: {
        offset: 0,
        limit: 25,
        type: "",
      },
    },
  });

  if (data) {
    //console.log("datav", data?.RecGetProductsbyMappedType);
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item md={12}>
          <List className={classes.list}>
            {data ? (
              data.RecGetProductsbyMappedType.length > 0 ? (
                data.RecGetProductsbyMappedType.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value.vbeid}`;

                  return (
                    <div className={classes.root}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          {/* <Paper className={classes.paper}> */}
                          <ListItem key={value.vbeid} divider dense>
                            <ListItemIcon>
                              <Checkbox
                                edge="end"
                                onChange={handleToggle(value)}
                                checked={checked.indexOf(value) !== -1}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>

                            <ListItemAvatar>
                              <Avatar
                                className={classes.large}
                                variant="square"
                                alt={value.name}
                                src={VBRIDGEHUB_MEDIAURL + "/" + value.image}
                              >
                                <CancelPresentationIcon />
                              </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                              disableTypography={true}
                              // style={{ padding: "2rem" }}
                              primary={
                                <Heading
                                  id={value.id}
                                  name={value.name}
                                  awardername={value.awardername}
                                  year={value.year}
                                />
                              }
                            />

                            <ListItemText
                              disableTypography={true}
                              // style={{ padding: "2rem" }}
                              primary={
                                <Heading
                                  id={value.id}
                                  name={value.shortdescription}
                                  awardername={value.awardername}
                                  year={value.year}
                                />
                              }
                            />
                            <ListItemText>
                              <TopicMapping productIds={checked} />
                            </ListItemText>
                          </ListItem>
                          {/* </Paper> */}
                        </Grid>
                        <Grid item xs={12}>
                          {/* <Paper className={classes.paper}> */}
                          <ListItemText>
                            {value.topiclist?.map((val) => (
                              <LightTooltip title={val.name} placement="bottom">
                                <Chip
                                  label={
                                    <EllipsisText>{val.name}</EllipsisText>
                                  }
                                  className={classes.chipDesign}
                                  size="small"
                                  // onDelete={() => handleDelete(val.vbeid)}
                                />
                              </LightTooltip>
                            ))}
                          </ListItemText>
                          {/* </Paper> */}
                        </Grid>
                      </Grid>
                    </div>
                  );
                })
              ) : (
                <EmptyDiv type="Awards" />
              )
            ) : null}
            {/* {data ? (
              data.RecGetProductsbyMappedType.length > 0 ? (
                data.RecGetProductsbyMappedType.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value.vbeid}`;

                  return (
                    <ListItem key={value.vbeid} divider dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(value)}
                          checked={checked.indexOf(value) !== -1}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>

                      <ListItemAvatar>
                        <Avatar
                          className={classes.large}
                          variant="square"
                          alt={value.name}
                          src={VBRIDGEHUB_MEDIAURL + "/" + value.image}
                        >
                          <CancelPresentationIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <Grid item xs={12} sm={6}>
                        <ListItemText
                          disableTypography={true}
                          // style={{ padding: "2rem" }}
                          primary={
                            <Heading
                              id={value.id}
                              name={value.name}
                              awardername={value.awardername}
                              year={value.year}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ListItemText
                          disableTypography={true}
                          // style={{ padding: "2rem" }}
                          primary={
                            <Heading
                              id={value.id}
                              name={value.shortdescription}
                              awardername={value.awardername}
                              year={value.year}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <ListItemText>
                          <TopicMapping productIds={checked} />
                        </ListItemText>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <ListItemText>
                          {value.topiclist?.map((val) => (
                            <LightTooltip title={val.name} placement="bottom">
                              <Chip
                                label={<EllipsisText>{val.name}</EllipsisText>}
                                className={classes.chipDesign}
                                size="small"
                                // onDelete={() => handleDelete(val.vbeid)}
                              />
                            </LightTooltip>
                          ))}
                        </ListItemText>
                      </Grid>
                    </ListItem>
                  );
                })
              ) : (
                <EmptyDiv type="Awards" />
              )
            ) : null} */}
          </List>
        </Grid>
      </Grid>
    </>
  );
}
