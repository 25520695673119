import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useStyles } from "../groups2.0/styles";
import { Button, Grid } from "@material-ui/core";
import CategoryDropDown from "./CategoryDropDown.js";
import Popover from "@material-ui/core/Popover";

export default function ViewMoreButton({
  title,
  groups,
  onClick,
  length,
  data,
  categoriesCount,
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.HelpIcon}>
        <Button
          className={classes.reqBtn}
          variant="outlined"
          size="small"
          color="primary"
          onClick={handleClick}
        >
          +{categoriesCount}
        </Button>
      </div>

      <Grid>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <CategoryDropDown
            data={data}
            onClick={onClick}
            length={length}
            title="Visit Category Page"
          />
        </Popover>
      </Grid>
    </>
  );
}
