import {
  Grid,
  Typography,
  FormControl,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import * as React from "react";

import { useFormik } from "formik";

import { useMutation, useQuery } from "@apollo/client";

import { useParams } from "react-router-dom";

import { GET_PROJECT, UPDATE_PROJECT } from "../../queries/query";

import { useStyles } from "./styles";

const validateShort = (values) => {
  const errors = {};
  if (!values.shortdescription) {
    errors.shortdescription = "Required";
  }

  return errors;
};

const Form = ({ data, refetch }) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      vbeid: data.vbeid,
      shortdescription: data.shortdescription,
    },

    validate: validateShort,

    onSubmit: (values) => {
      updateProject({ variables: { input: values } });
    },
  });

  const [updateProject] = useMutation(UPDATE_PROJECT, {
    onCompleted: (e) => {
      refetch();
    },
  });

  return (
    <FormControl component="fieldset" className={classes.descFormControlclass}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.textFieldLabel}
            >
              Project Short Description *
            </Typography>
          </Grid>

          <Grid item xs={10}>
            <TextField
              id="filled-full-width"
              name="shortdescription"
              placeholder="Project short description"
              multiline
              rows={8}
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.shortdescription}
              error={
                formik.touched.shortdescription &&
                Boolean(formik.errors.shortdescription)
              }
              onBlur={formik.handleSubmit}
              disabled={data.role[0] === "ADDED_TO"}
            />

            <Typography
              variant="caption"
              style={{ color: "red", fontWeight: "bold", margin: "5px" }}
            >
              Never include company names or any sensitive information in the
              short description
            </Typography>
          </Grid>
        </div>
      </form>
    </FormControl>
  );
};

export default function AddShortReview() {
  const classes = useStyles();
  const { id } = useParams();

  const { loading, error, data, refetch } = useQuery(GET_PROJECT, {
    variables: { input: { vbeid: id }, fetchPolicy: "no-cache" },
  });

  return (
    <Grid container alignItems="center" className={classes.reqRoot}>
      {loading ? (
        <CircularProgress className={classes.loading} />
      ) : data ? (
        <Form data={data.getProjectByIDV1} refetch={refetch} />
      ) : null}
    </Grid>
  );
}
