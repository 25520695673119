import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmAlert({ open, action, onConfirm, onCancel }) {
  const onClick = () => {
    onConfirm();
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {action === "remove"
            ? "Remove Section"
            : action === "block"
            ? "Block User"
            : "Unblock User"}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {action === "remove"
              ? "Are you sure you want to remove this section?"
              : `Are you sure you want to ${action} this user?`}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>

          <Button onClick={onClick} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
