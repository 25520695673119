import React from "react";
import { useParams, Link } from "react-router-dom";
import { useStyles } from "./styles";

import { Grid, Box, Paper } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useQuery } from "@apollo/client";

import { projectbyid, listLeads } from "./query";
import ProjectInfo from "./leadOverviewHead";
import SendResponse from "../../project/leads/tabs/respondbutton";
import DiscussionForum from "./discussionMain";
import LeadTabs from "./leadTabs";

export default function LeadOverview() {
  let { id, productid, invitedid } = useParams();

  const classes = useStyles();

  //project Information
  const { data } = useQuery(projectbyid, {
    variables: { input: { vbeid: id } },
  });

  //leads information
  const { data: leads, refetch } = useQuery(listLeads, {
    variables: { input: { vbeid: id } },
  });

  function Rejected(obj) {
    return obj.staus === "REJECTED";
  }

  function Approved(obj) {
    return obj.staus === "APPROVED";
  }

  let newObject = "";
  let approvedId = "";
  let rejectedId = "";

  if (leads) {
    if (leads.getLeadsByCompanyID.length >= 1) {
      newObject = leads.getLeadsByCompanyID.filter(
        (x) => x.productvbeid === productid
      );
      approvedId = newObject[0].role.filter(Approved);
      rejectedId = newObject[0].role.filter(Rejected);
    }
  }

  // console.log('approve',approvedId)

  // if(leads){
  // console.log( leads)}

  return (
    <Box m={10}>
      <Paper>
        <Grid container md={12}>
          <Grid item md={1} className={classes.arrowAlignInLeadOverview}>
            <Link to={"/requests"} variant="body2" className={classes.backLink}>
              <ArrowBackIcon />
            </Link>
          </Grid>

          <Grid item md={11}>
            <Grid container md={12} spacing={2}>
              {data ? (
                <Grid item md={8}>
                  <ProjectInfo
                    vbeid={id}
                    name={data.getProjectByID.name}
                    description={data.getProjectByID.description}
                    timeline={data.getProjectByID.timeline}
                    status={newObject ? newObject[0].role[0].staus : null}
                  />
                </Grid>
              ) : null}

              <Grid item md={4}>
                <Grid
                  container
                  spacing={4}
                  className={classes.acceptRejectButtonSpacing}
                >
                  {data && leads ? (
                    <Grid
                      item
                      md={12}
                      className={classes.acceptRejectButtonAlign}
                    >
                      {approvedId.length >= 1 ||
                      rejectedId.length >= 1 ? null : (
                        <SendResponse
                          refetch={refetch}
                          relationid={invitedid}
                        />
                      )}
                    </Grid>
                  ) : null}

                  <Grid
                    container
                    // spacing={2}
                    // justify="flex-start"
                    className={classes.messageButtonLeadHead}
                  >
                    {data && leads ? (
                      <Grid item>
                        <DiscussionForum
                          lead={true}
                          name={
                            leads.getLeadsByCompanyID[0].customername +
                            " : " +
                            leads.getLeadsByCompanyID[0].name
                          }
                          productId={productid}
                          projectId={id}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <LeadTabs approved={approvedId} productid={productid} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
