import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  imgsmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  heading: {
    fontSize: "1rem",
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  main: {
    padding: theme.spacing(1),
  },
  menuList: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  backgrid: {},
  backbutton: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(18),
  },
  projectName: {
    textAlign: "left",
    marginLeft: theme.spacing(-3),
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    justifyContent: "space-between",
  },
  logoimage: {
    marginBottom: theme.spacing(1),
  },
  responseRoot: {
    flexGrow: 1,
  },
  drawerOpenRoot: {
    flexGrow: 1,
    marginLeft: 200,
  },
  tableProjectName: {
    cursor: "pointer",
    "&:hover": {
      color: "#4179BD",
    },
  },
}));
