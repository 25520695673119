import * as React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Box,
  Divider,
  FormControlLabel,
  LinearProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useMutation, useQuery } from "@apollo/client";
import {
  SIGNED_URL,
  UPDATE_QA_ITEM,
  UPDATE_RESPONSE_ITEM,
  UPDATE_SOW_ITEM,
} from "../../../queries/query";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useFormik } from "formik";
const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  textfield: {
    height: theme.spacing(100),
  },
  properties: {
    width: theme.spacing(50),
  },
  propertiestitle: {
    marginBottom: theme.spacing(2),
  },
  propsbuttongroups: {
    marginTop: theme.spacing(2),
  },
  propsbutton: {
    margin: theme.spacing(1),
  },
  helpicon: {
    fontSize: theme.spacing(1.5),
    cursor: "pointer",
  },
  checkbox: {
    "& span": {
      fontSize: theme.spacing(1.4),
    },
  },
  input: {
    display: "none",
  },
  download: {
    fontSize: theme.spacing(1),
    textTransform: "none",
  },
  deleteFile: {
    fontSize: theme.spacing(1.2),
  },
  progressbar: {},
  rtitle: {
    fontWeight: "bold",
  },
}));

const validateItem = (values) => {
  const errors = {};
  if (!values.value) {
    errors.value = "Required";
  }

  return errors;
};

function FileBrowse(props) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <input
          className={classes.input}
          id={"file" + props.id}
          multiple
          type="file"
          onChange={(e) => {
            props.uploadFile(e);
          }}
        />

        <label htmlFor={"file" + props.id}>
          <Button
            color="secondary"
            size="small"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Attachment
          </Button>
        </label>
      </Grid>
      {props.upload ? (
        <Grid item xs={12} className={classes.progressbar}>
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={props.uploadPer}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

function DownLoadFile(props) {
  const classes = useStyles();

  const [dsignedUrl, { data: dsdata, loading: dsloading, error: dserror }] =
    useMutation(SIGNED_URL, {
      onCompleted: (e) => {
        console.log(e);

        let url = e.gettemplateSignedURL.signedurl;
        window.open(url);
      },
      onError: (err) => {
        console.log(err);
      },
    });

  function deleteFile() {
    props.formik.setFieldValue("attachmentfilename", "");
    props.formik.setFieldValue("attachment", "");
    props.formik.handleSubmit();
    props.setUpload(false);
    props.setUploadPer(0);
    props.setShowupload(true);
  }

  function downLoadFile() {
    dsignedUrl({
      variables: {
        input: {
          projectid: props.formik.values.projectid,
          productid: props.formik.values.productvbeid,
          attachmentfilename: props.name,
          method: "get",
        },
      },
    });
  }

  return (
    <Grid container>
      <Grid item xs={11}>
        <Button
          onClick={downLoadFile}
          color="secondary"
          size="small"
          component="span"
          className={classes.download}
          startIcon={<AttachFileIcon fontSize="small" />}
        >
          {props.name}
        </Button>
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={deleteFile}>
          <CloseIcon
            color="secondary"
            className={classes.deleteFile}
            fontSize="small"
          />
        </IconButton>
      </Grid>
    </Grid>
  );
}

function getQuery(type) {
  switch (type) {
    case "response_template":
      return UPDATE_RESPONSE_ITEM;

    case "qa_template":
      return UPDATE_QA_ITEM;

    case "sow_template":
      return UPDATE_SOW_ITEM;

    default:
      return null;
  }
}

function getQueryReturnItem(type) {
  switch (type) {
    case "response_template":
      return "addResTempItemvalue";

    case "qa_template":
      return "addQuestionTempItemvalue";

    case "sow_template":
      return "addAwardTempItemvalue";

    default:
      return null;
  }
}

export default function RCurrencyItem(props) {
  const classes = useStyles();
  const [metadata, setMetadata] = React.useState(props.metadata);
  const [showUpload, setShowupload] = React.useState(
    props.values.attachment === null || props.values.attachment === ""
  );
  const [upload, setUpload] = React.useState(false);
  const [uploadPer, setUploadPer] = React.useState(0);

  const [updateItem, { data, loading, error }] = useMutation(
    getQuery(props.type),
    {
      onCompleted: (e) => {
        // formik.setFieldValue(
        //   "timestamp",
        //   e[getQueryReturnItem(props.type)][0].timestamp
        // );
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const [signedUrl, { data: sdata, loading: sloading, error: serror }] =
    useMutation(SIGNED_URL, {
      onCompleted: (e) => {
        let url = e.gettemplateSignedURL.signedurl;
        let attachment = e.gettemplateSignedURL.attachment;
        formik.setFieldValue("attachment", attachment);
        putFile(url);
      },
      onError: (err) => {
        console.log(err);
      },
    });

  const formik = useFormik({
    initialValues: props.values,
    validate: validateItem,
    onSubmit: (values) => {
      var input = { ...values };

      if (input.timestamp === null) {
        delete input.timestamp;
      }

      updateItem({ variables: { input: [input] } });
    },
  });

  function uploadFile(e) {
    setUpload(true);
    var tfile = e.target.files[0];
    let name = tfile.name;

    formik.setFieldValue("attachmentfilename", name);

    signedUrl({
      variables: {
        input: {
          projectid: formik.values.projectid,
          productid: formik.values.productvbeid,
          attachmentfilename: name,
          method: "put",
        },
      },
    });
  }

  async function putFile(url) {
    await axios
      .request({
        method: "put",
        url: url,
        data: document.getElementById("file" + props.id).files[0],
        onUploadProgress: (p) => {
          const percent = Math.round((p.loaded / p.total) * 100);
          setUploadPer(percent);
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setShowupload(false);
          props.setUpload(false);
          formik.handleSubmit();
        }
      });
  }

  return (
    <Grid container alignItems="center" className={classes.root} spacing={1}>
      {!props.hidetitle ? (
        <Grid item xs={4}>
          <Typography
            variant="caption"
            gutterBottom
            className={props.readOnly ? classes.rtitle : ""}
          >
            {" "}
            {metadata.title}{" "}
          </Typography>
        </Grid>
      ) : null}

      <Grid item xs={props.hidetitle ? 12 : 4}>
        {props.readOnly ? (
          <Typography variant="caption">
            {metadata.currency} {formik.values.value}
          </Typography>
        ) : (
          <TextField
            type="number"
            autoComplete="off"
            // disabled={loading || props.readOnly}
            id="filled-full-width"
            name="value"
            onChange={formik.handleChange}
            helperText={formik.errors.value}
            error={Boolean(formik.errors.value)}
            value={formik.values.value}
            fullWidth
            variant="outlined"
            size="small"
            onBlur={formik.handleSubmit}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {metadata.currency}
                </InputAdornment>
              ),
            }}
          />
        )}
      </Grid>

      <Grid item xs={3}>
        {metadata.remarks ? (
          props.readOnly ? (
            <Typography variant="caption">{formik.values.remarks}</Typography>
          ) : (
            <TextField
              autoComplete="off"
              //   disabled={loading || props.readOnly}
              id="filled-full-width"
              name="remarks"
              value={formik.values.remarks}
              onChange={formik.handleChange}
              onBlur={formik.handleSubmit}
              placeholder="Remarks"
              fullWidth
              variant="outlined"
              size="small"
            />
          )
        ) : null}
      </Grid>
    </Grid>
  );
}
