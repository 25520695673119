import CustomCkeditor from "../../shared/customckeditor";
import EmptyDiv from "../companies/Company2.0/tabs/lists/emptyDiv";

export default function EditLongDescription({
  longdescription,
  id,
  isReadOnly,
  saveData,
}) {
  return longdescription !== "" && longdescription !== null ? (
    <CustomCkeditor
      data={longdescription}
      editorId={id}
      isReadOnly={isReadOnly ? true : false}
      saveMethod={saveData}
    />
  ) : !isReadOnly ? (
    <>
      <CustomCkeditor
        data={""}
        editorId={id}
        isReadOnly={isReadOnly ? true : false}
        saveMethod={saveData}
      />
    </>
  ) : (
    <EmptyDiv type="description" />
  );
}
