import { Badge, Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";

import * as React from "react";

import { useParams } from "react-router-dom";

import ViewPriceForm from "./priceForm";
import ViewQAForm from "./qaForm";
import ViewResponseForm from "./responseForm";
import ReviewSubmit from "./reviewSubmit";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  tabname: {
    fontSize: theme.spacing(1.2),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabName(props) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={2}>
        <Badge badgeContent={props.index} color="secondary" />
      </Grid>

      <Grid item xs={10}>
        <Typography variant="caption" className={classes.tabname}>
          {props.name}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function ViewResponse({ projectData, refetch }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const { id, productId } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let projectAccess =
    !projectData.role.includes("APPROVED") ||
    projectData.role.includes("SUBMITTED");

  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange}>
        <Tab
          label={<TabName index={1} name={"Solution Response"} />}
          {...a11yProps(0)}
          className={classes.tab}
        ></Tab>

        <Tab
          label={<TabName index={2} name={"Q&A Response"} />}
          {...a11yProps(1)}
          className={classes.tab}
        ></Tab>

        <Tab
          label={<TabName index={3} name={"Pricing Response"} />}
          {...a11yProps(2)}
          className={classes.tab}
        ></Tab>

        <Tab
          label={<TabName index={4} name={"Review & Submit"} />}
          {...a11yProps(3)}
          className={classes.tab}
        ></Tab>
      </Tabs>

      <TabPanel value={value} index={0}>
        <ViewResponseForm
          id={id}
          productId={productId}
          readOnly={projectAccess}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <ViewQAForm id={id} productId={productId} readOnly={projectAccess} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <ViewPriceForm
          productId={productId}
          projectId={id}
          readOnly={projectAccess}
          projectData={projectData}
        />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <ReviewSubmit
          readOnly={projectAccess}
          projectData={projectData}
          refetch={refetch}
          productId={productId}
        />
      </TabPanel>
    </div>
  );
}
