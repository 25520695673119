import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import { NewGroup } from "./creategroup";
import GroupTable from "./grouptable";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  projectList: {
    paddingTop: theme.spacing(5),
  },
  subheading: {
    fontSize: theme.spacing(1.5),
    fontWeight: "bold",

    marginLeft: theme.spacing(2),
    textAlign: "left",

    paddingTop: theme.spacing(2),
  },
}));
export default function GroupList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NewGroup />
      <Box m={12} className={classes.projectList}>
        <Paper>
          <Typography
            variant="button"
            display="block"
            color="primary"
            className={classes.subheading}
          >
            VbridgeHub Groups
          </Typography>

          <GroupTable />
        </Paper>
      </Box>
    </div>
  );
}
