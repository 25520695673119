import { gql } from "@apollo/client";

export const searchQuery = gql`
  query esQuery($input: esinput) {
    esQuery(input: $input) {
      total
      result {
        name
        image
        index
        vbeid
        type
        companyname
        companylogo
        shortdescription
      }
    }
  }
`;

export const getSearchResults = gql`
  query searchQuery($input: esinput) {
    openAIsearch(input: $input) {
      label
      vbeid
      id
    }
  }
`;
