import { CircularProgress, Grid, Typography } from "@material-ui/core";

import * as React from "react";

import { useMutation, useQuery } from "@apollo/client";

import { useParams } from "react-router-dom";

import { GET_PRODUCTS_BY_PROJECT, GET_PROJECT } from "../../queries/query";

import { useStyles } from "./styles";
import {
  //   listConciergerequests,
  listsuggestedvendors,
} from "../../../project v2.0/concierge/query";
import SearchResultCard from "../../../home/SearchResultCard";
import { postproduct } from "../../../groups2.0/query";
import VbridgeAlert from "../../../../shared/alert";

// const validateConcierge = (values) => {
//   const errors = {};
//   if (!values.remarks) {
//     errors.remarks = "Required";
//   }

//   return errors;
// };

const Message = ({ type }) => {
  const classes = useStyles();

  return (
    <div className={classes.startMessage}>
      <Typography variant="caption" component="div">
        Supplier recommendations from Concierge will appear here.
      </Typography>

      {type !== "requested" && (
        <Typography variant="caption">
          To seek recommendations, please send a note to vBridge Hub Concierge
          team by using the button on top.
        </Typography>
      )}
    </div>
  );
};

// const Form = ({ refetch, vbeid }) => {
//   const classes = useStyles();

//   const [requestConcierge, { loading }] = useMutation(REQUEST_FOR_CONCIERGE, {
//     onCompleted: (e) => {
//       refetch();
//     },
//   });

//   const formik = useFormik({
//     initialValues: {
//       vbeid: vbeid,
//       remarks: "",
//     },

//     validate: validateConcierge,

//     onSubmit: (values) => {
//       requestConcierge({ variables: { input: values } });
//     },
//   });

//   return (
//     <FormControl component="fieldset" className={classes.formControlclass}>
//       <form onSubmit={formik.handleSubmit}>
//         <div className={classes.mainForm}>
//           <Grid item xs={12}>
//             <Typography
//               variant="h6"
//               gutterBottom
//               className={classes.textFieldLabel}
//             >
//               Write your requirements here
//             </Typography>
//           </Grid>

//           <Grid item xs={12}>
//             <TextField
//               id="filled-full-width"
//               name="remarks"
//               placeholder="requirement summary"
//               multiline
//               rows={8}
//               fullWidth
//               variant="outlined"
//               onChange={formik.handleChange}
//               value={formik.values.remarks}
//               error={formik.touched.remarks && Boolean(formik.errors.remarks)}
//             />
//           </Grid>

//           <Grid item xs={12} style={{ padding: "1%" }}>
//             <Button variant="outlined" color="secondary" type="submit">
//               Submit
//             </Button>

//             {loading ? (
//               <CircularProgress size={24} className={classes.buttonProgress} />
//             ) : null}
//           </Grid>
//         </div>
//       </form>
//     </FormControl>
//   );
// };

export default function Concierge() {
  const classes = useStyles();
  const { id } = useParams();

  const { data } = useQuery(GET_PROJECT, {
    fetchPolicy: "network-only",
    variables: { input: { vbeid: id } },
  });

  const { data: products } = useQuery(GET_PRODUCTS_BY_PROJECT, {
    fetchPolicy: "cache-and-network",
    variables: { input: { vbeid: id } },
  });

  const { data: suggested } = useQuery(listsuggestedvendors, {
    variables: { input: { vbeid: id } },
    fetchPolicy: "cache-and-network",
  });

  const [addProductToProject, { data: addToProjectData, loading }] =
    useMutation(postproduct);

  const selectSupplier = (product) => {
    addProductToProject({
      variables: {
        input: { listvbeid: product, vbeid: id },
      },
    });
  };

  return (
    <Grid container className={classes.conciergeRoot}>
      <Grid container xs={12}>
        {addToProjectData && (
          <VbridgeAlert
            open={addToProjectData}
            severity="success"
            message="Supplier was added to this project successfully"
          />
        )}

        {data ? (
          <>
            {data.getProjectByIDV1.conciergestatus === false ? (
              <Message />
            ) : suggested &&
              suggested.listSuggestedProductsFromConcierge.length !== 0 ? (
              suggested.listSuggestedProductsFromConcierge.map((product) => {
                let selectedProduct = false;

                products.getProductsByProjectIDV1.forEach((selected) => {
                  if (product.productvbeid === selected.id) {
                    selectedProduct = true;
                  }
                });

                return (
                  <Grid item md={5}>
                    <SearchResultCard
                      product={product.productvbeid}
                      type="project"
                      evaluateAccess={false}
                      concierge={true}
                      onRemove={() => selectSupplier(product.productvbeid)}
                      addLoading={loading}
                      selected={selectedProduct}
                    />
                  </Grid>
                );
              })
            ) : (
              <Message type="requested" />
            )}
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}
