import { useState } from "react";

import { Button, TextField } from "@material-ui/core";

import EmptyDiv from "../companies/company/tabs/lists/emptyDiv";
import FilterCheckBox from "./FilterCheckBox";

import { useStyles } from "./styles";

export default function FilterList({ data, type, getList, listData }) {
  const classes = useStyles();
  const [viewMore, setViewMore] = useState(false);
  const [search, setSearch] = useState("");

  const onViewMoreClick = () => {
    setViewMore(!viewMore);
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const onFilterSelect = (id, checkedValue) => {
    getList(id, checkedValue);
  };

  let matchFound = false;

  return (
    <div className={classes.filterTitle}>
      {type}

      <div className={classes.filterList}>
        {data?.length > 6 ? (
          <TextField
            className={classes.searchTextField}
            onChange={onSearch}
            placeholder="Search"
            value={search}
          />
        ) : null}

        {data?.length !== 0 ? (
          data?.map((value, index) => {
            if (search !== "" && value.name) {
              let searchText = search.toLowerCase();
              let name = value.name.toLowerCase();
              let stringMatch = name.match(searchText);

              if (stringMatch) {
                matchFound = true;

                return (
                  <FilterCheckBox
                    name={value.name || value.year}
                    count={value.count}
                    vbeid={value.vbeid || value.year || value.name}
                    onFilterSelect={onFilterSelect}
                    listData={listData}
                  />
                );
              } else {
                if (index === data.length - 1 && !matchFound) {
                  return <EmptyDiv type="search" margin="noMargin" />;
                } else return null;
              }
            } else {
              if ((value.name || value.year) && (index < 6 || viewMore)) {
                return (
                  <FilterCheckBox
                    name={value.name || value.year}
                    count={value.count}
                    vbeid={value.vbeid || value.year || value.name}
                    onFilterSelect={onFilterSelect}
                    listData={listData}
                  />
                );
              } else {
                return null;
              }
            }
          })
        ) : (
          <EmptyDiv type={type} margin="noMargin" />
        )}

        {data?.length > 6 && search === "" && (
          <Button
            className={classes.viewMoreBtn}
            color="secondary"
            size="small"
            onClick={onViewMoreClick}
          >
            {viewMore ? "View Less" : "View More"}
          </Button>
        )}
      </div>
    </div>
  );
}
