/**
 * Queries for Agreements
 */

import { gql } from "@apollo/client";

export const inviteUser = gql`
  mutation inviteUserMutation($input: id!) {
    addPersonToPlatform(input: $input) {
      id
    }
  }
`;

export const userInfoQuery = gql`
  query userinfo {
    getLoginUserInfo(input: {}) {
      id
      vbeid
      name
      imageurl
      designation
      jobrole
      role
      roletype
      companyvbeid
      isProfileUpdated
      isMailIdValidated
      isVbridgeUser
      onBoardingCompleted
    }
  }
`;

export const getAgreement = gql`
  mutation createAgreement {
    createNDAdocumentforSign(input: {}) {
      signedurl
    }
  }
`;

// export const checkSignStatus = gql`
//   mutation checkStatus($input: id) {
//     checkStatus(input: $input) {
//       status
//     }
//   }
// `;

export const updateStatus = gql`
  mutation updateStatus($input: ndainput) {
    updateNDAResponse(input: $input) {
      status
    }
  }
`;

export const downloadDocument = gql`
  query downloadDocument {
    downloadNDAsigneddocument {
      signedurl
    }
  }
`;
