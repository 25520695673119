import { useMutation } from "@apollo/client";
import { Card, FormControl, Modal } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router";
import InputField from "../common/InputField";
import PrimaryButton from "../common/PrimaryButton";
import { createTemplate } from "./query";
import { useStyles } from "./styles";

export default function CreateTemplateForm({
  open,
  handleClose,
  type,
  tab,
  update,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [templateName, setTemplateName] = useState("");
  const [templateDesc, setTemplateDesc] = useState("");

  const [createTemplateMutation] = useMutation(createTemplate, {
    onCompleted: (response) => {
      update(response);

      history.push({
        pathname: "/createTemplate/" + response.createTemplate.vbeid,
        state: { template: response.createTemplate },
      });
    },
  });

  const handleChange = (type, event) => {
    if (type === "name") {
      setTemplateName(event.target.value);
    } else {
      setTemplateDesc(event.target.value);
    }
  };

  const onSave = () => {
    createTemplateMutation({
      variables: {
        input: {
          name: templateName,
          shortdescription: templateDesc,
          type: type,
          for: tab,
        },
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ width: "50%", margin: "20% auto" }}
    >
      <Card
        square
        className={classes.productDetailCard}
        style={{ padding: "16px", borderRadius: "5px" }}
        elevation={0}
      >
        <h4>Create Template</h4>

        <FormControl className={classes.formControl}>
          <InputField
            label="Template Name"
            onChange={(event) => handleChange("name", event)}
            value={templateName}
          />
        </FormControl>

        <FormControl className={classes.formControl} fullWidth={true}>
          <InputField
            label="Template Short Description"
            onChange={(event) => handleChange("desc", event)}
            value={templateDesc}
          />
        </FormControl>

        <PrimaryButton
          label="Save"
          disabled={!(templateName !== "" && templateDesc !== "")}
          onClick={onSave}
        />
      </Card>
    </Modal>
  );
}
