import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  table: {
    margin: theme.spacing(8, 1),
  },
  tagtable: {
    margin: theme.spacing(7, 1),

    "& button": {
      textTransform: "none !important",
    },
  },

  box: {
    display: "flex",
  },
  expandtable: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },

  button: {
    textTransform: "none !important",
    color: "#4179BD ",
    cursor: "pointer",
  },

  collapseGrid: {
    margin: theme.spacing(1),
  },

  boxheading: {
    fontSize: theme.spacing(1.6),
    fontWeight: 600,
  },

  spanbutton: {
    cursor: "pointer",
    color: "#7a0000",
    // #4179BD
  },

  yettoonboard: {
    color: "#4179BD;",
    fontSize: theme.spacing(1.4),
  },

  disabledata: {
    color: "#888888",
  },

  formControl: {
    width: "100% !important",
  },

  swiper: {
    "& .MuiDrawer-paper": {
      width: "60%",
      padding: theme.spacing(1),
    },

    "& .MuiAvatar-square": {
      width: "70px",
      height: "70px",
      "& img": {
        objectFit: "contain",
      },
    },

    "& .makeStyles-companyDetailsDiv-736": {
      margin: 0,
    },
  },

  card: {
    "& .MuiGrid-item": {
      margin: 0,
    },
  },

  sliderTitle: {
    fontSize: theme.spacing(2),
    padding: theme.spacing(2, 2),
    fontWeight: 600,
    background: "#eee",
  },
  entityinfo: {
    margin: theme.spacing(1, 0),
  },

  nodocs: {
    fontSize: theme.spacing(1.8),
  },
}));
