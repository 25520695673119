import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Paper} from "@material-ui/core";
import {NewTopic} from './createtopic';
import TopicTable from './topictable';
import { Typography } from '@material-ui/core';
 
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    
  },
  projectList:{

    paddingTop:theme.spacing(5)

  },
  subheading: {
    
    fontSize: theme.spacing(1.5),
    fontWeight:"bold",
    
    marginLeft:theme.spacing(2),
    textAlign: 'left',

    paddingTop:theme.spacing(2) 
    
}

     
}));

export default function TopicList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <NewTopic/>
    <Box m={12} className={classes.projectList}>

   <Paper >
      <Typography variant="button" display="block" color="primary" className={classes.subheading}>
        VBridgeHub Topics List
        </Typography>
      <TopicTable/>
      </Paper>

      </Box>
    </div>
    
   
    

  );
}
