import { Card, FormHelperText, Grid, SwipeableDrawer } from "@material-ui/core";
import { useState } from "react";
import AddLegalEntity from "./AddLegalEntity";
import { useStyles } from "./styles";

// import AddUser from "./AddUser";
// import TagAddUser from "./TagAddUser";

export default function LegalEntitySwiper({
  open,
  onClose,
  title,
  userInfo,
  type,
  legalentity,
  createdon,
  clearForm,
  status,
  submitForm,
  helpertext,
}) {
  const classes = useStyles();

  const [swiperOpen, setSwiperOpen] = useState(false);

  const handleOpen = () => {
    setSwiperOpen(true);
  };

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={onClose}
        className={classes.swiper}
      >
        <Card square className={classes.card} elevation={0}>
          <Grid container>
            <Grid container className={classes.headcontainer}>
              <Grid item md={1.5}>
                <p className={classes.sliderTitle}>{title}</p>
              </Grid>
              <Grid item md={10}>
                <p className={classes.sliderhelpertext}>{helpertext}</p>
              </Grid>
            </Grid>

            <Grid item md={12}>
              <AddLegalEntity
                userInfo={userInfo}
                onClose={onClose}
                pageType={type}
                legalentity={legalentity}
                createdon={createdon}
                clearForm={clearForm}
                status={status}
                submitForm={submitForm}
              />
            </Grid>
          </Grid>
        </Card>
      </SwipeableDrawer>
    </>
  );
}
