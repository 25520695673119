import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import { GET_TEMPLATE_BY_PROJECT } from "../../queries/query";

import { useQuery } from "@apollo/client";

import QAForm from "../../forms/others/QAForm";
import LoadingResult from "../../../../shared/LoadingResult";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function ViewQAForm({ id, productId, readOnly }) {
  const classes = useStyles();

  const { data, loading } = useQuery(GET_TEMPLATE_BY_PROJECT, {
    fetchPolicy: "no-cache",
    variables: { input: { vbeid: id, typelist: ["qa_template"] } },
  });

  return (
    <div className={classes.root}>
      {data ? (
        <QAForm
          projectId={id}
          productId={productId}
          type="qa_template"
          templateId={data.getTemplatesByProjectID[0].vbeid}
          readOnly={readOnly}
        />
      ) : null}

      {loading ? (
        <Grid
          container
          xs={10}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LoadingResult />
        </Grid>
      ) : null}
    </div>
  );
}
