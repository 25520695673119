import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  alignbutton: {
    marginTop: "-2%",
    marginLeft: "11%",
  },
  productfield: {
    width: "190%",
  },
  verticalfield: {
    width: "105%",
  },
  name: {
    width: "180%",
    marginLeft: "-2%",
    marginTop: "8%",
  },
  textfields: {
    width: "150%",
    marginBottom: "3%",
  },
  addNewBtn: {
    fontSize: "0.7rem",
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    padding: theme.spacing(1, 2),
    "& h2": {
      fontSize: "1rem",
    },
  },
  dialogDividers: {
    padding: theme.spacing(1, 2),
    "& p": {
      fontSize: "0.8rem",
    },
  },
  dialogRoot: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "450px !important",
      minWidth: "450px !important",
    },
  },
  formControlDiv: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: "100%",
  },
  errorMessage: {
    fontSize: "0.7rem",
    margin: theme.spacing(-0.5, 0, 1),
    color: "red",
  },
  buttonProgress: {
    height: "22px !important",
    width: "22px !important",
  },
  selectDiv: {
    fontSize: "0.8rem",
  },
}));
