import { makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  productHead: {
    cursor: "pointer",
  },
}));

export default function Heading({ name, vbeid }) {
  let history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.productHead}
        variant="h6"
        onClick={() => {
          history.push("/product/" + vbeid);
        }}
      >
        {name}
      </Typography>
    </>
  );
}
