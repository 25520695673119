import React from "react";

import clsx from "clsx";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";

import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 15,
  },
  active: {
    "& $line": {
      backgroundColor: "#232961",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#232961",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
  active: {
    backgroundColor: "#232961",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "#232961",
  },
  stepLabel: {
    "& .MuiStepLabel-label .MuiStepLabel-alternativeLabel": {
      marginTop: "8px",
    },
  },
});

const ColorlibStepIcon = ({ active, completed, icon }) => {
  const classes = useColorlibStepIconStyles();

  const icons = {
    1: <PersonIcon />,
    2: <BusinessIcon />,
    3: <AssignmentTurnedInIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(icon)]}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  stepperDiv: {
    background: "none",
    padding: theme.spacing(1, 3),
  },
  stepLabelSub: {
    fontSize: "0.7rem",
  },
}));

const getSteps = () => {
  return ["Personal Profile", "Company Profile", "Marketplace Agreement"];
};

export default function OnboardingSteps({ step }) {
  const classes = useStyles();
  const steps = getSteps();

  return (
    <Stepper
      alternativeLabel
      activeStep={step}
      connector={<ColorlibConnector />}
      className={classes.stepperDiv}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            className={classes.stepLabel}
            StepIconComponent={ColorlibStepIcon}
          >
            {label}
            {index === 0 && (
              <span className={classes.stepLabelSub}>(Mandatory)</span>
            )}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
