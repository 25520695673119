import React from "react";

import { useStyles } from "./styles";

import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

import { useMutation } from "@apollo/client";

import Attachmentslist from "./listAttachments";
import { signed } from "./query";

import ReadMore from "../../../shared/readmore";

export default function ViewSubmittedRequirement({
  submittedRequirement,
  lead,
}) {
  const classes = useStyles();

  //signed url for downloading the document
  const [signedUrl] = useMutation(signed, {
    onCompleted: (e) => {
      window.open(e.signedURLforProjectSupportDoc.signedurl, "_blank");
    },
  });

  //function for generating the signedurl
  function CreateUrl(e) {
    signedUrl({
      variables: {
        input: {
          method: "get",
          key: e.target.parentNode.getAttribute("pathname"),
        },
      },
    });
  }

  return (
    <>
      {submittedRequirement === undefined ? null : (
        <>
          {lead ? null : (
            <div className={classes.requirementViewSideHeading}>
              {/* <Typography variant="overline text">
            Previously Submitted Requirements
        </Typography> */}
            </div>
          )}

          <List>
            {submittedRequirement
              .slice(0)
              .reverse()
              .map((value) => {
                let createdDate = new Date(parseInt(value.id) * 1000)
                  .toDateString("en-US")
                  .substring(3);

                let position = 7;
                let newStr = ",";

                return (
                  <>
                    {value !== undefined ? (
                      <ListItem>
                        <Grid container md={12}>
                          <Grid item md={12}>
                            <Typography variant="caption">
                              {lead ? "Received on: " : "Submitted on: "}{" "}
                              {[
                                createdDate.slice(0, position),
                                newStr,
                                createdDate.slice(position),
                              ].join("")}
                              {/* {new Date(parseInt(value.id) * 1000).toLocaleDateString("en-US")} */}
                            </Typography>
                          </Grid>

                          <Grid item md={12}>
                            <ListItemText
                              className={classes.borderForLongDescription}
                            >
                              <div className={classes.submittedRequirementView}>
                                <ReadMore>{value.longdescription}</ReadMore>
                              </div>
                            </ListItemText>
                          </Grid>

                          <Grid item md={12}>
                            <Attachmentslist
                              getAttachments={value.attachments}
                              CreateUrl={CreateUrl}
                              display={true}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                    ) : null}
                  </>
                );
              })}
          </List>
        </>
      )}
    </>
  );
}
