export const productMenu = [
  "Technology",
  "Business Functions",
  "Business Verticals",
];

export const serviceMenu = [
  "Consulting Services",
  "Professional Services",
  "Development Services",
  "Managed Services",
];
