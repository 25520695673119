import { useEffect, useState } from "react";

import { useMutation, gql, useQuery } from "@apollo/client";

import {
  Button,
  Grid,
  Popover,
  ListItemText,
  Typography,
  ListItem,
  List,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PostAddIcon from "@material-ui/icons/PostAdd";

import VbridgeAlert from "../../shared/alert";
import { theme } from "../../../theme/theme";

import CreateProject from "./createProject";
import { addCompanyToProject, postproduct } from "../groups2.0/query";

import { useStyles } from "../products/product2.0/AddNew/styles";
import ProjectSlider from "../common/ProjectSlider";
import { useParams } from "react-router-dom";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function AddToProject({
  productvbeid,
  disable,
  searchContext,
  userCompany,
  checkedObj,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedProject, setSelectedProject] = useState([]);

  const classes = useStyles();
  const { id } = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setAnchorEl(null);

    if (
      option.type === "service-timematerial" ||
      option.type === "service-workpackage"
    ) {
      getCompany(option.vbeid);
    } else {
      AddToProject(option.vbeid);
    }
  };

  const onProjectView = (project) => {
    setSelectedProject(project);
  };

  const onClose = () => {
    setSelectedProject("");
    setShowDetails(false);
  };

  useEffect(() => {
    if (selectedProject.length !== 0) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }, [selectedProject]);

  const Actions = ({ option, position }) => {
    return (
      <Grid container style={{ fontStyle: "italic" }} spacing={1}>
        <Grid item>
          <div
            style={{
              fontSize: "12px",
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => onProjectView(option)}
          >
            View
          </div>
        </Grid>

        <Grid item>
          <Grid container spacing={1}>
            <Grid item></Grid>

            <Grid item>
              {position === "owner" ? (
                <Typography variant="caption">Owner</Typography>
              ) : (
                <Typography variant="caption">Member</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const [postlistmutation, { error: mutationError }] = useMutation(postproduct);

  const [addCompany, { data }] = useMutation(addCompanyToProject, {
    onCompleted: (response) => {},
  });

  function AddToProject(listid) {
    postlistmutation({
      variables: {
        input: { listvbeid: productvbeid, vbeid: listid },
      },
    });
  }

  const getCompany = (listid) => {
    let companyVbeid = "";

    if (searchContext === "company") {
      companyVbeid = [id];
    } else {
      companyVbeid = checkedObj.map((check) => {
        return check.companyvbeid || check;
      });
    }

    addCompany({
      variables: {
        input: { listvbeid: companyVbeid, vbeid: listid },
      },
    });
  };

  //get user projects
  const listUserProjects = gql`
    query listUserProjects($input: projectinputV1!) {
      listProjectbyPersonV1(input: $input) {
        id
        name
        description
        vbeid
        position
        type
        projectstatus
      }
    }
  `;

  const { data: userprojects, refetch } = useQuery(listUserProjects, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        type:
          searchContext === "products" || searchContext === "Products"
            ? "product"
            : searchContext === "services" || searchContext === "Services"
            ? "service"
            : "",
      },
    },
  });

  return (
    <>
      {mutationError ? (
        <VbridgeAlert
          open={true}
          message="Something Went Wrong."
          severity="error"
        />
      ) : null}

      {data ? (
        <VbridgeAlert
          open={true}
          message="Added to Project Successfully"
          severity="success"
        />
      ) : null}

      {userprojects ? (
        <>
          <div>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<PostAddIcon />}
              size="small"
              onClick={handleClick}
              disabled={disable}
              className={classes.addNewBtn}
            >
              Add To Project
            </Button>

            <Grid container justify="center" alignItems="center" spacing={5}>
              <Popover
                id="long-menu"
                style={{ width: "50rem" }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <List>
                  <Grid item>
                    {userprojects?.listProjectbyPersonV1.length !== 0 &&
                      userprojects?.listProjectbyPersonV1.map((option) => (
                        <ListItem key={option.vbeid} dense>
                          <ListItemText
                            style={{ paddingTop: theme.spacing(0) }}
                            primary={
                              <div
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "10rem",
                                }}
                              >
                                <LightTooltip
                                  disableFocusListener
                                  title={option.name}
                                  placement="top"
                                >
                                  <Typography
                                    variant="OVERLINE TEXT"
                                    noWrap
                                    color="primary"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "0.75rem",
                                    }}
                                    onClick={(event) =>
                                      handleMenuItemClick(option)
                                    }
                                  >
                                    {option.name}
                                  </Typography>
                                </LightTooltip>
                              </div>
                            }
                            secondary={
                              <Actions
                                option={option}
                                position={option.position}
                              />
                            }
                          />
                        </ListItem>
                      ))}
                  </Grid>
                </List>

                {/* <Grid item>
                  <CreateProject
                    searchContext={searchContext}
                    refetch={refetch}
                  />
                </Grid> */}
              </Popover>
            </Grid>

            {showDetails && selectedProject.length !== 0 && (
              <ProjectSlider
                open={showDetails}
                projectVbeid={selectedProject.vbeid}
                onClose={onClose}
                userCompany={userCompany}
                project={selectedProject}
              />
            )}
          </div>
        </>
      ) : null}
    </>
  );
}
