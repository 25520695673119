import OutlinedButton from "../companies/company/OutlinedButton";

import { useStyles } from "./styles";

export default function ClaimCompanyProfile({
  claimProfile,
  agreed,
  loading,
  userInfo,
}) {
  const classes = useStyles();

  return (
    <div className={classes.claimDiv}>
      <div className={classes.claimHeading}>
        {userInfo.roletype === "seller" ? (
          <>
            <div className={classes.formSubText}>
              {userInfo.companyname !== "" ? (
                <b>{userInfo.companyname}</b>
              ) : (
                "This company"
              )}{" "}
              profile is not yet claimed. Claiming this company profile allows
              you to update the content and become an Admin which can be
              extended to others in your company.{" "}
              <b>
                Only a claimed profile will allow you to receive and respond to
                buyer requests.
              </b>
            </div>
            <div className={classes.formSecondText}>
              An updated and rich company profile will greatly enhance our AI
              algorithm to showcase your company profile when Enterprises are
              looking for solutions. This section can be updated at any time.
            </div>
          </>
        ) : (
          <>
            <div className={classes.formSubText}>
              {userInfo.companyname !== "" ? (
                <b>{userInfo.companyname}</b>
              ) : (
                "This company"
              )}{" "}
              profile is not yet claimed.{" "}
              <b>
                {" "}
                Only a claimed profile will allow you to engage with vendors.
              </b>
            </div>
          </>
        )}

        <div className={classes.claimBtnDiv}>
          <OutlinedButton
            disabled={!agreed || loading}
            label={loading ? "" : "Claim Profile"}
            onClick={claimProfile}
            className={classes.claimBtn}
          />
        </div>
      </div>
    </div>
  );
}
