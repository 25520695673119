import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useParams } from "react-router-dom";
import CompanyMessaging from "../../../messaging2.0/CompanyMessaging";
import Messaging from "../../../messaging2.0/messaging";
import {
  GET_COMPANIES_BY_PROJECT,
  GET_PRODUCTS_BY_PROJECT,
} from "../../queries/query";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(3),
  },
}));

export default function ViewMessaging({ projectData }) {
  const classes = useStyles();
  const { id } = useParams();

  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  let queryName = GET_PRODUCTS_BY_PROJECT;
  let dataName = "getProductsByProjectIDV1";

  if (projectData?.type === "service-timematerial") {
    queryName = GET_COMPANIES_BY_PROJECT;
    dataName = "listCompaniesByProjectID";
  }

  const { data } = useQuery(queryName, {
    variables: { input: { vbeid: id } },
  });

  return (
    <div className={classes.root}>
      {data ? (
        projectData.type !== "service-timematerial" ? (
          <Messaging data={data} />
        ) : (
          <CompanyMessaging data={data} id={id} dataName={dataName} />
        )
      ) : null}
    </div>
  );
}
